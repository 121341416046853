import { DataFilter } from '../../../filters/data-filter/data-filter-service';
import { Guid } from '../../../utils/guid-service';
import { Aggregation, BaseQuery, FilterLogicalGroupType, Transformation } from '../../../XProjector/xprojector-client-service';
import { jsonArrayMember, jsonMember, jsonObject, TypedJSON } from 'typedjson';
import { BaseQueryConfigInterface, GroupSelectionTypes, InputArrayMode, LegendPosition, WidgetConfig } from '../../widget-config-service';

export enum PieChartType
{
	PIE         = 0,
  DOUGHNUT    = 1,
  BARCHART    = 2,
  RADAR       = 3,
  POLARAREA   = 4
}

@jsonObject
export class PiechartColumnConfig {

  @jsonMember
  public ColumnName : string = '';

  @jsonMember
  public Label : string = '';

  @jsonMember
  public Unit : string = '';

  @jsonMember
  public ColumnOutName : string = '';

  @jsonMember
  public Clickable : boolean = false;

  @jsonMember
  public Transform: Aggregation = Aggregation.NONE;

  @jsonMember
	public UseAggregationInputParameter : boolean = false;

  @jsonMember
  public AggregationInputParameterId: string = '';

  public Clone(): PiechartColumnConfig {
		return TypedJSON.parse(TypedJSON.stringify(this, PiechartColumnConfig), PiechartColumnConfig);
	}
}

@jsonObject
export class PiechartWidgetQuery implements BaseQueryConfigInterface {

  public Id : string = Guid.newGuid();

  @jsonMember
  public Name : string = '';

  @jsonMember
  public Query : BaseQuery = new BaseQuery();

  @jsonMember
  public ProjectionId : string = '';

  @jsonMember
  public MaxItems : number = 100;

  @jsonMember
  public UseGrouping : boolean = false;

  @jsonMember
  public SortOnLabel : boolean = false;

  @jsonMember
  public GroupingTransform : Transformation = Transformation.NONE;

  @jsonMember
	public UseTransformInputParameter : boolean = false;

  @jsonMember
  public TransformInputParameterId: string = '';

  @jsonArrayMember(String)
  public Group : string[] = [];

  @jsonMember
  public LabelConfig : PiechartColumnConfig = new PiechartColumnConfig();

  @jsonMember
  public DataConfig : PiechartColumnConfig = new PiechartColumnConfig();

  @jsonArrayMember(DataFilter)
  public DataFilters : DataFilter[] = [];

  @jsonMember
  public FilterLogicalGroupType : FilterLogicalGroupType = FilterLogicalGroupType.AND;

  @jsonMember
  public timestampColumnName : string = '';

  @jsonMember
	public UseProjectionInputParameter : boolean = false;

  @jsonMember
  public ProjectionInputParameterId: string = '';

  @jsonMember
  public GroupSelectionType : GroupSelectionTypes = GroupSelectionTypes.GROUP;

  @jsonMember
  public GroupInputParameterId: string = '';

  @jsonArrayMember(String)
  public GroupInputParameterIds: string[] = [];

  @jsonMember
  public InputArrayMode : InputArrayMode = InputArrayMode.OR_FILTER;
}

@jsonObject
export class PiechartWidgetConfig extends WidgetConfig {

constructor() {
  super();
  this.LegendPosition = LegendPosition.LEFT;
}

  @jsonMember
  public ConfigQuery : PiechartWidgetQuery = new PiechartWidgetQuery();

  @jsonArrayMember(PiechartWidgetQuery)
  public ConfigQueries : PiechartWidgetQuery[] =[];

  @jsonMember
  public ChartType : PieChartType = PieChartType.PIE;

  @jsonMember
	public AnimationsMs : number = 1000;

  @jsonMember
  public ColorScheme : string = '';

  @jsonMember
	public ColorByLabel : boolean = false;

	public Clone(): PiechartWidgetConfig {
		return TypedJSON.parse(TypedJSON.stringify(this, PiechartWidgetConfig), PiechartWidgetConfig);
  }

  public GetSubscriprionData(): { projectionId: string, group : string[] }[] {
    let result : { projectionId: string, group : string[] }[] = [];
    if (this.ConfigQuery.Query && this.ConfigQuery.Query.targetprojectionid?.length > 0) {
      result.push({projectionId : this.ConfigQuery.Query.targetprojectionid, group : this.ConfigQuery.Query.targetgroup})
    }
    return result;
  }

  public CanExportToExcel : boolean = true;

  public CanExportToImage : boolean = true;

  update(config: PiechartWidgetConfig) {
    super.update(config)

    this.ConfigQueries = config.ConfigQueries;
    this.ChartType = config.ChartType;
    this.AnimationsMs = config.AnimationsMs;
    this.ColorScheme = config.ColorScheme;
    this.ColorByLabel = config.ColorByLabel;
  }
}
