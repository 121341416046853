import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { EditTreenodeComponent } from '@xprojectorfeatures/xconf/components/edit-treenode/edit-treenode.component';
import { RossakerBmsDataCollectorViewData } from '@core/models/rossaker-bms-data-collector-view-data';
import { RossakerBmsLorawanMultiMeter } from '@core/models/rossaker-bms-lorawan-multimeter';
import { RossakerBmsAdminService } from '@core/services/rossaker-bms-admin-service';
import { GrpcNode } from '@xprojectorcore/xprojector_backend/proto/xprojector.grpc.models.pb';
import { GrpcNodeType, SearchNodesRequest } from '@xprojectorcore/xprojector_backend/proto/xprojector.xconf.pb';
import { XProjectorXConfClient } from '@xprojectorcore/xprojector_backend/xprojector-xconf-client';
import { DashboardOutputChangeParameters, DateHelper, OutputDataType, XprojAlertService, XprojModalService } from 'xproj-lib';
import { RossakerBmsDataCollectorComponent } from '@features/rossaker-bms/rossaker-bms-admin/rossaker-bms-data-collector-view.component';
import { RossakerLorawanDeviceInfo } from '@app/core/models/rossaker-lorawan-device-info';
import * as XLSX from 'xlsx';
import { RossakerXProjectorBmsLoRaWANClient } from '@app/core/xprojector_backend/rossaker-xprojector-bms-lorawan-client';
import { ConfiguredDownlinkItem, ConfiguredDownlinkSequence } from '@app/core/xprojector_backend/proto/xprojector.modulerossakerbms.lorawan.pb';
import { ClrDatagridSortOrder } from '@clr/angular';
import { RossakerBmsLatestValue } from '@app/core/models/rossaker-bms-latestvalue';

export class RossakerBmsLorawanMetersInfo {
  id : string;
  devEui : string;
  name : string;
  provisioned : boolean;
  lastActiveString : string;
  snr : string;
  spf : number;
  rssi : number;
  externalId : string;
  multiMeterType : string;
  appKey : string;
  createdAtString: string;
  modifiedAtString : string;
}

@Component({
  selector: 'app-rossaker-bms-lorawan-meters-group-data-collector-view',
  templateUrl: './rossaker-bms-lorawan-meters-group-data-collector-view.component.html',
  styleUrls: ['./rossaker-bms-lorawan-meters-group-data-collector-view.component.scss']
})
export class RossakerBmsLorawanMetersGroupDataCollectorViewComponent implements OnInit, RossakerBmsDataCollectorComponent {

  @ViewChild("editNode", { read: EditTreenodeComponent, static: false }) editNode: EditTreenodeComponent;

  private xconfClient: XProjectorXConfClient = inject(XProjectorXConfClient);
  private rossakerAdminService: RossakerBmsAdminService = inject(RossakerBmsAdminService);
  public dateHelper: DateHelper = inject(DateHelper);
  private alertService: XprojAlertService = inject(XprojAlertService);
  private modalService: XprojModalService = inject(XprojModalService);
  private loRaWANClient: RossakerXProjectorBmsLoRaWANClient = inject(RossakerXProjectorBmsLoRaWANClient);


  public static NodeTypeId : string = '_x_lorawan_group';

  data: RossakerBmsDataCollectorViewData;
  visible: boolean;

  infoActive: boolean = true;
  downlinkActive: boolean = false;
  locationActive: boolean = false;

  lorawanMultiMeters : RossakerBmsLorawanMultiMeter[] = [];
  lorawanDeviceInfos : RossakerLorawanDeviceInfo[] = [];
  loadingMeters : boolean = false;
  selectedMultiMeter : RossakerBmsLorawanMultiMeter;
  latestValues : RossakerBmsLatestValue[] = [];

  lorawanMultiMetersInfos : RossakerBmsLorawanMetersInfo[] = [];

  allNodeTypes: GrpcNodeType[] = [];
  currentEditNodeId : string;
  currentEditNode : GrpcNode;
  showEditModal : boolean = false;

  responsiveWidth: number = 600;
  meterParameters: DashboardOutputChangeParameters[] = [];
  gatewayParameters: DashboardOutputChangeParameters[] = [];


  loadingGateway: boolean = false;

  lorawanDeviceSpreadingfactorsStat: Map<number, number>;

  configuredDownlinkItems: ConfiguredDownlinkItem[] = [];
  selectedConfiguredDownlinkItem: ConfiguredDownlinkItem = null;

  configuredDownlinkSequences: ConfiguredDownlinkSequence[] = [];
  selectedConfiguredDownlinkSequence: ConfiguredDownlinkSequence = null;
  queueAllEnabled : boolean = true;
  downlinkFailedAfter : Date = new Date();
  downlinkFailedAfterString : string;
  downlinkOverrideTimeout : number = -1;
  downlinkSendEmail : boolean = false;

  showDownlinkInfoModal: boolean = false;
  downlinkInfoId : string;
  downlinkInfoLabel : string;
  downlinkInfos: {
    devEui: string,
    timestamp: string,
    result: string,
    message: string
  }[] = [];

  ascSort = ClrDatagridSortOrder.ASC;
  descSort = ClrDatagridSortOrder.DESC;

  constructor(
  ) { }

  ngOnInit(): void {
  }


  async initDataCollectorView() {
    await this.update();
  }

  async update() {
    try {
      this.loadingMeters = true;
      this.lorawanMultiMeters  = await this.rossakerAdminService.getLorawanMultiMeters(this.data.node.id);
      this.lorawanDeviceInfos  = await this.rossakerAdminService.getLoraWANDeviceInfos(this.lorawanMultiMeters.map(x => x.devEui));

      this.lorawanMultiMetersInfos = [];
      this.lorawanMultiMeters.forEach(meter => {
        let deviceInfo = this.lorawanDeviceInfos.find(x => x.deveui == meter.devEui);
        let lorawanMultiMetersInfo : RossakerBmsLorawanMetersInfo = new RossakerBmsLorawanMetersInfo();
        lorawanMultiMetersInfo.id = meter.id;
        lorawanMultiMetersInfo.devEui = meter.devEui;
        lorawanMultiMetersInfo.name = meter.name;
        lorawanMultiMetersInfo.provisioned = meter.provisioned;
        lorawanMultiMetersInfo.lastActiveString = deviceInfo?.modifiedAt ? this.dateHelper.utils.formatByString(deviceInfo?.modifiedAt, 'yyyy-MM-dd HH:mm:ss') : '---';
        lorawanMultiMetersInfo.externalId = meter.externalId;
        lorawanMultiMetersInfo.multiMeterType = meter.multiMeterType;
        lorawanMultiMetersInfo.appKey = meter.appKey;
        lorawanMultiMetersInfo.createdAtString = this.dateHelper.utils.formatByString(meter.createdAt, 'yyyy-MM-dd HH:mm:ss');
        lorawanMultiMetersInfo.modifiedAtString = this.dateHelper.utils.formatByString(meter.modifiedAt, 'yyyy-MM-dd HH:mm:ss');
        lorawanMultiMetersInfo.spf = deviceInfo?.spreadingfactor ?? -1;
        lorawanMultiMetersInfo.snr = deviceInfo?.snr.toFixed(1) ?? '-1';
        lorawanMultiMetersInfo.rssi = deviceInfo?.rssi ?? -1;

        this.lorawanMultiMetersInfos.push(lorawanMultiMetersInfo);
      });

      this.updateDownlinkItems();
      this.updateDownlinkSequences();
      this.updateDashboardOutputs();
      this.updateLatestValues();

    }
    finally {
      this.loadingMeters = false;
    }
  }

  async updateLatestValues() {
    this.latestValues = await this.rossakerAdminService.getLatestValues(this.data.bmsCustomer.customerId);
    this.lorawanMultiMeters.forEach(meter => {
      let gatewayId = +meter.id;
      let latestValue = this.latestValues.find(x => x.gatewayId == gatewayId);
      if (latestValue) {
        meter.lastValueAt = latestValue.timestamp;
      }
    });
  }

  async editMultiMeter() {
    if (this.selectedMultiMeter) {
      let node = await this.xconfClient.getNode(
        this.selectedMultiMeter.id,
        '_x_lorawan_multimeter');
      if (node) {
        if (this.allNodeTypes.length == 0) {
          this.allNodeTypes = await this.xconfClient.getNodeTypes();
        }

        this.currentEditNodeId = node.id;
        this.currentEditNode = node;
        this.showEditModal = true;
      }
    }
  }

  async updateNode(node: GrpcNode, oldId: string): Promise<boolean> {
    let result = await this.xconfClient.updateNode(node, oldId, '', this.data.customer.id);
    return result.result;
  }

  updateDashboardOutputs() {
    this.meterParameters = [];
    if (this.data.bmsCustomer && this.selectedMultiMeter) {

      let out = new DashboardOutputChangeParameters();
      out.outputParameterName = 'customerid';
      out.value = this.data.bmsCustomer.customerId;
      out.datatype = OutputDataType.String;
      this.meterParameters.push(out);

      out = new DashboardOutputChangeParameters();
      out.outputParameterName = 'customerxdbid';
      out.value = this.data.bmsCustomer.id;
      out.datatype = OutputDataType.Int64;
      this.meterParameters.push(out);

      out = new DashboardOutputChangeParameters();
      out.outputParameterName = 'deveui';
      out.value = this.selectedMultiMeter.devEui;
      out.datatype = OutputDataType.String;
      this.meterParameters.push(out);
    }

    this.gatewayParameters = [];
    if (this.data.bmsCustomer) {
      let out = new DashboardOutputChangeParameters();
      out.outputParameterName = 'customerid';
      out.value = this.data.bmsCustomer.customerId;
      out.datatype = OutputDataType.String;
      this.gatewayParameters.push(out);

      out = new DashboardOutputChangeParameters();
      out.outputParameterName = 'gatewayid';
      out.value = +this.data.node.id;
      out.datatype = OutputDataType.Int64;
      this.gatewayParameters.push(out);
    }
  }

  setWidth(width: number) {
    if (this.data) {
      this.data.width = width;
    }

    this.responsiveWidth = width;
  }

  async saveEditNode() {
    if (this.editNode) {
      this.editNode.savePropertyValues();
      let result = await this.updateNode(this.currentEditNode, this.currentEditNodeId);
      if (result) {
        this.alertService.info('Node updated.');
        this.update();
      }
      else {
        this.alertService.error('Error update node!');
      }
    }

    this.showEditModal = false;
  }

  async updateGateway(force: boolean = false) {
    try {
      this.loadingGateway = true;
      await this.loRaWANClient.getGateway(this.data.bmsCustomer.customerId, this.data.node.id,
        this.data.node.nodeTypeId, '', '', true, force);
    }
    finally {
      this.loadingGateway = false;
    }
  }

  async updateDownlinkItems() {
    this.configuredDownlinkItems = await this.loRaWANClient.getConfiguredDownlinkItems();
  }

  async updateDownlinkSequences() {
    this.configuredDownlinkSequences = await this.loRaWANClient.getConfiguredDownlinkSequences();
    this.downlinkFailedAfter = this.dateHelper.utils.addDays(new Date(), -3);
    this.downlinkFailedAfterString = this.dateHelper.utils.formatByString(this.downlinkFailedAfter, 'yyyy-MM-dd');
  }


  async enqueueDownlinkItem() {
    if (this.selectedConfiguredDownlinkItem) {
      let doEnqueue = await this.modalService.ShowConfirmModalAsync({
        header: 'Enqueue downlink item',
        description: 'Enqueue downlink item to all devices, are you sure?',
        ok: 'Enqueue',
        cancel: 'Cancel'
      });

      if (doEnqueue) {
        let result = await this.loRaWANClient.enqueueDownlinkItem(this.data.bmsCustomer.customerId, this.data.node.id,
          this.data.node.nodeTypeId, '', this.selectedConfiguredDownlinkItem);

        if (result.ok) {
          this.modalService.ShowConfirmModalAsync({
            header: 'Enqueue downlink item success',
            description: 'Downlink item enqueued to ' + result.deviceCount + ' devices.',
            showCancel: false
          });
        }
        else {
          this.modalService.ShowConfirmModalAsync({
            header: 'Enqueue downlink item failure',
            description: result.message,
            showCancel: false
          });

        }

      }
    }
  }

  async enqueueDownlinkSequence() {
    if (this.selectedConfiguredDownlinkSequence) {
      let doEnqueue = await this.modalService.ShowConfirmModalAsync({
        header: 'Enqueue downlink sequence',
        description: 'Enqueue downlink sequence to all devices, are you sure?',
        ok: 'Enqueue',
        cancel: 'Cancel'
      });

      if (doEnqueue) {
        if (!this.queueAllEnabled) {
          this.downlinkFailedAfter = this.dateHelper.utils.parse(this.downlinkFailedAfterString, 'yyyy-MM-dd');
        }
        let result = await this.loRaWANClient.enqueueDownlinkSequence(this.data.bmsCustomer.customerId, this.data.node.id,
          this.data.node.nodeTypeId, 'chirpstack', this.selectedConfiguredDownlinkSequence.id,
          this.queueAllEnabled ? null : this.downlinkFailedAfter, this.downlinkOverrideTimeout, this.downlinkSendEmail);

        if (result.ok) {
          this.modalService.ShowConfirmModalAsync({
            header: 'Enqueue downlink sequence success',
            description: 'Downlink sequence enqueued to ' + result.deviceCount + ' devices.',
            showCancel: false
          });
        }
        else {
          this.modalService.ShowConfirmModalAsync({
            header: 'Enqueue downlink sequence failure',
            description: result.message,
            showCancel: false
          });

        }

      }
    }
  }

  downlinkSequenceChanged() {
    if (this.selectedConfiguredDownlinkSequence) {
      this.downlinkOverrideTimeout = this.selectedConfiguredDownlinkSequence.timeout;
      this.downlinkSendEmail = false;
    }
  }

  private async _showDownlinkInfos(nodeId : string, nodeLabel: string) {
    this.downlinkInfoId = nodeId;
    this.downlinkInfoLabel = nodeLabel;

    this.downlinkInfos = [];
    let infos = await this.loRaWANClient.getDownlinkInfos(this.data.bmsCustomer.customerId, this.data.node.id, this.data.node.nodeTypeLabel,
      nodeId, nodeLabel);

    infos.forEach(x => {
      this.downlinkInfos.push({
        devEui: x.devEui,
        timestamp: x.timestamp.nanos > 0 ? this.dateHelper.formatDate(x.timestamp.toDate(), 'keyboardDateTime') : '',
        result: x.result,
        message: x.message
      });
    });
    this.showDownlinkInfoModal = true;
  }

  async showDownlinkSequenceInfos() {
    if (this.selectedConfiguredDownlinkSequence) {
      await this._showDownlinkInfos(this.selectedConfiguredDownlinkSequence.id, '_x_lorawan_lorawansequence');
    }
  }

  async showDownlinkInfos() {
    if (this.selectedConfiguredDownlinkItem) {
      await this._showDownlinkInfos(this.selectedConfiguredDownlinkItem.id, '_x_lorawan_lorawandownlink');
    }
  }

  async exportDownlinkInfos() {
    let result = await this.loRaWANClient.exportDownlinkInfos(this.data.bmsCustomer.customerId, this.data.node.id, this.data.node.nodeTypeLabel,
      this.downlinkInfoId, this.downlinkInfoLabel, 'DownlinkInfo');

    if (!result) {
      this.alertService.error('Error export to excel!');
    }
  }

  closeEditNode() {
    this.showEditModal = false;
  }

  async addMultiMeter() {

  }

  async exportMultiMeters() {
    var headers: string[] = ['devEui', 'name', 'provisioned', 'lastActiveString', 'externalId', 'rssi', 'spf', 'snr', 'multiMeterType', 'appKey', 'createdAtString', 'modifiedAtString', 'id'];
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.lorawanMultiMetersInfos, { header: headers });

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'SensorInfo');

    XLSX.writeFile(wb, 'lorawansensors.xlsx');
  }

  async importMultiMeters() {

  }

  async refresh() {
    await this.update();
  }
}
