<clr-tabs>
  <clr-tab>
    <button clrTabLink>Settings</button>
    <clr-tab-content>
      <!-- <h3>Dashboard settings</h3> -->
      <form clrForm style="margin-bottom: 2em;">
        <clr-select-container>
          <label>Link all widgets</label>
          <select clrSelect name="linkallwidgets" [(ngModel)]="config.GlobalWidgetSettings.LinkAllWidgets">
            <option [ngValue]="true">Yes</option>
            <option [ngValue]="false">No</option>
          </select>
        </clr-select-container>
        <clr-input-container>
          <label>Show decimals</label>
          <input clrInput placeholder="2" type="number" name="decimals"
            [(ngModel)]="config.GlobalWidgetSettings.Decimals" />
        </clr-input-container>
        <clr-input-container>
          <label>Min update interval</label>
          <input clrInput placeholder="5000" type="number" name="minupdateinterval"
            [(ngModel)]="config.GlobalWidgetSettings.MinUpdateInterval" />
        </clr-input-container>



        <clr-textarea-container>
          <label>Custom stylesheet</label>
          <textarea clrTextarea name="usercss" [(ngModel)]="config.UserCSS"></textarea>
        </clr-textarea-container>

        <clr-select-container>
          <label>Subscriptions</label>
          <select clrSelect name="SubscriptionsEnabled" [(ngModel)]="config.SubscriptionsEnabled">
            <option [ngValue]="true">Enabled</option>
            <option [ngValue]="false">Disabled</option>
          </select>
        </clr-select-container>

        <clr-select-container>
          <label>Refresh dashboard</label>
          <select clrSelect name="RefreshEnabled" [(ngModel)]="config.RefreshEnabled">
            <option [ngValue]="true">Enabled</option>
            <option [ngValue]="false">Disabled</option>
          </select>
        </clr-select-container>

        <clr-input-container *ngIf="config.RefreshEnabled">
          <label>Refresh interval (ms)</label>
          <input clrInput placeholder="" type="number" name="refreshinterval" min="500"
            [(ngModel)]="config.RefreshInterval" />
        </clr-input-container>

        <clr-select-container>
          <label>Display Date UTC</label>
          <select clrSelect name="dateutc" [(ngModel)]="config.GlobalWidgetSettings.DateUTC">
            <option [ngValue]="true">Yes</option>
            <option [ngValue]="false">No</option>
          </select>
        </clr-select-container>

      </form>

      <h4>Output parameters</h4>
      <clr-datagrid [(clrDgSingleSelected)]="selectedOutputParameter">
        <clr-dg-column>Name</clr-dg-column>
        <clr-dg-column>Datatype</clr-dg-column>

        <clr-dg-row *ngFor="let output of config.OutputParameters; let i = index" [clrDgItem]="output">
          <clr-dg-cell>
            <input class="clr-input" placeholder="" name="outputparam_name_{{i}}" id=outputparam_name_{{i}}
              [(ngModel)]="output.name" />
          </clr-dg-cell>

          <clr-dg-cell>
            <div class="clr-select-wrapper">
              <select class="clr-select" name="outputparam_datatype_{{i}}" id="outputparam_datatype_{{i}}"
                [(ngModel)]="output.datatype">
                <option [ngValue]="OutputDataType.Float32">Float32</option>
                <option [ngValue]="OutputDataType.Float64">Float64</option>
                <option [ngValue]="OutputDataType.UInt8">UInt8</option>
                <option [ngValue]="OutputDataType.Int32">Int32</option>
                <option [ngValue]="OutputDataType.Int64">Int64</option>
                <!-- <option value="5">Number</option> -->
                <option [ngValue]="OutputDataType.Timestamp">Timestamp</option>
                <option [ngValue]="OutputDataType.String">String</option>
                <option [ngValue]="OutputDataType.Projection">Projection</option>
              </select>
            </div>
          </clr-dg-cell>
        </clr-dg-row>
      </clr-datagrid>

      <button type="button" class="btn btn-link" (click)="addOutputParameter()">
        <clr-icon shape="plus-circle"></clr-icon>
        Add output parameter
      </button>
      <button *ngIf="selectedOutputParameter" type="button" class="btn btn-link" (click)="deleteSelectedParameter()">
        <clr-icon shape="minus-circle"></clr-icon>
        Remove selected parameter
      </button>
      <br />
      <button class="btn" (click)="close()">Close</button>

    </clr-tab-content>
  </clr-tab>
  <clr-tab>
    <button clrTabLink>Colors</button>
    <clr-tab-content>
      <clr-input-container>
        <label>Colorscheme</label>
        <input clrInput placeholder="" type="text" name="colorscheme"
          [(ngModel)]="config.GlobalWidgetSettings.ColorScheme" />
      </clr-input-container>

      <h4>Color palette</h4>
      <clr-datagrid [(clrDgSingleSelected)]="selectedPaletteColor">
        <clr-dg-column>Name</clr-dg-column>
        <clr-dg-column>Script Name</clr-dg-column>
        <clr-dg-column>DefaultColor</clr-dg-column>
        <clr-dg-column>Profile</clr-dg-column>

        <clr-dg-row *ngFor="let color of config.ColorPalette; let i = index" [clrDgItem]="color">
          <clr-dg-cell>
            <input class="clr-input" placeholder="" name="color_name_{{i}}" id=color_name_{{i}}
              [(ngModel)]="color.Name" />
          </clr-dg-cell>
          <clr-dg-cell>
            <input class="clr-input" placeholder="" name="color_luaname_{{i}}" id=color_luaname_{{i}}
              [(ngModel)]="color.LuaScriptName" />
          </clr-dg-cell>
          <clr-dg-cell>
            <input class="clr-input" placeholder="" name="color_defcolor_{{i}}" id=color_defcolor_{{i}}
              [(ngModel)]="color.DefaultColor" />
          </clr-dg-cell>
          <clr-dg-cell>
            <input class="clr-input" placeholder="" name="color_prof_{{i}}" id=color_prof_{{i}}
              [(ngModel)]="color.Profile" />
          </clr-dg-cell>

        </clr-dg-row>
      </clr-datagrid>

      <button type="button" class="btn btn-link" (click)="addPaletteColor()">
        <clr-icon shape="plus-circle"></clr-icon>
        Add color
      </button>
      <button *ngIf="selectedPaletteColor" type="button" class="btn btn-link" (click)="deleteSelectedPaletteColor()">
        <clr-icon shape="minus-circle"></clr-icon>
        Remove selected color
      </button>


    </clr-tab-content>
  </clr-tab>

  <clr-tab>
    <button clrTabLink>Widgets</button>
    <ng-template [(clrIfActive)]="widgetsActive">
      <clr-tab-content>
        <h4>Widgets</h4>
        <ul style="margin-bottom: 2em;">
          <li *ngFor="let widget of config.WidgetConfigs; let i = index">
            <clr-icon style="margin-right: 1em; cursor: pointer;" shape="trash" size="16" (click)=removeWidget(i)>
            </clr-icon>
            <clr-icon style="margin-right: 1em; cursor: pointer;" shape="copy" size="16" (click)=cloneWidget(widget)>
            </clr-icon>
            <p style="margin-right: 1em; cursor: pointer; display:inline-block;" (click)="selectWidget(widget)">
              {{widget.Title?.length > 0 ? widget.Title : widget.Id}}</p>
          </li>
        </ul>
      </clr-tab-content>
    </ng-template>
  </clr-tab>

  <clr-tab>
    <button clrTabLink>JSON</button>
    <ng-template [(clrIfActive)]="jsonActive">
      <clr-tab-content>
        <clr-alert class="copyToClipboardAlert" [clrAlertType]="'success'" [clrAlertSizeSmall]="false"
          *ngIf="showCopiedToClipboardAlert">
          <clr-alert-item>
            <span class="alert-text">
              Json copied to clipboard.
            </span>
          </clr-alert-item>
        </clr-alert>
        <textarea class="json" readonly="true">{{getJson()}}</textarea>
        <br>
        <button #copyToClipboard class="copyToClipboard" type="button" (click)="copyJsonToClipboard()">Copy to
          clipboard</button>
      </clr-tab-content>
    </ng-template>
  </clr-tab>
</clr-tabs>
