import { ViewportScroller } from '@angular/common';
import { AfterContentInit, AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BasicResponse, GrpcNode, GrpcNodeProperty } from '@xprojectorcore/xprojector_backend/proto/xprojector.grpc.models.pb';
import { GrpcEdgeType, GrpcNodeType, GrpcRootNodeInstance, GrpcTreeNodeDefinition, GrpcDataSourceDefinition, GrpcDataSourceInstance, GrpcTreeNodeReferenceDefinition, GetTreeRequest, GetTreeResponse, GrpcTreeNode, SearchNodesRequest, SearchProperty, GrpcNodeCommand } from '@xprojectorcore/xprojector_backend/proto/xprojector.xconf.pb';
import { XProjectorXConfClient } from '@xprojectorcore/xprojector_backend/xprojector-xconf-client';
import { CacheService } from 'xproj-lib';
import { NodeMaterialBuildState } from 'babylonjs/Materials/Node/nodeMaterialBuildState';
import { isThisSecond } from 'date-fns';
import { stringify } from 'querystring';
import { Observable, Subject } from 'rxjs';
import { ArrayUtils, DashboardOutputChangeParameters, Guid, XprojAlertService, XprojDashboardComponent, XProjectorClient, XprojModalService } from 'xproj-lib';
import { EditTreenodeComponent } from '../edit-treenode/edit-treenode.component';
import { event } from '@cds/core/internal';
import { XconfTreeview, XconfTreeNode } from '../../models/xconf-tree-node'
import { ClrLoadingState } from '@clr/angular';

var AsyncLock = require('async-lock');

export class DropDownItem {
  name: string;
  shape: string;
  nodeTypeLabel?: string;
  nodeTypeId?: string;
  onClick: (treeNode: XconfTreeNode) => void;
}

@Component({
  selector: 'app-configuration-datasource',
  templateUrl: './configuration-datasource.component.html',
  styleUrls: ['./configuration-datasource.component.scss'],
  providers: [
    XProjectorXConfClient
  ]
})
export class ConfigurationDataSourceComponent implements OnInit, AfterViewInit, AfterContentInit {

  @ViewChild("editTreeNode", { read: EditTreenodeComponent, static: false }) editTreeNode: EditTreenodeComponent;
  @ViewChild("addTreeNode", { read: EditTreenodeComponent, static: false }) addTreeNode: EditTreenodeComponent;
  @ViewChild("dashboard", { read: XprojDashboardComponent, static: false }) xprojDashboard: XprojDashboardComponent;

  private _dataSourceInstance: GrpcDataSourceInstance;
  @Input()
  get dataSourceInstance(): GrpcDataSourceInstance {
    return this._dataSourceInstance;
  };
  set dataSourceInstance(instance: GrpcDataSourceInstance) {
    this._dataSourceInstance = instance;
    this._rootNodes = [];
    this.initDataSourceInstance();
  }

  @Input() editEnabled: boolean = true;
  @Input() highlightSelected: boolean = false;
  @Input() sortByName: boolean = false;
  @Input() sortPaddingZeros: number = 0;
  @Input() customerId: string = '';
  @Input() moveEnabled: boolean = false;
  @Input() moveEnabledNodeTypeIds: string[] = [];
  @Input() duplicateEnabled: boolean = false;
  @Input() duplicateEnabledNodeTypeIds: string[] = [];
  @Input() expandRoot: boolean = false;
  @Input() selectedPath: string[] = [];
  @Input() lazy: boolean = true;
  @Input() treeMaxHops: number = 5;
  @Input() searchParameterIds: { typeName: string, key: string }[] = [];
  @Input() hideRoot: boolean = false;
  @Input() singletonReferences: boolean = false;
  @Input() singletonReferencesLabels: string[] = [];
  @Input() deleteWtihChildrenEnabled: boolean = false;
  @Input() sensitiveNodes : string [] = [];
  @Input() showNodeTypes : boolean = false;
  @Input() alwaysSelect : boolean = false;
  @Input() eventLogdEnabled: boolean = false;
  @Input() loadTreeNodeTypeIds : string [] = [];

  private _rootNodes: GrpcNode[] = [];
  @Input()
  get rootNodes(): GrpcNode[] {
    return this._rootNodes;
  }
  set rootNodes(nodes: GrpcNode[]) {
    this._rootNodes = nodes;
    if (this._rootNodes?.length > 0) {
      this.initDataSourceInstance(false);
    }
  }

  @Input() dropDownItems: DropDownItem[] = [];

  @Output() onTreeNodeSelect = new EventEmitter<{ treeNode: XconfTreeNode, nodeType: GrpcNodeType }>();
  @Output() onSaveTreeNode = new EventEmitter<{ treeNode: XconfTreeNode, nodeType: GrpcNodeType }>();
  @Output() onDeleteTreeNode = new EventEmitter<{ treeNode: XconfTreeNode, nodeType: GrpcNodeType }>();

  dataSourceDefinition: GrpcDataSourceDefinition;

  treeview: XconfTreeview;// = new Treeview();

  root$: Promise<XconfTreeNode[]>;

  private rootSubject = new Subject<XconfTreeNode[]>();

  nodeTypes: GrpcNodeType[] = [];
  nodeTypesById: Map<string, GrpcNodeType> = new Map<string, GrpcNodeType>();
  edgeTypes: GrpcEdgeType[] = [];
  rootNodeTypes: GrpcNodeType[] = [];

  //tree: GetTreeResponse;

  currentItem: XconfTreeNode;
  currentNodeType: GrpcNodeType;
  selectedItems: XconfTreeNode[] = [];
  selectedItemsHasSameParent: boolean = false;
  currentItemCommands : GrpcNodeCommand[] = [];

  showAddModal: boolean = false;
  addNodeTreeNodeDefinitions: GrpcTreeNodeDefinition[];
  addNodeTypeChildNodeTypes: GrpcNodeType[];

  showDashboardModal : boolean = false;
  dashboardOutputParameters: DashboardOutputChangeParameters[] = [];
  dashboardId: string;
  systemDashboard : boolean = false;
  dashboardResponsiveWidth: number = 1100;

  toAdd: {
    node: GrpcNode,
    parent: XconfTreeNode,
    reference: GrpcTreeNodeReferenceDefinition,
    edgeType: GrpcEdgeType,
    comment: string
  };

  showEditModal: boolean = false;
  saveState: ClrLoadingState = ClrLoadingState.DEFAULT;

  showMoveModal: boolean = false;
  moveInProgress: boolean = false;
  moveToItem: XconfTreeNode = null;

  showReadoutModal: boolean = false;
  readoutNode: GrpcNode;

  showNodeLogsModal : boolean = false;

  initiated: boolean = false;
  lock = new AsyncLock({ timeout: 20000 });
  lockkey: string = 'configdatasourceinitkey';
  lockkey2: string = 'configdatasourcegetchildnodeskey';

  constructor(private xConfClient: XProjectorXConfClient,
    private alertService: XprojAlertService,
    public xprojClient: XProjectorClient,
    private readonly cache: CacheService,
    private modalService: XprojModalService,
    private viewportScroller: ViewportScroller,
    private cdr: ChangeDetectorRef) {
  }

  async ngAfterViewInit() {
    //this.root$ = this.rootSubject.asObservable();
  }

  async ngOnInit() {
    //this.initDataSourceInstance();
    //this.root$ = this.getRootNodes();
    //this.rootSubject.next(this.treeview.children);
  }

  ngAfterContentInit(): void {
    //this.root$ = this.getRootNodes();
  }

  async initDataSourceInstance(updateDataSourceDefinition: boolean = true, sendEvent: boolean = true) {
    this.lock.acquire(this.lockkey, async () => {
      this.initiated = false;
      if ((updateDataSourceDefinition || !this.dataSourceDefinition) && this._dataSourceInstance.dataSourceDefinitionId?.length > 0) {
        this.dataSourceDefinition = await this.xConfClient.getDataSourceDefinition(this._dataSourceInstance.dataSourceDefinitionId, true);
        if (this.dataSourceDefinition) {
          this.nodeTypes = await this.xConfClient.getNodeTypes()
          this.edgeTypes = await this.xConfClient.getEdgeTypes();
          this.rootNodeTypes = this.nodeTypes.filter(nodetype => this.dataSourceDefinition.rootNodeTypeIds.includes(nodetype.id));

          this.nodeTypesById.clear();
          this.nodeTypes.forEach(nt => {
            this.nodeTypesById[nt.id] = nt;
          });
        }
      }

      this.treeview = new XconfTreeview();
      this.treeview.children = [];
      if (this.dataSourceDefinition) {

        if (this._rootNodes.length == 0) {
          this._rootNodes = await this.xConfClient.getDataSourceInstanceRootNodes(this._dataSourceInstance.id);
          if (this.hideRoot) {
            let roots: GrpcNode[] = [];
            await ArrayUtils.AsyncForEach(this._rootNodes, async (node: GrpcNode) => {
              let children = await this.xConfClient.getReferencedNodes(node.id, node.nodeTypeLabel, []);
              children.forEach(c => roots.push(c));
            });
            this._rootNodes = roots;
          }
        }

        if (this._rootNodes.length > 2) {
          this.lazy = true;
        }

        if (this.selectedPath?.length > 0 && !this.lazy && this._rootNodes.length > 0) {
          await ArrayUtils.AsyncForEach(this._rootNodes, async(root) => {
            let tree = await this.xConfClient.getTree(root.id, root.nodeTypeLabel, this.treeMaxHops);
            let treenode = new XconfTreeNode();
            treenode.node = tree.root;
            treenode.id = treenode.node.id;
            treenode.name = treenode.node.name || treenode.node.id;
            treenode.shape = this.getTreeNodeShape(tree.root.nodeTypeId);
            treenode.expanded = this.expandRoot;
            this.treeview.children.push(treenode);
            await this.addChildren(treenode, tree.children);
          });
        }
        else {
          let first : boolean = this.selectedPath?.length == 0;
          this._rootNodes.forEach(r => {
            let treenode = new XconfTreeNode();
            treenode.node = r;
            treenode.id = treenode.node.id;
            treenode.name = treenode.node.name || treenode.node.id;
            treenode.shape = this.getTreeNodeShape(r.nodeTypeId);
            treenode.expanded = this.expandRoot && first;
            first = false;
            this.treeview.children.push(treenode);
          })
        }

        await this.updateSelectedPath(sendEvent);
      }

      this.root$ = Promise.resolve(this.treeview.children);

      if (this.treeview.children.length > 0) {
        let id = this.treeview.children[0].id;
        let i = id.lastIndexOf('_');
        if (i > 0) {
          this.customerId = id.substring(i + 1);
        }
      }

      this.initiated = true;
    }).then(() => {
      // lock released
    });
  }

  async updateSelectedPath(sendEvent: boolean = true) {
    if (this.selectedPath?.length > 0) {
      let pathIndex = 0;
      let root = this.treeview.children.find(child => child.id == this.selectedPath[pathIndex]);
      if (root) {
        root.expanded = true;
        root.children = await this.getChildren(root);
        pathIndex++;
        var parent: XconfTreeNode = root;
        while (pathIndex < this.selectedPath.length - 1) {
          let child = parent.children.find(child => child.id == this.selectedPath[pathIndex]);
          if (child) {
            pathIndex++;
            child.expanded = true;
            // if (this.lazy) {
            //   this.cdr.detectChanges();
            // }
            child.children = await this.getChildren(child, this.lazy);
            parent = child;
          }
          else {
            break;
          }
        }

        if (pathIndex < this.selectedPath.length) {
          let child = parent.children.find(child => child.id == this.selectedPath[pathIndex]);
          if (child) {
            this.selectedItems = [child];
            this.selectedItemsHasSameParent = true;
            this.currentItem = child;
            this.currentNodeType = this.nodeTypes.find(nodeType => nodeType.id == child.node.nodeTypeId);
            if (sendEvent) {
              this.onTreeNodeSelect?.next({
                treeNode: child,
                nodeType: this.currentNodeType
              });
            }
            this.viewportScroller.scrollToAnchor(child.id);
          }
        }
      }
    }
  }

  findNode(nodeId: string): XconfTreeNode {
    let result: XconfTreeNode = null;
    if (this.treeview && this.treeview.children) {
      for (let child of this.treeview.children) {
        result = this._findNode(child, nodeId);
        if (result != null) {
          break;
        }
      }
    }

    return result;
  }

  async refreshTreeView() {
    await this.initDataSourceInstance(false);
  }

  private _findNode(node: XconfTreeNode, nodeId: string): XconfTreeNode {
    let result: XconfTreeNode = null;
    if (node.id == nodeId) {
      result = node;
    }
    else if (node.children && node.children.length > 0) {
      for (let child of node.children) {
        result = this._findNode(child, nodeId);
        if (result != null) {
          break;
        }
      }
    }

    return result;
  }

  async addChildren(parent: XconfTreeNode, grpcChildren: GrpcTreeNode[]) {
    parent.children = [];
    await ArrayUtils.AsyncForEach(grpcChildren, async (grpcChild: GrpcTreeNode) => {
      let child = new XconfTreeNode();
      child.node = grpcChild.node;
      child.id = grpcChild.node.id;
      parent.children.push(child);
      child.parent = parent;
      child.edgeType = this.edgeTypes.find(edge => edge.id == grpcChild.edgeTypeId);
      child.name = await this.getNodeDisplayName(grpcChild.node);
      child.shape = this.getTreeNodeShape(grpcChild.node.nodeTypeId);
      if (this.moveEnabledNodeTypeIds) {
        child.moveEnabled = this.moveEnabledNodeTypeIds.length == 0 || this.moveEnabledNodeTypeIds.findIndex(id => id == grpcChild.node.nodeTypeId) > -1;
      }
      else {
        child.moveEnabled = true;
      }

      if (this.duplicateEnabledNodeTypeIds) {
        child.duplicateEnabled = this.duplicateEnabledNodeTypeIds.length == 0 || this.duplicateEnabledNodeTypeIds.findIndex(id => id == grpcChild.node.nodeTypeId) > -1;
      }
      else {
        child.duplicateEnabled = true;
      }

      child.children = [];
      if (grpcChild.children.length > 0) {
        await this.addChildren(child, grpcChild.children);
      }
    });
  }

  getChildren = this.getChildNodes.bind(this);

  async getChildNodes(treenode: XconfTreeNode, force: boolean = false): Promise<XconfTreeNode[]> {
    try {
      if (treenode && treenode.node && !treenode.edgeType?.isReference) {
        if (!treenode.children || force) {

          if (!this.lazy || this.loadTreeNodeTypeIds.findIndex(x => x == treenode.node.nodeTypeId) > -1) {
            let tree : GetTreeResponse;
            await this.lock.acquire(this.lockkey2, async () => {
              tree = await this.xConfClient.getTree(treenode.node.id, treenode.node.nodeTypeLabel, this.treeMaxHops);
            });
            if (tree) {
              await this.addChildren(treenode, tree.children);
            }
          }
          else {
            treenode.children = [];
            let treenodeDefinition = this.dataSourceDefinition?.treeNodeDefinitions.find(tdef => tdef.nodeTypeId == treenode.node.nodeTypeId);

            if (treenodeDefinition) {
              if (treenodeDefinition.treeNodeReferenceDefinitions.length > 0) {
                let nodes = [];
                await this.lock.acquire(this.lockkey2, async () => {
                  nodes = await this.xConfClient.getReferencedNodes(treenode.node.id, treenode.node.nodeTypeLabel, []);
                });
                await ArrayUtils.AsyncForEach(nodes, async (n) => {
                  let child = new XconfTreeNode();
                  child.node = n;
                  child.id = n.id;
                  treenode.children.push(child);
                  child.parent = treenode;
                  child.edgeType = this.edgeTypes.find((edge) => edge.id == treenodeDefinition.treeNodeReferenceDefinitions.find(ref => ref.nodeTypeId == n.nodeTypeId || ref.nodeTypeId == n.nodeTypeLabel)?.edgeTypeId);
                  child.name = await this.getNodeDisplayName(n);
                  child.shape = this.getTreeNodeShape(n.nodeTypeId);
                  if (this.moveEnabledNodeTypeIds) {
                    child.moveEnabled = this.moveEnabledNodeTypeIds.length == 0 || this.moveEnabledNodeTypeIds.findIndex(id => id == n.nodeTypeId) > -1;
                  }
                  else {
                    child.moveEnabled = true;
                  }

                  if (this.duplicateEnabledNodeTypeIds) {
                    child.duplicateEnabled = this.duplicateEnabledNodeTypeIds.length == 0 || this.duplicateEnabledNodeTypeIds.findIndex(id => id == n.nodeTypeId) > -1;
                  }
                  else {
                    child.duplicateEnabled = true;
                  }

                });
              }
            }
          }
        }

        return Promise.resolve(treenode.children.sort((a, b) => {
          if (this.sortByName) {
            if (this.sortPaddingZeros <= 0) {
              return a.name > b.name ? 1 : -1;
            }
            else {
              return a.name.padStart(this.sortPaddingZeros, '0') > b.name.padStart(this.sortPaddingZeros, '0') ? 1 : -1;
            }
          }
          else {
            return a.id > b.id ? 1 : -1;
          }
        }));
      }
      else {
        return Promise.resolve([]);
      }
    }
    catch {
      return Promise.resolve([]);
    }
  };

  getTreeNodeShape(nodeTypeId: string): string {
    let result = 'node';
    let nodeType = this.nodeTypes.find(nt => nt.id == nodeTypeId);
    //if (this.nodeTypesById.has(nodeTypeId)) {
    //  var nodeType = this.nodeTypesById[nodeTypeId];
    if (nodeType) {
      if (nodeType.shape?.length > 0) {
        result = nodeType.shape;
      }
    }
    //}

    return result;
  }

  async addRootNode(nodetype: GrpcNodeType): Promise<boolean> {
    let root = new GrpcNode();
    root.nodeTypeId = nodetype.id;
    root.id = Guid.newGuid();
    root.nodeTypeLabel = nodetype.label;

    let result = await this.xConfClient.addDataSourceInstanceRootNode(this._dataSourceInstance.id, root);
    if (result.result) {
      let treenode = new XconfTreeNode();
      treenode.node = root;
      treenode.id = treenode.node.id;
      treenode.name = await this.getNodeDisplayName(root);
      this.treeview.children.push(treenode);
      this.initiated = false;
      setTimeout(() => {
        this.initiated = true;
      });
    }

    return result.result;
  }

  async addChildNode(parent: GrpcNode, child: GrpcNode, edgeTypeId: string, edgeIsReference: boolean, comment: string = ''): Promise<GrpcNode> {
    if (!edgeIsReference) {
      let result = await this.xConfClient.createReferencedNode(child, parent.id, parent.nodeTypeLabel, edgeTypeId, edgeIsReference, null, comment, this.customerId);
      if (result.result) {
        return result.node;
      } else {
        return null;
      }

    }
    else {
      let result = await this.xConfClient.createReference(parent.id, parent.nodeTypeLabel, child.id, child.nodeTypeLabel, edgeTypeId, edgeIsReference, null, comment, this.customerId);
      if (result.result) {
        return child;
      } else {
        return null;
      }
    }
  }

  async deleteNode(treenode: XconfTreeNode) {
    let result: BasicResponse;
    if (!this.deleteWtihChildrenEnabled && !treenode.edgeType?.isReference && treenode.children?.length > 0) {
      this.alertService.error('Delete node with children not allowed!');
    }
    else {
      this.modalService.ShowConfirmModal({ header: 'Delete node (' + treenode?.name + ')', description: 'Delete selected node, are you sure?' }, async (confirmed) => {
        if (confirmed) {
          if (treenode.edgeType?.isReference) {
            let parent: XconfTreeNode = treenode.parent as XconfTreeNode;
            result = await this.xConfClient.deleteReference(parent.node.id, parent.node.nodeTypeLabel, treenode.node.id, treenode.node.nodeTypeLabel,
                      treenode.edgeType.id, '', this.customerId);
          }
          else {
            result = await this.xConfClient.deleteNode(treenode.node.id, treenode.node.nodeTypeLabel, '', this.customerId);
          }

          if (result.result) {
            this.onDeleteTreeNode?.next({
              treeNode: this.currentItem,
              nodeType: this.currentNodeType
            });
            if (treenode.parent) {
              treenode.parent.children = treenode.parent.children.filter(n => n != treenode);

              treenode.parent.expanded = false;
              this.cdr.detectChanges();
              treenode.parent.expanded = true;

              if (this._rootNodes?.length > 0) {
                this.cache.remove(this._rootNodes[0].id);
              }
            }
            else {
              //root node
              this.treeview.children = this.treeview.children.filter(node => node != treenode);

              this.initiated = false;
              this.cdr.detectChanges();
              this.initiated = true;
            }
          }
          else {
            this.alertService.error('Error delete node: ' + result.message);
          }
        }
      });
    }
  }

  async deleteNodes(treenodes: XconfTreeNode[]) {
    let result: BasicResponse;
    if (treenodes?.length > 0) {
      this.modalService.ShowConfirmModal({ header: 'Delete nodes ( Count: ' + treenodes?.length + ')', description: 'Delete selected nodes, are you sure?' }, async (confirmed) => {
        if (confirmed) {
          await ArrayUtils.AsyncForEach(treenodes, async (treenode) => {
            if (!this.deleteWtihChildrenEnabled && !treenode.edgeType?.isReference && treenode.children?.length > 0) {
              this.alertService.error('Delete node with children not allowed!');
            }
            else {
              if (treenode.edgeType?.isReference) {
                let parent: XconfTreeNode = treenode.parent as XconfTreeNode;
                result = await this.xConfClient.deleteReference(parent.node.id, parent.node.nodeTypeLabel, treenode.node.id,
                      treenode.node.nodeTypeLabel, treenode.edgeType.id, '', this.customerId);
              }
              else {
                result = await this.xConfClient.deleteNode(treenode.node.id, treenode.node.nodeTypeLabel, '', this.customerId);
              }

              if (result.result) {
                this.onDeleteTreeNode?.next({
                  treeNode: this.currentItem,
                  nodeType: this.currentNodeType
                });
                if (treenode.parent) {
                  treenode.parent.children = treenode.parent.children.filter(n => n != treenode);

                  if (!this.selectedItemsHasSameParent) {
                    treenode.parent.expanded = false;
                    this.cdr.detectChanges();
                    treenode.parent.expanded = true;
                  }
                }
                else {
                  //root node
                  this.treeview.children = this.treeview.children.filter(node => node != treenode);

                  this.initiated = false;
                  this.cdr.detectChanges();
                  this.initiated = true;
                }
              }
              else {
                this.alertService.error('Error delete node: ' + result.message);
              }
            }
          });
          if (this.selectedItemsHasSameParent) {
            treenodes[0].parent.expanded = false;
            this.cdr.detectChanges();
            treenodes[0].parent.expanded = true;
          }
          if (this._rootNodes?.length > 0) {
            this.cache.remove(this._rootNodes[0].id);
          }

        }
      });
    }
  }

  async updateNode(node: GrpcNode, oldId: string): Promise<boolean> {
    let result = await this.xConfClient.updateNode(node, oldId, '', this.customerId);
    return result.result;
  }

  addNode(parent: XconfTreeNode) {

    let treenodeDefinition = this.dataSourceDefinition?.treeNodeDefinitions.find(td => td.nodeTypeId == parent.node.nodeTypeId);
    this.addNodeTypeChildNodeTypes = this.nodeTypes.filter(nt => {
      return treenodeDefinition?.treeNodeReferenceDefinitions.findIndex(tref => tref.nodeTypeId == nt.id) > -1;
    });

    if (this.addNodeTypeChildNodeTypes.length > 0) {
      let nodeToAdd = new GrpcNode();
      nodeToAdd.nodeTypeId = this.addNodeTypeChildNodeTypes[0].id;
      nodeToAdd.nodeTypeLabel = this.addNodeTypeChildNodeTypes[0].label;
      //nodeToAddParent = parent;

      let ref = this.dataSourceDefinition?.treeNodeDefinitions.find(td => td.nodeTypeId == parent.node.nodeTypeId).treeNodeReferenceDefinitions.find(tref => tref.nodeTypeId == nodeToAdd.nodeTypeId);
      this.toAdd = { node: nodeToAdd, parent: parent, reference: ref, edgeType: this.edgeTypes.find(e => e.id == ref.edgeTypeId), comment: '' };

      this.showAddModal = true;
    }

    if (this.currentItem) {
      //@ts-ignore
      this.currentItem.openDropDown = false;
    }
  }

  async duplicateNode(node: XconfTreeNode) {
    let nodeType = this.nodeTypes.find(n => n.id == node.node.nodeTypeId);

    let oldId = node.node.id;
    let copy;
    try {
      if (nodeType.autoGenerateId) {
        node.node.id = '';
      } else {
        node.node.id += '_copy';
      }

      let parent: XconfTreeNode = node.parent as XconfTreeNode;
      copy = await this.addChildNode(parent.node, node.node, node.edgeType.id, node.edgeType.isReference);
    }
    finally {
      //@ts-ignore
      node.openDropDown = false;
      node.node.id = oldId;
    }

    if (copy) {
      let treenode = new XconfTreeNode();
      treenode.node = await this.xConfClient.getNode(copy.id, node.node.nodeTypeLabel);
      treenode.id = treenode.node.id;
      treenode.parent = node.parent;
      treenode.edgeType = node.edgeType;
      treenode.name = await this.getNodeDisplayName(treenode.node);
      treenode.shape = this.getTreeNodeShape(treenode.node.nodeTypeId);
      treenode.moveEnabled = node.moveEnabled;
      treenode.duplicateEnabled = node.duplicateEnabled;
      node.parent.children.push(treenode);
      node.parent.expanded = !node.parent.expanded;
      let parent = node.parent;
      setTimeout(() => {
        parent.expanded = !parent.expanded;
      });
      this.alertService.info('Node duplicated.');
      if (this._rootNodes?.length > 0) {
        this.cache.remove(this._rootNodes[0].id);
      }
    }
    else {
      this.alertService.error('Error duplicate node!');
    }

  }

  selectItem(item: XconfTreeNode, $event) {
    //console.log('selectItem', $event);
    // @ts-ignore
    if ($event.button == 0 && !item.openDropDown) {
      if (this.currentItem && ($event.ctrlKey || $event.metaKey)) {
        let sameNodeType: boolean = true;
        let sameNodeParent: boolean = true;
        this.selectedItems.forEach(it => {
          if (it.node.nodeTypeId != item.node.nodeTypeId) {
            sameNodeType = false;
          }
          if (it.parent != item.parent) {
            sameNodeParent = false;
          }

        });
        if (sameNodeType) {
          this.selectedItemsHasSameParent = sameNodeParent;
          if (this.selectedItems.indexOf(item) < 0) {
            this.selectedItems.push(item);
          }
          else {
            this.selectedItems = this.selectedItems.filter(it => it != item);
          }
          if ($event) {
            $event.stopPropagation();
            $event.preventDefault();
          }
        }
      }
      else if (this.currentItem && $event.shiftKey) {
        let sameNodeType: boolean = true;
        let sameNodeParent: boolean = true;
        if (this.currentItem.node.nodeTypeId != item.node.nodeTypeId) {
          sameNodeType = false;
        }
        if (this.currentItem.parent != item.parent) {
          sameNodeParent = false;
        }
        if (sameNodeType) {
          this.selectedItems = [];
          this.selectedItemsHasSameParent = sameNodeParent;

          if (this.currentItem.parent) {
            let foundCurrentItem : boolean = false;
            let foundItem : boolean = false;
            this.currentItem.parent.children.forEach(child => {
              if (!foundCurrentItem) {
                foundCurrentItem = child == this.currentItem;
              }
              if ((foundCurrentItem && !foundItem) || (!foundCurrentItem && foundItem)) {
                this.selectedItems.push(child);
              }
              if (!foundItem) {
                foundItem = child == item;
              }
            });
            if (this.selectedItems.indexOf(this.currentItem) < 0) {
              this.selectedItems.push(this.currentItem);
            }
            if (this.selectedItems.indexOf(item) < 0) {
              this.selectedItems.push(item);
            }
          }
        }
      }
      else {
        if (this.selectedItems.length > 1 || this.selectedItems.indexOf(item) < 0 || this.alwaysSelect ){
          this.currentItem = item;
          this.currentNodeType = this.nodeTypes.find(nodeType => nodeType.id == item.node.nodeTypeId);
          this.selectedItems = [item];

          this.updateModalDashboardData(item, this.currentNodeType);

          this.onTreeNodeSelect?.next({
            treeNode: item,
            nodeType: this.currentNodeType
          });
        }
        // @ts-ignore
        else if (!item.openDropDown && !this.showEditModal){
          this.currentItem = null;
          this.selectedItems = [];
        }
      }
    }
    else {
      if (this.selectedItems.length < 2) {
        if (this.selectedItems.indexOf(item) < 0 || this.alwaysSelect ){
          this.currentItem = item;
          this.currentNodeType = this.nodeTypes.find(nodeType => nodeType.id == item.node.nodeTypeId);
          this.selectedItems = [item];

          this.updateModalDashboardData(item, this.currentNodeType);

          this.onTreeNodeSelect?.next({
            treeNode: item,
            nodeType: this.currentNodeType
          });
        }
      }
    }
  }

  updateModalDashboardData(treeNode : XconfTreeNode, nodeType : GrpcNodeType) {
    this.dashboardId = '';
    let outputs: DashboardOutputChangeParameters[] = [];

    if (treeNode) {
      treeNode.node.propertyValues.forEach(p => {
        if (p.valueType == GrpcNodeProperty.ValueTypes.DASHBOARD) {
          this.dashboardId = p.value;
          let prop = nodeType.dashboardProperties.find(prop => prop.id == p.key);
          if (prop) {
            this.systemDashboard = prop.systemDashboard;
          }
        } else {
          let out = new DashboardOutputChangeParameters();
          out.outputParameterName = p.key;
          out.value = p.value;
          outputs.push(out);
        }
      })

      let out = new DashboardOutputChangeParameters();
      out.outputParameterName = 'id';
      out.value = treeNode.id;
      outputs.push(out);
    }


    if (this.dashboardId?.length > 0) {
      this.dashboardOutputParameters = outputs;
    }
  }

  showModalDashboard() {
    if (this.dashboardId?.length > 0) {
      this.showDashboardModal = true;

      setTimeout(async () => {
        await this.xprojDashboard?.setDashboardOutputParameters(this.dashboardOutputParameters);
        await this.xprojDashboard?.setDashboardId(this.dashboardId, -1, '', this.systemDashboard);
      });
    }
  }

  onRightClick($event) {
    if (this.currentItem) {
      this.currentItemCommands = [];
      if (this.currentNodeType?.supportsNodeCommands) {
        this.xConfClient.getNodeCommands(this.currentItem.node.id, this.currentItem.node.nodeTypeLabel).then(response => {
          this.currentItemCommands = response.commands;
        }).catch(error => {

        })
      }
      //this.onOpenEditMenu(this.currentItem);
      // @ts-ignore
      this.currentItem.openDropDown = false;
      setTimeout(() => {
        // @ts-ignore
        this.currentItem.openDropDown = true;
      });
    }

    return false;
  }

  onNodeTypeSelect(nodeTypeId: string) {
    let nodeType = this.nodeTypes.find(nt => nt.id == nodeTypeId);

    if (nodeType) {
      this.toAdd.node = new GrpcNode();
      this.toAdd.node.nodeTypeId = nodeTypeId;
      this.toAdd.node.nodeTypeLabel = nodeType.label;

      this.toAdd.reference = this.dataSourceDefinition?.treeNodeDefinitions.find(td => td.nodeTypeId == this.toAdd.parent.node.nodeTypeId).treeNodeReferenceDefinitions.find(tref => tref.nodeTypeId == nodeTypeId);
      this.toAdd.edgeType = this.edgeTypes.find(e => e.id == this.toAdd.reference.edgeTypeId);
    }
  }

  async saveAddedNode() {
    if (this.toAdd?.node && this.toAdd?.parent) {
      //if (this.toAdd.node.id?.length > 0) {
      this.addTreeNode?.savePropertyValues();
      let treeNodeReferenceDefinition = this.dataSourceDefinition?.treeNodeDefinitions.find(td => td.nodeTypeId == this.toAdd.parent.node.nodeTypeId).treeNodeReferenceDefinitions.find(ref => ref.nodeTypeId == this.toAdd.node.nodeTypeId);

      if (treeNodeReferenceDefinition) {
        let edgeType = this.edgeTypes.find(e => e.id == treeNodeReferenceDefinition.edgeTypeId);
        let oldTreenode: XconfTreeNode = null;
        let singletonReference = this.singletonReferences && edgeType.isReference && (this.singletonReferencesLabels.length == 0 || this.singletonReferencesLabels.findIndex(x => x == this.toAdd.node.nodeTypeLabel) > -1);
        if (singletonReference) {

          let nodeExcists: boolean = false;
          await ArrayUtils.AsyncForEach(this._rootNodes, async (r) => {
            if (!nodeExcists) {
              let request = new SearchNodesRequest();
              request.rootId = r.id;
              request.rootLabel = r.nodeTypeLabel;
              request.limit = 1;
              request.maxHops = 10;
              request.skip = 0;
              request.label = this.toAdd.node.nodeTypeLabel;

              let idSearchProperty = new SearchProperty();
              idSearchProperty.typeName = 'string';
              idSearchProperty.key = '_id';
              idSearchProperty.value = this.toAdd.node.id;
              idSearchProperty.operator = SearchProperty.SearchPropertyOperator.Equals;
              request.properties.push(idSearchProperty);

              let result = await this.xConfClient.searchNodes(request);
              if (result.nodes.length > 0) {
                nodeExcists = true;
              }
            }
          });

          if (nodeExcists) {
            this.showAddModal = false;
            let addcommentResult = await this.modalService.ShowInputModalAsync({
              header: 'Move node',
              description: 'Input comment:',
              value: '',
              ok: 'Add comment',
              cancel: 'Continue without comment'
            });
            if (addcommentResult.result) {
              this.toAdd.comment = addcommentResult.value;
            }
          }
        }

        let result = await this.addChildNode(this.toAdd.parent.node, this.toAdd.node, treeNodeReferenceDefinition.edgeTypeId, edgeType.isReference, this.toAdd.comment);

        if (result) {
          if (singletonReference) {
            let oldTreenode = this.findNode(this.toAdd.node.id);
            if (oldTreenode != null) {
              oldTreenode.parent.children = oldTreenode.parent.children.filter(child => child.id != oldTreenode.id);
              oldTreenode.parent.expanded = !oldTreenode.parent.expanded;
              let parent = oldTreenode.parent;
              setTimeout(() => {
                parent.expanded = !parent.expanded;
              });
            }
          }

          let treenode = new XconfTreeNode();
          treenode.node = await this.xConfClient.getNode(result.id, this.toAdd.node.nodeTypeLabel);
          treenode.id = result.id;
          treenode.parent = this.toAdd.parent;
          treenode.edgeType = edgeType;
          treenode.name = await this.getNodeDisplayName(treenode.node);
          treenode.shape = this.getTreeNodeShape(treenode.node.nodeTypeId);
          this.toAdd.parent.children.push(treenode);
          this.toAdd.parent.expanded = !this.toAdd.parent.expanded;
          let parent = this.toAdd.parent;
          setTimeout(() => {
            parent.expanded = !parent.expanded;
          });
          this.alertService.info('Node added.');
          if (this._rootNodes?.length > 0) {
            this.cache.remove(this._rootNodes[0].id);
          }
        }
        else {
          this.alertService.error('Error add node!');
        }
      }
      // } else {
      //   this.alertService.error('No node id set!');
      // }

      this.closeAddNode();
    }

  }

  closeAddNode() {
    this.showAddModal = false;
    this.toAdd = null;
  }

  editNode(node: XconfTreeNode) {
    if (this.editEnabled) {
      this.showEditModal = true;
    }
    if (this.currentItem) {
      //@ts-ignore
      this.currentItem.openDropDown = false;
    }
  }

  dblClick(node : XconfTreeNode, $event) {
    if (this.editEnabled) {
      if (node && this.selectedItems.indexOf(node) < 0 ) {
        this.selectItem(node, $event);
      }
      this.showEditModal = true;
    }
  }

  async saveEditNode() {
    if (this.editTreeNode) {
      try {
        this.saveState = ClrLoadingState.LOADING;
        this.editTreeNode.savePropertyValues();
        this.onSaveTreeNode?.next({
          treeNode: this.currentItem,
          nodeType: this.currentNodeType
        });
        let result = await this.updateNode(this.currentItem.node, this.currentItem.id);
        if (result) {
          this.currentItem.node = await this.xConfClient.getNode(this.currentItem.node.id, this.currentItem.node.nodeTypeLabel);
          let rootNode = this._dataSourceInstance.rootNodeInstances.find(r => r.id == this.currentItem.id);
          if (rootNode) {
            rootNode.id = this.currentItem.node.id;
            await this.xConfClient.upsertDataSourceInstance(this._dataSourceInstance);
          }
          this.currentItem.id = this.currentItem.node.id;
          this.currentItem.name = await this.getNodeDisplayName(this.currentItem.node);
          this.alertService.info('Node updated.');
          if (this._rootNodes?.length > 0) {
            this.cache.remove(this._rootNodes[0].id);
          }
        }
        else {
          this.currentItem.node.id = this.currentItem.id;
          this.alertService.error('Error update node!');
        }
      }
      finally {
        this.saveState = ClrLoadingState.SUCCESS;
      }
    }

    this.showEditModal = false;
  }

  async refresh() {
    await this.xprojDashboard?.refresh();
  }

  closeEditNode() {
    this.showEditModal = false;
  }

  onAddSelectReference(node: GrpcNode) {
    if (this.toAdd) {
      this.toAdd.node.id = node ? node?.id : '';
    }
  }

  getNodeDisplayNameCallback = (node: GrpcNode) => {
    return this.getNodeDisplayName(node);
  }

  async getNodeDisplayName(node: GrpcNode): Promise<string> {
    if (node) {
      let nodeType = this.nodeTypes.find(n => n.id == node.nodeTypeId);
      if (nodeType && nodeType.namePropertyId?.length > 0) {
        let result: string[] = [];
        let namePropertyIds = nodeType.namePropertyId.split(',');

        await ArrayUtils.AsyncForEach(namePropertyIds, async (namePropertyId) => {
          if (namePropertyId == 'name') {
            result.push(node.name);
          }
          else {
            let propValue = node.propertyValues.find(v => v.key == namePropertyId);
            if (propValue) {
              let p = nodeType.stringProperties.find(strprop => strprop.id == nodeType.namePropertyId);
              if (p && p.projection) {
                let projection = await this.xprojClient.RequestGetConfigProjection(propValue.value);
                if (projection) {
                  result.push(projection.name);
                }
              }
              if (propValue.value?.length > 0) {
                result.push(propValue.value);
              }
            }
          }
        });

        if (result.length > 0) {
          return Promise.resolve(nodeType.namePrefix + result.join(', '));
        }
      }

      return Promise.resolve(node.name || node.id);
    }

    return Promise.resolve("");
  }

  moveItem(item) {
    this.currentItem = item;
    this.moveToItem = null;
    this.showMoveModal = true;
    this.moveInProgress = false;
  }

  async doMoveNode() {
    try {
      this.moveInProgress = true;
      this.cdr.detectChanges();
      let oldParent: XconfTreeNode;
      await ArrayUtils.AsyncForEach(this.selectedItems, async (item) => {
        if (item && this.moveToItem) {
          item.openDropDown = false;
          if (item != this.moveToItem
            && item.parent != this.moveToItem && !this.isChildOf(this.moveToItem, item)) {

            oldParent = item.parent as XconfTreeNode;

            let edgeType: GrpcEdgeType;
            try {
              let treeNodeReferenceDefinition = this.dataSourceDefinition?.treeNodeDefinitions.find(td => td.nodeTypeId == this.moveToItem.node.nodeTypeId).treeNodeReferenceDefinitions.find(ref => ref.nodeTypeId == this.currentItem.node.nodeTypeId);
              if (treeNodeReferenceDefinition) {
                edgeType = this.edgeTypes.find(e => e.id == treeNodeReferenceDefinition.edgeTypeId);
              }
            }
            catch { }

            let result = await this.xConfClient.moveNode(item.node, oldParent.node.id, oldParent.node.nodeTypeLabel, this.moveToItem.node.id,
                      this.moveToItem.node.nodeTypeLabel, edgeType.id, edgeType.isReference, null, '', this.customerId);

            if (result.result) {
              oldParent.children = oldParent.children.filter((i) => i.id != item.id);
              this.moveToItem.children.push(item);
              item.parent = this.moveToItem;

              item.node = await this.xConfClient.getNode(item.node.id, item.node.nodeTypeLabel)
            }
            else {
              this.alertService.error("Can't move '" + item.name + "' to '" + this.moveToItem.name + "': " + result.message);
            }

            if (!this.selectedItemsHasSameParent) {
              oldParent.expanded = !oldParent.expanded;
              this.moveToItem.expanded = !this.moveToItem.expanded;
              this.cdr.detectChanges();
              oldParent.expanded = !oldParent.expanded;
              this.moveToItem.expanded = !this.moveToItem.expanded;
            }
          }
          else {
            this.alertService.error(this.currentItem.name + "' is not allowed under '" + this.moveToItem.name + "'! ");
          }
        }
        else {
          this.alertService.error("Can't move '" + this.currentItem.name + "' to '" + this.moveToItem.name + "'!");
        }
      });

      if (this.selectedItemsHasSameParent && oldParent) {
        oldParent.expanded = !oldParent.expanded;
        this.moveToItem.expanded = !this.moveToItem.expanded;
        this.cdr.detectChanges();
        oldParent.expanded = !oldParent.expanded;
        this.moveToItem.expanded = !this.moveToItem.expanded;
      }

    }
    finally {
      this.showMoveModal = false;
      this.moveInProgress = false;
    }
  }

  isChildOf(child: XconfTreeNode, parent: XconfTreeNode): boolean {
    if (parent.children?.find(c => c.id == child.id)) {
      return true;
    }
    else {
      let result = false;
      parent.children?.forEach(c => {
        if (!result) {
          result = this.isChildOf(child, c);
        }
      })

      return result;
    }

  }

  async readoutMomentaryValues() {
    if (this.currentItem) {
      this.readoutNode = await this.xConfClient.getNode(this.currentItem.node.id, this.currentItem.node.nodeTypeLabel);
      this.showReadoutModal = true;
    }
  }

  async showNodeLogs() {
    if (this.currentItem) {
      this.readoutNode = await this.xConfClient.getNode(this.currentItem.node.id, this.currentItem.node.nodeTypeLabel);
      this.showNodeLogsModal = true;
    }
  }

  async executeCommmand(command : GrpcNodeCommand) {
    if (this.currentItem) {
      let commandItem = this.currentItem;
      //@ts-ignore
      this.currentItem.openDropDown = false;
      let doExecutee = true;
      if (command.confirmMessage?.length > 0) {
        doExecutee = await this.modalService.ShowConfirmModalAsync({
          header: 'Execute command',
          description: command.confirmMessage,
          ok: 'Ok',
          cancel: 'Cancel'
        });
      }

      if (doExecutee) {
        this.xConfClient.executeNodeCommand(commandItem.node.id, commandItem.node.nodeTypeLabel, command.id).then(async result => {
          if (result.ok) {
            this.alertService.info("Command executed ok: " + result.message);
            if (result.nodeUpdated) {
              commandItem.children = await this.getChildren(commandItem, true);
              commandItem.expanded = !commandItem.expanded;
              this.cdr.detectChanges();
              commandItem.expanded = !commandItem.expanded;
            }
          }
          else {
            this.alertService.error("Error execute command: " + result.message);
          }
        }).catch(error =>
          this.alertService.error("Error execute command: " + error)
        )
      }
    }
  }
}
