import { Component, OnInit } from '@angular/core';
import { ClrDatagridSortOrder, ClrLoadingState } from '@clr/angular';
import { XAUTO_DriverType, XAUTO_ModbusRTUMode, XAUTO_ModbusRTUParity, XAUTO_ModbusType, XAUTO_ModbusVariableByteOrder, XAUTO_ModbusVariableRegisterOrder, XAUTO_ModbusVariableType, XAUTO_XAutoDriver, XAUTO_XAutoDriverModbus, XAUTO_XAutoVariable, XAUTO_XAutoVariableModbus, XDataType, XProjectorClient } from '../../XProjector/xprojector-client-service';
import * as uuid from 'uuid';
import * as XLSX from 'xlsx';
import { NgxFileDropEntry } from 'ngx-file-drop';

export class ViewVariable{
  public Variable : XAUTO_XAutoVariable;
  public Modbus : XAUTO_XAutoVariableModbus;
}

export class ViewDriver{
  public Driver : XAUTO_XAutoDriver;
  public Modbus : XAUTO_XAutoDriverModbus;
  public Variables: Array<ViewVariable> = new Array<ViewVariable>();
}


@Component({
  selector: 'xproj-editconnectors-modbus',
  templateUrl: './editconnectors-modbus.component.html',
  styleUrls: ['./editconnectors-modbus.component.scss']
})
export class EditconnectorsModbusComponent implements OnInit {

  sizeOptions = [10, 20, 50, 100];
  sizeOptionsVars = [10, 20, 50, 100];
  ascSort = ClrDatagridSortOrder.ASC;
  public loadingConnectors : ClrLoadingState = ClrLoadingState.DEFAULT;
  savingDriver : ClrLoadingState = ClrLoadingState.DEFAULT;
  savingRemovingDriver : ClrLoadingState = ClrLoadingState.DEFAULT;
  savingRemovingVariable : ClrLoadingState = ClrLoadingState.DEFAULT;
  savingVariable : ClrLoadingState =ClrLoadingState.DEFAULT;

  XAUTO_ModbusType = XAUTO_ModbusType;
  XAUTO_ModbusVariableType = XAUTO_ModbusVariableType;
  XAUTO_ModbusVariableByteOrder = XAUTO_ModbusVariableByteOrder;
  XAUTO_ModbusVariableRegisterOrder = XAUTO_ModbusVariableRegisterOrder;

  XAUTO_ModbusRTUMode = XAUTO_ModbusRTUMode;
  XAUTO_ModbusRTUParity = XAUTO_ModbusRTUParity;

  XDataType = XDataType;
  public showAddConnector : boolean = false;

  public connectors = [];
  public selectedConnector : ViewDriver = null;
  public selectedVariable : ViewVariable = null;

  public showImport:boolean=false;
  public ExcelImportLog: string = "";
  public ExcelImportError:string ="";

  public generateNewIDsOnImport = true;

  public clearImportLog()
  {
    this.ExcelImportError = "";
    this.ExcelImportLog = "";
  }


  hashCode (str: string): string
  {
    // tslint:disable:no-bitwise
    let hash = 0;
    if (typeof str !== 'string' || str.length === 0) {
      return String(hash);
    }
    for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i);
      hash = ((hash << 5) - hash) + char;
      hash = hash & hash; // convert to 32bit integer
    }
    hash = hash >>> 0; // convert signed to unsigned https://stackoverflow.com/a/1908655
    return Number(hash).toString(32).toUpperCase(); // make the hash small, convert base10 to base32
    // tslint:enable:no-bitwise
  };

  async onImportDataExcel(files: NgxFileDropEntry[]) {
    this.ExcelImportLog = "";
    this.ExcelImportError = "";

    for(let droppedFile of files)
    {

      if (!droppedFile.fileEntry.isFile)
      {
        this.ExcelImportError = "Not a file";
        return;
      }

      const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;

      fileEntry.file(async (file: File) =>
      {
        try
        {
          this.ExcelImportLog += "Importing " + file.name +"\n";
          let buff = await file.arrayBuffer();
          const wb: XLSX.WorkBook = XLSX.read(buff, { type: 'binary' });

          const wsname: string = wb.SheetNames[0];
          const ws: XLSX.WorkSheet = wb.Sheets[wsname];

          const data = XLSX.utils.sheet_to_json(ws, {header: 1, raw: false}); // generate objects
          console.log("XLSX data", data);

          let header = data[0] as string[];
          let cnamei = {};
          for(let si = 0; si < header.length; si++)
          {
            let scol = header[si];
            cnamei[scol] = si;
          }


          for(let row = 1; row < data.length; row++)
          {
            this.ExcelImportLog += "Fetching row " + row.toString() +"\n";
            let mvar = new XAUTO_XAutoVariableModbus();
            let xvar = new XAUTO_XAutoVariable();

            for(let scol in xvar)
            {
              //this.ExcelImportLog+="Fetching " + scol + "\n";

              if(scol == "transform")
              {
                console.log("Checking transform");
                for(let scol2 in xvar["transform"])
                {
                  let scol3 = "transform_" + scol2;
                  console.log("Looking for ", scol3);
                  let index2 = cnamei[scol3];
                  if(!index2)
                    continue;
                  let ds2 = data[row][index2];
                  if(!ds2)
                    continue;

                  console.log("Found ", scol3);

                  if(typeof xvar[scol][scol2] == 'string')
                  {
                    xvar[scol][scol2] = ds2;
                  }
                  if(typeof xvar[scol][scol2] == 'boolean')
                  {
                    xvar[scol][scol2] = ds2 == "true";
                  }
                  if(typeof xvar[scol][scol2] == 'number')
                  {
                    xvar[scol][scol2] = Number.parseFloat(ds2);
                  }
                  if(typeof xvar[scol][scol2] == 'object')
                  {
                    xvar[scol][scol2] = new Date(ds2);
                  }
                }
                continue;
              }


              let index = cnamei[scol];
              if(!index)
                continue;

              let ds = data[row][index];
              if(!ds)
                continue;


              if(typeof xvar[scol] == 'string')
              {
                xvar[scol] = ds;
              }
              if(typeof xvar[scol] == 'boolean')
              {
                xvar[scol] = ds == "true";
              }
              if(typeof xvar[scol] == 'number')
              {
                xvar[scol] = Number.parseFloat(ds);
              }
              if(typeof xvar[scol] == 'object')
              {
                xvar[scol] = new Date(ds);
              }
            }

            for(let scol in mvar)
            {
              let mscol = "modbus_" + scol;
              //this.ExcelImportLog+="Fetching " + scol + "\n";
              let index = cnamei[mscol];
              if(!index)
                continue;

              let ds = data[row][index];
              if(!ds)
                continue;

              if(typeof mvar[scol] == 'string')
              {
                mvar[scol] = ds;
              }
              if(typeof mvar[scol] == 'boolean')
              {
                mvar[scol] = ds == "true";
              }
                if(typeof mvar[scol] == 'number')
              {
                mvar[scol] = Number.parseFloat(ds);
              }
              if(typeof mvar[scol] == 'object')
              {
                mvar[scol] = new Date(ds);
              }

            }
            // Nullables..?

            if(this.generateNewIDsOnImport)
            {
              xvar.xautovariableid = uuid.v4();
            }
            else
            {
              if(xvar.xautodriverid != this.selectedConnector.Driver.xautodriverid ||
                !xvar.xautovariableid || xvar.xautovariableid=="" )
              {
                xvar.xautovariableid = xvar.xautodriverid + "-" + this.hashCode(xvar.xautodriverid + xvar.xautovariableid).toString();
              }
            }

            if(!xvar.name||xvar.name == "") continue;

            mvar.xautovariableid = xvar.xautovariableid;
            mvar.xautodriverid=this.selectedConnector.Driver.xautodriverid;
            xvar.xautodriverid = this.selectedConnector.Driver.xautodriverid;

            //this.ExcelImportLog+="xvar:\n" + JSON.stringify(xvar,null,2) + "\n";
            //this.ExcelImportLog+="mvar:\n" + JSON.stringify(mvar,null,2) + "\n";

            try
            {
              let t = await this.xprojClient.XAUTO_SaveVariable(xvar);
              if(!t)
                throw Error("could not save xvar");

            }
            catch(e)
            {
              console.log("Could not save xvar", xvar);
              this.ExcelImportError += "Error xvar import: " + e.toString()  +"\n";
              continue;
            }
            try
            {
              let t = await this.xprojClient.XAUTO_SaveVariableModbus(mvar);
              if(!t)
                throw Error("could not save mvar");
            }
            catch(e)
            {
              console.log("Could not save mvar:", mvar);
              this.ExcelImportError += "Error mvar import: " + e.toString() +"\n";
              //this.xprojClient.XAUTO_RemoveVariable(xvar.xautovariableid);
            }

            let vv = new ViewVariable();
            vv.Modbus = mvar;
            vv.Variable = xvar;
            this.selectedConnector.Variables.push(vv);
            //this.ExcelImportLog+="Pushing " + xvar.name + "\n";


          }

          this.ExcelImportLog += "Import done";
          this.showImport=false;
        }
        catch(e)
        {
          this.ExcelImportError += e;
        }
      });
    }
  }

  constructor(private xprojClient: XProjectorClient) {
  }


  /*
  importFromFileExcel(filename: string): XLSX.AOA2SheetOpts {

    const wb: XLSX.WorkBook = XLSX.read(filename, { type: 'binary' });

    // grab first sheet
    const wsname: string = wb.SheetNames[0];
    const ws: XLSX.WorkSheet = wb.Sheets[wsname];

    return <XLSX.AOA2SheetOpts>(XLSX.utils.sheet_to_json(ws, { header: 1 }));
  }

  async importDataExcel(data: any[][])
  {
    if(data.length<1)
      return;

    if (this.tableData.columns.length >= data[0].length)
    {

    }


  }*/

  exportViewVariable(ev :ViewVariable) : object
  {
    let item = {};
    for(let t in ev.Variable)
    {
      if(t == "xautodriverid")
        continue;
      //if(t == "xautovariableid")
        //continue;
      if(t.endsWith("utc"))
        continue;

      if(t == "transform")
      {
        for(let ts in ev.Variable[t])
        {
          item["transform_" + ts] = ev.Variable[t][ts].toString();
        }
        continue;
      }

      item[t] = ev.Variable[t].toString();
    }
    for(let t in ev.Modbus)
    {
      if(t == "xautodriverid")
        continue;
      if(t == "xautovariableid")
        continue;
      if(t.endsWith("utc"))
        continue;
      item["modbus_" + t] = ev.Modbus[t].toString();
    }
    return item;
  }

//   get_header_row(sheet) : any {
//     var headers = [];
//     var range = XLSX.utils.decode_range(sheet['!ref']);
//     var C, R = range.s.r; /* start in the first row */
//     /* walk every column in the range */
//     for(C = range.s.c; C <= range.e.c; ++C) {
//         var cell = sheet[XLSX.utils.encode_cell({c:C, r:R})] /* find the cell in the first row */

//         var hdr = "UNKNOWN " + C; // <-- replace with your desired default
//         if(cell && cell.t) hdr = XLSX.utils.format_cell(cell);

//         headers.push(hdr);
//     }
//     return headers;
// }

  async export()
  {
    let exportvariables = [];

    for(let ev of this.selectedConnector.Variables )
    {
      let item = this.exportViewVariable(ev);
      exportvariables.push(item);
    }

    const ws  = XLSX.utils.json_to_sheet( exportvariables);

    ws['!cols'] = [];
    //ws['!cols'][1] = { hidden: true };

    let range = XLSX.utils.decode_range(ws['!ref']);
    for(let C= range.s.c; C <= range.e.c; ++C) {
      let cell = ws[XLSX.utils.encode_cell({ c : C, r : range.s.r })] /* find the cell in the first row */

      if(cell["v"] == "transform_luain")
      {
        ws['!cols'][C] = {hidden : true};
      }
      //var hdr = "UNKNOWN " + C; // <-- replace with your desired default
      //if(cell && cell.t) hdr = XLSX.utils.format_cell(cell);

      // headers.push(hdr);
  }

    // for(let t in ws)
    // {
    //   if(ws[t]["v"] == "transform_luain")
    //   {
    //     ws[t]["hidden"] = true;
    //   }
    // }

    // ws['!cols']

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Variables");
    XLSX.writeFile( wb, this.selectedConnector.Driver.name + "-variables.xlsx" );
  }

  async saveAll()
  {
    this.verifyRegisters();
    for(let i = 0; i < this.selectedConnector.Variables.length; i++)
    {
      await this.xprojClient.XAUTO_SaveVariable(this.selectedConnector.Variables[i].Variable);
      await this.xprojClient.XAUTO_SaveVariableModbus(this.selectedConnector.Variables[i].Modbus);
    }
  }

  async enableAll()
  {
    for(let i = 0; i < this.selectedConnector.Variables.length; i++)
    {
      this.selectedConnector.Variables[i].Variable.enabled = true;
    }
    await this.saveAll();
  }

  async disableAll()
  {
    for(let i = 0; i < this.selectedConnector.Variables.length; i++)
    {
      this.selectedConnector.Variables[i].Variable.enabled = false;
    }
    await this.saveAll();
  }

  removeVariable()
  {
    this.savingRemovingVariable = ClrLoadingState.LOADING;
    this.savingRemovingVariable = ClrLoadingState.SUCCESS;
  }

  addXautoVariable()
  {
    this.selectedVariable.Variable = new XAUTO_XAutoVariable();
    this.selectedVariable.Variable.xautogroup = this.selectedConnector.Driver.defaultxautogroup;
    this.selectedVariable.Variable.xgroup = this.selectedConnector.Driver.defaultxgroup;
    this.selectedVariable.Variable.xautodriverid= this.selectedConnector.Driver.xautodriverid;
    this.selectedVariable.Variable.xautovariableid = uuid.v4();
    this.selectedVariable.Variable.type = XDataType.Float64;
    //this.selectedConnector.Variables
  }

  addVariable()
  {
    this.selectedVariable = new ViewVariable();
    this.addXautoVariable();
    this.selectedVariable.Variable.name = "New variable";
    this.selectedVariable.Modbus = new XAUTO_XAutoVariableModbus();
    this.selectedVariable.Modbus.xautodriverid = this.selectedVariable.Variable.xautodriverid;
    this.selectedVariable.Modbus.xautovariableid = this.selectedVariable.Variable.xautovariableid;

    this.selectedConnector.Variables.push(this.selectedVariable);
  }

  ErrorsInRegisters= "";
  verifyRegisters()
  {
    let newErrors = "";
    let sorted = this.selectedConnector.Variables.sort( (a,b) => a.Modbus.address-b.Modbus.address );
    for(let i = 0; i < sorted.length; i++)
    {
      let a = sorted[i];
      if(!a.Variable.enabled)
        continue;

      let max = sorted.length-i;
      for(let j = 1; j < a.Modbus.size && j < max; j++)
      {
        let b = sorted[i+j];
        if(!b.Variable.enabled)
          continue;

        if ( (a.Modbus.address+a.Modbus.size) > b.Modbus.address )
        {
          if(newErrors != "")
            newErrors += "\n";
          newErrors += "Address " + b.Modbus.size.toString() + " in collision with '" + a.Variable.name + "' and '" + b.Variable.name + "'";
        }
      }
    }
    this.ErrorsInRegisters = newErrors;
  }

  connectorChanged($event)
  {
    this.verifyRegisters();
  }

  async saveVariable()
  {
    this.verifyRegisters();
    this.savingVariable = ClrLoadingState.LOADING;

    try
    {
      this.savingVariable = ClrLoadingState.SUCCESS;
      console.log("save variable");
      await this.xprojClient.XAUTO_SaveVariable( this.selectedVariable.Variable );
      console.log("save variable modbus");
      await this.xprojClient.XAUTO_SaveVariableModbus( this.selectedVariable.Modbus );
      console.log("save variable modbus done");
    }
    catch
    {
      this.savingVariable = ClrLoadingState.ERROR;
    }
  }

  removeDriver()
  {
    this.savingRemovingDriver = ClrLoadingState.LOADING;
    this.savingRemovingDriver = ClrLoadingState.SUCCESS;
  }

  async saveDriver()
  {
    this.savingDriver = ClrLoadingState.LOADING;

    try
    {
      await this.xprojClient.XAUTO_SaveDriver(this.selectedConnector.Driver);
      await this.xprojClient.XAUTO_SaveDriverModbus(this.selectedConnector.Modbus);
      this.savingDriver = ClrLoadingState.SUCCESS;
    }
    catch
    {
      this.savingDriver = ClrLoadingState.ERROR;
    }
  }

  addXAutoDriver()
  {
    this.selectedConnector = new ViewDriver();

    this.selectedConnector.Driver = new XAUTO_XAutoDriver();
    this.selectedConnector.Driver.xautodriverid = uuid.v4();
  }
  addConnector()
  {
    this.addXAutoDriver();
    this.selectedConnector.Driver.name = "New Modbus";
    this.selectedConnector.Driver.driver = XAUTO_DriverType.MODBUS;
    this.selectedConnector.Modbus = new XAUTO_XAutoDriverModbus();
    this.selectedConnector.Modbus.xautodriverid = this.selectedConnector.Driver.xautodriverid;
    this.selectedConnector.Modbus.port = 502;

    this.connectors.push(this.selectedConnector);
  }

  async loadVariables(driver : ViewDriver)
  {
    console.log("loading variables");
    let variables = await this.xprojClient.XAUTO_GetVariables(0,10000, driver.Driver.xautodriverid);
    for( let j =0; j < variables.length; j++)
    {
      let variable = variables[j];
      //console.log("loading variable", variable.xautovariableid);

      let modvar = null;
      try
      {
        modvar = await this.xprojClient.XAUTO_GetVariableModbus(variable.xautovariableid);
      }
      catch(e)
      {
        console.log("Could not find modbusvariable:", e);
        // Remove old variable
        continue;
      }

      let newViewVar = new ViewVariable();
      newViewVar.Variable = variable;
      newViewVar.Modbus = modvar;

      driver.Variables.push(newViewVar);
    }
    console.log("loading variables done;", driver);
  }

  async loadDrivers()
  {
    this.loadingConnectors = ClrLoadingState.LOADING;
    console.log("Loadingstate", this.loadingConnectors.toString());
    try
    {
      let drivers = await this.xprojClient.XAUTO_GetDrivers(0, 1000);
      for(let i = 0; i < drivers.length; i++)
      {
        let driver = drivers[i];
        if(driver.driver != XAUTO_DriverType.MODBUS)
          continue;

        let modbusdriver = await this.xprojClient.XAUTO_GetDriverModbus(driver.xautodriverid);
        let viewdriver = new ViewDriver();
        viewdriver.Driver = driver;
        viewdriver.Modbus = modbusdriver;
        await this.loadVariables(viewdriver);
        this.connectors.push(viewdriver);
      }
    }
    catch
    {
      this.loadingConnectors = ClrLoadingState.ERROR;
      console.log("Loadingstate", this.loadingConnectors.toString());
    }
    this.loadingConnectors = ClrLoadingState.SUCCESS;
    console.log("Loadingstate", this.loadingConnectors.toString());
  }

  ngOnInit() {
    this.loadDrivers();
  }

}
