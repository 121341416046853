import { Injectable, Output, EventEmitter, Inject } from '@angular/core';
import { BaseQuery, ColumnFilteringRelativeTimestamp, DFTQuery, DownSampleQuery, MultiseriesQuery, SPCQuery, XDataType, XProjectorClient } from '../XProjector/xprojector-client-service';
import { jsonArrayMember, jsonMember, jsonObject, TypedJSON } from 'typedjson';
import { OutputDataType, WidgetConfig } from './widget-config-service';

import { GridsterItem, GridsterItemComponentInterface } from 'angular-gridster2';
import { Observable, Subject } from 'rxjs';
import { XprojLuaDashboardWrapper } from '../dashboard/xproj-lua';
import { LOGGERSERVICE, XprojLoggerService } from '../logger/xproj-logger-service';

@jsonObject
export class ZoomParameters {
	@jsonMember
	public from : Date;
	@jsonMember
	public to : Date;
	@jsonMember
	public unit : string = '';
}

@jsonObject
export class HiglightParameters {
	@jsonMember
	public from : Date;
	@jsonMember
	public to : Date;
	@jsonMember
	public unit : string = '';
}

@jsonObject
export class MasterTimeSettings {
  @jsonMember
  public from : Date;
  @jsonMember
  public to : Date;
  @jsonMember
  public relativeTimestamp : ColumnFilteringRelativeTimestamp | any;  //to prevent ReferencedError in browser??!! webpack error?
}

@jsonObject
export class MasterParameters {
	@jsonMember
	public projectionId : string;
	@jsonArrayMember(String)
	public group : string[];
	@jsonMember
	public time : MasterTimeSettings
}

@jsonObject
export class LinkedWidgetChangeParameters {
	@jsonMember
	public widgetId : string;
	@jsonArrayMember(String)
	public path : string[] = [];
	@jsonMember
	public zoom : ZoomParameters;
	@jsonMember
	public highlight : HiglightParameters;
	@jsonMember
	public master : MasterParameters;

	public Clone() : LinkedWidgetChangeParameters {
		return TypedJSON.parse(TypedJSON.stringify(this, LinkedWidgetChangeParameters), LinkedWidgetChangeParameters);
	}

}

@jsonObject
export class LinkedWidgetSelectValue {
  @jsonMember
	public id : string;

	@jsonMember
	public columnname : string;

	@jsonMember
	public label : string;

	@jsonMember
	public value : string;

	@jsonMember
	public projectionId : string;

	@jsonMember
	public datatype : XDataType;

	@jsonArrayMember(String)
	public group : string[] = [];
}

@jsonObject
export class LinkedWidgetSelectParameters {
	@jsonMember
	public widgetId : string;

	@jsonArrayMember(String)
	public path : string[] = [];

	@jsonArrayMember(LinkedWidgetSelectValue)
	public values : LinkedWidgetSelectValue[] = [];

	@jsonMember
	public selected : LinkedWidgetSelectValue;

	public Clone() : LinkedWidgetSelectParameters {
		return TypedJSON.parse(TypedJSON.stringify(this, LinkedWidgetSelectParameters), LinkedWidgetSelectParameters);
	}

}

@jsonObject
export class WidgetOutputChangeParameters {
  @jsonMember
  public widgetId : string;

	@jsonMember
  public outputParameterId : string;

  @jsonMember
  public value : any

  @jsonMember
  public datatype : OutputDataType;

}

@Injectable({
  providedIn: 'root'
})
export class XprojWidgetService {

	refreshSubject: Subject<string> = new Subject();
	resetSubject: Subject<string> = new Subject();
	updateQuerySubject: Subject<{widgetId : string, config : WidgetConfig}> = new Subject();
  saveConfigSubject: Subject<{widgetId : string, callback: (c : WidgetConfig) => void}> = new Subject();
  exportQueriesSubject: Subject<{widgetId : string, callback: (queries : (BaseQuery | DownSampleQuery | DFTQuery | MultiseriesQuery | SPCQuery)[], excludeColumns : string[]) => void}> = new Subject();
  exportImageSubject: Subject<{widgetId : string, callback: (href : any) => void}> = new Subject();
  exportDataSubject: Subject<{widgetId : string, callback: (data : any[][]) => void}> = new Subject();
	linkedWidgetChangeSubject: Subject<LinkedWidgetChangeParameters> = new Subject();
  linkedWidgetSelectSubject: Subject<LinkedWidgetSelectParameters> = new Subject();
  resizeSubject: Subject<{item: GridsterItem, itemComponent : GridsterItemComponentInterface}> = new Subject();
  widgetOutputChangeSubject: Subject<WidgetOutputChangeParameters[]> = new Subject();
  projectionDataChangedSubject: Subject<string[]> = new Subject();
  initDoneSubject: Subject<void> = new Subject();
  getInputParameterValuesSubject: Subject<{widgetId : string, callback: (inputParameterValues : Map<string, any>) => void}> = new Subject();
  setInputParameterValuesSubject: Subject<{widgetId : string, inputParameterValues : Map<string, any>}> = new Subject();

  public lua : XprojLuaDashboardWrapper = null;
  public constructor(public xprojClient: XProjectorClient, @Inject(LOGGERSERVICE) private logger: XprojLoggerService)
  {
    this.lua = new XprojLuaDashboardWrapper(xprojClient, this.logger);
  }


  private dashboardParameters : WidgetOutputChangeParameters[] = [];

  public initDone() {
		this.initDoneSubject.next();
  }

  public onInitDone(): Observable<void> {
    return this.initDoneSubject.asObservable();
  }

	public refresh(widgetId : string = '') {
		this.refreshSubject.next(widgetId);
  }

  public onRefresh(): Observable<string> {
    return this.refreshSubject.asObservable();
  }


  public resized(item : GridsterItem, itemComponent: GridsterItemComponentInterface) {
		this.resizeSubject.next({item: item, itemComponent : itemComponent});
  }

  public onResized(): Observable<{item: GridsterItem, itemComponent : GridsterItemComponentInterface}> {
    return this.resizeSubject.asObservable();
  }


	public reset(widgetId : string = '') {
		this.resetSubject.next(widgetId);
  }

  public onReset(): Observable<string> {
    return this.resetSubject.asObservable();
  }


	public updateQuery(widgetId : string, config : WidgetConfig) {
		this.updateQuerySubject.next({widgetId: widgetId, config});
  }

  public onUpdateQuery(): Observable<{widgetId : string, config : WidgetConfig}> {
    return this.updateQuerySubject.asObservable();
  }

	public saveConfig(widgetId : string, callback: (c : WidgetConfig) => void) {
		this.saveConfigSubject.next({widgetId: widgetId, callback: callback});
  }

  public onSaveConfig(): Observable<{widgetId : string, callback: (c : WidgetConfig) => void}> {
    return this.saveConfigSubject.asObservable();
  }


	public linkedWidgetChanged(parameters : LinkedWidgetChangeParameters) {
		this.linkedWidgetChangeSubject.next(parameters);
  }

  public onLinkedWidgetChanged(): Observable<LinkedWidgetChangeParameters> {
    return this.linkedWidgetChangeSubject.asObservable();
  }


	public linkedWidgetSelected(parameters : LinkedWidgetSelectParameters) {
		this.linkedWidgetSelectSubject.next(parameters);
  }

  public onLinkedWidgetSelected(): Observable<LinkedWidgetSelectParameters> {
    return this.linkedWidgetSelectSubject.asObservable();
  }


  public outputParameterChanged(parameters : WidgetOutputChangeParameters) {
		this.widgetOutputChangeSubject.next([parameters]);
  }

  public outputParametersChanged(parameters : WidgetOutputChangeParameters[], isDashboardParameters : boolean = false) {
    if (isDashboardParameters) {
      this.dashboardParameters = parameters;
    }
		this.widgetOutputChangeSubject.next(parameters);
  }

  public getDashboardParameters(): WidgetOutputChangeParameters[] {
    return this.dashboardParameters;
  }

  public onWidgetOutputChanged(): Observable<WidgetOutputChangeParameters[]> {
    return this.widgetOutputChangeSubject.asObservable();
  }

  public exportQueries(widgetId : string, callback: (queries : (BaseQuery | DownSampleQuery | DFTQuery | MultiseriesQuery | SPCQuery)[], excludeColumns : string[]) => void) {
		this.exportQueriesSubject.next({widgetId: widgetId, callback: callback});
  }

  public exportImage(widgetId : string, callback: (href : any) => void) {
		this.exportImageSubject.next({widgetId: widgetId, callback: callback});
  }

  public exportData(widgetId : string, callback: (data : any[][]) => void) {
		this.exportDataSubject.next({widgetId: widgetId, callback: callback});
  }

  public projectionDataChanged(projectionIds : string[]) {
		this.projectionDataChangedSubject.next(projectionIds);
  }

  public onProjectionDataChanged(): Observable<string[]> {
    return this.projectionDataChangedSubject.asObservable();
  }

  public getInputParameterValues(widgetId : string, callback: (inputParameterValues : Map<string, any>) => void) {
		this.getInputParameterValuesSubject.next({widgetId: widgetId, callback: callback});
  }

  public setInputParameterValues(widgetId : string, inputParameterValues : Map<string, any>) {
		this.setInputParameterValuesSubject.next({widgetId, inputParameterValues});
  }
}
