/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions,
  uint8ArrayToBase64
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as googleProtobuf000 from './google/protobuf/empty.pb';
import * as googleProtobuf001 from './google/protobuf/timestamp.pb';
/**
 * Message implementation for modulerossakerbms.lorawan.DownlinkItem
 */
export class DownlinkItem implements GrpcMessage {
  static id = 'modulerossakerbms.lorawan.DownlinkItem';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new DownlinkItem();
    DownlinkItem.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: DownlinkItem) {
    _instance.id = _instance.id || '';
    _instance.devEui = _instance.devEui || '';
    _instance.confirmed = _instance.confirmed || false;
    _instance.pending = _instance.pending || false;
    _instance.fport = _instance.fport || 0;
    _instance.data = _instance.data || '';
    _instance.deliveryStatus = _instance.deliveryStatus || '';
    _instance.requestTimestamp = _instance.requestTimestamp || undefined;
    _instance.responseTimestamp = _instance.responseTimestamp || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: DownlinkItem,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        case 2:
          _instance.devEui = _reader.readString();
          break;
        case 3:
          _instance.confirmed = _reader.readBool();
          break;
        case 4:
          _instance.pending = _reader.readBool();
          break;
        case 5:
          _instance.fport = _reader.readInt32();
          break;
        case 6:
          _instance.data = _reader.readString();
          break;
        case 7:
          _instance.deliveryStatus = _reader.readString();
          break;
        case 8:
          _instance.requestTimestamp = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.requestTimestamp,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 9:
          _instance.responseTimestamp = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.responseTimestamp,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    DownlinkItem.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: DownlinkItem,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
    if (_instance.devEui) {
      _writer.writeString(2, _instance.devEui);
    }
    if (_instance.confirmed) {
      _writer.writeBool(3, _instance.confirmed);
    }
    if (_instance.pending) {
      _writer.writeBool(4, _instance.pending);
    }
    if (_instance.fport) {
      _writer.writeInt32(5, _instance.fport);
    }
    if (_instance.data) {
      _writer.writeString(6, _instance.data);
    }
    if (_instance.deliveryStatus) {
      _writer.writeString(7, _instance.deliveryStatus);
    }
    if (_instance.requestTimestamp) {
      _writer.writeMessage(
        8,
        _instance.requestTimestamp as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.responseTimestamp) {
      _writer.writeMessage(
        9,
        _instance.responseTimestamp as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
  }

  private _id?: string;
  private _devEui?: string;
  private _confirmed?: boolean;
  private _pending?: boolean;
  private _fport?: number;
  private _data?: string;
  private _deliveryStatus?: string;
  private _requestTimestamp?: googleProtobuf001.Timestamp;
  private _responseTimestamp?: googleProtobuf001.Timestamp;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of DownlinkItem to deeply clone from
   */
  constructor(_value?: RecursivePartial<DownlinkItem.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.devEui = _value.devEui;
    this.confirmed = _value.confirmed;
    this.pending = _value.pending;
    this.fport = _value.fport;
    this.data = _value.data;
    this.deliveryStatus = _value.deliveryStatus;
    this.requestTimestamp = _value.requestTimestamp
      ? new googleProtobuf001.Timestamp(_value.requestTimestamp)
      : undefined;
    this.responseTimestamp = _value.responseTimestamp
      ? new googleProtobuf001.Timestamp(_value.responseTimestamp)
      : undefined;
    DownlinkItem.refineValues(this);
  }
  get id(): string | undefined {
    return this._id;
  }
  set id(value: string | undefined) {
    this._id = value;
  }
  get devEui(): string | undefined {
    return this._devEui;
  }
  set devEui(value: string | undefined) {
    this._devEui = value;
  }
  get confirmed(): boolean | undefined {
    return this._confirmed;
  }
  set confirmed(value: boolean | undefined) {
    this._confirmed = value;
  }
  get pending(): boolean | undefined {
    return this._pending;
  }
  set pending(value: boolean | undefined) {
    this._pending = value;
  }
  get fport(): number | undefined {
    return this._fport;
  }
  set fport(value: number | undefined) {
    this._fport = value;
  }
  get data(): string | undefined {
    return this._data;
  }
  set data(value: string | undefined) {
    this._data = value;
  }
  get deliveryStatus(): string | undefined {
    return this._deliveryStatus;
  }
  set deliveryStatus(value: string | undefined) {
    this._deliveryStatus = value;
  }
  get requestTimestamp(): googleProtobuf001.Timestamp | undefined {
    return this._requestTimestamp;
  }
  set requestTimestamp(value: googleProtobuf001.Timestamp | undefined) {
    this._requestTimestamp = value;
  }
  get responseTimestamp(): googleProtobuf001.Timestamp | undefined {
    return this._responseTimestamp;
  }
  set responseTimestamp(value: googleProtobuf001.Timestamp | undefined) {
    this._responseTimestamp = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    DownlinkItem.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): DownlinkItem.AsObject {
    return {
      id: this.id,
      devEui: this.devEui,
      confirmed: this.confirmed,
      pending: this.pending,
      fport: this.fport,
      data: this.data,
      deliveryStatus: this.deliveryStatus,
      requestTimestamp: this.requestTimestamp
        ? this.requestTimestamp.toObject()
        : undefined,
      responseTimestamp: this.responseTimestamp
        ? this.responseTimestamp.toObject()
        : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): DownlinkItem.AsProtobufJSON {
    return {
      id: this.id,
      devEui: this.devEui,
      confirmed: this.confirmed,
      pending: this.pending,
      fport: this.fport,
      data: this.data,
      deliveryStatus: this.deliveryStatus,
      requestTimestamp: this.requestTimestamp
        ? this.requestTimestamp.toProtobufJSON(options)
        : null,
      responseTimestamp: this.responseTimestamp
        ? this.responseTimestamp.toProtobufJSON(options)
        : null
    };
  }
}
export module DownlinkItem {
  /**
   * Standard JavaScript object representation for DownlinkItem
   */
  export interface AsObject {
    id?: string;
    devEui?: string;
    confirmed?: boolean;
    pending?: boolean;
    fport?: number;
    data?: string;
    deliveryStatus?: string;
    requestTimestamp?: googleProtobuf001.Timestamp.AsObject;
    responseTimestamp?: googleProtobuf001.Timestamp.AsObject;
  }

  /**
   * Protobuf JSON representation for DownlinkItem
   */
  export interface AsProtobufJSON {
    id?: string;
    devEui?: string;
    confirmed?: boolean;
    pending?: boolean;
    fport?: number;
    data?: string;
    deliveryStatus?: string;
    requestTimestamp?: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    responseTimestamp?: googleProtobuf001.Timestamp.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for modulerossakerbms.lorawan.ConfiguredDownlinkItem
 */
export class ConfiguredDownlinkItem implements GrpcMessage {
  static id = 'modulerossakerbms.lorawan.ConfiguredDownlinkItem';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ConfiguredDownlinkItem();
    ConfiguredDownlinkItem.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ConfiguredDownlinkItem) {
    _instance.id = _instance.id || '';
    _instance.name = _instance.name || '';
    _instance.data = _instance.data || '';
    _instance.fport = _instance.fport || 0;
    _instance.confirmed = _instance.confirmed || false;
    _instance.deviceType = _instance.deviceType || '';
    _instance.ttl = _instance.ttl || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ConfiguredDownlinkItem,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        case 2:
          _instance.name = _reader.readString();
          break;
        case 3:
          _instance.data = _reader.readString();
          break;
        case 4:
          _instance.fport = _reader.readInt32();
          break;
        case 5:
          _instance.confirmed = _reader.readBool();
          break;
        case 6:
          _instance.deviceType = _reader.readString();
          break;
        case 7:
          _instance.ttl = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    ConfiguredDownlinkItem.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ConfiguredDownlinkItem,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
    if (_instance.name) {
      _writer.writeString(2, _instance.name);
    }
    if (_instance.data) {
      _writer.writeString(3, _instance.data);
    }
    if (_instance.fport) {
      _writer.writeInt32(4, _instance.fport);
    }
    if (_instance.confirmed) {
      _writer.writeBool(5, _instance.confirmed);
    }
    if (_instance.deviceType) {
      _writer.writeString(6, _instance.deviceType);
    }
    if (_instance.ttl) {
      _writer.writeInt32(7, _instance.ttl);
    }
  }

  private _id?: string;
  private _name?: string;
  private _data?: string;
  private _fport?: number;
  private _confirmed?: boolean;
  private _deviceType?: string;
  private _ttl?: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ConfiguredDownlinkItem to deeply clone from
   */
  constructor(_value?: RecursivePartial<ConfiguredDownlinkItem.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.name = _value.name;
    this.data = _value.data;
    this.fport = _value.fport;
    this.confirmed = _value.confirmed;
    this.deviceType = _value.deviceType;
    this.ttl = _value.ttl;
    ConfiguredDownlinkItem.refineValues(this);
  }
  get id(): string | undefined {
    return this._id;
  }
  set id(value: string | undefined) {
    this._id = value;
  }
  get name(): string | undefined {
    return this._name;
  }
  set name(value: string | undefined) {
    this._name = value;
  }
  get data(): string | undefined {
    return this._data;
  }
  set data(value: string | undefined) {
    this._data = value;
  }
  get fport(): number | undefined {
    return this._fport;
  }
  set fport(value: number | undefined) {
    this._fport = value;
  }
  get confirmed(): boolean | undefined {
    return this._confirmed;
  }
  set confirmed(value: boolean | undefined) {
    this._confirmed = value;
  }
  get deviceType(): string | undefined {
    return this._deviceType;
  }
  set deviceType(value: string | undefined) {
    this._deviceType = value;
  }
  get ttl(): number | undefined {
    return this._ttl;
  }
  set ttl(value: number | undefined) {
    this._ttl = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ConfiguredDownlinkItem.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ConfiguredDownlinkItem.AsObject {
    return {
      id: this.id,
      name: this.name,
      data: this.data,
      fport: this.fport,
      confirmed: this.confirmed,
      deviceType: this.deviceType,
      ttl: this.ttl
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ConfiguredDownlinkItem.AsProtobufJSON {
    return {
      id: this.id,
      name: this.name,
      data: this.data,
      fport: this.fport,
      confirmed: this.confirmed,
      deviceType: this.deviceType,
      ttl: this.ttl
    };
  }
}
export module ConfiguredDownlinkItem {
  /**
   * Standard JavaScript object representation for ConfiguredDownlinkItem
   */
  export interface AsObject {
    id?: string;
    name?: string;
    data?: string;
    fport?: number;
    confirmed?: boolean;
    deviceType?: string;
    ttl?: number;
  }

  /**
   * Protobuf JSON representation for ConfiguredDownlinkItem
   */
  export interface AsProtobufJSON {
    id?: string;
    name?: string;
    data?: string;
    fport?: number;
    confirmed?: boolean;
    deviceType?: string;
    ttl?: number;
  }
}

/**
 * Message implementation for modulerossakerbms.lorawan.ConfiguredDownlinkSequence
 */
export class ConfiguredDownlinkSequence implements GrpcMessage {
  static id = 'modulerossakerbms.lorawan.ConfiguredDownlinkSequence';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ConfiguredDownlinkSequence();
    ConfiguredDownlinkSequence.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ConfiguredDownlinkSequence) {
    _instance.id = _instance.id || '';
    _instance.name = _instance.name || '';
    _instance.deviceType = _instance.deviceType || '';
    _instance.timeout = _instance.timeout || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ConfiguredDownlinkSequence,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        case 2:
          _instance.name = _reader.readString();
          break;
        case 3:
          _instance.deviceType = _reader.readString();
          break;
        case 4:
          _instance.timeout = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    ConfiguredDownlinkSequence.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ConfiguredDownlinkSequence,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
    if (_instance.name) {
      _writer.writeString(2, _instance.name);
    }
    if (_instance.deviceType) {
      _writer.writeString(3, _instance.deviceType);
    }
    if (_instance.timeout) {
      _writer.writeInt32(4, _instance.timeout);
    }
  }

  private _id?: string;
  private _name?: string;
  private _deviceType?: string;
  private _timeout?: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ConfiguredDownlinkSequence to deeply clone from
   */
  constructor(_value?: RecursivePartial<ConfiguredDownlinkSequence.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.name = _value.name;
    this.deviceType = _value.deviceType;
    this.timeout = _value.timeout;
    ConfiguredDownlinkSequence.refineValues(this);
  }
  get id(): string | undefined {
    return this._id;
  }
  set id(value: string | undefined) {
    this._id = value;
  }
  get name(): string | undefined {
    return this._name;
  }
  set name(value: string | undefined) {
    this._name = value;
  }
  get deviceType(): string | undefined {
    return this._deviceType;
  }
  set deviceType(value: string | undefined) {
    this._deviceType = value;
  }
  get timeout(): number | undefined {
    return this._timeout;
  }
  set timeout(value: number | undefined) {
    this._timeout = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ConfiguredDownlinkSequence.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ConfiguredDownlinkSequence.AsObject {
    return {
      id: this.id,
      name: this.name,
      deviceType: this.deviceType,
      timeout: this.timeout
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ConfiguredDownlinkSequence.AsProtobufJSON {
    return {
      id: this.id,
      name: this.name,
      deviceType: this.deviceType,
      timeout: this.timeout
    };
  }
}
export module ConfiguredDownlinkSequence {
  /**
   * Standard JavaScript object representation for ConfiguredDownlinkSequence
   */
  export interface AsObject {
    id?: string;
    name?: string;
    deviceType?: string;
    timeout?: number;
  }

  /**
   * Protobuf JSON representation for ConfiguredDownlinkSequence
   */
  export interface AsProtobufJSON {
    id?: string;
    name?: string;
    deviceType?: string;
    timeout?: number;
  }
}

/**
 * Message implementation for modulerossakerbms.lorawan.EnqueueDownlinkItemRequest
 */
export class EnqueueDownlinkItemRequest implements GrpcMessage {
  static id = 'modulerossakerbms.lorawan.EnqueueDownlinkItemRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new EnqueueDownlinkItemRequest();
    EnqueueDownlinkItemRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: EnqueueDownlinkItemRequest) {
    _instance.customerId = _instance.customerId || '';
    _instance.nodeId = _instance.nodeId || '';
    _instance.nodeTypeId = _instance.nodeTypeId || '';
    _instance.operator = _instance.operator || '';
    _instance.downlinkItem = _instance.downlinkItem || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: EnqueueDownlinkItemRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.customerId = _reader.readString();
          break;
        case 2:
          _instance.nodeId = _reader.readString();
          break;
        case 3:
          _instance.nodeTypeId = _reader.readString();
          break;
        case 4:
          _instance.operator = _reader.readString();
          break;
        case 5:
          _instance.downlinkItem = new ConfiguredDownlinkItem();
          _reader.readMessage(
            _instance.downlinkItem,
            ConfiguredDownlinkItem.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    EnqueueDownlinkItemRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: EnqueueDownlinkItemRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.customerId) {
      _writer.writeString(1, _instance.customerId);
    }
    if (_instance.nodeId) {
      _writer.writeString(2, _instance.nodeId);
    }
    if (_instance.nodeTypeId) {
      _writer.writeString(3, _instance.nodeTypeId);
    }
    if (_instance.operator) {
      _writer.writeString(4, _instance.operator);
    }
    if (_instance.downlinkItem) {
      _writer.writeMessage(
        5,
        _instance.downlinkItem as any,
        ConfiguredDownlinkItem.serializeBinaryToWriter
      );
    }
  }

  private _customerId?: string;
  private _nodeId?: string;
  private _nodeTypeId?: string;
  private _operator?: string;
  private _downlinkItem?: ConfiguredDownlinkItem;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of EnqueueDownlinkItemRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<EnqueueDownlinkItemRequest.AsObject>) {
    _value = _value || {};
    this.customerId = _value.customerId;
    this.nodeId = _value.nodeId;
    this.nodeTypeId = _value.nodeTypeId;
    this.operator = _value.operator;
    this.downlinkItem = _value.downlinkItem
      ? new ConfiguredDownlinkItem(_value.downlinkItem)
      : undefined;
    EnqueueDownlinkItemRequest.refineValues(this);
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }
  get nodeId(): string | undefined {
    return this._nodeId;
  }
  set nodeId(value: string | undefined) {
    this._nodeId = value;
  }
  get nodeTypeId(): string | undefined {
    return this._nodeTypeId;
  }
  set nodeTypeId(value: string | undefined) {
    this._nodeTypeId = value;
  }
  get operator(): string | undefined {
    return this._operator;
  }
  set operator(value: string | undefined) {
    this._operator = value;
  }
  get downlinkItem(): ConfiguredDownlinkItem | undefined {
    return this._downlinkItem;
  }
  set downlinkItem(value: ConfiguredDownlinkItem | undefined) {
    this._downlinkItem = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    EnqueueDownlinkItemRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): EnqueueDownlinkItemRequest.AsObject {
    return {
      customerId: this.customerId,
      nodeId: this.nodeId,
      nodeTypeId: this.nodeTypeId,
      operator: this.operator,
      downlinkItem: this.downlinkItem ? this.downlinkItem.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): EnqueueDownlinkItemRequest.AsProtobufJSON {
    return {
      customerId: this.customerId,
      nodeId: this.nodeId,
      nodeTypeId: this.nodeTypeId,
      operator: this.operator,
      downlinkItem: this.downlinkItem
        ? this.downlinkItem.toProtobufJSON(options)
        : null
    };
  }
}
export module EnqueueDownlinkItemRequest {
  /**
   * Standard JavaScript object representation for EnqueueDownlinkItemRequest
   */
  export interface AsObject {
    customerId?: string;
    nodeId?: string;
    nodeTypeId?: string;
    operator?: string;
    downlinkItem?: ConfiguredDownlinkItem.AsObject;
  }

  /**
   * Protobuf JSON representation for EnqueueDownlinkItemRequest
   */
  export interface AsProtobufJSON {
    customerId?: string;
    nodeId?: string;
    nodeTypeId?: string;
    operator?: string;
    downlinkItem?: ConfiguredDownlinkItem.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for modulerossakerbms.lorawan.EnqueueDownlinkItemResponse
 */
export class EnqueueDownlinkItemResponse implements GrpcMessage {
  static id = 'modulerossakerbms.lorawan.EnqueueDownlinkItemResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new EnqueueDownlinkItemResponse();
    EnqueueDownlinkItemResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: EnqueueDownlinkItemResponse) {
    _instance.ok = _instance.ok || false;
    _instance.message = _instance.message || '';
    _instance.deviceCount = _instance.deviceCount || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: EnqueueDownlinkItemResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.ok = _reader.readBool();
          break;
        case 2:
          _instance.message = _reader.readString();
          break;
        case 3:
          _instance.deviceCount = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    EnqueueDownlinkItemResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: EnqueueDownlinkItemResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.ok) {
      _writer.writeBool(1, _instance.ok);
    }
    if (_instance.message) {
      _writer.writeString(2, _instance.message);
    }
    if (_instance.deviceCount) {
      _writer.writeInt32(3, _instance.deviceCount);
    }
  }

  private _ok?: boolean;
  private _message?: string;
  private _deviceCount?: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of EnqueueDownlinkItemResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<EnqueueDownlinkItemResponse.AsObject>) {
    _value = _value || {};
    this.ok = _value.ok;
    this.message = _value.message;
    this.deviceCount = _value.deviceCount;
    EnqueueDownlinkItemResponse.refineValues(this);
  }
  get ok(): boolean | undefined {
    return this._ok;
  }
  set ok(value: boolean | undefined) {
    this._ok = value;
  }
  get message(): string | undefined {
    return this._message;
  }
  set message(value: string | undefined) {
    this._message = value;
  }
  get deviceCount(): number | undefined {
    return this._deviceCount;
  }
  set deviceCount(value: number | undefined) {
    this._deviceCount = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    EnqueueDownlinkItemResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): EnqueueDownlinkItemResponse.AsObject {
    return {
      ok: this.ok,
      message: this.message,
      deviceCount: this.deviceCount
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): EnqueueDownlinkItemResponse.AsProtobufJSON {
    return {
      ok: this.ok,
      message: this.message,
      deviceCount: this.deviceCount
    };
  }
}
export module EnqueueDownlinkItemResponse {
  /**
   * Standard JavaScript object representation for EnqueueDownlinkItemResponse
   */
  export interface AsObject {
    ok?: boolean;
    message?: string;
    deviceCount?: number;
  }

  /**
   * Protobuf JSON representation for EnqueueDownlinkItemResponse
   */
  export interface AsProtobufJSON {
    ok?: boolean;
    message?: string;
    deviceCount?: number;
  }
}

/**
 * Message implementation for modulerossakerbms.lorawan.DataChunk
 */
export class DataChunk implements GrpcMessage {
  static id = 'modulerossakerbms.lorawan.DataChunk';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new DataChunk();
    DataChunk.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: DataChunk) {
    _instance.data = _instance.data || new Uint8Array();
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: DataChunk,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.data = _reader.readBytes();
          break;
        default:
          _reader.skipField();
      }
    }

    DataChunk.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: DataChunk, _writer: BinaryWriter) {
    if (_instance.data && _instance.data.length) {
      _writer.writeBytes(1, _instance.data);
    }
  }

  private _data?: Uint8Array;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of DataChunk to deeply clone from
   */
  constructor(_value?: RecursivePartial<DataChunk.AsObject>) {
    _value = _value || {};
    this.data = _value.data;
    DataChunk.refineValues(this);
  }
  get data(): Uint8Array | undefined {
    return this._data;
  }
  set data(value: Uint8Array | undefined) {
    this._data = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    DataChunk.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): DataChunk.AsObject {
    return {
      data: this.data ? this.data.subarray(0) : new Uint8Array()
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): DataChunk.AsProtobufJSON {
    return {
      data: this.data ? uint8ArrayToBase64(this.data) : ''
    };
  }
}
export module DataChunk {
  /**
   * Standard JavaScript object representation for DataChunk
   */
  export interface AsObject {
    data?: Uint8Array;
  }

  /**
   * Protobuf JSON representation for DataChunk
   */
  export interface AsProtobufJSON {
    data?: string;
  }
}

/**
 * Message implementation for modulerossakerbms.lorawan.EnqueueDownlinkSequenceRequest
 */
export class EnqueueDownlinkSequenceRequest implements GrpcMessage {
  static id = 'modulerossakerbms.lorawan.EnqueueDownlinkSequenceRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new EnqueueDownlinkSequenceRequest();
    EnqueueDownlinkSequenceRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: EnqueueDownlinkSequenceRequest) {
    _instance.customerId = _instance.customerId || '';
    _instance.nodeId = _instance.nodeId || '';
    _instance.nodeTypeId = _instance.nodeTypeId || '';
    _instance.operator = _instance.operator || '';
    _instance.downlinkSequenceId = _instance.downlinkSequenceId || '';
    _instance.failedAfter = _instance.failedAfter || undefined;
    _instance.overrideTimeout = _instance.overrideTimeout || 0;
    _instance.sendEmail = _instance.sendEmail || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: EnqueueDownlinkSequenceRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.customerId = _reader.readString();
          break;
        case 2:
          _instance.nodeId = _reader.readString();
          break;
        case 3:
          _instance.nodeTypeId = _reader.readString();
          break;
        case 4:
          _instance.operator = _reader.readString();
          break;
        case 5:
          _instance.downlinkSequenceId = _reader.readString();
          break;
        case 6:
          _instance.failedAfter = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.failedAfter,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 7:
          _instance.overrideTimeout = _reader.readInt32();
          break;
        case 8:
          _instance.sendEmail = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    EnqueueDownlinkSequenceRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: EnqueueDownlinkSequenceRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.customerId) {
      _writer.writeString(1, _instance.customerId);
    }
    if (_instance.nodeId) {
      _writer.writeString(2, _instance.nodeId);
    }
    if (_instance.nodeTypeId) {
      _writer.writeString(3, _instance.nodeTypeId);
    }
    if (_instance.operator) {
      _writer.writeString(4, _instance.operator);
    }
    if (_instance.downlinkSequenceId) {
      _writer.writeString(5, _instance.downlinkSequenceId);
    }
    if (_instance.failedAfter) {
      _writer.writeMessage(
        6,
        _instance.failedAfter as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.overrideTimeout) {
      _writer.writeInt32(7, _instance.overrideTimeout);
    }
    if (_instance.sendEmail) {
      _writer.writeBool(8, _instance.sendEmail);
    }
  }

  private _customerId?: string;
  private _nodeId?: string;
  private _nodeTypeId?: string;
  private _operator?: string;
  private _downlinkSequenceId?: string;
  private _failedAfter?: googleProtobuf001.Timestamp;
  private _overrideTimeout?: number;
  private _sendEmail?: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of EnqueueDownlinkSequenceRequest to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<EnqueueDownlinkSequenceRequest.AsObject>
  ) {
    _value = _value || {};
    this.customerId = _value.customerId;
    this.nodeId = _value.nodeId;
    this.nodeTypeId = _value.nodeTypeId;
    this.operator = _value.operator;
    this.downlinkSequenceId = _value.downlinkSequenceId;
    this.failedAfter = _value.failedAfter
      ? new googleProtobuf001.Timestamp(_value.failedAfter)
      : undefined;
    this.overrideTimeout = _value.overrideTimeout;
    this.sendEmail = _value.sendEmail;
    EnqueueDownlinkSequenceRequest.refineValues(this);
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }
  get nodeId(): string | undefined {
    return this._nodeId;
  }
  set nodeId(value: string | undefined) {
    this._nodeId = value;
  }
  get nodeTypeId(): string | undefined {
    return this._nodeTypeId;
  }
  set nodeTypeId(value: string | undefined) {
    this._nodeTypeId = value;
  }
  get operator(): string | undefined {
    return this._operator;
  }
  set operator(value: string | undefined) {
    this._operator = value;
  }
  get downlinkSequenceId(): string | undefined {
    return this._downlinkSequenceId;
  }
  set downlinkSequenceId(value: string | undefined) {
    this._downlinkSequenceId = value;
  }
  get failedAfter(): googleProtobuf001.Timestamp | undefined {
    return this._failedAfter;
  }
  set failedAfter(value: googleProtobuf001.Timestamp | undefined) {
    this._failedAfter = value;
  }
  get overrideTimeout(): number | undefined {
    return this._overrideTimeout;
  }
  set overrideTimeout(value: number | undefined) {
    this._overrideTimeout = value;
  }
  get sendEmail(): boolean | undefined {
    return this._sendEmail;
  }
  set sendEmail(value: boolean | undefined) {
    this._sendEmail = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    EnqueueDownlinkSequenceRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): EnqueueDownlinkSequenceRequest.AsObject {
    return {
      customerId: this.customerId,
      nodeId: this.nodeId,
      nodeTypeId: this.nodeTypeId,
      operator: this.operator,
      downlinkSequenceId: this.downlinkSequenceId,
      failedAfter: this.failedAfter ? this.failedAfter.toObject() : undefined,
      overrideTimeout: this.overrideTimeout,
      sendEmail: this.sendEmail
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): EnqueueDownlinkSequenceRequest.AsProtobufJSON {
    return {
      customerId: this.customerId,
      nodeId: this.nodeId,
      nodeTypeId: this.nodeTypeId,
      operator: this.operator,
      downlinkSequenceId: this.downlinkSequenceId,
      failedAfter: this.failedAfter
        ? this.failedAfter.toProtobufJSON(options)
        : null,
      overrideTimeout: this.overrideTimeout,
      sendEmail: this.sendEmail
    };
  }
}
export module EnqueueDownlinkSequenceRequest {
  /**
   * Standard JavaScript object representation for EnqueueDownlinkSequenceRequest
   */
  export interface AsObject {
    customerId?: string;
    nodeId?: string;
    nodeTypeId?: string;
    operator?: string;
    downlinkSequenceId?: string;
    failedAfter?: googleProtobuf001.Timestamp.AsObject;
    overrideTimeout?: number;
    sendEmail?: boolean;
  }

  /**
   * Protobuf JSON representation for EnqueueDownlinkSequenceRequest
   */
  export interface AsProtobufJSON {
    customerId?: string;
    nodeId?: string;
    nodeTypeId?: string;
    operator?: string;
    downlinkSequenceId?: string;
    failedAfter?: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    overrideTimeout?: number;
    sendEmail?: boolean;
  }
}

/**
 * Message implementation for modulerossakerbms.lorawan.EnqueueDownlinkSequenceResponse
 */
export class EnqueueDownlinkSequenceResponse implements GrpcMessage {
  static id = 'modulerossakerbms.lorawan.EnqueueDownlinkSequenceResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new EnqueueDownlinkSequenceResponse();
    EnqueueDownlinkSequenceResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: EnqueueDownlinkSequenceResponse) {
    _instance.ok = _instance.ok || false;
    _instance.message = _instance.message || '';
    _instance.deviceCount = _instance.deviceCount || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: EnqueueDownlinkSequenceResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.ok = _reader.readBool();
          break;
        case 2:
          _instance.message = _reader.readString();
          break;
        case 3:
          _instance.deviceCount = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    EnqueueDownlinkSequenceResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: EnqueueDownlinkSequenceResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.ok) {
      _writer.writeBool(1, _instance.ok);
    }
    if (_instance.message) {
      _writer.writeString(2, _instance.message);
    }
    if (_instance.deviceCount) {
      _writer.writeInt32(3, _instance.deviceCount);
    }
  }

  private _ok?: boolean;
  private _message?: string;
  private _deviceCount?: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of EnqueueDownlinkSequenceResponse to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<EnqueueDownlinkSequenceResponse.AsObject>
  ) {
    _value = _value || {};
    this.ok = _value.ok;
    this.message = _value.message;
    this.deviceCount = _value.deviceCount;
    EnqueueDownlinkSequenceResponse.refineValues(this);
  }
  get ok(): boolean | undefined {
    return this._ok;
  }
  set ok(value: boolean | undefined) {
    this._ok = value;
  }
  get message(): string | undefined {
    return this._message;
  }
  set message(value: string | undefined) {
    this._message = value;
  }
  get deviceCount(): number | undefined {
    return this._deviceCount;
  }
  set deviceCount(value: number | undefined) {
    this._deviceCount = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    EnqueueDownlinkSequenceResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): EnqueueDownlinkSequenceResponse.AsObject {
    return {
      ok: this.ok,
      message: this.message,
      deviceCount: this.deviceCount
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): EnqueueDownlinkSequenceResponse.AsProtobufJSON {
    return {
      ok: this.ok,
      message: this.message,
      deviceCount: this.deviceCount
    };
  }
}
export module EnqueueDownlinkSequenceResponse {
  /**
   * Standard JavaScript object representation for EnqueueDownlinkSequenceResponse
   */
  export interface AsObject {
    ok?: boolean;
    message?: string;
    deviceCount?: number;
  }

  /**
   * Protobuf JSON representation for EnqueueDownlinkSequenceResponse
   */
  export interface AsProtobufJSON {
    ok?: boolean;
    message?: string;
    deviceCount?: number;
  }
}

/**
 * Message implementation for modulerossakerbms.lorawan.GetDownlinkInfosRequest
 */
export class GetDownlinkInfosRequest implements GrpcMessage {
  static id = 'modulerossakerbms.lorawan.GetDownlinkInfosRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetDownlinkInfosRequest();
    GetDownlinkInfosRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetDownlinkInfosRequest) {
    _instance.customerId = _instance.customerId || '';
    _instance.nodeId = _instance.nodeId || '';
    _instance.nodeTypeId = _instance.nodeTypeId || '';
    _instance.downlinkId = _instance.downlinkId || '';
    _instance.downlinkNodeLabel = _instance.downlinkNodeLabel || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetDownlinkInfosRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.customerId = _reader.readString();
          break;
        case 2:
          _instance.nodeId = _reader.readString();
          break;
        case 3:
          _instance.nodeTypeId = _reader.readString();
          break;
        case 4:
          _instance.downlinkId = _reader.readString();
          break;
        case 5:
          _instance.downlinkNodeLabel = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    GetDownlinkInfosRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetDownlinkInfosRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.customerId) {
      _writer.writeString(1, _instance.customerId);
    }
    if (_instance.nodeId) {
      _writer.writeString(2, _instance.nodeId);
    }
    if (_instance.nodeTypeId) {
      _writer.writeString(3, _instance.nodeTypeId);
    }
    if (_instance.downlinkId) {
      _writer.writeString(4, _instance.downlinkId);
    }
    if (_instance.downlinkNodeLabel) {
      _writer.writeString(5, _instance.downlinkNodeLabel);
    }
  }

  private _customerId?: string;
  private _nodeId?: string;
  private _nodeTypeId?: string;
  private _downlinkId?: string;
  private _downlinkNodeLabel?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetDownlinkInfosRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetDownlinkInfosRequest.AsObject>) {
    _value = _value || {};
    this.customerId = _value.customerId;
    this.nodeId = _value.nodeId;
    this.nodeTypeId = _value.nodeTypeId;
    this.downlinkId = _value.downlinkId;
    this.downlinkNodeLabel = _value.downlinkNodeLabel;
    GetDownlinkInfosRequest.refineValues(this);
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }
  get nodeId(): string | undefined {
    return this._nodeId;
  }
  set nodeId(value: string | undefined) {
    this._nodeId = value;
  }
  get nodeTypeId(): string | undefined {
    return this._nodeTypeId;
  }
  set nodeTypeId(value: string | undefined) {
    this._nodeTypeId = value;
  }
  get downlinkId(): string | undefined {
    return this._downlinkId;
  }
  set downlinkId(value: string | undefined) {
    this._downlinkId = value;
  }
  get downlinkNodeLabel(): string | undefined {
    return this._downlinkNodeLabel;
  }
  set downlinkNodeLabel(value: string | undefined) {
    this._downlinkNodeLabel = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetDownlinkInfosRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetDownlinkInfosRequest.AsObject {
    return {
      customerId: this.customerId,
      nodeId: this.nodeId,
      nodeTypeId: this.nodeTypeId,
      downlinkId: this.downlinkId,
      downlinkNodeLabel: this.downlinkNodeLabel
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetDownlinkInfosRequest.AsProtobufJSON {
    return {
      customerId: this.customerId,
      nodeId: this.nodeId,
      nodeTypeId: this.nodeTypeId,
      downlinkId: this.downlinkId,
      downlinkNodeLabel: this.downlinkNodeLabel
    };
  }
}
export module GetDownlinkInfosRequest {
  /**
   * Standard JavaScript object representation for GetDownlinkInfosRequest
   */
  export interface AsObject {
    customerId?: string;
    nodeId?: string;
    nodeTypeId?: string;
    downlinkId?: string;
    downlinkNodeLabel?: string;
  }

  /**
   * Protobuf JSON representation for GetDownlinkInfosRequest
   */
  export interface AsProtobufJSON {
    customerId?: string;
    nodeId?: string;
    nodeTypeId?: string;
    downlinkId?: string;
    downlinkNodeLabel?: string;
  }
}

/**
 * Message implementation for modulerossakerbms.lorawan.DownlinkInfo
 */
export class DownlinkInfo implements GrpcMessage {
  static id = 'modulerossakerbms.lorawan.DownlinkInfo';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new DownlinkInfo();
    DownlinkInfo.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: DownlinkInfo) {
    _instance.devEui = _instance.devEui || '';
    _instance.timestamp = _instance.timestamp || undefined;
    _instance.result = _instance.result || '';
    _instance.message = _instance.message || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: DownlinkInfo,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.devEui = _reader.readString();
          break;
        case 2:
          _instance.timestamp = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.timestamp,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.result = _reader.readString();
          break;
        case 4:
          _instance.message = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    DownlinkInfo.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: DownlinkInfo,
    _writer: BinaryWriter
  ) {
    if (_instance.devEui) {
      _writer.writeString(1, _instance.devEui);
    }
    if (_instance.timestamp) {
      _writer.writeMessage(
        2,
        _instance.timestamp as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.result) {
      _writer.writeString(3, _instance.result);
    }
    if (_instance.message) {
      _writer.writeString(4, _instance.message);
    }
  }

  private _devEui?: string;
  private _timestamp?: googleProtobuf001.Timestamp;
  private _result?: string;
  private _message?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of DownlinkInfo to deeply clone from
   */
  constructor(_value?: RecursivePartial<DownlinkInfo.AsObject>) {
    _value = _value || {};
    this.devEui = _value.devEui;
    this.timestamp = _value.timestamp
      ? new googleProtobuf001.Timestamp(_value.timestamp)
      : undefined;
    this.result = _value.result;
    this.message = _value.message;
    DownlinkInfo.refineValues(this);
  }
  get devEui(): string | undefined {
    return this._devEui;
  }
  set devEui(value: string | undefined) {
    this._devEui = value;
  }
  get timestamp(): googleProtobuf001.Timestamp | undefined {
    return this._timestamp;
  }
  set timestamp(value: googleProtobuf001.Timestamp | undefined) {
    this._timestamp = value;
  }
  get result(): string | undefined {
    return this._result;
  }
  set result(value: string | undefined) {
    this._result = value;
  }
  get message(): string | undefined {
    return this._message;
  }
  set message(value: string | undefined) {
    this._message = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    DownlinkInfo.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): DownlinkInfo.AsObject {
    return {
      devEui: this.devEui,
      timestamp: this.timestamp ? this.timestamp.toObject() : undefined,
      result: this.result,
      message: this.message
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): DownlinkInfo.AsProtobufJSON {
    return {
      devEui: this.devEui,
      timestamp: this.timestamp ? this.timestamp.toProtobufJSON(options) : null,
      result: this.result,
      message: this.message
    };
  }
}
export module DownlinkInfo {
  /**
   * Standard JavaScript object representation for DownlinkInfo
   */
  export interface AsObject {
    devEui?: string;
    timestamp?: googleProtobuf001.Timestamp.AsObject;
    result?: string;
    message?: string;
  }

  /**
   * Protobuf JSON representation for DownlinkInfo
   */
  export interface AsProtobufJSON {
    devEui?: string;
    timestamp?: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    result?: string;
    message?: string;
  }
}

/**
 * Message implementation for modulerossakerbms.lorawan.GetDownlinkInfosResponse
 */
export class GetDownlinkInfosResponse implements GrpcMessage {
  static id = 'modulerossakerbms.lorawan.GetDownlinkInfosResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetDownlinkInfosResponse();
    GetDownlinkInfosResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetDownlinkInfosResponse) {
    _instance.downlinkInfos = _instance.downlinkInfos || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetDownlinkInfosResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new DownlinkInfo();
          _reader.readMessage(
            messageInitializer1,
            DownlinkInfo.deserializeBinaryFromReader
          );
          (_instance.downlinkInfos = _instance.downlinkInfos || []).push(
            messageInitializer1
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetDownlinkInfosResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetDownlinkInfosResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.downlinkInfos && _instance.downlinkInfos.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.downlinkInfos as any,
        DownlinkInfo.serializeBinaryToWriter
      );
    }
  }

  private _downlinkInfos?: DownlinkInfo[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetDownlinkInfosResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetDownlinkInfosResponse.AsObject>) {
    _value = _value || {};
    this.downlinkInfos = (_value.downlinkInfos || []).map(
      m => new DownlinkInfo(m)
    );
    GetDownlinkInfosResponse.refineValues(this);
  }
  get downlinkInfos(): DownlinkInfo[] | undefined {
    return this._downlinkInfos;
  }
  set downlinkInfos(value: DownlinkInfo[] | undefined) {
    this._downlinkInfos = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetDownlinkInfosResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetDownlinkInfosResponse.AsObject {
    return {
      downlinkInfos: (this.downlinkInfos || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetDownlinkInfosResponse.AsProtobufJSON {
    return {
      downlinkInfos: (this.downlinkInfos || []).map(m =>
        m.toProtobufJSON(options)
      )
    };
  }
}
export module GetDownlinkInfosResponse {
  /**
   * Standard JavaScript object representation for GetDownlinkInfosResponse
   */
  export interface AsObject {
    downlinkInfos?: DownlinkInfo.AsObject[];
  }

  /**
   * Protobuf JSON representation for GetDownlinkInfosResponse
   */
  export interface AsProtobufJSON {
    downlinkInfos?: DownlinkInfo.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for modulerossakerbms.lorawan.ExportDownlinkInfosRequest
 */
export class ExportDownlinkInfosRequest implements GrpcMessage {
  static id = 'modulerossakerbms.lorawan.ExportDownlinkInfosRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ExportDownlinkInfosRequest();
    ExportDownlinkInfosRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ExportDownlinkInfosRequest) {
    _instance.customerId = _instance.customerId || '';
    _instance.nodeId = _instance.nodeId || '';
    _instance.nodeTypeId = _instance.nodeTypeId || '';
    _instance.downlinkId = _instance.downlinkId || '';
    _instance.downlinkNodeLabel = _instance.downlinkNodeLabel || '';
    _instance.chunkSize = _instance.chunkSize || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ExportDownlinkInfosRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.customerId = _reader.readString();
          break;
        case 2:
          _instance.nodeId = _reader.readString();
          break;
        case 3:
          _instance.nodeTypeId = _reader.readString();
          break;
        case 4:
          _instance.downlinkId = _reader.readString();
          break;
        case 5:
          _instance.downlinkNodeLabel = _reader.readString();
          break;
        case 6:
          _instance.chunkSize = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    ExportDownlinkInfosRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ExportDownlinkInfosRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.customerId) {
      _writer.writeString(1, _instance.customerId);
    }
    if (_instance.nodeId) {
      _writer.writeString(2, _instance.nodeId);
    }
    if (_instance.nodeTypeId) {
      _writer.writeString(3, _instance.nodeTypeId);
    }
    if (_instance.downlinkId) {
      _writer.writeString(4, _instance.downlinkId);
    }
    if (_instance.downlinkNodeLabel) {
      _writer.writeString(5, _instance.downlinkNodeLabel);
    }
    if (_instance.chunkSize) {
      _writer.writeInt32(6, _instance.chunkSize);
    }
  }

  private _customerId?: string;
  private _nodeId?: string;
  private _nodeTypeId?: string;
  private _downlinkId?: string;
  private _downlinkNodeLabel?: string;
  private _chunkSize?: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ExportDownlinkInfosRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<ExportDownlinkInfosRequest.AsObject>) {
    _value = _value || {};
    this.customerId = _value.customerId;
    this.nodeId = _value.nodeId;
    this.nodeTypeId = _value.nodeTypeId;
    this.downlinkId = _value.downlinkId;
    this.downlinkNodeLabel = _value.downlinkNodeLabel;
    this.chunkSize = _value.chunkSize;
    ExportDownlinkInfosRequest.refineValues(this);
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }
  get nodeId(): string | undefined {
    return this._nodeId;
  }
  set nodeId(value: string | undefined) {
    this._nodeId = value;
  }
  get nodeTypeId(): string | undefined {
    return this._nodeTypeId;
  }
  set nodeTypeId(value: string | undefined) {
    this._nodeTypeId = value;
  }
  get downlinkId(): string | undefined {
    return this._downlinkId;
  }
  set downlinkId(value: string | undefined) {
    this._downlinkId = value;
  }
  get downlinkNodeLabel(): string | undefined {
    return this._downlinkNodeLabel;
  }
  set downlinkNodeLabel(value: string | undefined) {
    this._downlinkNodeLabel = value;
  }
  get chunkSize(): number | undefined {
    return this._chunkSize;
  }
  set chunkSize(value: number | undefined) {
    this._chunkSize = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ExportDownlinkInfosRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ExportDownlinkInfosRequest.AsObject {
    return {
      customerId: this.customerId,
      nodeId: this.nodeId,
      nodeTypeId: this.nodeTypeId,
      downlinkId: this.downlinkId,
      downlinkNodeLabel: this.downlinkNodeLabel,
      chunkSize: this.chunkSize
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ExportDownlinkInfosRequest.AsProtobufJSON {
    return {
      customerId: this.customerId,
      nodeId: this.nodeId,
      nodeTypeId: this.nodeTypeId,
      downlinkId: this.downlinkId,
      downlinkNodeLabel: this.downlinkNodeLabel,
      chunkSize: this.chunkSize
    };
  }
}
export module ExportDownlinkInfosRequest {
  /**
   * Standard JavaScript object representation for ExportDownlinkInfosRequest
   */
  export interface AsObject {
    customerId?: string;
    nodeId?: string;
    nodeTypeId?: string;
    downlinkId?: string;
    downlinkNodeLabel?: string;
    chunkSize?: number;
  }

  /**
   * Protobuf JSON representation for ExportDownlinkInfosRequest
   */
  export interface AsProtobufJSON {
    customerId?: string;
    nodeId?: string;
    nodeTypeId?: string;
    downlinkId?: string;
    downlinkNodeLabel?: string;
    chunkSize?: number;
  }
}

/**
 * Message implementation for modulerossakerbms.lorawan.GetConfiguredDownlinkSequencesRequest
 */
export class GetConfiguredDownlinkSequencesRequest implements GrpcMessage {
  static id = 'modulerossakerbms.lorawan.GetConfiguredDownlinkSequencesRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetConfiguredDownlinkSequencesRequest();
    GetConfiguredDownlinkSequencesRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetConfiguredDownlinkSequencesRequest) {
    _instance.deviceType = _instance.deviceType || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetConfiguredDownlinkSequencesRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.deviceType = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    GetConfiguredDownlinkSequencesRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetConfiguredDownlinkSequencesRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.deviceType) {
      _writer.writeString(1, _instance.deviceType);
    }
  }

  private _deviceType?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetConfiguredDownlinkSequencesRequest to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<GetConfiguredDownlinkSequencesRequest.AsObject>
  ) {
    _value = _value || {};
    this.deviceType = _value.deviceType;
    GetConfiguredDownlinkSequencesRequest.refineValues(this);
  }
  get deviceType(): string | undefined {
    return this._deviceType;
  }
  set deviceType(value: string | undefined) {
    this._deviceType = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetConfiguredDownlinkSequencesRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetConfiguredDownlinkSequencesRequest.AsObject {
    return {
      deviceType: this.deviceType
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetConfiguredDownlinkSequencesRequest.AsProtobufJSON {
    return {
      deviceType: this.deviceType
    };
  }
}
export module GetConfiguredDownlinkSequencesRequest {
  /**
   * Standard JavaScript object representation for GetConfiguredDownlinkSequencesRequest
   */
  export interface AsObject {
    deviceType?: string;
  }

  /**
   * Protobuf JSON representation for GetConfiguredDownlinkSequencesRequest
   */
  export interface AsProtobufJSON {
    deviceType?: string;
  }
}

/**
 * Message implementation for modulerossakerbms.lorawan.GetConfiguredDownlinkSequencesResponse
 */
export class GetConfiguredDownlinkSequencesResponse implements GrpcMessage {
  static id =
    'modulerossakerbms.lorawan.GetConfiguredDownlinkSequencesResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetConfiguredDownlinkSequencesResponse();
    GetConfiguredDownlinkSequencesResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetConfiguredDownlinkSequencesResponse) {
    _instance.downlinkSequences = _instance.downlinkSequences || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetConfiguredDownlinkSequencesResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new ConfiguredDownlinkSequence();
          _reader.readMessage(
            messageInitializer1,
            ConfiguredDownlinkSequence.deserializeBinaryFromReader
          );
          (_instance.downlinkSequences =
            _instance.downlinkSequences || []).push(messageInitializer1);
          break;
        default:
          _reader.skipField();
      }
    }

    GetConfiguredDownlinkSequencesResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetConfiguredDownlinkSequencesResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.downlinkSequences && _instance.downlinkSequences.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.downlinkSequences as any,
        ConfiguredDownlinkSequence.serializeBinaryToWriter
      );
    }
  }

  private _downlinkSequences?: ConfiguredDownlinkSequence[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetConfiguredDownlinkSequencesResponse to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<GetConfiguredDownlinkSequencesResponse.AsObject>
  ) {
    _value = _value || {};
    this.downlinkSequences = (_value.downlinkSequences || []).map(
      m => new ConfiguredDownlinkSequence(m)
    );
    GetConfiguredDownlinkSequencesResponse.refineValues(this);
  }
  get downlinkSequences(): ConfiguredDownlinkSequence[] | undefined {
    return this._downlinkSequences;
  }
  set downlinkSequences(value: ConfiguredDownlinkSequence[] | undefined) {
    this._downlinkSequences = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetConfiguredDownlinkSequencesResponse.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetConfiguredDownlinkSequencesResponse.AsObject {
    return {
      downlinkSequences: (this.downlinkSequences || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetConfiguredDownlinkSequencesResponse.AsProtobufJSON {
    return {
      downlinkSequences: (this.downlinkSequences || []).map(m =>
        m.toProtobufJSON(options)
      )
    };
  }
}
export module GetConfiguredDownlinkSequencesResponse {
  /**
   * Standard JavaScript object representation for GetConfiguredDownlinkSequencesResponse
   */
  export interface AsObject {
    downlinkSequences?: ConfiguredDownlinkSequence.AsObject[];
  }

  /**
   * Protobuf JSON representation for GetConfiguredDownlinkSequencesResponse
   */
  export interface AsProtobufJSON {
    downlinkSequences?: ConfiguredDownlinkSequence.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for modulerossakerbms.lorawan.GetConfiguredDownlinkItemsRequest
 */
export class GetConfiguredDownlinkItemsRequest implements GrpcMessage {
  static id = 'modulerossakerbms.lorawan.GetConfiguredDownlinkItemsRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetConfiguredDownlinkItemsRequest();
    GetConfiguredDownlinkItemsRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetConfiguredDownlinkItemsRequest) {
    _instance.deviceType = _instance.deviceType || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetConfiguredDownlinkItemsRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.deviceType = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    GetConfiguredDownlinkItemsRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetConfiguredDownlinkItemsRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.deviceType) {
      _writer.writeString(1, _instance.deviceType);
    }
  }

  private _deviceType?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetConfiguredDownlinkItemsRequest to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<GetConfiguredDownlinkItemsRequest.AsObject>
  ) {
    _value = _value || {};
    this.deviceType = _value.deviceType;
    GetConfiguredDownlinkItemsRequest.refineValues(this);
  }
  get deviceType(): string | undefined {
    return this._deviceType;
  }
  set deviceType(value: string | undefined) {
    this._deviceType = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetConfiguredDownlinkItemsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetConfiguredDownlinkItemsRequest.AsObject {
    return {
      deviceType: this.deviceType
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetConfiguredDownlinkItemsRequest.AsProtobufJSON {
    return {
      deviceType: this.deviceType
    };
  }
}
export module GetConfiguredDownlinkItemsRequest {
  /**
   * Standard JavaScript object representation for GetConfiguredDownlinkItemsRequest
   */
  export interface AsObject {
    deviceType?: string;
  }

  /**
   * Protobuf JSON representation for GetConfiguredDownlinkItemsRequest
   */
  export interface AsProtobufJSON {
    deviceType?: string;
  }
}

/**
 * Message implementation for modulerossakerbms.lorawan.GetConfiguredDownlinkItemsResponse
 */
export class GetConfiguredDownlinkItemsResponse implements GrpcMessage {
  static id = 'modulerossakerbms.lorawan.GetConfiguredDownlinkItemsResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetConfiguredDownlinkItemsResponse();
    GetConfiguredDownlinkItemsResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetConfiguredDownlinkItemsResponse) {
    _instance.downlinkItems = _instance.downlinkItems || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetConfiguredDownlinkItemsResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new ConfiguredDownlinkItem();
          _reader.readMessage(
            messageInitializer1,
            ConfiguredDownlinkItem.deserializeBinaryFromReader
          );
          (_instance.downlinkItems = _instance.downlinkItems || []).push(
            messageInitializer1
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetConfiguredDownlinkItemsResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetConfiguredDownlinkItemsResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.downlinkItems && _instance.downlinkItems.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.downlinkItems as any,
        ConfiguredDownlinkItem.serializeBinaryToWriter
      );
    }
  }

  private _downlinkItems?: ConfiguredDownlinkItem[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetConfiguredDownlinkItemsResponse to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<GetConfiguredDownlinkItemsResponse.AsObject>
  ) {
    _value = _value || {};
    this.downlinkItems = (_value.downlinkItems || []).map(
      m => new ConfiguredDownlinkItem(m)
    );
    GetConfiguredDownlinkItemsResponse.refineValues(this);
  }
  get downlinkItems(): ConfiguredDownlinkItem[] | undefined {
    return this._downlinkItems;
  }
  set downlinkItems(value: ConfiguredDownlinkItem[] | undefined) {
    this._downlinkItems = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetConfiguredDownlinkItemsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetConfiguredDownlinkItemsResponse.AsObject {
    return {
      downlinkItems: (this.downlinkItems || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetConfiguredDownlinkItemsResponse.AsProtobufJSON {
    return {
      downlinkItems: (this.downlinkItems || []).map(m =>
        m.toProtobufJSON(options)
      )
    };
  }
}
export module GetConfiguredDownlinkItemsResponse {
  /**
   * Standard JavaScript object representation for GetConfiguredDownlinkItemsResponse
   */
  export interface AsObject {
    downlinkItems?: ConfiguredDownlinkItem.AsObject[];
  }

  /**
   * Protobuf JSON representation for GetConfiguredDownlinkItemsResponse
   */
  export interface AsProtobufJSON {
    downlinkItems?: ConfiguredDownlinkItem.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for modulerossakerbms.lorawan.LoRaWANOperator
 */
export class LoRaWANOperator implements GrpcMessage {
  static id = 'modulerossakerbms.lorawan.LoRaWANOperator';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new LoRaWANOperator();
    LoRaWANOperator.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: LoRaWANOperator) {
    _instance.id = _instance.id || '';
    _instance.name = _instance.name || '';
    _instance.pbDefault = _instance.pbDefault || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: LoRaWANOperator,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        case 2:
          _instance.name = _reader.readString();
          break;
        case 3:
          _instance.pbDefault = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    LoRaWANOperator.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: LoRaWANOperator,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
    if (_instance.name) {
      _writer.writeString(2, _instance.name);
    }
    if (_instance.pbDefault) {
      _writer.writeBool(3, _instance.pbDefault);
    }
  }

  private _id?: string;
  private _name?: string;
  private _pbDefault?: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of LoRaWANOperator to deeply clone from
   */
  constructor(_value?: RecursivePartial<LoRaWANOperator.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.name = _value.name;
    this.pbDefault = _value.pbDefault;
    LoRaWANOperator.refineValues(this);
  }
  get id(): string | undefined {
    return this._id;
  }
  set id(value: string | undefined) {
    this._id = value;
  }
  get name(): string | undefined {
    return this._name;
  }
  set name(value: string | undefined) {
    this._name = value;
  }
  get pbDefault(): boolean | undefined {
    return this._pbDefault;
  }
  set pbDefault(value: boolean | undefined) {
    this._pbDefault = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    LoRaWANOperator.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): LoRaWANOperator.AsObject {
    return {
      id: this.id,
      name: this.name,
      pbDefault: this.pbDefault
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): LoRaWANOperator.AsProtobufJSON {
    return {
      id: this.id,
      name: this.name,
      pbDefault: this.pbDefault
    };
  }
}
export module LoRaWANOperator {
  /**
   * Standard JavaScript object representation for LoRaWANOperator
   */
  export interface AsObject {
    id?: string;
    name?: string;
    pbDefault?: boolean;
  }

  /**
   * Protobuf JSON representation for LoRaWANOperator
   */
  export interface AsProtobufJSON {
    id?: string;
    name?: string;
    pbDefault?: boolean;
  }
}

/**
 * Message implementation for modulerossakerbms.lorawan.GetOperatorsResponse
 */
export class GetOperatorsResponse implements GrpcMessage {
  static id = 'modulerossakerbms.lorawan.GetOperatorsResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetOperatorsResponse();
    GetOperatorsResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetOperatorsResponse) {
    _instance.operators = _instance.operators || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetOperatorsResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new LoRaWANOperator();
          _reader.readMessage(
            messageInitializer1,
            LoRaWANOperator.deserializeBinaryFromReader
          );
          (_instance.operators = _instance.operators || []).push(
            messageInitializer1
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetOperatorsResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetOperatorsResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.operators && _instance.operators.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.operators as any,
        LoRaWANOperator.serializeBinaryToWriter
      );
    }
  }

  private _operators?: LoRaWANOperator[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetOperatorsResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetOperatorsResponse.AsObject>) {
    _value = _value || {};
    this.operators = (_value.operators || []).map(m => new LoRaWANOperator(m));
    GetOperatorsResponse.refineValues(this);
  }
  get operators(): LoRaWANOperator[] | undefined {
    return this._operators;
  }
  set operators(value: LoRaWANOperator[] | undefined) {
    this._operators = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetOperatorsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetOperatorsResponse.AsObject {
    return {
      operators: (this.operators || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetOperatorsResponse.AsProtobufJSON {
    return {
      operators: (this.operators || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module GetOperatorsResponse {
  /**
   * Standard JavaScript object representation for GetOperatorsResponse
   */
  export interface AsObject {
    operators?: LoRaWANOperator.AsObject[];
  }

  /**
   * Protobuf JSON representation for GetOperatorsResponse
   */
  export interface AsProtobufJSON {
    operators?: LoRaWANOperator.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for modulerossakerbms.lorawan.ClearDownlinkQueueRequest
 */
export class ClearDownlinkQueueRequest implements GrpcMessage {
  static id = 'modulerossakerbms.lorawan.ClearDownlinkQueueRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ClearDownlinkQueueRequest();
    ClearDownlinkQueueRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ClearDownlinkQueueRequest) {
    _instance.customerId = _instance.customerId || '';
    _instance.nodeId = _instance.nodeId || '';
    _instance.nodeTypeId = _instance.nodeTypeId || '';
    _instance.operator = _instance.operator || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ClearDownlinkQueueRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.customerId = _reader.readString();
          break;
        case 2:
          _instance.nodeId = _reader.readString();
          break;
        case 3:
          _instance.nodeTypeId = _reader.readString();
          break;
        case 4:
          _instance.operator = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    ClearDownlinkQueueRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ClearDownlinkQueueRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.customerId) {
      _writer.writeString(1, _instance.customerId);
    }
    if (_instance.nodeId) {
      _writer.writeString(2, _instance.nodeId);
    }
    if (_instance.nodeTypeId) {
      _writer.writeString(3, _instance.nodeTypeId);
    }
    if (_instance.operator) {
      _writer.writeString(4, _instance.operator);
    }
  }

  private _customerId?: string;
  private _nodeId?: string;
  private _nodeTypeId?: string;
  private _operator?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ClearDownlinkQueueRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<ClearDownlinkQueueRequest.AsObject>) {
    _value = _value || {};
    this.customerId = _value.customerId;
    this.nodeId = _value.nodeId;
    this.nodeTypeId = _value.nodeTypeId;
    this.operator = _value.operator;
    ClearDownlinkQueueRequest.refineValues(this);
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }
  get nodeId(): string | undefined {
    return this._nodeId;
  }
  set nodeId(value: string | undefined) {
    this._nodeId = value;
  }
  get nodeTypeId(): string | undefined {
    return this._nodeTypeId;
  }
  set nodeTypeId(value: string | undefined) {
    this._nodeTypeId = value;
  }
  get operator(): string | undefined {
    return this._operator;
  }
  set operator(value: string | undefined) {
    this._operator = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ClearDownlinkQueueRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ClearDownlinkQueueRequest.AsObject {
    return {
      customerId: this.customerId,
      nodeId: this.nodeId,
      nodeTypeId: this.nodeTypeId,
      operator: this.operator
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ClearDownlinkQueueRequest.AsProtobufJSON {
    return {
      customerId: this.customerId,
      nodeId: this.nodeId,
      nodeTypeId: this.nodeTypeId,
      operator: this.operator
    };
  }
}
export module ClearDownlinkQueueRequest {
  /**
   * Standard JavaScript object representation for ClearDownlinkQueueRequest
   */
  export interface AsObject {
    customerId?: string;
    nodeId?: string;
    nodeTypeId?: string;
    operator?: string;
  }

  /**
   * Protobuf JSON representation for ClearDownlinkQueueRequest
   */
  export interface AsProtobufJSON {
    customerId?: string;
    nodeId?: string;
    nodeTypeId?: string;
    operator?: string;
  }
}

/**
 * Message implementation for modulerossakerbms.lorawan.ClearDownlinkQueueResponse
 */
export class ClearDownlinkQueueResponse implements GrpcMessage {
  static id = 'modulerossakerbms.lorawan.ClearDownlinkQueueResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ClearDownlinkQueueResponse();
    ClearDownlinkQueueResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ClearDownlinkQueueResponse) {
    _instance.ok = _instance.ok || false;
    _instance.message = _instance.message || '';
    _instance.deviceCount = _instance.deviceCount || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ClearDownlinkQueueResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.ok = _reader.readBool();
          break;
        case 2:
          _instance.message = _reader.readString();
          break;
        case 3:
          _instance.deviceCount = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    ClearDownlinkQueueResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ClearDownlinkQueueResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.ok) {
      _writer.writeBool(1, _instance.ok);
    }
    if (_instance.message) {
      _writer.writeString(2, _instance.message);
    }
    if (_instance.deviceCount) {
      _writer.writeInt32(3, _instance.deviceCount);
    }
  }

  private _ok?: boolean;
  private _message?: string;
  private _deviceCount?: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ClearDownlinkQueueResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<ClearDownlinkQueueResponse.AsObject>) {
    _value = _value || {};
    this.ok = _value.ok;
    this.message = _value.message;
    this.deviceCount = _value.deviceCount;
    ClearDownlinkQueueResponse.refineValues(this);
  }
  get ok(): boolean | undefined {
    return this._ok;
  }
  set ok(value: boolean | undefined) {
    this._ok = value;
  }
  get message(): string | undefined {
    return this._message;
  }
  set message(value: string | undefined) {
    this._message = value;
  }
  get deviceCount(): number | undefined {
    return this._deviceCount;
  }
  set deviceCount(value: number | undefined) {
    this._deviceCount = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ClearDownlinkQueueResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ClearDownlinkQueueResponse.AsObject {
    return {
      ok: this.ok,
      message: this.message,
      deviceCount: this.deviceCount
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ClearDownlinkQueueResponse.AsProtobufJSON {
    return {
      ok: this.ok,
      message: this.message,
      deviceCount: this.deviceCount
    };
  }
}
export module ClearDownlinkQueueResponse {
  /**
   * Standard JavaScript object representation for ClearDownlinkQueueResponse
   */
  export interface AsObject {
    ok?: boolean;
    message?: string;
    deviceCount?: number;
  }

  /**
   * Protobuf JSON representation for ClearDownlinkQueueResponse
   */
  export interface AsProtobufJSON {
    ok?: boolean;
    message?: string;
    deviceCount?: number;
  }
}

/**
 * Message implementation for modulerossakerbms.lorawan.GetDownlinkItemsRequest
 */
export class GetDownlinkItemsRequest implements GrpcMessage {
  static id = 'modulerossakerbms.lorawan.GetDownlinkItemsRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetDownlinkItemsRequest();
    GetDownlinkItemsRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetDownlinkItemsRequest) {
    _instance.customerId = _instance.customerId || '';
    _instance.nodeId = _instance.nodeId || '';
    _instance.nodeTypeId = _instance.nodeTypeId || '';
    _instance.operator = _instance.operator || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetDownlinkItemsRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.customerId = _reader.readString();
          break;
        case 2:
          _instance.nodeId = _reader.readString();
          break;
        case 3:
          _instance.nodeTypeId = _reader.readString();
          break;
        case 4:
          _instance.operator = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    GetDownlinkItemsRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetDownlinkItemsRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.customerId) {
      _writer.writeString(1, _instance.customerId);
    }
    if (_instance.nodeId) {
      _writer.writeString(2, _instance.nodeId);
    }
    if (_instance.nodeTypeId) {
      _writer.writeString(3, _instance.nodeTypeId);
    }
    if (_instance.operator) {
      _writer.writeString(4, _instance.operator);
    }
  }

  private _customerId?: string;
  private _nodeId?: string;
  private _nodeTypeId?: string;
  private _operator?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetDownlinkItemsRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetDownlinkItemsRequest.AsObject>) {
    _value = _value || {};
    this.customerId = _value.customerId;
    this.nodeId = _value.nodeId;
    this.nodeTypeId = _value.nodeTypeId;
    this.operator = _value.operator;
    GetDownlinkItemsRequest.refineValues(this);
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }
  get nodeId(): string | undefined {
    return this._nodeId;
  }
  set nodeId(value: string | undefined) {
    this._nodeId = value;
  }
  get nodeTypeId(): string | undefined {
    return this._nodeTypeId;
  }
  set nodeTypeId(value: string | undefined) {
    this._nodeTypeId = value;
  }
  get operator(): string | undefined {
    return this._operator;
  }
  set operator(value: string | undefined) {
    this._operator = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetDownlinkItemsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetDownlinkItemsRequest.AsObject {
    return {
      customerId: this.customerId,
      nodeId: this.nodeId,
      nodeTypeId: this.nodeTypeId,
      operator: this.operator
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetDownlinkItemsRequest.AsProtobufJSON {
    return {
      customerId: this.customerId,
      nodeId: this.nodeId,
      nodeTypeId: this.nodeTypeId,
      operator: this.operator
    };
  }
}
export module GetDownlinkItemsRequest {
  /**
   * Standard JavaScript object representation for GetDownlinkItemsRequest
   */
  export interface AsObject {
    customerId?: string;
    nodeId?: string;
    nodeTypeId?: string;
    operator?: string;
  }

  /**
   * Protobuf JSON representation for GetDownlinkItemsRequest
   */
  export interface AsProtobufJSON {
    customerId?: string;
    nodeId?: string;
    nodeTypeId?: string;
    operator?: string;
  }
}

/**
 * Message implementation for modulerossakerbms.lorawan.GetDownlinkItemsResponse
 */
export class GetDownlinkItemsResponse implements GrpcMessage {
  static id = 'modulerossakerbms.lorawan.GetDownlinkItemsResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetDownlinkItemsResponse();
    GetDownlinkItemsResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetDownlinkItemsResponse) {
    _instance.downlinkItems = _instance.downlinkItems || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetDownlinkItemsResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new DownlinkItem();
          _reader.readMessage(
            messageInitializer1,
            DownlinkItem.deserializeBinaryFromReader
          );
          (_instance.downlinkItems = _instance.downlinkItems || []).push(
            messageInitializer1
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetDownlinkItemsResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetDownlinkItemsResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.downlinkItems && _instance.downlinkItems.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.downlinkItems as any,
        DownlinkItem.serializeBinaryToWriter
      );
    }
  }

  private _downlinkItems?: DownlinkItem[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetDownlinkItemsResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetDownlinkItemsResponse.AsObject>) {
    _value = _value || {};
    this.downlinkItems = (_value.downlinkItems || []).map(
      m => new DownlinkItem(m)
    );
    GetDownlinkItemsResponse.refineValues(this);
  }
  get downlinkItems(): DownlinkItem[] | undefined {
    return this._downlinkItems;
  }
  set downlinkItems(value: DownlinkItem[] | undefined) {
    this._downlinkItems = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetDownlinkItemsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetDownlinkItemsResponse.AsObject {
    return {
      downlinkItems: (this.downlinkItems || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetDownlinkItemsResponse.AsProtobufJSON {
    return {
      downlinkItems: (this.downlinkItems || []).map(m =>
        m.toProtobufJSON(options)
      )
    };
  }
}
export module GetDownlinkItemsResponse {
  /**
   * Standard JavaScript object representation for GetDownlinkItemsResponse
   */
  export interface AsObject {
    downlinkItems?: DownlinkItem.AsObject[];
  }

  /**
   * Protobuf JSON representation for GetDownlinkItemsResponse
   */
  export interface AsProtobufJSON {
    downlinkItems?: DownlinkItem.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for modulerossakerbms.lorawan.GetGatewayRequest
 */
export class GetGatewayRequest implements GrpcMessage {
  static id = 'modulerossakerbms.lorawan.GetGatewayRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetGatewayRequest();
    GetGatewayRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetGatewayRequest) {
    _instance.customerId = _instance.customerId || '';
    _instance.nodeId = _instance.nodeId || '';
    _instance.nodeTypeId = _instance.nodeTypeId || '';
    _instance.operator = _instance.operator || '';
    _instance.gatewayId = _instance.gatewayId || '';
    _instance.updateLocation = _instance.updateLocation || false;
    _instance.forceUpdateLocation = _instance.forceUpdateLocation || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetGatewayRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.customerId = _reader.readString();
          break;
        case 2:
          _instance.nodeId = _reader.readString();
          break;
        case 3:
          _instance.nodeTypeId = _reader.readString();
          break;
        case 4:
          _instance.operator = _reader.readString();
          break;
        case 5:
          _instance.gatewayId = _reader.readString();
          break;
        case 6:
          _instance.updateLocation = _reader.readBool();
          break;
        case 7:
          _instance.forceUpdateLocation = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    GetGatewayRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetGatewayRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.customerId) {
      _writer.writeString(1, _instance.customerId);
    }
    if (_instance.nodeId) {
      _writer.writeString(2, _instance.nodeId);
    }
    if (_instance.nodeTypeId) {
      _writer.writeString(3, _instance.nodeTypeId);
    }
    if (_instance.operator) {
      _writer.writeString(4, _instance.operator);
    }
    if (_instance.gatewayId) {
      _writer.writeString(5, _instance.gatewayId);
    }
    if (_instance.updateLocation) {
      _writer.writeBool(6, _instance.updateLocation);
    }
    if (_instance.forceUpdateLocation) {
      _writer.writeBool(7, _instance.forceUpdateLocation);
    }
  }

  private _customerId?: string;
  private _nodeId?: string;
  private _nodeTypeId?: string;
  private _operator?: string;
  private _gatewayId?: string;
  private _updateLocation?: boolean;
  private _forceUpdateLocation?: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetGatewayRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetGatewayRequest.AsObject>) {
    _value = _value || {};
    this.customerId = _value.customerId;
    this.nodeId = _value.nodeId;
    this.nodeTypeId = _value.nodeTypeId;
    this.operator = _value.operator;
    this.gatewayId = _value.gatewayId;
    this.updateLocation = _value.updateLocation;
    this.forceUpdateLocation = _value.forceUpdateLocation;
    GetGatewayRequest.refineValues(this);
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }
  get nodeId(): string | undefined {
    return this._nodeId;
  }
  set nodeId(value: string | undefined) {
    this._nodeId = value;
  }
  get nodeTypeId(): string | undefined {
    return this._nodeTypeId;
  }
  set nodeTypeId(value: string | undefined) {
    this._nodeTypeId = value;
  }
  get operator(): string | undefined {
    return this._operator;
  }
  set operator(value: string | undefined) {
    this._operator = value;
  }
  get gatewayId(): string | undefined {
    return this._gatewayId;
  }
  set gatewayId(value: string | undefined) {
    this._gatewayId = value;
  }
  get updateLocation(): boolean | undefined {
    return this._updateLocation;
  }
  set updateLocation(value: boolean | undefined) {
    this._updateLocation = value;
  }
  get forceUpdateLocation(): boolean | undefined {
    return this._forceUpdateLocation;
  }
  set forceUpdateLocation(value: boolean | undefined) {
    this._forceUpdateLocation = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetGatewayRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetGatewayRequest.AsObject {
    return {
      customerId: this.customerId,
      nodeId: this.nodeId,
      nodeTypeId: this.nodeTypeId,
      operator: this.operator,
      gatewayId: this.gatewayId,
      updateLocation: this.updateLocation,
      forceUpdateLocation: this.forceUpdateLocation
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetGatewayRequest.AsProtobufJSON {
    return {
      customerId: this.customerId,
      nodeId: this.nodeId,
      nodeTypeId: this.nodeTypeId,
      operator: this.operator,
      gatewayId: this.gatewayId,
      updateLocation: this.updateLocation,
      forceUpdateLocation: this.forceUpdateLocation
    };
  }
}
export module GetGatewayRequest {
  /**
   * Standard JavaScript object representation for GetGatewayRequest
   */
  export interface AsObject {
    customerId?: string;
    nodeId?: string;
    nodeTypeId?: string;
    operator?: string;
    gatewayId?: string;
    updateLocation?: boolean;
    forceUpdateLocation?: boolean;
  }

  /**
   * Protobuf JSON representation for GetGatewayRequest
   */
  export interface AsProtobufJSON {
    customerId?: string;
    nodeId?: string;
    nodeTypeId?: string;
    operator?: string;
    gatewayId?: string;
    updateLocation?: boolean;
    forceUpdateLocation?: boolean;
  }
}

/**
 * Message implementation for modulerossakerbms.lorawan.KeyValuePair
 */
export class KeyValuePair implements GrpcMessage {
  static id = 'modulerossakerbms.lorawan.KeyValuePair';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new KeyValuePair();
    KeyValuePair.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: KeyValuePair) {
    _instance.key = _instance.key || '';
    _instance.value = _instance.value || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: KeyValuePair,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.key = _reader.readString();
          break;
        case 2:
          _instance.value = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    KeyValuePair.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: KeyValuePair,
    _writer: BinaryWriter
  ) {
    if (_instance.key) {
      _writer.writeString(1, _instance.key);
    }
    if (_instance.value) {
      _writer.writeString(2, _instance.value);
    }
  }

  private _key?: string;
  private _value?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of KeyValuePair to deeply clone from
   */
  constructor(_value?: RecursivePartial<KeyValuePair.AsObject>) {
    _value = _value || {};
    this.key = _value.key;
    this.value = _value.value;
    KeyValuePair.refineValues(this);
  }
  get key(): string | undefined {
    return this._key;
  }
  set key(value: string | undefined) {
    this._key = value;
  }
  get value(): string | undefined {
    return this._value;
  }
  set value(value: string | undefined) {
    this._value = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    KeyValuePair.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): KeyValuePair.AsObject {
    return {
      key: this.key,
      value: this.value
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): KeyValuePair.AsProtobufJSON {
    return {
      key: this.key,
      value: this.value
    };
  }
}
export module KeyValuePair {
  /**
   * Standard JavaScript object representation for KeyValuePair
   */
  export interface AsObject {
    key?: string;
    value?: string;
  }

  /**
   * Protobuf JSON representation for KeyValuePair
   */
  export interface AsProtobufJSON {
    key?: string;
    value?: string;
  }
}

/**
 * Message implementation for modulerossakerbms.lorawan.GetGatewayResponse
 */
export class GetGatewayResponse implements GrpcMessage {
  static id = 'modulerossakerbms.lorawan.GetGatewayResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetGatewayResponse();
    GetGatewayResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetGatewayResponse) {
    _instance.gatewayId = _instance.gatewayId || '';
    _instance.name = _instance.name || '';
    _instance.description = _instance.description || '';
    _instance.longitude = _instance.longitude || 0;
    _instance.latitude = _instance.latitude || 0;
    _instance.createdAt = _instance.createdAt || undefined;
    _instance.modifiedAt = _instance.modifiedAt || undefined;
    _instance.lastSeenAt = _instance.lastSeenAt || undefined;
    _instance.tags = _instance.tags || [];
    _instance.metaData = _instance.metaData || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetGatewayResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.gatewayId = _reader.readString();
          break;
        case 2:
          _instance.name = _reader.readString();
          break;
        case 3:
          _instance.description = _reader.readString();
          break;
        case 4:
          _instance.longitude = _reader.readDouble();
          break;
        case 5:
          _instance.latitude = _reader.readDouble();
          break;
        case 6:
          _instance.createdAt = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.createdAt,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 7:
          _instance.modifiedAt = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.modifiedAt,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 8:
          _instance.lastSeenAt = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.lastSeenAt,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 9:
          const messageInitializer9 = new KeyValuePair();
          _reader.readMessage(
            messageInitializer9,
            KeyValuePair.deserializeBinaryFromReader
          );
          (_instance.tags = _instance.tags || []).push(messageInitializer9);
          break;
        case 10:
          const messageInitializer10 = new KeyValuePair();
          _reader.readMessage(
            messageInitializer10,
            KeyValuePair.deserializeBinaryFromReader
          );
          (_instance.metaData = _instance.metaData || []).push(
            messageInitializer10
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetGatewayResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetGatewayResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.gatewayId) {
      _writer.writeString(1, _instance.gatewayId);
    }
    if (_instance.name) {
      _writer.writeString(2, _instance.name);
    }
    if (_instance.description) {
      _writer.writeString(3, _instance.description);
    }
    if (_instance.longitude) {
      _writer.writeDouble(4, _instance.longitude);
    }
    if (_instance.latitude) {
      _writer.writeDouble(5, _instance.latitude);
    }
    if (_instance.createdAt) {
      _writer.writeMessage(
        6,
        _instance.createdAt as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.modifiedAt) {
      _writer.writeMessage(
        7,
        _instance.modifiedAt as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.lastSeenAt) {
      _writer.writeMessage(
        8,
        _instance.lastSeenAt as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.tags && _instance.tags.length) {
      _writer.writeRepeatedMessage(
        9,
        _instance.tags as any,
        KeyValuePair.serializeBinaryToWriter
      );
    }
    if (_instance.metaData && _instance.metaData.length) {
      _writer.writeRepeatedMessage(
        10,
        _instance.metaData as any,
        KeyValuePair.serializeBinaryToWriter
      );
    }
  }

  private _gatewayId?: string;
  private _name?: string;
  private _description?: string;
  private _longitude?: number;
  private _latitude?: number;
  private _createdAt?: googleProtobuf001.Timestamp;
  private _modifiedAt?: googleProtobuf001.Timestamp;
  private _lastSeenAt?: googleProtobuf001.Timestamp;
  private _tags?: KeyValuePair[];
  private _metaData?: KeyValuePair[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetGatewayResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetGatewayResponse.AsObject>) {
    _value = _value || {};
    this.gatewayId = _value.gatewayId;
    this.name = _value.name;
    this.description = _value.description;
    this.longitude = _value.longitude;
    this.latitude = _value.latitude;
    this.createdAt = _value.createdAt
      ? new googleProtobuf001.Timestamp(_value.createdAt)
      : undefined;
    this.modifiedAt = _value.modifiedAt
      ? new googleProtobuf001.Timestamp(_value.modifiedAt)
      : undefined;
    this.lastSeenAt = _value.lastSeenAt
      ? new googleProtobuf001.Timestamp(_value.lastSeenAt)
      : undefined;
    this.tags = (_value.tags || []).map(m => new KeyValuePair(m));
    this.metaData = (_value.metaData || []).map(m => new KeyValuePair(m));
    GetGatewayResponse.refineValues(this);
  }
  get gatewayId(): string | undefined {
    return this._gatewayId;
  }
  set gatewayId(value: string | undefined) {
    this._gatewayId = value;
  }
  get name(): string | undefined {
    return this._name;
  }
  set name(value: string | undefined) {
    this._name = value;
  }
  get description(): string | undefined {
    return this._description;
  }
  set description(value: string | undefined) {
    this._description = value;
  }
  get longitude(): number | undefined {
    return this._longitude;
  }
  set longitude(value: number | undefined) {
    this._longitude = value;
  }
  get latitude(): number | undefined {
    return this._latitude;
  }
  set latitude(value: number | undefined) {
    this._latitude = value;
  }
  get createdAt(): googleProtobuf001.Timestamp | undefined {
    return this._createdAt;
  }
  set createdAt(value: googleProtobuf001.Timestamp | undefined) {
    this._createdAt = value;
  }
  get modifiedAt(): googleProtobuf001.Timestamp | undefined {
    return this._modifiedAt;
  }
  set modifiedAt(value: googleProtobuf001.Timestamp | undefined) {
    this._modifiedAt = value;
  }
  get lastSeenAt(): googleProtobuf001.Timestamp | undefined {
    return this._lastSeenAt;
  }
  set lastSeenAt(value: googleProtobuf001.Timestamp | undefined) {
    this._lastSeenAt = value;
  }
  get tags(): KeyValuePair[] | undefined {
    return this._tags;
  }
  set tags(value: KeyValuePair[] | undefined) {
    this._tags = value;
  }
  get metaData(): KeyValuePair[] | undefined {
    return this._metaData;
  }
  set metaData(value: KeyValuePair[] | undefined) {
    this._metaData = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetGatewayResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetGatewayResponse.AsObject {
    return {
      gatewayId: this.gatewayId,
      name: this.name,
      description: this.description,
      longitude: this.longitude,
      latitude: this.latitude,
      createdAt: this.createdAt ? this.createdAt.toObject() : undefined,
      modifiedAt: this.modifiedAt ? this.modifiedAt.toObject() : undefined,
      lastSeenAt: this.lastSeenAt ? this.lastSeenAt.toObject() : undefined,
      tags: (this.tags || []).map(m => m.toObject()),
      metaData: (this.metaData || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetGatewayResponse.AsProtobufJSON {
    return {
      gatewayId: this.gatewayId,
      name: this.name,
      description: this.description,
      longitude: this.longitude,
      latitude: this.latitude,
      createdAt: this.createdAt ? this.createdAt.toProtobufJSON(options) : null,
      modifiedAt: this.modifiedAt
        ? this.modifiedAt.toProtobufJSON(options)
        : null,
      lastSeenAt: this.lastSeenAt
        ? this.lastSeenAt.toProtobufJSON(options)
        : null,
      tags: (this.tags || []).map(m => m.toProtobufJSON(options)),
      metaData: (this.metaData || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module GetGatewayResponse {
  /**
   * Standard JavaScript object representation for GetGatewayResponse
   */
  export interface AsObject {
    gatewayId?: string;
    name?: string;
    description?: string;
    longitude?: number;
    latitude?: number;
    createdAt?: googleProtobuf001.Timestamp.AsObject;
    modifiedAt?: googleProtobuf001.Timestamp.AsObject;
    lastSeenAt?: googleProtobuf001.Timestamp.AsObject;
    tags?: KeyValuePair.AsObject[];
    metaData?: KeyValuePair.AsObject[];
  }

  /**
   * Protobuf JSON representation for GetGatewayResponse
   */
  export interface AsProtobufJSON {
    gatewayId?: string;
    name?: string;
    description?: string;
    longitude?: number;
    latitude?: number;
    createdAt?: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    modifiedAt?: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    lastSeenAt?: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    tags?: KeyValuePair.AsProtobufJSON[] | null;
    metaData?: KeyValuePair.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for modulerossakerbms.lorawan.GetDeviceRequest
 */
export class GetDeviceRequest implements GrpcMessage {
  static id = 'modulerossakerbms.lorawan.GetDeviceRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetDeviceRequest();
    GetDeviceRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetDeviceRequest) {
    _instance.customerId = _instance.customerId || '';
    _instance.nodeId = _instance.nodeId || '';
    _instance.nodeTypeId = _instance.nodeTypeId || '';
    _instance.operator = _instance.operator || '';
    _instance.devEui = _instance.devEui || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetDeviceRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.customerId = _reader.readString();
          break;
        case 2:
          _instance.nodeId = _reader.readString();
          break;
        case 3:
          _instance.nodeTypeId = _reader.readString();
          break;
        case 4:
          _instance.operator = _reader.readString();
          break;
        case 5:
          _instance.devEui = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    GetDeviceRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetDeviceRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.customerId) {
      _writer.writeString(1, _instance.customerId);
    }
    if (_instance.nodeId) {
      _writer.writeString(2, _instance.nodeId);
    }
    if (_instance.nodeTypeId) {
      _writer.writeString(3, _instance.nodeTypeId);
    }
    if (_instance.operator) {
      _writer.writeString(4, _instance.operator);
    }
    if (_instance.devEui) {
      _writer.writeString(5, _instance.devEui);
    }
  }

  private _customerId?: string;
  private _nodeId?: string;
  private _nodeTypeId?: string;
  private _operator?: string;
  private _devEui?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetDeviceRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetDeviceRequest.AsObject>) {
    _value = _value || {};
    this.customerId = _value.customerId;
    this.nodeId = _value.nodeId;
    this.nodeTypeId = _value.nodeTypeId;
    this.operator = _value.operator;
    this.devEui = _value.devEui;
    GetDeviceRequest.refineValues(this);
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }
  get nodeId(): string | undefined {
    return this._nodeId;
  }
  set nodeId(value: string | undefined) {
    this._nodeId = value;
  }
  get nodeTypeId(): string | undefined {
    return this._nodeTypeId;
  }
  set nodeTypeId(value: string | undefined) {
    this._nodeTypeId = value;
  }
  get operator(): string | undefined {
    return this._operator;
  }
  set operator(value: string | undefined) {
    this._operator = value;
  }
  get devEui(): string | undefined {
    return this._devEui;
  }
  set devEui(value: string | undefined) {
    this._devEui = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetDeviceRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetDeviceRequest.AsObject {
    return {
      customerId: this.customerId,
      nodeId: this.nodeId,
      nodeTypeId: this.nodeTypeId,
      operator: this.operator,
      devEui: this.devEui
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetDeviceRequest.AsProtobufJSON {
    return {
      customerId: this.customerId,
      nodeId: this.nodeId,
      nodeTypeId: this.nodeTypeId,
      operator: this.operator,
      devEui: this.devEui
    };
  }
}
export module GetDeviceRequest {
  /**
   * Standard JavaScript object representation for GetDeviceRequest
   */
  export interface AsObject {
    customerId?: string;
    nodeId?: string;
    nodeTypeId?: string;
    operator?: string;
    devEui?: string;
  }

  /**
   * Protobuf JSON representation for GetDeviceRequest
   */
  export interface AsProtobufJSON {
    customerId?: string;
    nodeId?: string;
    nodeTypeId?: string;
    operator?: string;
    devEui?: string;
  }
}

/**
 * Message implementation for modulerossakerbms.lorawan.Device
 */
export class Device implements GrpcMessage {
  static id = 'modulerossakerbms.lorawan.Device';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new Device();
    Device.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: Device) {
    _instance.devEui = _instance.devEui || '';
    _instance.name = _instance.name || '';
    _instance.description = _instance.description || '';
    _instance.appEui = _instance.appEui || '';
    _instance.appKey = _instance.appKey || '';
    _instance.applicationId = _instance.applicationId || '';
    _instance.provisioned = _instance.provisioned || false;
    _instance.deviceProfileId = _instance.deviceProfileId || '';
    _instance.tags = _instance.tags || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(_instance: Device, _reader: BinaryReader) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.devEui = _reader.readString();
          break;
        case 2:
          _instance.name = _reader.readString();
          break;
        case 3:
          _instance.description = _reader.readString();
          break;
        case 4:
          _instance.appEui = _reader.readString();
          break;
        case 5:
          _instance.appKey = _reader.readString();
          break;
        case 6:
          _instance.applicationId = _reader.readString();
          break;
        case 7:
          _instance.provisioned = _reader.readBool();
          break;
        case 8:
          _instance.deviceProfileId = _reader.readString();
          break;
        case 9:
          const messageInitializer9 = new KeyValuePair();
          _reader.readMessage(
            messageInitializer9,
            KeyValuePair.deserializeBinaryFromReader
          );
          (_instance.tags = _instance.tags || []).push(messageInitializer9);
          break;
        default:
          _reader.skipField();
      }
    }

    Device.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: Device, _writer: BinaryWriter) {
    if (_instance.devEui) {
      _writer.writeString(1, _instance.devEui);
    }
    if (_instance.name) {
      _writer.writeString(2, _instance.name);
    }
    if (_instance.description) {
      _writer.writeString(3, _instance.description);
    }
    if (_instance.appEui) {
      _writer.writeString(4, _instance.appEui);
    }
    if (_instance.appKey) {
      _writer.writeString(5, _instance.appKey);
    }
    if (_instance.applicationId) {
      _writer.writeString(6, _instance.applicationId);
    }
    if (_instance.provisioned) {
      _writer.writeBool(7, _instance.provisioned);
    }
    if (_instance.deviceProfileId) {
      _writer.writeString(8, _instance.deviceProfileId);
    }
    if (_instance.tags && _instance.tags.length) {
      _writer.writeRepeatedMessage(
        9,
        _instance.tags as any,
        KeyValuePair.serializeBinaryToWriter
      );
    }
  }

  private _devEui?: string;
  private _name?: string;
  private _description?: string;
  private _appEui?: string;
  private _appKey?: string;
  private _applicationId?: string;
  private _provisioned?: boolean;
  private _deviceProfileId?: string;
  private _tags?: KeyValuePair[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of Device to deeply clone from
   */
  constructor(_value?: RecursivePartial<Device.AsObject>) {
    _value = _value || {};
    this.devEui = _value.devEui;
    this.name = _value.name;
    this.description = _value.description;
    this.appEui = _value.appEui;
    this.appKey = _value.appKey;
    this.applicationId = _value.applicationId;
    this.provisioned = _value.provisioned;
    this.deviceProfileId = _value.deviceProfileId;
    this.tags = (_value.tags || []).map(m => new KeyValuePair(m));
    Device.refineValues(this);
  }
  get devEui(): string | undefined {
    return this._devEui;
  }
  set devEui(value: string | undefined) {
    this._devEui = value;
  }
  get name(): string | undefined {
    return this._name;
  }
  set name(value: string | undefined) {
    this._name = value;
  }
  get description(): string | undefined {
    return this._description;
  }
  set description(value: string | undefined) {
    this._description = value;
  }
  get appEui(): string | undefined {
    return this._appEui;
  }
  set appEui(value: string | undefined) {
    this._appEui = value;
  }
  get appKey(): string | undefined {
    return this._appKey;
  }
  set appKey(value: string | undefined) {
    this._appKey = value;
  }
  get applicationId(): string | undefined {
    return this._applicationId;
  }
  set applicationId(value: string | undefined) {
    this._applicationId = value;
  }
  get provisioned(): boolean | undefined {
    return this._provisioned;
  }
  set provisioned(value: boolean | undefined) {
    this._provisioned = value;
  }
  get deviceProfileId(): string | undefined {
    return this._deviceProfileId;
  }
  set deviceProfileId(value: string | undefined) {
    this._deviceProfileId = value;
  }
  get tags(): KeyValuePair[] | undefined {
    return this._tags;
  }
  set tags(value: KeyValuePair[] | undefined) {
    this._tags = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    Device.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): Device.AsObject {
    return {
      devEui: this.devEui,
      name: this.name,
      description: this.description,
      appEui: this.appEui,
      appKey: this.appKey,
      applicationId: this.applicationId,
      provisioned: this.provisioned,
      deviceProfileId: this.deviceProfileId,
      tags: (this.tags || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): Device.AsProtobufJSON {
    return {
      devEui: this.devEui,
      name: this.name,
      description: this.description,
      appEui: this.appEui,
      appKey: this.appKey,
      applicationId: this.applicationId,
      provisioned: this.provisioned,
      deviceProfileId: this.deviceProfileId,
      tags: (this.tags || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module Device {
  /**
   * Standard JavaScript object representation for Device
   */
  export interface AsObject {
    devEui?: string;
    name?: string;
    description?: string;
    appEui?: string;
    appKey?: string;
    applicationId?: string;
    provisioned?: boolean;
    deviceProfileId?: string;
    tags?: KeyValuePair.AsObject[];
  }

  /**
   * Protobuf JSON representation for Device
   */
  export interface AsProtobufJSON {
    devEui?: string;
    name?: string;
    description?: string;
    appEui?: string;
    appKey?: string;
    applicationId?: string;
    provisioned?: boolean;
    deviceProfileId?: string;
    tags?: KeyValuePair.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for modulerossakerbms.lorawan.GetDeviceResponse
 */
export class GetDeviceResponse implements GrpcMessage {
  static id = 'modulerossakerbms.lorawan.GetDeviceResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetDeviceResponse();
    GetDeviceResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetDeviceResponse) {
    _instance.device = _instance.device || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetDeviceResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.device = new Device();
          _reader.readMessage(
            _instance.device,
            Device.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GetDeviceResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetDeviceResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.device) {
      _writer.writeMessage(
        1,
        _instance.device as any,
        Device.serializeBinaryToWriter
      );
    }
  }

  private _device?: Device;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetDeviceResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetDeviceResponse.AsObject>) {
    _value = _value || {};
    this.device = _value.device ? new Device(_value.device) : undefined;
    GetDeviceResponse.refineValues(this);
  }
  get device(): Device | undefined {
    return this._device;
  }
  set device(value: Device | undefined) {
    this._device = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetDeviceResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetDeviceResponse.AsObject {
    return {
      device: this.device ? this.device.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetDeviceResponse.AsProtobufJSON {
    return {
      device: this.device ? this.device.toProtobufJSON(options) : null
    };
  }
}
export module GetDeviceResponse {
  /**
   * Standard JavaScript object representation for GetDeviceResponse
   */
  export interface AsObject {
    device?: Device.AsObject;
  }

  /**
   * Protobuf JSON representation for GetDeviceResponse
   */
  export interface AsProtobufJSON {
    device?: Device.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for modulerossakerbms.lorawan.UpdateDeviceRequest
 */
export class UpdateDeviceRequest implements GrpcMessage {
  static id = 'modulerossakerbms.lorawan.UpdateDeviceRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UpdateDeviceRequest();
    UpdateDeviceRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UpdateDeviceRequest) {
    _instance.customerId = _instance.customerId || '';
    _instance.nodeId = _instance.nodeId || '';
    _instance.nodeTypeId = _instance.nodeTypeId || '';
    _instance.operator = _instance.operator || '';
    _instance.device = _instance.device || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UpdateDeviceRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.customerId = _reader.readString();
          break;
        case 2:
          _instance.nodeId = _reader.readString();
          break;
        case 3:
          _instance.nodeTypeId = _reader.readString();
          break;
        case 4:
          _instance.operator = _reader.readString();
          break;
        case 5:
          _instance.device = new Device();
          _reader.readMessage(
            _instance.device,
            Device.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    UpdateDeviceRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UpdateDeviceRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.customerId) {
      _writer.writeString(1, _instance.customerId);
    }
    if (_instance.nodeId) {
      _writer.writeString(2, _instance.nodeId);
    }
    if (_instance.nodeTypeId) {
      _writer.writeString(3, _instance.nodeTypeId);
    }
    if (_instance.operator) {
      _writer.writeString(4, _instance.operator);
    }
    if (_instance.device) {
      _writer.writeMessage(
        5,
        _instance.device as any,
        Device.serializeBinaryToWriter
      );
    }
  }

  private _customerId?: string;
  private _nodeId?: string;
  private _nodeTypeId?: string;
  private _operator?: string;
  private _device?: Device;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UpdateDeviceRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<UpdateDeviceRequest.AsObject>) {
    _value = _value || {};
    this.customerId = _value.customerId;
    this.nodeId = _value.nodeId;
    this.nodeTypeId = _value.nodeTypeId;
    this.operator = _value.operator;
    this.device = _value.device ? new Device(_value.device) : undefined;
    UpdateDeviceRequest.refineValues(this);
  }
  get customerId(): string | undefined {
    return this._customerId;
  }
  set customerId(value: string | undefined) {
    this._customerId = value;
  }
  get nodeId(): string | undefined {
    return this._nodeId;
  }
  set nodeId(value: string | undefined) {
    this._nodeId = value;
  }
  get nodeTypeId(): string | undefined {
    return this._nodeTypeId;
  }
  set nodeTypeId(value: string | undefined) {
    this._nodeTypeId = value;
  }
  get operator(): string | undefined {
    return this._operator;
  }
  set operator(value: string | undefined) {
    this._operator = value;
  }
  get device(): Device | undefined {
    return this._device;
  }
  set device(value: Device | undefined) {
    this._device = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UpdateDeviceRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UpdateDeviceRequest.AsObject {
    return {
      customerId: this.customerId,
      nodeId: this.nodeId,
      nodeTypeId: this.nodeTypeId,
      operator: this.operator,
      device: this.device ? this.device.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UpdateDeviceRequest.AsProtobufJSON {
    return {
      customerId: this.customerId,
      nodeId: this.nodeId,
      nodeTypeId: this.nodeTypeId,
      operator: this.operator,
      device: this.device ? this.device.toProtobufJSON(options) : null
    };
  }
}
export module UpdateDeviceRequest {
  /**
   * Standard JavaScript object representation for UpdateDeviceRequest
   */
  export interface AsObject {
    customerId?: string;
    nodeId?: string;
    nodeTypeId?: string;
    operator?: string;
    device?: Device.AsObject;
  }

  /**
   * Protobuf JSON representation for UpdateDeviceRequest
   */
  export interface AsProtobufJSON {
    customerId?: string;
    nodeId?: string;
    nodeTypeId?: string;
    operator?: string;
    device?: Device.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for modulerossakerbms.lorawan.UpdateDeviceResponse
 */
export class UpdateDeviceResponse implements GrpcMessage {
  static id = 'modulerossakerbms.lorawan.UpdateDeviceResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UpdateDeviceResponse();
    UpdateDeviceResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UpdateDeviceResponse) {
    _instance.ok = _instance.ok || false;
    _instance.message = _instance.message || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UpdateDeviceResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.ok = _reader.readBool();
          break;
        case 2:
          _instance.message = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    UpdateDeviceResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UpdateDeviceResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.ok) {
      _writer.writeBool(1, _instance.ok);
    }
    if (_instance.message) {
      _writer.writeString(2, _instance.message);
    }
  }

  private _ok?: boolean;
  private _message?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UpdateDeviceResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<UpdateDeviceResponse.AsObject>) {
    _value = _value || {};
    this.ok = _value.ok;
    this.message = _value.message;
    UpdateDeviceResponse.refineValues(this);
  }
  get ok(): boolean | undefined {
    return this._ok;
  }
  set ok(value: boolean | undefined) {
    this._ok = value;
  }
  get message(): string | undefined {
    return this._message;
  }
  set message(value: string | undefined) {
    this._message = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UpdateDeviceResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UpdateDeviceResponse.AsObject {
    return {
      ok: this.ok,
      message: this.message
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UpdateDeviceResponse.AsProtobufJSON {
    return {
      ok: this.ok,
      message: this.message
    };
  }
}
export module UpdateDeviceResponse {
  /**
   * Standard JavaScript object representation for UpdateDeviceResponse
   */
  export interface AsObject {
    ok?: boolean;
    message?: string;
  }

  /**
   * Protobuf JSON representation for UpdateDeviceResponse
   */
  export interface AsProtobufJSON {
    ok?: boolean;
    message?: string;
  }
}
