import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CoreSharedModule } from '@xprojectorshared/core-shared.module';
import { ProjectionsWorkspaceComponent } from './components/projections-workspace/projections-workspace.component';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import { DataConfigurationRoutingModule } from './data-configuration-routing.module';

@NgModule({
  declarations: [
    ProjectionsWorkspaceComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    MonacoEditorModule.forRoot(),
    DataConfigurationRoutingModule,
    CoreSharedModule
  ]
})
export class DataConfigurationModule { }
