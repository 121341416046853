import { Component, OnInit, Input, OnDestroy, ElementRef, ViewChild, AfterViewInit, Output, EventEmitter, ChangeDetectorRef, Inject } from '@angular/core';
import { BaseQuery, DownSampleQuery, BaseQueryInputColumnDescription, BaseQueryResult, XDataType, FilterLogicalGroup, FilterLogicalGroupType, ColumnFilteringTimestamp, FilterComparator, Transformation, ColumnFilteringRelativeTimestamp, DFTQuery, XProjectorClient } from '../../../XProjector/xprojector-client-service';
import { XprojWidgetService, LinkedWidgetChangeParameters, ZoomParameters, HiglightParameters, WidgetOutputChangeParameters, MasterTimeSettings } from '../../xproj-widget-service';
import { ClrLoadingState } from '@clr/angular';
import Chart from 'chart.js/auto';
import { SpectrumAnalyzerConfig, SpectrumAnalyzerQuery, YAxisId } from '../spectrum-analyzer-widget-config/spectrum-analyzer-config-service';
import { ArrayUtils } from '../../../utils/array-utils-service';
import { ChartUtils } from '../../../utils/chart-utils-service';
import zoomPlugin from 'chartjs-plugin-zoom';
import 'chartjs-adapter-date-fns';
import annotationPlugin from 'chartjs-plugin-annotation';
import autocolors from 'chartjs-plugin-autocolors';
import { Guid } from '../../../utils/guid-service';
import { WidgetUtils } from '../../../utils/widget-utils-service';
import { WidgetBase } from '../../widget-base';
import { GridsterItemComponentInterface } from 'angular-gridster2';
import { GroupSelectionTypes } from '../../widget-config-service';
import { XprojCommonStringsService } from '../../../i18n/xproj-common-strings.service';
import { ColorHelper } from '../../../helpers/color-helper-service';
import { LOGGERSERVICE, XprojLoggerService } from '../../../logger/xproj-logger-service';

@Component({
  selector: 'xproj-spectrum-analyzer-widget',
  templateUrl: './xproj-spectrum-analyzer-widget.component.html',
  styleUrls: ['./xproj-spectrum-analyzer-widget.component.scss']
})
export class XprojSpectrumAnalyzerWidgetComponent extends WidgetBase implements OnInit, OnDestroy, AfterViewInit {

  @Output() onLoadingStateChange = new EventEmitter<ClrLoadingState>();

  @ViewChild("graphCanvas", { read: ElementRef, static: false }) graphCanvas: ElementRef;
  @ViewChild("spectrumanalyzerWidget", { read: ElementRef, static: false }) spectrumanalyzerWidget: ElementRef;

  data = {
    datasets: []
  };

  //https://www.chartjs.org/docs/latest/axes/cartesian/time.html
  options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 1000
    },
    canvas: {
      height: 300
    },
    scales: {
      x: {
        type: 'time',
        display: true,
        time: {
          displayFormats: {
            day: 'd MMM',
            hour: 'd MMM HH:mm',
            minute: 'HH:mm',
            second: 'HH:mm:ss',
            millisecond: 'HH:mm:ss.SSS'
          }
        }
      },
      y: {
        type: 'linear',
        position: 'left',
        display: false,
        scaleLabel: {
          display: false,
          labelString: ''
        },
        ticks: {
          callback: function (value, index, ticks) {
            return ChartUtils.FormatLabel(this, value, index, ticks);
          }
        }
      },
      right_y: {
        type: 'linear',
        position: 'right',
        display: false,
        scaleLabel: {
          display: false,
          labelString: ''
        },
        ticks: {
          callback: function (value, index, ticks) {
            return ChartUtils.FormatLabel(this, value, index, ticks);
          }
        }
      }
    },
    plugins: {
      autocolors: {
        enabled: true,
        mode: 'dataset',
      },
      title: {
        display: false,
        text: ''
      },
      legend: {
        display: true,
        position: 'bottom',
        align: 'center'
      },
      //https://nagix.github.io/chartjs-plugin-colorschemes/
      colorschemes: {
        scheme: 'tableau.Classic20'
      },
      zoom: {
        // Container for pan options
        pan: {
          // Boolean to enable panning
          enabled: true,

          // Panning directions. Remove the appropriate direction to disable
          // Eg. 'y' would only allow panning in the y direction
          // A function that is called as the user is panning and returns the
          // available directions can also be used:
          //   mode: function({ chart }) {
          //     return 'xy';
          //   },
          mode: 'xy',

          modifierKey: 'shift',

          rangeMin: {
            // Format of min pan range depends on scale type
            x: null,
            y: null
          },
          rangeMax: {
            // Format of max pan range depends on scale type
            x: null,
            y: null
          },

          // On category scale, factor of pan velocity
          speed: 20,

          // Minimal pan distance required before actually applying pan
          threshold: 10,

          // Function called while the user is panning
          //onPan: function ({ chart }) { console.log(`I'm panning!!!`); },
          // Function called once panning is completed
          //onPanComplete: function ({ chart }) { console.log(`I was panned!!!`); }
        },

        // Container for zoom options
        zoom: {
          drag: {
            // Boolean to enable zooming
            enabled: true,

            // 	 borderColor: 'rgba(225,225,225,0.3)'
            // 	 borderWidth: 5,
            // 	 backgroundColor: 'rgb(225,225,225)',
          },

          // Zooming directions. Remove the appropriate direction to disable
          // Eg. 'y' would only allow zooming in the y direction
          // A function that is called as the user is zooming and returns the
          // available directions can also be used:
          //   mode: function({ chart }) {
          //     return 'xy';
          //   },
          mode: 'x',

          rangeMin: {
            // Format of min zoom range depends on scale type
            x: null,
            y: null
          },
          rangeMax: {
            // Format of max zoom range depends on scale type
            x: null,
            y: null
          },

          // Speed of zoom via mouse wheel
          // (percentage of zoom on a wheel event)
          speed: 0.1,

          // Minimal zoom distance required before actually applying zoom
          threshold: 2,

          // On category scale, minimal zoom level before actually applying zoom
          sensitivity: 3,

          // Function called while the user is zooming
          //onZoom: function ({ chart }) { console.log(`I'm zooming!!!`); },
          // Function called once zooming is completed
          //onZoomComplete: function ({ chart }) { console.log(`I was zoomed!!!`, chart); }
        }
      },
      //https://github.com/chartjs/chartjs-plugin-annotation
      annotation: {
        // Defines when the annotations are drawn.
        // This allows positioning of the annotation relative to the other
        // elements of the graph.
        //
        // Should be one of: afterDraw, afterDatasetsDraw, beforeDatasetsDraw
        // See http://www.chartjs.org/docs/#advanced-usage-creating-plugins
        drawTime: 'afterDatasetsDraw', // (default)

        // Mouse events to enable on each annotation.
        // Should be an array of one or more browser-supported mouse events
        // See https://developer.mozilla.org/en-US/docs/Web/Events
        events: ['click'],

        // Double-click speed in ms used to distinguish single-clicks from
        // double-clicks whenever you need to capture both. When listening for
        // both click and dblclick, click events will be delayed by this
        // amount.
        dblClickSpeed: 350, // ms (default)

        // Array of annotation configuration objects
        // See below for detailed descriptions of the annotation options
        annotations: []
      }
    }
  }

  widgetConfig: SpectrumAnalyzerConfig;

  graphChart: any;
  loading: boolean = false;

  private fromZoom: Date = null;
  private toZoom: Date = null;
  private altDown: Boolean = false;
  private cursor: string;
  private useRelativeTimestamp: boolean = true;
  private lastQueries: DFTQuery[] = [];

  constructor(
    @Inject(LOGGERSERVICE) public logger: XprojLoggerService,
    public xprojClient: XProjectorClient,
    public cdr: ChangeDetectorRef,
    public xprojCommonStrings: XprojCommonStringsService,
    public widgetService: XprojWidgetService,
    private colorHelper : ColorHelper) {
    super(logger, xprojClient, widgetService);
  }

  ngAfterViewInit(): void {
    if (this.graphChart)
      return;

    this.initGraph();

    if (!this.widgetConfig.ControlledByMaster || !this.responsive) {
      this.loadDelayed(false, this.zoom);
    }
  }

  private initGraph() {
    this.graphChart?.destroy();

    if (!this.graphCanvas || !this.graphCanvas.nativeElement) {
      this.logger.log("Hmm no canvas.. Returning");
      return;
    }
    let canvas = this.graphCanvas.nativeElement as HTMLCanvasElement;
    if (!canvas)
      return;

    this.options.plugins.colorschemes.scheme = this.widgetConfig.ColorScheme?.length > 0 ? this.widgetConfig.ColorScheme : this.globalWidgetSettings.ColorScheme;

    this.graphChart = new Chart(canvas,
      {
        type: 'line',
        data: this.data,
        options: this.options as any,
        plugins: [zoomPlugin, autocolors, annotationPlugin]
      }
    )

    this.graphChart.options.plugins.zoom.zoom.enabled = this.zoom && !this.widgetConfig.DisableZoom;
    this.graphChart.options.plugins.zoom.zoom.onZoom = this.onZoom.bind(this);
    this.graphChart.options.plugins.zoom.zoom.onZoomComplete = this.onZoomComplete.bind(this);
    this.graphChart.options.plugins.zoom.pan.enabled = false;
    this.graphChart.options.plugins.zoom.pan.onPanComplete = undefined;

    this.graphChart.options.interaction.intersect = this.widgetConfig.InteractionIntersect;
    this.graphChart.options.interaction.mode = this.widgetConfig.InteractionMode;
    this.graphChart.options.interaction.axis = this.widgetConfig.InteractionAxis;

    this.graphChart.options.plugins.autocolors.customize = this.customizeColor.bind(this);
    this.graphChart.options.plugins.legend.onHover = this.onLegendHover.bind(this);
    this.graphChart.options.onHover = this.onHover.bind(this);

    //responsive
    this.graphChart.options.responsive = this.responsive;
    this.graphChart.options.maintainAspectRatio = !this.responsive;
  }

  async ngOnInit() {
    this.widgetConfig = this.config as SpectrumAnalyzerConfig;

    await super.ngOnInit();
  }

  async onInit() {
    this.setChartCursor(this.widgetConfig.DisableZoom ? 'auto' : 'crosshair');
  }

  ngOnDestroy(): void {
    this.graphChart?.destroy();
    super.ngOnDestroy();
  }

  async onRefresh() {
    await this.loadDelayed(true, this.zoom, true);
  }

  async onResized(component: GridsterItemComponentInterface) {
    this.setWidgetHeight(this.getHeight(component));
    await this.loadDelayed(true, this.zoom);
  }

  async onReset() {
    this.fromZoom = null;
    this.toZoom = null;
    this.useRelativeTimestamp = true;
    this.data.datasets = [];

    await this.loadDelayed(true, this.zoom, true);
  }


  async onUpdateQuery() {
    this.initGraph();
    this.data.datasets = [];
    await this.loadDelayed(true, this.zoom);
  }

  async onProjectionDataChanged(projectionIds: string[], force : boolean = false) {
    let i = this.widgetConfig.ConfigQueries.findIndex(q => projectionIds.findIndex(p => p == q.Query.targetprojectionid) >= 0);
    if (force || i >= 0) {
      await this.load(false, this.zoom, true, false);
    }
  }

  async onLinkedWidgetChanged(e: LinkedWidgetChangeParameters) {
    let load: boolean = false;
    if (e.zoom) {
      if (this.fromZoom != e.zoom.from || this.toZoom != e.zoom.to) {
        this.fromZoom = e.zoom.from;
        this.toZoom = e.zoom.to;
        this.useRelativeTimestamp = false;

        if (e.zoom.to == null || e.zoom.from == null) {
          await this.resetChartZoom();
        }

        load = true;
      }
    }

    if (e.master) {
      if (e.master.projectionId?.length > 0 && e.master.group) {
        this.widgetConfig.ConfigQueries.forEach(query => {
          query.Query.targetprojectionid = e.master.projectionId;
          query.Query.targetgroup = e.master.group;
          load = true;
        })
      }

      if (e.master.time) {
        if (e.master.time.relativeTimestamp) {
          this.relativeTimestamp = e.master.time.relativeTimestamp;
          this.useRelativeTimestamp = true;
          load = true;
        }
        else {
          this.fromZoom = e.master.time.from;
          this.toZoom = e.master.time.to;
          this.useRelativeTimestamp = false;
          load = true;
        }
      }
    }

    if (load) {
      this.data.datasets = [];
      await this.loadDelayed(true, this.zoom);
    }

    if (e.highlight) {
      this.setHighlightedTimeArea(e.highlight.from, e.highlight.to);
      this.graphChart.update();
    }
    else {
      this.setHighlightedTimeArea();
      this.graphChart.update();
    }
  }

  async onWidgetOutputTimeChanged(time: MasterTimeSettings) {
    if (time) {
      if (time.relativeTimestamp) {
        this.relativeTimestamp = time.relativeTimestamp;
        this.useRelativeTimestamp = true;
      }
      else {
        this.fromZoom = time.from;
        this.toZoom = time.to;
        this.useRelativeTimestamp = false;
      }
    }
  }

  async onWidgetOutputChanged(event: WidgetOutputChangeParameters[]) {
    await this.loadDelayed(true, this.zoom);
  }

  private setWidgetHeight(height: number): void {
    if (height) {
      this.widgetheight = height;
    }
  }

  async resetZoom() {
    if (this.zoom) {
      this.fromZoom = null;
      this.toZoom = null;
      this.useRelativeTimestamp = true;

      let params = new LinkedWidgetChangeParameters();
      params.widgetId = this.globalWidgetSettings.LinkAllWidgets ? '' : this.config.Id;
      params.path.push(this.config.Id);
      let zoom = new ZoomParameters();
      zoom.from = this.fromZoom;
      zoom.to = this.toZoom;
      params.zoom = zoom;

      this.widgetService.linkedWidgetChanged(params);

      this.data.datasets.forEach(ds => {
        ds.data = [];
      });

      await this.loadDelayed(true, this.zoom);
      await this.resetChartZoom();
    }
  }

  async resetChartZoom() {
    let oldZoom = this.zoom;
    this.zoom = false;
    await this.graphChart.resetZoom();
    this.zoom = oldZoom;
  }

  setChartCursor(cursor?: string, save: boolean = true) {
    if (cursor && save) {
      this.cursor = cursor;
    }

    if (this.spectrumanalyzerWidget?.nativeElement) {
      this.spectrumanalyzerWidget.nativeElement.style.cursor = cursor ? cursor : this.cursor;
    }
  }

  onShiftDown(event) {
    //this.logger.log('keydown', event.key);
    if (this.zoom) {
      this.graphChart.options.plugins.zoom.zoom.enabled = false;
      this.graphChart.options.plugins.zoom.pan.enabled = true;
      this.graphChart.options.plugins.zoom.pan.onPanComplete = this.onPanComplete.bind(this);
      this.setChartCursor('grab');
    }

  }

  onShiftUp(event) {
    //this.logger.log('keyup', event.key);
    if (this.zoom) {
      this.graphChart.options.plugins.zoom.zoom.enabled = !this.widgetConfig.DisableZoom;
      this.graphChart.options.plugins.zoom.pan.enabled = false;
      this.graphChart.options.plugins.zoom.pan.onPanComplete = undefined;
      this.setChartCursor(this.widgetConfig.DisableZoom ? 'auto' : 'crosshair');
    }
  }

  onAltDown(event) {
    this.altDown = true;
  }

  onAltUp(event) {
    this.altDown = false;
  }

  focusOut() {
    this.altDown = false;
    this.onShiftUp(null);
  }

  onHover($event, chartElement) {
    let elem = this.graphChart.getElementsAtEventForMode($event, 'index', { intersect: true }, false);
    if (elem?.length > 0) {
      this.setChartCursor('pointer', false);
    }
    else {
      this.setChartCursor();
    }
  }

  onLegendHover(e, legendItem, legend) {
    this.setChartCursor('pointer', false);
  }

  customizeColor(context): { background: string, border: string } {
    return this.colorHelper.GetColor(this.options.plugins.colorschemes.scheme, context.datasetIndex, context.colors);
  }

  async onZoom(chart) {
    this.graphChart.options.animation.duration = 0;
  }

  async onZoomComplete(chart) {
    if (this.zoom) {
      let ticks = chart.chart.scales.x.ticks;
      let fromMs = ticks[0].value;
      let toMs = ticks[ticks.length - 1].value;
      this.fromZoom = new Date(fromMs);
      this.toZoom = new Date(toMs);
      this.useRelativeTimestamp = false;

      this.graphChart.options.animation.duration = 0;
      //this.graphChart.resetZoom();
      this.setHighlightedTimeArea();

      let params = new LinkedWidgetChangeParameters();
      params.widgetId = this.globalWidgetSettings.LinkAllWidgets ? '' : this.config.Id;
      params.path.push(this.config.Id);

      let zoom = new ZoomParameters();
      zoom.from = this.fromZoom;
      zoom.to = this.toZoom;
      params.zoom = zoom;

      this.data.datasets.forEach(ds => {
        ds.data = [];
      });

      await this.loadDelayed(false, this.zoom);

      this.widgetService.linkedWidgetChanged(params);
    }
  }

  onPanComplete(chart) {
    if (this.zoom) {
      let ticks = chart.chart.scales.x.ticks;
      let fromMs = ticks[0].value;
      let toMs = ticks[ticks.length - 1].value;
      this.fromZoom = new Date(fromMs);
      this.toZoom = new Date(toMs);

      let params = new LinkedWidgetChangeParameters();
      params.widgetId = this.globalWidgetSettings.LinkAllWidgets ? '' : this.config.Id;
      params.path.push(this.config.Id);
      let zoom = new ZoomParameters();
      zoom.from = this.fromZoom;
      zoom.to = this.toZoom;
      params.zoom = zoom;

      this.data.datasets.forEach(ds => {
        ds.data = [];
      });
      this.loadDelayed(false, this.zoom);

      this.widgetService.linkedWidgetChanged(params);
    }
  }

  graphClick($event) {
    try {
      let elem = this.graphChart.getElementsAtEventForMode($event, 'nearest', { intersect: true }, false);
      //console.log('chart', this.graphChart);
      //console.log('elem', elem);

      if (elem[0]) {
        let index = elem[0]?._index;
        //console.log(index)
        if (index) {
          // elem[0]._model.borderColor = 'black';
          // elem[0]._model.borderWidth = 10;
          // elem[0]._view.borderColor = 'black';

          let from: Date = new Date(elem[0]._xScale._timestamps.data[index]);
          //console.log('from', from);
          let unit = elem[0]._xScale._unit;
          let to: Date = new Date(elem[0]._xScale._timestamps.data[index]);
          switch (unit) {
            case 'year': {
              to.setFullYear(to.getFullYear() + 1);
              break;
            }
            case 'quarter': {
              to.setMonth(to.getMonth() + 3);
              break;
            }
            case 'month': {
              to.setMonth(to.getMonth() + 1);
              break;
            }
            case 'week': {
              to.setDate(to.getDate() + 7);
              break;
            }
            case 'day': {
              to.setDate(to.getDate() + 1);
              break;
            }
            case 'hour': {
              to.setHours(to.getHours() + 1);
              break;
            }
            case 'minute': {
              to.setMinutes(to.getMinutes() + 1);
              break;
            }
            default: {
              to.setMinutes(to.getMinutes() + 1);
              break;
            }
          }
          //console.log('to', to);

          if (from && to) {
            let params = new LinkedWidgetChangeParameters();
            params.widgetId = this.globalWidgetSettings.LinkAllWidgets ? '' : this.config.Id;
            params.path.push(this.config.Id);

            if (this.altDown) {
              let zoom = new ZoomParameters();
              zoom.from = from;
              zoom.to = to;
              zoom.unit = unit;
              params.zoom = zoom;
            }
            else {
              let highlight = new HiglightParameters();
              highlight.from = from;
              highlight.to = to;
              highlight.unit = unit;
              params.highlight = highlight;

              let zoom = new ZoomParameters();
              zoom.from = this.fromZoom;
              zoom.to = this.toZoom;
              zoom.unit = unit;
              params.zoom = zoom;
            }

            //console.log('elem2', elem);
            this.widgetService.linkedWidgetChanged(params);
            //this.graphChart?.update();
          }
        }
      }
    }
    catch { }
  }

  setHighlightedTimeArea(from?: Date, to?: Date) {

    this.graphChart.options.plugins.annotation.annotations = [];
    if (from && to) {
      let area = {
        type: 'box',
        drawTime: 'beforeDatasetsDraw',
        id: Guid.newGuid(),
        xScaleID: 'x',
        xMin: from,
        xMax: to,
        borderColor: 'lightgray',
        borderWidth: 0,
        backgroundColor: 'lightgray',
      }

      this.graphChart.options.plugins.annotation.annotations.push(area);
    }
  }

  getExportQueries(): DFTQuery[] {
    return this.lastQueries;
  }

  getExportImage(): any {
    let url_base64jp = this.graphCanvas.nativeElement.toDataURL("image/jpg");
    return url_base64jp;
  }

  loadTimer: any;
  async loadDelayed(animation: boolean, points: boolean, forcereload: boolean = false, showLoading: boolean = true) {
    if (!this.loadTimer) {
      this.loadTimer = setTimeout(() => {
        this.loadTimer = undefined;
        this.load(animation, points, forcereload, showLoading);
      }, 200);
    }
  }

  async load(animation: boolean, points: boolean, forcereload: boolean = false, showLoading: boolean = true) {
    if (this.loading) {
      return;
    }
    this.cdr.detach();
    //Clear highlighted area
    this.setHighlightedTimeArea();

    if (!this.widgetheight) {
      this.setWidgetHeight(this.widgetConfig.Height);
    }

    this.lastQueries = [];

    try {
      if (!this.inputParametersHasValue(true)) {
        //console.log('not inputParametersHasValue');
      }
      else {
        this.loading = true;
        this.onLoadingStateChange?.emit(ClrLoadingState.LOADING);

        this.options.plugins.legend.display = this.widgetConfig.LegendShow;
        this.options.plugins.legend.position = this.getLegendPostion();
        this.options.plugins.legend.align = this.getLegendAlignment();

        let date_x_axis = false;

        let show_left_yaxis: boolean = true;
        let show_right_yaxis: boolean = false;
        let left_yaxis_unit: string = '';
        let right_yaxis_unit: string = '';
        let xaxistimeunit = false;
        let xaxisInfo: { type: string, labels: string[] } = { type: 'linear', labels: [] };

        await ArrayUtils.AsyncForEach(this.widgetConfig.ConfigQueries, async (configQuery: SpectrumAnalyzerQuery) => {

          let showPoints: boolean = points;

          let dftQuery: DFTQuery;
          let querydata = await this.requestBaseQueryResult(() => this.getQuery(configQuery),
            (query) => {
              dftQuery = query;
              dftQuery.query.columns.splice(0, 1);// remove x-column hack
              return this.xprojClient.RequestQueryDFTQuery(dftQuery, forcereload, 'spectrumanalyserwidget', this.config.Name);
            }, configQuery, configQuery.DataFilters, forcereload, 'spectrumanalyserwidget', this.config.Name);

          this.lastQueries.push(dftQuery);

          // if (dftQuery.query.grouping.columnname.length == 0) {
          //   querydata = await this.state.xproj.RequestQueryDownSampleQuery(dftQuery, forcereload);
          //   showPoints = showPoints && querydata.nrpoints < configQuery.Query.maxitems;
          // }
          // else {
          //   querydata = await this.state.xproj.RequestQueryBaseQuery(dftQuery.query, forcereload);
          // }

          let data_x = [];
          let data_y = [];
          //console.log(querydata);

          for (let columnOut of querydata.columns) {
            if (columnOut.columnoutname == 'x') {
              if (columnOut.datatype == XDataType.Number) {
                data_x = querydata.datanumbers[columnOut.indexintypedvector];
                date_x_axis = false;
                xaxisInfo = ChartUtils.GetXAxisTypeAndLabels(configQuery, data_x);
              }
              if (columnOut.datatype == XDataType.Timestamp) {
                data_x = querydata.datatimestamps[columnOut.indexintypedvector];
                date_x_axis = true;
                if (!configQuery.timestampColumnName || configQuery.timestampColumnName.length == 0) {
                  configQuery.timestampColumnName = columnOut.columnname;
                }
              }
              continue;
            }

            let label = columnOut.columnoutname;
            let yaxisid: string;

            let columnName = columnOut.columnoutname;
            let columnType = columnOut.columnoutname;
            let s = columnOut.columnoutname.split(' ');
            if (s.length > 1) {
              if (s.length == 2) {
                columnName = s[0];
                columnType = s[1];
              }
              else {
                columnType = s[s.length - 1];
                columnName = columnName.substring(0, columnName.length - (columnType.length + 1));

              }
            }

            let yAxesConfig = configQuery.YAxesConfigs?.find(yaxisconfig => yaxisconfig.ColumnOutName == columnName || yaxisconfig.ColumnName == columnName);
            if (yAxesConfig) {
              let axisId = YAxisId.LEFT;
              switch (columnType) {
                case "real":
                  label = yAxesConfig.LabelReal || columnOut.columnoutname;
                  axisId = yAxesConfig.AxisIdReal;
                  break;

                case "power":
                  label = yAxesConfig.LabelPower || columnOut.columnoutname;
                  axisId = yAxesConfig.AxisIdPower;
                  break;

                default:
                  label = yAxesConfig.Label || columnOut.columnoutname;
                  axisId = yAxesConfig.AxisId;
                  break;
              }


              switch (axisId) {
                case YAxisId.LEFT:
                  yaxisid = 'y';
                  show_left_yaxis = true;
                  if (left_yaxis_unit.length == 0) {
                    left_yaxis_unit = yAxesConfig.Unit;
                  }
                  break;
                case YAxisId.RIGHT:
                  yaxisid = 'right_y';
                  show_right_yaxis = true;
                  if (right_yaxis_unit.length == 0) {
                    right_yaxis_unit = yAxesConfig.Unit;
                  }
                  break;

              }

              data_y.push({
                id: configQuery.Id + columnOut.columnoutname,
                data: querydata.datanumbers[columnOut.indexintypedvector],
                label: label + (axisId == YAxisId.RIGHT ? ' (' + this.xprojCommonStrings.keys.rightY + ')' : ''),
                yaxisid: yaxisid,
                showPoints: showPoints,
                type: yAxesConfig?.Type
              });
            }
            else {
              data_y.push({
                id: configQuery.Id + columnOut.columnoutname,
                data: querydata.datanumbers[columnOut.indexintypedvector],
                label: columnOut.columnoutname,
                yaxisid: yaxisid,
                showPoints: showPoints,
                //type: yAxesConfig?.Type
              });
            }
          }

          if (!configQuery.timestampColumnName || configQuery.timestampColumnName.length == 0) {
            configQuery.timestampColumnName = configQuery.Xaxis;
          }

          for (let d of data_y) {
            let seriesdata = [];
            for (let i = 0; i < d.data.length; i++) {
              let point = { x: data_x[i], y: d.data[i] };
              seriesdata.push(point);
            }

            if (xaxistimeunit == false && d.type == 'bar') {
              xaxistimeunit = ChartUtils.GetBarsXAxisUnit(configQuery.XaxisTransform);
            }

            let excistingDs = this.data.datasets.find(x => x.id == d.id);

            if (excistingDs) {
              // let last = excistingDs.data.at(-1);
              // if (last) {
              //   excistingDs.data.push(...seriesdata.filter(d => d.x > last.x));
              // }
              // else {
                excistingDs.data = seriesdata;
              // }
            }
            else {
              this.data.datasets.push(
                {
                  id: d.id,
                  label: d.label,
                  data: seriesdata,
                  fill: false,
                  pointRadius: d.showPoints ? 3 : 0,
                  lineTension: 0,
                  yAxisID: d.yaxisid,
                  index: this.data.datasets.length,
                  type: d.type != undefined ? d.type : 'line'
                }
              );
            }
          }
        });

        if (date_x_axis) {
          this.graphChart.options.scales.x.type = "time";
          this.graphChart.options.scales.x.time.unit = xaxistimeunit
        }
        else {
          this.graphChart.options.scales.x.type = xaxisInfo.type;
          this.graphChart.options.scales.x.labels = xaxisInfo.labels;
        }

        this.graphChart.options.scales.y.display = show_left_yaxis;
        this.graphChart.options.scales.y.scaleLabel.display = left_yaxis_unit.length > 0;
        this.graphChart.options.scales.y.scaleLabel.labelString = left_yaxis_unit;

        this.graphChart.options.scales.right_y.display = show_right_yaxis;
        this.graphChart.options.scales.right_y.scaleLabel.display = right_yaxis_unit.length > 0;
        this.graphChart.options.scales.right_y.scaleLabel.labelString = right_yaxis_unit;


        this.graphChart.options.animation.duration = animation ? this.widgetConfig.AnimationsMs : 0;

        this.graphChart.options.type = '';

        this.graphChart.update();
        //console.log('chart updated', this.widgetheight);
        this.onLoadingStateChange?.emit(ClrLoadingState.SUCCESS);
      }
    }
    catch (error) {
      this.logger.error(error);
      this.onLoadingStateChange?.emit(ClrLoadingState.ERROR);
    }
    finally {
      this.loading = false;
      this.cdr.reattach();
      this.graphChart.options.animation.duration = this.widgetConfig.AnimationsMs;
    }
  }

  getQuery(configQuery: SpectrumAnalyzerQuery): DFTQuery {
    let dftQuery = new DFTQuery();

    dftQuery.query = configQuery.Query.Clone();

    dftQuery.query.columns = dftQuery.query.columns.filter(col => col.columnname.indexOf(':') < 0);

    // Aggregation input parameters
    let i = 0;
    dftQuery.query.columns.forEach(col => {
      if (i > 0) {
        let yAxisConfig = configQuery.YAxesConfigs[i - 1];
        if (yAxisConfig.UseAggregationInputParameter) {
          col.columnaggregation = this.getParameterValue(yAxisConfig.AggregationInputParameterId, col.columnaggregation).value;
        }
      }
      i++;
    });

    //Transformation input parameters
    if (configQuery.UseGrouping && configQuery.UseTransformInputParameter) {
      dftQuery.query.grouping.columntransformation = this.getParameterValue(configQuery.TransformInputParameterId, configQuery.XaxisTransform).value;
    }

    //Projection input parameters
    if (configQuery.UseProjectionInputParameter) {
      dftQuery.query.targetprojectionid = this.getParameterValue(configQuery.ProjectionInputParameterId, configQuery.Query.targetprojectionid).value;
    }

    //Group input parameters
    if (configQuery.GroupSelectionType == GroupSelectionTypes.GROUP_INPUT) {
      dftQuery.query.targetgroup = this.getParameterValue(configQuery.GroupInputParameterId, configQuery.Query.targetgroup).value;
    }
    else if (configQuery.GroupSelectionType == GroupSelectionTypes.GROUP_INPUT_PARAMETERS) {
      dftQuery.query.targetgroup = [];
      configQuery.GroupInputParameterIds.forEach(id => {
        dftQuery.query.targetgroup.push(this.getParameterValue(id, '').value + '');
      });
    }

    let queryZoom = WidgetUtils.GetQueryZoomDates(this.fromZoom, this.toZoom, configQuery.XaxisTransform);
    WidgetUtils.SetQueryFilterValues(dftQuery.query, configQuery.DataFilters, (i, d) => this.getParameterValue(i, d));
    WidgetUtils.AddQueryTimeframe(dftQuery.query,
      queryZoom.from ?? this.from,
      queryZoom.to ?? this.to,
      configQuery.timestampColumnName,
      this.useRelativeTimestamp ? this.relativeTimestamp : null);

    return dftQuery;
  }
}

