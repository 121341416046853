import { Component, Input, OnInit } from '@angular/core';
import { OutputDataType  } from '../../../../../../widgets/widget-config-service';
import { BaseControllerConfig, ToggleMemberConfig, XprojOutputToggleControllerConfig } from '../../../xproj-output-controller-widget-config-service';
import { WidgetConfig, WidgetInputParameter } from '../../../../../widget-config-service';
import { ComboboxMemberConfig, XprojOutputComboboxControllerConfig } from '../../../xproj-output-controller-widget-config-service';
import { Projection } from '../../../../../../XProjector/xprojector-client-service';

@Component({
  selector: 'xproj-output-toggle-controller-config',
  templateUrl: './xproj-output-toggle-controller-config.component.html',
  styleUrls: ['./xproj-output-toggle-controller-config.component.scss']
})
export class XprojOutputToggleControllerConfigComponent implements OnInit {

  @Input() set config (value : BaseControllerConfig)
  {
    this.toggleConfig = value as XprojOutputToggleControllerConfig;
  }

  @Input() projections : Projection[] = [];
  @Input() inputParameters : WidgetInputParameter[] = [];
  @Input() widgets : WidgetConfig[];


  toggleConfig: XprojOutputToggleControllerConfig;

  selectedMember: ToggleMemberConfig;

  OutputDataType = OutputDataType;

  constructor() { }

  ngOnInit(): void {
  }

}
