
import { BimFileInfo, XProjectorClient, Blob, SetBlob } from 'xproj-lib';
import { inject } from '@angular/core';
import { XprojBimService } from 'xproj-lib';
//import { XAUTO_XAutoProcess3DGraph } from 'projects/xproj-lib/src/lib/XProjector/xprojector-client-service';


export class XprojBimXdbService implements XprojBimService {

  getId(what: string, id:string, customerId: string)
  {
    return 'bmsbim_' + what + '_' + id;
  }

  loadedVersions : Map<string, string> = new Map<string, string>();
  public latestLoaded : any = null;

  async uploadBimFile(id: string, name: string, fileFormat: string, blob : Blob, customerId: string): Promise<boolean> {
    let sid = this.getId("ifc", id, customerId);
    let setblob = new SetBlob();

    setblob.id = sid;
    setblob.data = blob.data;
    setblob.previous_hash = "";
    if( this.loadedVersions.has(sid) )
      setblob.previous_hash = this.loadedVersions.get(sid);
    return await this.xprojClient.RequestSaveBlob(setblob);
  }

  async uploadBabylonFile(id: string, name: string, fileFormat: string, blob : Blob, customerId: string): Promise<boolean> {
    let sid = this.getId("babylon", id, customerId);
    let setblob = new SetBlob();

    setblob.id = sid;
    setblob.data = blob.data;
    setblob.previous_hash = "";
    if( this.loadedVersions.has(sid) )
      setblob.previous_hash = this.loadedVersions.get(sid);
    return await this.xprojClient.RequestSaveBlob(setblob);  
  }

  async uploadFloorplanFile(id: string, name: string, fileFormat: string, blob : Blob, customerId: string): Promise<boolean> {
    let sid = this.getId("floorplan", id, customerId);
    let setblob = new SetBlob();

    setblob.id = sid;
    setblob.data = blob.data;
    setblob.previous_hash = "";
    if( this.loadedVersions.has(sid) )
      setblob.previous_hash = this.loadedVersions.get(sid);
    return await this.xprojClient.RequestSaveBlob(setblob);
  }

  getBimFileInfo(id: string, customerId: string): Promise<BimFileInfo> {
    throw new Error('Method not implemented.');
  }
  getBabylonFileInfo(id: string, customerId: string): Promise<BimFileInfo> {
    throw new Error('Method not implemented.');
  }
  getFloorplanFileInfo(id: string, customerId: string): Promise<BimFileInfo> {
    throw new Error('Method not implemented.');
  }
  async getBimFile(id: string, customerId: string): Promise<Blob> {
    let sid = this.getId("floorplan", id, customerId);
    let rval = await this.xprojClient.RequestGetBlob(sid);
    this.loadedVersions.set(sid, rval.hash);
    this.latestLoaded = rval;
    return rval;
  }
  async getBabylonFile(id: string, customerId: string): Promise<Blob> {
    let sid = this.getId("floorplan", id, customerId);
    let rval = await this.xprojClient.RequestGetBlob(sid);
    this.loadedVersions.set(sid, rval.hash);
    this.latestLoaded = rval;
    return rval;
  }
  async getFloorplanFile(id: string, customerId: string): Promise<Blob> {    
    let sid = this.getId("floorplan", id, customerId);
    let rval = await this.xprojClient.RequestGetBlob(sid);
    console.log("retrieved floorplan", sid, rval);
    this.loadedVersions.set(sid, rval.hash);
    this.latestLoaded = rval;
    return rval;
  }
  deleteBimFile(id: string, customerId: string): Promise<boolean> {
    throw new Error('Method not implemented.');
  }
  deleteBabylonFile(id: string, customerId: string): Promise<boolean> {
    throw new Error('Method not implemented.');
  }

  async deleteFloorplanFile(id: string, customerId: string): Promise<boolean> {
    let sid = this.getId("floorplan", id, customerId);
    await this.xprojClient.RequestRemoveBlob(sid);
    this.loadedVersions.delete(sid);    
    return true;
  }

  private xprojClient: XProjectorClient = inject(XProjectorClient);

  private hash : string = '';



  private bytesToString (bytes: Uint8Array): string {
    return new TextDecoder().decode(bytes);
  };

  private stringToBytes (str: string): Uint8Array {
    return new TextEncoder().encode(str);
  };
}
