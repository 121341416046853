<div>
  <form clrForm>
    <clr-select-container>
      <label>Projection</label>
      <select clrSelect id="useprojectioninput" name="useprojectioninput"
        [(ngModel)]="widgetQuery.UseProjectionInputParameter">
        <option [ngValue]="true"> Input </option>
        <option [ngValue]="false"> Projection </option>
      </select>

      <select clrSelect style="margin-left: 1em;" *ngIf="!widgetQuery.UseProjectionInputParameter"
        name="projection" [(ngModel)]="projection" (ngModelChange)="selectedProjectionChange($event)">
        <option *ngFor="let proj of projections" [ngValue]="proj">{{proj.name| xproj_pretty}}</option>
      </select>

      <select clrSelect style="margin-left: 1em;" *ngIf="widgetQuery.UseProjectionInputParameter"
        class="clr-select" name="UseProjectionInputParameter"
        [(ngModel)]="widgetQuery.ProjectionInputParameterId">
        <option *ngFor="let input of inputParameters | xproj_filterinputparameters : [OutputDataType.Projection]"
          [ngValue]="input.id">
          {{input.id}}</option>
      </select>
    </clr-select-container>

    <clr-select-container>
      <label>Input / Group</label>
      <select clrSelect id="usegroupinput" name="usegroupinput"
        [(ngModel)]="widgetQuery.GroupSelectionType">
        <option [ngValue]="GroupSelectionTypes.GROUP"> Group </option>
        <option [ngValue]="GroupSelectionTypes.GROUP_INPUT"> Input group </option>
        <option [ngValue]="GroupSelectionTypes.GROUP_INPUT_PARAMETERS"> Input parameters </option>
      </select>

      <select clrSelect *ngIf="widgetQuery.GroupSelectionType == GroupSelectionTypes.GROUP_INPUT"
        class="clr-select" name="GroupSelectionType" [(ngModel)]="widgetQuery.GroupInputParameterId">
        <option *ngFor="let input of inputParameters | xproj_filterinputparameters : [OutputDataType.Group]"
          [ngValue]="input.id">
          {{input.id}}</option>
      </select>
    </clr-select-container>

    <xproj-group-selection #groupSelect
      *ngIf="widgetQuery.GroupSelectionType == GroupSelectionTypes.GROUP" [projection]="projection"
      [selected]="widgetQuery.Query?.targetgroup"
      (groupSelected)="selectedProjectionGroupChange($event)">
    </xproj-group-selection>

    <clr-combobox-container
      *ngIf="widgetQuery.GroupSelectionType == GroupSelectionTypes.GROUP_INPUT_PARAMETERS">
      <label>Group input selections</label>
      <clr-combobox [(ngModel)]="widgetQuery.GroupInputParameterIds" name="multiSelect" clrMulti="true"
        required>
        <ng-container *clrOptionSelected="let selected">
          {{selected}}
        </ng-container>
        <clr-options>
          <clr-option
            *clrOptionItems="let input of inputParameters | xproj_filterinputparameters : [OutputDataType.String, OutputDataType.Number];  field:'id'"
            [clrValue]="input.id">
            {{input.id}}
          </clr-option>
        </clr-options>
      </clr-combobox>
    </clr-combobox-container>
  </form>

  <h5>Data</h5>
  <form clrForm *ngIf="columns">

    <!-- <clr-input-container>
      <label>Max items</label>
      <input clrInput placeholder="" type="number" name="rows" min="1" max="100"
        [(ngModel)]="widgetQuery.MaxItems" />
    </clr-input-container> -->

    <clr-select-container>
      <label>Default sorting column</label>
      <select clrSelect id="sortingcolumn" name="sortingcolumn"
        [(ngModel)]="widgetQuery.defaultSortColumnName">
        <option [ngValue]=""> </option>
        <option *ngFor="let col of widgetQuery.ColumnConfigs" [ngValue]="col.ColumnName">
          {{col.ColumnName}}</option>
      </select>
    </clr-select-container>

    <clr-select-container>
      <label>Default sorting order</label>

      <select clrSelect id="sortingorder" name="sortingorder"
        [(ngModel)]="widgetQuery.defaultSortDescending">
        <option [ngValue]="false"> Ascending </option>
        <option [ngValue]="true"> Descending </option>
      </select>
    </clr-select-container>

    <clr-select-container>
      <label>Grouping</label>

      <select clrSelect id="usegrouping" name="usegrouping" [(ngModel)]="widgetQuery.UseGrouping">
        <option [ngValue]="true"> Group </option>
        <option [ngValue]="false">No grouping</option>
      </select>

    </clr-select-container>

    <clr-datagrid [(clrDgSingleSelected)]="selectedColumnConfig">
      <clr-dg-column>Column</clr-dg-column>
      <clr-dg-column *ngIf="widgetQuery.UseGrouping">Transform / Aggregation</clr-dg-column>
      <clr-dg-column>Label</clr-dg-column>
      <clr-dg-column>Unit</clr-dg-column>
      <clr-dg-column>Default</clr-dg-column>
      <clr-dg-column>Move</clr-dg-column>

      <clr-dg-row *ngFor="let column of widgetQuery.ColumnConfigs; let i = index" [clrDgItem]="column">
        <clr-dg-cell>
          <div class="clr-select-wrapper">
            <select class="clr-select" name="tselectqueryData.column{{i}}" id="tselectcolumn_{{i}}"
              [(ngModel)]="column.ColumnName" (ngModelChange)="onColumnSelect($event, i)">
              <option *ngFor="let col of columns" [ngValue]="col.columnname">
                {{col.columnname}}</option>
              <option *ngFor="let col of preQueryColumnConfigs" [ngValue]="col.columnname">
                {{col.columnname}}</option>
              <option *ngFor="let col of widgetQuery?.Scriptedcolumnspostaggregation"
                [ngValue]="'script:' + col.columnoutname">
                script:{{col.columnoutname}}</option>
            </select>
          </div>
        </clr-dg-cell>
        <clr-dg-cell *ngIf="widgetQuery.UseGrouping">
          <div class="clr-select-wrapper">
            <input *ngIf="widgetQuery.UseGrouping && i == 0" class="clr-checkbox" type="checkbox"
              placeholder="" name="TransformInput{{i}}"
              [(ngModel)]="widgetQuery.UseTransformInputParameter" />

            <select class="clr-select" style="width:13em;"
              *ngIf="widgetQuery.UseGrouping && i == 0 && !widgetQuery.UseTransformInputParameter"
              id="xaxisgrouptransform" name="xaxisgrouptransform"
              [(ngModel)]="widgetQuery.GroupingTransform">
              <option [ngValue]="Transformation.NONE"> None </option>
              <option [ngValue]="Transformation.TIMESTAMP_RESOLUTION_YEAR"> Year </option>
              <option [ngValue]="Transformation.TIMESTAMP_RESOLUTION_MONTH"> Month </option>
              <option [ngValue]="Transformation.TIMESTAMP_RESOLUTION_DAY"> Day </option>
              <option [ngValue]="Transformation.TIMESTAMP_RESOLUTION_HOUR"> Hour </option>
              <option [ngValue]="Transformation.TIMESTAMP_RESOLUTION_MINUTE"> Minute </option>
              <option [ngValue]="Transformation.TIMESTAMP_RESOLUTION_SECOND"> Second </option>
              <option [ngValue]="Transformation.TIMESTAMP_DAYOFYEAR"> Day of year </option>
              <option [ngValue]="Transformation.TIMESTAMP_DAYOFMONTH"> Day of month </option>
              <option [ngValue]="Transformation.TIMESTAMP_DAYOFWEEK"> Day of week </option>
              <option [ngValue]="Transformation.TIMESTAMP_SECONDS_OF_DAY"> Time of day - total seconds of day
              </option>
              <option [ngValue]="Transformation.TIMESTAMP_MINUTES_OF_DAY"> Time of day - total minutes of day
              </option>
              <option [ngValue]="Transformation.TIMESTAMP_HOURS"> Time of day - hours of day </option>
              <option [ngValue]="Transformation.TIMESTAMP_MINUTES"> Time of day - minutes of hour </option>
              <option [ngValue]="Transformation.TIMESTAMP_SECONDS"> Time of day - seconds of minute </option>
              <option [ngValue]="Transformation.FORWARD_DIFF"> Forward diff </option>
            </select>

            <select
              *ngIf="widgetQuery.UseGrouping && i == 0 && widgetQuery.UseTransformInputParameter"
              class="clr-select" style="width:13em;" name="inputtransformparam"
              [(ngModel)]="widgetQuery.TransformInputParameterId">
              <option
                *ngFor="let input of inputParameters | xproj_filterinputparameters : [OutputDataType.Transformation]"
                [ngValue]="input.id">
                {{input.id}}</option>
            </select>

            <input *ngIf="widgetQuery.UseGrouping && i > 0" class="clr-checkbox" type="checkbox"
              placeholder="" name="AggregationInput{{i}}"
              [(ngModel)]="widgetQuery.ColumnConfigs[i].UseAggregationInputParameter" />

            <select
              *ngIf="widgetQuery.UseGrouping && i > 0 && !widgetQuery.ColumnConfigs[i].UseAggregationInputParameter"
              class="clr-select" style="width:13em;" name="tselectqueryData.columnagg{{i}}"
              [(ngModel)]="widgetQuery.ColumnConfigs[i].Transform">
              <option [ngValue]="Aggregation.NONE"> None </option>
              <option [ngValue]="Aggregation.COUNT"> Count </option>
              <option [ngValue]="Aggregation.DISTINCT_COUNT"> Distinct Count </option>

              <option [ngValue]="Aggregation.SUM"> Sum </option>

              <option [ngValue]="Aggregation.MAX"> Max </option>
              <option [ngValue]="Aggregation.MIN"> Min </option>

              <option [ngValue]="Aggregation.FIRST"> First </option>
              <option [ngValue]="Aggregation.LAST"> Last </option>

              <option [ngValue]="Aggregation.FORWARD_DIFF"> Forward diff </option>

              <option [ngValue]="Aggregation.MEAN_ARITHMETIC"> Mean - arithmetic </option>
              <option [ngValue]="Aggregation.MEAN_GEOMETRIC"> Mean - geometric </option>
              <option [ngValue]="Aggregation.MEAN_HARMONIC"> Mean - harmonic </option>

              <option [ngValue]="Aggregation.MEDIAN"> Median </option>

              <option [ngValue]="Aggregation.SD_SAMPLED_UNCORRECTED"> Standard deviation - uncorrected </option>
              <option [ngValue]="Aggregation.SD_SAMPLED_CORRECTED"> Standard deviation - corrected </option>
              <option [ngValue]="Aggregation.SD_SAMPLED_UNBIASED_APPROX_ND"> Standard deviation - unbiased approx
              </option>

              <option [ngValue]="Aggregation.MAD_ORIGO_MEAN_ARITHMETIC"> Mean absolute deviation - around
                arithmetic mean </option>
              <option [ngValue]="Aggregation.MAD_ORIGO_MEAN_GEOMETRIC"> Mean absolute deviation - around geometric
                mean </option>
              <option [ngValue]="Aggregation.MAD_ORIGO_MEAN_HARMONIC"> Mean absolute deviation - around harmonic
                mean </option>
              <option [ngValue]="Aggregation.MAD_ORIGO_MEDIAN"> Mean absolute deviation - around median </option>
            </select>

            <select
              *ngIf="widgetQuery.UseGrouping && i > 0 && widgetQuery.ColumnConfigs[i].UseAggregationInputParameter"
              class="clr-select" style="width:13em;" name="inputparam"
              [(ngModel)]="widgetQuery.ColumnConfigs[i].AggregationInputParameterId">
              <option
                *ngFor="let input of inputParameters | xproj_filterinputparameters : [OutputDataType.Aggregation]"
                [ngValue]="input.id">
                {{input.id}}</option>
            </select>
          </div>
        </clr-dg-cell>

        <clr-dg-cell>
          <input class="clr-input" placeholder="" name="tlabel{{i}}"
            [(ngModel)]="widgetQuery.ColumnConfigs[i].Label" />
        </clr-dg-cell>

        <clr-dg-cell>
          <input class="clr-input" placeholder="" name="unit{{i}}"
            [(ngModel)]="widgetQuery.ColumnConfigs[i].Unit" />
        </clr-dg-cell>

        <clr-dg-cell>
          <input class="clr-input" placeholder="" name="defaultvalue{{i}}"
            [(ngModel)]="widgetQuery.ColumnConfigs[i].DefaultValue" />
        </clr-dg-cell>

        <clr-dg-cell>
          <clr-icon (click)="moveColumnUp(i);" shape="circle-arrow" [style.cursor]="'pointer'"></clr-icon>
          <clr-icon (click)="moveColumnDown(i);" shape="circle-arrow" [style.cursor]="'pointer'"
            style="margin-left: 1em; transform: rotate(180deg);"></clr-icon>
        </clr-dg-cell>
      </clr-dg-row>
    </clr-datagrid>


    <clr-dropdown [clrCloseMenuOnItemClick]="true">
      <button clrDropdownTrigger aria-label="More actions">
        <clr-icon shape="ellipsis-vertical" size="12"></clr-icon>
        <!-- <clr-icon shape="caret down"></clr-icon> -->
      </button>
      <clr-dropdown-menu *clrIfOpen>
        <div aria-label="Dropdown header Action 1" clrDropdownItem (click)="addAllColumns()">Add all columns</div>
        <div aria-label="Dropdown header Action 2" clrDropdownItem (click)="removeAllColumns()">Remove all columns
        </div>
      </clr-dropdown-menu>
    </clr-dropdown>

    <button type="button" class="btn btn-link" (click)="addColumn()">
      <clr-icon shape="plus-circle"></clr-icon>
      Add column
    </button>
    <button *ngIf="selectedColumnConfig" type="button" class="btn btn-link" (click)="removeSelectedColumn()">
      <clr-icon shape="minus-circle"></clr-icon>
      Remove selected column
    </button>

    <div *ngIf="selectedColumnConfig" style="max-width: 800px;">
      <div class="card">
        <div class="card-header">
          Editing column
        </div>

        <div class="card-block">
          <div class="card-title">
            {{selectedColumnConfig.ColumnName}} ({{selectedColumnConfig.Label}})
          </div>
          <form clrForm>
            <clr-input-container *ngIf="selectedColumnConfig.Datatype == XDataType.Timestamp">
              <label class="clr-col-4">Format (empty = default)
                <clr-signpost>
                  <clr-signpost-content *clrIfOpen  [clrPosition]="'top-right'">
                    <h3>Date formats</h3>
                    <p>fullDate,
                      fullDateWithWeekday,
                      normalDate,
                      normalDateWithWeekday,
                      shortDate,
                      year,
                      month,
                      monthShort,
                      monthAndYear,
                      monthAndDate,
                      weekday,
                      weekdayShort,
                      dayOfMonth,
                      hours12h,
                      hours24h,
                      minutes,
                      seconds,
                      fullTime,
                      fullTime12h,
                      fullTime24h,
                      fullDateTime,
                      fullDateTime12h,
                      fullDateTime24h,
                      keyboardDate,
                      keyboardDateTime,
                      keyboardDateTime12h,
                      keyboardDateTime24h</p>
                  </clr-signpost-content>
                </clr-signpost>
              </label>
              <input clrInput class="clr-col-8" type="text" size="20" [(ngModel)]="selectedColumnConfig.DateFormat"
                name="fromconfigformat" />
            </clr-input-container>
          </form>

        </div>
      </div>
    </div>
  </form>

  <h5>Filters</h5>
  <xproj-projection-filter #projectionFilter id="projectionFilter" [projectionid]="projection?.projectionid"
    [columnDescriptions]="columns" [group]="widgetQuery.Query.targetgroup"
    [datafilters]="widgetQuery.DataFilters"
    [filterLogicalGroupType]="widgetQuery.FilterLogicalGroupType" [inputParameters]="inputParameters"
    [widgets]="widgets">
  </xproj-projection-filter>

  <br>

  <clr-accordion>
    <clr-accordion-panel
      *ngFor="let scriptedcolumn of widgetQuery.Scriptedcolumnspostaggregation; index as i">
      <clr-accordion-title>ScriptedColumn {{i+1}}</clr-accordion-title>
      <clr-accordion-content>
        <xproj-edit-lua-query-column [luaQueryColumn]="scriptedcolumn"
          (onQueryColumnRemoved)="onLuaQueryColumnRemoved($event)"></xproj-edit-lua-query-column>
      </clr-accordion-content>
    </clr-accordion-panel>
  </clr-accordion>
  <button type="button" class="btn btn-link" (click)="addScriptedColumnsPostAggregation();">
    <clr-icon shape="plus-circle"></clr-icon>
    Add scripted column
  </button>

  <br />
  <button class="btn btn-danger-outline" (click)="removeQuery()">Remove query</button>

</div>
