import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { TypedJSON } from 'typedjson';
import { XprojGroupSelectionComponent } from '../../../filters/group-selection/xproj-group-selection.component';
import { XprojProjectionFilterComponent } from '../../../filters/projection-filter/xproj-projection-filter.component';
import { ArrayUtils } from '../../../utils/array-utils-service';
import { Aggregation, BaseQueryInputColumnDescription, ColumnGroupingDescription, LuaQueryColumn, Projection, ProjectionColumnDescription, Transformation, XDataType, XProjectorClient } from '../../../XProjector/xprojector-client-service';
import { ColorProperty } from '../../label/label-widget-config/label-widget-config-service';
import { GroupSelectionTypes, InputArrayMode, OutputDataType, WidgetConfig, WidgetInputParameter, WidgetPrQueryColumnConfig } from '../../widget-config-service';
import { PiechartWidgetConfig, PiechartWidgetQuery } from '../piechart-widget-config/piechart-widget-config-service';

@Component({
  selector: 'xproj-piechart-widget-query-config',
  templateUrl: './xproj-piechart-widget-query-config.component.html',
  styleUrls: ['./xproj-piechart-widget-query-config.component.scss']
})
export class XprojPiechartWidgetQueryConfigComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild("projectionFilter", { read: XprojProjectionFilterComponent, static: false }) projectionFilter: XprojProjectionFilterComponent;
  @ViewChild("groupSelect", { read: XprojGroupSelectionComponent, static: false }) groupSelect: XprojGroupSelectionComponent;


  @Input() widgetQuery: PiechartWidgetQuery;
  @Input() projections: Projection[] = [];
  @Input() inputParameters: WidgetInputParameter[] = [];
  @Input() widgetConfig: PiechartWidgetConfig;
  @Input() widgets: WidgetConfig[];

  @Output() onQueryRemoved = new EventEmitter<PiechartWidgetQuery>();
  @Output() onDuplicateQuery = new EventEmitter<PiechartWidgetQuery>();

  projection: Projection;
  queryableSelectedGroup: string[] = [];
  columns: ProjectionColumnDescription[] = [];
  selectedGroup: string[] = [];
  preQueryColumnConfigs : { columnname : string, columnConfig : WidgetPrQueryColumnConfig }[] = [];

  Transformation = Transformation;
  Aggregation = Aggregation;
  OutputDataType = OutputDataType;
  GroupSelectionTypes = GroupSelectionTypes;
  InputArrayMode = InputArrayMode;


  constructor(private xprojClient: XProjectorClient) { }

  ngOnInit(): void {
    this.selectedGroup = this.widgetQuery.Query.targetgroup
  }

  ngOnDestroy(): void {

  }

  async ngAfterViewInit() {
    if (this.projections?.length == 0) {
      this.projections = await this.xprojClient.RequestListQueryableProjections(0, 10000);
    }

    this.projection = this.projections.find(p => p.projectionid == this.widgetQuery.ProjectionId);

    await this.selectedProjectionGroupChange(this.selectedGroup);
  }

  async selectedProjectionChange(projection: Projection) {
    if (projection) {
      this.selectedGroup = null;
      this.queryableSelectedGroup.length = 0;
      await this.queryColumns(this.projection["projectionid"], null);
      this.checkTimeFilterColumn();
    }
  }

  async selectedProjectionGroupChange(group: any) {
    if (this.projection) {
      this.selectedGroup = group;
      this.queryableSelectedGroup.length = 0;
      for (let gr of group) {
        this.queryableSelectedGroup.push(gr);
      }
      //console.log('selectedProjectionGroupChange', group);
      await this.queryColumns(this.projection["projectionid"], group);
      this.checkTimeFilterColumn();
    }
  }

  async queryColumns(projectionId: string, group: Array<string>) {
    //this.loadingProjectionColumns = true;
    let groupstr = "";
    if (group) {
      groupstr = group.join(",");
    }
    this.columns = await this.xprojClient.RequestListQueryableProjectionColumns(projectionId, groupstr, 0, 500);

    this.preQueryColumnConfigs = [];
    this.widgetConfig.WidgetPreQueryConfigs.forEach(preConfig => {
      preConfig.ColumnConfigs.forEach(c => this.preQueryColumnConfigs.push({ columnname: (preConfig.Prefix?.length > 0 ? preConfig.Prefix : preConfig.Name) + ':' + c.ColumnOutName, columnConfig: c }));
    });
    //this.loadingProjectionColumns = false;

  }

  checkTimeFilterColumn(force: boolean = false): void {
    let found = false;
    //Look for timestamp or time column
    if (force || !this.widgetQuery.timestampColumnName || this.widgetQuery.timestampColumnName.length == 0) {
      this.columns.forEach(col => {
        if (col.datatype == XDataType.Timestamp &&
          (col.columnname.toLowerCase() == 'timestamp' || col.columnname.toLowerCase() == 'time')) {
          this.widgetQuery.timestampColumnName = col.columnname;
          found = true;
        }
      });
    }
    //Take first column with Timestamp type if not found before.
    if (!found && (force || !this.widgetQuery.timestampColumnName || this.widgetQuery.timestampColumnName.length == 0)) {
      this.columns.forEach(col => {
        if (col.datatype == XDataType.Timestamp) {
          this.widgetQuery.timestampColumnName = col.columnname;
        }
      });
    }
  }

  onDataColumnSelect($event) {
    let column = this.columns.find(col => col.columnname == $event);
    if (column && column.unit?.length > 0) {
      this.widgetQuery.DataConfig.Unit = column.unit;
    }
    else {
      let column = this.preQueryColumnConfigs.find(col => col.columnname == $event);
      if (column && column.columnConfig.Unit?.length > 0) {
        this.widgetQuery.DataConfig.Unit = column.columnConfig.Unit;
      }
    }
  }

  addScriptedColumnsPostAggregation() {
    this.widgetQuery.Query.scriptedcolumnspostaggregation.push(new LuaQueryColumn());
  }

  onLuaQueryColumnRemoved(queryColumn: LuaQueryColumn) {
    this.widgetQuery.Query.scriptedcolumnspostaggregation = this.widgetQuery.Query.scriptedcolumnspostaggregation.filter(q => q != queryColumn);
  }

  removeQuery() {
    this.onQueryRemoved?.emit(this.widgetQuery);
  }

  duplicateQuery() {
    this.onDuplicateQuery?.emit(this.widgetQuery);
  }

  updateInputParameters(inputs: WidgetInputParameter[]) {
    this.inputParameters = inputs;
    if (this.projectionFilter) {
      this.projectionFilter.inputParameters = this.inputParameters;
    }
  }

  public async SaveQuery() {
    this.widgetQuery.DataFilters.forEach(filter => {
      filter.ColumnDescriptor = TypedJSON.parse(JSON.stringify(filter.ColumnDescriptor), ProjectionColumnDescription);
    });

    let scriptedcolumnspostaggregation = this.widgetQuery.Query.scriptedcolumnspostaggregation;
    this.widgetQuery.Query = this.projectionFilter.GetQuery();
    this.widgetQuery.DataFilters = this.projectionFilter.datafilters;
    this.widgetQuery.FilterLogicalGroupType = this.projectionFilter.filterLogicalGroupType;
    this.widgetQuery.Query.maxitems = 1000;
    this.widgetQuery.Query.scriptedcolumnspostaggregation = scriptedcolumnspostaggregation;

    if (this.projection) {
      this.widgetQuery.ProjectionId = this.projection.projectionid;
      this.widgetQuery.Group = this.selectedGroup;
      this.widgetQuery.Query.targetprojectionid = this.widgetQuery.ProjectionId;

      if (this.groupSelect) {
        this.widgetQuery.Query.targetgroup = this.groupSelect.getSelectedGroup();
      }
    }

    let labelcol = new BaseQueryInputColumnDescription();
    labelcol.columnname = this.widgetQuery.LabelConfig.ColumnName;
    labelcol.columnoutname = this.widgetQuery.LabelConfig.ColumnOutName = "Label";

    if (!this.widgetQuery.UseGrouping) {
      this.widgetQuery.Query.grouping = new ColumnGroupingDescription();
      this.widgetQuery.Query.columns.push(labelcol);
    }
    else {
      this.widgetQuery.Query.grouping.columnname = labelcol.columnname;
      this.widgetQuery.Query.grouping.columntransformation = this.widgetQuery.GroupingTransform;
      this.widgetQuery.Query.grouping.columnoutname = labelcol.columnoutname;
    }

    let datacol = new BaseQueryInputColumnDescription();
    datacol.columnname = this.widgetQuery.DataConfig.ColumnName;
    datacol.columnoutname = this.widgetQuery.DataConfig.ColumnOutName = "Data";
    if (this.widgetQuery.UseGrouping) {
      datacol.columnaggregation = this.widgetQuery.DataConfig.Transform;
    }

    if (this.widgetQuery.UseGrouping) {
      this.widgetQuery.Query.sorting.columnname = this.widgetQuery.SortOnLabel ? labelcol.columnoutname : datacol.columnoutname;
      this.widgetQuery.Query.sorting.descending = true;
    }
    else {
      this.widgetQuery.Query.sorting.columnname = this.widgetQuery.SortOnLabel ? labelcol.columnname : datacol.columnname;
      this.widgetQuery.Query.sorting.descending = true;
    }

    this.widgetQuery.Query.columns.push(datacol);

  }

}
