import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { XAUTO_XAutoDriver, XAUTO_XAutoDriverCollectd, XAUTO_XAutoDriverExternal, XAUTO_XAutoDriverGpio, XAUTO_XAutoDriverMqtt, XAUTO_XAutoDriverMidi, XAUTO_XAutoDriverModbus, XAUTO_XAutoDriverOpcua, XAUTO_XAutoDriverRedis, XAUTO_XAutoVariable, XAUTO_XAutoVariableModbus, XAUTO_XAutoVariableCollectd, XAUTO_XAutoVariableExternal, XAUTO_XAutoVariableGpio, XAUTO_XAutoVariableMqtt, XAUTO_XAutoVariableMidi, XAUTO_XAutoVariableOpcua, XAUTO_XAutoVariableRedis, XAUTO_XAutoProcessGraph, XAUTO_XAutoProgram, XAUTO_XAutoProgramLua, XAUTO_XAutoProgramScheduling, XProjectorClient, XAUTO_DriverType, XAUTO_XAutoProgramType, XAUTO_XAutoProgramSchedulingInterval, XAUTO_XAutoProgramSchedulingType, Projection, ProjectionExecution, ProjectionExecutionDataSourceQuery, ProjectionExecutionLuaMainFunction, ProjectionColumnDescription, ProjectionExecutionEntry, SetBlob, Blob as XBLOB, XDataType } from '../../XProjector/xprojector-client-service';
import { encode,decode } from "@msgpack/msgpack";
import { NgxFileDropEntry } from 'ngx-file-drop';
import { ClrLoadingState } from '@clr/angular';

export class XdbBackupFile
{
  Drivers: XAUTO_XAutoDriver[] = [];
  DriversModbus: XAUTO_XAutoDriverModbus[] = [];
  DriversCollectd: XAUTO_XAutoDriverCollectd[] = [];
  DriversExternal : XAUTO_XAutoDriverExternal[] = [];
  DriversGpio: XAUTO_XAutoDriverGpio[] = [];
  DriversMqtt : XAUTO_XAutoDriverMqtt[] =[];
  DriversMidi : XAUTO_XAutoDriverMidi[] = [];
  DriversOpcua: XAUTO_XAutoDriverOpcua[] = [];
  DriversRedis: XAUTO_XAutoDriverRedis[] = [];

  Variables: XAUTO_XAutoVariable[] = [];
  VariablesModbus: XAUTO_XAutoVariableModbus[] = [];
  VariablesCollectd: XAUTO_XAutoVariableCollectd[] = [];
  VariablesExternal : XAUTO_XAutoVariableExternal[] = [];
  VariablesGpio: XAUTO_XAutoVariableGpio[] = [];
  VariablesMqtt : XAUTO_XAutoVariableMqtt[] =[];
  VariablesMidi : XAUTO_XAutoVariableMidi[] = [];
  VariablesOpcua: XAUTO_XAutoVariableOpcua[] = [];
  VariablesRedis: XAUTO_XAutoVariableRedis[] = [];

  ProcessGraphs: XAUTO_XAutoProcessGraph[] = [];

  Programs: XAUTO_XAutoProgram[] =[];
  ProgramsLUA: XAUTO_XAutoProgramLua[] = [];
  ProgramSchedulings: XAUTO_XAutoProgramScheduling[] = [];
  ProgramSchedulingsInterval : XAUTO_XAutoProgramSchedulingInterval[] = [];

  Projections : Projection[] = [];
  ProjectionExecutions : ProjectionExecution[] = [];
  ProjectionExecutionDataSourceQueries : ProjectionExecutionDataSourceQuery[] = [];
  ProjectionExecutionLuaMainFunctions : ProjectionExecutionLuaMainFunction[] = [];
  ProjectionColumns: ProjectionColumnDescription[] = [];

  Blobs: XBLOB[] = [];
}

@Component({
  selector: 'xproj-xdb-backup',
  templateUrl: './xdb-backup.component.html',
  styleUrls: ['./xdb-backup.component.scss']
})
export class XdbBackupComponent implements OnInit{
  constructor(private xprojClient: XProjectorClient) {
  }

  showbackupwarning = true;
  showrestorewarning = true;
  create_backup_log = "";
  restore_backup_log = "";
  savedtoblob = false;
  backup : Uint8Array= null;
  backupFilename : string = null;
  downloadUrl : string = null;
  backupSize: number =0;
  blobbackups : string[] = [];

  ClrLoadingState = ClrLoadingState;

  prefixbackup = "xbackup-";

  restoredata:Uint8Array = null;

  @ViewChild('logoutput') logoutput!: ElementRef;
  @ViewChild('loginput') loginput!: ElementRef;

  fromitem = 0;
  maxitems = 10000000;

  selectedProjections : Projection [] = [];
  selectedDrivers : XAUTO_XAutoDriver  [] = [];
  selectedPrograms : XAUTO_XAutoProgram [] = [];
  loadingProjections = false;
  loadingDrivers = false;
  loadingPrograms = false;

  Projections : Projection[] = [];
  Drivers: XAUTO_XAutoDriver[] = [];
  Programs: XAUTO_XAutoProgram[] = [];

  async LoadProjections() {
    this.loadingProjections = true;
    let t = await this.xprojClient.RequestListConfigProjections(0, 1000);
    this.loadingProjections = false;
    for (let i = 0; i < t.length; i++) {
      this.Projections.push(t[i]);
    }
  }

  async LoadDrivers() {
    this.loadingDrivers = true;
    let t = await this.xprojClient.XAUTO_GetDrivers(0, 1000);
    this.loadingDrivers = false;
    for (let i = 0; i < t.length; i++) {
      this.Drivers.push(t[i]);
    }
  }

  async LoadPrograms() {
    this.loadingPrograms = true;
    let t = await this.xprojClient.XAUTO_GetPrograms(0, 1000);
    this.loadingPrograms = false;
    for (let i = 0; i < t.length; i++) {
      this.Programs.push(t[i]);
    }
  }

  async ngOnInit()
  {
    const fromitem = this.fromitem;
    const maxitems = this.maxitems;

    console.log("getting blobs");
    let blobs = await this.xprojClient.RequestGetBlobIDs(fromitem, maxitems);
    console.log("got blobs", blobs);
    for(let i of blobs)
    {
      let stri = i.toUpperCase();
      if(stri.startsWith(this.prefixbackup.toUpperCase()))
        this.blobbackups.push(i);
    }

    this.LoadProjections();
    this.LoadDrivers();
    this.LoadPrograms();
  }

  log_backup(what:string)
  {
    this.create_backup_log += what + "\r\n";
    if( this.logoutput &&  this.logoutput.nativeElement)
    {
      let textarea = this.logoutput.nativeElement;
      if(textarea.selectionStart == textarea.selectionEnd)
      {
        textarea.scrollTop = textarea.scrollHeight;
      }
    }
  }
  log_restore(what:string)
  {
    this.restore_backup_log += what + "\r\n";
    if( this.loginput &&  this.loginput.nativeElement)
    {
      let textarea = this.loginput.nativeElement;
      if(textarea.selectionStart == textarea.selectionEnd)
      {
        textarea.scrollTop = textarea.scrollHeight;
      }
    }
  }

  async generateBackup(partial:boolean)
  {
    const fromitem = this.fromitem;
    const maxitems = this.maxitems;

    this.create_backup_log = "";
    this.log_backup("Creating backup, partial: " + partial.toString());
    let backup = new XdbBackupFile();

    this.log_backup("Fetching drivers");    
    let Drivers = await this.xprojClient.XAUTO_GetDrivers(fromitem, maxitems);
    backup.Drivers=[];

    let drivertypes= {};

    for(let driver of Drivers)
    {
      const foundInDrivers = this.selectedDrivers.find( item => item.xautodriverid == driver.xautodriverid );
      if(!foundInDrivers && partial)
        continue;

      console.log(driver);
      drivertypes[driver.xautodriverid] = driver.driver;
      try
      {
        this.log_backup("\tFetching specific driver for " + driver.xautodriverid + " with type " + driver.driver.toString());
        switch(driver.driver)
        {
          case XAUTO_DriverType.EXTERNAL:
            backup.DriversExternal.push( await this.xprojClient.XAUTO_GetDriverExternal(driver.xautodriverid) );
            break;
          case XAUTO_DriverType.REDIS:
            backup.DriversRedis.push( await this.xprojClient.XAUTO_GetDriverRedis(driver.xautodriverid) );
            break;
          case XAUTO_DriverType.BACNET:
            break;
          case XAUTO_DriverType.MODBUS:
            backup.DriversModbus.push( await this.xprojClient.XAUTO_GetDriverModbus(driver.xautodriverid) );
            break;
          case XAUTO_DriverType.MBUS:
            break;
          case XAUTO_DriverType.OPCUA:
            backup.DriversOpcua.push( await this.xprojClient.XAUTO_GetDriverOpcua(driver.xautodriverid) );
            break;
          case XAUTO_DriverType.PROFIBUS:
            break;
          case XAUTO_DriverType.CANBUS:
            break;
          case XAUTO_DriverType.MQTT:
            backup.DriversMqtt.push( await this.xprojClient.XAUTO_GetDriverMqtt(driver.xautodriverid) );
            break;
          case XAUTO_DriverType.LORAWAN_MQTT:
            break;
          case XAUTO_DriverType.GPIO:
            backup.DriversGpio.push( await this.xprojClient.XAUTO_GetDriverGpio(driver.xautodriverid) );
            break;
          case XAUTO_DriverType.COLLECTD:
            backup.DriversCollectd.push( await this.xprojClient.XAUTO_GetDriverCollectd(driver.xautodriverid) );
            break;
          case XAUTO_DriverType.MIDI:
            backup.DriversMidi.push( await this.xprojClient.XAUTO_GetDriverMidi(driver.xautodriverid) );
            break;
        }
        backup.Drivers.push(driver);
      }
      catch(err)
      {      
        this.log_backup("\tFailed Fetching specific driver for " + driver.xautodriverid + " with type " + driver.driver.toString() + " - discarding");
      }
    }

    this.log_backup("Fetching variables");
    let Variables = await this.xprojClient.XAUTO_GetVariables(fromitem, maxitems);
    backup.Variables = [];
    for(let variable of Variables)
    {
      variable.type = XDataType.Float64;
      const foundInDrivers = this.selectedDrivers.find( item => item.xautodriverid == variable.xautodriverid );
      if(!foundInDrivers && partial)
      {
        continue;
      }
      try
      {
        if(!drivertypes[variable.xautodriverid])
          continue;
        let drivertype = drivertypes[variable.xautodriverid];
        this.log_backup("\tFetching specific variable for " + variable.xautodriverid + " with type " + drivertype);
        switch(drivertype)
        {
          case XAUTO_DriverType.EXTERNAL:
            backup.VariablesExternal.push( await this.xprojClient.XAUTO_GetVariableExternal(variable.xautovariableid) );
          break;
          case XAUTO_DriverType.REDIS:
            backup.VariablesRedis.push( await this.xprojClient.XAUTO_GetVariableRedis(variable.xautovariableid) );
            break;
          case XAUTO_DriverType.BACNET:
            break;
          case XAUTO_DriverType.MODBUS:
            backup.VariablesModbus.push( await this.xprojClient.XAUTO_GetVariableModbus(variable.xautovariableid) );
            break;
          case XAUTO_DriverType.MBUS:
            break;
          case XAUTO_DriverType.OPCUA:
            backup.VariablesOpcua.push( await this.xprojClient.XAUTO_GetVariableOpcua(variable.xautovariableid) );
            break;
          case XAUTO_DriverType.PROFIBUS:
            break;
          case XAUTO_DriverType.CANBUS:
            break;
          case XAUTO_DriverType.MQTT:
            backup.VariablesMqtt.push( await this.xprojClient.XAUTO_GetVariableMqtt(variable.xautovariableid) );
            break;
          case XAUTO_DriverType.LORAWAN_MQTT:
            break;
          case XAUTO_DriverType.GPIO:
            backup.VariablesGpio.push( await this.xprojClient.XAUTO_GetVariableGpio(variable.xautovariableid) );
            break;
          case XAUTO_DriverType.COLLECTD:
            backup.VariablesCollectd.push( await this.xprojClient.XAUTO_GetVariableCollectd(variable.xautovariableid) );
            break;
          case XAUTO_DriverType.MIDI:
            backup.VariablesMidi.push( await this.xprojClient.XAUTO_GetVariableMidi(variable.xautovariableid) );
            break;
        }
        backup.Variables.push(variable);
      }
      catch(err)
      {      
        this.log_backup("\tFailed Fetching specific variable for " + variable.xautodriverid + " - discarding");
      }
    }

    if(!partial)
    { // TODO IF PARTIAL    
      this.log_backup("Fetching process graphs");
      backup.ProcessGraphs = await this.xprojClient.XAUTO_GetProcessGraphs(fromitem,maxitems);
    }

    backup.Programs = this.selectedPrograms;
    this.log_backup("Fetching Programs");
    backup.Programs = await this.xprojClient.XAUTO_GetPrograms(fromitem, maxitems);

    for(let program of backup.Programs)
    {
      const foundInPrograms = this.selectedPrograms.find( item => item.xautoprogramid == program.xautoprogramid );
      if(!foundInPrograms && partial)
        continue;

      this.log_backup("\tFetching specific program for " + program.xautoprogramid);
      if(program.type != XAUTO_XAutoProgramType.LUA)
      {
        throw "Not supported program type";
      }

      backup.ProgramsLUA.push( await this.xprojClient.XAUTO_GetProgramLua(program.xautoprogramid) );
    }

    this.log_backup("Fetching scheduling");
    backup.ProgramSchedulings = await this.xprojClient.XAUTO_GetProgramsSchedulings(fromitem, maxitems);

    for(let progsched of backup.ProgramSchedulings)
    {
      const foundInPrograms = this.selectedPrograms.find( item => item.xautoprogramid == progsched.xautoprogramid );
      if(!foundInPrograms && partial)
        continue;

      if(progsched.type != XAUTO_XAutoProgramSchedulingType.Interval)
      {
        throw "Not supported scheduling type";
      }
      this.log_backup("\tFetching scheduling interval");
      backup.ProgramSchedulingsInterval.push( await this.xprojClient.XAUTO_GetProgramsSchedulingInterval(progsched.xautoprogramschedulingid) );
    }


    // Projections : Projection[] = [];
    // ProjectionExecutions : ProjectionExecution[] = [];
    // ProjectionExecutionDataSourceQueries : ProjectionExecutionDataSourceQuery[] = [];
    // ProjectionExecutionLuaMainFunctions : ProjectionExecutionLuaMainFunction[] = [];
    // ProjectionColumns: ProjectionColumnDescription[] = [];
    this.log_backup("Fetching projections");
    let tproj = await this.xprojClient.RequestListConfigProjections(fromitem, maxitems);
    let bannedProjectionIDPrefixes = ["XAUTO", "XDB", "XBOT"];

    function projidMatchesBanned(str:string) : boolean
    {
      let STR = str.toUpperCase()
      for (let i of bannedProjectionIDPrefixes)
      {
        if(STR.startsWith(i))
          return true;
      }
      return false;
    }
    for(let proj of tproj)
    {
      if(projidMatchesBanned(proj.projectionid))
        continue;

      const foundInProjs = this.selectedProjections.find( item => item.projectionid == proj.projectionid );
      if(!foundInProjs && partial)
        continue;


      backup.Projections.push(proj);
      this.log_backup("\tFetching columns for " + proj.projectionid);
      let t = await this.xprojClient.RequestListConfigProjectionColumns(proj.projectionid, fromitem, maxitems);
      for(let itt of t)
      {
        backup.ProjectionColumns.push(itt);
      }

      this.log_backup("\tFetching executions for " + proj.projectionid);
      let execs = await this.xprojClient.RequestListConfigProjectionExecutions(proj.projectionid, fromitem, maxitems);
      for(let exec of execs)
      {
        this.log_backup("\t\tFetching specific executions for " + exec.projectionexecutionid);
        backup.ProjectionExecutions.push(exec);
        switch(exec.entrypoint)
        {
          case ProjectionExecutionEntry.DataSourceQuery:
            let execsdss = await this.xprojClient.RequestListConfigProjectionExecutionDataSourceQueries(exec.projectionexecutionid, fromitem, maxitems);
            for(let execdss of execsdss)
            {
              backup.ProjectionExecutionDataSourceQueries.push(execdss);
            }
            break;
          case ProjectionExecutionEntry.LuaMainFunction:
            let execslmfs = await this.xprojClient.RequestListConfigProjectionExecutionLuaMainFunctions(exec.projectionexecutionid, fromitem, maxitems);
            for(let execlmfs of execslmfs)
            {
              backup.ProjectionExecutionLuaMainFunctions.push(execlmfs);
            }
            break;
          case ProjectionExecutionEntry.ProjectionCursor:
            throw "Projection execution entrypoint not supported";
        }
      }
    }

    if(!partial)
    { // Todo if partial
      
      this.log_backup("Fetching blobs");
      let blobids = await this.xprojClient.RequestGetBlobIDs(fromitem,maxitems);
      for(let blobid of blobids)
      {
        this.log_backup("\tFetching blob " + blobid);
        let blob = await this.xprojClient.RequestGetBlob(blobid);
        backup.Blobs.push(blob);
      }
    }

    this.log_backup("Done");
    console.log(backup);

    this.backup = encode(backup);
    this.downloadUrl = null;
    this.savedtoblob = false;
    this.backupFilename = this.prefixbackup + new Date().toISOString() + ".xbak";
  }



  async download()
  {
    const base64url = await new Promise(r => {
      const reader = new FileReader()
      reader.onload = () => r(reader.result)
      reader.readAsDataURL(new Blob([this.backup]))
    });

    this.downloadUrl = base64url.toString();
    this.backupSize = this.backup.length;
  }


  async savetoblob()
  {
    console.log("saving blob", this.backupFilename);
    let blob = new SetBlob();
    blob.data = this.backup;
    blob.id = this.backupFilename;
    await this.xprojClient.RequestSaveBlob(blob);
    console.log("saved blob");
    this.savedtoblob = true;
    this.blobbackups.push(this.backupFilename);
  }

  selectedRestorePoint = null;

  async restoreFromBlob(blobid: string)
  {
    console.log("will restore from blobid", blobid);
    let blob = await this.xprojClient.RequestGetBlob(blobid);
    this.restoredata = blob.data;
    this.selectedRestorePoint = "XDB Blob: " + blob.id;
  }

  loadRestoreFromDisk()
  {
    this.selectedRestorePoint = "Uploaded file";
  }

  async dropped(files: NgxFileDropEntry[]) {

    for (const droppedFile of files) {

      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file(async (file: File) =>
        {
          this.selectedRestorePoint = "Uploaded file: " +file.name;
          let buff = await file.arrayBuffer();
          this.restoredata = new Uint8Array(buff);
        });
      }
    }
  }

  restoringStatus:ClrLoadingState = ClrLoadingState.DEFAULT;
  async restoreBackup()
  {
    this.restoringStatus = ClrLoadingState.LOADING;
    this.log_restore("Restoring");
    let restore :XdbBackupFile = decode(this.restoredata) as XdbBackupFile;
    // Drivers
    this.log_restore("Setting Drivers with disabled flag");
    for(let driver of restore.Drivers)
    {
      this.log_restore("\tSetting Driver " + driver.xautodriverid);
      driver.enabled = false; // we enable them in the end
      await this.xprojClient.XAUTO_SaveDriver(driver);
    }
    this.log_restore("Setting external drivers");
    for(let driver of restore.DriversExternal)
    {
      await this.xprojClient.XAUTO_SaveDriverExternal(driver);
    }
    this.log_restore("Setting redis drivers");
    for(let driver of restore.DriversRedis)
    {
      await this.xprojClient.XAUTO_SaveDriverRedis(driver);
    }
    this.log_restore("Setting modbus drivers");
    for(let driver of restore.DriversModbus)
    {
      await this.xprojClient.XAUTO_SaveDriverModbus(driver);
    }
    this.log_restore("Setting opcua drivers");
    for(let driver of restore.DriversOpcua)
    {
      await this.xprojClient.XAUTO_SaveDriverOpcua(driver);
    }
    this.log_restore("Setting mqtt drivers");
    for(let driver of restore.DriversMqtt)
    {
      await this.xprojClient.XAUTO_SaveDriverMQTT(driver);
    }
    this.log_restore("Setting gpio drivers");
    for(let driver of restore.DriversGpio)
    {
      await this.xprojClient.XAUTO_SaveDriverRedis(driver);
    }
    this.log_restore("Setting collectd drivers");
    for(let driver of restore.DriversCollectd)
    {
      await this.xprojClient.XAUTO_SaveDriverRedis(driver);
    }
    this.log_restore("Setting midi drivers");
    for(let driver of restore.DriversMidi)
    {
      await this.xprojClient.XAUTO_SaveDriverRedis(driver);
    }

    // Variables
    this.log_restore("Setting Variables");
    for(let variable of restore.Variables)
    {
      await this.xprojClient.XAUTO_SaveVariable(variable);
    }
    this.log_restore("Setting external variables");
    for(let variable of restore.VariablesExternal)
    {
      await this.xprojClient.XAUTO_SaveVariableExternal(variable);
    }
    this.log_restore("Setting redis variables");
    for(let variable of restore.VariablesRedis)
    {
      await this.xprojClient.XAUTO_SaveVariableRedis(variable);
    }
    this.log_restore("Setting modbus variables");
    for(let variable of restore.VariablesModbus)
    {
      await this.xprojClient.XAUTO_SaveVariableModbus(variable);
    }
    this.log_restore("Setting opcua variables");
    for(let variable of restore.VariablesOpcua)
    {
      await this.xprojClient.XAUTO_SaveVariableOpcua(variable);
    }
    this.log_restore("Setting mqtt variables");
    for(let variable of restore.VariablesMqtt)
    {
      await this.xprojClient.XAUTO_SaveVariableMQTT(variable);
    }
    this.log_restore("Setting gpio variables");
    for(let variable of restore.VariablesGpio)
    {
      await this.xprojClient.XAUTO_SaveVariableRedis(variable);
    }
    this.log_restore("Setting collectd variables");
    for(let variable of restore.VariablesCollectd)
    {
      await this.xprojClient.XAUTO_SaveVariableRedis(variable);
    }
    this.log_restore("Setting midi variables");
    for(let variable of restore.VariablesMidi)
    {
      await this.xprojClient.XAUTO_SaveVariableRedis(variable);
    }

    // Set drivers with enabled true
    {
      let restore :XdbBackupFile = decode(this.restoredata) as XdbBackupFile;
      // Drivers
      this.log_restore("Setting Drivers enabled if enabled");
      for(let driver of restore.Drivers)
      {
        if(!driver.enabled)
          continue;
        this.log_restore("\tSetting Driver " + driver.xautodriverid);        
        await this.xprojClient.XAUTO_SaveDriver(driver);
      }
    }

    // Process graphs
    this.log_restore("Setting process graphs");
    for(let graph of restore.ProcessGraphs)
    {
      await this.xprojClient.XAUTO_SaveProcessGraph(graph);
    }

    // Programs
    this.log_restore("Setting programs");
    for(let program of restore.Programs)
    {
      await this.xprojClient.XAUTO_SaveProgram(program);
    }
    this.log_restore("Setting lua programs");
    for(let program of restore.ProgramsLUA)
    {
      await this.xprojClient.XAUTO_SaveProgramLua(program);
    }
    this.log_restore("Setting program scheduling");
    for(let scheduling of restore.ProgramSchedulings)
    {
      await this.xprojClient.XAUTO_SaveProgramsScheduling(scheduling);
    }
    this.log_restore("Setting program scheduling interval");
    for(let scheduling of restore.ProgramSchedulingsInterval)
    {
      await this.xprojClient.XAUTO_SaveProgramsSchedulingInterval(scheduling);
    }

    // Projections
    this.log_restore("Setting projections");
    for(let proj of restore.Projections)
    {
      await this.xprojClient.RequestSaveProjection(proj,true);
    }
    this.log_restore("Setting projection executions");
    for(let exec of restore.ProjectionExecutions)
    {
      await this.xprojClient.RequestSaveProjectionExecution(exec);
    }
    this.log_restore("Setting projection data source executions");
    for(let exec of restore.ProjectionExecutionDataSourceQueries)
    {
      await this.xprojClient.RequestSaveProjectionExecutionDataSourceQuery(exec);
    }
    this.log_restore("Setting projection lua main function executions");
    for(let exec of restore.ProjectionExecutionLuaMainFunctions)
    {
      await this.xprojClient.RequestSaveProjectionExecutionLuaMainfunction(exec);
    }
    this.log_restore("Setting columns");
    for(let col of restore.ProjectionColumns)
    {
      await this.xprojClient.RequestSaveProjectionColumnDescription(col);
    }
    this.log_restore("Setting blobs");
    for(let blob of restore.Blobs)
    {
      this.log_restore("\tSetting blob " + blob.id);
      let newSetBlob = new SetBlob();
      newSetBlob.data = blob.data;
      newSetBlob.id = blob.id;
      newSetBlob.previous_hash = "";
      try
      {
        await this.xprojClient.RequestSaveBlob(newSetBlob);
      }
      catch
      {
        // this.log_restore("\tFailed setting blob " + blob.id + " with empty hash, refreshing");
        // // We probably had wronte hash.. lets fetch current if exist
        let currblob = await this.xprojClient.RequestGetBlob(blob.id);
        newSetBlob.previous_hash = currblob.hash;
        await this.xprojClient.RequestSaveBlob(newSetBlob);
        //this.log_restore("\tRefresh worked for "+ blob.id);
      }
    }
    this.log_restore("Done");
    this.restoringStatus = ClrLoadingState.SUCCESS;
  }
}
