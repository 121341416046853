import { Component, Inject, Input, OnInit } from '@angular/core';
import { Treeview } from '@xprojectorcore/models/treeview';
import { TreeNode } from '@xprojectorcore/models/tree-node';
import { TreeviewItem } from '@xprojectorcore/models/treeview-item';
import { TypedJSON } from 'typedjson';
import { ArrayUtils, DashboardConfig, Guid, XprojDashboardInteractService, WidgetType, XprojAlertService, XprojModalService } from 'xproj-lib';
import { ADMINDASHBOARDSSERVICE, AdminDashboardsService } from '../../services/admin-dashboards.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin-dashboard-treeview',
  templateUrl: './admin-dashboard-treeview.component.html',
  styleUrls: ['./admin-dashboard-treeview.component.scss']
})
export class AdminDashboardTreeviewComponent implements OnInit {

  @Input() treeview: Treeview;
  @Input() expanded: boolean = false;
  _collapsed: boolean = false;
  @Input()
  get collapsed() { return this._collapsed };
  set collapsed(value) {
    this._collapsed = value;
    this.xprojDashboardInteractService.ShowDashboardSettings(undefined, undefined, this._collapsed);
  }
  showEdit: boolean = false;
  newname: string;
  currentItem: TreeviewItem;
  currentWidgetTypes: WidgetType[] = [];

  showMove: boolean = false;
  moveToItem: TreeviewItem = null;

  currentDraggedItem: TreeNode = null;

  //clipboardItem : TreeviewItem = null;

  constructor(
    @Inject(ADMINDASHBOARDSSERVICE) public adminDashboardsService: AdminDashboardsService,
    private xprojDashboardInteractService: XprojDashboardInteractService,
    private alertService: XprojAlertService,
    private modalService: XprojModalService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.initTreeview(this.expanded);
  }

  getChildren = (item) => item.children;

  initTreeview(expanded: boolean = undefined) {
    if (this.treeview) {
      this.treeview.children.forEach(child => {
        child.parent = this.treeview;
        this.initItem(child, expanded);
      });
    }
  }

  initItem(item: TreeviewItem, expanded: boolean = undefined) {
    if (item.id == this.treeview.selectedNode?.id) {
      // @ts-ignore
      item.parent.expanded = true;
    }

    if (expanded != undefined) {
      item.expanded = expanded;
    }

    item.children.forEach(child => {
      child.parent = item;
      this.initItem(child, expanded);
      if (child.expanded) {
        item.expanded = true;
      }
    });
  }

  refresh() {
    //Force reload - find a better way?!
    let selected = this.treeview.selectedNode;
    this.treeview = TypedJSON.parse(TypedJSON.stringify(this.treeview, Treeview), Treeview);
    this.treeview.selectedNode = selected;
    this.initTreeview();
  }

  async addDashboard(item: TreeviewItem) {
    let child = new TreeviewItem();
    child.id = Guid.newGuid();
    child.name = 'dashboard_' + (item.children.length + 1);
    child.parent = item;
    item.children.push(child);

    await this.adminDashboardsService.saveDashboardTree(this.treeview);

    let dashboard = new DashboardConfig();
    dashboard.Id = child.id;
    await this.adminDashboardsService.saveDashboard(dashboard);

    this.refresh();
  }

  selectItem(item: TreeviewItem) {
    this.currentItem = item;
  }

  onRightClick($event, item: TreeviewItem) {
    $event.stopPropagation();

    if (this.currentItem) {
          // @ts-ignore
      this.currentItem.openDropDown = false
    }

    if (item) {
      this.currentItem = item;
      this.onOpenEditMenu(item);
      // @ts-ignore
      item.openDropDown = false;
      setTimeout(() => {
        // @ts-ignore
        item.openDropDown = true;
      });
    }

    return false;
  }

  onOpenEditMenu(item: TreeviewItem) {
    this.xprojDashboardInteractService.GetWidgetTypes(item.id, (widgetTypes: WidgetType[]) => {
      this.currentWidgetTypes = widgetTypes;
    });
  }

  addWidget(item: TreeviewItem, widgetType: WidgetType) {
    this.xprojDashboardInteractService.AddWidget(item.id, widgetType, (result: boolean) => {
      if (!result) {
        this.alertService.error('Error add dashboard widget.');
      }
    });
  }

  showDashboardSettings(item: TreeviewItem) {
    this.xprojDashboardInteractService.ShowDashboardSettings(item.id, true, undefined);
  }

  async deleteDashboard(item: TreeNode, deleteChildren: boolean = false) {
    this.modalService.ShowConfirmModal({ header: 'Delete dashboard', description: 'Delete dashboard, are you sure?' }, async (result) => {
      if (result) {
        if (item && item.parent) {
          if (deleteChildren) {
            await ArrayUtils.AsyncForEach(item.children, async (child) => {
              await this.deleteDashboardRecursive(child);
            });
          }

          if (await this.adminDashboardsService.deleteDashboard(item.id)) {
            item.parent.children = item.parent.children.filter(c => c.id != item.id);
            await this.adminDashboardsService.saveDashboardTree(this.treeview);

            this.refresh();
          }
        }
      }
    });
  }

  private async deleteDashboardRecursive(item: TreeNode) {
    await ArrayUtils.AsyncForEach(item.children, async (child) => {
      await this.deleteDashboardRecursive(child);
    });

    await this.adminDashboardsService.deleteDashboard(item.id);
  }


  async editDashboard(item: TreeviewItem, mobile : boolean = false) {
    if (item) {
      this.currentItem = item;
      setTimeout(() => {
        this.router.navigate(['dashboard', item.id, mobile ? 'edit-mobile' : 'edit']);
      });
    }
  }

  async viewFullscreen(item: TreeviewItem) {
    if (item) {
      this.currentItem = item;
      setTimeout(() => {
        this.router.navigate(['dashboard', item.id, 'fullscreen']);
      });
    }
  }


  async modifyDashboard(item: TreeviewItem) {
    if (item) {
      this.currentItem = item;
      this.newname = item.name;
      this.showEdit = true;
    }
  }

  async doModifyDashboard() {
    if (this.currentItem) {
      this.currentItem.name = this.newname;

      // if (this.currentUserRights.isAdminUser) {
      //   let dashboard = await this.adminDashboardsService.loadDashboard(this.currentItem.id);
      //   if (dashboard && dashboard.Id != this.newname) {
      //     let oldId = dashboard.Id;

      //     this.currentItem.id = this.newname;
      //     dashboard.Id = this.newname;
      //     let result = await this.adminDashboardsService.saveDashboard(dashboard);
      //     if (result) {
      //       await this.adminDashboardsService.deleteDashboardOld(oldId);
      //     }
      //   }
      // }

      await this.adminDashboardsService.saveDashboardTree(this.treeview);

      this.refresh();
    }
  }

  moveItem(item) {
    this.currentItem = item;
    this.moveToItem = null;
    this.showMove = true;
  }

  async doMoveDashboard() {
    if (this.currentItem && this.moveToItem) {
      if (this.currentItem != this.moveToItem
        && this.currentItem.parent != this.moveToItem && !this.isChildOf(this.moveToItem, this.currentItem)) {
        this.currentItem.parent.children = this.currentItem.parent.children.filter((i) => i.id != this.currentItem.id);
        this.moveToItem.children.push(this.currentItem);
        await this.adminDashboardsService.saveDashboardTree(this.treeview);
        this.refresh();
      }
      else {
        this.alertService.error("Can't move '" + this.currentItem.name + "' to '" + this.moveToItem.name + "'!");
      }
    }
  }

  isChildOf(child: TreeNode, parent: TreeNode): boolean {
    if (parent.children.find(c => c.id == child.id)) {
      return true;
    }
    else {
      let result = false;
      parent.children.forEach(c => {
        if (!result) {
          result = this.isChildOf(child, c);
        }
      })

      return result;
    }

  }

  async moveUp(item: TreeviewItem) {
    if (item && item.parent) {
      let index: number = item.parent.children.findIndex((child) => child.id == item.id);
      ArrayUtils.MoveItemUp(item.parent.children, index);
      await this.adminDashboardsService.saveDashboardTree(this.treeview);
      this.refresh();
    }
  }

  async moveDown(item: TreeviewItem) {
    if (item && item.parent) {
      let index: number = item.parent.children.findIndex((child) => child.id == item.id);
      ArrayUtils.MoveItemDown(item.parent.children, index);
      await this.adminDashboardsService.saveDashboardTree(this.treeview);
      this.refresh();
    }
  }

  copyItem(item: TreeviewItem) {
    this.adminDashboardsService.clipboardTreeviewItem = item;
  }

  async pasteItem(parentItem: TreeNode) {
    if (this.adminDashboardsService.clipboardTreeviewItem) {
      let item = TypedJSON.parse(TypedJSON.stringify(this.adminDashboardsService.clipboardTreeviewItem, TreeviewItem), TreeviewItem);
      item.id = Guid.newGuid();
      item.name = this.adminDashboardsService.clipboardTreeviewItem.name + '-copy';
      this.adminDashboardsService.loadDashboard(this.adminDashboardsService.clipboardTreeviewItem.id)
        .then(async (dashboard) => {
          if (dashboard) {
            let copyDashboard = TypedJSON.parse(TypedJSON.stringify(dashboard, DashboardConfig), DashboardConfig);
            copyDashboard.Id = item.id;
            await this.adminDashboardsService.saveDashboard(copyDashboard);
          }
        });

      parentItem.children.push(item);
      await this.adminDashboardsService.saveDashboardTree(this.treeview);
      this.refresh();

      this.adminDashboardsService.clipboardTreeviewItem = null;
    }
  }

  // dragStart(item: TreeNode) {
  //   //console.log('dragStart', item);
  // }

  // async onDrop(draggedItem: TreeNode, droppenOnitem?: TreeNode) {
  //   //console.log('onDrop', draggedItem, droppenOnitem);
  //   if (this.currentUserRights.isCustomerAdmin && draggedItem && draggedItem.parent && droppenOnitem) {
  //     draggedItem.parent.children = draggedItem.parent.children.filter((i) => i.id != draggedItem.id);
  //     droppenOnitem.children.push(draggedItem);
  //     await this.adminDashboardsService.saveDashboardTree(this.treeview);
  //     this.refresh();
  //   }
  // }

  // dragEnd() {
  //   //console.log('dragEnd');
  //   this.currentDraggedItem = null;
  // }

}
