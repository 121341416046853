import { AfterViewInit, Component, Inject, Input, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import { MasterTimeSettings, WidgetOutputChangeParameters, XprojWidgetService } from '../../../../../xproj-widget-service';
import { OutputDataType, WidgetConfig } from '../../../../../widget-config-service';
import { BaseControllerConfig, OutputPeriod, OutputTimeType, XprojOutputTimeControllerConfig } from '../../../xproj-output-controller-widget-config-service';
import { TypedJSON } from 'typedjson';
import { XprojMasterWidgetTimeComponent } from '../../../../../master/master-widget-time/xproj-master-widget-time.component';
import { XprojOutputControllerBase } from '../../../xproj-output-controller-base';
import { DateHelper } from '../../../../../../helpers/date-helper-service';
import { LOGGERSERVICE, XprojLoggerService } from '../../../../../../logger/xproj-logger-service';
import { DateIOFormats } from "@date-io/core/IUtils";

@Component({
  selector: 'xproj-output-time-controller',
  templateUrl: './xproj-output-time-controller.component.html',
  styleUrls: ['./xproj-output-time-controller.component.scss']
})
export class XprojOutputTimeControllerComponent extends XprojOutputControllerBase implements OnInit {

  @ViewChild("masterTime", { read: XprojMasterWidgetTimeComponent, static: false }) masterTime: XprojMasterWidgetTimeComponent;

  @Input() widgetConfig: WidgetConfig;
  @Input() config: BaseControllerConfig;

  _initDone: boolean = false;
  @Input() get initDone(): boolean {
    return this._initDone;
  }
  set initDone(value: boolean) {
    if (!this._initDone && value) {
      if (!this.useApplyButton) {
        this.onTimeApply();
      }
    }
    this._initDone = value;
  }


  timeConfig: XprojOutputTimeControllerConfig;

  selectedFrom: Date;
  selectedTo: Date;
  selectedPeriod: number = 0;

  selectedFromString: string;
  selectedToString: string;

  periods: { start: Date, end: Date }[] = [];

  OutputTimeType = OutputTimeType;

  constructor(
    @Inject(LOGGERSERVICE) private logger: XprojLoggerService,
    public widgetService: XprojWidgetService,
    private dateHelper: DateHelper
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {
    this.timeConfig = this.config as XprojOutputTimeControllerConfig;

    this.init();
  }

  ngAfterViewInit(): void {
  }

  onTimeApply() {
    if (!this.useApplyButton) {
      this.widgetService.outputParametersChanged(this.getOutputChangeParameters());
    }
  }

  init() {
    if (!this.timeConfig.FromConfig.Text || this.timeConfig.FromConfig.Text.length == 0) {
      this.timeConfig.FromConfig.Text = 'From';
    }
    if (!this.timeConfig.ToConfig.Text || this.timeConfig.ToConfig.Text.length == 0) {
      this.timeConfig.ToConfig.Text = 'To'
    }

    let now = new Date();
    let year: number = this.timeConfig.FromConfig.Year > -1 ? this.timeConfig.FromConfig.Year : now.getUTCFullYear();
    let month: number = this.timeConfig.FromConfig.Month > 0 ? this.timeConfig.FromConfig.Month - 1 : now.getUTCMonth();
    this.selectedFrom = new Date(year, month, 1);
    let day: number = this.timeConfig.FromConfig.Day > 0 ? this.timeConfig.FromConfig.Day : now.getUTCDate();
    if (day > this.dateHelper.utils.getDaysInMonth(this.selectedFrom)) {
      this.selectedFrom = new Date(year, month, this.dateHelper.utils.getDaysInMonth(this.selectedFrom));
    }
    else {
      this.selectedFrom = new Date(year, month, day);
    }
    this.selectedFrom = new Date(year, month, day);
    if (this.timeConfig.FromConfig.Year < 0) {
      this.selectedFrom = this.dateHelper.utils.addMonths(this.selectedFrom, 12 * this.timeConfig.FromConfig.YearOffset);
    }
    if (this.timeConfig.FromConfig.Month <= 0) {
      this.selectedFrom = this.dateHelper.utils.addMonths(this.selectedFrom, this.timeConfig.FromConfig.MonthOffset);
    }
    if (this.timeConfig.FromConfig.Day <= 0) {
      this.selectedFrom = this.dateHelper.utils.addDays(this.selectedFrom, this.timeConfig.FromConfig.DayOffset);
    }


    year = this.timeConfig.ToConfig.Year > -1 ? this.timeConfig.ToConfig.Year : now.getUTCFullYear();
    month = this.timeConfig.ToConfig.Month > 0 ? this.timeConfig.ToConfig.Month - 1 : now.getUTCMonth();
    this.selectedTo = new Date(year, month, 1);
    day = this.timeConfig.ToConfig.Day > 0 ? this.timeConfig.ToConfig.Day : now.getUTCDate();
    if (day > this.dateHelper.utils.getDaysInMonth(this.selectedTo)) {
      this.selectedTo = new Date(year, month, this.dateHelper.utils.getDaysInMonth(this.selectedTo));
    }
    else {
      this.selectedTo = new Date(year, month, day);
    }
    this.selectedTo = new Date(year, month, day);
    if (this.timeConfig.ToConfig.Year < 0) {
      this.selectedTo = this.dateHelper.utils.addMonths(this.selectedTo, 12 * this.timeConfig.ToConfig.YearOffset);
    }
    if (this.timeConfig.ToConfig.Month <= 0) {
      this.selectedTo = this.dateHelper.utils.addMonths(this.selectedTo, this.timeConfig.ToConfig.MonthOffset);
    }
    if (this.timeConfig.ToConfig.Day <= 0) {
      this.selectedTo = this.dateHelper.utils.addDays(this.selectedTo, this.timeConfig.ToConfig.DayOffset);
    }

    this.selectedFromString = this.dateHelper.utils.formatByString(this.selectedFrom, 'yyyy-MM-dd');
    this.selectedToString = this.dateHelper.utils.formatByString(this.selectedTo, 'yyyy-MM-dd');

    if (this.timeConfig.Type == OutputTimeType.Period) {
      this.periods = this.getPeriods();
    }
  }

  async onUpdateQuery() {
    this.init();
  }

  getOutputChangeParameters(): WidgetOutputChangeParameters[] {
    if (this.timeConfig) {
      let from: Date;
      let to: Date;
      let label : string = '';
      if (this.timeConfig.Type == OutputTimeType.Period) {
        let period = this.periods[this.selectedPeriod];
        from = period.start;
        to = period.end;
        label = this.formatPeriodString(period.start, period.end);
      }
      else {
        from = this.dateHelper.utils.parse(this.selectedFromString, 'yyyy-MM-dd');
        to = this.dateHelper.utils.parse(this.selectedToString, 'yyyy-MM-dd');
        to = this.dateHelper.utils.addDays(to, 1);
        to = this.dateHelper.utils.addSeconds(to, -1);
      }

      from = this.dateHelper.utils.addMinutes(from, -from.getTimezoneOffset());
      to = this.dateHelper.utils.addMinutes(to, -to.getTimezoneOffset());

      let outputFrom = new WidgetOutputChangeParameters();
      outputFrom.widgetId = this.widgetConfig.Id;
      outputFrom.outputParameterId = this.timeConfig.Id + '_from';
      outputFrom.datatype = OutputDataType.Timestamp;
      outputFrom.value = from;

      let outputs = [outputFrom];

      if (this.timeConfig.Type != OutputTimeType.Timestamp) {
        let outputTo = new WidgetOutputChangeParameters();
        outputTo.widgetId = this.widgetConfig.Id;
        outputTo.outputParameterId = this.timeConfig.Id + '_to';
        outputTo.datatype = OutputDataType.Timestamp;
        outputTo.value = to;

        outputs.push(outputTo);
      }

      let outputDisplayName = new WidgetOutputChangeParameters();
      outputDisplayName.widgetId = this.widgetConfig.Id;
      outputDisplayName.outputParameterId = this.timeConfig.Id + '_label';
      outputDisplayName.datatype = OutputDataType.String;
      outputDisplayName.value = label;
      outputs.push(outputDisplayName);

      return outputs;
    }
    else {
      return [];
    }
  }

  getPeriods(): { start: Date, end: Date }[] {
    let result: { start: Date, end: Date }[] = [];

    switch (this.timeConfig.Period) {
      case OutputPeriod.Year:
        let startYear = new Date(this.selectedFrom.getUTCFullYear(), 0);
        for (let i = this.timeConfig.PeriodCountForward; i > 0; i--) {
          let startOfYear = this.dateHelper.utils.addMonths(startYear, 12 * (i));
          let endOfYear = this.dateHelper.utils.addMonths(startOfYear, 12);
          result.push({ start: startOfYear, end: this.dateHelper.utils.addSeconds(endOfYear, -1) });
        }

        if (this.timeConfig.PeriodCountBackward > 0) {
          this.selectedPeriod = result.length;
        }
        else {
          this.selectedPeriod = result.length - 1;
        }

        for (let i = 0; i < this.timeConfig.PeriodCountBackward; i++) {
          let startOfYear = this.dateHelper.utils.addMonths(startYear, -12 * i);
          let endOfYear = this.dateHelper.utils.addMonths(startOfYear, 12);
          result.push({ start: startOfYear, end: this.dateHelper.utils.addSeconds(endOfYear, -1) });
        }
        break;

      case OutputPeriod.Quarter:
        let month = this.dateHelper.utils.getMonth(this.selectedFrom);
        let offset = 0 + ((month + 4 - (1 + ((this.timeConfig.QuarterStartMonth - 1) % 3))) % 3);

        for (let i = this.timeConfig.PeriodCountForward; i > 0; i--) {
          let monthStart = this.dateHelper.utils.addMonths(this.selectedFrom, ((i * 3) - offset));
          let monthEnd = this.dateHelper.utils.addMonths(this.selectedFrom, ((i * 3) - offset) + 2);
          let startOfMonth = this.dateHelper.utils.startOfMonth(monthStart);
          let endOfMonth = this.dateHelper.utils.endOfMonth(monthEnd);
          result.push({ start: startOfMonth, end: endOfMonth });
        }


        if (this.timeConfig.PeriodCountBackward > 0) {
          this.selectedPeriod = result.length;
        }
        else {
          this.selectedPeriod = result.length - 1;
        }

        for (let i = 0; i < this.timeConfig.PeriodCountBackward; i++) {
          let monthStart = this.dateHelper.utils.addMonths(this.selectedFrom, -((i * 3) + offset));
          let monthEnd = this.dateHelper.utils.addMonths(this.selectedFrom, -((i * 3) + offset) + 2);
          let startOfMonth = this.dateHelper.utils.startOfMonth(monthStart);
          let endOfMonth = this.dateHelper.utils.endOfMonth(monthEnd);
          result.push({ start: startOfMonth, end: endOfMonth });
        }
        break;

      case OutputPeriod.SixMonths:
        let monthSixMonths = this.dateHelper.utils.getMonth(this.selectedFrom);
        let offsetSixMonths = 0 + ((monthSixMonths + 4 - (1 + ((this.timeConfig.QuarterStartMonth - 1) % 3))) % 3);

        for (let i = this.timeConfig.PeriodCountForward; i > 0; i--) {
          let monthStart = this.dateHelper.utils.addMonths(this.selectedFrom, ((i * 6) - offsetSixMonths));
          let monthEnd = this.dateHelper.utils.addMonths(this.selectedFrom, ((i * 6) - offsetSixMonths) + 5);
          let startOfMonth = this.dateHelper.utils.startOfMonth(monthStart);
          let endOfMonth = this.dateHelper.utils.endOfMonth(monthEnd);
          result.push({ start: startOfMonth, end: endOfMonth });
        }


        if (this.timeConfig.PeriodCountBackward > 0) {
          this.selectedPeriod = result.length;
        }
        else {
          this.selectedPeriod = result.length - 1;
        }

        for (let i = 0; i < this.timeConfig.PeriodCountBackward; i++) {
          let monthStart = this.dateHelper.utils.addMonths(this.selectedFrom, -((i * 6) + offsetSixMonths));
          let monthEnd = this.dateHelper.utils.addMonths(this.selectedFrom, -((i * 6) + offsetSixMonths) + 5);
          let startOfMonth = this.dateHelper.utils.startOfMonth(monthStart);
          let endOfMonth = this.dateHelper.utils.endOfMonth(monthEnd);
          result.push({ start: startOfMonth, end: endOfMonth });
        }
        break;

      case OutputPeriod.Month:
        for (let i = this.timeConfig.PeriodCountForward; i > 0; i--) {
          let month = this.dateHelper.utils.addMonths(this.selectedFrom, i);
          let startOfMonth = this.dateHelper.utils.startOfMonth(month);
          let endOfMonth = this.dateHelper.utils.endOfMonth(month);
          result.push({ start: startOfMonth, end: endOfMonth });
        }

        if (this.timeConfig.PeriodCountBackward > 0) {
          this.selectedPeriod = result.length;
        }
        else {
          this.selectedPeriod = result.length - 1;
        }

        for (let i = 0; i < this.timeConfig.PeriodCountBackward; i++) {
          let month = this.dateHelper.utils.addMonths(this.selectedFrom, -i);
          let startOfMonth = this.dateHelper.utils.startOfMonth(month);
          let endOfMonth = this.dateHelper.utils.endOfMonth(month);
          result.push({ start: startOfMonth, end: endOfMonth });
        }
        break;

      case OutputPeriod.WeekSunday:
        let startOfWeek = this.dateHelper.utils.startOfWeek(this.selectedFrom);
        let endOfWeek = this.dateHelper.utils.endOfWeek(this.selectedFrom);
        for (let i = this.timeConfig.PeriodCountForward; i > 0; i--) {
          result.push({ start: this.dateHelper.utils.addWeeks(startOfWeek, i), end: this.dateHelper.utils.addWeeks(endOfWeek, i) });
        }
        if (this.timeConfig.PeriodCountBackward > 0) {
          this.selectedPeriod = result.length;
        }
        else {
          this.selectedPeriod = result.length - 1;
        }
        for (let i = 0; i < this.timeConfig.PeriodCountBackward; i++) {
          result.push({ start: this.dateHelper.utils.addWeeks(startOfWeek, -i), end: this.dateHelper.utils.addWeeks(endOfWeek, -i) });
        }
        break;

      case OutputPeriod.WeekMonday:
        let startOfWeekMonday = this.dateHelper.utils.startOfWeek(this.selectedFrom);
        startOfWeekMonday = this.dateHelper.utils.addDays(startOfWeekMonday, 1);
        let endOfWeekMonday = this.dateHelper.utils.endOfWeek(this.selectedFrom);
        endOfWeekMonday = this.dateHelper.utils.addDays(endOfWeekMonday, 1);
        for (let i = this.timeConfig.PeriodCountForward; i > 0; i--) {
          result.push({ start: this.dateHelper.utils.addWeeks(startOfWeekMonday, i), end: this.dateHelper.utils.addWeeks(endOfWeekMonday, i) });
        }
        if (this.timeConfig.PeriodCountBackward > 0) {
          this.selectedPeriod = result.length;
        }
        else {
          this.selectedPeriod = result.length - 1;
        }
        for (let i = 0; i < this.timeConfig.PeriodCountBackward; i++) {
          result.push({ start: this.dateHelper.utils.addWeeks(startOfWeekMonday, -i), end: this.dateHelper.utils.addWeeks(endOfWeekMonday, -i) });
        }
        break;

      case OutputPeriod.Day:
        let startOfDay = this.dateHelper.utils.startOfDay(this.selectedFrom);
        let endOfDay = this.dateHelper.utils.endOfDay(this.selectedFrom);
        for (let i = this.timeConfig.PeriodCountForward; i > 0; i--) {
          result.push({ start: this.dateHelper.utils.addDays(startOfDay, i), end: this.dateHelper.utils.addDays(endOfDay, i) });
        }
        if (this.timeConfig.PeriodCountBackward > 0) {
          this.selectedPeriod = result.length;
        }
        else {
          this.selectedPeriod = result.length - 1;
        }
        for (let i = 0; i < this.timeConfig.PeriodCountBackward; i++) {
          result.push({ start: this.dateHelper.utils.addDays(startOfDay, -i), end: this.dateHelper.utils.addDays(endOfDay, -i) });
        }
        break;

      case OutputPeriod.Hour:
        break;

    }

    return result;
  }

  getDataFormat(date : Date, dateFormat : string, defaultFormat : any) : any {
    let result = '';
    let format = dateFormat?.length > 0 ? dateFormat : defaultFormat;
    try {
      result = this.dateHelper.utils.format(date, format as any);
    }
    catch {
      try {
        result = this.dateHelper.utils.formatByString(date, format as string);
      }
      catch {
        result = this.dateHelper.utils.format(date, defaultFormat);
      }
    }

    return result;
  }

  formatPeriodString(start: Date, end: Date): string {
    let result = '';
    switch (this.timeConfig.Period) {
      case OutputPeriod.Year:
        result = this.getDataFormat(start, this.timeConfig.DateFormat, 'year');
        break;

      case OutputPeriod.Month:
        result = this.getDataFormat(start, this.timeConfig.DateFormat, 'monthAndYear');
        break;

      case OutputPeriod.Quarter:
        result = this.dateHelper.utils.format(start, 'keyboardDate') + ' - ' + this.dateHelper.utils.format(end, 'keyboardDate');
        break;

      case OutputPeriod.SixMonths:
        result = this.dateHelper.utils.format(start, 'keyboardDate') + ' - ' + this.dateHelper.utils.format(end, 'keyboardDate');
        break;

      case OutputPeriod.WeekSunday:
      case OutputPeriod.WeekMonday:
        result = this.dateHelper.utils.format(start, 'normalDateWithWeekday') + ' - ' + this.dateHelper.utils.format(end, 'normalDateWithWeekday');
        break;

      case OutputPeriod.Day:
        result = this.getDataFormat(start, this.timeConfig.DateFormat, 'normalDateWithWeekday');
        break;

      case OutputPeriod.Hour:
        result = this.getDataFormat(start, this.timeConfig.DateFormat, 'fullTime24h');
        break;

    }

    return result;
  }
}
