import { jsonObject, jsonMember } from 'typedjson';

@jsonObject
export class GlobalWidgetSettings {
  @jsonMember
  public LinkAllWidgets : boolean = false;

  @jsonMember
  public Decimals : number = 2;

  @jsonMember
  public MinUpdateInterval : number = 5000;

  @jsonMember
  public ColorScheme : string = 'tableau.Classic20';

  @jsonMember
  public DateUTC : boolean = false;

}
