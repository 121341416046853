import { BrowserModule } from '@angular/platform-browser';
import { NgModule, PLATFORM_ID } from '@angular/core';
import { GlobalErrorHandler } from './globalerrorhandler'

import { ErrorHandler } from '@angular/core'

import { FormsModule } from '@angular/forms';


import { AppRoutingModule } from '@app/app-routing.module';
import { AppComponent } from '@app/app.component';
import { ClarityModule } from '@clr/angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MainNavComponent } from '@core/components/main-nav/main-nav.component';
import { SettingsComponent } from '@shared/components/settings/settings.component';
import { AngularResizeEventModule } from 'angular-resize-event';
import { AngularSplitModule } from 'angular-split';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { HttpClientModule } from '@angular/common/http';
import { NgxFileDropModule } from 'ngx-file-drop';
import { GrpcCoreModule, GrpcLoggerModule } from '@ngx-grpc/core';
import { GrpcWorkerClientModule } from '@ngx-grpc/worker-client';
import { GrpcMessage } from '@ngx-grpc/common';
//import { LMarkdownEditorModule } from 'ngx-markdown-editor';
import { CacheModule, XPROJBIMSERVICE, XprojConsoleLoggerService } from 'xproj-lib';
import { QRCodeModule } from 'angularx-qrcode';

import { XPROJECTORBACKENDCLIENTSETTINGS } from '@xprojectorcore/xprojector_backend/xprojector-backend-settings-service';

import { LOGGERSERVICE, LocalstorageDashboardService, DASHBOARDSERVICE, XprojModule, XprojModuleCache, XPROJECTORCLIENTSETTINGS, XProjectorClient, XProjectorClientModule, XprojAlertService, XprojModalService, XprojCommonStringsService } from 'xproj-lib';

import { SocialLoginModule, SocialAuthServiceConfig } from '@vipstorage/angularx-social-login';
import {
  GoogleLoginProvider,
  MicrosoftLoginProvider
} from '@vipstorage/angularx-social-login';


import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import { environment } from 'src/environments/environment';
import { CommonModule } from '@angular/common';
import { StateService } from '@xprojectorcore/services/state-service';
import { LoginComponent } from '@shared/components/login/login.component';
import { XProjectorSysAdminClient } from '@xprojectorcore/xprojector_backend/xprojector-sysadmin-client';
import { XProjectorXConfClient } from '@xprojectorcore/xprojector_backend/xprojector-xconf-client';
import { UnitConversionsService } from '@xprojectorcore/services/unit-conversions.service';
import { XProjectorCustomerUsersClient } from '@xprojectorcore/xprojector_backend/xprojector-customerusers-client';
import { XProjectorCustomerAdminClient } from '@xprojectorcore/xprojector_backend/xprojector-customeradmin-client';
import { XProjectorFilesClient } from '@xprojectorcore/xprojector_backend/xprojector-files-client';
import { XProjectorXEdgeClient } from '@xprojectorcore/xprojector_backend/xprojector-xedge-client';
//import { SharedModule} from '@shared/shared.module';
import { SysAdminModule } from '@xprojectorfeatures/sysadmin/sys-admin.module';
import { UserProfileComponent } from '@shared/components/user-profile/user-profile.component';
import { CustomerSettingsComponent } from '@shared/components/customer-settings/customer-settings.component';
import { DataConfigurationModule } from '@xprojectorfeatures/data-configuration/data-configuration.module';
import { XEdgeModule } from '@xprojectorfeatures/xedge/xedge.module';
import { HomeComponent } from '@core/components/home/home.component';
import { UserProfilesComponent } from '@shared/components/user-profiles/user-profiles.component';
import { DashboardService } from '@xprojectorcore/services/dashboard.service';
import { AdminDashboardsXConfService } from '@xprojectorfeatures/admin-dashboards/services/admin-dashboards-xconf.service';
import { XbotExecutionService } from '@xprojectorcore/services/xbot-execution.service';
import { XprojBimXConfService } from '@xprojectorfeatures/bim/services/xproj-bim-xconf.service';
import { XprojBimXdbService } from '@xprojectorfeatures/bim/services/xproj-bim-xdb.service';

import { RossakerBmsModule } from '@features/rossaker-bms/rossaker-bms.module';
import { XProjectorCredentialsClient } from '@xprojectorcore/xprojector_backend/xprojector-credentials-client';
import { CreateCredentialsComponent } from './shared/components/create-credentials/create-credentials.component';
import { ResetPasswordComponent } from './shared/components/reset-password/reset-password.component';
import { StartMobileAppComponent } from '@shared/components/start-mobile-app/start-mobile-app.component';
import { NgxloggerLoggerService } from '@xprojectorcore/services/ngxlogger-logger.service';
import { AlertComponent } from '@core/components/alert/alert.component';
import { SessionStorageService } from '@xprojectorcore/services/session-storage.service';

import '@xprojectorcore/icons';
import { ADMINDASHBOARDSSERVICE } from '@xprojectorfeatures/admin-dashboards/services/admin-dashboards.service';

declare var XPROJECTOR_HOST;
declare var XPROJECTOR_BACKEND_HOST;
declare var XPROJECTOR_BACKEND_GRPC;

const googleLoginOptions = {
  scopes: ['profile', 'email']
}; // https://developers.google.com/api-client-library/javascript/reference/referencedocs#gapiauth2clientconfig

const microsoftLoginOptions = {
  redirect_uri : location.origin,
  //cacheLocation: 'localStorage'
  cacheLocation: 'sessionStorage'
};

@NgModule({
  declarations: [
    AppComponent,
    MainNavComponent,
    SettingsComponent,
    HomeComponent,
    StartMobileAppComponent,
    AlertComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    ClarityModule,
    BrowserAnimationsModule,
    FormsModule,
    AngularResizeEventModule,
    AngularSplitModule,
    HttpClientModule,
    NgxFileDropModule,
    //MonacoEditorModule.forRoot(),

    LoggerModule.forRoot({
      //serverLoggingUrl: '/api/logs',
      level: (!environment.production && !environment.logoff) ? NgxLoggerLevel.DEBUG : NgxLoggerLevel.OFF,
      // serverLogLevel
      serverLogLevel: NgxLoggerLevel.OFF,
      colorScheme : ['purple', 'teal', 'gray', 'gray', 'red', 'red', 'red']
    }),
    XprojModule,
    XProjectorClientModule.forRoot({ host : XPROJECTOR_HOST, cacheTTLSeconds : 30, cacheEnabled : true }),
    XprojModuleCache,
    SysAdminModule,
    DataConfigurationModule,
    XEdgeModule,
    //LMarkdownEditorModule,
    SocialLoginModule,
    QRCodeModule,

    GrpcCoreModule.forRoot(),
    GrpcWorkerClientModule.forRoot({
      worker: new Worker(new URL('./core/xprojector_backend/grpc.worker', import.meta.url), { type: 'module' }),
      settings: { host: XPROJECTOR_BACKEND_GRPC },
    }),
    GrpcLoggerModule.forRoot({
      settings: {
         // enables logger in dev mode and still lets you see them in production when running `localStorage.setItem('logger', 'true') in the console`
        enabled: localStorage.getItem('GRPC_CONSOLE_LOGGER_ENABLED') === 'true' || (!environment.production && !environment.logoff),
         // protobuf json is more human-readable than the default toObject() mapping
         // please beware: if you use google.protobuf.Any you must pass the proper `messagePool` argument
        requestMapper: (msg: GrpcMessage) => msg.toProtobufJSON(),
        responseMapper: (msg: GrpcMessage) => msg.toProtobufJSON(),
      },
    }),

    RossakerBmsModule,
  ],
  exports: [
    XprojModule,
    AlertComponent
  ],
  providers: [
    /*{provide: ErrorHandler, useClass: GlobalErrorHandler},*/
    { provide : DASHBOARDSERVICE, useClass: DashboardService },
    { provide : ADMINDASHBOARDSSERVICE, useClass: AdminDashboardsXConfService },
    //{ provide : XPROJBIMSERVICE, useClass: XprojBimXConfService },
    { provide : XPROJBIMSERVICE, useClass: XprojBimXdbService },

    //{ provide : XPROJECTORCLIENTSETTINGS, useValue: { host : XPROJECTOR_HOST } },
    StateService,
    XprojAlertService,
    XProjectorSysAdminClient,
    XProjectorCustomerUsersClient,
    XProjectorCustomerAdminClient,
    XProjectorXConfClient,
    UnitConversionsService,
    XProjectorFilesClient,
    XProjectorCredentialsClient,
    XbotExecutionService,
    XprojModalService,
    XprojCommonStringsService,
    SessionStorageService,

    XProjectorXEdgeClient,

    { provide : XPROJECTORBACKENDCLIENTSETTINGS, useValue: { host : XPROJECTOR_BACKEND_HOST } },
    { provide : LOGGERSERVICE, useClass: NgxloggerLoggerService },

    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: true,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '771785567665-3hlqfuad17pbp68hopsjsu2b33bgdich.apps.googleusercontent.com',
              googleLoginOptions
            ),
          },
          {
            id: MicrosoftLoginProvider.PROVIDER_ID,
            provider: new MicrosoftLoginProvider(
              '32f52463-c72d-4b20-8500-6d0e2e67721d',
              microsoftLoginOptions
            )
          }
        ],
      } as SocialAuthServiceConfig,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
