import { Injectable } from '@angular/core';
import { RossakerBmsCustomerConfig } from '@core/models/rossaker-bms-customer-config';
import { RossakerBmsCustomer } from '@core/models/rossaker-bms-customer';
import { XProjectorXConfClient } from '@xprojectorcore/xprojector_backend/xprojector-xconf-client';
import { resourceLimits } from 'worker_threads';
import { ArrayUtils, BaseQuery, BaseQueryInputColumnDescription, BaseQueryResult, ColumnFilteringNumerical, ColumnFilteringString, DateHelper, FilterComparator, FilterLogicalGroup, FilterLogicalGroupType, ProjectionColumnDescription, SetDataColumnDescription, SetDataQuery, XDataType, XProjectorClient } from 'xproj-lib';
import { RossakerBmsDataUtils, flakeId } from '@core/utils/rossaker-bms-data-utils';
import { RossakerBmsBillingPeriod, RossakerBmsMonthOfYear } from '@core/models/rossaker-bms-export-bot';
import { RossakerBmsRealestate } from '@core/models/rossaker-bms-realestate';
import { RossakerBmsBuilding } from '@core/models/rossaker-bms-building';
import { RossakerBmsBuildingAddress } from '@core/models/rossaker-bms-buildingaddress';
import { RossakerBmsApartment } from '@core/models/rossaker-bms-apartment';
import { RossakerBmsLatestValue } from '@core/models/rossaker-bms-latestvalue';
import { RossakerBmsFacility } from '@core/models/rossaker-bms-facility';
import { RossakerBmsMeter, RossakerBmsMeterState } from '@core/models/rossaker-bms-meter';
import { RossakerBmsGateway } from '@core/models/rossaker-bms-gateway';
import { RossakerBmsLorawanMultiMeter } from '@core/models/rossaker-bms-lorawan-multimeter';
import { NGXLogger } from 'ngx-logger';
import { BasicResponse, GrpcNode } from '@xprojectorcore/xprojector_backend/proto/xprojector.grpc.models.pb';
import { RossakerBmsMeterData } from '@core/models/rossaker-bms-meter-data';
import { RossakerBmsInvoiceInfo } from '@core/models/rossaker-bms-invoice-info'
import * as XLSX from 'xlsx';
import { GrpcNodeType, SearchNodesRequest } from '@xprojectorcore/xprojector_backend/proto/xprojector.xconf.pb';
import { RossakerLorawanDeviceInfo } from '../models/rossaker-lorawan-device-info';
import { XprojBimBuildingService } from '@xprojectorfeatures/bim/services/xproj-bim-building.service';
import { BimBuildingaddress } from '@xprojectorfeatures/bim/models/bim-buildingaddress';

const CUSTOMER_PROJECTIONID: string = 'ros-customer';
const CUSTOMER_REALESTATE_PROJECTIONID: string = 'ros-customer-realestate';
const REALESTATE_PROJECTIONID: string = 'ros-realestate';
const BUILDING_PROJECTIONID: string = 'ros-building';
const BUILDINGADDRESS_PROJECTIONID: string = 'ros-buildingaddress';
const APARTMENT_PROJECTIONID: string = 'ros-apartment';
const FACILITY_PROJECTIONID: string = 'ros-facility';
const METER_PROJECTIONID: string = 'ros-meter';
const INVOICEINFO_PROJECTIONID: string = 'ros-invoiceinfo';
const LORAWANDEVICEINFO_PROJECTIONID: string = 'lw-lorawandeviceinfo';
const DATAPOINTLATEST_PROJECTIONID: string = 'ros-datapointvalue-latest';

@Injectable({
  providedIn: 'root'
})
export class RossakerBmsAdminService implements XprojBimBuildingService {

  customerColumns: ProjectionColumnDescription[] = [];
  customerRealestateColumns: ProjectionColumnDescription[] = [];
  realestateColumns: ProjectionColumnDescription[] = [];
  buildingColumns: ProjectionColumnDescription[] = [];
  buildingAddressColumns: ProjectionColumnDescription[] = [];
  apartmentColumns: ProjectionColumnDescription[] = [];
  facilityColumns: ProjectionColumnDescription[] = [];
  meterColumns: ProjectionColumnDescription[] = [];
  invoiceInfoColumns: ProjectionColumnDescription[] = [];
  loraWanDeviceInfoColumns: ProjectionColumnDescription[] = [];
  latestValuesColumns: ProjectionColumnDescription[] = [];

  private setDataInvoiceInfoColumns: SetDataColumnDescription[] = [
    {
      columnname: 'id',
      datatype: XDataType.Int64,
      indexintypedvector: 0
    },
    {
      columnname: 'customerid',
      datatype: XDataType.String,
      indexintypedvector: 0
    },
    {
      columnname: 'metertype',
      datatype: XDataType.String,
      indexintypedvector: 1
    },
    {
      columnname: 'activefrom',
      datatype: XDataType.Timestamp,
      indexintypedvector: 0
    },
    {
      columnname: 'createdat',
      datatype: XDataType.Timestamp,
      indexintypedvector: 1
    },
    {
      columnname: 'modifiedat',
      datatype: XDataType.Timestamp,
      indexintypedvector: 2
    },
    {
      columnname: 'deletedat',
      datatype: XDataType.Timestamp,
      indexintypedvector: 3
    },
    {
      columnname: 'deleted',
      datatype: XDataType.UInt8,
      indexintypedvector: 1
    }
  ];

  constructor(
    private xconfClient: XProjectorXConfClient,
    private xprojClient: XProjectorClient,
    private dateHelper: DateHelper,
    private logger: NGXLogger
  ) {

  }


  async getBimBuildingaddresses(buildingId: number): Promise<BimBuildingaddress[]> {
    let result: BimBuildingaddress[] = [];
    let buildingAddresses = await this.getBuildingAddressesByBuilding(buildingId, true);

    buildingAddresses.forEach(x => {
      let bimBuildingAddress = new BimBuildingaddress();
      bimBuildingAddress.id = x.id;
      bimBuildingAddress.description = x.description;
      bimBuildingAddress.northof = x.northOf;
      bimBuildingAddress.eastof = x.eastOf;
      bimBuildingAddress.southof = x.southOf;
      bimBuildingAddress.westof = x.westOf;

      result.push(bimBuildingAddress);
    });

    return result;
  }

  async setBimBuildingaddresses(buildingId: number, bimBuildingAddresses: BimBuildingaddress[], customerId: string): Promise<boolean> {
    let buildingAddressesNodes = await this.xconfClient.getReferencedNodes(buildingId + '', '_x_bms_building', [], '_x_bms_buildingaddress', 1);

    await ArrayUtils.AsyncForEach(bimBuildingAddresses, (async (x: BimBuildingaddress) => {
      let buildingAddressesNode = buildingAddressesNodes.find(ba => ba.id == x.id + '');
      if (buildingAddressesNode) {
        let nodeChanged: boolean = false;
        buildingAddressesNode.propertyValues.forEach(p => {
          switch (p.key) {
            case 'northof':
              let prevNorthOf = RossakerBmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
              if (prevNorthOf != x.northof) {
                p.value = RossakerBmsDataUtils.getPropertyValue(p.valueType, x.northof, this.dateHelper);
                nodeChanged = true;
              }
              break;
            case 'eastof':
              let prevEastOf = RossakerBmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
              if (prevEastOf != x.eastof) {
                p.value = RossakerBmsDataUtils.getPropertyValue(p.valueType, x.eastof, this.dateHelper);
                nodeChanged = true;
              }
              break;
            case 'southof':
              let prevSouthOf = RossakerBmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
              if (prevSouthOf != x.southof) {
                p.value = RossakerBmsDataUtils.getPropertyValue(p.valueType, x.southof, this.dateHelper);
                nodeChanged = true;
              }
              break;
            case 'westof':
              let prevWestOf = RossakerBmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
              if (prevWestOf != x.westof) {
                p.value = RossakerBmsDataUtils.getPropertyValue(p.valueType, x.westof, this.dateHelper);
                nodeChanged = true;
              }
              break;
          }
        });

        if (nodeChanged) {
          await this.xconfClient.updateNode(buildingAddressesNode, buildingAddressesNode.id, '', customerId);
        }
      }
    }));


    return true;
  }

  async getCustomerConfig(customerId: string, nodeTypes: GrpcNodeType[] = null): Promise<RossakerBmsCustomerConfig> {
    let customerConfigNode = await this.xconfClient.getNode('_x_bms_customerconfig_root_' + customerId, '_x_bms_customerconfig_root');

    return this.nodeToCustomerConfig(customerConfigNode, customerId, nodeTypes);
  }

  async nodeToCustomerConfig(customerConfigNode: GrpcNode, customerId: string, nodeTypes: GrpcNodeType[] = null): Promise<RossakerBmsCustomerConfig> {
    let result: RossakerBmsCustomerConfig = new RossakerBmsCustomerConfig();
    result.customerId = customerId;

    if (customerConfigNode) {

      customerConfigNode.propertyValues.forEach(p => {
        switch (p.key) {

          case 'organisationnumber':
            result.organisationNumber = RossakerBmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
            break;
          case 'associationid':
            result.associationId = RossakerBmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
            break;
          case 'associationid2':
            result.associationId2 = RossakerBmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
            break;
          case 'tariffdecimalcount':
            result.tariffDecimalCount = RossakerBmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
            break;
          case 'reportdatapointdecimalcount':
            result.reportDataPointDecimalCount = RossakerBmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
            break;
          case 'trustee':
            result.trustee = RossakerBmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
            break;
          case 'customeristrustee':
            result.customerIsTrustee = RossakerBmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
            break;
          case 'mergechargingstationswithel':
            result.mergeChargingStationsWithEl = RossakerBmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
            break;
          case 'billingenabled':
            result.billingEnabled = RossakerBmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
            break;
          case 'billingperiod':
            result.billingPeriod = +RossakerBmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
            if (isNaN(result.billingPeriod)) {
              result.billingPeriod = RossakerBmsBillingPeriod[RossakerBmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper) as string];
            }
            break;
          case 'billingperiodchangedat':
            result.billingPeriodChangedAt = RossakerBmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
            result.billingPeriodChangedAtString = this.dateHelper.utils.formatByString(result.billingPeriodChangedAt, 'yyyy-MM-dd');
            break;
          case 'billingperiodstartmonth':
            result.billingPeriodStartMonth = +RossakerBmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
            if (isNaN(result.billingPeriodStartMonth)) {
              result.billingPeriodStartMonth = RossakerBmsMonthOfYear[RossakerBmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper) as string];
            }
            break;
          case 'disabled':
            result.disabled = RossakerBmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
            break;
          case 'extrapolatecoefficient':
            result.extrapolateCoefficient = RossakerBmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
            break;
          case 'paddingexternalidcount':
            result.paddingExternalIdCount = RossakerBmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
            break;
          case 'paddingexternalidoverridetrustee':
            result.paddingExternalIdOverrideTrustee = RossakerBmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
            break;
          case 'simcardcount':
            result.simCardCount = RossakerBmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
            break;
          case 'contracttype':
            result.contractType = RossakerBmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
            if (nodeTypes) {
              let nodeType = nodeTypes.find(nt => nt.id == '_x_bms_customerconfig_root');
              if (nodeType) {
                result.contractTypeStringOptionsProperty = nodeType.stringOptionsProperties.find(sp => sp.id == p.key);
              }
            }
            break;
          case 'eventsenabled':
            result.eventsEnabled = RossakerBmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
            break;

        }
      });
    }

    return result;
  }

  async saveCustomerConfig(customerConfig: RossakerBmsCustomerConfig, customerConfigNode: GrpcNode = undefined): Promise<BasicResponse> {
    if (!customerConfigNode) {
      customerConfigNode = await this.xconfClient.getNode('_x_bms_customerconfig_root_' + customerConfig.customerId, '_x_bms_customerconfig_root');
    }

    if (customerConfigNode) {
      customerConfigNode.propertyValues.forEach(p => {
        switch (p.key) {

          case 'organisationnumber':
            p.value = RossakerBmsDataUtils.getPropertyValue(p.valueType, customerConfig.organisationNumber, this.dateHelper);
            break;
          case 'associationid':
            p.value = RossakerBmsDataUtils.getPropertyValue(p.valueType, customerConfig.associationId, this.dateHelper);
            break;
          case 'associationid2':
            p.value = RossakerBmsDataUtils.getPropertyValue(p.valueType, customerConfig.associationId2, this.dateHelper);
            break;
          case 'tariffdecimalcount':
            p.value = RossakerBmsDataUtils.getPropertyValue(p.valueType, customerConfig.tariffDecimalCount, this.dateHelper);
            break;
          case 'reportdatapointdecimalcount':
            p.value = RossakerBmsDataUtils.getPropertyValue(p.valueType, customerConfig.reportDataPointDecimalCount, this.dateHelper);
            break;
          case 'trustee':
            p.value = RossakerBmsDataUtils.getPropertyValue(p.valueType, customerConfig.trustee, this.dateHelper);
            break;
          case 'customeristrustee':
            p.value = RossakerBmsDataUtils.getPropertyValue(p.valueType, customerConfig.customerIsTrustee, this.dateHelper);
            break;
          case 'mergechargingstationswithel':
            p.value = RossakerBmsDataUtils.getPropertyValue(p.valueType, customerConfig.mergeChargingStationsWithEl, this.dateHelper);
            break;
          case 'billingenabled':
            p.value = RossakerBmsDataUtils.getPropertyValue(p.valueType, customerConfig.billingEnabled, this.dateHelper);
            break;
          case 'billingperiod':
            p.value = RossakerBmsDataUtils.getPropertyValue(p.valueType, customerConfig.billingPeriod, this.dateHelper);
            break;
          case 'billingperiodchangedat':
            customerConfig.billingPeriodChangedAt = this.dateHelper.utils.parse(customerConfig.billingPeriodChangedAtString, 'yyyy-MM-dd');
            p.value = RossakerBmsDataUtils.getPropertyValue(p.valueType, this.dateHelper.utils.addMinutes(customerConfig.billingPeriodChangedAt, -customerConfig.billingPeriodChangedAt.getTimezoneOffset()), this.dateHelper);
            break;
          case 'billingperiodstartmonth':
            p.value = RossakerBmsDataUtils.getPropertyValue(p.valueType, customerConfig.billingPeriodStartMonth, this.dateHelper);
            break;
          case 'extrapolatecoefficient':
            p.value = RossakerBmsDataUtils.getPropertyValue(p.valueType, customerConfig.extrapolateCoefficient, this.dateHelper);
            break;
          case 'disabled':
            p.value = RossakerBmsDataUtils.getPropertyValue(p.valueType, customerConfig.disabled, this.dateHelper);
            break;
          case 'paddingexternalidcount':
            p.value = RossakerBmsDataUtils.getPropertyValue(p.valueType, customerConfig.paddingExternalIdCount, this.dateHelper);
            break;
          case 'paddingexternalidoverridetrustee':
            p.value = RossakerBmsDataUtils.getPropertyValue(p.valueType, customerConfig.paddingExternalIdOverrideTrustee, this.dateHelper);
            break;
          case 'simcardcount':
            p.value = RossakerBmsDataUtils.getPropertyValue(p.valueType, customerConfig.simCardCount, this.dateHelper);
            break;
          case 'contracttype':
            p.value = RossakerBmsDataUtils.getPropertyValue(p.valueType, customerConfig.contractType, this.dateHelper);
            break;
          case 'eventsenabled':
            p.value = RossakerBmsDataUtils.getPropertyValue(p.valueType, customerConfig.eventsEnabled, this.dateHelper);
            break;

        }
      });

      return this.xconfClient.updateNode(customerConfigNode, customerConfigNode.id, '', customerConfig.customerId);
    }
    else {
      return new BasicResponse({ result: false, message: 'Error get customer config node!' });
    }
  }

  async deleteCustomerConfig(customerId: string): Promise<BasicResponse> {
    return await this.xconfClient.deleteNode('_x_bms_customerconfig_root_' + customerId, '_x_bms_customerconfig_root', '', customerId);
  }

  async getCustomers(forceReload: boolean = false): Promise<RossakerBmsCustomer[]> {
    return this._getCustomers('', forceReload);
  }

  async getCustomer(customerId: string, forceReload: boolean = false): Promise<RossakerBmsCustomer> {
    let result = await this._getCustomers(customerId, forceReload);
    if (result.length > 0) {
      return result[0];
    }

    return undefined;
  }

  private async _getCustomers(customerId: string = '', forceReload: boolean = false): Promise<RossakerBmsCustomer[]> {
    let result: RossakerBmsCustomer[] = [];
    if (this.customerColumns.length == 0) {
      this.customerColumns = await this.xprojClient.RequestListQueryableProjectionColumns(CUSTOMER_PROJECTIONID, '', 0, 100);
    }

    let query: BaseQuery = new BaseQuery();
    query.targetprojectionid = CUSTOMER_PROJECTIONID;
    query.maxitems = 5000;
    query.targetgroup = [];

    let filterId = 0;
    query.filter.type = FilterLogicalGroupType.AND;

    if (customerId?.length > 0) {
      let customerIdFiltering = new ColumnFilteringString();
      customerIdFiltering.columnname = 'customerid';
      customerIdFiltering.comparator = FilterComparator.Equals;
      customerIdFiltering.value = customerId;
      customerIdFiltering.queryfilterid = ++filterId;
      query.filter.filters.push(customerIdFiltering.queryfilterid);
      query.stringfilters.push(customerIdFiltering);
    }

    let deletedFiltering = new ColumnFilteringNumerical();
    deletedFiltering.columnname = 'deleted';
    deletedFiltering.comparator = FilterComparator.Equals;
    deletedFiltering.value = 0;
    deletedFiltering.queryfilterid = ++filterId;
    query.filter.filters.push(deletedFiltering.queryfilterid);
    query.numericalfilters.push(deletedFiltering);

    this.customerColumns.forEach(c => {
      let inCol = new BaseQueryInputColumnDescription();
      inCol.columnname = c.columnname;
      inCol.columnoutname = c.columnname;
      query.columns.push(inCol);
    });

    query.sorting.columnname = 'customername';
    query.sorting.descending = false;

    try {
      let queryResult = await this.xprojClient.RequestQueryBaseQuery(query, forceReload);

      let numericaldata = queryResult.datanumbers;
      let timestampdata = queryResult.datatimestamps;
      let stringdata = queryResult.datastrings;

      let rowCount = 0;
      if (numericaldata.length > 0) {
        rowCount = numericaldata[0].length;
      }

      for (let row = 0; row < rowCount; row++) {
        let customer = new RossakerBmsCustomer();
        for (let i = 0; i < queryResult.columns.length; i++) {
          let it = queryResult.columns[i];
          let typ = it.datatype;
          let data = [];
          if (typ == XDataType.Number) {
            data = numericaldata[it.indexintypedvector];
          }
          if (typ == XDataType.String) {
            data = stringdata[it.indexintypedvector];
          }
          if (typ == XDataType.Timestamp) {
            data = timestampdata[it.indexintypedvector];
          }

          switch (it.columnoutname) {
            case 'id':
              customer.id = data[row];
              break;
            case 'customerid':
              customer.customerId = data[row];
              break;
            case 'organisationnumber':
              customer.organisationNumber = data[row];
              break;
            case 'customername':
              customer.customerName = data[row];
              break;
            case 'associationid':
              customer.associationId = data[row];
              break;
            case 'customeristrustee':
              customer.customerIsTrustee = data[row] > 0;
              break;
            case 'billingenabled':
              customer.billingEnabled = data[row] > 0;
              break;
            case 'extrapolateenabled':
              customer.extrapolateEnabled = data[row] > 0;
              break;
            case 'extrapolatecoefficient':
              customer.extrapolateCoefficient = data[row];
              break;
            case 'tariffdecimalcount':
              customer.tariffDecimalCount = data[row];
              break;
            case 'extrapolatecoefficient':
              customer.extrapolateCoefficient = data[row];
              break;
            case 'disabled':
              customer.disabled = data[row] > 0;
              break;
          }
        }

        result.push(customer);
      }
    }
    catch (err) {
      this.logger.error(err);
    }

    return result;
  }


  async getCustomerIdByRealestate(realestateId: number): Promise<string> {

    let result: string = '';
    let query: BaseQuery = new BaseQuery();
    query.targetprojectionid = CUSTOMER_REALESTATE_PROJECTIONID;
    query.maxitems = 1;
    query.targetgroup = [];

    let filterId = 0;
    query.filter.type = FilterLogicalGroupType.AND;

    let realestateFiltering = new ColumnFilteringNumerical();
    realestateFiltering.columnname = 'realestateid';
    realestateFiltering.comparator = FilterComparator.Equals;
    realestateFiltering.value = realestateId;
    realestateFiltering.queryfilterid = ++filterId;
    query.filter.filters.push(realestateFiltering.queryfilterid);
    query.numericalfilters.push(realestateFiltering);

    let deletedFiltering = new ColumnFilteringNumerical();
    deletedFiltering.columnname = 'deleted';
    deletedFiltering.comparator = FilterComparator.Equals;
    deletedFiltering.value = 0;
    deletedFiltering.queryfilterid = ++filterId;
    query.filter.filters.push(deletedFiltering.queryfilterid);
    query.numericalfilters.push(deletedFiltering);

    let inCol = new BaseQueryInputColumnDescription();
    inCol.columnname = 'customerid';
    inCol.columnoutname = 'customerid';
    query.columns.push(inCol);

    try {
      let queryResult = await this.xprojClient.RequestQueryBaseQuery(query, true);

      if (queryResult.datastrings.length > 0 && queryResult.datastrings[0].length > 0) {
        result = queryResult.datastrings[0][0];
      }

    }
    catch (err) {
      this.logger.error(err);
    }

    return result;
  }

  async getRealestates(customerId: string, forceReload: boolean = false): Promise<RossakerBmsRealestate[]> {
    let result: RossakerBmsRealestate[] = [];

    if (this.realestateColumns.length == 0) {
      this.realestateColumns = await this.xprojClient.RequestListQueryableProjectionColumns(REALESTATE_PROJECTIONID, '', 0, 100);
    }

    let query: BaseQuery = new BaseQuery();
    query.targetprojectionid = REALESTATE_PROJECTIONID;
    query.maxitems = 5000;
    query.targetgroup = [];

    let filterId = 0;
    query.filter.type = FilterLogicalGroupType.AND;

    let customerFiltering = new ColumnFilteringString();
    customerFiltering.columnname = 'customerid';
    customerFiltering.comparator = FilterComparator.Equals;
    customerFiltering.value = customerId;
    customerFiltering.queryfilterid = ++filterId;
    query.filter.filters.push(customerFiltering.queryfilterid);
    query.stringfilters.push(customerFiltering);

    let deletedFiltering = new ColumnFilteringNumerical();
    deletedFiltering.columnname = 'deleted';
    deletedFiltering.comparator = FilterComparator.Equals;
    deletedFiltering.value = 0;
    deletedFiltering.queryfilterid = ++filterId;
    query.filter.filters.push(deletedFiltering.queryfilterid);
    query.numericalfilters.push(deletedFiltering);

    this.realestateColumns.forEach(c => {
      let inCol = new BaseQueryInputColumnDescription();
      inCol.columnname = c.columnname;
      inCol.columnoutname = c.columnname;
      query.columns.push(inCol);
    });

    query.sorting.columnname = 'name';
    query.sorting.descending = false;

    try {
      let queryResult = await this.xprojClient.RequestQueryBaseQuery(query, forceReload);

      let numericaldata = queryResult.datanumbers;
      let timestampdata = queryResult.datatimestamps;
      let stringdata = queryResult.datastrings;

      let rowCount = 0;
      if (numericaldata.length > 0) {
        rowCount = numericaldata[0].length;
      }

      for (let row = 0; row < rowCount; row++) {
        let realestate = new RossakerBmsRealestate();
        realestate.customerId = customerId;
        for (let i = 0; i < queryResult.columns.length; i++) {
          let it = queryResult.columns[i];
          let typ = it.datatype;
          let data = [];
          if (typ == XDataType.Number) {
            data = numericaldata[it.indexintypedvector];
          }
          if (typ == XDataType.String) {
            data = stringdata[it.indexintypedvector];
          }
          if (typ == XDataType.Timestamp) {
            data = timestampdata[it.indexintypedvector];
          }

          switch (it.columnoutname) {
            case 'id':
              realestate.id = data[row];
              break;
            case 'name':
              realestate.name = data[row];
              break;
            case 'svlant-propertydesignation':
              realestate.svlantPropertyDesignation = data[row];
              break;
            case 'description':
              realestate.description = data[row];
              break;
            case 'latitude':
              realestate.latitude = data[row];
              break;
            case 'longitude':
              realestate.longitude = data[row];
              break;
            case 'createdat':
              realestate.createdAt = data[row];
              break;
            case 'modifiedat':
              realestate.modifiedAt = data[row];
              break;
          }
        }

        result.push(realestate);
      }
    }
    catch (err) {
      this.logger.error(err);
    }

    return result;
  }

  async getBuildings(customerId: string, forceReload: boolean = false): Promise<RossakerBmsBuilding[]> {
    let result: RossakerBmsBuilding[] = [];

    if (this.buildingColumns.length == 0) {
      this.buildingColumns = await this.xprojClient.RequestListQueryableProjectionColumns(BUILDING_PROJECTIONID, '', 0, 100);
    }

    let query: BaseQuery = new BaseQuery();
    query.targetprojectionid = BUILDING_PROJECTIONID;
    query.maxitems = 5000;
    query.targetgroup = [];

    let filterId = 0;
    query.filter.type = FilterLogicalGroupType.AND;

    let customerFiltering = new ColumnFilteringString();
    customerFiltering.columnname = 'customerid';
    customerFiltering.comparator = FilterComparator.Equals;
    customerFiltering.value = customerId;
    customerFiltering.queryfilterid = ++filterId;
    query.filter.filters.push(customerFiltering.queryfilterid);
    query.stringfilters.push(customerFiltering);

    let deletedFiltering = new ColumnFilteringNumerical();
    deletedFiltering.columnname = 'deleted';
    deletedFiltering.comparator = FilterComparator.Equals;
    deletedFiltering.value = 0;
    deletedFiltering.queryfilterid = ++filterId;
    query.filter.filters.push(deletedFiltering.queryfilterid);
    query.numericalfilters.push(deletedFiltering);

    this.buildingColumns.forEach(c => {
      let inCol = new BaseQueryInputColumnDescription();
      inCol.columnname = c.columnname;
      inCol.columnoutname = c.columnname;
      query.columns.push(inCol);
    });

    query.sorting.columnname = 'svlant-buildingno';
    query.sorting.descending = false;

    try {
      let queryResult = await this.xprojClient.RequestQueryBaseQuery(query, forceReload);

      let numericaldata = queryResult.datanumbers;
      let timestampdata = queryResult.datatimestamps;
      let stringdata = queryResult.datastrings;

      let rowCount = 0;
      if (numericaldata.length > 0) {
        rowCount = numericaldata[0].length;
      }

      for (let row = 0; row < rowCount; row++) {
        let building = new RossakerBmsBuilding();
        for (let i = 0; i < queryResult.columns.length; i++) {
          let it = queryResult.columns[i];
          let typ = it.datatype;
          let data = [];
          if (typ == XDataType.Number) {
            data = numericaldata[it.indexintypedvector];
          }
          if (typ == XDataType.String) {
            data = stringdata[it.indexintypedvector];
          }
          if (typ == XDataType.Timestamp) {
            data = timestampdata[it.indexintypedvector];
          }

          switch (it.columnoutname) {
            case 'id':
              building.id = data[row];
              break;
            case 'realestateid':
              building.realestateId = data[row];
              break;
            case 'svlant-buildingno':
              building.svLantBuildingNo = data[row];
              break;
            case 'description':
              building.description = data[row];
              break;
            case 'buildingtype':
              building.buildingtype = data[row];
              break;
            case 'latitude':
              building.latitude = data[row];
              break;
            case 'longitude':
              building.longitude = data[row];
              break;
            case 'createdat':
              building.createdAt = data[row];
              break;
            case 'modifiedat':
              building.modifiedAt = data[row];
              break;
          }
        }

        result.push(building);
      }
    }
    catch (err) {
      this.logger.error(err);
    }

    return result;
  }

  async getBuildingAddresses(customerId: string, forceReload: boolean = false): Promise<RossakerBmsBuildingAddress[]> {
    let result: RossakerBmsBuildingAddress[] = [];

    if (this.buildingAddressColumns.length == 0) {
      this.buildingAddressColumns = await this.xprojClient.RequestListQueryableProjectionColumns(BUILDINGADDRESS_PROJECTIONID, '', 0, 100);
    }

    let query: BaseQuery = new BaseQuery();
    query.targetprojectionid = BUILDINGADDRESS_PROJECTIONID;
    query.maxitems = 5000;
    query.targetgroup = [];

    let filterId = 0;
    query.filter.type = FilterLogicalGroupType.AND;

    let customerFiltering = new ColumnFilteringString();
    customerFiltering.columnname = 'customerid';
    customerFiltering.comparator = FilterComparator.Equals;
    customerFiltering.value = customerId;
    customerFiltering.queryfilterid = ++filterId;
    query.filter.filters.push(customerFiltering.queryfilterid);
    query.stringfilters.push(customerFiltering);

    let deletedFiltering = new ColumnFilteringNumerical();
    deletedFiltering.columnname = 'deleted';
    deletedFiltering.comparator = FilterComparator.Equals;
    deletedFiltering.value = 0;
    deletedFiltering.queryfilterid = ++filterId;
    query.filter.filters.push(deletedFiltering.queryfilterid);
    query.numericalfilters.push(deletedFiltering);

    this.buildingAddressColumns.forEach(c => {
      let inCol = new BaseQueryInputColumnDescription();
      inCol.columnname = c.columnname;
      inCol.columnoutname = c.columnname;
      query.columns.push(inCol);
    });

    query.sorting.columnname = 'street';
    query.sorting.descending = false;

    try {
      let queryResult = await this.xprojClient.RequestQueryBaseQuery(query, forceReload);

      result = this.getBuildingAddressesFromQueryResult(queryResult);
    }
    catch (err) {
      this.logger.error(err);
    }

    return result;
  }

  async getBuildingAddressesByBuilding(buildingId: number, forceReload: boolean = false): Promise<RossakerBmsBuildingAddress[]> {
    let result: RossakerBmsBuildingAddress[] = [];

    if (this.buildingAddressColumns.length == 0) {
      this.buildingAddressColumns = await this.xprojClient.RequestListQueryableProjectionColumns(BUILDINGADDRESS_PROJECTIONID, '', 0, 100);
    }

    let query: BaseQuery = new BaseQuery();
    query.targetprojectionid = BUILDINGADDRESS_PROJECTIONID;
    query.maxitems = 5000;
    query.targetgroup = [];

    let filterId = 0;
    query.filter.type = FilterLogicalGroupType.AND;

    let buildingFiltering = new ColumnFilteringNumerical();
    buildingFiltering.columnname = 'buildingid';
    buildingFiltering.comparator = FilterComparator.Equals;
    buildingFiltering.value = buildingId;
    buildingFiltering.queryfilterid = ++filterId;
    query.filter.filters.push(buildingFiltering.queryfilterid);
    query.numericalfilters.push(buildingFiltering);

    let deletedFiltering = new ColumnFilteringNumerical();
    deletedFiltering.columnname = 'deleted';
    deletedFiltering.comparator = FilterComparator.Equals;
    deletedFiltering.value = 0;
    deletedFiltering.queryfilterid = ++filterId;
    query.filter.filters.push(deletedFiltering.queryfilterid);
    query.numericalfilters.push(deletedFiltering);

    this.buildingAddressColumns.forEach(c => {
      let inCol = new BaseQueryInputColumnDescription();
      inCol.columnname = c.columnname;
      inCol.columnoutname = c.columnname;
      query.columns.push(inCol);
    });

    query.sorting.columnname = 'street';
    query.sorting.descending = false;

    try {
      let queryResult = await this.xprojClient.RequestQueryBaseQuery(query, forceReload);

      result = this.getBuildingAddressesFromQueryResult(queryResult);
    }
    catch (err) {
      this.logger.error(err);
    }

    return result;
  }

  private getBuildingAddressesFromQueryResult(queryResult: BaseQueryResult): RossakerBmsBuildingAddress[] {
    let result: RossakerBmsBuildingAddress[] = [];

    let numericaldata = queryResult.datanumbers;
    let timestampdata = queryResult.datatimestamps;
    let stringdata = queryResult.datastrings;

    let rowCount = 0;
    if (numericaldata.length > 0) {
      rowCount = numericaldata[0].length;
    }

    for (let row = 0; row < rowCount; row++) {
      let buildingAddress = new RossakerBmsBuildingAddress();
      for (let i = 0; i < queryResult.columns.length; i++) {
        let it = queryResult.columns[i];
        let typ = it.datatype;
        let data = [];
        if (typ == XDataType.Number) {
          data = numericaldata[it.indexintypedvector];
        }
        if (typ == XDataType.String) {
          data = stringdata[it.indexintypedvector];
        }
        if (typ == XDataType.Timestamp) {
          data = timestampdata[it.indexintypedvector];
        }

        switch (it.columnoutname) {
          case 'id':
            buildingAddress.id = data[row];
            break;
          case 'buildingid':
            buildingAddress.buildingId = data[row];
            break;
          case 'realestateid':
            buildingAddress.realestateId = data[row];
            break;
          case 'description':
            buildingAddress.description = data[row];
            break;
          case 'street':
            buildingAddress.street = data[row];
            break;
          case 'latitude':
            buildingAddress.latitude = data[row];
            break;
          case 'longitude':
            buildingAddress.longitude = data[row];
            break;
          case 'housenumber':
            buildingAddress.housenumber = data[row];
            break;
          case 'postalcode':
            buildingAddress.postalcode = data[row];
            break;
          case 'city':
            buildingAddress.city = data[row];
            break;
          case 'district':
            buildingAddress.district = data[row];
            break;
          case 'country':
            buildingAddress.country = data[row];
            break;
          case 'northof':
            buildingAddress.northOf = data[row];
            break;
          case 'eastof':
            buildingAddress.eastOf = data[row];
            break;
          case 'southof':
            buildingAddress.southOf = data[row];
            break;
          case 'westof':
            buildingAddress.westOf = data[row];
            break;
          case 'createdat':
            buildingAddress.createdAt = data[row];
            break;
          case 'modifiedat':
            buildingAddress.modifiedAt = data[row];
            break;
        }
      }

      result.push(buildingAddress);
    }

    return result;
  }

  async getApartments(customerId: string, forceReload: boolean = false): Promise<RossakerBmsApartment[]> {
    let result: RossakerBmsApartment[] = [];

    if (this.apartmentColumns.length == 0) {
      this.apartmentColumns = await this.xprojClient.RequestListQueryableProjectionColumns(APARTMENT_PROJECTIONID, '', 0, 100);
    }

    let query: BaseQuery = new BaseQuery();
    query.targetprojectionid = APARTMENT_PROJECTIONID;
    query.maxitems = 5000;
    query.targetgroup = [];

    let filterId = 0;
    query.filter.type = FilterLogicalGroupType.AND;

    let customerFiltering = new ColumnFilteringString();
    customerFiltering.columnname = 'customerid';
    customerFiltering.comparator = FilterComparator.Equals;
    customerFiltering.value = customerId;
    customerFiltering.queryfilterid = ++filterId;
    query.filter.filters.push(customerFiltering.queryfilterid);
    query.stringfilters.push(customerFiltering);

    let deletedFiltering = new ColumnFilteringNumerical();
    deletedFiltering.columnname = 'deleted';
    deletedFiltering.comparator = FilterComparator.Equals;
    deletedFiltering.value = 0;
    deletedFiltering.queryfilterid = ++filterId;
    query.filter.filters.push(deletedFiltering.queryfilterid);
    query.numericalfilters.push(deletedFiltering);

    this.apartmentColumns.forEach(c => {
      let inCol = new BaseQueryInputColumnDescription();
      inCol.columnname = c.columnname;
      inCol.columnoutname = c.columnname;
      query.columns.push(inCol);
    });

    query.sorting.columnname = 'svlant-apartmentno';
    query.sorting.descending = false;

    try {
      let queryResult = await this.xprojClient.RequestQueryBaseQuery(query, forceReload);

      let numericaldata = queryResult.datanumbers;
      let timestampdata = queryResult.datatimestamps;
      let stringdata = queryResult.datastrings;

      let rowCount = 0;
      if (numericaldata.length > 0) {
        rowCount = numericaldata[0].length;
      }

      for (let row = 0; row < rowCount; row++) {
        let apartment = new RossakerBmsApartment();
        for (let i = 0; i < queryResult.columns.length; i++) {
          let it = queryResult.columns[i];
          let typ = it.datatype;
          let data = [];
          if (typ == XDataType.Number) {
            data = numericaldata[it.indexintypedvector];
          }
          if (typ == XDataType.String) {
            data = stringdata[it.indexintypedvector];
          }
          if (typ == XDataType.Timestamp) {
            data = timestampdata[it.indexintypedvector];
          }

          switch (it.columnoutname) {
            case 'id':
              apartment.id = data[row];
              break;
            case 'buildingid':
              apartment.buildingId = data[row];
              break;
            case 'realestateid':
              apartment.realestateId = data[row];
              break;
            case 'buildingaddressid':
              apartment.buildingAddressId = data[row];
              break;
            case 'externalid':
              apartment.externalId = data[row];
              break;
            case 'prefix':
              apartment.prefix = data[row];
              break;
            case 'svlant-apartmentno':
              apartment.svlantApartmentno = data[row];
              break;
            case 'area':
              apartment.area = data[row];
              break;
            case 'size':
              apartment.size = data[row];
              break;
            case 'createdat':
              apartment.createdAt = data[row];
              break;
            case 'modifiedat':
              apartment.modifiedAt = data[row];
              break;
          }
        }

        result.push(apartment);
      }
    }
    catch (err) {
      this.logger.error(err);
    }

    return result;
  }

  async getFacilities(customerId: string, forceReload: boolean = false): Promise<RossakerBmsFacility[]> {
    let result: RossakerBmsFacility[] = [];

    if (this.facilityColumns.length == 0) {
      this.facilityColumns = await this.xprojClient.RequestListQueryableProjectionColumns(FACILITY_PROJECTIONID, '', 0, 100);
    }

    let query: BaseQuery = new BaseQuery();
    query.targetprojectionid = FACILITY_PROJECTIONID;
    query.maxitems = 5000;
    query.targetgroup = [];

    let filterId = 0;
    query.filter.type = FilterLogicalGroupType.AND;

    let customerFiltering = new ColumnFilteringString();
    customerFiltering.columnname = 'customerid';
    customerFiltering.comparator = FilterComparator.Equals;
    customerFiltering.value = customerId;
    customerFiltering.queryfilterid = ++filterId;
    query.filter.filters.push(customerFiltering.queryfilterid);
    query.stringfilters.push(customerFiltering);

    let deletedFiltering = new ColumnFilteringNumerical();
    deletedFiltering.columnname = 'deleted';
    deletedFiltering.comparator = FilterComparator.Equals;
    deletedFiltering.value = 0;
    deletedFiltering.queryfilterid = ++filterId;
    query.filter.filters.push(deletedFiltering.queryfilterid);
    query.numericalfilters.push(deletedFiltering);

    this.facilityColumns.forEach(c => {
      let inCol = new BaseQueryInputColumnDescription();
      inCol.columnname = c.columnname;
      inCol.columnoutname = c.columnname;
      query.columns.push(inCol);
    });

    query.sorting.columnname = 'externalid';
    query.sorting.descending = false;

    try {
      let queryResult = await this.xprojClient.RequestQueryBaseQuery(query, forceReload);

      let numericaldata = queryResult.datanumbers;
      let timestampdata = queryResult.datatimestamps;
      let stringdata = queryResult.datastrings;

      let rowCount = 0;
      if (numericaldata.length > 0) {
        rowCount = numericaldata[0].length;
      }

      for (let row = 0; row < rowCount; row++) {
        let facility = new RossakerBmsFacility();
        for (let i = 0; i < queryResult.columns.length; i++) {
          let it = queryResult.columns[i];
          let typ = it.datatype;
          let data = [];
          if (typ == XDataType.Number) {
            data = numericaldata[it.indexintypedvector];
          }
          if (typ == XDataType.String) {
            data = stringdata[it.indexintypedvector];
          }
          if (typ == XDataType.Timestamp) {
            data = timestampdata[it.indexintypedvector];
          }

          switch (it.columnoutname) {
            case 'id':
              facility.id = data[row];
              break;
            case 'buildingid':
              facility.buildingId = data[row];
              break;
            case 'realestateid':
              facility.realestateId = data[row];
              break;
            case 'buildingaddressid':
              facility.buildingAddressId = data[row];
              break;
            case 'externalid':
              facility.externalId = data[row];
              break;
            case 'prefix':
              facility.prefix = data[row];
              break;
            case 'facilitytype':
              facility.facilityType = data[row];
              break;
            case 'area':
              facility.area = data[row];
              break;
            case 'size':
              facility.size = data[row];
              break;
            case 'floor':
              facility.floor = data[row];
              break;
            case 'createdat':
              facility.createdAt = data[row];
              break;
            case 'modifiedat':
              facility.modifiedAt = data[row];
              break;
          }
        }

        result.push(facility);
      }
    }
    catch (err) {
      this.logger.error(err);
    }

    return result;
  }

  async getGateways(customerId: string): Promise<RossakerBmsGateway[]> {
    let result: RossakerBmsGateway[] = [];

    try {
      let gatewayNodes = await this.xconfClient.getReferencedNodes('_x_bms_datacollectors_root_' + customerId, '_x_bms_datacollectors_root', [], '_x_datacollectors_collector', 5);
      gatewayNodes.forEach(gatewayNode => {
        let gateway = new RossakerBmsGateway();
        gatewayNode.propertyValues.forEach(p => {
          switch (p.key) {
            case 'id':
              gateway.id = +RossakerBmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
              break;
            case 'endpointurl':
              gateway.endpointURL = RossakerBmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
              break;
            case 'serialnumber':
              gateway.serialnumber = RossakerBmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
              break;
            case 'Vendor':
              gateway.vendor = RossakerBmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
              break;
            case 'model':
              gateway.model = RossakerBmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
              break;
            case 'placing':
              gateway.placing = RossakerBmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
              break;
          }
        });

        result.push(gateway);
      });
    }
    catch (err) {
      this.logger.error(err);
    }

    return result;
  }

  async getMeterTypes(): Promise<string[]> {
    let result: string[] = [];

    try {
      let meterTypeNodes = await this.xconfClient.getReferencedNodes('_x_bms_metertypes_root', '_x_bms_metertypes_root', [], '_x_bms_metertypes_type', 1);
      meterTypeNodes.forEach(meterTypeNode => {
        result.push(meterTypeNode.name);
      });
    }
    catch (err) {
      this.logger.error(err);
    }

    return result;
  }

  async getMeters(customerId: string, realestateId: number, forceReload: boolean = false): Promise<RossakerBmsMeter[]> {
    let result: RossakerBmsMeter[] = [];

    if (this.meterColumns.length == 0) {
      this.meterColumns = await this.xprojClient.RequestListQueryableProjectionColumns(METER_PROJECTIONID, '', 0, 100);
    }

    let query: BaseQuery = new BaseQuery();
    query.targetprojectionid = METER_PROJECTIONID;
    query.maxitems = 5000;
    query.targetgroup = [];

    let filterId = 0;
    query.filter.type = FilterLogicalGroupType.AND;

    let customerFiltering = new ColumnFilteringString();
    customerFiltering.columnname = 'customerid';
    customerFiltering.comparator = FilterComparator.Equals;
    customerFiltering.value = customerId;
    customerFiltering.queryfilterid = ++filterId;
    query.filter.filters.push(customerFiltering.queryfilterid);
    query.stringfilters.push(customerFiltering);

    let realestateFiltering = new ColumnFilteringNumerical();
    realestateFiltering.columnname = 'realestateid';
    realestateFiltering.comparator = FilterComparator.Equals;
    realestateFiltering.value = realestateId;
    realestateFiltering.queryfilterid = ++filterId;
    query.filter.filters.push(realestateFiltering.queryfilterid);
    query.numericalfilters.push(realestateFiltering);

    let deletedFiltering = new ColumnFilteringNumerical();
    deletedFiltering.columnname = 'deleted';
    deletedFiltering.comparator = FilterComparator.Equals;
    deletedFiltering.value = 0;
    deletedFiltering.queryfilterid = ++filterId;
    query.filter.filters.push(deletedFiltering.queryfilterid);
    query.numericalfilters.push(deletedFiltering);

    this.meterColumns.forEach(c => {
      let inCol = new BaseQueryInputColumnDescription();
      inCol.columnname = c.columnname;
      inCol.columnoutname = c.columnname;
      query.columns.push(inCol);
    });

    query.sorting.columnname = 'subaddress1';
    query.sorting.descending = false;

    try {
      let queryResult = await this.xprojClient.RequestQueryBaseQuery(query, forceReload);

      let rowCount = 0;
      if (queryResult.datanumbers.length > 0) {
        rowCount = queryResult.datanumbers[0].length;
      }

      for (let row = 0; row < rowCount; row++) {
        let meter = this._getMeter(queryResult, row);
        result.push(meter);
      }
    }
    catch (err) {
      this.logger.error(err);
    }

    return result;
  }

  async getMeter(meterId: number): Promise<RossakerBmsMeter> {
    let result: RossakerBmsMeter;
    if (this.meterColumns.length == 0) {
      this.meterColumns = await this.xprojClient.RequestListQueryableProjectionColumns(METER_PROJECTIONID, '', 0, 100);
    }

    let query: BaseQuery = new BaseQuery();
    query.targetprojectionid = METER_PROJECTIONID;
    query.maxitems = 1;
    query.targetgroup = [];

    let filterId = 0;
    query.filter.type = FilterLogicalGroupType.AND;

    let idFiltering = new ColumnFilteringNumerical();
    idFiltering.columnname = 'id';
    idFiltering.comparator = FilterComparator.Equals;
    idFiltering.value = meterId;
    idFiltering.queryfilterid = ++filterId;
    query.filter.filters.push(idFiltering.queryfilterid);
    query.numericalfilters.push(idFiltering);

    let deletedFiltering = new ColumnFilteringNumerical();
    deletedFiltering.columnname = 'deleted';
    deletedFiltering.comparator = FilterComparator.Equals;
    deletedFiltering.value = 0;
    deletedFiltering.queryfilterid = ++filterId;
    query.filter.filters.push(deletedFiltering.queryfilterid);
    query.numericalfilters.push(deletedFiltering);

    this.meterColumns.forEach(c => {
      let inCol = new BaseQueryInputColumnDescription();
      inCol.columnname = c.columnname;
      inCol.columnoutname = c.columnname;
      query.columns.push(inCol);
    });

    query.sorting.columnname = 'id';
    query.sorting.descending = false;

    try {
      let queryResult = await this.xprojClient.RequestQueryBaseQuery(query, true);

      let rowCount = 0;
      if (queryResult.datanumbers.length > 0) {
        result = this._getMeter(queryResult, 0);
      }
    }
    catch (err) {
      this.logger.error(err);
    }

    return result;
  }

  async getMeterByIdentifier(identifier: string): Promise<RossakerBmsMeter> {
    let result: RossakerBmsMeter;
    if (this.meterColumns.length == 0) {
      this.meterColumns = await this.xprojClient.RequestListQueryableProjectionColumns(METER_PROJECTIONID, '', 0, 100);
    }

    let query: BaseQuery = new BaseQuery();
    query.targetprojectionid = METER_PROJECTIONID;
    query.maxitems = 1;
    query.targetgroup = [];

    let filterId = 0;
    query.filter.type = FilterLogicalGroupType.AND;

    let subAddress1Filtering = new ColumnFilteringString();
    subAddress1Filtering.columnname = 'subaddress1';
    subAddress1Filtering.comparator = FilterComparator.Equals;
    subAddress1Filtering.value = identifier;
    subAddress1Filtering.queryfilterid = ++filterId;
    query.filter.filters.push(subAddress1Filtering.queryfilterid);
    query.stringfilters.push(subAddress1Filtering);

    let deletedFiltering = new ColumnFilteringNumerical();
    deletedFiltering.columnname = 'deleted';
    deletedFiltering.comparator = FilterComparator.Equals;
    deletedFiltering.value = 0;
    deletedFiltering.queryfilterid = ++filterId;
    query.filter.filters.push(deletedFiltering.queryfilterid);
    query.numericalfilters.push(deletedFiltering);

    this.meterColumns.forEach(c => {
      let inCol = new BaseQueryInputColumnDescription();
      inCol.columnname = c.columnname;
      inCol.columnoutname = c.columnname;
      query.columns.push(inCol);
    });

    query.sorting.columnname = 'subaddress1';
    query.sorting.descending = false;

    try {
      let queryResult = await this.xprojClient.RequestQueryBaseQuery(query, true);

      let rowCount = 0;
      if (queryResult.datanumbers.length > 0) {
        result = this._getMeter(queryResult, 0);
      }
    }
    catch (err) {
      this.logger.error(err);
    }

    return result;
  }

  _getMeter(queryResult: BaseQueryResult, row: number): RossakerBmsMeter {
    let meter = new RossakerBmsMeter();

    for (let i = 0; i < queryResult.columns.length; i++) {
      let it = queryResult.columns[i];
      let typ = it.datatype;
      let data = [];
      if (typ == XDataType.Number) {
        data = queryResult.datanumbers[it.indexintypedvector];
      }
      if (typ == XDataType.String) {
        data = queryResult.datastrings[it.indexintypedvector];
      }
      if (typ == XDataType.Timestamp) {
        data = queryResult.datatimestamps[it.indexintypedvector];
      }

      switch (it.columnoutname) {
        case 'id':
          meter.id = data[row];
          break;
        case 'customerid':
          meter.customerId = data[row];
          break;
        case 'buildingid':
          meter.buildingId = data[row];
          break;
        case 'realestateid':
          meter.realestateId = data[row];
          break;
        case 'buildingaddressid':
          meter.buildingAddressId = data[row];
          break;
        case 'apartmentid':
          meter.apartmentId = data[row];
          break;
        case 'facilityid':
          meter.facilityId = data[row];
          break;
        case 'subaddress1':
          meter.identifier = data[row];
          break;
        case 'subaddress2':
          meter.manufacturer = data[row];
          break;
        case 'subaddress3':
          meter.variable = data[row];
          break;
        case 'subaddress4':
          meter.index = +data[row];
          break;
        case 'unit':
          meter.unit = data[row];
          break;
        case 'datapointvalueunit':
          meter.datapointValueUnit = data[row];
          break;
        case 'state':
          meter.state = data[row];
          break;
        case 'model':
          meter.model = data[row];
          break;
        case 'metertype':
          meter.meterType = data[row];
          break;
        case 'metersubtype':
          meter.meterSubtype = data[row];
          break;
        case 'typeid':
          meter.typeId = data[row];
          break;
        case 'coeffiecent':
          meter.coeffiecent = data[row];
          break;
        case 'createdat':
          meter.createdAt = data[row];
          break;
        case 'modifiedat':
          meter.modifiedAt = data[row];
          break;
        case 'tag':
          meter.tag = data[row];
          break;
      }
    }

    return meter;
  }

  async exportToExcel(meterDatas: RossakerBmsMeterData[], filename: string) {
    var headers: string[] = ['svlantPropertyDesignation', 'externalId', 'svlantApartmentno', 'facilityType', 'area', 'size',
      'svLantBuildingNo', 'street', 'housenumber', 'gw_serialnumber', 'gw_vendor', 'seconadaryAddress', 'manufacturer', 'unit',
      'datapointValueUnit', 'variable', 'meterType', 'index', 'state'];
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(meterDatas, { header: headers });

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    XLSX.writeFile(wb, filename);
  }

  async getInvoiceInfos(customerId: string): Promise<RossakerBmsInvoiceInfo[]> {
    let result: RossakerBmsInvoiceInfo[] = [];

    let query: BaseQuery = new BaseQuery();
    query.targetprojectionid = INVOICEINFO_PROJECTIONID;
    query.maxitems = 5000;
    query.targetgroup = [];

    let filterId = 0;
    query.filter.type = FilterLogicalGroupType.AND;

    let customerIdFiltering = new ColumnFilteringString();
    customerIdFiltering.columnname = 'customerid';
    customerIdFiltering.comparator = FilterComparator.Equals;
    customerIdFiltering.value = customerId;
    customerIdFiltering.queryfilterid = ++filterId;
    query.filter.filters.push(customerIdFiltering.queryfilterid);
    query.stringfilters.push(customerIdFiltering);


    let deletedFiltering = new ColumnFilteringNumerical();
    deletedFiltering.columnname = 'deleted';
    deletedFiltering.comparator = FilterComparator.Equals;
    deletedFiltering.value = 0;
    deletedFiltering.queryfilterid = ++filterId;
    query.filter.filters.push(deletedFiltering.queryfilterid);
    query.numericalfilters.push(deletedFiltering);

    if (this.invoiceInfoColumns.length == 0) {
      this.invoiceInfoColumns = await this.xprojClient.RequestListQueryableProjectionColumns(INVOICEINFO_PROJECTIONID, '', 0, 100);
    }

    this.invoiceInfoColumns.forEach(c => {
      let inCol = new BaseQueryInputColumnDescription();
      inCol.columnname = c.columnname;
      inCol.columnoutname = c.columnname;
      query.columns.push(inCol);
    });

    query.sorting.columnname = 'activefrom';
    query.sorting.descending = true;

    try {
      let queryResult = await this.xprojClient.RequestQueryBaseQuery(query, true);

      let numericaldata = queryResult.datanumbers;
      let timestampdata = queryResult.datatimestamps;
      let stringdata = queryResult.datastrings;

      let rowCount = 0;
      if (numericaldata.length > 0) {
        rowCount = numericaldata[0].length;
      }

      for (let row = 0; row < rowCount; row++) {
        let invoiceInfo = new RossakerBmsInvoiceInfo();
        for (let i = 0; i < queryResult.columns.length; i++) {
          let it = queryResult.columns[i];
          let typ = it.datatype;
          let data = [];
          if (typ == XDataType.Number) {
            data = numericaldata[it.indexintypedvector];
          }
          if (typ == XDataType.String) {
            data = stringdata[it.indexintypedvector];
          }
          if (typ == XDataType.Timestamp) {
            data = timestampdata[it.indexintypedvector];
          }

          switch (it.columnoutname) {
            case 'id':
              invoiceInfo.id = data[row];
              break;
            case 'customerid':
              invoiceInfo.customerId = data[row];
              break;
            case 'activefrom':
              invoiceInfo.activeFrom = data[row];
              break;
            case 'metertype':
              invoiceInfo.meterType = data[row];
              break;
            case 'createdat':
              invoiceInfo.createdAt = data[row];
              break;
            case 'modifiedat':
              invoiceInfo.modifiedAt = data[row];
              break;
            case 'deletedat':
              invoiceInfo.deletedAt = data[row];
              break;
            case 'deleted':
              invoiceInfo.deleted = data[row];
              break;
          }
        }

        result.push(invoiceInfo);
      }
    }
    catch (err) {
      this.logger.error(err);
    }

    return result;
  }

  async setDataInvoiceInfos(invoiceInfos: RossakerBmsInvoiceInfo[]): Promise<boolean> {
    let query: SetDataQuery = new SetDataQuery();

    let now = new Date();
    await ArrayUtils.AsyncForEach(invoiceInfos, async (invoiceInfo: RossakerBmsInvoiceInfo) => {
      if (!invoiceInfo.id || invoiceInfo.id == 0) {
        invoiceInfo.id = await flakeId.nextId();
        invoiceInfo.createdAt = now;
      }
      invoiceInfo.modifiedAt = now;
    });

    query.datastrings = [
      invoiceInfos.map(t => t.customerId),
      invoiceInfos.map(t => t.meterType),
    ];
    query.datanumbers = [
      invoiceInfos.map(t => t.id),
      invoiceInfos.map(t => t.deleted ? 1 : 0),
    ];
    query.datatimestamps = [
      invoiceInfos.map(t => t.activeFrom ?? new Date()),
      invoiceInfos.map(t => t.createdAt ?? new Date()),
      invoiceInfos.map(t => t.modifiedAt ?? new Date()),
      invoiceInfos.map(t => t.deletedAt ?? new Date(0))
    ];

    query.projectionid = INVOICEINFO_PROJECTIONID;
    query.columns = this.setDataInvoiceInfoColumns;

    return await this.xprojClient.RequestSetData(query);
  }

  async getLorawanMultiMeters(lorawanGroupId: string): Promise<RossakerBmsLorawanMultiMeter[]> {
    let result: RossakerBmsLorawanMultiMeter[] = [];

    try {
      let request = new SearchNodesRequest();
      request.rootId = lorawanGroupId;
      request.rootLabel = '_x_lorawan_group';
      request.limit = 1000;
      request.maxHops = 10;
      request.skip = 0;
      request.label = '_x_lorawan_multimeter';

      let searchResult = await this.xconfClient.searchNodes(request);

      searchResult.nodes.forEach(node => {
        let lorawanMultiMeter = this.getLorawanMultiMeter(node);
        result.push(lorawanMultiMeter);
      });
    }
    catch (err) {
      this.logger.error(err);
    }

    return result;
  }

  getLorawanMultiMeter(node: GrpcNode): RossakerBmsLorawanMultiMeter {
    let lorawanMultiMeter = new RossakerBmsLorawanMultiMeter();
    lorawanMultiMeter.id = node.id;
    lorawanMultiMeter.name = node.name;
    node.propertyValues.forEach(p => {
      switch (p.key) {
        case 'loramultimetertype':
          lorawanMultiMeter.multiMeterType = RossakerBmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
          break;
        case 'appkey':
          lorawanMultiMeter.appKey = RossakerBmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
          break;
        case 'deveui':
          lorawanMultiMeter.devEui = RossakerBmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
          break;
        case 'externalid':
          lorawanMultiMeter.externalId = RossakerBmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
          break;
        case 'provisioned':
          lorawanMultiMeter.provisioned = RossakerBmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
          break;
        case 'createdat':
          lorawanMultiMeter.createdAt = RossakerBmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
          break;
        case 'modifiedat':
          lorawanMultiMeter.modifiedAt = RossakerBmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
          break;
        case 'loradevicetype':
          lorawanMultiMeter.deviceType = RossakerBmsDataUtils.getValue(p.valueType, p.value, p, this.dateHelper);
          break;
      }
    });

    return lorawanMultiMeter;
  }

  async getLoraWANDeviceInfos(deveuis: string[]): Promise<RossakerLorawanDeviceInfo[]> {

    let result: RossakerLorawanDeviceInfo[] = [];
    let query: BaseQuery = new BaseQuery();
    query.targetprojectionid = LORAWANDEVICEINFO_PROJECTIONID;
    query.maxitems = deveuis.length;
    query.targetgroup = [];

    let filterId = 0;
    query.filter.type = FilterLogicalGroupType.AND;

    let deveuiGroup = new FilterLogicalGroup();
    deveuiGroup.type = FilterLogicalGroupType.OR;
    deveuiGroup.queryfilterid = filterId++;
    for (let deveui of deveuis) {
      let newFilter = new ColumnFilteringString();
      newFilter.queryfilterid = filterId++;
      newFilter.columnname = 'deveui';
      newFilter.comparator = FilterComparator.Equals;
      newFilter.value = deveui;
      deveuiGroup.filters.push(newFilter.queryfilterid);
      query.stringfilters.push(newFilter);
    }
    query.filter.filters.push(deveuiGroup.queryfilterid);
    query.subfiltergroups.push(deveuiGroup);

    let deletedFiltering = new ColumnFilteringNumerical();
    deletedFiltering.columnname = 'deleted';
    deletedFiltering.comparator = FilterComparator.Equals;
    deletedFiltering.value = 0;
    deletedFiltering.queryfilterid = ++filterId;
    query.filter.filters.push(deletedFiltering.queryfilterid);
    query.numericalfilters.push(deletedFiltering);

    if (this.loraWanDeviceInfoColumns.length == 0) {
      this.loraWanDeviceInfoColumns = await this.xprojClient.RequestListQueryableProjectionColumns(LORAWANDEVICEINFO_PROJECTIONID, '', 0, 100);
    }

    this.loraWanDeviceInfoColumns.forEach(c => {
      let inCol = new BaseQueryInputColumnDescription();
      inCol.columnname = c.columnname;
      inCol.columnoutname = c.columnname;
      query.columns.push(inCol);
    });

    try {
      let queryResult = await this.xprojClient.RequestQueryBaseQuery(query, true);

      let numericaldata = queryResult.datanumbers;
      let timestampdata = queryResult.datatimestamps;
      let stringdata = queryResult.datastrings;

      let rowCount = 0;
      if (numericaldata.length > 0) {
        rowCount = numericaldata[0].length;
      }

      for (let row = 0; row < rowCount; row++) {
        let deviceInfo = new RossakerLorawanDeviceInfo();
        for (let i = 0; i < queryResult.columns.length; i++) {
          let it = queryResult.columns[i];
          let typ = it.datatype;
          let data = [];
          if (typ == XDataType.Number) {
            data = numericaldata[it.indexintypedvector];
          }
          if (typ == XDataType.String) {
            data = stringdata[it.indexintypedvector];
          }
          if (typ == XDataType.Timestamp) {
            data = timestampdata[it.indexintypedvector];
          }

          switch (it.columnoutname) {
            case 'id':
              deviceInfo.id = data[row];
              break;
            case 'batterylevel':
              deviceInfo.batteryLevel = data[row];
              break;
            case 'deveui':
              deviceInfo.deveui = data[row];
              break;
            case 'frequency':
              deviceInfo.frequency = data[row];
              break;
            case 'gatewaycount':
              deviceInfo.gatewayCount = data[row];
              break;
            case 'modifiedat':
              deviceInfo.modifiedAt = data[row];
              break;
            case 'gatewayidentifier':
              deviceInfo.gatewayIdentifier = data[row];
              break;
            case 'gatewayidentifiers':
              deviceInfo.gatewayIdentifiers = data[row];
              break;
            case 'latitude':
              deviceInfo.latitude = data[row];
              break;
            case 'longitude':
              deviceInfo.longitude = data[row];
              break;
            case 'rssi':
              deviceInfo.rssi = data[row];
              break;
            case 'snr':
              deviceInfo.snr = data[row];
              break;
            case 'spreadingfactor':
              deviceInfo.spreadingfactor = data[row];
              break;
          }
        }

        result.push(deviceInfo);
      }
    }
    catch (err) {
      this.logger.error(err);
    }

    return result;
  }

  async getLatestValues(customerId: string, forceReload: boolean = false): Promise<RossakerBmsLatestValue[]> {
    let result: RossakerBmsLatestValue[] = [];

    if (this.latestValuesColumns.length == 0) {
      this.latestValuesColumns = await this.xprojClient.RequestListQueryableProjectionColumns(DATAPOINTLATEST_PROJECTIONID, '', 0, 100);
    }

    let query: BaseQuery = new BaseQuery();
    query.targetprojectionid = DATAPOINTLATEST_PROJECTIONID;
    query.maxitems = 5000;
    query.targetgroup = [];

    let filterId = 0;
    query.filter.type = FilterLogicalGroupType.AND;

    let customerFiltering = new ColumnFilteringString();
    customerFiltering.columnname = 'customerid';
    customerFiltering.comparator = FilterComparator.Equals;
    customerFiltering.value = customerId;
    customerFiltering.queryfilterid = ++filterId;
    query.filter.filters.push(customerFiltering.queryfilterid);
    query.stringfilters.push(customerFiltering);

    let deletedFiltering = new ColumnFilteringNumerical();
    deletedFiltering.columnname = 'deleted';
    deletedFiltering.comparator = FilterComparator.Equals;
    deletedFiltering.value = 0;
    deletedFiltering.queryfilterid = ++filterId;
    query.filter.filters.push(deletedFiltering.queryfilterid);
    query.numericalfilters.push(deletedFiltering);

    this.latestValuesColumns.forEach(c => {
      let inCol = new BaseQueryInputColumnDescription();
      inCol.columnname = c.columnname;
      inCol.columnoutname = c.columnname;
      query.columns.push(inCol);
    });

    query.sorting.columnname = 'timestamp';
    query.sorting.descending = true;

    try {
      let queryResult = await this.xprojClient.RequestQueryBaseQuery(query, forceReload);

      let numericaldata = queryResult.datanumbers;
      let timestampdata = queryResult.datatimestamps;
      let stringdata = queryResult.datastrings;

      let rowCount = 0;
      if (numericaldata.length > 0) {
        rowCount = numericaldata[0].length;
      }

      for (let row = 0; row < rowCount; row++) {
        let latestValue = new RossakerBmsLatestValue();
        for (let i = 0; i < queryResult.columns.length; i++) {
          let it = queryResult.columns[i];
          let typ = it.datatype;
          let data = [];
          if (typ == XDataType.Number) {
            data = numericaldata[it.indexintypedvector];
          }
          if (typ == XDataType.String) {
            data = stringdata[it.indexintypedvector];
          }
          if (typ == XDataType.Timestamp) {
            data = timestampdata[it.indexintypedvector];
          }

          switch (it.columnoutname) {
            case 'meterid':
              latestValue.meterId = data[row];
              break;
            case 'buildingid':
              latestValue.buildingId = data[row];
              break;
            case 'realestateid':
              latestValue.realestateId = data[row];
              break;
            case 'buildingaddressid':
              latestValue.buildingAddressId = data[row];
              break;
            case 'customerid':
              latestValue.customerId = data[row];
              break;
            case 'facilityid':
              latestValue.facilityId = data[row];
              break;
            case 'gatewayid':
              latestValue.gatewayId = data[row];
              break;
            case 'metersubtype':
              latestValue.meterSubtype = data[row];
              break;
            case 'metertype':
              latestValue.meterType = data[row];
              break;
            case 'modifiedat':
              latestValue.modifiedAt = data[row];
              break;
            case 'timestamp':
              latestValue.timestamp = data[row];
              break;
            case 'unit':
              latestValue.unit = data[row];
              break;
            case 'value':
              latestValue.value = data[row];
              break;

          }
        }

        result.push(latestValue);
      }
    }
    catch (err) {
      this.logger.error(err);
    }

    return result;
  }
}
