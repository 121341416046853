<div class="clr-row">
    <div class="clr-col-8">
      <h3 *ngIf="selectedBmsCustomer" class="sensitive">{{selectedBmsCustomer.customerName}}
      </h3>
    </div>
    <div class="clr-col-4">
      <div class="searchbar-container">
        <div class="icons-search-wrapper">
          <label class="searchbar-label">
            <clr-icon shape="search" size="24"></clr-icon>
            <input type="text" class="searchbar-input" placeholder="Search..." (keyup.enter)="search()"
              [(ngModel)]="searchValue">
          </label>
        </div>
      </div>
    </div>
  </div>
  
  <clr-tabs #tabs>
    <clr-tab>
      <button clrTabLink (click)="bmsActive = true;">ISA 95</button>
      <div class="tab-area" *ngIf="bmsActive">
        <clr-tab-content>
          <div class="customers-content-area">
            <app-configuration-datasource #bmsConfigurationDatasource
              [sensitiveNodes]="['_x_bms_realestate', '_x_bms_building', '_x_bms_buildingaddress']"
              *ngIf="bmsDataSourceInstance" [lazy]="true" [dataSourceInstance]="bmsDataSourceInstance" [expandRoot]="true"
              [customerId]="selectedBmsCustomer.customerId" [sortByName]="true" [dropDownItems]="dropDownItems"
              [sortPaddingZeros]="10" [highlightSelected]="true" (onTreeNodeSelect)="onTreeNodeSelect($event)"
              [moveEnabled]="true" [searchParameterIds]="[{typeName : 'string', key : 'seconadaryaddress'}]"
              [moveEnabledNodeTypeIds]="[]" [singletonReferences]="true">
            </app-configuration-datasource>
          </div>
        </clr-tab-content>
      </div>
    </clr-tab>
    <clr-tab>
      <button clrTabLink (click)="bmsActive = true;">Import</button>
      <div class="tab-area" *ngIf="bmsActive">
        <clr-tab-content>
          <div class="customers-content-area">
            <div class="card">
              <div class="card-header">
                Data import
              </div>
              <div class="card-block">
                <div class="card-text">
                  <form clrForm clrLayout="vertical" autocomplete="off">
                    <clr-input-container>
                      <label class="clr-col-12">Import data file, select or drop file.</label>
                      <input clrInput class="clr-col-12" name="importdatafile_file" #datafile type="file"
                        (change)="uploadDataFile(datafile)" />
                    </clr-input-container>
                  </form>
  
                  <button type="button" class="btn btn-outline" [clrLoading]="importingState"
                    [disabled]="!importDatafile" (click)="importDataFiles()">
                    <clr-icon shape="import"></clr-icon>
                    Import data file
                  </button>
                </div>
              </div>
            </div>
          </div>
        </clr-tab-content>
      </div>
    </clr-tab>
  </clr-tabs>
  