import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserRights } from '@xprojectorcore/models/user-rights';
import { StateService } from '@xprojectorcore/services/state-service';
import { CustomerUsersClient } from '@xprojectorcore/xprojector_backend/proto/xprojector.customerusers.pbsc';
import { Customer, CustomerUser, User } from '@xprojectorcore/xprojector_backend/proto/xprojector.grpc.models.pb';
import { XProjectorSysAdminClient } from '@xprojectorcore/xprojector_backend/xprojector-sysadmin-client';
import { XProjectorXConfClient } from '@xprojectorcore/xprojector_backend/xprojector-xconf-client';
import { ClrForm } from '@clr/angular';
import { map } from 'rxjs/operators';
import { XprojAlertService, XprojModalService } from 'xproj-lib';

export class CustomerUserWithCustomer {
  customerUser: CustomerUser;
  customerName: string;
}

@Component({
  selector: 'app-sys-admin-user-profiles',
  templateUrl: './sys-admin-user-profiles.component.html',
  styleUrls: ['./sys-admin-user-profiles.component.scss']
})
export class SysAdminUserProfilesComponent implements OnInit {

  @ViewChild(ClrForm, { static: true }) clrForm: ClrForm;

  @Input() customerId: string;
  @Input() searchForm: boolean = false;
  @Input() header: string = "Admin Users";

  @Output() onUserSelected: EventEmitter<CustomerUser> = new EventEmitter();

  users: User[];
  showRemoveUser: boolean = false;
  selectedUser: User;
  currentUserRights: UserRights;
  newUser: User = new User();

  customerUsers: CustomerUser[] = [];
  customers: Customer[] = [];
  customerUserWithCustomers: CustomerUserWithCustomer[] = [];
  showRemoveCustomerUser: boolean = false;
  selectedCustomerUser: CustomerUser;
  currentCustomerUserRights: UserRights;
  newCustomerUser: CustomerUser = new CustomerUser();
  newCustomerUserPassword: string = '';
  newCustomerUserSetPassword: boolean = false;

  showAddUserModal: boolean = false;

  searchCustomerUser: CustomerUser = new CustomerUser();

  constructor(
    private state: StateService,
    private route: ActivatedRoute,
    private xconfClient: XProjectorXConfClient,
    private sysAdminClient: XProjectorSysAdminClient,
    private modalService: XprojModalService,
    private router: Router,
    private alertService: XprojAlertService) { }


  async ngOnInit() {
    this.route.params.pipe(map(p => p.searchform)).subscribe(async (searchform) => {
      if (searchform == 'true') {
        this.searchForm = true;
      }
    });

    this.currentUserRights = this.state.userRightsValue;
    if (this.customerId) {
      this.getCustomerUsers();
    }
    else {
      this.getUsers();
    }

    this.clearSearchUsersForm();
  }

  async getUsers() {
    this.users = await this.sysAdminClient.getUsers();
  }

  async removeUser(user: User) {
    if (user) {
      this.selectedUser = user;
      this.showRemoveUser = true;
    }
  }

  async doRemoveSelectedUser() {
    if (this.selectedUser) {
        let result = await this.sysAdminClient.deleteUser(this.selectedUser.id);
        if (result.result) {
          this.users = this.users.filter(c => c != this.selectedUser);
          this.selectedUser = null;
          this.alertService.success('User deleted.');
        } else {
          this.alertService.error(result.message);
        }
    }
  }

  async addUser() {
    this.showAddUserModal = false;
    try {
      if (this.isNewUserValid()) {
        this.newUser.id = this.newUser.email;
        let user = await this.sysAdminClient.addNewUser(this.newUser);
        if (user) {
          this.alertService.success('New user added.');
          this.users.push(user);
          this.clear();
        }
        else {
          this.alertService.error('Error add new user!');
        }
      }
      else {
        this.clrForm.markAsTouched();
      }
    }
    catch (err) {
      this.alertService.error('Error add new user: ' + err);
    }
  }

  private isNewUserValid(): boolean {
    return this.newUser && this.newUser.firstname.length > 0 && this.newUser.surname.length > 0 && this.newUser.email.length > 0;
  }

  clear() {
    this.newUser = new User();
  }

  async deleteApiKey(user: CustomerUser) {
    user.apiKey = '';
  }

  async generateNewApiKey(user: CustomerUser) {
    user.apiKey = await this.sysAdminClient.generateApiKey();
  }

  async getCustomerUsers() {
    this.customerUsers = await this.sysAdminClient.getCustomerUsers(this.customerId, true);
  }

  async removeCustomerUser(user: CustomerUser) {
    if (user) {
      this.selectedCustomerUser = user;
      this.showRemoveCustomerUser = true;
    }
  }

  async doRemoveSelectedCustomerUser() {
    if (this.selectedCustomerUser) {
      //check if tenant user?
      let refs = await this.xconfClient.getReferencedNodes(this.selectedCustomerUser.id, '_x_customerusers_user', [], '', 1);
      if (refs.length >= 2) {
        //Has refs other than creds
        this.selectedCustomerUser.isAdmin = false;
        let result = await this.sysAdminClient.modifyCustomerUser(this.selectedCustomerUser);
        if (result.result) {
          this.customerUsers = this.customerUsers.filter(c => c != this.selectedCustomerUser);
          this.selectedCustomerUser = null;
          this.alertService.success('User deleted.');
        } else {
          this.alertService.error(result.message);
        }
      }
      else {
        let result = await this.sysAdminClient.deleteCustomerUser(this.customerId, this.selectedCustomerUser.id);
        if (result.result) {
          this.customerUsers = this.customerUsers.filter(c => c != this.selectedCustomerUser);
          this.selectedCustomerUser = null;
          this.alertService.success('User deleted.');
        } else {
          this.alertService.error(result.message);
        }
      }
    }
  }

  async addCustomerUser() {
    this.showAddUserModal = false;
    try {
      let excistingCustomerUsers = await this.sysAdminClient.getCustomerUsers('', null, '', '', this.newCustomerUser.email);
      //console.log('excistingCustomerUsers', excistingCustomerUsers);
      if (excistingCustomerUsers.length > 0) {
        if (this.customers.length == 0) {
          this.customers = await this.sysAdminClient.getCustomers();
        }

        let customer: Customer;
        let userId: string;
        let excistingCustomerUser = excistingCustomerUsers[0];
        let s = excistingCustomerUser.id.split('_');
        if (s.length > 1) {
          userId = s[0];
          customer = this.customers?.find(c => c.id == s[1]);
        }

        if (customer) {
          if (customer.id != this.customerId) {
            this.modalService.ShowConfirmModal({ header: 'User excists', description: 'User with email "' + this.newCustomerUser.email + '" already excists under customer "' + customer.name + '", move user here?' }, async (confirmed) => {
              if (confirmed) {
                let result = await this.xconfClient.createReference('_x_customerusers_root_' + this.customerId, '_x_customerusers_root', excistingCustomerUser.id,
                           '_x_customerusers_user', '_x_customerusers_hasuser', true);
                if (result.result) {
                  await this.xconfClient.deleteReference('_x_customerusers_root_' + customer.id, '_x_customerusers_root', excistingCustomerUser.id, '_x_customerusers_user', '_x_customerusers_hasuser');
                  let node = await this.xconfClient.getNode(excistingCustomerUser.id, '_x_customerusers_user');
                  if (node) {
                    node.id = userId + '_' + this.customerId;
                    await this.xconfClient.updateNode(node, excistingCustomerUser.id, '', customer.id);
                  }
                  this.alertService.success('User moved ok.');
                  this.clearCustomerUser();
                  await this.getCustomerUsers();
                }
              }
            });
          }
          else if (!excistingCustomerUser.isAdmin) {
            let node = await this.xconfClient.getNode(excistingCustomerUser.id, '_x_customerusers_user');
            if (node) {
              let adminProperty =  node.propertyValues.find(x => x.key == 'isadmin');
              if (adminProperty) {
                adminProperty.value = 'true';
                await this.xconfClient.updateNode(node, excistingCustomerUser.id, '', customer.id);

                this.alertService.success('Customer admin rights added to user.');
                this.clearCustomerUser();
                await this.getCustomerUsers();
                this.modalService.ShowConfirmModal({ header: 'Add user', description: 'User excists as tenant, customer admin rights added to user.', showCancel: false }, (result) => {});
              }
            }
          }
        }
      }
      else {
        if (this.isNewCustomerUserValid()) {
          this.newCustomerUser.id = this.newCustomerUser.email;
          this.newCustomerUser.isAdmin = true;
          this.newCustomerUser.customerId = this.customerId;

          let nweUser = await this.sysAdminClient.addUserToCustomer(this.newCustomerUser, this.newCustomerUserSetPassword ? this.newCustomerUserPassword : '');
          if (nweUser) {
            this.alertService.success('New user added.');
            this.customerUsers.push(nweUser);
            this.clearCustomerUser();
          }
          else {
            this.alertService.error('Error add new user!');
          }
        }
        else {
          this.clrForm.markAsTouched();
        }
      }
    }
    catch (err) {
      this.alertService.error('Error add new user: ' + err);
    }
  }

  private isNewCustomerUserValid(): boolean {
    return this.newCustomerUser && this.newCustomerUser.firstname.length > 0 && this.newCustomerUser.surname.length > 0 && this.newCustomerUser.email.length > 0;
  }

  clearCustomerUser() {
    this.newCustomerUser = new CustomerUser();
    this.newCustomerUserPassword = '';
    this.newCustomerUserSetPassword = false;
  }

  async saveCustomerUser(customerUser: CustomerUser) {
    let result = await this.sysAdminClient.modifyCustomerUser(customerUser);

    if (result.result) {
      this.alertService.success('User updated.');
    }
    else {
      this.alertService.error('Error update user!');
    }
  }

  async resetPassword(user: CustomerUser) {
    this.modalService.ShowConfirmModal({ header: 'Reset password', description: 'Reset password for user "' + user.email + '", are you sure?' }, async (confirmed) => {
      if (confirmed) {
        let result = await this.sysAdminClient.resetPassword(user);

        if (result.result) {
          this.alertService.success('Password reseted.');
        }
        else {
          this.alertService.error('Error reset password!');
        }
      }
    });
  }

  async selectedCustomerUserChanged(customerUser: CustomerUser) {
    this.onUserSelected?.emit(customerUser);
  }

  clearSearchUsersForm() {
    this.searchCustomerUser.firstname = '';
    this.searchCustomerUser.surname = '';
    this.searchCustomerUser.email = '';
    this.searchCustomerUser.isAdmin = true;
  }

  async searchUsers() {
    if (this.customers.length == 0) {
      this.customers = await this.sysAdminClient.getCustomers();
    }
    this.customerUserWithCustomers = [];
    this.customerUsers = await this.sysAdminClient.getCustomerUsers('', this.searchCustomerUser.isAdmin, this.searchCustomerUser.firstname, this.searchCustomerUser.surname, this.searchCustomerUser.email);
    this.customerUsers.forEach(user => {
      let userWithCustomer = new CustomerUserWithCustomer();
      userWithCustomer.customerUser = user;
      let s = user.id.split('_');
      if (s.length > 1) {
        user.customerId = s[1];
        let customer = this.customers?.find(c => c.id == user.customerId);
        if (customer) {
          userWithCustomer.customerName = customer.name;
        }
      }

      this.customerUserWithCustomers.push(userWithCustomer);
    });
  }

  async viewCustomer(customerUserWithCustomer: CustomerUserWithCustomer) {
    if (customerUserWithCustomer) {
      this.router.navigateByUrl('/bmsadmin/customers/' + customerUserWithCustomer.customerUser.customerId + '/users');
    }
  }

}
