import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { StateService } from '@xprojectorcore/services/state-service';

@Injectable(
{
  providedIn: 'root'
})
export class SysAdminAuthGuard  {
    constructor(
        private router: Router,
        private state: StateService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const user = this.state.userRightsValue;
        if (user && user.isAdminUser) {
          let expiresAtUTC = new Date(user.session.expiresAtUTC);
          if (expiresAtUTC.getTime() > Date.now()) {
            // authorised so return true
            return true;
          }
          else {
            this.router.navigate(['/login'], { queryParams: { returnUrl: state.url, admin: 'true' } });
            return false;
          }
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
        return false;
    }
}
