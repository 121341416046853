<h3>New customer</h3>
<form clrForm (ngSubmit)="addCustomer()">
  <!-- <clr-input-container>
    <label>Customer username:</label>
    <input clrInput required placeholder="customer username" name="customerid" type="text" [(ngModel)]="newCustomer.username" />
  </clr-input-container> -->
  <clr-input-container>
    <label>Customer name:</label>
    <input clrInput required placeholder="customer name" name="customername" type="text"
      [(ngModel)]="newCustomer.name" />
  </clr-input-container>
  <clr-input-container>
    <label>Customer description:</label>
    <input clrInput required placeholder="customer description" name="customerdescription" type="text"
      [(ngModel)]="newCustomer.description" />
  </clr-input-container>
  <clr-checkbox-container>
    <clr-checkbox-wrapper>
      <input type="checkbox" clrCheckbox [(ngModel)]="isTrustee" name="customertrustee" />
      <label>Trustee</label>
    </clr-checkbox-wrapper>
  </clr-checkbox-container>
  <clr-checkbox-container>
    <clr-checkbox-wrapper>
      <input type="checkbox" clrCheckbox [(ngModel)]="isNonBmscustomer" name="customernonbms" />
      <label>Non Bms customer</label>
    </clr-checkbox-wrapper>
  </clr-checkbox-container>

  <clr-select-container *ngIf="!isTrustee && !isNonBmscustomer">
    <label>County</label>
    <select clrSelect name="county" [(ngModel)]="county">
      <option [ngValue]="'Blekinge län'">Blekinge län</option>
      <option [ngValue]="'Dalarnas län'">Dalarnas län</option>
      <option [ngValue]="'Gotlands län'">Gotlands län</option>
      <option [ngValue]="'Gävleborgs län'">Gävleborgs län</option>
      <option [ngValue]="'Hallands län'">Hallands län</option>
      <option [ngValue]="'Jämtlands län'">Jämtlands län</option>
      <option [ngValue]="'Jönköpings län'">Jönköpings län</option>
      <option [ngValue]="'Kalmar län'">Kalmar län</option>
      <option [ngValue]="'Kronobergs län'">Kronobergs län</option>
      <option [ngValue]="'Norrbottens län'">Norrbottens län</option>
      <option [ngValue]="'Skåne län'">Skåne län</option>
      <option [ngValue]="'Stockholms län'">Stockholms län</option>
      <option [ngValue]="'Södermanlands län'">Södermanlands län</option>
      <option [ngValue]="'Uppsala län'">Uppsala län</option>
      <option [ngValue]="'Värmlands län'">Värmlands län</option>
      <option [ngValue]="'Västerbottens län'">Västerbottens län</option>
      <option [ngValue]="'Västernorrlands län'">Västernorrlands län</option>
      <option [ngValue]="'Västmanlands län'">Västmanlands län</option>
      <option [ngValue]="'Västra Götalands län'">Västra Götalands län</option>
      <option [ngValue]="'Örebro län'">Örebro län</option>
      <option [ngValue]="'Östergötlands län'">Östergötlands län</option>
    </select>
  </clr-select-container>

  <clr-select-container *ngIf="!isTrustee && !isNonBmscustomer">
    <label>District</label>
    <select clrSelect name="district" [(ngModel)]="district">
      <option [ngValue]="'Ale kommun'">Ale kommun</option>
      <option [ngValue]="'Alingsås kommun'">Alingsås kommun</option>
      <option [ngValue]="'Alvesta kommun'">Alvesta kommun</option>
      <option [ngValue]="'Aneby kommun'">Aneby kommun</option>
      <option [ngValue]="'Arboga kommun'">Arboga kommun</option>
      <option [ngValue]="'Arjeplogs kommun'">Arjeplogs kommun</option>
      <option [ngValue]="'Arvidsjaurs kommun'">Arvidsjaurs kommun</option>
      <option [ngValue]="'Arvika kommun'">Arvika kommun</option>
      <option [ngValue]="'Askersunds kommun'">Askersunds kommun</option>
      <option [ngValue]="'Avesta kommun'">Avesta kommun</option>
      <option [ngValue]="'Bengtsfors kommun'">Bengtsfors kommun</option>
      <option [ngValue]="'Bergs kommun'">Bergs kommun</option>
      <option [ngValue]="'Bjurholms kommun'">Bjurholms kommun</option>
      <option [ngValue]="'Bjuvs kommun'">Bjuvs kommun</option>
      <option [ngValue]="'Bodens kommun'">Bodens kommun</option>
      <option [ngValue]="'Bollebygds kommun'">Bollebygds kommun</option>
      <option [ngValue]="'Bollnäs kommun'">Bollnäs kommun</option>
      <option [ngValue]="'Borgholms kommun'">Borgholms kommun</option>
      <option [ngValue]="'Borlänge kommun'">Borlänge kommun</option>
      <option [ngValue]="'Borås kommun'">Borås kommun</option>
      <option [ngValue]="'Botkyrka kommun'">Botkyrka kommun</option>
      <option [ngValue]="'Boxholms kommun'">Boxholms kommun</option>
      <option [ngValue]="'Bromölla kommun'">Bromölla kommun</option>
      <option [ngValue]="'Bräcke kommun'">Bräcke kommun</option>
      <option [ngValue]="'Burlövs kommun'">Burlövs kommun</option>
      <option [ngValue]="'Båstads kommun'">Båstads kommun</option>
      <option [ngValue]="'Dals-Eds kommun'">Dals-Eds kommun</option>
      <option [ngValue]="'Danderyds kommun'">Danderyds kommun</option>
      <option [ngValue]="'Degerfors kommun'">Degerfors kommun</option>
      <option [ngValue]="'Dorotea kommun'">Dorotea kommun</option>
      <option [ngValue]="'Eda kommun'">Eda kommun</option>
      <option [ngValue]="'Ekerö kommun'">Ekerö kommun</option>
      <option [ngValue]="'Eksjö kommun'">Eksjö kommun</option>
      <option [ngValue]="'Emmaboda kommun'">Emmaboda kommun</option>
      <option [ngValue]="'Enköpings kommun'">Enköpings kommun</option>
      <option [ngValue]="'Eskilstuna kommun'">Eskilstuna kommun</option>
      <option [ngValue]="'Eslövs kommun'">Eslövs kommun</option>
      <option [ngValue]="'Essunga kommun'">Essunga kommun</option>
      <option [ngValue]="'Fagersta kommun'">Fagersta kommun</option>
      <option [ngValue]="'Falkenbergs kommun'">Falkenbergs kommun</option>
      <option [ngValue]="'Falköpings kommun'">Falköpings kommun</option>
      <option [ngValue]="'Falu kommun'">Falu kommun</option>
      <option [ngValue]="'Filipstads kommun'">Filipstads kommun</option>
      <option [ngValue]="'Finspångs kommun'">Finspångs kommun</option>
      <option [ngValue]="'Flens kommun'">Flens kommun</option>
      <option [ngValue]="'Forshaga kommun'">Forshaga kommun</option>
      <option [ngValue]="'Färgelanda kommun'">Färgelanda kommun</option>
      <option [ngValue]="'Gagnefs kommun'">Gagnefs kommun</option>
      <option [ngValue]="'Gislaveds kommun'">Gislaveds kommun</option>
      <option [ngValue]="'Gnesta kommun'">Gnesta kommun</option>
      <option [ngValue]="'Gnosjö kommun'">Gnosjö kommun</option>
      <option [ngValue]="'Gotlands kommun'">Gotlands kommun</option>
      <option [ngValue]="'Grums kommun'">Grums kommun</option>
      <option [ngValue]="'Grästorps kommun'">Grästorps kommun</option>
      <option [ngValue]="'Gullspångs kommun'">Gullspångs kommun</option>
      <option [ngValue]="'Gällivare kommun'">Gällivare kommun</option>
      <option [ngValue]="'Gävle kommun'">Gävle kommun</option>
      <option [ngValue]="'Göteborgs kommun'">Göteborgs kommun</option>
      <option [ngValue]="'Götene kommun'">Götene kommun</option>
      <option [ngValue]="'Habo kommun'">Habo kommun</option>
      <option [ngValue]="'Hagfors kommun'">Hagfors kommun</option>
      <option [ngValue]="'Hallsbergs kommun'">Hallsbergs kommun</option>
      <option [ngValue]="'Hallstahammars kommun'">Hallstahammars kommun</option>
      <option [ngValue]="'Halmstads kommun'">Halmstads kommun</option>
      <option [ngValue]="'Hammarö kommun'">Hammarö kommun</option>
      <option [ngValue]="'Haninge kommun'">Haninge kommun</option>
      <option [ngValue]="'Haparanda kommun'">Haparanda kommun</option>
      <option [ngValue]="'Heby kommun'">Heby kommun</option>
      <option [ngValue]="'Hedemora kommun'">Hedemora kommun</option>
      <option [ngValue]="'Helsingborgs kommun'">Helsingborgs kommun</option>
      <option [ngValue]="'Herrljunga kommun'">Herrljunga kommun</option>
      <option [ngValue]="'Hjo kommun'">Hjo kommun</option>
      <option [ngValue]="'Hofors kommun'">Hofors kommun</option>
      <option [ngValue]="'Huddinge kommun'">Huddinge kommun</option>
      <option [ngValue]="'Hudiksvalls kommun'">Hudiksvalls kommun</option>
      <option [ngValue]="'Hultsfreds kommun'">Hultsfreds kommun</option>
      <option [ngValue]="'Hylte kommun'">Hylte kommun</option>
      <option [ngValue]="'Håbo kommun'">Håbo kommun</option>
      <option [ngValue]="'Hällefors kommun'">Hällefors kommun</option>
      <option [ngValue]="'Härjedalens kommun'">Härjedalens kommun</option>
      <option [ngValue]="'Härnösands kommun'">Härnösands kommun</option>
      <option [ngValue]="'Härryda kommun'">Härryda kommun</option>
      <option [ngValue]="'Hässleholms kommun'">Hässleholms kommun</option>
      <option [ngValue]="'Höganäs kommun'">Höganäs kommun</option>
      <option [ngValue]="'Högsby kommun'">Högsby kommun</option>
      <option [ngValue]="'Hörby kommun'">Hörby kommun</option>
      <option [ngValue]="'Höörs kommun'">Höörs kommun</option>
      <option [ngValue]="'Jokkmokks kommun'">Jokkmokks kommun</option>
      <option [ngValue]="'Järfälla kommun'">Järfälla kommun</option>
      <option [ngValue]="'Jönköpings kommun'">Jönköpings kommun</option>
      <option [ngValue]="'Kalix kommun'">Kalix kommun</option>
      <option [ngValue]="'Kalmar kommun'">Kalmar kommun</option>
      <option [ngValue]="'Karlsborgs kommun'">Karlsborgs kommun</option>
      <option [ngValue]="'Karlshamns kommun'">Karlshamns kommun</option>
      <option [ngValue]="'Karlskoga kommun'">Karlskoga kommun</option>
      <option [ngValue]="'Karlskrona kommun'">Karlskrona kommun</option>
      <option [ngValue]="'Karlstads kommun'">Karlstads kommun</option>
      <option [ngValue]="'Katrineholms kommun'">Katrineholms kommun</option>
      <option [ngValue]="'Kils kommun'">Kils kommun</option>
      <option [ngValue]="'Kinda kommun'">Kinda kommun</option>
      <option [ngValue]="'Kiruna kommun'">Kiruna kommun</option>
      <option [ngValue]="'Klippans kommun'">Klippans kommun</option>
      <option [ngValue]="'Knivsta kommun'">Knivsta kommun</option>
      <option [ngValue]="'Kramfors kommun'">Kramfors kommun</option>
      <option [ngValue]="'Kristianstads kommun'">Kristianstads kommun</option>
      <option [ngValue]="'Kristinehamns kommun'">Kristinehamns kommun</option>
      <option [ngValue]="'Krokoms kommun'">Krokoms kommun</option>
      <option [ngValue]="'Kumla kommun'">Kumla kommun</option>
      <option [ngValue]="'Kungsbacka kommun'">Kungsbacka kommun</option>
      <option [ngValue]="'Kungsörs kommun'">Kungsörs kommun</option>
      <option [ngValue]="'Kungälvs kommun'">Kungälvs kommun</option>
      <option [ngValue]="'Kävlinge kommun'">Kävlinge kommun</option>
      <option [ngValue]="'Köpings kommun'">Köpings kommun</option>
      <option [ngValue]="'Laholms kommun'">Laholms kommun</option>
      <option [ngValue]="'Landskrona kommun'">Landskrona kommun</option>
      <option [ngValue]="'Laxå kommun'">Laxå kommun</option>
      <option [ngValue]="'Lekebergs kommun'">Lekebergs kommun</option>
      <option [ngValue]="'Leksands kommun'">Leksands kommun</option>
      <option [ngValue]="'Lerums kommun'">Lerums kommun</option>
      <option [ngValue]="'Lessebo kommun'">Lessebo kommun</option>
      <option [ngValue]="'Lidingö kommun'">Lidingö kommun</option>
      <option [ngValue]="'Lidköpings kommun'">Lidköpings kommun</option>
      <option [ngValue]="'Lilla Edets kommun'">Lilla Edets kommun</option>
      <option [ngValue]="'Lindesbergs kommun'">Lindesbergs kommun</option>
      <option [ngValue]="'Linköpings kommun'">Linköpings kommun</option>
      <option [ngValue]="'Ljungby kommun'">Ljungby kommun</option>
      <option [ngValue]="'Ljusdals kommun'">Ljusdals kommun</option>
      <option [ngValue]="'Ljusnarsbergs kommun'">Ljusnarsbergs kommun</option>
      <option [ngValue]="'Lomma kommun'">Lomma kommun</option>
      <option [ngValue]="'Ludvika kommun'">Ludvika kommun</option>
      <option [ngValue]="'Luleå kommun'">Luleå kommun</option>
      <option [ngValue]="'Lunds kommun'">Lunds kommun</option>
      <option [ngValue]="'Lycksele kommun'">Lycksele kommun</option>
      <option [ngValue]="'Lysekils kommun'">Lysekils kommun</option>
      <option [ngValue]="'Malmö kommun'">Malmö kommun</option>
      <option [ngValue]="'Malung-Sälens kommun'">Malung-Sälens kommun</option>
      <option [ngValue]="'Malå kommun'">Malå kommun</option>
      <option [ngValue]="'Mariestads kommun'">Mariestads kommun</option>
      <option [ngValue]="'Marks kommun'">Marks kommun</option>
      <option [ngValue]="'Markaryds kommun'">Markaryds kommun</option>
      <option [ngValue]="'Melleruds kommun'">Melleruds kommun</option>
      <option [ngValue]="'Mjölby kommun'">Mjölby kommun</option>
      <option [ngValue]="'Mora kommun'">Mora kommun</option>
      <option [ngValue]="'Motala kommun'">Motala kommun</option>
      <option [ngValue]="'Mullsjö kommun'">Mullsjö kommun</option>
      <option [ngValue]="'Munkedals kommun'">Munkedals kommun</option>
      <option [ngValue]="'Munkfors kommun'">Munkfors kommun</option>
      <option [ngValue]="'Mölndals kommun'">Mölndals kommun</option>
      <option [ngValue]="'Mönsterås kommun'">Mönsterås kommun</option>
      <option [ngValue]="'Mörbylånga kommun'">Mörbylånga kommun</option>
      <option [ngValue]="'Nacka kommun'">Nacka kommun</option>
      <option [ngValue]="'Nora kommun'">Nora kommun</option>
      <option [ngValue]="'Norbergs kommun'">Norbergs kommun</option>
      <option [ngValue]="'Nordanstigs kommun'">Nordanstigs kommun</option>
      <option [ngValue]="'Nordmalings kommun'">Nordmalings kommun</option>
      <option [ngValue]="'Norrköpings kommun'">Norrköpings kommun</option>
      <option [ngValue]="'Norrtälje kommun'">Norrtälje kommun</option>
      <option [ngValue]="'Norsjö kommun'">Norsjö kommun</option>
      <option [ngValue]="'Nybro kommun'">Nybro kommun</option>
      <option [ngValue]="'Nykvarns kommun'">Nykvarns kommun</option>
      <option [ngValue]="'Nyköpings kommun'">Nyköpings kommun</option>
      <option [ngValue]="'Nynäshamns kommun'">Nynäshamns kommun</option>
      <option [ngValue]="'Nässjö kommun'">Nässjö kommun</option>
      <option [ngValue]="'Ockelbo kommun'">Ockelbo kommun</option>
      <option [ngValue]="'Olofströms kommun'">Olofströms kommun</option>
      <option [ngValue]="'Orsa kommun'">Orsa kommun</option>
      <option [ngValue]="'Orusts kommun'">Orusts kommun</option>
      <option [ngValue]="'Osby kommun'">Osby kommun</option>
      <option [ngValue]="'Oskarshamns kommun'">Oskarshamns kommun</option>
      <option [ngValue]="'Ovanåkers kommun'">Ovanåkers kommun</option>
      <option [ngValue]="'Oxelösunds kommun'">Oxelösunds kommun</option>
      <option [ngValue]="'Pajala kommun'">Pajala kommun</option>
      <option [ngValue]="'Partille kommun'">Partille kommun</option>
      <option [ngValue]="'Perstorps kommun'">Perstorps kommun</option>
      <option [ngValue]="'Piteå kommun'">Piteå kommun</option>
      <option [ngValue]="'Ragunda kommun'">Ragunda kommun</option>
      <option [ngValue]="'Robertsfors kommun'">Robertsfors kommun</option>
      <option [ngValue]="'Ronneby kommun'">Ronneby kommun</option>
      <option [ngValue]="'Rättviks kommun'">Rättviks kommun</option>
      <option [ngValue]="'Sala kommun'">Sala kommun</option>
      <option [ngValue]="'Salems kommun'">Salems kommun</option>
      <option [ngValue]="'Sandvikens kommun'">Sandvikens kommun</option>
      <option [ngValue]="'Sigtuna kommun'">Sigtuna kommun</option>
      <option [ngValue]="'Simrishamns kommun'">Simrishamns kommun</option>
      <option [ngValue]="'Sjöbo kommun'">Sjöbo kommun</option>
      <option [ngValue]="'Skara kommun'">Skara kommun</option>
      <option [ngValue]="'Skellefteå kommun'">Skellefteå kommun</option>
      <option [ngValue]="'Skinnskattebergs kommun'">Skinnskattebergs kommun</option>
      <option [ngValue]="'Skurups kommun'">Skurups kommun</option>
      <option [ngValue]="'Skövde kommun'">Skövde kommun</option>
      <option [ngValue]="'Smedjebackens kommun'">Smedjebackens kommun</option>
      <option [ngValue]="'Sollefteå kommun'">Sollefteå kommun</option>
      <option [ngValue]="'Sollentuna kommun'">Sollentuna kommun</option>
      <option [ngValue]="'Solna kommun'">Solna kommun</option>
      <option [ngValue]="'Sorsele kommun'">Sorsele kommun</option>
      <option [ngValue]="'Sotenäs kommun'">Sotenäs kommun</option>
      <option [ngValue]="'Staffanstorps kommun'">Staffanstorps kommun</option>
      <option [ngValue]="'Stenungsunds kommun'">Stenungsunds kommun</option>
      <option [ngValue]="'Stockholms kommun'">Stockholms kommun</option>
      <option [ngValue]="'Storfors kommun'">Storfors kommun</option>
      <option [ngValue]="'Storumans kommun'">Storumans kommun</option>
      <option [ngValue]="'Strängnäs kommun'">Strängnäs kommun</option>
      <option [ngValue]="'Strömstads kommun'">Strömstads kommun</option>
      <option [ngValue]="'Strömsunds kommun'">Strömsunds kommun</option>
      <option [ngValue]="'Sundbybergs kommun'">Sundbybergs kommun</option>
      <option [ngValue]="'Sundsvalls kommun'">Sundsvalls kommun</option>
      <option [ngValue]="'Sunne kommun'">Sunne kommun</option>
      <option [ngValue]="'Surahammars kommun'">Surahammars kommun</option>
      <option [ngValue]="'Svalövs kommun'">Svalövs kommun</option>
      <option [ngValue]="'Svedala kommun'">Svedala kommun</option>
      <option [ngValue]="'Svenljunga kommun'">Svenljunga kommun</option>
      <option [ngValue]="'Säffle kommun'">Säffle kommun</option>
      <option [ngValue]="'Säters kommun'">Säters kommun</option>
      <option [ngValue]="'Sävsjö kommun'">Sävsjö kommun</option>
      <option [ngValue]="'Söderhamns kommun'">Söderhamns kommun</option>
      <option [ngValue]="'Söderköpings kommun'">Söderköpings kommun</option>
      <option [ngValue]="'Södertälje kommun'">Södertälje kommun</option>
      <option [ngValue]="'Sölvesborgs kommun'">Sölvesborgs kommun</option>
      <option [ngValue]="'Tanums kommun'">Tanums kommun</option>
      <option [ngValue]="'Tibro kommun'">Tibro kommun</option>
      <option [ngValue]="'Tidaholms kommun'">Tidaholms kommun</option>
      <option [ngValue]="'Tierps kommun'">Tierps kommun</option>
      <option [ngValue]="'Timrå kommun'">Timrå kommun</option>
      <option [ngValue]="'Tingsryds kommun'">Tingsryds kommun</option>
      <option [ngValue]="'Tjörns kommun'">Tjörns kommun</option>
      <option [ngValue]="'Tomelilla kommun'">Tomelilla kommun</option>
      <option [ngValue]="'Torsby kommun'">Torsby kommun</option>
      <option [ngValue]="'Torsås kommun'">Torsås kommun</option>
      <option [ngValue]="'Tranemo kommun'">Tranemo kommun</option>
      <option [ngValue]="'Tranås kommun'">Tranås kommun</option>
      <option [ngValue]="'Trelleborgs kommun'">Trelleborgs kommun</option>
      <option [ngValue]="'Trollhättans kommun'">Trollhättans kommun</option>
      <option [ngValue]="'Trosa kommun'">Trosa kommun</option>
      <option [ngValue]="'Tyresö kommun'">Tyresö kommun</option>
      <option [ngValue]="'Täby kommun'">Täby kommun</option>
      <option [ngValue]="'Töreboda kommun'">Töreboda kommun</option>
      <option [ngValue]="'Uddevalla kommun'">Uddevalla kommun</option>
      <option [ngValue]="'Ulricehamns kommun'">Ulricehamns kommun</option>
      <option [ngValue]="'Umeå kommun'">Umeå kommun</option>
      <option [ngValue]="'Upplands Väsby kommun'">Upplands Väsby kommun</option>
      <option [ngValue]="'Upplands-Bro kommun'">Upplands-Bro kommun</option>
      <option [ngValue]="'Uppsala kommun'">Uppsala kommun</option>
      <option [ngValue]="'Uppvidinge kommun'">Uppvidinge kommun</option>
      <option [ngValue]="'Vadstena kommun'">Vadstena kommun</option>
      <option [ngValue]="'Vaggeryds kommun'">Vaggeryds kommun</option>
      <option [ngValue]="'Valdemarsviks kommun'">Valdemarsviks kommun</option>
      <option [ngValue]="'Vallentuna kommun'">Vallentuna kommun</option>
      <option [ngValue]="'Vansbro kommun'">Vansbro kommun</option>
      <option [ngValue]="'Vara kommun'">Vara kommun</option>
      <option [ngValue]="'Varbergs kommun'">Varbergs kommun</option>
      <option [ngValue]="'Vaxholms kommun'">Vaxholms kommun</option>
      <option [ngValue]="'Vellinge kommun'">Vellinge kommun</option>
      <option [ngValue]="'Vetlanda kommun'">Vetlanda kommun</option>
      <option [ngValue]="'Vilhelmina kommun'">Vilhelmina kommun</option>
      <option [ngValue]="'Vimmerby kommun'">Vimmerby kommun</option>
      <option [ngValue]="'Vindelns kommun'">Vindelns kommun</option>
      <option [ngValue]="'Vingåkers kommun'">Vingåkers kommun</option>
      <option [ngValue]="'Vårgårda kommun'">Vårgårda kommun</option>
      <option [ngValue]="'Vänersborgs kommun'">Vänersborgs kommun</option>
      <option [ngValue]="'Vännäs kommun'">Vännäs kommun</option>
      <option [ngValue]="'Värmdö kommun'">Värmdö kommun</option>
      <option [ngValue]="'Värnamo kommun'">Värnamo kommun</option>
      <option [ngValue]="'Västerviks kommun'">Västerviks kommun</option>
      <option [ngValue]="'Västerås kommun'">Västerås kommun</option>
      <option [ngValue]="'Växjö kommun'">Växjö kommun</option>
      <option [ngValue]="'Ydre kommun'">Ydre kommun</option>
      <option [ngValue]="'Ystads kommun'">Ystads kommun</option>
      <option [ngValue]="'Åmåls kommun'">Åmåls kommun</option>
      <option [ngValue]="'Ånge kommun'">Ånge kommun</option>
      <option [ngValue]="'Åre kommun'">Åre kommun</option>
      <option [ngValue]="'Årjängs kommun'">Årjängs kommun</option>
      <option [ngValue]="'Åsele kommun'">Åsele kommun</option>
      <option [ngValue]="'Åstorps kommun'">Åstorps kommun</option>
      <option [ngValue]="'Åtvidabergs kommun'">Åtvidabergs kommun</option>
      <option [ngValue]="'Älmhults kommun'">Älmhults kommun</option>
      <option [ngValue]="'Älvdalens kommun'">Älvdalens kommun</option>
      <option [ngValue]="'Älvkarleby kommun'">Älvkarleby kommun</option>
      <option [ngValue]="'Älvsbyns kommun'">Älvsbyns kommun</option>
      <option [ngValue]="'Ängelholms kommun'">Ängelholms kommun</option>
      <option [ngValue]="'Öckerö kommun'">Öckerö kommun</option>
      <option [ngValue]="'Ödeshögs kommun'">Ödeshögs kommun</option>
      <option [ngValue]="'Örebro kommun'">Örebro kommun</option>
      <option [ngValue]="'Örkelljunga kommun'">Örkelljunga kommun</option>
      <option [ngValue]="'Örnsköldsviks kommun'">Örnsköldsviks kommun</option>
      <option [ngValue]="'Östersunds kommun'">Östersunds kommun</option>
      <option [ngValue]="'Österåkers kommun'">Österåkers kommun</option>
      <option [ngValue]="'Östhammars kommun'">Östhammars kommun</option>
      <option [ngValue]="'Östra Göinge kommun'">Östra Göinge kommun</option>
      <option [ngValue]="'Överkalix kommun'">Överkalix kommun</option>
      <option [ngValue]="'Övertorneå kommun'">Övertorneå kommun</option>
    </select>
  </clr-select-container>
  <!-- <clr-checkbox-container>
    <clr-checkbox-wrapper>
      <input type="checkbox" clrCheckbox [(ngModel)]="newCustomer.isEnabled"
        name="customerenabled" />
      <label>Enabled</label>
    </clr-checkbox-wrapper>
  </clr-checkbox-container>
  <clr-input-container>
    <label>Customer admin email:</label>
    <input clrInput required placeholder="customeradmin" name="customeradmin" type="text" [(ngModel)]="newCustomerAdmin.email" />
  </clr-input-container> -->

</form>
<button type="submit" class="btn btn-outline" (click)="addCustomer();">
  <clr-icon shape="plus-circle"></clr-icon>
  Add customer
</button>
