import { Component, OnInit, Input, Inject } from '@angular/core';

import { Subject } from 'rxjs';

import {ClrDatagridFilterInterface, ClrDatagridFilter, ClrDatagridNumericFilterInterface} from "@clr/angular";

@Component({
    selector: "xproj-datagrid-numeric-filter",
    templateUrl: './xproj-datagrid-numeric-filter.component.html',
    styleUrls: ['./xproj-datagrid-numeric-filter.component.scss']
})
export class XprojDatagridNumericFilterComponent implements OnInit, ClrDatagridFilterInterface<any>
{
    name = 'DatagridNumericFilterComponent';
    custom = true;

    public exact : number | null = null;
    public low : number | null = null;
    public high : number | null = null;

    @Input() columnname : string;
    @Input() exactPlaceholder: string = 'Exact value';
    @Input() minPlaceholder: string = 'Min value';
    @Input() maxPlaceholder: string = 'Max value';

    changes = new Subject<any>();

    constructor(private filterContainer: ClrDatagridFilter) {
        filterContainer.setFilter(this);
    }

    get exactPlaceholderValue() {
      return this.exactPlaceholder;
    }

    get maxPlaceholderValue() {
      return this.maxPlaceholder;
    }

    get minPlaceholderValue() {
      return this.minPlaceholder;
    }

    clear() {
      this.exact = null;
      this.low = null;
      this.high = null;
      this.submit();
    }

    change(): void {
      this.changes.next(true);
    }

    submit() {
      this.change();
    }

    isActive(): boolean {
     return this.exact != null || this.low != null || this.high != null;
    }

    accepts(item: any): boolean {
      return true;
    }

    ngOnInit(): void {
    }

}
