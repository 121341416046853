import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Projection, ProjectionColumnDescription, DataSource, ProjectionExecutionEntry, XDataType, XProjectorClient, ProjectionColumnGroupColumnDescription,
  MsgPackCloneObject,
  ProjectionExecution,
  ProjectionExecutionDataSourceQuery,
  ProjectionExecutionLuaMainFunction,
  ResetProjectionDataQuery,
  ProjectionDependencyRemote,
  SetDataQuery,
  SetDataColumnDescription
} from '../../XProjector/xprojector-client-service';

import * as uuid from 'uuid';
import { ClrLoadingState } from '@clr/angular';
import { ProjectionDependencies, ProjectionDependencyProjection } from '../../XProjector/xprojector-client-service';
import { XProjDatasourcequeryproberComponent } from '../datasourcequeryprober/xproj-datasourcequeryprober.component';
import { XprojModalService } from '../../modals/xproj-modal-service.service';
import * as XLSX from 'xlsx';
import { jsonObject, jsonMember, TypedJSON, jsonArrayMember } from 'typedjson';
import { LOGGERSERVICE, XprojLoggerService } from '../../logger/xproj-logger-service';
import { environment } from 'src/environments/environment';
import { NgxFileDropEntry } from 'ngx-file-drop';

export class UIExecution {
  execution: any;
  executionDS = [];
  executionLMF = [];
}

// copy paste from projectio-dataeditor .. to be reworked
@jsonObject
export class EnumMember {
  @jsonMember
  public value : number;

  @jsonMember
  public name : string;
}

export class QueryDataColumn {
  id: string;
  columnname: string;
  columnnameSetData: string;
  label: string;
  datatype: XDataType;
  projectionid: string;
  hidden: boolean = false;
  writeable: boolean = false;
  primaryKey: boolean = false;
  editMode: EditMode = EditMode.String;
  enumMembers: EnumMember[] = [];
  useFixedValue: boolean = false;
  isOnDeleteColumn: boolean = false;
  fixedValueInputParameterId: string = '';
  fixedValue: string = '';
  data: any[] = [];
}
export enum EditMode {
  Number = 0,
  String = 1,
  Timestamp = 2,
  Boolean = 3,
  XDatatype = 4,
  Enum = 5,
  Flags = 6
}


class TableData {
  columns: QueryDataColumn[] = [];
  data_col0: any = [];
  data_col_deleted: any = [];
  initilized: boolean = false;
  oldInitilized: boolean = false;
}

// end copy paste from projectio-dataeditor .. to be reworked

@Component({
  selector: 'xproj-editprojection',
  templateUrl: './xproj-editprojection.component.html',
  styleUrls: ['./xproj-editprojection.component.scss']
})

export class XProjEditprojectionComponent implements OnInit {

  // copy paste..
  tableData: TableData = new TableData();


  // , scrollBeyondLastLine: false
  editorOptionsLua = { theme: environment.editortheme,
    language: 'lua',
     automaticLayout: true,
     acceptSuggestionOnEnter: "smart",
     minimap: { enabled: false },
     ariaLabel: "arne weise",
     scrollBeyondLastLine: false
    };

  RunNowTriggeredFromLMFSection = false;
  ProjectionExecutionEntry = ProjectionExecutionEntry;
  XDataType = XDataType;
  selectedProjection: any;
  selectedExecution: any;
  selectedExecutionDS: any;
  selectedExecutionLMF: any;
  Executions = [];

  //ExecutionsDS = [];
  loadingProjection = false;
  projectionId: string = "";
  newTag: string = "";
  //newColTag : string = "";
  creatingNew: boolean = false;
  loadingProjectionColumns: boolean = false;
  selectedGroup: any = null;
  selectedGroupColumn: any = null;
  selectedGroupnrPrimarykeys: number = 0;
  SchedulingQueues=[];
  DataPaths = [];

  ManualRemoteNodeID :string = "";

  resetProjectionDataGroups : string[] = [];

  nrPrimarykeys: number = 0;
  Columns: Array<ProjectionColumnDescription> = null;

  Datasources = [];
  Projections : {projection : Projection,  scriptname : string}[] = [];

  savingProjection: ClrLoadingState = ClrLoadingState.DEFAULT;

  //savingRemoveGrouping : ClrLoadingState = ClrLoadingState.DEFAULT;

  importingProjectionColumnsFromQuery: ClrLoadingState = ClrLoadingState.DEFAULT;
  runningProjection: ClrLoadingState = ClrLoadingState.DEFAULT;
  runningResetProjectionData: ClrLoadingState = ClrLoadingState.DEFAULT;
  loadingExecutions = ClrLoadingState.DEFAULT;
  savingExecutions = ClrLoadingState.DEFAULT;

  projectionExecutionLog: any = null;

  isNumericalType(xdbtype : XDataType) : boolean
  {
    switch(xdbtype)
    {
        case XDataType.Float32:
        case XDataType.Float64:
        case XDataType.Int32:
        case XDataType.Int64:
        case XDataType.Number:
        case XDataType.UInt8:
          return true;
    }

    return false;
  }
  public ExcelImportError:string = '';
  public ExcelImportLog : string = '';

  async onImportDataExcel(files: NgxFileDropEntry[]) {
    this.ExcelImportLog = "";
    this.ExcelImportError = "";

    for(let droppedFile of files)
    {

      if (!droppedFile.fileEntry.isFile)
      {
        this.ExcelImportError = "Not a file";
        return;
      }

      const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;

      fileEntry.file(async (file: File) =>
      {
        try
        {
          this.ExcelImportLog += "Importing " + file.name +"\n";
          let buff = await file.arrayBuffer();
          const wb: XLSX.WorkBook = XLSX.read(buff, { type: 'binary' });

          const wsname: string = wb.SheetNames[0];
          const ws: XLSX.WorkSheet = wb.Sheets[wsname];

          const data = XLSX.utils.sheet_to_json(ws, {header: 1, raw: false}); // generate objects
          console.log("XLSX data", data);

          let importcolumns = [];
          let header = data[0] as string[];
          let importtypes = {};
          let importindexes  = {};

          let setDataQuery = new SetDataQuery();
          setDataQuery.datanumbers = [];
          setDataQuery.datatimestamps = [];
          setDataQuery.datastrings = [];

          for(let scol of header)
          {
            importcolumns.push(scol);
            for(let col of this.Columns)
            {
              if(col.columnname == scol)
              {
                importtypes[scol] = col.datatype;

                let setcol = new SetDataColumnDescription();
                setcol.columnname = scol;
                setcol.datatype = col.datatype;
                console.log(scol + " type:", setcol.datatype);

                if(this.isNumericalType(col.datatype))
                {
                  setcol.indexintypedvector = setDataQuery.datanumbers.length;
                  setDataQuery.datanumbers.push([]);
                }
                else if(col.datatype == XDataType.Timestamp)
                {
                  setcol.indexintypedvector = setDataQuery.datatimestamps.length;
                  setDataQuery.datatimestamps.push([]);
                }
                else
                {
                  setcol.indexintypedvector = setDataQuery.datastrings.length;
                  setDataQuery.datastrings.push([]);
                }
                importindexes[scol] = setcol.indexintypedvector;
                setDataQuery.columns.push(setcol);
              }
            }
          }

          setDataQuery.projectionid = this.projectionId;

          this.ExcelImportLog += "Preparing data\n";
          for(let row = 1; row < data.length; row++)
          {
            for(let i = 0; i < importcolumns.length; i++)
            {
              let scol = importcolumns[i];
              let index = importindexes[scol];
              let importtype = importtypes[scol];

              if(this.isNumericalType(importtype))
              {
                let n = Number.parseFloat(data[row][i]);
                if(isNaN(n))
                {
                  n = 0;
                }
                setDataQuery.datanumbers[index].push(n);
              }
              else if(importtype == XDataType.Timestamp)
              {
                let d = (new Date(data[row][i]));
                if(d.getFullYear()<1900)
                {
                  d = new Date(1900, 1, 1);
                }
                setDataQuery.datatimestamps[index].push( d );
              }
              else
              {
                let s = (data[row][i]);
                if(!s)
                {
                  s = "";
                }
                setDataQuery.datastrings[index].push( s );
              }
            }
          }

          console.log("Setting data:", setDataQuery);
          this.ExcelImportLog += "Setting data\n";
          await this.xprojClient.RequestSetData(setDataQuery);
          this.ExcelImportLog += "Setting data done - Imported " + data.length.toString() + " rows\n";
        }
        catch(e)
        {
          this.ExcelImportError += e;
        }
      });
    }
  }


  public ShowImportFromFile : boolean = true;

  // onImportDataCSV($event) {
  //   const target: DataTransfer = <DataTransfer>($event.target);
  //   if (target.files.length !== 1)
  //     throw new Error('Cannot use multiple files');

  //   const reader: FileReader = new FileReader();
  //   reader.onload = (e: any) => {

  //     const filename: string = e.target.result;
  //     const data = <any[]>this.importFromFileExcel(filename);

  //     this.importDataExcel(data);
  //   };
  //   reader.readAsBinaryString(target.files[0]);
  // }

  // importFromFileCSV(filename: string): XLSX.AOA2SheetOpts {

  //   const wb: XLSX.WorkBook = XLSX.read(filename, { type: 'binary' });

  //   /* grab first sheet */
  //   const wsname: string = wb.SheetNames[0];
  //   const ws: XLSX.WorkSheet = wb.Sheets[wsname];

  //   return <XLSX.AOA2SheetOpts>(XLSX.utils.sheet_to_json(ws, { header: 1 }));
  // }

  constructor(
    private xprojClient: XProjectorClient,
    private modalService: XprojModalService,
    @Inject(LOGGERSERVICE) private logger: XprojLoggerService,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router) {

  }

  //@ts-ignore
  EditorLMF : monaco.editor.ICodeEditor = null;

  getEditorHeight(str:string)
  {
    const lines = (str.match(/\n/g) || '').length + 1;
    return lines * 19 + 10;
  }


  onInitEditorLuaMain(editor : any)
  {
    console.log("editor:", editor);
    //@ts-ignore
    let ed = editor as monaco.editor.ICodeEditor;
    this.EditorLMF = ed;
    // this.updateMarginGlyphs();
    // //this.Editor.onMouseDown = this.onEditorMouseDown;
    // this.Editor.onMouseDown ( this.onEditorMouseDown.bind(this));
  }

  async SaveExecution(exec: any)
  {
    try
    {
      this.savingProjection = ClrLoadingState.LOADING;
      let Exec = exec as UIExecution;
      await this.xprojClient.RequestSaveProjectionExecution(Exec.execution);
      for(let t of Exec.executionDS)
        await this.xprojClient.RequestSaveProjectionExecutionDataSourceQuery(t);
      for(let t of Exec.executionLMF)
        await this.xprojClient.RequestSaveProjectionExecutionLuaMainfunction(t);

      this.savingProjection = ClrLoadingState.SUCCESS;
    }
    catch
    {
      this.savingProjection = ClrLoadingState.ERROR;
    }
  }

  async Save(overridelastprojected: boolean) {
    this.logger.info(this.selectedProjection);
    try {
      this.savingProjection = ClrLoadingState.LOADING;
      let response = await this.xprojClient.RequestSaveProjection(this.selectedProjection as Projection, overridelastprojected);
      this.selectedProjection = response;

      if (this.creatingNew) {
        this.creatingNew = false;
        this.router.navigateByUrl(this.router.url.replace("new", this.projectionId), { skipLocationChange: false });
      }
      this.savingProjection = ClrLoadingState.SUCCESS;
    }
    catch
    {
      this.savingProjection = ClrLoadingState.ERROR;
    }
  }

  async ResetProjectionData() {
    this.modalService.ShowConfirmModal({ header: 'Reset projection data', description: 'Reset projction data, are you sure?' }, async (result) => {
      if (result) {
        try {
          this.runningResetProjectionData = ClrLoadingState.LOADING;
          await this.xprojClient.RequestResetConfigProjectionData(this.projectionId);
          this.runningResetProjectionData = ClrLoadingState.SUCCESS;
        }
        catch
        {
          this.runningResetProjectionData = ClrLoadingState.ERROR;
        }
      }
    });

  }

  async ResetProjectionGroupData() {
    if (this.resetProjectionDataGroups?.length > 0) {
      this.modalService.ShowConfirmModal({ header: 'Reset projection data on group', description: 'Reset projction group data: [' + this.resetProjectionDataGroups.join(', ') + '], are you sure?' }, async (result) => {
        if (result) {
          try {
            this.runningResetProjectionData = ClrLoadingState.LOADING;
            let resetData : ResetProjectionDataQuery = new ResetProjectionDataQuery();
            resetData.projectionid = this.projectionId;
            resetData.group = this.resetProjectionDataGroups
            await this.xprojClient.RequestResetConfigProjectionGroupData(resetData);
            this.runningResetProjectionData = ClrLoadingState.SUCCESS;
          }
          catch
          {
            this.runningResetProjectionData = ClrLoadingState.ERROR;
          }
        }
      });
    }
  }

  async selectedResetGroupChange(group: any) {
    if (this.selectedProjection) {
      this.resetProjectionDataGroups = group;
    }
  }


  async RunProjection() {
    try {
      this.runningProjection = ClrLoadingState.LOADING;
      this.projectionExecutionLog = await this.xprojClient.RequestRunConfigProjection(this.projectionId);
      this.runningProjection = ClrLoadingState.SUCCESS;
    }
    catch
    {
      this.runningProjection = ClrLoadingState.ERROR;
    }
  }


  async ImportColumnsFromDatasourceQuery(prober: XProjDatasourcequeryproberComponent) {
    try {
      this.importingProjectionColumnsFromQuery = ClrLoadingState.LOADING;
      let createdColumns = await prober.getColumns();
      let s = new Set<string>();
      for (let i = 0; i < createdColumns.length; i++) {
        s.add(createdColumns[i].columnname);
      }
      this.logger.debug(s);
      this.logger.debug("s size: " + s.size.toString());
      this.logger.debug("createdColumns.length: " + createdColumns.length.toString());
      if (s.size != createdColumns.length) {
        throw new Error("Non-unique column names");
      }

      for (let i = 0; i < this.Columns.length; i++) {
        await this.xprojClient.RequestRemoveConfigProjectionColumn(this.Columns[i].projectioncolumndescriptionid);
      }

      this.Columns.length = 0;
      for (let i = 0; i < createdColumns.length; i++) {
        let col = createdColumns[i];
        col.projectioncolumndescriptionid = uuid.v4();
        col.projectionid = this.projectionId;
        createdColumns[i] = await this.xprojClient.RequestSaveProjectionColumnDescription(col);
        this.Columns.push(createdColumns[i]);
      }
      this.importingProjectionColumnsFromQuery = ClrLoadingState.SUCCESS;
    }
    catch
    {
      this.importingProjectionColumnsFromQuery = ClrLoadingState.ERROR;
    }
  }

  ImportOutputColumnsFromColumns() {
    for (let i = 0; i < this.Columns.length; i++) {
      let newCopy = MsgPackCloneObject(this.Columns[i]) as ProjectionColumnDescription;
      newCopy.projectioncolumndescriptionid = uuid.v4();
      this.selectedExecution.execution.grouping.outputcolumns.push(newCopy);
    }
  }

  NewGroupingColumnInput() {
    let newGroupingColumn = new ProjectionColumnGroupColumnDescription();
    newGroupingColumn.projectioncolumndescriptionid = "unset";
    //newGroupingColumn= uuid.v4();
    this.selectedExecution.execution.grouping.groupcolumns.push(newGroupingColumn);
  }

  RemoveGroupColumnInput() {
    for (let i = 0; i < this.selectedExecution.execution.grouping.groupcolumns.length; i++) {
      if (this.selectedExecution.execution.grouping.groupcolumns[i] == this.selectedGroupColumn) {
        this.selectedExecution.execution.grouping.groupcolumns.splice(i, 1);
        return;
      }
    }
    this.selectedGroupColumn = null;
  }

  async NewExecution() {
    let newExec = new ProjectionExecution();
    newExec.projectionexecutionid = uuid.v4();
    newExec.projectionid = this.projectionId;
    newExec.isdefaultexecution = (!this.Executions  || this.Executions.length == 0);
    newExec.entrypoint = ProjectionExecutionEntry.DataSourceQuery;
    newExec = await this.xprojClient.RequestSaveProjectionExecution(newExec);

    let newUiExec = new UIExecution();
    newUiExec.execution = newExec;
    this.Executions.push(newUiExec);
  }
  async RemoveExecution(exec: any) {
    throw new Error("not implemented");
  }

  async recreateEntryPoint()
  {
    // No need really
    // if(this.selectedExecution.entrypoint == ProjectionExecutionEntry.DataSourceQuery)
    // {

    // }
  }

  async NewExecutionDS(exec: any) {
    let Exec = exec as UIExecution;
    let newExecDS = new ProjectionExecutionDataSourceQuery();
    newExecDS.projectionexecutionid = Exec.execution.projectionexecutionid;
    newExecDS.projectionexecutiondatasourcequeryid = uuid.v4();
    newExecDS.entrypointdatasourcequery = "";
    newExecDS.deletedfieldname = "";
    newExecDS.modifiedatfieldname = "";
    newExecDS.projectionexecutionid = Exec.execution.projectionexecutionid;
    newExecDS.entrypointdatasourceid = "";
    if(this.Datasources.length && this.Datasources.length > 0)
      newExecDS.entrypointdatasourceid = (this.Datasources[0] as DataSource).datasourceid;

    this.logger.debug("newexecds: ", newExecDS);
    newExecDS = await this.xprojClient.RequestSaveProjectionExecutionDataSourceQuery(newExecDS);
    Exec.executionDS.push(newExecDS);
  }
  async RemoveExecutionDS(exec: any, execDS: any) {
    let doRemove = await this.modalService.ShowConfirmModalAsync({
      header: 'Remove Datasource Query',
      description: 'Remove Datasource Query, are you sure?',
      ok: 'Remove',
      cancel: 'Cancel'
    });
    if (doRemove) {
      let id = execDS.projectionexecutiondatasourcequeryid;
      let wasRemoved = await this.xprojClient.RequstRemoveConfigProjectionExecutionDataSourceQuery( id );
      if(wasRemoved)
      {
        exec.executionDS = exec.executionDS.filter(x => x.projectionexecutiondatasourcequeryid != id);
      }
      else
      {
        this.logger.error("ERROR: Could not remove DS");
      }
    }
  }
  async NewExecutionLMF(exec: any) {
    let Exec = exec as UIExecution;
    let newExecLMF = new ProjectionExecutionLuaMainFunction();
    newExecLMF.projectionexecutionid = Exec.execution.projectionexecutionid;
    newExecLMF.projectionexecutionluamainfunctionid = uuid.v4();
    newExecLMF.luascript = "";

    this.logger.debug("newExecLMF: ", newExecLMF);
    newExecLMF = await this.xprojClient.RequestSaveProjectionExecutionLuaMainfunction(newExecLMF);
    Exec.executionLMF.push(newExecLMF);
  }
  async RemoveExecutionLMF(exec: any, execLMF: any) {
    let doRemove = await this.modalService.ShowConfirmModalAsync({
      header: 'Remove Lua Main Function',
      description: 'Remove Lua Main Function, are you sure?',
      ok: 'Remove',
      cancel: 'Cancel'
    });
    if (doRemove) {
      let id = execLMF.projectionexecutionluamainfunctionid;
      let wasRemoved = await this.xprojClient.RequstRemoveConfigProjectionExecutionLuaMainFunction( id );
      if(wasRemoved)
      {
        exec.executionLMF = exec.executionLMF.filter(x => x.projectionexecutionluamainfunctionid != id);
        this.selectedExecutionLMF = null;
      }
      else
      {
        this.logger.error("ERROR: Could not remove LMF");
      }
    }
  }


  loadingDatasources = false;
  selectedDataSources = [];

  entrypoint = 0;
  entrypointdatasourceid = 'null';

  async LoadDataSources() {
    this.loadingDatasources = true;
    let t = await this.xprojClient.RequestListConfigDatasources(0, 100);
    this.loadingDatasources = false;
    for (let i = 0; i < t.length; i++) {
      this.Datasources.push(t[i]);
    }
  }

  selectedProjections : { projection : Projection,  scriptname : string }[] = [];
  selectedRemotes : { remotenodeid : string,  scriptname : string }[] = [];
  loadingProjections = false;

  async LoadProjections() {
    this.loadingProjections = true;
    let t = await this.xprojClient.RequestListConfigProjections(0, 100);
    this.loadingProjections = false;
    for (let i = 0; i < t.length; i++) {
      if (t[i].projectionid == this.projectionId)
        continue;
      this.Projections.push({projection : t[i], scriptname : t[i].name });
    }
  }

  getProjection(projid: string): {projection : Projection, scriptname : string} {
    for (let i = 0; i < this.Projections.length; i++)
    {
      if (this.Projections[i].projection.projectionid == projid)
        return this.Projections[i];
    }
    console.error("Cannot find projection", projid);
    return null;
  }
  getDatasource(datasourceid: string): DataSource {
    for (let i = 0; i < this.Datasources.length; i++)
      if (this.Datasources[i].datasourceid == datasourceid)
        return this.Datasources[i];
    return null;
  }
  async LoadInputDependencies() {
    if (!this.selectedProjection.dependencies) {
      this.selectedProjection.dependencies = new ProjectionDependencies();
    }

    if (!this.selectedProjection.dependencies.projections) {
      this.selectedProjection.dependencies.projections = new Array<ProjectionDependencyProjection>();
    }
    if (!this.selectedProjection.dependencies.datasourceids) {
      this.selectedProjection.dependencies.datasourceids = new Array<string>();
    }
    if (!this.selectedProjection.dependencies.globalluascriptids) {
      this.selectedProjection.dependencies.globalluascriptids = new Array<string>();
    }

    for (let i = 0; i < this.selectedProjection.dependencies.projections.length; i++) {
      let depprojid = this.selectedProjection.dependencies.projections[i].projectionid;
      let projectionWithScriptname = this.getProjection(depprojid);
      projectionWithScriptname.scriptname = this.selectedProjection.dependencies.projections[i].scriptname;
      this.selectedProjections.push(projectionWithScriptname);
    }
    for (let i = 0; i < this.selectedProjection.dependencies.datasourceids.length; i++) {
      let depdsid = this.selectedProjection.dependencies.datasourceids[i];
      this.selectedDataSources.push(this.getDatasource(depdsid));
    }
  }

  async LoadExecutions() {
    this.logger.info("Loading executions");
    this.Executions.length = 0;
    let execs = await this.xprojClient.RequestListConfigProjectionExecutions(this.projectionId, 0, 500);
    this.logger.info("Execs: ", execs);
    for (let i = 0; i < execs.length; i++) {
      let exec = execs[i];
      let newExec = new UIExecution();
      newExec.execution = exec;

      let execDSs = await this.xprojClient.RequestListConfigProjectionExecutionDataSourceQueries(exec["projectionexecutionid"], 0, 500);
      this.logger.info("execDS for " + exec["projectionexecutionid"], execDSs);
      for (let j = 0; j < execDSs.length; j++) {
        newExec.executionDS.push(execDSs[j]);
      }

      let execLMFs = await this.xprojClient.RequestListConfigProjectionExecutionLuaMainFunctions(exec["projectionexecutionid"], 0, 500);
      this.logger.info("execLMFs for " + exec["projectionexecutionid"], execLMFs);
      for (let j = 0; j < execLMFs.length; j++) {
        newExec.executionLMF.push(execLMFs[j]);
      }

      this.Executions.push(newExec);
    }
  }

  async SaveDependencyProjections() {
    this.selectedProjection.dependencies.projections.length = 0;
    for (let i = 0; i < this.selectedProjections.length; i++) {
      let projectiondep = new ProjectionDependencyProjection();
      projectiondep.projectionid = this.selectedProjections[i].projection.projectionid;
      projectiondep.scriptname = this.selectedProjections[i].scriptname;
      this.selectedProjection.dependencies.projections.push(projectiondep);
    }

    return await this.Save(false);
  }

  async SaveDependencyRemotes() {
    return await this.Save(false);
  }

  async AddManualRemoteNodeID()
  {
    if(this.ManualRemoteNodeID  && this.ManualRemoteNodeID!= ""){
      let newDep = new ProjectionDependencyRemote();
      newDep.remotenodeid = this.ManualRemoteNodeID;
      newDep.scriptname = this.ManualRemoteNodeID;
      this.selectedProjection.dependencies.remotes.push(newDep);

      return await this.Save(false);
    }
  }

  async SaveDependencyDatasources() {
    this.selectedProjection.dependencies.datasourceids.length = 0;
    for (let i = 0; i < this.selectedDataSources.length; i++) {
      this.selectedProjection.dependencies.datasourceids.push(this.selectedDataSources[i].datasourceid);
    }

    return await this.Save(false);
  }

  async LoadSchedulingQueues()
  {
    this.SchedulingQueues = await this.xprojClient.RequestListConfigSchedulingQueues();
    this.logger.info("schedulingques", this.SchedulingQueues);
  }

  async LoadDataPaths()
  {
    this.DataPaths = await this.xprojClient.RequestListConfigDataPaths();
    this.logger.info("datapaths", this.DataPaths);
  }

  async Start() {
    await this.LoadSchedulingQueues();
    await this.LoadDataPaths();
    await this.LoadDataSources();
    await this.LoadProjections();

    if (this.projectionId == 'new') {
      this.creatingNew = true;
      this.selectedProjection = new Projection();
      this.projectionId = uuid.v4();
      this.selectedProjection.projectionid = this.projectionId;
      return;
    }

    this.loadingProjection = true;
    this.selectedProjection = await this.xprojClient.RequestGetConfigProjection(this.projectionId);
    this.logger.info('got selectedprojection', this.selectedProjection);
    this.loadingProjection = false;

    await this.LoadInputDependencies();
    await this.LoadExecutions();
  }

  ngOnInit(): void {

    this.route.params.subscribe(params => {
      this.projectionId = params['id'];
      this.Start();
    });

  }

}
