import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
//import { BmsMeterAdminComponent } from '@features/bms/bms-admin/bms-meter-admin/bms-meter-admin.component';
import { SysAdminUserProfilesComponent } from '@xprojectorfeatures/sysadmin/components/sys-admin-user-profiles/sys-admin-user-profiles.component';
import { RossakerBmsAdminDashboardComponent } from './components/rossaker-bms-admin-dashboard/rossaker-bms-admin-dashboard.component';
import { RossakerBmsAdminDashboardsComponent } from './components/rossaker-bms-admin-dashboards/rossaker-bms-admin-dashboards.component';

import { RossakerBmsAdminWorkspaceComponent } from './components/rossaker-bms-admin-workspace/rossaker-bms-admin-workspace.component';
import { RossakerBmsCustomerImportComponent } from './components/rossaker-bms-customer-import/rossaker-bms-customer-import.component';
import { RossakerBmsCustomersAdminComponent } from './components/rossaker-bms-customers-admin/rossaker-bms-customers-admin.component';
import { RossakerBmsDataCollectorsComponent } from './components/rossaker-bms-data-collectors/rossaker-bms-data-collectors.component';
import { RossakerBmsOverviewComponent } from './components/rossaker-bms-overview/rossaker-bms-overview.component';
import { RossakerBmsRealestateViewComponent } from './components/rossaker-bms-realestate-view/rossaker-bms-realestate-view.component';
import { RossakerIiotCustomersAdminComponent } from './components/rossaker-iiot-customers-admin/rossaker-iiot-customers-admin.component';
import { RossakerBmsEventsComponent } from './components/rossaker-bms-admin-events/rossaker-bms-events/rossaker-bms-events.component';
import { RossakerBmsCustomerAddComponent } from './components/rossaker-bms-customer-add/rossaker-bms-customer-add.component';
import { RossakerBmsUsersComponent } from './components/rossaker-bms-users/rossaker-bms-users.component';
import { RossakerBmsSettingsComponent } from './components/rossaker-bms-settings/rossaker-bms-settings.component';

const rossakerBmsMetersModule = () => import('@features/rossaker-bms/rossaker-bms-meters/rossaker-bms-meters.module').then(x => x.RossakerBmsMetersModule);

const routes: Routes = [
  {
      path: '', component: RossakerBmsAdminWorkspaceComponent,
      children: [
          { path: 'customeroverview', component: RossakerBmsOverviewComponent },
          { path: 'customerbms', component: RossakerBmsCustomersAdminComponent },
          { path: 'customeriiot', component: RossakerIiotCustomersAdminComponent },
          { path: 'customerusers', component: RossakerBmsUsersComponent },
          { path: 'customersettings', component: RossakerBmsSettingsComponent },

          // { path: 'customers/:id/:module', component: BmsCustomersAdminComponent },
          // { path: 'customers/:id/:module/:nodeid/:nodelabel', component: BmsCustomersAdminComponent }
          { path: 'datacollectors', component: RossakerBmsDataCollectorsComponent },

          { path: 'customerrealestates', component: RossakerBmsRealestateViewComponent },

          { path: 'importcustomer', component: RossakerBmsCustomerImportComponent },
          { path: 'addcustomer', component: RossakerBmsCustomerAddComponent },

          { path: 'users', component: SysAdminUserProfilesComponent },
          { path: 'users/:searchform', component: SysAdminUserProfilesComponent },

          { path: 'insightdashboards', component: RossakerBmsAdminDashboardsComponent },
          { path: 'insightdashboards/:id', component: RossakerBmsAdminDashboardComponent },

          { path: 'meteradmin', loadChildren: rossakerBmsMetersModule },

          { path: 'events', component: RossakerBmsEventsComponent },
      ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RossakerBmsAdminRoutingModule { }
