import { RossakerLoRaWANClient } from './proto/xprojector.modulerossakerbms.lorawan.pbsc';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { StateService } from '@xprojectorcore/services/state-service';
import { ClearDownlinkQueueRequest, ClearDownlinkQueueResponse, ConfiguredDownlinkItem, ConfiguredDownlinkSequence, DownlinkInfo, DownlinkItem, EnqueueDownlinkItemRequest, EnqueueDownlinkItemResponse, EnqueueDownlinkSequenceRequest, EnqueueDownlinkSequenceResponse, ExportDownlinkInfosRequest, GetConfiguredDownlinkItemsRequest, GetConfiguredDownlinkSequencesRequest, GetDeviceRequest, GetDeviceResponse, GetDownlinkInfosRequest, GetDownlinkItemsRequest, GetDownlinkItemsResponse, GetGatewayRequest, GetGatewayResponse, UpdateDeviceRequest, UpdateDeviceResponse } from './proto/xprojector.modulerossakerbms.lorawan.pb';
import { Timestamp } from './proto/google/protobuf/timestamp.pb';
import { saveAs } from 'file-saver';
import { Device } from './proto/xprojector.modulerossakerbms.lorawan.pb';

@Injectable({
  providedIn: 'root'
})
export class RossakerXProjectorBmsLoRaWANClient implements OnInit, OnDestroy {

  constructor(
    private loRaWANClient: RossakerLoRaWANClient,
    private state: StateService) {
  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {

  }

  async enqueueDownlinkItem(customerId: string, nodeId : string, nodeTypeId : string, operator : string, downlinkItem : ConfiguredDownlinkItem) :  Promise<EnqueueDownlinkItemResponse> {
    let request: EnqueueDownlinkItemRequest = new EnqueueDownlinkItemRequest({
      customerId: customerId,
      nodeId: nodeId,
      nodeTypeId : nodeTypeId,
      downlinkItem : downlinkItem,
      operator : operator
    });

    return await this.loRaWANClient.enqueueDownlinkItem(request, this.state.metadata).toPromise();
  }

  async getConfiguredDownlinkItems(deviceType: string = '') :  Promise<ConfiguredDownlinkItem[]> {
    let request: GetConfiguredDownlinkItemsRequest = new GetConfiguredDownlinkItemsRequest({
      deviceType: deviceType,
    });

    var result = await this.loRaWANClient.getConfiguredDownlinkItems(request, this.state.metadata).toPromise();

    return result.downlinkItems;
  }

  async clearDownlinkQueue(customerId: string, nodeId : string, nodeTypeId : string, operator : string) :  Promise<ClearDownlinkQueueResponse> {
    let request: ClearDownlinkQueueRequest = new ClearDownlinkQueueRequest({
      customerId: customerId,
      nodeId: nodeId,
      nodeTypeId : nodeTypeId,
      operator : operator
    });

    return await this.loRaWANClient.clearDownlinkQueue(request, this.state.metadata).toPromise();
  }

  async getDownlinkItems(customerId: string, nodeId : string, nodeTypeId : string, operator : string) :  Promise<DownlinkItem[]> {
    let request: GetDownlinkItemsRequest = new GetDownlinkItemsRequest({
      customerId: customerId,
      nodeId: nodeId,
      nodeTypeId : nodeTypeId,
      operator : operator
    });

    var result = await this.loRaWANClient.getDownlinkItems(request, this.state.metadata).toPromise();

    return result.downlinkItems;
  }

  async getGateway(customerId: string, nodeId : string, nodeTypeId : string, operator : string, gatewayId: string, updateLocation : boolean, forceUpdateLocation : boolean) :  Promise<GetGatewayResponse> {
    let request: GetGatewayRequest = new GetGatewayRequest({
      customerId: customerId,
      nodeId: nodeId,
      nodeTypeId : nodeTypeId,
      operator : operator,
      gatewayId: gatewayId,
      updateLocation: updateLocation,
      forceUpdateLocation: forceUpdateLocation
    });

    return this.loRaWANClient.getGateway(request, this.state.metadata).toPromise();
  }

  async getDevice(customerId: string, nodeId : string, nodeTypeId : string, operator : string, devEui: string = '') :  Promise<Device> {
    let request: GetDeviceRequest = new GetDeviceRequest({
      customerId: customerId,
      nodeId: nodeId,
      nodeTypeId : nodeTypeId,
      operator : operator,
      devEui: devEui
    });

    let result = await this.loRaWANClient.getDevice(request, this.state.metadata).toPromise();

    return result.device;
  }

  async updateDevice(customerId: string, nodeId : string, nodeTypeId : string, operator : string, device: Device) :  Promise<UpdateDeviceResponse> {
    let request: UpdateDeviceRequest = new UpdateDeviceRequest({
      customerId: customerId,
      nodeId: nodeId,
      nodeTypeId : nodeTypeId,
      operator : operator,
      device : device
    });

    return await this.loRaWANClient.updateDevice(request, this.state.metadata).toPromise();
  }


  async getConfiguredDownlinkSequences(deviceType: string = '') :  Promise<ConfiguredDownlinkSequence[]> {
    let request: GetConfiguredDownlinkSequencesRequest = new GetConfiguredDownlinkSequencesRequest({
      deviceType: deviceType,
    });

    var result = await this.loRaWANClient.getConfiguredDownlinkSequences(request, this.state.metadata).toPromise();

    return result.downlinkSequences;
  }

  async enqueueDownlinkSequence(customerId: string, nodeId : string, nodeTypeId : string, operator : string,
        downlinkSequenceId : string, failedAfter : Date = null,  overrideTimeout : number = -1, sendEmail : boolean = false) :  Promise<EnqueueDownlinkSequenceResponse> {
    let request: EnqueueDownlinkSequenceRequest = new EnqueueDownlinkSequenceRequest({
      customerId: customerId,
      nodeId: nodeId,
      nodeTypeId : nodeTypeId,
      downlinkSequenceId : downlinkSequenceId,
      operator : operator,
      failedAfter : failedAfter ? Timestamp.fromDate(failedAfter) : null,
      overrideTimeout : overrideTimeout,
      sendEmail : sendEmail
    });

    return await this.loRaWANClient.enqueueDownlinkSequence(request, this.state.metadata).toPromise();
  }

  async getDownlinkInfos(customerId: string, nodeId : string, nodeTypeId : string, downlinkId : string, downlinkNodeLabel : string) :  Promise<DownlinkInfo[]> {
      let request: GetDownlinkInfosRequest = new GetDownlinkInfosRequest({
        customerId: customerId,
        nodeId: nodeId,
        nodeTypeId : nodeTypeId,
        downlinkId : downlinkId,
        downlinkNodeLabel : downlinkNodeLabel,
      });

      let result = await this.loRaWANClient.getDownlinkInfos(request, this.state.metadata).toPromise();

      return result.downlinkInfos;
  }

  async exportDownlinkInfos(customerId: string, nodeId : string, nodeTypeId : string, downlinkId : string, downlinkNodeLabel : string, filename : string) :  Promise<boolean> {
    return new Promise((resolve, reject) => {
      let request: ExportDownlinkInfosRequest = new ExportDownlinkInfosRequest({
        customerId: customerId,
        nodeId: nodeId,
        nodeTypeId : nodeTypeId,
        downlinkId : downlinkId,
        downlinkNodeLabel : downlinkNodeLabel,
        chunkSize : 1024 * 32
      });

      let dataChunks : Uint8Array[] = [];
      this.loRaWANClient.exportDownlinkInfos(request, this.state.metadata).subscribe(
        ((event : any) => {
          let datachunk : Uint8Array = event.data;
          if (datachunk) {
            dataChunks.push(datachunk);
          }
        }),
        ((error) => {
          reject(error);
        }),
        (() => {
          if (dataChunks.length > 0) {
            var blob = new Blob(dataChunks);
            saveAs(blob, filename + ".xlsx");

            resolve(true);
          }
          else {
            resolve(false);
          }
        })

      );
    });
  }
}
