<xproj-widget-config #widgetBaseConfig [dashboardConfig]="dashboardConfig" [config]="config" [widgets]="widgets"
  [gridSettings]="gridSettings" [showPreQuery]="true" (save)="onWidgetConfigSaved($event)"></xproj-widget-config>

<clr-accordion>
  <clr-accordion-panel>
    <clr-accordion-title>Table options</clr-accordion-title>
    <clr-accordion-content>
      <form clrForm>
        <clr-input-container>
          <label class="clr-col-12">Empty table text</label>
          <input clrInput class="clr-col-12" style="width: 85%;" placeholder="" name="emptytabletext"
            [(ngModel)]="widgetConfig.EmptyTableText" />
        </clr-input-container>
        <clr-input-container>
          <label class="clr-col-12">Rows per page options</label>
          <input clrInput class="clr-col-12" style="width: 85%;" placeholder="" name="rowsperpageoptionsstring"
            [(ngModel)]="rowsPerPageOptionsString" />
        </clr-input-container>
        <clr-input-container>
          <label class="clr-col-12">Default rows per page</label>
          <input clrInput class="clr-col-12" type="number" min="1" max="1000" step="1" placeholder="" name="defaultrowsperpage"
            [(ngModel)]="widgetConfig.DefaultRowsPerPage" />
        </clr-input-container>
        <clr-checkbox-container>
          <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox name="AutoClickFirstRow" [(ngModel)]="widgetConfig.AutoClickFirstRow" />
            <label>Auto click first row after load.</label>
          </clr-checkbox-wrapper>
        </clr-checkbox-container>
        <clr-checkbox-container>
          <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox name="compact" [(ngModel)]="widgetConfig.Compact" />
            <label>Compact table</label>
          </clr-checkbox-wrapper>
        </clr-checkbox-container>
        <clr-checkbox-container>
          <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox name="clientpagination" [(ngModel)]="widgetConfig.ClientPagination" />
            <label>Clientside pagination</label>
          </clr-checkbox-wrapper>
        </clr-checkbox-container>
        <clr-input-container *ngIf="widgetConfig.ClientPagination" >
          <label class="clr-col-12">Clientside pagination max items</label>
          <input clrInput class="clr-col-12" type="number" min="1" max="100000" step="1" placeholder="" name="clientpaginationmaxitems"
            [(ngModel)]="widgetConfig.ClientPaginationMaxItems" />
        </clr-input-container>
        <clr-checkbox-container>
          <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox name="multiselec" [(ngModel)]="widgetConfig.MultiSelect" />
            <label>Multi select</label>
          </clr-checkbox-wrapper>
        </clr-checkbox-container>
        <clr-input-container *ngIf="widgetConfig.MultiSelect" >
          <label class="clr-col-12">Multiselect max items</label>
          <input clrInput class="clr-col-12" type="number" min="1" max="100000" step="1" placeholder="" name="multiselectmaxitems"
            [(ngModel)]="widgetConfig.MultiSelectMaxItems" />
        </clr-input-container>
      </form>
    </clr-accordion-content>
  </clr-accordion-panel>
</clr-accordion>

<clr-accordion>
  <clr-accordion-panel *ngFor="let configQuery of widgetConfig.ConfigQueries; index as i">
    <clr-accordion-title>Query {{i+1}}</clr-accordion-title>
    <clr-accordion-content>
      <xproj-table-widget-query-config [widgetQuery]="configQuery" [projections]="projections" [inputParameters]="widgetConfig.InputParameters"
        [widgetConfig]="widgetConfig" [widgets]="widgets" (onQueryRemoved)="onQueryRemoved($event)">
      </xproj-table-widget-query-config>
    </clr-accordion-content>
  </clr-accordion-panel>
</clr-accordion>
<button type="button" class="btn btn-link" (click)="addConfigQuery();">
  <clr-icon shape="plus-circle"></clr-icon>
  Add query
</button>
<form clrForm>
  <clr-checkbox-container>
    <clr-checkbox-wrapper>
      <input type="checkbox" clrCheckbox name="MultiSeriesQuery" [(ngModel)]="widgetConfig.MultiseriesConfig.Enabled" />
      <label>Multi series query</label>
    </clr-checkbox-wrapper>
  </clr-checkbox-container>

  <div *ngIf="widgetConfig.MultiseriesConfig.Enabled">
    <clr-input-container
      *ngFor="let value of widgetConfig.MultiseriesConfig.JoinableColumns; let i = index; trackBy: indexTracker">
      <label>Join Column {{i}}</label>
      <input clrInput type="text" [(ngModel)]="widgetConfig.MultiseriesConfig.JoinableColumns[i]"
        name="Joinablecolumns_{{i}}" />
    </clr-input-container>
  </div>

  <br />

  <clr-accordion *ngIf="widgetConfig.MultiseriesConfig.Enabled">
    <clr-accordion-panel
      *ngFor="let scriptedcolumn of widgetConfig.MultiseriesConfig.ScriptedColumnsPostJoin; index as i">
      <clr-accordion-title>ScriptedColumn {{i+1}}</clr-accordion-title>
      <clr-accordion-content>
        <xproj-edit-lua-query-column [luaQueryColumn]="scriptedcolumn.Column" [unit]="scriptedcolumn.Unit"
          (onUnitChange)="scriptedcolumn.Unit=$event" (onQueryColumnRemoved)="onLuaQueryColumnRemoved($event)">
        </xproj-edit-lua-query-column>
      </clr-accordion-content>
    </clr-accordion-panel>
  </clr-accordion>
  <button type="button" class="btn btn-link" (click)="addScriptedColumnsPostAggregation();"
    *ngIf="widgetConfig.MultiseriesConfig.Enabled">
    <clr-icon shape="plus-circle"></clr-icon>
    Add scripted column
  </button>

</form>

<clr-accordion>
  <clr-accordion-panel *ngFor="let buttonConfig of widgetConfig.ButtonConfigs; index as i">
    <clr-accordion-title>Button {{i+1}}</clr-accordion-title>
    <clr-accordion-content>
      <form clrForm>
        <clr-input-container>
          <label>Name</label>
          <input clrInput style="width: 75%;" placeholder="" name="buttonConfigName_{{i}}"
            [(ngModel)]="buttonConfig.Name" />
        </clr-input-container>
      </form>

      <b>Lua script</b>
      <ngx-monaco-editor class="script-textarea" [options]="editorOptions" [(ngModel)]="buttonConfig.LuaScript">
      </ngx-monaco-editor>

      <button class="btn btn-danger-outline" (click)="removeButtonConfig(buttonConfig)">Remove button</button>
    </clr-accordion-content>
  </clr-accordion-panel>
</clr-accordion>
<button type="button" class="btn btn-link" (click)="addButtonConfig();">
  <clr-icon shape="plus-circle"></clr-icon>
  Add button
</button>
