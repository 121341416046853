<clr-modal [(clrModalOpen)]="showExportDialog">
  <h3 class="modal-title">Export dashboard</h3>
  <div class="modal-body">
    <form clrForm>
      <label>Input export filename:</label>
      <input clrInput placeholder="dashboardexport.json" name="input" [(ngModel)]="exportFilename" />
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="showExportDialog = false">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="showExportDialog = false; exportDashboard()">Ok</button>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="showImportDialog">
  <h3 class="modal-title">Import dashboard</h3>
  <div class="modal-body">
    <div class="dropzone">
      <ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)">
        <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
          Drop dashboard config to import. Will replace current config.
          <button type="button" (click)="openFileSelector()">Browse Files</button>
        </ng-template>
      </ngx-file-drop>
    </div>
    <div class="modal-footer"></div>
    <button type="button" class="btn btn-outline" (click)="showImportDialog = false">Close</button>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="showLoadParameters" *ngIf="dashboardConfig && dashboardConfig.DynamicParameters"
  clrModalSize="xl">
  <h3 class="modal-title">Event based parameters</h3>
  <div class="modal-body">

    <div class="limit-height" style="max-width:1200px;">
      <clr-datagrid [(clrDgSingleSelected)]="selectedEventBasedParameter" [clrDgRowSelection]="true">

        <clr-dg-column> Name </clr-dg-column>
        <clr-dg-column> Triggers on </clr-dg-column>
        <clr-dg-column> Description </clr-dg-column>


        <!-- todo: filter where param is of type ScriptedParametersOnEvent-->

        <clr-dg-row
          *ngFor="let param of dashboardConfig.DynamicParameters | xproj_filter_typeof:'ScriptedParametersOnEvent'"
          [clrDgItem]="param">
          <clr-dg-cell class="cellhideoverflow"> {{param.Name}} </clr-dg-cell>
          <clr-dg-cell class="cellhideoverflow"> {{param.OnEvent}} </clr-dg-cell>
          <clr-dg-cell class="cellhideoverflow"> {{param.Description}} </clr-dg-cell>

        </clr-dg-row>

        <!-- <clr-dg-footer>{{ dashboardConfig.DynamicParameters.length}} Dynamic parameters</clr-dg-footer> -->

      </clr-datagrid>
    </div>

    <button type="button" class="btn btn-link" (click)="AddLoadParameter()">
      <clr-icon shape="plus-circle"></clr-icon>
      Add parameter
    </button>
    <button *ngIf="selectedEventBasedParameter" type="button" class="btn btn-link"
      (click)="RemoveScriptedParameter(selectedEventBasedParameter); ">
      <clr-icon shape="minus-circle "></clr-icon>
      Remove parameter
    </button>

    <div *ngIf="selectedEventBasedParameter">

      <form clrForm>
        <clr-input-container>
          <label>Name </label>
          <input clrInput type="text" [(ngModel)]="selectedEventBasedParameter.Name" name="eventbasedparmaetername"
            size="35" />
        </clr-input-container>


        <clr-input-container>
          <label>Description </label>
          <input clrInput type="text" [(ngModel)]="selectedEventBasedParameter.Description"
            name="eventbasedparmaeterdescription" size="35" />
        </clr-input-container>

        <clr-select-container>
          <label>Trigger</label>
          <select clrSelect name="eventbasedparametertriggerson" [(ngModel)]="selectedEventBasedParameter.OnEvent">
            <option [ngValue]="DashboardEvent.INIT">Init</option>
            <!-- <option [ngValue]="DashboardEvent.PARAMETERS_LOADED">After parameters have loaded</option>
        <option [ngValue]="DashboardEvent.DATA_CHANGED">Data changed</option>
        <option [ngValue]="DashboardEvent.DATA_RETRIEVED">Data retrieved</option> -->
          </select>
        </clr-select-container>

        <div>
          <label for="editeventbasedparameterlua" class="clr-control-label clr-col-12 clr-col-md-4">Script</label>
          <div class="clr-control-container clr-col-md-10 clr-col-12">
            <ngx-monaco-editor style="height: 200px;" name="editeventbasedparameterlua" [options]="editorOptionsLua"
              [(ngModel)]="selectedEventBasedParameter.LuaScript">
            </ngx-monaco-editor>
          </div>
        </div>

        <button type="button" class="btn btn-link" (click)="lua.RunScriptedParameter(selectedEventBasedParameter); ">
          <clr-icon shape="play "></clr-icon>
          Run now
        </button>

      </form>

    </div>

    <hr />

    <div class="modal-footer">
      <button type="button" class="btn btn-outline" (click)="HideLoadParameters()">Close</button>
    </div>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="showLinkedParameters" *ngIf="dashboardConfig && dashboardConfig.DynamicParameters"
  clrModalSize="xl">
  <h3 class="modal-title">Linked parameters ?</h3>
  <div class="modal-body">

    <div class="limit-height linkedparameters_table">
      <clr-datagrid [(clrDgSingleSelected)]="selectedLinkedParameter"
        (clrDgSingleSelectedChange)="selectedLinkedParameterChanged($event)">

        <clr-dg-column> Name </clr-dg-column>
        <clr-dg-column> Triggers on </clr-dg-column>
        <clr-dg-column> Description </clr-dg-column>


        <!-- todo: filter where param is of type ScriptedParametersOnEvent-->

        <clr-dg-row
          *ngFor="let param of dashboardConfig.DynamicParameters | xproj_filter_typeof:'ScriptedParametersOnParameterChange'"
          [clrDgItem]="param">
          <clr-dg-cell class="cellhideoverflow"> {{param.Name}} </clr-dg-cell>
          <clr-dg-cell class="cellhideoverflow"> {{param.OnParameterChanged}} </clr-dg-cell>
          <clr-dg-cell class="cellhideoverflow"> {{param.Description}} </clr-dg-cell>

        </clr-dg-row>

        <!-- <clr-dg-footer>{{ dashboardConfig.DynamicParameters.length}} Dynamic parameters</clr-dg-footer> -->

      </clr-datagrid>
    </div>

    <button type="button" class="btn btn-link" (click)="AddLinkedParameter()">
      <clr-icon shape="plus-circle"></clr-icon>
      Add parameter
    </button>
    <button *ngIf="selectedLinkedParameter" type="button" class="btn btn-link"
      (click)="RemoveScriptedParameter(selectedLinkedParameter); ">
      <clr-icon shape="minus-circle "></clr-icon>
      Remove parameter
    </button>

    <div *ngIf="selectedLinkedParameter">

      <form clrForm>
        <clr-input-container>
          <label>Name </label>
          <input clrInput type="text" [(ngModel)]="selectedLinkedParameter.Name" name="linkedbasedparmaetername"
            size="35" />
        </clr-input-container>


        <clr-input-container>
          <label>Description </label>
          <input clrInput type="text" [(ngModel)]="selectedLinkedParameter.Description"
            name="linkedbasedparmaeterdescription" size="35" />
        </clr-input-container>


        <div class="limit-height linkedparameters_table">
          <clr-datagrid [(clrDgSelected)]="selectedLinkedParmaeterTriggerOn"
            (clrDgSelectedChange)="selectedLinkedParmaeterTriggerOnChanged($event)">

            <clr-dg-column> Name </clr-dg-column>
            <clr-dg-column> Type </clr-dg-column>

            <clr-dg-row *ngFor="let param of allParameters" [clrDgItem]="param">
              <clr-dg-cell class="cellhideoverflow"> {{param.name}} </clr-dg-cell>
              <clr-dg-cell class="cellhideoverflow"> {{param.datatype}} </clr-dg-cell>

            </clr-dg-row>

            <clr-dg-footer> Selected {{selectedLinkedParmaeterTriggerOn.length}} / {{allParameters.length}} Parameters
            </clr-dg-footer>

          </clr-datagrid>
        </div>

        <div>
          <label for="editlinkedbasedparameterlua" class="clr-control-label clr-col-12 clr-col-md-4">Script</label>
          <div class="clr-control-container clr-col-md-10 clr-col-12">
            <ngx-monaco-editor style="height: 200px;" name="editlinkedbasedparameterlua" [options]="editorOptionsLua"
              [(ngModel)]="selectedLinkedParameter.LuaScript">
            </ngx-monaco-editor>
          </div>
        </div>

        <button type="button" class="btn btn-link" (click)="lua.RunScriptedParameter(selectedLinkedParameter); ">
          <clr-icon shape="play "></clr-icon>
          Run now
        </button>

      </form>

    </div>

    <hr />

    <div class="modal-footer">
      <button type="button" class="btn btn-outline" (click)="HideLinkedParameters()">Close</button>
    </div>
  </div>
</clr-modal>

<div class="dashboard-editmenu">
  <div class="editheader" *ngIf="editMode && !selectedWidgetConfig" style="min-height: 8em;">
    <!--  && showDashboardSettings -->

    <clr-tabs>
      <clr-tab>
        <button clrTabLink id="linkHome" (click)="this.showSettings = false">Home</button>
        <clr-tab-content id="content1" *clrIfActive="true">

          <!-- <form class="clr-form clr-form-compact" *ngIf="dashboardConfig">
      <clr-input-container>
        <label>Name</label>
        <input clrInput type="text" name="dashboardname" [(ngModel)]="dashboardConfig.Id" />
      </clr-input-container>
    </form> -->

          <div style="padding-top:1em;">
            <div class="btn-group btn-outline">
              <button class="btn btn-sm" (click)="save(true)" [clrLoading]="savingDashboard">
                <!--[disabled]="!this.modified"-->
                <clr-icon shape="floppy" title="Save"></clr-icon>
                Save
              </button>
              <!-- <button class="btn btn-sm" (click)="addWidget('piechart')" disabled>
                <clr-icon shape="floppy" title="Save"></clr-icon>
                Save as
              </button> -->
            </div>
            <div class="btn-group btn-outline">
              <button class="btn btn-sm" (click)="showImportDialog = true;">
                <clr-icon shape="import" title="import"></clr-icon>
                Import
              </button>
              <button class="btn btn-sm" (click)="showExportDialog = true;">
                <clr-icon shape="export" title="export"></clr-icon>
                Export
              </button>
              <!-- <button class="btn btn-sm" (click)="addWidget('piechart')" disabled>
                <clr-icon shape="share" title="share"></clr-icon>
                Share
              </button> -->
            </div>
            <div class="btn-group btn-outline">
              <button class="btn btn-sm" (click)="copiedWidgetConfig=markedWidgetConfig"
                [disabled]="!markedWidgetConfig">
                <clr-icon shape="copy" title="Copy"></clr-icon>
                Copy
              </button>
              <button class="btn btn-sm" (click)="pasteWidgetConfig(copiedWidgetConfig)"
                [disabled]="!copiedWidgetConfig">
                <clr-icon shape="paste" title="Paste"></clr-icon>
                Paste
              </button>
            </div>
            <div class="btn-group btn-outline">
              <button class="btn btn-sm" (click)="showSettings=!showSettings;">
                <clr-icon shape="cog" title="Settings"></clr-icon>
                Settings
              </button>
            </div>
            <div class="btn-group btn-outline">
              <button class="btn btn-sm" (click)="resetDashboard()">
                <clr-icon shape="times" title="Reset"></clr-icon>
                Reset
              </button>
            </div>

          </div>
        </clr-tab-content>
      </clr-tab>

      <clr-tab>
        <button clrTabLink (click)="this.showSettings = false">Insert</button>
        <clr-tab-content *clrIfActive>

          <div style="padding-top:1em" *ngIf="inResponsiveEditingMode">
            <button class="btn btn-sm" (click)="addAllWidgetsToResponsive()">
              <clr-icon shape="install " title="Add all widgets to responsive layout"></clr-icon>
              Add all widgets
            </button>
            <button class="btn btn-sm" (click)="clearAllWidgetsInResponsive()">
              <clr-icon shape="remove " title="Clear this resolution"></clr-icon>
              Clear all widgets
            </button>
          </div>

          <div style="padding-top:1em" *ngIf="!inResponsiveEditingMode">

            <div class="btn-group btn-outline">
              <button class="btn btn-sm" (click)="addWidget('chart')" draggable="true"
                (dragstart)="insertDragStart($event, 'chart')">
                <clr-icon shape="line-chart" title="Chart"></clr-icon>
                Multi series chart
              </button>
              <button class="btn btn-sm" (click)="addWidget('spectrumanalyzer')" draggable="true"
                (dragstart)="insertDragStart($event, 'spectrumanalyzer')">
                <clr-icon shape="bell-curve" title="Spectrum analyzer"></clr-icon>
                Spectrum
              </button>
              <button class="btn btn-sm" (click)="addWidget('spc')" draggable="true"
                (dragstart)="insertDragStart($event, 'spc')">
                <clr-icon shape="line-chart" title="SPC"></clr-icon>
                SPC
              </button>
            </div>

            <div class="btn-group btn-outline">
              <button class="btn btn-sm" (click)="addWidget('piechart')" draggable="true"
                (dragstart)="insertDragStart($event, 'piechart')">
                <clr-icon shape="pie-chart" title="Pie chart"></clr-icon>
                Pie
              </button>
              <button class="btn btn-sm" (click)="addWidget('barchart')" draggable="true"
                (dragstart)="insertDragStart($event, 'barchart')">
                <clr-icon shape="bar-chart" title="Bar chart"></clr-icon>
                Bars
              </button>
            </div>

            <div class="btn-group btn-outline">
              <button class="btn btn-sm" (click)="addWidget('label')" draggable="true"
                (dragstart)="insertDragStart($event, 'label')">
                <clr-icon shape="text-color" title="Label"></clr-icon>
                Label
              </button>
              <button class="btn btn-sm" (click)="addWidget('text')" draggable="true"
                (dragstart)="insertDragStart($event, 'text')">
                <clr-icon shape="text" title="Text"></clr-icon>
                Text
              </button>
              <button class="btn btn-sm" (click)="addWidget('table')" draggable="true"
                (dragstart)="insertDragStart($event, 'table')">
                <clr-icon shape="table" title="Table"></clr-icon>
                Table
              </button>
            </div>

            <div class="btn-group btn-outline">
              <button class="btn btn-sm" (click)="addWidget('map')" draggable="true"
                (dragstart)="insertDragStart($event, 'map')">
                <clr-icon shape="map" title="Map"></clr-icon>
                Map
              </button>
              <button class="btn btn-sm" (click)="addWidget('svg')" draggable="true"
                (dragstart)="insertDragStart($event, 'svg')">
                <clr-icon shape="image" title="SVG"></clr-icon>
                SVG
              </button>
              <button class="btn btn-sm" (click)="addWidget('scripted3d')" draggable="true"
                (dragstart)="insertDragStart($event, 'scripted3d')">
                <clr-icon shape="heat-map" title="Scripted 3D"></clr-icon>
                Scripted 3D
              </button>

            </div>

            <!-- <hr style="border: none;"/> -->

            <div class="btn-group btn-outline">
              <button class="btn btn-sm" (click)="addWidget('master')" draggable="true"
                (dragstart)="insertDragStart($event, 'master')">
                Master widget
              </button>
              <button class="btn btn-sm" (click)="addWidget('output')" draggable="true"
                (dragstart)="insertDragStart($event, 'output')">
                <clr-icon shape="cog" title="Parameters"></clr-icon>
                Controller
              </button>
              <button class="btn btn-sm" (click)="addWidget('container')" draggable="true"
                (dragstart)="insertDragStart($event, 'container')">
                <clr-icon shape="container" title="Parameters"></clr-icon>
                Container
              </button>
            </div>

            <div class="btn-group btn-outline">
              <button class="btn btn-sm" (click)="addWidget('projectiondataeditor')" draggable="true"
                (dragstart)="insertDragStart($event, 'projectiondataeditor')">
                Data editor widget
              </button>
            </div>
          </div>
        </clr-tab-content>
      </clr-tab>

      <clr-tab>
        <button clrTabLink (click)="this.showSettings = false">Layout</button>
        <clr-tab-content *clrIfActive>
          <div style="padding-top:1em; font-size: 12px;">

            <div style="display: inline-grid;">
              <label>Gridtype</label>
              <select class="clr-select" id="gridtype" name="gridtype" [(ngModel)]="dashboardConfig.Grid.GridType"
                (ngModelChange)="refreshToolbox()" style="margin-top: 0.2em;">
                <option [ngValue]="GridType.Fit"> Fit </option>
                <option [ngValue]="GridType.ScrollVertical"> ScrollVertical </option>
                <option [ngValue]="GridType.ScrollHorizontal"> ScrollHorizontal </option>
                <option [ngValue]="GridType.Fixed"> Fixed </option>
                <option [ngValue]="GridType.VerticalFixed"> VerticalFixed </option>
                <option [ngValue]="GridType.HorizontalFixed"> HorizontalFixed </option>
              </select>
            </div>

            <div style="display: inline-grid; margin-left: 2em;">
              <label>Compact</label>
              <select class="clr-select" id="compacttype" name="compacttype" style="margin-top: 0.2em;"
                [(ngModel)]="dashboardConfig.Grid.CompactType" (ngModelChange)="refreshToolbox()">
                <option [ngValue]="CompactType.None"> None </option>
                <option [ngValue]="CompactType.CompactUp"> CompactUp </option>
                <option [ngValue]="CompactType.CompactLeft"> CompactLeft </option>
                <option [ngValue]="CompactType.CompactUpAndLeft"> CompactUpAndLeft </option>
                <option [ngValue]="CompactType.CompactLeftAndUp"> CompactLeftAndUp </option>
                <option [ngValue]="CompactType.CompactRight"> CompactRight </option>
                <option [ngValue]="CompactType.CompactUpAndRight"> CompactUpAndRight </option>
                <option [ngValue]="CompactType.CompactRightAndUp"> CompactRightAndUp </option>
              </select>
            </div>


            <div style="display: inline-grid; margin-left: 2em;">
              <label>Paste</label>
              <div class="clr-checkbox-container" style="display: inline-flex">
                <input type="checkbox" clrCheckbox name="enablepasteingrid" [(ngModel)]="enablePasteInGrid"
                  (ngModelChange)="refreshToolbox()" />
                <label style="margin-right: 0.5em;">Enable</label>
              </div>
            </div>

            <div style="display: inline-grid; margin-left: 2em;">
              <label>Push directions</label>
              <div class="clr-checkbox-container" style="display: inline-flex">
                <input type="checkbox" clrCheckbox name="pushnorth" [(ngModel)]="dashboardConfig.Grid.PushNorth"
                  (ngModelChange)="refreshToolbox()" />
                <label style="margin-right: 0.5em;">North </label>
                <input type="checkbox" clrCheckbox name="pusheast" [(ngModel)]="dashboardConfig.Grid.PushEast"
                  (ngModelChange)="refreshToolbox()" />
                <label style="margin-right: 0.5em;">East</label>
                <input type="checkbox" clrCheckbox name="pushsouth" [(ngModel)]="dashboardConfig.Grid.PushSouth"
                  (ngModelChange)="refreshToolbox()" />
                <label style="margin-right: 0.5em;">South </label>
                <input type="checkbox" clrCheckbox name="pushwest" [(ngModel)]="dashboardConfig.Grid.PushWest"
                  (ngModelChange)="refreshToolbox()" />
                <label>West</label>
              </div>
              <!-- <div class="clr-checkbox-container" style="display: inline-flex;">
            <input type="checkbox" clrCheckbox name="pushsouth" [(ngModel)]="dashboardConfig.Grid.PushSouth"
              (ngModelChange)="refreshToolbox()" />
            <label>South </label>
            <input type="checkbox" clrCheckbox name="pushwest" [(ngModel)]="dashboardConfig.Grid.PushWest"
              (ngModelChange)="refreshToolbox()" />
            <label>West</label>
          </div> -->
            </div>

            <div style="display: inline-grid; margin-left: 2em;">
              <label style="font-size: 10px;">Grid</label>
              <select class="clr-select" id="displaygrid" name="displaygrid" [(ngModel)]="gridoptions.displayGrid"
                (ngModelChange)="refreshToolbox()" style="margin-top: 0.2em;">
                <option [ngValue]="DisplayGrid.Always"> Always </option>
                <option [ngValue]="DisplayGrid.OnDragAndResize"> On Drag & Resize </option>
                <option [ngValue]="DisplayGrid.None"> None </option>
              </select>
            </div>

            <div class="signpost-item" style="display: inline-grid; margin-left: 2em;">
              <label>Sidenav</label>
              <clr-signpost>
                <clr-icon shape="view-columns" class="is-solid has-badge-info" size="24" clrSignpostTrigger>
                </clr-icon>
                <clr-icon shape="angle" class="rotate_180" size="16" clrSignpostTrigger>
                </clr-icon>
                <clr-signpost-content [clrPosition]="'bottom-middle'" *clrIfOpen>
                  <form clrForm clrLayout="vertical">
                    <clr-checkbox-container>
                      <clr-checkbox-wrapper>
                        <input type="checkbox" clrCheckbox name="ShowSideNav"
                          [(ngModel)]="dashboardConfig.Grid.ShowSideNav" (ngModelChange)="refreshToolbox()" />
                        <label>Show sidenav</label>
                      </clr-checkbox-wrapper>
                    </clr-checkbox-container>
                    <clr-input-container>
                      <label>Sidenav width (px)</label>
                      <input clrInput placeholder=200 name="SideNavWidth" type="number"
                        [(ngModel)]="dashboardConfig.Grid.SideNavWidth" (ngModelChange)="refreshToolbox()" />
                    </clr-input-container>
                    <clr-select-container>
                      <label>Sidenav position</label>
                      <select clrSelect id="SideNavPosition" name="SideNavPosition"
                        [(ngModel)]="dashboardConfig.Grid.SideNavPosition" (ngModelChange)="refreshToolbox()">
                        <option [ngValue]="SideNavPosition.LEFT"> Left </option>
                        <option [ngValue]="SideNavPosition.RIGHT"> Right </option>
                        <option [ngValue]="SideNavPosition.TOP"> Top </option>
                        <option [ngValue]="SideNavPosition.BOTTOM"> Bottom </option>
                      </select>
                    </clr-select-container>
                    <clr-combobox-container>
                      <label>Sidenav widgets</label>
                      <clr-combobox [(ngModel)]="sidenavConfigs" name="sidenavSelect" clrMulti="true"
                        (ngModelChange)="refreshToolbox()">
                        <ng-container *clrOptionSelected="let widget">
                          {{widget.Title?.length > 0 ? widget.Title : widget.Id}}
                        </ng-container>
                        <clr-options>
                          <clr-option *clrOptionItems="let widget of dashboardConfig.WidgetConfigs" [clrValue]="widget">
                            {{widget.Title?.length > 0 ? widget.Title : widget.Id}}
                          </clr-option>
                        </clr-options>
                      </clr-combobox>
                    </clr-combobox-container>
                  </form>
                </clr-signpost-content>
              </clr-signpost>
            </div>

            <div class="signpost-item" style="display: inline-grid; margin-left: 1em;">
              <label>Layers</label>
              <clr-signpost>
                <clr-icon shape="layers" class="is-solid has-badge-info" size="24" clrSignpostTrigger>
                </clr-icon>
                <clr-icon shape="angle" class="rotate_180" size="16" clrSignpostTrigger></clr-icon>
                <clr-signpost-content [clrPosition]="'bottom-middle'" *clrIfOpen>
                  <form clrForm clrLayout="vertical">
                    <clr-checkbox-container>
                      <clr-checkbox-wrapper>
                        <input type="checkbox" clrCheckbox name="allowmultilayer"
                          [(ngModel)]="dashboardConfig.Grid.AllowMultiLayer" (ngModelChange)="refreshToolbox()" />
                        <label>Allow multi layer</label>
                      </clr-checkbox-wrapper>
                    </clr-checkbox-container>
                    <clr-input-container>
                      <label>Default layer index</label>
                      <input clrInput placeholder="1" name="defaultlayerindex" type="number"
                        [(ngModel)]="dashboardConfig.Grid.DefaultLayerIndex" (ngModelChange)="refreshToolbox()" />
                    </clr-input-container>
                    <clr-input-container>
                      <label>Max layer index</label>
                      <input clrInput placeholder="2" name="maxlayerindex" type="number"
                        [(ngModel)]="dashboardConfig.Grid.MaxLayerIndex" (ngModelChange)="refreshToolbox()" />
                    </clr-input-container>
                    <clr-input-container>
                      <label>Base layer index</label>
                      <input clrInput placeholder="1" name="baselayerindex" type="number"
                        [(ngModel)]="dashboardConfig.Grid.BaseLayerIndex" (ngModelChange)="refreshToolbox()" />
                    </clr-input-container>
                  </form>
                </clr-signpost-content>
              </clr-signpost>
            </div>

            <div class="signpost-item" style="display: inline-grid; margin-left: 1em;">
              <label>Color</label>
              <clr-signpost>
                <clr-icon shape="color-palette" class="is-solid has-badge-info" size="24" clrSignpostTrigger>
                </clr-icon>
                <clr-icon shape="angle" class="rotate_180" size="16" clrSignpostTrigger></clr-icon>
                <clr-signpost-content [clrPosition]="'bottom-middle'" *clrIfOpen>
                  <form clrForm clrLayout="vertical">
                    <clr-input-container>
                      <label>Background color</label>
                      <input clrInput placeholder="transparent" name="backgroundcolor" type="text"
                        [(ngModel)]="dashboardConfig.Grid.BackgroundColor" (ngModelChange)="refreshToolbox()" />
                      <input clrInput placeholder="transparent" name="backgroundcolor" type="color"
                        [(ngModel)]="dashboardConfig.Grid.BackgroundColor" style="margin-left: 1em;"
                        (ngModelChange)="refreshToolbox()" />
                    </clr-input-container>

                    <clr-input-container>
                      <label>Background image</label>
                      <input clrInput placeholder="" name="backgroundimage" type="text"
                        [(ngModel)]="dashboardConfig.Grid.BackgroundImage" (ngModelChange)="refreshToolbox()" />

                    </clr-input-container>           
                    
                    <clr-input-container>
                      <label>Foreground color</label>
                      <input clrInput placeholder="" name="foregroundcolor" type="text"
                        [(ngModel)]="dashboardConfig.Grid.Color" (ngModelChange)="refreshToolbox()" />
                      <input clrInput placeholder="" name="foregroundcolor" type="color"
                        [(ngModel)]="dashboardConfig.Grid.Color" style="margin-left: 1em;"
                        (ngModelChange)="refreshToolbox()" />
                    </clr-input-container>                    
                  </form>
                </clr-signpost-content>
              </clr-signpost>
            </div>

            <div class="signpost-item" style="display: inline-grid; margin-left: 1em;">
              <label>Grid</label>
              <clr-signpost>
                <clr-icon shape="grid-view" class="is-solid has-badge-info" size="24" clrSignpostTrigger>
                </clr-icon>
                <clr-icon shape="angle" class="rotate_180" size="16" clrSignpostTrigger></clr-icon>
                <clr-signpost-content [clrPosition]="'bottom-middle'" *clrIfOpen>
                  <form clrForm clrLayout="vertical">
                    <clr-input-container>
                      <label>Margin</label>
                      <input clrInput placeholder="8" name="margin" type="number"
                        [(ngModel)]="dashboardConfig.Grid.Margin" (ngModelChange)="refreshToolbox()" />
                    </clr-input-container>
                    <clr-input-container>
                      <label>MinCols</label>
                      <input clrInput placeholder="0" name="mincols" type="number"
                        [(ngModel)]="dashboardConfig.Grid.MinCols" (ngModelChange)="refreshToolbox()" />
                    </clr-input-container>
                    <clr-input-container>
                      <label>MaxCols</label>
                      <input clrInput placeholder="0" name="maxcols" type="number"
                        [(ngModel)]="dashboardConfig.Grid.MaxCols" (ngModelChange)="refreshToolbox()" />
                    </clr-input-container>
                    <clr-input-container>
                      <label>MinRows</label>
                      <input clrInput placeholder="0" name="minrows" type="number"
                        [(ngModel)]="dashboardConfig.Grid.MinRows" (ngModelChange)="refreshToolbox()" />
                    </clr-input-container>
                    <clr-input-container>
                      <label>MaxRows</label>
                      <input clrInput placeholder="0" name="maxrows" type="number"
                        [(ngModel)]="dashboardConfig.Grid.MaxRows" (ngModelChange)="refreshToolbox()" />
                    </clr-input-container>
                    <clr-input-container>
                      <label>FixedColWidth</label>
                      <input clrInput placeholder="0" name="fixedcolwidth" type="number"
                        [(ngModel)]="dashboardConfig.Grid.FixedColWidth" (ngModelChange)="refreshToolbox()" />
                    </clr-input-container>
                    <clr-input-container>
                      <label>FixedRowHeight</label>
                      <input clrInput placeholder="0" name="fixedrowheight" type="number"
                        [(ngModel)]="dashboardConfig.Grid.FixedRowHeight" (ngModelChange)="refreshToolbox()" />
                    </clr-input-container>
                    <clr-input-container>
                      <label>ScrollSensitivity</label>
                      <input clrInput placeholder="0" name="scrollsensitivity" type="number"
                        [(ngModel)]="dashboardConfig.Grid.ScrollSensitivity" (ngModelChange)="refreshToolbox()" />
                    </clr-input-container>
                    <clr-input-container>
                      <label>ScrollSpeed</label>
                      <input clrInput placeholder="0" name="scrollspeed" type="number"
                        [(ngModel)]="dashboardConfig.Grid.ScrollSpeed" (ngModelChange)="refreshToolbox()" />
                    </clr-input-container>
                    <clr-checkbox-container>
                      <clr-checkbox-wrapper>
                        <input type="checkbox" clrCheckbox name="disablewindowresize"
                          [(ngModel)]="dashboardConfig.Grid.DisableWindowResize" (ngModelChange)="refreshToolbox()" />
                        <label>Disable Window Resize</label>
                      </clr-checkbox-wrapper>
                    </clr-checkbox-container>
                  </form>
                </clr-signpost-content>
              </clr-signpost>
            </div>

            <div class="signpost-item" style="display: inline-grid; margin-left: 1em;">
              <label>Settings</label>
              <clr-signpost>
                <clr-icon shape="cog" class="is-solid" size="24" (click)="showSettings=!showSettings;"
                  clrSignpostTrigger>
                </clr-icon>
                <!-- <clr-signpost-content [clrPosition]="'bottom-middle'" *clrIfOpen>
            </clr-signpost-content> -->
              </clr-signpost>
            </div>

            <!-- <button class="btn" (click)="showSettings=true;" style="margin-left: 2em; vertical-align: bottom;">
          <clr-icon shape="cog" title="Settings"></clr-icon>
          All
        </button> -->

          </div>
        </clr-tab-content>
      </clr-tab>

      <clr-tab>
        <button clrTabLink (click)="this.showSettings = false" id="linkScripting">Scripting</button>
        <clr-tab-content id="content1" *clrIfActive>

          <div style="padding-top:1em;">
            <div class="btn-group  btn-outline">
              <button class="btn btn-sm" (click)="ShowLoadParameters()">
                <!--[disabled]="!this.modified"-->
                <clr-icon shape="install" title="Event based parameters"></clr-icon>
                Event based parameters
              </button>
              <button class="btn btn-sm" (click)="ShowLinkedPrameters()">
                <clr-icon shape="recycle" title="Linked parameters"></clr-icon>
                Linked parameters
              </button>
            </div>

          </div>
        </clr-tab-content>
      </clr-tab>

      <clr-tab>
        <button clrTabLink (click)="this.showSettings = false" id="linkResponsive">Responsiveness</button>
        <clr-tab-content id="content1" *clrIfActive>

          <!-- <div style="padding-top:1em;">
                        <div class="btn-group  btn-outline">
                            <button class="btn btn-sm" (click)="ShowLoadParameters()">

          <clr-icon shape="install" title="Event based parameters"></clr-icon>
          Event based parameters
        </button>
                            <button class="btn btn-sm" (click)="ShowLinkedPrameters()">
          <clr-icon shape="recycle" title="Linked parameters"></clr-icon>
          Linked parameters
        </button>
                        </div>

                    </div> -->

          <div style="display: inline-grid; margin-left: 2em;">
            <label>Target resolution / device</label>
            <select class="clr-select" id="responsivetarget" name="responsivetarget" style="margin-top: 0.5em;"
              [(ngModel)]="targetResolution" (ngModelChange)="refreshResolution()">
              <option [ngValue]="Resolutions.Sandbox"> Full </option>
              <option [ngValue]="Resolutions.Mobile"> Mobile </option>
              <option [ngValue]="Resolutions.HD"> HD </option>
              <option [ngValue]="Resolutions.QHD"> QHD </option>
              <option [ngValue]="Resolutions.UHD"> 4K </option>
              <option [ngValue]="Resolutions.IPad"> iPad </option>
            </select>
          </div>
          <div style="display: inline-grid; margin-left: 2em;">
            <label>Reset reponsitivity</label>
            <button class="btn btn-sm" (click)="clearAllResponsitivity()">
              <clr-icon shape="remove " title="Clear all responsitivity"></clr-icon>
              Clear all responsitivity
            </button>
          </div>

        </clr-tab-content>
      </clr-tab>


    </clr-tabs>

  </div>

  <div class="dashboard-settings"
    *ngIf="false && editMode && showDashboardSettings && !selectedWidgetConfig && !showSettings">
    <clr-dropdown>
      <button class="dropdown-toggle" clrDropdownTrigger>
        <clr-icon shape="cog" size="24"></clr-icon>
        <clr-icon shape="caret down"></clr-icon>
      </button>
      <clr-dropdown-menu>
        <!-- <h4 class="dropdown-header">Widgets config</h4> -->
        <div clrDropdownItem (click)="addWidget('chart')">Add chart widget</div>
        <div clrDropdownItem (click)="addWidget('label')">Add label widget</div>
        <div clrDropdownItem (click)="addWidget('output')">Add output widget</div>
        <div clrDropdownItem (click)="addWidget('piechart')">Add piechart widget</div>
        <div clrDropdownItem (click)="addWidget('map')">Add map widget</div>
        <div clrDropdownItem (click)="addWidget('master')">Add master widget</div>
        <div clrDropdownItem (click)="addWidget('spectrumanalyzer')">Add spectrum analyzer widget</div>
        <div clrDropdownItem (click)="addWidget('spc')">Add Spc widget</div>
        <div clrDropdownItem (click)="addWidget('svg')">Add svg widget</div>
        <div clrDropdownItem (click)="addWidget('scripted3d')">Add Scripted3d widget</div>
        <div clrDropdownItem (click)="addWidget('table')">Add table widget</div>
        <div clrDropdownItem (click)="addWidget('text')">Add text widget</div>
        <div class="dropdown-divider"></div>
        <div clrDropdownItem (click)="showSettings=true">Settings</div>
      </clr-dropdown-menu>
    </clr-dropdown>
  </div>

  <span *ngIf="selectedWidgetConfig" class="close-selected-widget" (click)="onEditClose($event)">Close <clr-icon  shape="window-close" size="36"
    ></clr-icon></span>


  <!-- <clr-icon *ngIf="showSettings" shape="window-close" size="24" class="settings-close" (click)="onSettingsClose($event);">
</clr-icon> -->


</div>
<div *ngIf="showSettings">
  <xproj-dashboard-config [config]="dashboardConfig" (onClose)="onSettingsClose($event)"
    (onSelectWidget)="onSelectWidget($event)"></xproj-dashboard-config>
</div>

<div class="dashboard-area" onresize="onResizeDashboard($event)">
  <a id="download-image" download="ChartImage.jpg"></a>

  <as-split #mainview direction="horizontal" [unit]="'pixel'" *ngIf="!(showSettings || viewFullScreen)" [gutterSize]="7"
    (dragEnd)="onSplitDragEnd($event)" class="dashboard-view">

    
    <as-split-area [order]="1" [visible]="selectedWidgetConfig && !editFullScreen">
      <as-split [direction]="dashboardConfig?.Grid.SideNavPosition <= SideNavPosition.RIGHT ? 'horizontal' : 'vertical'"
        [unit]="'pixel'" [gutterSize]="7" [useTransition]="true" (dragEnd)="onSplitDragEnd($event)">
        <as-split-area
          [order]="(dashboardConfig?.Grid.SideNavPosition == SideNavPosition.RIGHT || dashboardConfig?.Grid.SideNavPosition == SideNavPosition.BOTTOM) ? 1 : 2">
          <div class="dashboard-layout" [ngStyle]="dashboardStyle">            
            <gridster #grid id="grid" [options]="gridoptions" *ngIf="dashboardConfig"
    
              [style.background]="gridBackgroundColor==''?'transparent':gridBackgroundColor"
              [style.background-image]="gridBackgroundImage==''?'':'url(' + gridBackgroundImage + ')'"
              [style.background-size]="'contain'"
              [style.color]="gridColor"
               spellcheck="false" style="caret-color: transparent;padding: 0px !important;" tabindex="0"
              (paste)="onPasteWidgetInsert($event)">
              <gridster-item [item]="widgetConfig" *ngFor="let widgetConfig of gridWidgetConfigs"
                [style]="setGridItemStyle(widgetConfig)" style="border: 3px solid !important;">
                <div class="clr-row" [style]="(widgetConfig.Borderless&&!editMode)?'': 'min-height: 24px;'">
                  <div [class]="editMode ? 'widget-move clr-col-2' : 'clr-col-2'"></div>

                  <div class="widget-header gridster-item-content clr-col-8"
                    [class]="widgetConfig == markedWidgetConfig ? 'widget-header-marked' : ''" *ngIf="(editMode||!widgetConfig.Borderless)">
                    <div style="min-height: 1em;">
                      <b contenteditable="editMode" (paste)="onPasteWidgetReplace($event, widgetConfig)"
                        (copy)="onCopyWidget($event, widgetConfig)">
                        <!-- <clr-icon *ngIf="editMode" class="edit-widget" shape="copy-to-clipboard" size="16"
                        (click)="onCopyWidgetClick(widgetConfig)"></clr-icon> -->

                        <clr-icon *ngIf="inResponsiveEditingMode" class="view-widget" shape="trash" size="16"
                          (click)="removeWidgetFromResponsive(widgetConfig)">
                        </clr-icon>

                        <clr-icon *ngIf="viewFullScreenEnabled && widgetConfig?.FullscreenEnabled" class="view-widget" shape="eye" size="16" (click)="onViewWidgetClick(widgetConfig)">
                        </clr-icon>
                        <clr-icon *ngIf="editMode && !inResponsiveEditingMode" class="edit-widget" shape="cog" size="16"
                          (click)="onEditWidgetClick(widgetConfig)"></clr-icon>
                        <clr-signpost *ngIf="widgetConfig?.Tooltip">
                          <clr-signpost-content [clrPosition]="'bottom-middle'" *clrIfOpen>
                            {{ widgetConfig.Tooltip }}
                          </clr-signpost-content>
                        </clr-signpost>

                        <span (click)="onMarkWidgetClick(widgetConfig)"
                          [class]="((widgetConfig.IsSensitive||widgetConfig.TitleIsSensitive)?'sensitive ': ' ') + ((widgetConfig.ShowTitle || widgetConfig == markedWidgetConfig) ? 'title-widget-show' : editMode ? 'title-widget-edit-hide' : 'title-widget-hide')">
                          {{widgetConfig.DisplayTitle.length
                          > 0 ? widgetConfig.DisplayTitle : ' '}}</span>
                      </b>
                    </div>
                  </div>
                  <div [class]="editMode ? 'widget-move clr-col-1' : 'clr-col-1'"></div>
                  <div class="gridster-item-content clr-col-1" *ngIf="!widgetConfig.Borderless"></div>
                  <div class="gridster-item-content" *ngIf="!widgetConfig.Borderless"></div>
                  <div class="gridster-item-content export-widget">
                    <clr-dropdown
                      *ngIf="enableExport && widgetConfig.ExportEnabled && (widgetConfig.CanExportToImage || widgetConfig.CanExportToExcel)"
                      [clrCloseMenuOnItemClick]="true">
                      <button clrDropdownTrigger aria-label="Export button">
                        <clr-icon shape="export" size="16"></clr-icon>
                      </button>
                      <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
                        <div *ngIf="widgetConfig.CanExportToImage" aria-label="Export jpg" clrDropdownItem>
                          <a (click)="onExportWidgetClick(widgetConfig, 'image')">
                            Export image
                          </a>
                        </div>
                        <div *ngIf="widgetConfig.CanExportToExcel" aria-label="Export excel" clrDropdownItem
                          (click)="onExportWidgetClick(widgetConfig, 'queries')">Export excel</div>
                      </clr-dropdown-menu>
                    </clr-dropdown>
                  </div>
                </div>
                <div class="gridster-item-content" style="caret-color: auto"
                  [class]="widgetConfig.IsSensitive?'sensitive':''">
                  <xproj-widget [config]="widgetConfig" [responsive]="true" [zoom]="true" [editMode]="editMode"
                    [dashboardConfig]="dashboardConfig" (onBeforeInit)="onBeforeWidgetInit($event)"
                    (onAfterInit)="onAfterWidgetInit($event)">
                  </xproj-widget>
                </div>

              </gridster-item>
            </gridster>
          </div>
        </as-split-area>
        <as-split-area *ngIf="dashboardConfig?.Grid.ShowSideNav && sidenavConfigs?.length > 0"
          [size]="dashboardConfig.Grid.SideNavWidth" style="position: relative;"
          [order]="(dashboardConfig?.Grid.SideNavPosition == SideNavPosition.RIGHT || dashboardConfig?.Grid.SideNavPosition == SideNavPosition.BOTTOM) ? 2 : 1">
          <clr-icon *ngIf="dashboardConfig?.Grid.SideNavPosition == SideNavPosition.LEFT" shape="angle-double" size="24"
            [class]="sidenavCollapsed ? 'sidenav-collapsed-left' : 'sidenav-expanded-left'" (click)="toogleNav();">
          </clr-icon>
          <clr-icon *ngIf="dashboardConfig?.Grid.SideNavPosition == SideNavPosition.RIGHT" shape="angle-double"
            size="24" [class]="sidenavCollapsed ? 'sidenav-collapsed-right' : 'sidenav-expanded-right'"
            (click)="toogleNav();">
          </clr-icon>
          <clr-icon *ngIf="dashboardConfig?.Grid.SideNavPosition == SideNavPosition.TOP" shape="angle-double" size="24"
            [class]="sidenavCollapsed ? 'sidenav-collapsed-top' : 'sidenav-expanded-top'" (click)="toogleNav();">
          </clr-icon>
          <clr-icon *ngIf="dashboardConfig?.Grid.SideNavPosition == SideNavPosition.BOTTOM" shape="angle-double"
            size="24" [class]="sidenavCollapsed ? 'sidenav-collapsed-bottom' : 'sidenav-expanded-bottom'"
            (click)="toogleNav();">
          </clr-icon>
          <div *ngFor="let sidenavConfig of sidenavConfigs" [style.display]="sidenavCollapsed ? 'none' : ''">
            <div class="clr-row">

              <div class="clr-col-2"></div>
              <div class="widget-header gridster-item-content clr-col-8">

                <div style="min-height: 1em;">
                  <b>
                    <clr-icon *ngIf="editMode" class="edit-widget" shape="cog" size="16"
                      (click)="onEditWidgetClick(sidenavConfig)"></clr-icon>
                    {{sidenavConfig.DisplayTitle.length > 0 ? sidenavConfig.DisplayTitle : ' '}}
                  </b>
                </div>
              </div>

              <div class="clr-col-3"></div>
              <div class="gridster-item-content export-widget">
                <clr-dropdown *ngIf="enableExport && (sidenavConfig.CanExportToImage || sidenavConfig.CanExportToExcel)"
                  [clrCloseMenuOnItemClick]="true">
                  <button clrDropdownTrigger aria-label="Export button">
                    <clr-icon shape="export" size="16"></clr-icon>
                  </button>
                  <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
                    <div *ngIf="sidenavConfig.CanExportToImage" aria-label="Export jpg" clrDropdownItem>
                      <a (click)="onExportWidgetClick(sidenavConfig, 'image')">
                        Export image
                      </a>
                    </div>
                    <div *ngIf="sidenavConfig.CanExportToExcel" aria-label="Export excel" clrDropdownItem
                      (click)="onExportWidgetClick(sidenavConfig, 'queries')">Export excel</div>
                  </clr-dropdown-menu>
                </clr-dropdown>
              </div>
            </div>
            <div class="gridster-item-content">
              <xproj-widget [config]="sidenavConfig" [responsive]="true" [zoom]="true" [editMode]="editMode"
                [dashboardConfig]="dashboardConfig" (onBeforeInit)="onBeforeWidgetInitSidenav($event)"
                (onAfterInit)="onAfterWidgetInit($event)">
              </xproj-widget>
            </div>
        </div>
        </as-split-area>
      </as-split>
    </as-split-area>

    <as-split-area [(size)]="editWidth" *ngIf="selectedWidgetConfig" [order]="3">
      <div style="position: relative;" class="dashboard-edit">
        <h3>
          <clr-icon *ngIf="!editFullScreen" style="cursor: pointer;" shape="resize-up" size="16"
            (click)="editFullScreen = true"></clr-icon>
          <clr-icon *ngIf="editFullScreen" style="cursor: pointer;" shape="resize-down" size="16"
            (click)="editFullScreen = false"></clr-icon>
          Edit '{{selectedWidgetConfig.Title}}'
        </h3>
        <xproj-widget [showConfig]="true" [editMode]="editMode" [config]="selectedWidgetConfig" (onConfigChanged)="onConfigChanged($event)"
          [dashboardConfig]="dashboardConfig" (onBeforeInit)="onBeforeWidgetInit($event)"
          (onAfterInit)="onAfterWidgetInit($event)">
        </xproj-widget>
        <br />
        <button class="btn" (click)="SaveWidgetConfig()">Save</button>
        <button class="btn" (click)="onEditClose($event);">Close</button>
        <button class="btn btn-danger-outline" (click)="removeWidgetConfig()">Remove</button>
      </div>
    </as-split-area>
  </as-split>

  <clr-tabs *ngIf="viewFullScreen">
    <clr-tab>
      <button clrTabLink (click)="widgetService.refresh(selectedWidgetConfig?.Id);"></button>
      <clr-tab-content>
        <div class="widget-view">
          <xproj-widget [config]="selectedWidgetConfig" [zoom]="true" [editMode]="editMode" [responsive]="false	" [widgetheight]="selectedWidgetConfig.Height"
            [dashboardConfig]="dashboardConfig" (onLoadingStateChange)="loading=$event" (onAfterInit)="onAfterWidgetInitFullscreen($event)">
          </xproj-widget>
        </div>
      </clr-tab-content>
    </clr-tab>
    <!-- <clr-tab *ngIf="(selectedWidgetConfig | typeof) ==  'ChartWidgetConfig'">
      <button clrTabLink (click)="widgetService.refresh(selectedWidgetConfig?.Id);">Table View</button>
      <clr-tab-content *clrIfActive>
        <div class="widget-view">
          <xproj-table-widget *ngIf="(selectedWidgetConfig | typeof) == 'ChartWidgetConfig'" [widgetheight]="selectedWidgetConfig.Height"
            [chartConfig]="selectedWidgetConfig" [globalWidgetSettings]="dashboardConfig.GlobalWidgetSettings">
          </xproj-table-widget>
        </div>
      </clr-tab-content>
    </clr-tab> -->
  </clr-tabs>
</div>
