<main class="content-area">
    <h1>MQTT Connectors</h1>

    <div class="limit-height" style="max-width:1200px;">
        <clr-datagrid class="datagrid-compact" [(clrDgSingleSelected)]="selectedConnector" [clrDgRowSelection]="true" [clrDgLoading]="loadingConnectors">

            <clr-dg-column [clrDgField]="'Driver.enabled'"> Enabled </clr-dg-column>
            <clr-dg-column [clrDgField]="'Driver.name'" [clrDgSortOrder]="ascSort"> Name </clr-dg-column>
            <clr-dg-column [clrDgField]="'Driver.description'"> Description </clr-dg-column>
            <clr-dg-column [clrDgField]="'Driver.defaultxautogroup'"> Default XAutoGroup </clr-dg-column>
            <clr-dg-column [clrDgField]="'Driver.defaultxgroup'"> Default XGroup </clr-dg-column>

            <clr-dg-row *clrDgItems="let connector of connectors" [clrDgItem]="connector">
                <clr-dg-cell class="cellhideoverflow"> {{connector.Driver.enabled}} </clr-dg-cell>
                <clr-dg-cell class="cellhideoverflow"> {{connector.Driver.name}} </clr-dg-cell>
                <clr-dg-cell class="cellhideoverflow"> {{connector.Driver.description}} </clr-dg-cell>
                <clr-dg-cell class="cellhideoverflow"> {{connector.Driver.defaultxautogroup}} </clr-dg-cell>
                <clr-dg-cell class="cellhideoverflow"> {{connector.Driver.defaultxgroup}} </clr-dg-cell>
            </clr-dg-row>

            <clr-dg-footer>
                <clr-dg-pagination #pagination [clrDgPageSize]="sizeOptions[0]">
                    <clr-dg-page-size [clrPageSizeOptions]="sizeOptions">Connectors per page</clr-dg-page-size>
                    {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} MQTT Connectors
                </clr-dg-pagination>
            </clr-dg-footer>
        </clr-datagrid>
    </div>

    <button class="btn btn-link " (click)="addConnector()">
        <clr-icon shape="plus-circle "></clr-icon>
        New MQTT connector
    </button>
    <button *ngIf="selectedConnector" type="button" class="btn btn-link" [clrLoading]="savingRemovingDriver" (click)="removeDriver(); ">
        <clr-icon shape="minus-circle "></clr-icon>
        Remove connector
    </button>


    <clr-accordion [clrAccordionMultiPanel]="true" *ngIf="selectedConnector">

        <clr-accordion-panel [clrAccordionPanelOpen]="true">
            <clr-accordion-title>Connector configuration</clr-accordion-title>
            <clr-accordion-content>

                <form clrForm>
                    <clr-input-container>
                        <label>Driver ID </label>
                        <input clrInput type="text" [(ngModel)]="selectedConnector.Driver.xautodriverid" name="xautodriverid" disabled size="35" />
                    </clr-input-container>

                    <clr-input-container>
                        <label>Name </label>
                        <input clrInput type="text" [(ngModel)]="selectedConnector.Driver.name" name="drivername" size="35" />
                    </clr-input-container>

                    <clr-input-container>
                        <label>Description </label>
                        <input clrInput type="text" [(ngModel)]="selectedConnector.Driver.description" name="driverdescription" size="35" />
                    </clr-input-container>

                    <clr-input-container>
                        <label>Default XAuto Group </label>
                        <input clrInput type="text" [(ngModel)]="selectedConnector.Driver.defaultxautogroup" name="driverdefaultxautogroup" size="35" />
                    </clr-input-container>

                    <clr-input-container>
                        <label>Default XGroup </label>
                        <input clrInput type="text" [(ngModel)]="selectedConnector.Driver.defaultxgroup" name="driverdefaultxgroup" size="35" />
                    </clr-input-container>

                    <clr-input-container>
                        <label>URI</label>
                        <input clrInput type="text" [(ngModel)]="selectedConnector.MQTT.uri" name="driveruri" size="35" />
                    </clr-input-container>
                    <clr-input-container>
                        <label>Username</label>
                        <input clrInput type="text" [(ngModel)]="selectedConnector.MQTT.username" name="driverusername" size="35" />
                    </clr-input-container>
                    <clr-input-container>
                        <label>Client ID</label>
                        <input clrInput type="text" [(ngModel)]="selectedConnector.MQTT.clientid" name="driverclientid" size="35" />
                    </clr-input-container>
                    <clr-input-container>
                        <label>Password</label>
                        <input clrInput type="password" [(ngModel)]="selectedConnector.MQTT.password" name="driverpassword" size="35" />
                    </clr-input-container>

                    <clr-toggle-container>
                        <clr-toggle-wrapper>
                            <input type="checkbox" clrToggle value="true" name="drivervalidateservercertificate" [(ngModel)]="selectedConnector.MQTT.validateservercertificate" />
                            <label>Validate server certificate</label>
                        </clr-toggle-wrapper>
                    </clr-toggle-container>

                    <clr-input-container>
                        <label>Subscribe topic input (keep as narrow as possible)</label>
                        <input clrInput type="text" [(ngModel)]="selectedConnector.MQTT.subscribetopicin" name="driversubscribetopticin" size="35" />
                    </clr-input-container>
                    <clr-input-container>
                        <label>Extract Identifier From Topic (REGEX), <span style="font-family:monospace">{{MQTTID}}</span> is key</label>
                        <input clrInput type="text" [(ngModel)]="selectedConnector.MQTT.extractidentifierfromtopicin" name="driverextractidentifierfromtopicin" size="35" />
                    </clr-input-container>
                    <clr-input-container>
                        <label>Topic out, <span style="font-family: monospace;">{{MQTTID}}</span> is key</label>
                        <input clrInput type="text" [(ngModel)]="selectedConnector.MQTT.topicout" name="driver" size="35" />
                    </clr-input-container>
                    <clr-input-container>
                        <label>JSON pointer</label>
                        <input clrInput type="text" [(ngModel)]="selectedConnector.MQTT.jsonpointer" name="driverjsonpointer" size="35" />
                    </clr-input-container>
<!-- 
                    <clr-select-container>
                        <label>Message Format</label>
                        <select clrSelect name="driverformat" [(ngModel)]="selectedConnector.MQTT.format">
                            <option [ngValue]="XAUTO_MQTTDataFormat.JSON">JSON</option>
                        </select>
                    </clr-select-container> -->

                    <!-- <clr-select-container>
                        <label>Payload Encoding</label>
                        <select clrSelect name="driverpayloaddataencoding" [(ngModel)]="selectedConnector.MQTT.payloaddataencoding">
                            <option [ngValue]="XAUTO_MQTTPayloadEncoding.RAW">RAW</option>
                            <option [ngValue]="XAUTO_MQTTPayloadEncoding.BASE64">BASE64</option>
                            <option [ngValue]="XAUTO_MQTTPayloadEncoding.HEX">HEX</option>
                        </select>
                    </clr-select-container> -->


                    <clr-toggle-container>
                        <clr-toggle-wrapper>
                            <input type="checkbox" clrToggle value="true" name="driverenabled" [(ngModel)]="selectedConnector.Driver.enabled" />
                            <label>Enabled</label>
                        </clr-toggle-wrapper>
                    </clr-toggle-container>


                    <button class="btn" [clrLoading]="savingDriver" (click)="saveDriver()">Save</button>

                </form>
            </clr-accordion-content>
        </clr-accordion-panel>

        <clr-accordion-panel [clrAccordionPanelOpen]="false">
            <clr-accordion-title>Connector Variables</clr-accordion-title>
            <clr-accordion-content>
                <div class="limit-height" style="max-width:1200px;">
                    <clr-datagrid class="datagrid-compact" [(clrDgSingleSelected)]="selectedVariable" [clrDgRowSelection]="true" [clrDgLoading]="loadingConnectors">

                        <clr-dg-column [clrDgField]="'enabled'"> Enabled </clr-dg-column>
                        <clr-dg-column [clrDgField]="'name'" [clrDgSortOrder]="ascSort"> Name </clr-dg-column>
                        <clr-dg-column [clrDgField]="'description'"> Description </clr-dg-column>
                        <clr-dg-column [clrDgField]="'xautogroup'"> XAutoGroup </clr-dg-column>
                        <clr-dg-column [clrDgField]="'xgroup'"> XGroup </clr-dg-column>
                        <clr-dg-column [clrDgField]="'defaultscriptname'"> Default scriptname </clr-dg-column>

                        <clr-dg-row *clrDgItems="let variable of selectedConnector.Variables" [clrDgItem]="variable">
                            <clr-dg-cell class="cellhideoverflow"> {{variable.Variable.enabled}} </clr-dg-cell>
                            <clr-dg-cell class="cellhideoverflow"> {{variable.Variable.name}} </clr-dg-cell>
                            <clr-dg-cell class="cellhideoverflow"> {{variable.Variable.description}} </clr-dg-cell>
                            <clr-dg-cell class="cellhideoverflow"> {{variable.Variable.xautogroup}} </clr-dg-cell>
                            <clr-dg-cell class="cellhideoverflow"> {{variable.Variable.xgroup}} </clr-dg-cell>
                            <clr-dg-cell class="cellhideoverflow"> {{variable.Variable.defaultscriptname}} </clr-dg-cell>
                        </clr-dg-row>

                        <clr-dg-footer>
                            <clr-dg-pagination #pagination [clrDgPageSize]="sizeOptions[1]">
                                <clr-dg-page-size [clrPageSizeOptions]="sizeOptions">Variables per page</clr-dg-page-size>
                                {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} Variables
                            </clr-dg-pagination>
                        </clr-dg-footer>
                    </clr-datagrid>
                </div>
                <button class="btn btn-link " (click)="addVariable()">
                    <clr-icon shape="plus-circle "></clr-icon>
                    New MQTT variable
                </button>
                <button *ngIf="selectedConnector" type="button" class="btn btn-link" [clrLoading]="savingRemovingVariable" (click)="removeVariable(); ">
                    <clr-icon shape="minus-circle "></clr-icon>
                    Remove variable
                </button>


                <form clrForm *ngIf="selectedVariable">
                    <clr-input-container>
                        <label>Variable ID </label>
                        <input clrInput type="text" [(ngModel)]="selectedVariable.Variable.xautovariableid" name="variablexautovariableid" disabled size="35" />
                    </clr-input-container>

                    <clr-input-container>
                        <label>Name </label>
                        <input clrInput type="text" [(ngModel)]="selectedVariable.Variable.name" name="variablename" size="35" />
                    </clr-input-container>

                    <clr-input-container>
                        <label>Description </label>
                        <input clrInput type="text" [(ngModel)]="selectedVariable.Variable.description" name="variabledescription" size="35" />
                    </clr-input-container>

                    <clr-input-container>
                        <label>XAuto Group </label>
                        <input clrInput type="text" [(ngModel)]="selectedVariable.Variable.xautogroup" name="variablexautogroup" size="35" />
                    </clr-input-container>

                    <clr-input-container>
                        <label>XGroup </label>
                        <input clrInput type="text" [(ngModel)]="selectedVariable.Variable.xgroup" name="variablexgroup" size="35" />
                    </clr-input-container>

                    <clr-toggle-container>
                        <clr-toggle-wrapper>
                            <input type="checkbox" clrToggle value="true" name="variablewritable" [(ngModel)]="selectedVariable.Variable.writable" />
                            <label>Writable</label>
                        </clr-toggle-wrapper>
                    </clr-toggle-container>
                    <clr-input-container>
                        <label>Sample interval (seconds) </label>
                        <input clrInput type="number" [(ngModel)]="selectedVariable.Variable.sampleintervalseconds" name="variablesampleinterval" size="35" />
                    </clr-input-container>

                    <clr-input-container>
                        <label>Flush interval (seconds) </label>
                        <input clrInput type="number" [(ngModel)]="selectedVariable.Variable.flushintervalseconds" name="variableflushintervalseconds" size="35" />
                    </clr-input-container>

                    <clr-input-container>
                        <label>MQTT Identifier </label>
                        <input clrInput type="text" [(ngModel)]="selectedVariable.MQTT.mqttidentifier" name="varmqttidentifier" size="35" />
                    </clr-input-container>


                    <!-- todo: format, datatypein, datatypeout -->
                    <clr-select-container>
                        <label>Payload format</label>
                        <select clrSelect name="driverpayloadformat" [(ngModel)]="selectedVariable.MQTT.format">
                            <option [ngValue]="XAUTO_MQTTDataFormat.JSON">JSON</option>
                            <option [ngValue]="XAUTO_MQTTDataFormat.BINARY">Binary</option>
                        </select>
                    </clr-select-container> 

                    <!-- <clr-select-container>
                        <label>Binary payload Encoding</label>
                        <select clrSelect name="driverpayloaddataencoding" [(ngModel)]="selectedVariable.MQTT.format">
                            <option [ngValue]="XAUTO_MQTTBinaryPayloadEncoding.RAW">RAW</option>
                            <option [ngValue]="XAUTO_MQTTBinaryPayloadEncoding.BASE64">BASE64</option>
                            <option [ngValue]="XAUTO_MQTTBinaryPayloadEncoding.HEX">HEX</option>
                        </select>
                    </clr-select-container>  -->

                    <clr-select-container>
                        <label>Data type in</label>
                        <select clrSelect name="vardatatypein" [(ngModel)]="selectedVariable.MQTT.datatypein">
                            <option [ngValue]="XAUTO_JSONType.DOUBLE">Double</option>
                            <option [ngValue]="XAUTO_JSONType.BOOL">Bool</option>
                            <option [ngValue]="XAUTO_JSONType.INT64">Int64</option>
                            <option [ngValue]="XAUTO_JSONType.UINT64">UInt64</option>
                            <option [ngValue]="XAUTO_JSONType.STRING">String</option>
                            <option [ngValue]="XAUTO_JSONType.OBJECT">Object</option>
                        </select>
                    </clr-select-container> 

                    <clr-select-container>
                        <label>Data type out</label>
                        <select clrSelect name="vardatatypeout" [(ngModel)]="selectedVariable.MQTT.datatypeout">
                            <option [ngValue]="XAUTO_JSONType.DOUBLE">Double</option>
                            <option [ngValue]="XAUTO_JSONType.BOOL">Bool</option>
                            <option [ngValue]="XAUTO_JSONType.INT64">Int64</option>
                            <option [ngValue]="XAUTO_JSONType.UINT64">UInt64</option>
                            <option [ngValue]="XAUTO_JSONType.STRING">String</option>
                            <option [ngValue]="XAUTO_JSONType.OBJECT">Object</option>
                        </select>
                    </clr-select-container>                    

                    <clr-input-container *ngIf="selectedVariable.MQTT.format == XAUTO_MQTTDataFormat.JSON">
                        <label>JSON Pointer In </label>
                        <input clrInput type="text" [(ngModel)]="selectedVariable.MQTT.jsonpointerin" name="varjsonpointerin" size="35" />
                    </clr-input-container>

                    <clr-input-container *ngIf="selectedVariable.MQTT.format == XAUTO_MQTTDataFormat.JSON">
                        <label>JSON Pointer Out </label>
                        <input clrInput type="text" [(ngModel)]="selectedVariable.MQTT.jsonpointerout" name="varjsonpointerout" size="35" />
                    </clr-input-container>

                    <!-- todo: datatype in / out -->

                    <label for="varluastrdatain" class="clr-control-label clr-col-12 clr-col-md-4">Transform data in (Lua)</label>
                        <div class="clr-control-container clr-col-md-10 clr-col-12">                        
                        <ngx-monaco-editor
                            [style.height.px]="getEditorHeight(selectedVariable.MQTT.luascripttransformdatain )"
                            name="editvartrasdatainlua" [options]="editorOptionsLua"
                            [(ngModel)]="selectedVariable.MQTT.luascripttransformdatain">
                        </ngx-monaco-editor>
                    </div>

                    <label for="varluastrdataout" class="clr-control-label clr-col-12 clr-col-md-4">Transform data out (Lua)</label>
                        <div class="clr-control-container clr-col-md-10 clr-col-12">
                        <ngx-monaco-editor
                            [style.height.px]="getEditorHeight(selectedVariable.MQTT.luascripttransformdataout )"
                            name="editvartrasdataoutlua" [options]="editorOptionsLua"
                            [(ngModel)]="selectedVariable.MQTT.luascripttransformdataout">
                        </ngx-monaco-editor>
                    </div>

                    <clr-toggle-container>
                        <clr-toggle-wrapper>
                            <input type="checkbox" clrToggle value="true" name="variableenabled" [(ngModel)]="selectedVariable.Variable.enabled" />
                            <label>Enabled</label>
                        </clr-toggle-wrapper>
                    </clr-toggle-container>

                    <button class="btn" [clrLoading]="savingVariable" (click)="saveVariable()">Save</button>

                </form>

            </clr-accordion-content>
        </clr-accordion-panel>
    </clr-accordion>
</main>