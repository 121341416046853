import '@cds/core/icon/register.js';
import { ClarityIcons, pencilIcon, trashIcon, exclamationCircleIcon,
          bubbleExclamationIcon, exclamationTriangleIcon, eyeIcon, playIcon,
          copyIcon, checkCircleIcon, checkIcon, arrowIcon } from '@cds/core/icon';

ClarityIcons.addIcons(pencilIcon);
ClarityIcons.addIcons(trashIcon);
ClarityIcons.addIcons(exclamationCircleIcon);
ClarityIcons.addIcons(exclamationTriangleIcon);
ClarityIcons.addIcons(bubbleExclamationIcon);
ClarityIcons.addIcons(eyeIcon);
ClarityIcons.addIcons(playIcon);
ClarityIcons.addIcons(checkIcon);
ClarityIcons.addIcons(checkCircleIcon);
ClarityIcons.addIcons(arrowIcon);
ClarityIcons.addIcons(copyIcon);
