import { Component, OnInit, AfterViewInit, Output, EventEmitter, OnDestroy, Input, Inject, ViewChild, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { ChartWidgetConfig } from '../widgets/chart/chart-widget-config/xproj-chart-widget-config-service';
import { CopiedWidget, DashboardConfig, DashboardEvent, ReponsiveiveOverridableWidgetConfigs, Resolutions, ResponsiveOverriderableWidgetConfig, ScriptedParametersOnEvent, ScriptedParametersOnParameterChange, SideNavPosition } from '../dashboard/xproj-dashboard-service';
import { LinkedWidgetChangeParameters, LinkedWidgetSelectParameters, WidgetOutputChangeParameters, XprojWidgetService } from '../widgets/xproj-widget-service';
import { ClrLoadingState } from '@clr/angular';
import { OutputDataType, WidgetConfig, WidgetOutputParameter } from '../widgets/widget-config-service';
import { TableWidgetConfig } from '../widgets/table/table-widget-config/table-widget-config-service';
import { MasterWidgetConfig } from '../widgets/master/master-widget-config/master-widget-config-service';
import { Subject, Subscription, timer } from 'rxjs';
import { PieChartType, PiechartWidgetConfig } from '../widgets/piechart/piechart-widget-config/piechart-widget-config-service';
import { LabelWidgetConfig } from '../widgets/label/label-widget-config/label-widget-config-service';
import { GridsterConfig, GridsterItem, GridsterComponentInterface, GridsterItemComponentInterface, CompactType, DisplayGrid, GridType } from 'angular-gridster2';
import { GridOptions } from '../utils/gridster-utils-service';
import { SpectrumAnalyzerConfig } from '../widgets/spectrum-analyzer/spectrum-analyzer-widget-config/spectrum-analyzer-config-service';
import { DASHBOARDSERVICE, XprojDashboardService } from '../dashboard/xproj-dashboard-service';
import { WidgetType, XprojDashboardInteractService } from '../dashboard/xproj-dashboard-interact.service';
import { TextWidgetConfig } from '../widgets/text/text-widget-config/text-widget-config-service';
import { Aggregation, BaseQuery, BaseQueryInputColumnDescription, BaseQueryResult, ColumnFilteringString, DFTQuery, DownSampleQuery, FilterComparator, FilterLogicalGroup, FilterLogicalGroupType, MsgPackCloneObject, MultiseriesQuery, Projection, SPCQuery, XProjectorClient } from '../XProjector/xprojector-client-service';
import { ArrayUtils } from '../utils/array-utils-service';
import { takeUntil } from 'rxjs/operators';
import { MapWidgetConfig } from '../widgets/map/map-widget-config/xproj-map-widget-config-service';
import { SvgWidgetConfig } from '../widgets/svg/svg-widget-config/xproj-svg-widget-config-service';
import { Scripted3dWidgetConfig } from '../widgets/scripted3d/scripted3d-widget-config/xproj-scripted3d-widget-config-service';
import { OutputControllerWidgetConfig } from '../widgets/output/output-controller-widget-config/xproj-output-controller-widget-config-service';
import { SplitAreaDirective, SplitComponent } from 'angular-split';
import { XprojModalService } from '../modals/xproj-modal-service.service';
import { TypedJSON } from 'typedjson';
import { saveAs } from 'file-saver';
import { FileSystemDirectoryEntry, FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { ClipboardService } from 'ngx-clipboard'

import * as fengari from 'fengari-web';
import { ProjectionDataEditorWidgetConfig } from '../widgets/projection-dataeditor/projection-dataeditor-widget-config/projection-dataeditor-widget-config-service';
import { ContainerWidgetConfig } from '../widgets/container/container-widget-config/container-widget-config-service';
import { Guid } from '../utils/guid-service';
import { SpcConfig, SpcQuery } from '../widgets/spc/spc-widget-config/spc-config-service';
import { LOGGERSERVICE, XprojLoggerService } from '../logger/xproj-logger-service';
import { environment } from 'src/environments/environment';
import { XprojLuaDashboardWrapper } from '../dashboard/xproj-lua';
import { DashboardOutputChangeParameters } from '../models/dashboard-output-change-parameters';
import { ScriptedParameters } from '../models/scripted-parameters';
// import * as fengarinterop from 'fengari-interop';

export class WidgetExportParameters {
  name: string;
  queries: (BaseQuery | DownSampleQuery | DFTQuery | MultiseriesQuery | SPCQuery)[];
  decimals: number = 3;
  excludeColumns: string[] = [];
  data: any[];
}

export const CUSTOMERID_OUTPUTPARAMETERID = '__CUSTOMER_ID__';
export const REMOTENODEID_OUTPUTPARAMETERID = '__REMOTENODE_ID__';

@Component({
  selector: 'xproj-render-dashboard',
  templateUrl: './xproj-render-dashboard.component.html',
  styleUrls: ['./xproj-render-dashboard.component.scss']
})
export class XprojRenderDashboardComponent implements OnInit, AfterViewInit, OnDestroy {
  targetResolution: Resolutions = Resolutions.Sandbox;

  @ViewChild("mainview", { read: ElementRef, static: false }) mainview: ElementRef;
  @ViewChild("sidenav", { read: ElementRef, static: false }) sidenav: ElementRef;

  //@ViewChild("xprojuserstyles", { read: ElementRef, static: false }) xprojuserstyles : ElementRef;

  @ViewChild(SplitComponent) splitEl: SplitComponent
  @ViewChildren(SplitAreaDirective) areasEl: QueryList<SplitAreaDirective>

  @Output() onWidgetValueSelected = new EventEmitter<LinkedWidgetSelectParameters>();
  @Output() onWidgetValueChanged = new EventEmitter<LinkedWidgetChangeParameters>();
  @Output() onWidgetExport = new EventEmitter<WidgetExportParameters>();

  @Input() editMode: boolean = false;
  @Input() showDashboardSettings: boolean = false;

  @Input() set responsiveWidth(value: number) {
    // if (!this.editMode)
    //   this.refreshResolutionView(value);
  }

  @Input('beforeSave') beforeSave: (dashboardConfig: DashboardConfig) => DashboardConfig;

  //@Input() dashboardConfig: DashboardConfig; // Commented out for creating responsive, add again
  dashboardConfig: DashboardConfig = null;
  originalDashboardConfig: DashboardConfig = null;

  @Input() dashboardId: string = '';
  @Input() dashboardTag: string = '';
  @Input() systemDashboard: boolean = false;
  @Input() assetDashboard: boolean = false;
  //@Input() isMobile: boolean = false;
  @Input() enableExport: boolean = false;
  @Input() enableSubscriptions: boolean = true;

  private _dashboardOutputParameters: DashboardOutputChangeParameters[] = [];
  @Input()
  get dashboardOutputParameters() {
    return this._dashboardOutputParameters;
  }
  set dashboardOutputParameters(params) {
    this._dashboardOutputParameters = params;
    this.updateParameters(this._dashboardOutputParameters);
  }

  dashboardVersion: number = -1;

  outputParametetersUpdated: boolean = false;
  subscriptionsEnabled: boolean = true;

  ngUnsubscribe = new Subject<void>();

  loadingProjectionColumns = false;

  loadFromService: boolean = true;
  loading: ClrLoadingState = ClrLoadingState.DEFAULT;

  viewFullScreen: boolean = false;
  initDone: boolean = false;
  initCounter: number;
  sidenavCollapsed = true;

  exportFilename: string = 'dashboardexport.json';
  showExportDialog: boolean = false;
  showImportDialog: boolean = false;
  configfiles: NgxFileDropEntry[] = [];

  showLoadParameters: boolean = false;
  showLinkedParameters: boolean = false;

  gridWidgetConfigs: WidgetConfig[] = [];
  sidenavConfigs: WidgetConfig[] = [];

  widgetInitCounter: number;

  dataHasChanged: boolean = false;
  projectionIdsChanged: string[] = [];
  dataChangedTimerSource: any;
  dashboardWidth: string = "100%";
  dashboardStyle = { "width": "100%" };


  selectedLinkedParmaeterTriggerOn = [];
  private parameterLastChanged: Map<string, Date> = new Map<string, Date>();
  public allParameters: Array<WidgetOutputParameter> = new Array<WidgetOutputParameter>()
  private hasSetupSubscribeOnParameters = false;

  modified: boolean = false;

  private lua : XprojLuaDashboardWrapper = null;

  SideNavPosition = SideNavPosition;

  private widgetTypes: WidgetType[] =
    [
      {
        id: 'chart',
        name: 'Chart widget'
      },
      {
        id: 'container',
        name: 'Container widget'
      },
      {
        id: 'scripted3d',
        name: 'Scripted 3D'
      },
      {
        id: 'projectiondataeditor',
        name: 'Data editor widget'
      },
      {
        id: 'label',
        name: 'Label widget'
      },
      {
        id: 'map',
        name: 'Map widget'
      },
      {
        id: 'master',
        name: 'Master widget'
      },
      {
        id: 'output',
        name: 'Output widget'
      },
      {
        id: 'piechart',
        name: 'Piechart widget'
      },
      {
        id: 'spectrumanalyzer',
        name: 'Spectrum analyzer widget'
      },
      {
        id: 'svg',
        name: 'Svg widget'
      },
      {
        id: 'table',
        name: 'Table widget'
      },
      {
        id: 'text',
        name: 'Text widget'
      }
    ];


  constructor(
    @Inject(DASHBOARDSERVICE) private dashboardService: XprojDashboardService,
    public widgetService: XprojWidgetService,
    public xprojClient: XProjectorClient,
    private dashboardInteractService: XprojDashboardInteractService,
    private modalService: XprojModalService,
    @Inject(LOGGERSERVICE) private logger: XprojLoggerService,
    private clipboard: ClipboardService) {

      this.lua = new XprojLuaDashboardWrapper(xprojClient, logger);
      this.lua.onUpdateAndUpsertDynamicParameters = this.updateAndUpsertDynamicParameters.bind(this);

  }

  // justSetDashboardWidth() {
  //   this.dashboardWidth = this.RequestedWidth.toString() + "px";

  //   this.dashboardStyle["margin-left"] = "auto";
  //   this.dashboardStyle["margin-right"] = "auto";
  //   this.dashboardStyle["box-shadow"] = "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)";
  //   this.dashboardStyle["width"] = this.dashboardWidth;
  // }

  public get_width(cols) : string
  {
    //return cols * this.dashboardConfig.Grid.FixedColWidth + "px";

    // We want to do % but it seems to not work inside the xproj widget component

    let dashboardElement = document.getElementsByClassName("dashboard-area") as HTMLCollectionOf<HTMLElement>;
    let fullWidth = dashboardElement[0].offsetWidth;

    let width = (cols / this.dashboardConfig.Grid.MaxCols)*fullWidth;
    return width.toFixed(0).toString() + "px";
  }


  public get_minimum_height(rows) : number
  {
    return rows * this.dashboardConfig.Grid.FixedRowHeight;
  }

  async ngOnInit(): Promise<void> {
    //this.gridoptions.gridSizeChangedCallback = this.gridSizeChanged.bind(this);

    this.loadFromService = !this.dashboardConfig;

    await this.reload();

    //this.sortWidgetConfigs();

    // Moved this to a specific action / stefan
    // this.savetimersource.pipe(takeUntil(this.ngUnsubscribe)).subscribe(async (event) => {
    //   if (this.modified && this.editMode) {
    //     await this.dashboardService.saveDashboard(this.dashboardConfig);
    //     this.modified = false;
    //   }
    // });


    this.widgetService.linkedWidgetSelectSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(async (event: LinkedWidgetSelectParameters) => {
      this.onWidgetValueSelected?.next(event.Clone());
    });

    this.widgetService.linkedWidgetChangeSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(async (event: LinkedWidgetChangeParameters) => {
      this.onWidgetValueChanged?.next(event.Clone());
    });

    this.dashboardInteractService.getWidgetTypesSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(async ({ dashboardId, callback }) => {
      if (dashboardId == this.dashboardId) {
        callback(this.widgetTypes);
      }
    });

    // this.dashboardInteractService.addWidgetSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(async ({ dashboardId, widgetType, callback }) => {
    //   if (dashboardId == this.dashboardId) {
    //     await this.addWidget(widgetType.id)
    //     callback(true);
    //   }
    // });

    this.dashboardInteractService.showDashboardSettingsSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(async ({ dashboardId, showSettings, settingsIconVisible }) => {
      //this.logger.info('showSettings', showSettings);
      //this.logger.info('settingsIconVisible', settingsIconVisible);
      if (!dashboardId || dashboardId == this.dashboardId) {
        if (settingsIconVisible != undefined) {
          this.showDashboardSettings = settingsIconVisible;
        }
      }
    });

    this.dashboardInteractService.widgetOutputChangeSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(async (events: DashboardOutputChangeParameters[]) => {
      events.forEach(e => {
        let param = this.dashboardOutputParameters.find(p => p.outputParameterName == e.outputParameterName);
        if (param) {
          param.value = e.value;
        }
        else {
          this.dashboardOutputParameters.push(e.Clone());
        }
      });

      await this.updateParameters(this.dashboardOutputParameters);
    });

    this.xprojClient.authenticatedSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(async () => {
      if (this.subscriptionsEnabled) {
        await this.SubscribeAllProjecionData();
      }
    });

    this.xprojClient.projectionDataChangedSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(async (projectionId) => {
      if (this.subscriptionsEnabled) {
        if (this.dashboardConfig.GlobalWidgetSettings.MinUpdateInterval == 0) {
          this.projectionIdsChanged.push(projectionId);
          this.emitOnDataChange();
        }
        else {
          this.dataHasChanged = true;
          if (this.projectionIdsChanged.findIndex(p => p == projectionId) < 0) {
            this.projectionIdsChanged.push(projectionId);
          }

          if (!this.dataChangedTimerSource) {
            this.dataChangedTimerSource = timer(this.dashboardConfig.GlobalWidgetSettings.MinUpdateInterval, this.dashboardConfig.GlobalWidgetSettings.MinUpdateInterval);
            this.dataChangedTimerSource.pipe(takeUntil(this.ngUnsubscribe)).subscribe(async (event) => {
              this.emitOnDataChange();
            });
          }
        }
      }
    });

    this.dashboardInteractService.dashboardRefresh.pipe(takeUntil(this.ngUnsubscribe)).subscribe(async () => {
      this.refresh();
    });

    this.SubscribeAllProjecionData();

    this.setupSubscribeOnParameters();

    if (this.dashboardConfig) {
      for (let it of this.dashboardConfig.DynamicParameters) {
        let itEvent = it as ScriptedParametersOnEvent;
        if (!itEvent) {
          this.setupSubscribeOnParameters();
          continue;
        }
        if (itEvent.OnEvent == DashboardEvent.INIT || itEvent.OnEvent == DashboardEvent.PARAMETERS_LOADED) {
          this.lua.RunScriptedParameter(itEvent);
        }
      }
    }
  }

  setupSubscribeOnParameters(): void {
    if (this.hasSetupSubscribeOnParameters)
      return;

    this.hasSetupSubscribeOnParameters = true;

    this.widgetService.widgetOutputChangeSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((parameters) => {
      let now = new Date();
      this.logger.info("parameter has changed?");
      this.logger.info(parameters);
      for (let p of parameters) {
        if (!this.parameterLastChanged[p.outputParameterId]) {
          let nParam = new WidgetOutputParameter();
          nParam.datatype = p.datatype;
          nParam.id = p.outputParameterId;
          nParam.name = this.getOutputParameterName(p.widgetId, p.outputParameterId);
          this.allParameters.push(nParam);
        }
        this.parameterLastChanged[p.outputParameterId] = now;
      }

      this.RunDynamicLinkedPrametersIfNeeded();
    });
  }

  getOutputParameterName(widgetId: string, parameterId: string): string {
    let widget = this.dashboardConfig?.WidgetConfigs.find(w => w.Id == widgetId);
    if (widget) {
      let outParam = widget.OutputParameters.find(p => p.id == parameterId);
      if (outParam) {
        return outParam.name;
      }
    }

    return parameterId;
  }

  selectedLinkedParmaeterTriggerOnChanged(event: any): void {
    let selectedparam = this.selectedLinkedParameter as ScriptedParametersOnParameterChange;
    selectedparam.OnParameterChanged.length = 0;
    for (let it of this.selectedLinkedParmaeterTriggerOn) {
      let sparam = it as WidgetOutputParameter;
      selectedparam.OnParameterChanged.push(sparam.id);
    }
  }

  mojs(event: any): void {
    this.logger.debug("hah!");
  }
  selectedLinkedParameterChanged(event: any): void {

    //selectedLinkedParmaeterTriggerOn
    let selectedparam = this.selectedLinkedParameter as ScriptedParametersOnParameterChange;
    this.selectedLinkedParmaeterTriggerOn.length = 0;
    for (let it of selectedparam.OnParameterChanged) {
      for (let jit of this.allParameters) {
        if (it == jit.id) {
          this.selectedLinkedParmaeterTriggerOn.push(jit);
        }
      }
    }
  }

  private RunDynamicLinkedPrametersIfNeeded(): void {
    let now = new Date();
    let heldBackTreshold = now;
    heldBackTreshold.setMilliseconds(now.getMilliseconds() - 50);

    for (let sp of this.dashboardConfig.DynamicParameters) {
      let splinked = sp as ScriptedParametersOnParameterChange;
      if (!splinked ||
        !splinked.OnParameterChanged ||
        splinked.OnParameterChanged.length == 0)
        continue;


      if (splinked.lastran > heldBackTreshold) {
        splinked.heldBack = true;
        continue;
      }
      let shouldRun = false;

      if (!splinked.heldBack) {
        for (let p of splinked.OnParameterChanged) {
          let pchanged = this.parameterLastChanged[p];
          if (!pchanged || pchanged <= splinked.lastran) {
            continue;
          }
          shouldRun = true;
          break;
        }
      }
      if (shouldRun) {
        splinked.lastran = now;
        this.lua.RunScriptedParameter(splinked);
      }
    }
  }

  ngAfterViewInit(): void {
    this.logger.info("After view init and targetresolution is: ", this.targetResolution);
    this.widgetService.refresh();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();

    //this.savetimersubscription?.unsubscribe();
    this.UnsubscribeAllProjecionData();
  }

  insertSystemOutputParameters() {
    let updated: boolean = false;

    let output = this.dashboardConfig?.OutputParameters.find(out => out.id == CUSTOMERID_OUTPUTPARAMETERID);
    if (!output) {
      output = new WidgetOutputParameter();
      output.id = CUSTOMERID_OUTPUTPARAMETERID;
      output.name = 'customerid';
      output.datatype = OutputDataType.String;
      this.dashboardConfig.OutputParameters.push(output);

      updated = true;
    }

    // let outputRemotNodeId = this.dashboardConfig?.OutputParameters.find(out => out.id == REMOTENODEID_OUTPUTPARAMETERID);
    // if (!outputRemotNodeId) {
    //   outputRemotNodeId = new WidgetOutputParameter();
    //   outputRemotNodeId.id = REMOTENODEID_OUTPUTPARAMETERID;
    //   outputRemotNodeId.name = 'remotenodeid';
    //   outputRemotNodeId.datatype = OutputDataType.String;
    //   this.dashboardConfig.OutputParameters.push(outputRemotNodeId);

    //   updated = true;
    // }

    // if (updated) {
    //   this.dashboardService.saveDashboard(this.dashboardConfig);
    // }
  }

  async updateParameters(events: DashboardOutputChangeParameters[]) {
    let outputs: WidgetOutputChangeParameters[] = [];
    await ArrayUtils.AsyncForEach(events, async (event: DashboardOutputChangeParameters) => {
      let output = this.dashboardConfig?.OutputParameters.find(out => out.name.toLowerCase() == event.outputParameterName.toLowerCase());
      if (output) {
        let outputChanged = new WidgetOutputChangeParameters();
        outputChanged.widgetId = '__dashboard__';
        outputChanged.outputParameterId = output.id;
        outputChanged.value = event.value;
        outputChanged.datatype = output.datatype;

        outputs.push(outputChanged);

        //this.logger.info('updateParameters', outputChanged);
      }
    });
    if (outputs.length > 0) {
      this.outputParametetersUpdated = true;
      this.widgetService.outputParametersChanged(outputs, true);
    }
  }

  async updateAndUpsertDynamicParameters(events: DashboardOutputChangeParameters[], source: ScriptedParameters) {
    let outputs: WidgetOutputChangeParameters[] = [];

    await ArrayUtils.AsyncForEach(events, async (event: DashboardOutputChangeParameters) => {
      let output = this.dashboardConfig?.OutputParameters.find(out => out.name == event.outputParameterName);
      if (!output) {
        output = new WidgetOutputParameter();
        output.name = event.outputParameterName;
        output.datatype = event.datatype;
        output.id = "dynamic-" + source.Name + "-" + output.name;
        this.dashboardConfig.OutputParameters.push(output);
      }
      if (output) {
        let outputChanged = new WidgetOutputChangeParameters();
        outputChanged.widgetId = '__dashboard__';
        outputChanged.outputParameterId = output.id;
        outputChanged.value = event.value;
        outputChanged.datatype = output.datatype;

        outputs.push(outputChanged);
      }
    });
    if (outputs.length > 0) {
      this.widgetService.outputParametersChanged(outputs, true);
    }
  }

  async reload() {

    this.outputParametetersUpdated = false;
    this.initDone = false;

    if (this.loadFromService && this.dashboardId?.length > 0) {
      try {
        if (this.assetDashboard) {
          const serializer = new TypedJSON(DashboardConfig);
          let response = await fetch('./assets/dashboards/' + this.dashboardId);
          if (response.ok) {
            let json = await response.text();
            if (json?.length > 0) {
              this.dashboardConfig = serializer.parse(json);
            }
          }
          else {
            this.dashboardConfig = new DashboardConfig();
            this.dashboardConfig.Id = this.dashboardId;
          }
        }
        else {
          this.dashboardConfig = await this.dashboardService.loadDashboard(this.dashboardId, this.dashboardVersion, this.dashboardTag, this.systemDashboard);
        }
      }
      catch (error) {
        this.logger.error('error', error);
        this.dashboardConfig = new DashboardConfig();
        this.dashboardConfig.Id = this.dashboardId;
      }
    }

    if (this.dashboardConfig) {
      if (!this.dashboardConfig.ResponsiveWidgets) {
        this.dashboardConfig.ResponsiveWidgets = new Map<number, ReponsiveiveOverridableWidgetConfigs>();
      }

      this.subscriptionsEnabled = this.enableSubscriptions && this.dashboardConfig.SubscriptionsEnabled;

      this.originalDashboardConfig = TypedJSON.parse(TypedJSON.stringify(this.dashboardConfig, DashboardConfig), DashboardConfig);

      if (this.dashboardConfig.Grid.ShowSideNav) {
        setTimeout(() => {
          this.toogleNav(true);
        });
      }

      this.sortWidgetConfigs();
      this.modified = false;

      this.insertSystemOutputParameters();

      // if (this.isMobile) {
      //   this.gridoptions.mobileBreakpoint = 0;
      // }
    }

    this.targetResolution = Resolutions.Sandbox;

    //let userstyle = this.xprojuserstyles.nativeElement as Element

    let xprojuserstyles = document.getElementById("xprojuserstyles");
    if(xprojuserstyles && this.dashboardConfig.UserCSS && this.dashboardConfig.UserCSS != "")
    {
      console.log("setting xprojcssstyles = ", this.dashboardConfig.UserCSS);
      xprojuserstyles.textContent = this.dashboardConfig.UserCSS;
    }
  }

  async setDashboardId(id: string, version: number = -1, tag: string = '', systemDashboard: boolean = false, parameters: DashboardOutputChangeParameters[] = []) {
    if (id != this.dashboardId || this.dashboardVersion != version || this.systemDashboard != systemDashboard) {
      this.UnsubscribeAllProjecionData();
      this.dashboardId = id;
      this.dashboardVersion = version;
      this.dashboardTag = tag;
      this.systemDashboard = systemDashboard;
      if (parameters.length > 0) {
        this.dashboardOutputParameters = parameters;
      }
      await this.reload();

      this.SubscribeAllProjecionData();
      //this.sortWidgetConfigs();
    }
    else if (this.outputParametetersUpdated) {
      await this.reload();
    }
  }

  async setDashboardOutputParameters(parameters: DashboardOutputChangeParameters[]) {
    this.dashboardOutputParameters = parameters;
    await this.updateParameters(this.dashboardOutputParameters);
  }

  refresh(): void {
  }

  refreshToolbox(): void {

    let sidenavs = [];
    this.sidenavConfigs.forEach(c => {
      sidenavs.push(c.Id);
    });
    this.dashboardConfig.Grid.SideNavWidgets = sidenavs;

    this.refresh();
  }

  setInitDone(done: boolean) {
    if (done && !this.initDone) {
      this.initDone = true;
      this.logger.debug('Init dashboard done!');
    }
  }

  DashboardEvent = DashboardEvent;

  editorOptionsLua = { theme: environment.editortheme, language: 'lua', automaticLayout: true, acceptSuggestionOnEnter: "smart", minimap: { enabled: false } };
  public selectedEventBasedParameter: any = null;
  public selectedLinkedParameter: any = null;

  ShowLoadParameters(): void {
    this.showLoadParameters = true;
  }

  AddLoadParameter(): void {
    let newParam = new ScriptedParametersOnEvent();
    newParam.Description = "New script";
    newParam.OnEvent = DashboardEvent.PARAMETERS_LOADED;
    this.dashboardConfig.DynamicParameters.push(newParam);
  }

  ShowLinkedParameters(): void {
    this.showLinkedParameters = true;
  }

  AddLinkedParameter(): void {
    let newParam = new ScriptedParametersOnParameterChange();
    newParam.Description = "New script";
    this.dashboardConfig.DynamicParameters.push(newParam);
  }

  RemoveScriptedParameter(param: ScriptedParametersOnEvent): void {
    for (let i = 0; i < this.dashboardConfig.DynamicParameters.length; i++) {
      if (this.dashboardConfig.DynamicParameters[i] == param) {
        this.dashboardConfig.DynamicParameters.splice(i, 1);
        return;
      }
    }
    // todo
    //this.dashboardConfig.DynamicParameters.
  }

  HideLoadParameters(): void {
    this.showLoadParameters = false;
  }


  ShowLinkedPrameters(): void {
    this.showLoadParameters = false;
    this.showLinkedParameters = true;
  }


  HideLinkedParameters(): void {
    this.showLinkedParameters = false;
  }

  savingDashboard: ClrLoadingState = ClrLoadingState.DEFAULT;

  async onViewWidgetClick(widgetConfig: WidgetConfig) {
    this.viewFullScreen = true;

    widgetConfig.Height = this.mainview.nativeElement.offsetHeight;
  }

  async onExportWidgetClick(widgetConfig: WidgetConfig, exportAs: string) {
    if (exportAs == 'queries') {
      this.widgetService.exportQueries(widgetConfig.Id, (queries, excludeColumns) => {
        if (queries?.length > 0) {
          let params: WidgetExportParameters = new WidgetExportParameters();
          params.name = widgetConfig.Title?.length > 0 ? widgetConfig.Title : widgetConfig.Name;
          params.queries = queries;
          params.decimals = this.dashboardConfig.GlobalWidgetSettings.Decimals;
          params.excludeColumns = excludeColumns;
          this.onWidgetExport?.emit(params);
        }
        else {
          this.widgetService.exportData(widgetConfig.Id, (data) => {
            let params: WidgetExportParameters = new WidgetExportParameters();
            params.name = widgetConfig.Title?.length > 0 ? widgetConfig.Title : widgetConfig.Name;
            params.data = data;
            params.decimals = this.dashboardConfig.GlobalWidgetSettings.Decimals;
            params.excludeColumns = excludeColumns;
            this.onWidgetExport?.emit(params);
          });
        }
      });
    }
    else if (exportAs == 'image') {
      this.widgetService.exportImage(widgetConfig.Id, (href) => {
        widgetConfig.Href = href;
        let link: any = document.getElementById('download-image');
        link.href = href;
        setTimeout(() => {
          link.click();
        });
      });

    }
  }

  exportDashboard() {
    let json: string = TypedJSON.stringify(this.dashboardConfig, DashboardConfig);
    var blob = new Blob([json], { type: "text/plain;charset=utf-8" });
    saveAs(blob, this.exportFilename);
  }



  async setSubscriptionEnabled(enabled: boolean) {
    if (this.subscriptionsEnabled != enabled) {
      if (!this.subscriptionsEnabled) {
        await this.SubscribeAllProjecionData();
      }
      else {
        await this.UnsubscribeAllProjecionData();
      }

      this.subscriptionsEnabled = enabled;
    }
  }

  private async emitOnDataChange() {
    if (this.dashboardConfig) {
      this.dataHasChanged = false;
      this.widgetService.projectionDataChanged(this.projectionIdsChanged);
      this.projectionIdsChanged = [];
    }
  }

  async SubscribeAllProjecionData() {
    if (this.dashboardConfig && this.subscriptionsEnabled) {
      await ArrayUtils.AsyncForEach(this.dashboardConfig.GetSubscriprionData(), async (data: { projectionId: string, group: string[] }) => {
        try {
          await this.xprojClient.RequestSubscribeProjectionData(data.projectionId, data.group);
        }
        catch { }
      });
    }
  }

  async UnsubscribeAllProjecionData() {
    if (this.dashboardConfig) {
      await ArrayUtils.AsyncForEach(this.dashboardConfig.GetSubscriprionData(), async (data: { projectionId: string, group: string[] }) => {
        try {
          await this.xprojClient.RequestUnsubscribeProjectionData(data.projectionId, data.group);
        }
        catch { }
      });
    }
  }

  resetDashboard() {
    this.modalService.ShowConfirmModal({ header: 'Reset', description: 'Reset and clear all widgets, are you sure?' }, (result) => {
      if (result) {
        this.dashboardConfig.WidgetConfigs.length = 0;
        this.dashboardConfig.ResponsiveWidgets?.clear();
        this.originalDashboardConfig.WidgetConfigs = [];
        this.sortWidgetConfigs();
        this.modified = true;
        this.refresh();
      }
    });
  }

  insertDragStart($event, widgetTypeId: string) {
    $event.dataTransfer.setData("widgetTypeId", widgetTypeId);
  }


  setGridItemStyle(config: WidgetConfig): string {
    return `background: ${config.BackgroundColor}; border: ${config.BorderWidth}px solid ${config.BorderColor}; border-radius: ${config.BorderRadius}px`;
  }

  sortWidgetConfigs(): void {
    let configs = [];
    this.widgetInitCounter = this.dashboardConfig.WidgetConfigs.length;
    this.dashboardConfig.WidgetConfigs = this.dashboardConfig.WidgetConfigs.sort((a, b) => {
      if (a.constructor.name != 'MasterWidgetConfig') {
        return -1;
      }

      return 1;
    })

    if (this.dashboardConfig.Grid.ShowSideNav) {
      this.gridWidgetConfigs = this.dashboardConfig.WidgetConfigs.filter(c => !this.dashboardConfig.Grid.SideNavWidgets.find(w => c.Id == w));

      let sidenavs = [];
      this.dashboardConfig.Grid.SideNavWidgets.forEach(id => {
        let widget = this.dashboardConfig.WidgetConfigs.find(c => id == c.Id)
        if (widget) {
          sidenavs.push(widget);
        }
      })
      this.sidenavConfigs = sidenavs;

    }
    else {
      this.gridWidgetConfigs = this.dashboardConfig.WidgetConfigs;
      this.sidenavConfigs = [];
    }


    // this.logger.info("sorting widgets for mobile mode");
    this.gridWidgetConfigs = this.gridWidgetConfigs.sort(
      (w1: WidgetConfig, w2: WidgetConfig) => {
        return (w1.x + w1.y * this.dashboardConfig.Grid.MaxCols) - (w2.x + w2.y * this.dashboardConfig.Grid.MaxCols)
      }
    )

    this.initCounter = this.gridWidgetConfigs.length;
    //this.logger.info('WidgetConfigs', this.dashboardConfig.WidgetConfigs);
  }

  onBeforeWidgetInit(widgetConfig: WidgetConfig) {
  }

  onAfterWidgetInit(widgetConfig: WidgetConfig) {
    this.widgetInitCounter--;
    if (this.widgetInitCounter == 0) {
      setTimeout(() => {
        this.onAllWidgetInitiated();
      });
    }
  }

  onAllWidgetInitiated() {
    this.logger.debug('onAllWidgetInitiated');
    this.updateParameters(this.dashboardOutputParameters);
    this.widgetService.initDone();
  }

  onConfigChanged($event) {
    this.sortWidgetConfigs();
  }

  onResize(): void {
    //this.logger.info('onresizeee');
  }


  onSplitDragEnd($event) {
    //this.saveEditWidth();
    this.refresh();
  }

  getEditArea(): SplitAreaDirective {
    let result: SplitAreaDirective = undefined;
    this.areasEl.forEach(area => {
      if (area.order == 3) {
        result = area;
      }
    });

    return result;
  }

  toogleNav(forceStateExpanded?: boolean) {
    let sidenavCollapsed: boolean = false;

    if (forceStateExpanded != undefined) {
      this.sidenavCollapsed = forceStateExpanded;
    }

    if (this.dashboardConfig.Grid.SideNavPosition == SideNavPosition.RIGHT) {
      if (this.sidenavCollapsed) {
        this.areasEl.forEach(area => {
          if (area.order == 2) {
            if (forceStateExpanded) {
              area.size = this.dashboardConfig.Grid.SideNavWidth;
            }
            area.expand();
          }
        });
        sidenavCollapsed = false;
      }
      else {
        this.areasEl.forEach(area => {
          if (area.order == 2) {
            area.collapse(25, 'left');
          }
        });
        sidenavCollapsed = true;
      }
    }
    else if (this.dashboardConfig.Grid.SideNavPosition == SideNavPosition.LEFT) {
      if (this.sidenavCollapsed) {
        this.areasEl.forEach(area => {
          if (area.order == 1) {
            if (forceStateExpanded) {
              area.size = this.dashboardConfig.Grid.SideNavWidth;
            }
            area.expand();
          }
        });
        sidenavCollapsed = false;
      }
      else {
        this.areasEl.forEach(area => {
          if (area.order == 1) {
            area.collapse(25, 'right');
          }
        });
        sidenavCollapsed = true;
      }
    }
    else if (this.dashboardConfig.Grid.SideNavPosition == SideNavPosition.TOP) {
      if (this.sidenavCollapsed) {
        this.areasEl.forEach(area => {
          if (area.order == 1) {
            if (forceStateExpanded) {
              area.size = this.dashboardConfig.Grid.SideNavWidth;
            }
            area.expand();
          }
        });
        sidenavCollapsed = false;
      }
      else {
        this.areasEl.forEach(area => {
          if (area.order == 1) {
            area.collapse(25, 'right');
          }
        });
        sidenavCollapsed = true;
      }
    }
    else if (this.dashboardConfig.Grid.SideNavPosition == SideNavPosition.BOTTOM) {
      if (this.sidenavCollapsed) {
        this.areasEl.forEach(area => {
          if (area.order == 2) {
            if (forceStateExpanded) {
              area.size = this.dashboardConfig.Grid.SideNavWidth;
            }
            area.expand();
          }
        });
        sidenavCollapsed = false;
      }
      else {
        this.areasEl.forEach(area => {
          if (area.order == 2) {
            area.collapse(30, 'left');
          }
        });
        sidenavCollapsed = true;
      }
    }

    setTimeout(() => {
      this.sidenavCollapsed = sidenavCollapsed;
      this.refresh();
    }, 100);
  }

  // dropped(files: NgxFileDropEntry[]) {
  //   this.modalService.ShowConfirmModal({ header: 'Import dashboard', description: 'Import dashboard, are you sure?' }, (result) => {
  //     this.showImportDialog = false;
  //     if (result) {
  //       this.configfiles = files;
  //       for (const droppedFile of files) {

  //         if (droppedFile.fileEntry.isFile) {
  //           const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
  //           fileEntry.file((file: File) => {
  //             const reader = new FileReader();
  //             reader.onerror = (e) => {
  //               this.logger.info("Error importing file ");
  //               this.logger.info(e);
  //             }
  //             reader.onloadend = (e) => {
  //               const json = reader.result.toString().trim();
  //               try {
  //                 this.targetResolution = Resolutions.Sandbox;
  //                 let importedConfig = TypedJSON.parse(json, DashboardConfig);

  //                 this.dashboardConfig.update(importedConfig);
  //                 this.subscriptionsEnabled = this.enableSubscriptions && this.dashboardConfig.SubscriptionsEnabled;
  //                 this.originalDashboardConfig = TypedJSON.parse(TypedJSON.stringify(this.dashboardConfig, DashboardConfig), DashboardConfig);

  //                 if (this.dashboardConfig.Grid.ShowSideNav) {
  //                   setTimeout(() => {
  //                     this.toogleNav(true);
  //                   });
  //                 }

  //                 this.markedWidgetConfig = undefined;
  //                 this.copiedWidgetConfig = undefined;
  //                 this.sortWidgetConfigs();
  //                 this.setGridOptions();
  //                 this.gridoptions.api?.optionsChanged();
  //                 this.modified = true;
  //               }
  //               catch {
  //                 this.logger.info('Error import config!!!');
  //               }
  //             }
  //             reader.readAsText(file);

  //             //file.text() - not supported in all browser yet!
  //             // file.text().then(json => {
  //             //   try {
  //             //     let importedConfig = TypedJSON.parse(json, DashboardConfig);
  //             //     //this.logger.info('import', importedConfig)
  //             //     //this.config.Id = importedConfig.Id;
  //             //     this.config.LinkAllWidgets = importedConfig.LinkAllWidgets;
  //             //     this.config.WidgetConfigs = importedConfig.WidgetConfigs;
  //             //     this.config.Grid = importedConfig.Grid;
  //             //     this.dashboardService.saveDashboard(this.config);
  //             //   }
  //             //   catch {
  //             //     this.logger.info('Error import config!!!');
  //             //   }
  //             // });
  //           });
  //         } else {
  //           // It was a directory (empty directories are added, otherwise only files)
  //           const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
  //           this.logger.info(droppedFile.relativePath, fileEntry);
  //         }
  //       }
  //     }
  //   });
  // }

}
