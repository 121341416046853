import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Inject, NgZone, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { GridsterItemComponentInterface } from 'angular-gridster2';
import { BaseQuery, BaseQueryInputColumnDescription, BaseQueryResult, ColumnFilteringNumerical, ColumnFilteringRelativeTimestamp, ColumnFilteringString, ColumnFilteringTimestamp, ColumnGroupingDescription, XDataType, XProjectorClient } from '../../../XProjector/xprojector-client-service';
import { WidgetBase } from '../../widget-base';
import { LinkedWidgetChangeParameters, WidgetOutputChangeParameters, XprojWidgetService } from '../../xproj-widget-service';
import { Scripted3dColumnConfig, Scripted3dWidgetConfig, Scripted3dWidgetQuery } from '../scripted3d-widget-config/xproj-scripted3d-widget-config-service';
import { ArrayUtils } from '../../../utils/array-utils-service';
import { GroupSelectionTypes, OutputDataType, WidgetInputParameterAction, WidgetOutputParameter } from '../../widget-config-service';
import { WidgetUtils } from '../../../utils/widget-utils-service';

//import { Engine, Scene, ArcRotateCamera, HemisphericLight, Vector3, MeshBuilder, Mesh, FreeCamera, StandardMaterial, Color3, Color4, ValueCondition, BabylonFileLoaderConfiguration } from "babylonjs";
import * as BABYLON from 'babylonjs';
import * as BABYLONMATERIALS from 'babylonjs-materials';
import * as BABYLONPROCTEX from 'babylonjs-procedural-textures';
import * as BABYLONGUI from 'babylonjs-gui';

import { DateHelper } from '../../../helpers/date-helper-service';
import { LOGGERSERVICE, XprojLoggerService } from '../../../logger/xproj-logger-service';
import {XprojLuaDashboardWrapper} from '../../../dashboard/xproj-lua';
import * as fengari from 'fengari-web';
import { XPROJBIMSERVICE, XprojBimService } from '../../../services/xproj-bim.service';
import { Console } from 'console';

export class cubePoint
{
  pos : BABYLON.Vector3 = new BABYLON.Vector3();
  lghid: string = "";
  value: number = 0;
}

@Component({
  selector: 'xproj-scripted3d-widget',
  templateUrl: './xproj-scripted3d-widget.component.html',
  styleUrls: ['./xproj-scripted3d-widget.component.scss'],
  changeDetection:ChangeDetectionStrategy.OnPush
})
export class XprojScripted3ddWidgetComponent extends WidgetBase implements OnInit, OnDestroy, AfterViewInit {

  private xprojBimService: XprojBimService = inject(XPROJBIMSERVICE);

  widgetConfig: Scripted3dWidgetConfig;
  id: any;
  loading = false;
  private initiated: boolean = false;
  private updateSvgSize: boolean = true;

  lua : XprojLuaDashboardWrapper = null;


  private engine: BABYLON.Engine;

  clicktimer: any;

  constructor(
    @Inject(LOGGERSERVICE) public logger: XprojLoggerService,
    public xprojClient: XProjectorClient,
    public widgetService: XprojWidgetService, private ngZone: NgZone) {
      super(logger, xprojClient, widgetService);
      this.lua = widgetService.lua;
  }



  private scene: BABYLON.Scene;
  private canvas: HTMLCanvasElement;
  cubePoints: cubePoint[]  = [];

  furTexture;
  furMaterial;
  grassTexture;
  public shells;
  clickablecontent={};
  UITexture:BABYLONGUI.AdvancedDynamicTexture = null;
  shadowGenerator;
  skybox = null;
  alphamat = null;
  pickedEdgeRendering={}
  public ground = null;
  prevPickedMesh = null;
  prevPickedEdgeColor = null;
  prevPickedEdgeEnabled = false;
  dataQueriesResult={}
  optimizer = null;

  resetSceneContent()
  {
    this.dataQueriesResult={};
    this.pickedEdgeRendering = {};
    this.prevPickedEdgeColor=null;
    this.prevPickedMesh=null;
    this.clickablecontent = {};

    if(this.optimizer)
    {
      this.optimizer.dispose();
      this.optimizer =null;
    }
    if(this.ground)
    {
      this.ground.dispose();
      this.ground = null;
    }
    if(this.skybox)
    {
      this.skybox.dispose();
      this.skybox = null;
    }
    if(this.alphamat)
    {
      this.alphamat.dispose();
      this.alphamat = null;
    }
    if(this.shadowGenerator)
    {
      this.shadowGenerator.dispose();
      this.shadowGenerator = null;
    }
    if(this.UITexture)
    {
      this.UITexture.dispose();
      this.UITexture=null;
    }

    if(this.furTexture)
    {
      this.furTexture.dispose();
      this.furTexture = null;
    }
    if(this.furMaterial)
    {
      this.furMaterial.dispose();
      this.furMaterial = null;
    }
    if(this.shells)
    {
      for (let i = 0; i < this.shells.length; i++)
      {
        this.shells[i].dispose();
      }
      this.shells = null;
    }
  }


  Lua_PARAMS_GetWidgetParamNumber(id:string):number
  {
    let param = this.config.InputParameters.find( x => x.id == id );
    if(!param) return 0;
    let rval = this.getParameterValue(param.id, WidgetUtils.GetInputValue(param.initValue, param.datatype)).value as number;
    return  rval;
  }
  Lua_PARAMS_GetWidgetParamString(id:string):string
  {
    let param = this.config.InputParameters.find( x => x.id == id );
    if(!param) return "";
    let rval = this.getParameterValue(param.id, param.initValue).value as string;
    return  rval;
  }



  Lua_MESH_AddClickable(id:string, key:string, value:any)
  {
    let m = this.scene.getMeshById(id);
    if(m)
    {
      //console.log("clickable: Setting is pickable");
      m.isPickable=true;
    }
    if(!m)
    {
      console.log("C")
    }
    let t = this.clickablecontent[id];
    if(!t)
    {
      t={};
      this.clickablecontent[id] = t;
    }
    t[key] = value;

    //console.log("clickable type for", id, key, value, typeof(value) );

    let wop = this.widgetConfig.OutputParameters.find(x=>x.id==key);
    if (wop)
    {
      wop.datatype = OutputDataType.String;
      if(typeof(value)=="number")
        wop.datatype = OutputDataType.Float64;

      return;
    }

    let output = new WidgetOutputParameter();
    output.id = key;
    output.name = key;
    output.datatype = OutputDataType.String;
    if(typeof(value)=="number")
      output.datatype = OutputDataType.Float64;

    this.widgetConfig.OutputParameters.push(output);
  }

  Lua_MESH_setGrass(id:string)
  {
    console.log("Creating fur for", id);
    if(!this.furTexture)
    {
      console.log("generating texture and material");
      this.grassTexture = new BABYLONPROCTEX.GrassProceduralTexture("grassTex", 256, this.scene);
      this.furTexture = BABYLONMATERIALS.FurMaterial.GenerateTexture("furTexture", this.scene);
      this.furMaterial = new BABYLONMATERIALS.FurMaterial("furMaterial", this.scene);
      this.furMaterial.highLevelFur = true;
      this.furMaterial.furTexture = this.furTexture;
      this.furMaterial.diffuseTexture = this.grassTexture;

      this.furMaterial.furLength = 1; //4
      this.furMaterial.furAngle = 0;
      this.furMaterial.furColor = new BABYLON.Color3(1, 1, 1);
      this.furMaterial.furSpacing = 5; // 6
      this.furMaterial.furDensity = 50; // 10
      this.furMaterial.furSpeed = 1000; // 200
      this.furMaterial.furGravity = new BABYLON.Vector3(0, -1, 0);
    }

    this.furMaterial.specularColor = new BABYLON.Color3(0, 0, 0);
    var mesh = this.scene.getMeshById(id);// as BABYLON.Mesh;
    mesh.material = this.furMaterial;

    var quality = 30; // 30=Average quality

    // Create shells
    let m = mesh as BABYLON.Mesh;
    if(!m)
       console.error("M is not mesh!");
    this.shells = BABYLONMATERIALS.FurMaterial.FurifyMesh(m, quality);
    for (let i = 0; i < this.shells.length; i++)
    {
      //ground.receiveShadows = true;
      //this.shells[i].material = this.furMaterial;
      this.shells[i].receiveShadows = true;
    }

    console.log("done applying fur to", id);

    this.scene.fogMode = BABYLON.Scene.FOGMODE_EXP2;
    this.scene.fogColor= new BABYLON.Color3(172/255.0, 184/255.0, 191/255.0);
    this.scene.fogDensity = 0.002;

        // Sky material
    var skyboxMaterial = new BABYLONMATERIALS.SkyMaterial("skyMaterial", this.scene);
    skyboxMaterial.backFaceCulling = false;
    skyboxMaterial.useSunPosition = true; // Do not set sun position from azimuth and inclination
    skyboxMaterial.sunPosition = new BABYLON.Vector3(20, 100, 20);

    skyboxMaterial.mieDirectionalG = 0.8;
    skyboxMaterial.mieCoefficient = 0.005; // The mieCoefficient in interval [0, 0.1], affects the property skyMaterial.mieDirectionalG
    skyboxMaterial.cameraOffset.y = this.scene.activeCamera.globalPosition.y;

    this.skybox = BABYLON.Mesh.CreateBox("skyBox", 1000.0, this.scene);
    this.skybox.isPickable=false;
    this.skybox.clickable
    this.skybox.material = skyboxMaterial;
    skyboxMaterial.inclination = 0;
    this.skybox.applyFog = false;
  }


  Lua_Update_Linked_TEXT_Button(id:string, text:string,  width:number, height:number, x:number, y:number,
    r:number, g:number, b:number, a:number,
    rt:number, gt:number, bt:number, at:number,
    linkid:string)
  {
    if(!this.UITexture)
      return;
    console.log("updating text for", id)
    let rectcontrol = this.UITexture.getControlByName("rect"+id) as BABYLONGUI.Rectangle;
    if(rectcontrol)
    {
      console.log("updating rect text for", id)
      rectcontrol.background = "rgba(" + r.toString() + "," + g.toString() + "," + b.toString() + "," + a.toString() + ")";// new BABYLON.Color4(r,g,b,a);
      rectcontrol.widthInPixels = width;
      rectcontrol.heightInPixels = height;
      rectcontrol.linkOffsetY = y;
      rectcontrol.linkOffsetX = x;
    }

    let textcontrol = this.UITexture.getControlByName("text"+id) as BABYLONGUI.TextBlock;
    if(textcontrol)
    {
      console.log("updating text text for", id, "to", text)
      textcontrol.text = text;
      textcontrol.color = "rgba(" + rt.toString() + "," + gt.toString() + "," + bt.toString() + "," + at.toString() + ")";// new BABYLON.Color4(r,g,b,a);

    }
  }

  Lua_Linked_TEXT_Button(id:string, text:string, width:number, height:number, x:number, y:number,
    r:number, g:number, b:number, a:number,
    rt:number, gt:number, bt:number, at:number,
    linkid:string )
  {
    // GUI
    if(!this.UITexture)
      this.UITexture = BABYLONGUI.AdvancedDynamicTexture.CreateFullscreenUI("UI");

    var rect1 = new BABYLONGUI.Rectangle();
    rect1.widthInPixels = width;
    rect1.heightInPixels = height;
    /*
    rect1.cornerRadius = 20;
    rect1.color = "Orange";
    rect1.thickness = 4;*/
    rect1.thickness = 0;
    rect1.name="rect"+id;


    rect1.background = "rgba(" + r.toString() + "," + g.toString() + "," + b.toString() + "," + a.toString() + ")";// new BABYLON.Color4(r,g,b,a);
    this.UITexture.addControl(rect1);

    var label = new BABYLONGUI.TextBlock();
    label.text = text;
    label.name = "text"+id;
    label.color = "rgba(" + rt.toString() + "," + gt.toString() + "," + bt.toString() + "," + at.toString() + ")";// new BABYLON.Color4(r,g,b,a);
    rect1.addControl(label);

    rect1.linkWithMesh( this.scene.getMeshById(linkid) );
    rect1.linkOffsetY = y;
    rect1.linkOffsetX = x;
  }

  Lua_MESH_setPickedEdgeRendering(id:string, width:number, r: number, g: number, b:number, a:number)
  {
    this.pickedEdgeRendering[id] = new BABYLON.Color4(r, g, b, a);
  }

  Lua_MESH_setEdgeRendering(id:string, enable:boolean, only: boolean, width:number, r: number, g: number, b:number, a:number, biga:number)
  {
    if(!this.alphamat)
    {
      this.alphamat = new BABYLON.StandardMaterial('alphamat', this.scene);
      this.alphamat.diffuseColor = BABYLON.Color3.White();
      //this.alphamat.emissiveColor = new BABYLON.Color3(1.0, .1, .1); // not necessary artistic decision
      this.alphamat.emissiveColor = new BABYLON.Color3(1.0, 1.0, 1.0); // not necessary artistic decision
      this.alphamat.alpha = biga;//0.05;
    }
    var mesh = this.scene.getMeshById(id);// as BABYLON.Mesh;
    if(only)
    {
      mesh.material = this.alphamat;
    }
    if(!enable)
    {
      mesh.disableEdgesRendering();
      return;
    }

    mesh.enableEdgesRendering();
    mesh.edgesWidth = width;
    mesh.edgesColor = new BABYLON.Color4(r, g, b, a);
  }

  Lua_MESH_setColor(id: string,  r: number,g : number, b: number, a:number)
  {
    // debugger;
    var mesh = this.scene.getMeshById(id);// as BABYLON.Mesh;
    var colors = mesh.getVerticesData(BABYLON.VertexBuffer.ColorKind);

    if (!colors)
    {
      let nbVertices = mesh.getPositionData().length;
      colors = new Array(4 * nbVertices);
      colors = colors.fill(0);
    }

    for (let i = 0 ; i < colors.length; )
    {
      colors[i++] = r;
      colors[i++] = g;
      colors[i++] = b;
      colors[i++] = a;
    }
    mesh.setVerticesData(BABYLON.VertexBuffer.ColorKind, colors);

    if(a > 0.99999)
      return;

    let material = this.scene.getMaterialById(id);
    if(!material)
    {
      material = new BABYLON.StandardMaterial(id, this.scene);
      mesh.material = material;
    }
    material.alpha = a;

    //mesh.vertices

    // var myMaterial = new BABYLON.StandardMaterial("myMaterial", this.scene);
    // myMaterial.diffuseColor = new Color3(1, 1, 1);
    // myMaterial.specularColor = new Color3(0.5, 0.6, 0.87);
    // myMaterial.emissiveColor = new Color3(1, 1, 1);
    // myMaterial.ambientColor = new Color3(0.23, 0.98, 0.53);
    // myMaterial.alpha = 0.5;
  }

  useShadows = false;
  LuaCreateHemisphericalLight(id: string, x:number, y:number, z:number, intensity: number)
  {
    console.log("Creating light", id);
    //var light = new BABYLON.HemisphericLight(id, new BABYLON.Vector3(x, y, z), this.scene);
    var light = new BABYLON.DirectionalLight(id, new BABYLON.Vector3(-1, -2, -1), this.scene);
    light.position = new BABYLON.Vector3(x, y, z);
    light.intensity = intensity;//0.7;


    if(this.shadowGenerator)
    {
      this.shadowGenerator.dispose();
      this.shadowGenerator = null;
    }

    if(this.useShadows)
    {
    this.shadowGenerator = new BABYLON.ShadowGenerator(512, light)
    this.shadowGenerator.useExponentialShadowMap = true;
    this.shadowGenerator.transparencyShadow = true;
    }

    // var lensEffect = new BABYLON.LensRenderingPipeline('lens', {
    //   edge_blur: 1.0,
    //   chromatic_aberration: 1.0,
    //   distortion: 1.0,
    //   dof_focus_distance: 100, // 50
    //   dof_aperture: 6.0,			// set this very high for tilt-shift effect
    //   grain_amount: 1.0,
    //   dof_pentagon: true,
    //   dof_gain: 1.0,
    //   dof_threshold: 1.0,
    //   dof_darken: 0.25
    // }, this.scene, 1.0, [this.scene.activeCamera]);


    // Default intensity is 1. Let's dim the light a small amount
    console.log("Creating light done for", id);
  }

  Lua_MESH_AddRotation(id:string, x: number, y: number, z:number)
  {
    let m = this.scene.getMeshById(id);
    m.rotation.x += x;
    m.rotation.y += y;
    m.rotation.z += z;
  }

  Lua_MESH_SetRotation(id:string, x: number, y: number, z:number)
  {
    let m = this.scene.getMeshById(id);
    m.rotation.x = x;
    m.rotation.y = y;
    m.rotation.z = z;
  }

  Lua_MESH_createGround( id:string, wx:number,wy:number, subdivisions:number)
  {
    if(this.ground)
      return;
    this.ground = BABYLON.MeshBuilder.CreateGround(id,  { width: wx,  height:wy, updatable: true, subdivisions: subdivisions });
    this.ground.position.y = -4.05;
    this.ground.receiveShadows = true;
    this.ground.isPickable = false;

    // https://forum.babylonjs.com/t/arcrotatecamera-limiting-the-rotation/11639
    
  }

  // const shape = BABYLON.Mesh.CreatePolyhedron("shape" + i, {type: i, size: 3}, scene);
  // shape.position = new BABYLON.Vector3(-30 + 15 * (i % 5), 20 + (-15 * Math.floor(i/5)), 0);

  Lua_MESH_createBox( id:string, x:number,y :number,z: number, wx:number,wy:number,wz:number)
  {
    //{ width: nrWidth*spacingFactor, height:nrHeight*spacingFactor, depth:nrDepth*spacingFactor, faceColors: scolors }
    var cube = BABYLON.MeshBuilder.CreateBox(id,  { width: wx,  height:wy,  depth:wz, updatable: true });

    cube.position.x = x;
    cube.position.y = y;
    cube.position.z = z;

    if(this.shadowGenerator)
      this.shadowGenerator.getShadowMap().renderList.push(cube);

    cube.isPickable = false;

    cube.applyFog=false;
  }

  Lua_MESH_createBox_Chose_Sides( id:string, x:number,y :number,z: number, wx:number,wy:number,wz:number,
      includea: boolean,includeb: boolean,includec: boolean,included: boolean,includee: boolean,includef: boolean)
  {
    //{ width: nrWidth*spacingFactor, height:nrHeight*spacingFactor, depth:nrDepth*spacingFactor, faceColors: scolors }

    var cube = BABYLON.MeshBuilder.CreateBox(id,  { width: wx,  height:wy,  depth:wz, updatable: true });
    cube.position.x = x;
    cube.position.y = y;
    cube.position.z = z;
    cube.isPickable = false;


    // cube.enableEdgesRendering();
    // cube.edgesWidth = 20.0;
    // cube.edgesColor = new BABYLON.Color4(1, 0.5, 0.09, 1);

    if(this.shadowGenerator)
      this.shadowGenerator.getShadowMap().renderList.push(cube);

    cube.applyFog=false;

    let inds_a= [2, 3, 0, 2, 0, 1];
    let inds_b= [4, 5, 6, 4, 6, 7];
    let inds_c= [9, 10, 11, 9, 11, 8];
    let inds_d= [12, 14, 15, 12, 13, 14];

    let inds = [];
    if(includea)
      inds = inds.concat(inds_a);
    if(includeb)
      inds = inds.concat(inds_b);
    if(includec)
      inds = inds.concat(inds_c);
    if(included)
      inds = inds.concat(inds_d);

    const topFaceOrder: any = [17, 18, 19, 16];
    const bottomFaceOrder: any = [22, 23, 20, 21];
    if(includee)
      inds.push(topFaceOrder[0], topFaceOrder[2], topFaceOrder[3], topFaceOrder[0], topFaceOrder[1], topFaceOrder[2]);
    if(includef)
      inds.push(bottomFaceOrder[0], bottomFaceOrder[2], bottomFaceOrder[3], bottomFaceOrder[0], bottomFaceOrder[1], bottomFaceOrder[2]);


    cube.setIndices( inds);
  }

  Lua_SCENE_setclearcolor(r:number, g:number, b:number,a:number)
  {
    this.scene.clearColor = new BABYLON.Color4(r, g, b, a);
  }
  Lua_SCENE_setenvironmentintensity(intensity:number)
  {
    this.scene.environmentIntensity = intensity;
  }

  Lua_WIDGET_getData(query:string) : BaseQueryResult
  {
    let res = this.dataQueriesResult[query];
    if(!query)
      return null;

    return res;
  }

  // https://forum.babylonjs.com/t/how-can-i-make-the-transparent-background/1216/7
  async loadScene()
  {
    return;
    const filename = "habitat7";//"habitat7.babylon";
    //const root = "https://demo.robotrisa.com/api/v1/file/nocache/";
    const root = "/api/v1/file/nocache/";
    console.log("Loading " + root+filename);

    await BABYLON.SceneLoader.AppendAsync(root, filename, this.scene);
    console.log("Loaded..");

    this.scene.clearColor = new BABYLON.Color4(0.2, 0.2, 0.3, 0.01);
    this.scene.environmentIntensity  = 0;


    var camera = new BABYLON.ArcRotateCamera("camera1", 0,0,10000, new BABYLON.Vector3(0,0,0), this.scene);

    // This targets the camera to scene origin
    camera.setTarget(new BABYLON.Vector3( 0,0, 0 ));

    // // This attaches the camera to the canvas
    camera.attachControl(this.canvas, true, true);

    // this.scene.setActiveCameraById("camera1");

    //this.scene.createDefaultCameraOrLight(true, true, true);
    // BABYLON.SceneLoader.Load(root, filename,this.engine, (scene: BABYLON.Scene) => {
    //   console.log("Loaded!");
    //   that.scene = scene;


    //   //let camera = new BABYLON.FollowCamera("camera1", new BABYLON.Vector3(0,0,-100), this.scene, this.scene.meshes[0]);
    //    var camera = new BABYLON.ArcRotateCamera("camera1", 0,0,0, new BABYLON.Vector3(0, 0, 0), that.scene);
    //   // // This targets the camera to scene origin
    //    camera.setTarget(new BABYLON.Vector3( 0, 0, 0 ));
    //   // // This attaches the camera to the canvas
    //   //camera.attachControl();
    //   camera.attachControl(that.canvas, true, true);

      /*console.log("Creating camera");

      camera = new BABYLON.FreeCamera("camera1", new BABYLON.Vector3(0, 0, -100000), that.scene);

      // This targets the camera to scene origin
      camera.setTarget(BABYLON.Vector3.Zero());

      // This attaches the camera to the canvas
      camera.attachControl(that.canvas, true);

      console.log("Loaded and camera..");*/
  //});
  }
  runDataUpdateScript()
  {
    if(this.widgetConfig.OnDataUpdateLuaScript && this.widgetConfig.OnDataUpdateLuaScript.length > 0)
      {
        this.ngZone.runOutsideAngular(() => {this.lua.RunScript(this.widgetConfig.OnDataUpdateLuaScript);})
      }

  }

  Lua_SceneLoad(rootUrl:string, filename:string)
  {
    BABYLON.SceneLoader.Append(rootUrl, filename, this.scene);
  }

  extraInternalLua = "";

  registerLua() : number
  {
    let nrPops = 0;
    {
      fengari.interop.push(fengari.L, this);
      fengari.lua.lua_setglobal(fengari.L, "scene");
      nrPops++;
    }
    {
      {
        fengari.interop.push(
          fengari.L,
          this.Lua_SceneLoad
        );
        fengari.lua.lua_setglobal(fengari.L, "lua_scene_load");
      }
      nrPops++;
    }
    {
      {
        fengari.interop.push(
          fengari.L,
          this.Lua_MESH_createBox
        );
        fengari.lua.lua_setglobal(fengari.L, "lua_mesh_createbox");
      }
      nrPops++;
    }
    {
      {
        fengari.interop.push(
          fengari.L,
          this.Lua_MESH_createBox_Chose_Sides
        );
        fengari.lua.lua_setglobal(fengari.L, "lua_mesh_createbox_selectsides");
      }
      nrPops++;
    }
    {
      {
        fengari.interop.push(
          fengari.L,
          this.Lua_MESH_createGround
        );
        fengari.lua.lua_setglobal(fengari.L, "lua_mesh_createground");
      }
      nrPops++;
    }
    {
      {
        fengari.interop.push(
          fengari.L,
          this.Lua_Linked_TEXT_Button
        );
        fengari.lua.lua_setglobal(fengari.L, "lua_linked_text_button");
      }
      nrPops++;
    }
    {
      {
        fengari.interop.push(
          fengari.L,
          this.Lua_Update_Linked_TEXT_Button
        );
        fengari.lua.lua_setglobal(fengari.L, "lua_update_linked_text_button");
      }
      nrPops++;
    }
    {
      {
        fengari.interop.push(
          fengari.L,
          this.Lua_MESH_setColor
        );
        fengari.lua.lua_setglobal(fengari.L, "lua_mesh_setcolor");
      }
      nrPops++;
    }
    {
      {
        fengari.interop.push(
          fengari.L,
          this.Lua_MESH_setEdgeRendering
        );
        fengari.lua.lua_setglobal(fengari.L, "lua_mesh_setedgerendering");
      }
      nrPops++;
    }
    {
      {
        fengari.interop.push(
          fengari.L,
          this.Lua_MESH_setPickedEdgeRendering
        );
        fengari.lua.lua_setglobal(fengari.L, "lua_mesh_setpickededgerendering");
      }
      nrPops++;
    }

    {
      {
        fengari.interop.push(
          fengari.L,
          this.Lua_MESH_setGrass
        );
        fengari.lua.lua_setglobal(fengari.L, "lua_mesh_setgrass");
      }
      nrPops++;
    }
    {
      {
        fengari.interop.push(
          fengari.L,
          this.LuaCreateHemisphericalLight
        );
        fengari.lua.lua_setglobal(fengari.L, "lua_light_createhemispherical");
      }
      nrPops++;
    }
    {
      {
        fengari.interop.push(
          fengari.L,
          this.Lua_MESH_AddRotation
        );
        fengari.lua.lua_setglobal(fengari.L, "lua_mesh_addrotation");
      }
      nrPops++;
    }
    {
      {
        fengari.interop.push(
          fengari.L,
          this.Lua_MESH_SetRotation
        );
        fengari.lua.lua_setglobal(fengari.L, "lua_mesh_setrotation");
      }
      nrPops++;
    }
    {
      {
        fengari.interop.push(
          fengari.L,
          this.Lua_PARAMS_GetWidgetParamNumber
        );
        fengari.lua.lua_setglobal(fengari.L, "lua_widget_param_get_number");
      }
      nrPops++;
    }
    {
      {
        fengari.interop.push(
          fengari.L,
          this.Lua_PARAMS_GetWidgetParamString
        );
        fengari.lua.lua_setglobal(fengari.L, "lua_widget_param_get_string");
      }
      nrPops++;
    }
    {
      {
        fengari.interop.push(
          fengari.L,
          this.Lua_SCENE_setclearcolor
        );
        fengari.lua.lua_setglobal(fengari.L, "lua_scene_setclearcolor");
      }
      nrPops++;
    }
    {
      {
        fengari.interop.push(
          fengari.L,
          this.Lua_MESH_AddClickable
        );
        fengari.lua.lua_setglobal(fengari.L, "lua_mesh_add_clickable");
      }
      nrPops++;
    }
    {
      {
        fengari.interop.push(
          fengari.L,
          this.Lua_SCENE_setenvironmentintensity
        );
        fengari.lua.lua_setglobal(fengari.L, "lua_scene_setenvironmentintensity");
      }
      nrPops++;
    }
    {
      {
        fengari.interop.push(
          fengari.L,
          this.Lua_WIDGET_getData
        );
        fengari.lua.lua_setglobal(fengari.L, "lua_widget_getdata");
      }
      nrPops++;
    }

    // BMS 3D
    fengari.interop.push(fengari.L, this.xprojBimService);
    fengari.lua.lua_setglobal(fengari.L, "xprojBimService");
    {
      {
        fengari.interop.push(
          fengari.L,
          this.xprojBimService.getFloorplanFile
        );
        fengari.lua.lua_setglobal(fengari.L, "lua_bms_get_floorplanfile");
      }
      nrPops++;
    }

    let extraInternalLuaBMS = `

    function bms_get_floorplan(id, customerid)
        local t = lua_bms_get_floorplanfile(xprojBimService, id,customerid)
        local co = coroutine.running()

        t["then"](t,
            function()
              coroutine.resume(co)
            end)
        coroutine.yield()
        return xprojBimService.latestLoaded;
    end
    `;

    //addBox
    //this.setColor

    this.extraInternalLua =   `

    function load_scene(rooturl, filename)
        lua_scene_load(scene, rooturl, filename)
    end

    function create_box(id, x, y, z, wx, wy, wz)
      lua_mesh_createbox(scene, id, x,y,z, wx,wy,wz)
    end

    function create_box_sides(id, x, y, z, wx, wy, wz, includea,includeb,includec,included,includee,includef)
      lua_mesh_createbox_selectsides(scene,id, x, y, z, wx, wy, wz, includea,includeb,includec,included,includee,includef)
    end

    function create_ground(id, wx, wy, subdivisions)
      lua_mesh_createground(scene, id, wx,wy,subdivisions)
    end

    function create_linked_text_button(id, text, width, height, x, y, r, g, b, a, rt,gt,bt,at, linkid)
      print("doing text with id",id)
      lua_linked_text_button(scene, id, text, width, height, x, y, r, g, b, a, rt,gt,bt,at, linkid)
    end

    function update_linked_text_button(id, text, width, height, x, y, r, g, b, a, rt,gt,bt,at)
      lua_update_linked_text_button(scene, id, text, width, height, x, y, r, g, b, a, rt,gt,bt,at)
    end

    function set_grass(id)
      lua_mesh_setgrass(scene, id)
    end

    function set_color(id, r,g,b,a)
      lua_mesh_setcolor(scene, id, r,g,b,a)
    end

    function set_edge_rendering(id, enable, only, w, r,g,b,a, biga)
      lua_mesh_setedgerendering(scene, id, enable, only, w, r,g,b,a, biga)
    end

    function set_picked_edge_rendering(id, w, r,g,b,a)
      lua_mesh_setpickededgerendering(scene, id, w, r,g,b,a)
    end


    function create_light_hemispherical(id, x,y,z,intensity)
      lua_light_createhemispherical(scene, id, x,y,z,intensity)
    end

    function add_rotation(id, x,y,z)
      lua_mesh_addrotation(scene, id, x,y,z)
    end

    function set_rotation(id, x,y,z)
      lua_mesh_setrotation(scene, id, x,y,z)
    end

    function set_clearcolor(r,g,b,a)
      lua_scene_setclearcolor(scene,r,g,b,a)
    end

    function mesh_add_clickable(id, key, val)
      lua_mesh_add_clickable(scene, id,key,val)
    end

    function set_envintensity(intensity)
      lua_scene_setenvironmentintensity(scene,intensity)
    end

    function widget_data_get(queryname)
      return lua_widget_getdata(scene, queryname)
    end

    function widget_param_get_string(id)
      local rval= lua_widget_param_get_number(scene,id)
      print("Fetched nstring widget param lua", id, rval)
      return rval;
    end

    function widget_param_get_number(id)
      local rval= lua_widget_param_get_number(scene,id)
      print("Fetched number widget param lua", id, rval)
      return rval;
    end
` + "\n" + extraInternalLuaBMS;

    return nrPops;
  }

  runBeforeRenderScript()
  {
    if(!this.widgetConfig.BeforeRenderLuaScript || this.widgetConfig.BeforeRenderLuaScript.length ==0)
      return;
    this.ngZone.runOutsideAngular(() => {
      let nrPops = this.registerLua();
      this.lua.RunScript(this.widgetConfig.BeforeRenderLuaScript, this.extraInternalLua, nrPops);
    });
  }

  verifyCamera()
  {
    // let camera = this.scene.activeCamera;

    // const ray = new BABYLON.Ray(camera.position, BABYLON.Vector3.Down(), 1).intersectsMesh(this.ground)
    // if (ray.hit) {
    //   camera.set
    //   camera.setPosition(new Vector3(this.camera.position.x, ray.pickedPoint!.y + 1, this.camera.position.z))
    // }
    // this.scene.activeCameras.

  }


  runInitScript()
  {
    //let nrDepth = 5;
    // let nrHeight = 100;
    // let nrWidth = 100;

    this.ngZone.runOutsideAngular(() => {

      this.resetSceneContent();

      this.scene?.dispose();
      this.scene = new BABYLON.Scene(this.engine);

      var options = new BABYLON.SceneOptimizerOptions(60,500);
      options.addOptimization(new BABYLON.TextureOptimization(0, 256));
      options.addOptimization(new BABYLON.RenderTargetsOptimization(1));
      options.addOptimization(new BABYLON.ShadowsOptimization(2));
      options.addOptimization(new BABYLON.HardwareScalingOptimization(5, 4));

      class ToggleGrass extends BABYLON.CustomOptimization{
        constructor(priority, private widget:XprojScripted3ddWidgetComponent){
            super(priority)
        }

        onApply = () => {
          //console.log("grass stuff");
          if(this.widget.shells)
            {
              for (let i = 0; i < this.widget.shells.length; i++)
              {
                this.widget.shells[i].dispose();
              }
              this.widget.shells = null;
            }

          return true;
        }
        onGetDescription = () => {
            return "Turning grass";
        }
    };

    options.addOptimization(new ToggleGrass(3, this));

      // Optimizer
      this.optimizer = new BABYLON.SceneOptimizer(this.scene, options);

      if(this.widgetConfig.BeforeRenderLuaScript && this.widgetConfig.BeforeRenderLuaScript.length > 0 )
      {
        let that = this;
        this.scene.registerBeforeRender(function()
        {
          that.verifyCamera();
        });

        this.scene.registerBeforeRender(function()
        {
          that.runBeforeRenderScript();
        });
      }

      //this.scene.clearColor = new Color4(0.5, 0.0, 0.5, 0);

      // This creates and positions a free camera (non-mesh)
      //var camera = new BABYLON.ArcRotateCamera("camera1", 0,1,1, new BABYLON.Vector3(-nrWidth/2, 0, -nrWidth*1.5), this.scene);
      //camera.useAutoRotationBehavior = true;

      var camera = new BABYLON.ArcRotateCamera("camera", Math.PI / 2, Math.PI / 2, 200, new BABYLON.Vector3(0,10,0), this.scene);
      //camera.attachControl(this.canvas, true,true,2);
      //camera.attachControl(false, true,3);
      camera.attachControl(this.canvas);

      camera._panningMouseButton = 2;
      camera.panningSensibility = 30;
      //camera.angularSensibilityY = 1;


      // This makes panning does not work, we could activate this when clicking
      var CoT = new BABYLON.TransformNode("root");
      camera.lockedTarget = CoT;

      let cam = this.scene.activeCamera as BABYLON.ArcRotateCamera;
      //cam.upperBetaLimit=Math.PI/2-0.04;//0.1;        
      cam.alpha = 0;


      this.scene.onPointerObservable.add((pointerInfo) => {
        this.ngZone.runOutsideAngular(() => {
          switch (pointerInfo.type) {
            case BABYLON.PointerEventTypes.POINTERDOWN:
              //console.log("scene pointer event. pointerInfo obj:", pointerInfo);
              // FROM https://www.babylonjs-playground.com/#7B4S6A#1


                console.log("scene pointer event - pickedMesh:",
                          (pointerInfo.pickInfo.pickedMesh ? pointerInfo.pickInfo.pickedMesh.name : "none"));

                      if (pointerInfo.pickInfo.pickedMesh
                        && pointerInfo.pickInfo.pickedMesh != this.ground
                        && pointerInfo.pickInfo.pickedMesh != this.skybox)
                        {

                          let t = this.clickablecontent[pointerInfo.pickInfo.pickedMesh.name];
                          if(t)
                          {
                            let outputs: WidgetOutputChangeParameters[] = [];
                            for(let tk in t)
                            {
                              let outputChanged = new WidgetOutputChangeParameters();
                              outputChanged.widgetId = this.widgetConfig.Id;
                              outputChanged.outputParameterId = tk;
                              outputChanged.value = t[tk];
                              outputChanged.datatype=OutputDataType.String;
                              //console.log("clickable type of value", typeof(outputChanged.value) );
                              if( typeof(outputChanged.value) == "number")
                              {
                                //console.log("clickable: adding as number");
                                outputChanged.datatype = OutputDataType.Float64;
                              }
                              outputs.push(outputChanged);
                            }
                            if (outputs.length > 0) {
                              this.widgetService.outputParametersChanged(outputs);
                            }

                            if(this.prevPickedEdgeColor && this.prevPickedMesh)
                            {
                              this.prevPickedMesh.edgesColor=this.prevPickedEdgeColor;
                            }

                            this.prevPickedMesh = pointerInfo.pickInfo.pickedMesh;
                            let pEdges= this.pickedEdgeRendering[pointerInfo.pickInfo.pickedMesh.name];
                            if(pEdges)
                            {
                              this.prevPickedEdgeColor = pointerInfo.pickInfo.pickedMesh.edgesColor;
                              this.prevPickedEdgeEnabled = true;
                              pointerInfo.pickInfo.pickedMesh.edgesColor = pEdges;
                              //this.prevPickedEdgeEnabled = pointerInfo.pickInfo.pickedMesh
                            }



                            //camera.moveTargetTo(pointerInfo.pickInfo.pickedMesh.position, 50);
                            //camera.setTarget(pointerInfo.pickInfo.pickedMesh.position);
                            //camera.zoomToMouseLocation=true;


                            var easingFunction = new BABYLON.BackEase(.0);
                            easingFunction.setEasingMode(BABYLON.EasingFunction.EASINGMODE_EASEIN);


                            var whereto = new BABYLON.Vector3(
                              pointerInfo.pickInfo.pickedPoint.x,
                              pointerInfo.pickInfo.pickedPoint.y,
                              pointerInfo.pickInfo.pickedPoint.z );//minus 10 to get infront of the mesh

                            var wheretoTwo = new BABYLON.Vector3(
                              pointerInfo.pickInfo.pickedPoint.x,
                              pointerInfo.pickInfo.pickedPoint.y,
                              pointerInfo.pickInfo.pickedPoint.z);//minus 10 to get infront of the mesh


                            //var animTwo = BABYLON.Animation.CreateAndStartAnimation("anim", CoT, "position", 300, 100, CoT.position, wheretoTwo, 2, easingFunction);
                            //var anim = BABYLON.Animation.CreateAndStartAnimation("anim", camera, "position", 30, 100, camera.position, whereto, 2, easingFunction);

                            //keep tracking collision while position is updated
                            camera.checkCollisions = true;

                            //camera.lockedTarget=pointerInfo.pickInfo.pickedMesh;
                            //camera.update();
                        }
                      }
              break;
            }
        });
        });

      // camera.useFramingBehavior = true;
      // camera.useNaturalPinchZoom=true;
      // camera.allowUpsideDown=false;
      //  this.scene.collisionsEnabled=true;
      //  camera.checkCollisions = true;
      //  camera.collisionRadius = new BABYLON.Vector3(1, 1, 1);
      // this.scene.gravity = new BABYLON.Vector3(0, -0.9, 0);

      // This targets the camera to scene origin
      //camera.setTarget(new BABYLON.Vector3( 0, 0/*nrHeight/2*/, 0 ));

      // This attaches the camera to the canvas
      //camera.attachControl(this.canvas, true, true);


      // Entire createScene() should be wrapped into a default init script!

      if(!this.widgetConfig.InitLuaScript || this.widgetConfig.InitLuaScript.length == 0)
        return;



  /*
  const filename = "habitat7";//"habitat7.babylon";
      //const root = "https://demo.robotrisa.com/api/v1/file/nocache/";
      const root = "/api/v1/file/nocache/";
      console.log("Loading " + root+filename);

      await BABYLON.SceneLoader.AppendAsync(root, filename, this.scene);
      console.log("Loaded..");

      */

      let nrPops = this.registerLua();
      this.lua.RunScript(this.widgetConfig.InitLuaScript, this.extraInternalLua, nrPops);

      //this.scene.clearColor = new Color4(0.2, 0.2, 0.3, 0.01);
      //this.scene.environmentIntensity  = 0;


      var camera = new BABYLON.ArcRotateCamera("camera1", 0,0,10000, new BABYLON.Vector3(0,0,0), this.scene);

      // This targets the camera to scene origin
      camera.setTarget(new BABYLON.Vector3( 0,0, 0 ));

      // // This attaches the camera to the canvas
      camera.attachControl(this.canvas, true, true);

      this.optimizer.start();
    });
  }


  hslToRgb(h, s, l) : number[]{
    var r, g, b;

    if(s == 0){
        r = g = b = l; // achromatic
    }else{
        var hue2rgb = function hue2rgb(p, q, t){
            if(t < 0) t += 1;
            if(t > 1) t -= 1;
            if(t < 1/6) return p + (q - p) * 6 * t;
            if(t < 1/2) return q;
            if(t < 2/3) return p + (q - p) * (2/3 - t) * 6;
            return p;
        }

        var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        var p = 2 * l - q;
        r = hue2rgb(p, q, h + 1/3);
        g = hue2rgb(p, q, h);
        b = hue2rgb(p, q, h - 1/3);
    }

    return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
}

  // reBuildSceneWithData()
  // {
  //   // This creates a basic Babylon Scene object (non-mesh)
  //   let nrDepth = 2;
  //   let nrHeight = 3;
  //   let nrWidth = 4;


  //   this.scene = new BABYLON.Scene(this.engine);

  //   this.scene.clearColor = new BABYLON.Color4(0.0, 0.0, 0.0, 0);



  //   // This creates a light, aiming 0,1,0 - to the sky (non-mesh)
  //   var light = new BABYLON.HemisphericLight("light", new BABYLON.Vector3(0, 1, 0), this.scene);

  //   // Default intensity is 1. Let's dim the light a small amount
  //   light.intensity = 0.7;

  //   var myMaterial = new BABYLON.StandardMaterial("myMaterial", this.scene);

  //   myMaterial.diffuseColor = new BABYLON.Color3(1, 1, 1);
  //   myMaterial.specularColor = new BABYLON.Color3(0.5, 0.6, 0.87);
  //   myMaterial.emissiveColor = new BABYLON.Color3(1, 1, 1);
  //   myMaterial.ambientColor = new BABYLON.Color3(0.23, 0.98, 0.53);
  //   myMaterial.alpha = 0.5;



  //   let spacingFactor = 1.1;
  //   let cubeSize = 0.5;

  //   let scolor = new BABYLON.Color4(0.7,0.7,0.7);
  //   let scolors = [];
  //   for (let j = 0; j < 6; j++) {
  //     scolors.push(scolor);
  //   }

  //   let maxY = 0;
  //   let maxX = 0;
  //   for(let i = 0; i < this.cubePoints.length; i++)
  //   {
  //     if(this.cubePoints[i].pos.y>maxY)
  //       maxY = this.cubePoints[i].pos.y;
  //     if(this.cubePoints[i].pos.x>maxX)
  //       maxX = this.cubePoints[i].pos.x;
  //   }


  //   nrDepth = 2;
  //   nrHeight = maxY;
  //   nrWidth = maxX;

  //   // This is just to filter out dummmy data
  //   if(maxX>10)
  //     nrWidth = Math.min(maxX,4);

  //   this.logger.info(nrDepth,nrHeight,nrWidth,maxY,maxX);

  //   // for(let i = 0; i < this.cubePoints.length; i++)
  //   // {
  //   //   let p = this.cubePoints[i].pos;
  //   //   let v = this.cubePoints[i].value;

  //   //   let color = new Color4(0, 1, 1, 1);
  //   //   let colors = [];
  //   //   for (let j = 0; j < 6; j++) {
  //   //     colors.push(color);
  //   //   }
  //   //   var cube = BABYLON.MeshBuilder.CreateBox("cube", { size: cubeSize, faceColors: colors });
  //   //   cube.position.y = p.y * spacingFactor;
  //   //   cube.position.x = (p.x%2) * spacingFactor - (nrWidth/2)*1.1;
  //   //   cube.position.z = ((p.x+1)%2) * spacingFactor;

  //   //   cube.material = myMaterial as any;
  //   // }

  //    let surroundingCube = BABYLON.MeshBuilder.CreateBox("cube", { width: nrWidth*spacingFactor, height:nrHeight*spacingFactor, depth:nrDepth*spacingFactor, faceColors: scolors });
  //    surroundingCube.position.x = -cubeSize*spacingFactor; // rätt
  //    surroundingCube.position.y = cubeSize*nrHeight*spacingFactor - cubeSize;
  //    surroundingCube.position.z = cubeSize*spacingFactor; // rätt
  //    surroundingCube.material = myMaterial as any;



  //   for (let z = 0; z < nrDepth; z++) {
  //     for (let y = 0; y < nrHeight; y++) {
  //        for (let x = 0; x < nrWidth; x++) {
  //          let color = new BABYLON.Color4(0.5, 0.5, 0.5, 1);

  //          for(let i = 0; i < this.cubePoints.length; i++)
  //          {
  //            let p = this.cubePoints[i].pos;

  //            if(p.x%4 != x) continue;
  //            if((p.x+1)%2 != z) continue;
  //            if(p.y != y) continue;

  //            //this.logger.info("Found a point!!", p);

  //            let s = this.cubePoints[i].value;
  //            if( s < 18)
  //             s = 18;
  //           if( s > 28)
  //             s = 28;

  //           s = (s-18.0)/10.0;
  //           if(s>1)
  //             s = 1;


  //           let hslColors = this.hslToRgb(1-s, 1, 0.5);
  //           this.logger.info("from s ", s, " got hsl colors: ", hslColors);
  //           color.r = hslColors[0];
  //           color.g = hslColors[1];
  //           color.b = hslColors[2];
  //          }

  //          let colors = [];
  //          for (let j = 0; j < 6; j++) {
  //            colors.push(color);
  //          }
  //          var cube = BABYLON.MeshBuilder.CreateBox("cube", { size: cubeSize, faceColors: colors });
  //          cube.position.y = y * spacingFactor;
  //          cube.position.x = x * spacingFactor - (nrWidth/2)*1.1;
  //          cube.position.z = z * spacingFactor;

  //          cube.material = myMaterial as any;
  //          cube.parent = surroundingCube;
  //          cube.position.x -= surroundingCube.position.x;
  //          cube.position.y -= surroundingCube.position.y;
  //          cube.position.z -= surroundingCube.position.z;
  //        }
  //      }
  //    }

  //     // This creates and positions a free camera (non-mesh)
  //   var camera = new BABYLON.ArcRotateCamera("camera1", 0,0,nrHeight*1.2, new BABYLON.Vector3(-nrWidth/2, 0, -nrWidth*1.5), this.scene);

  //   // This targets the camera to scene origin
  //   camera.setTarget(new BABYLON.Vector3( 0, nrHeight/2, 0 ));

  //   // This attaches the camera to the canvas
  //   camera.attachControl(this.canvas, true, true);
  //   this.scene.registerBeforeRender(function()
  //   {
  //     surroundingCube.rotation.y += 0.005;
  //   });



  // }

  ngAfterViewInit(): void {

    if (!this.widgetConfig.ControlledByMaster || !this.responsive) {
      let that = this;
      setTimeout(() => {
        this.update().then(x => this.initiated = true);
      }, 500);
    }

    this.canvas = document.getElementById(this.id) as HTMLCanvasElement;
    this.logger.info("canvas: ");
    this.logger.info(this.canvas);

    this.engine = new BABYLON.Engine(this.canvas, true, { preserveDrawingBuffer: true, stencil: true });

    let that = this;
    let i = 0;

    this.ngZone.runOutsideAngular( () => {
    this.engine.runRenderLoop(function () {

      that.ngZone.runOutsideAngular(() => {
        i++;
        if(i%2==0)
          that.scene?.render();
      });
      //const targetFps = 5;
      //setInterval(() => that.scene.render(), 1000 / targetFps);
    });
    });

    this.runInitScript();

    //   window.addEventListener('resize', function(){
    //     this.logger.info("resize!");
    //     that.engine.resize();
    // });
  }

  ngOnDestroy(): void {
    this.scene?.dispose();
    super.ngOnDestroy();
  }


  async ngOnInit() {
    this.widgetConfig = this.config as Scripted3dWidgetConfig;
    this.id = this.widgetConfig.Id;
    this.id = this.id.replaceAll('-', '');

    await super.ngOnInit();
  }

  async onInit() {
    this.logger.info("I got on init");
  }

  async onRefresh() {
    this.update();
  }

  async update() {
    if (!this.widgetheight || !this.widgetwidth) {
      this.setWidgetSize(this.widgetConfig.Height, this.widgetConfig.Width);
    }

    this.logger.info("RESIZE!");
    setTimeout(() => {
      this.engine.resize();

      this.load();
    });
  }

  async onResized(component: GridsterItemComponentInterface) {
    this.setWidgetSize(this.getHeight(component), component?.width);
    setTimeout(() => {
      this.update();
    });
  }

  async onReset() {
    this.logger.info("I got onreset");
  }

  async onUpdateQuery() {
    this.update();

    this.runDataUpdateScript();
  }

  async onLinkedWidgetChanged(event: LinkedWidgetChangeParameters) {
    await this.load();
  }

  async onWidgetOutputChanged(event: WidgetOutputChangeParameters[]) {
    let doInit = false;
    event.forEach(x => {
      let inputParameter = this.config.InputParameters.find(y => y.widgetOutputParameterId == x.outputParameterId);
      if (inputParameter?.action == WidgetInputParameterAction.Init) {
        doInit = true;
      }
    });

    if (doInit) {
      this.runInitScript();
    }

    await this.load();
  }

  async onProjectionDataChanged(projectionIds: string[], force : boolean = false) {
    let i = this.widgetConfig.ConfigQueries.findIndex(q => projectionIds.findIndex(p => p == q.Query.targetprojectionid) >= 0);
    if (force || i >= 0) {
      await this.load(true);
    }
  }

  private setWidgetSize(height: number, width?: number): void {
    if (height != this.widgetheight || width != this.widgetwidth) {
      //this.updateCanvasSize = true;
      //this.resize();
    }

    if (height) {
      this.widgetheight = height;
    }
    this.widgetwidth = width ?? this.widgetheight;
  }


  getQuery(queryConfig: Scripted3dWidgetQuery): BaseQuery {
    let query: BaseQuery = new BaseQuery();

    query.targetprojectionid = queryConfig.ProjectionId;
    query.maxitems = 2000;
    query.targetgroup = queryConfig.Group;

    let filterId = 0;
    queryConfig.DataFilters.forEach(datafilter => {
      let columnFiltering = WidgetUtils.GetColumnFiltering(datafilter);

      if (columnFiltering) {
        columnFiltering.queryfilterid = ++filterId;
        query.filter.filters.push(columnFiltering.queryfilterid);

        if (columnFiltering instanceof ColumnFilteringNumerical) {
          query.numericalfilters.push(columnFiltering);
        }
        else if (columnFiltering instanceof ColumnFilteringString) {
          query.stringfilters.push(columnFiltering);
        }
        else if (columnFiltering instanceof ColumnFilteringTimestamp) {
          query.timestampfilters.push(columnFiltering);
        }
        else if (columnFiltering instanceof ColumnFilteringRelativeTimestamp) {
          query.relativetimestampfilters.push(columnFiltering);
        }
      }
    });

    return query;

  }

  async load(forcereload: boolean = false) {
    if (this.loading) {
      return;
    }

    this.loading = true;

    this.cubePoints.length = 0;

    try {
      if (!this.inputParametersHasValue(true))
      {
      }
      else
      {
        await ArrayUtils.AsyncForEach(this.widgetConfig.ConfigQueries, async (configQuery: Scripted3dWidgetQuery) =>
        {
          let query = this.getQuery(configQuery);

          // // Aggregation input parameters
          // let i = 0;
          // query.columns.forEach(col => {
          //   let dataConfig = configQuery.DataConfigs[i];
          //   if (dataConfig.UseAggregationInputParameter) {
          //     col.columnaggregation = this.getParameterValue(dataConfig.AggregationInputParameterId, col.columnaggregation);
          //   }
          //   i++;
          // });

          //Projection input parameters
          if (configQuery.UseProjectionInputParameter) {
            query.targetprojectionid = this.getParameterValue(configQuery.ProjectionInputParameterId, configQuery.Query.targetprojectionid).value;
          }

          //Group input parameters
          if (configQuery.GroupSelectionType == GroupSelectionTypes.GROUP_INPUT) {
            query.targetgroup = this.getParameterValue(configQuery.GroupInputParameterId, configQuery.Query.targetgroup).value;
          }
          else if (configQuery.GroupSelectionType == GroupSelectionTypes.GROUP_INPUT_PARAMETERS) {
            query.targetgroup = [];
            configQuery.GroupInputParameterIds.forEach(id => {
              query.targetgroup.push(this.getParameterValue(id, '').value + '');
            });
          }


          WidgetUtils.SetQueryFilterValues(query, configQuery.DataFilters, (i, d) => this.getParameterValue(i, d));
          WidgetUtils.AddQueryTimeframe(query,
            this.from,
            this.to,
            configQuery.timestampColumnName,
            // this.useRelativeTimestamp ? this.relativeTimestamp : null
            null
          );

          let queryResult = await this.xprojClient.RequestQueryBaseQuery(query, forcereload, 'scripted3dwidget', this.config.Name);
          console.log("DATA Registering result for",this.config.Name);
          this.dataQueriesResult[configQuery.Name] = queryResult;

        });
      }
      this.runDataUpdateScript();
    }
    catch(err)
    {
      console.error(err);
    }


    this.loading = false;

  }


}
