<clr-tabs>
  <clr-tab>
    <button clrTabLink>Device Info</button>
    <ng-template [(clrIfActive)]="deviceInfoActive">
      <clr-tab-content>
        <table class="table table-vertical" *ngIf="lorawanDeviceInfo">
          <caption style="margin-bottom: 1em;">
            Device info
          </caption>
          <tbody>
            <tr>
              <th class="left">DevEui</th>
              <td class="left">{{lorawanDeviceInfo.deveui}}</td>
            </tr>
            <tr>
              <th class="left">Gateway Count</th>
              <td class="left">{{lorawanDeviceInfo.gatewayCount}}</td>
            </tr>
            <tr>
              <th class="left">Gateway Id</th>
              <td class="left">{{lorawanDeviceInfo.gatewayIdentifier}}</td>
            </tr>
            <tr>
              <th class="left">Gateway Ids</th>
              <td class="left">{{lorawanDeviceInfo.gatewayIdentifiers}}</td>
            </tr>
            <tr>
              <th class="left">Latitude</th>
              <td class="left">{{lorawanDeviceInfo.latitude}}</td>
            </tr>
            <tr>
              <th class="left">Longitude</th>
              <td class="left">{{lorawanDeviceInfo.longitude}}</td>
            </tr>
                <tr>
              <th class="left">RSSI</th>
              <td class="left">{{lorawanDeviceInfo.rssi}}</td>
            </tr>
                <tr>
              <th class="left">Snr</th>
              <td class="left">{{lorawanDeviceInfo.snr?.toFixed(1)}}</td>
            </tr>
            <tr>
              <th class="left">Spf</th>
              <td class="left">{{lorawanDeviceInfo.spreadingfactor}}</td>
            </tr>
            <tr>
              <th class="left">Battery</th>
              <td class="left">{{lorawanDeviceInfo.batteryLevel == 255 ? '---' : (lorawanDeviceInfo.batteryLevel * 100/ 254).toFixed(0) + ' %'}}</td>
            </tr>
            <tr>
              <th class="left">Time</th>
              <td class="left">{{dateHelper.utils.format(lorawanDeviceInfo.modifiedAt, 'keyboardDateTime')}}</td>
            </tr>
          </tbody>
        </table>

        <p *ngIf="!lorawanDeviceInfo"> Device info not available.</p>
      </clr-tab-content>
    </ng-template>
  </clr-tab>
  <clr-tab>
    <button clrTabLink>Downlink</button>
    <ng-template [(clrIfActive)]="downlinkActive">
      <clr-tab-content>
        <div class="detail-area">
          <div class="card centered">
            <div class="card-header">Send downlink</div>
            <div class="card-block">
              <p *ngIf="configuredDownlinkItems.length == 0">No downlink items configured for this device type.</p>
              <form clrForm *ngIf="configuredDownlinkItems?.length > 0">
                <clr-select-container>
                  <label class="clr-col-12">Select downlink item:</label>
                  <select class="clr-col-12" clrSelect name="selectedDownlinkItem"
                    [(ngModel)]="selectedConfiguredDownlinkItem">
                    <option *ngFor="let item of configuredDownlinkItems" [ngValue]="item">{{item.name}} ({{item.deviceType}})</option>
                  </select>
                </clr-select-container>
              </form>
              <button class="btn btn-outline enqueueButton" [disabled]="!selectedConfiguredDownlinkItem" (click)="enqueueDownlinkItem();">
                <clr-icon shape="export"></clr-icon>
                Enqueue downlink item...
              </button>
            </div>
          </div>
          <div class="card centered">
            <div class="card-header">Send downlink sequence</div>
            <div class="card-block">
              <div class="card-text">
                <p *ngIf="configuredDownlinkSequences.length == 0">No downlink sequences configured for this device type.</p>
                <form clrForm *ngIf="configuredDownlinkSequences?.length > 0">
                  <clr-select-container>
                    <label class="clr-col-12">Select downlink sequence:</label>
                    <select class="clr-col-12" clrSelect name="selectedDownlinkSequence"
                      [(ngModel)]="selectedConfiguredDownlinkSequence" (ngModelChange)="downlinkSequenceChanged()">
                      <option *ngFor="let item of configuredDownlinkSequences" [ngValue]="item">{{item.name}}
                        ({{item.deviceType}})</option>
                    </select>
                  </clr-select-container>
                  <clr-input-container *ngIf="selectedConfiguredDownlinkSequence">
                    <label class="clr-col-4">Timeout (s):</label>
                    <input class="clr-col-8" clrInput type="number" min="0" max="999999999" placeholder=""
                      name="downlinkoverridetimeout" [(ngModel)]="downlinkOverrideTimeout" />
                  </clr-input-container>
                  <clr-toggle-container *ngIf="selectedConfiguredDownlinkSequence">
                    <clr-toggle-wrapper>
                      <input type="checkbox" clrToggle [(ngModel)]="downlinkSendEmail"
                        name="downlinksendemail" />
                      <label>Send email when done.</label>
                    </clr-toggle-wrapper>
                  </clr-toggle-container>
                </form>
                <button class="btn btn-outline enqueueButton" [disabled]="!selectedConfiguredDownlinkSequence"
                  (click)="enqueueDownlinkSequence();">
                  <clr-icon shape="export"></clr-icon>
                  Enqueue downlink seq...
                </button>

              </div>
            </div>
          </div>
        </div>

        <div class="detail-area">
          <div class="card centered">
            <div class="card-header">Downlink queue</div>
            <div class="card-block">
              <clr-datagrid *ngIf="downlinkItems" [clrDgLoading]="loadingDownlinkitems" class="datagrid meterGrid">
                <clr-dg-action-bar>
                  <div class="btn-group" style="float: right;">
                    <button class="btn btn-sm btn-secondary" (click)="updateDownLinkItems(true)">
                      <clr-icon shape="refresh" directions="left"></clr-icon> Refresh
                    </button>
                    <button class="btn btn-sm btn-secondary" (click)="clearDownlinkQueue()" [disabled]="downlinkItems.length == 0">
                      <clr-icon shape="trash" directions="left"></clr-icon> Clear queue...
                    </button>
                  </div>
                </clr-dg-action-bar>

                <clr-dg-column [clrDgField]="'item.id'" [style.min-width.px]="200">
                  <ng-container *clrDgHideableColumn="{hidden: true}">Id</ng-container>
                </clr-dg-column>
                <clr-dg-column [clrDgField]="'item.devEui'" [style.min-width.px]="200">
                  <ng-container *clrDgHideableColumn="{hidden: true}">DevEui</ng-container>
                </clr-dg-column>
                <clr-dg-column [clrDgField]="'item.confirmed'" [style.min-width.px]="30">
                  <ng-container *clrDgHideableColumn="{hidden: false}">Confirmed</ng-container>
                </clr-dg-column>
                <clr-dg-column [clrDgField]="'item.pending'" [style.min-width.px]="30">
                  <ng-container *clrDgHideableColumn="{hidden: false}">Pending</ng-container>
                </clr-dg-column>
                <clr-dg-column [clrDgField]="'item.fport'" [style.min-width.px]="30">
                  <ng-container *clrDgHideableColumn="{hidden: false}">FPort</ng-container>
                </clr-dg-column>
                <clr-dg-column [clrDgField]="'item.data'" [style.min-width.px]="300">
                  <ng-container *clrDgHideableColumn="{hidden: false}">Data</ng-container>
                </clr-dg-column>
                <clr-dg-column [clrDgField]="'item.deliveryStatus'" [style.min-width.px]="200">
                  <ng-container *clrDgHideableColumn="{hidden: false}">Status</ng-container>
                </clr-dg-column>
                <clr-dg-column [clrDgField]="'item.requestTimestamp'" [style.min-width.px]="200">
                  <ng-container *clrDgHideableColumn="{hidden: true}">Request time</ng-container>
                </clr-dg-column>
                <clr-dg-column [clrDgField]="'item.responseTimestamp'" [style.min-width.px]="200">
                  <ng-container *clrDgHideableColumn="{hidden: true}">Response time</ng-container>
                </clr-dg-column>

                <clr-dg-placeholder>Empty queue</clr-dg-placeholder>

                <clr-dg-row *clrDgItems="let item of downlinkItems; let i = index" [clrDgItem]="item">
                  <clr-dg-cell class="left">{{item.id}}</clr-dg-cell>
                  <clr-dg-cell class="left">{{item.devEui}}</clr-dg-cell>
                  <clr-dg-cell class="left">{{item.confirmed ? 'yes' : 'no'}}</clr-dg-cell>
                  <clr-dg-cell class="left">{{item.pending ? 'yes' : 'no'}}</clr-dg-cell>
                  <clr-dg-cell class="left">{{item.fport}}</clr-dg-cell>
                  <clr-dg-cell class="left">{{item.data}}</clr-dg-cell>
                  <clr-dg-cell class="left">{{item.deliveryStatus}}</clr-dg-cell>
                  <clr-dg-cell class="left">{{item.requestTimestamp}}</clr-dg-cell>
                  <clr-dg-cell class="left">{{item.responseTimestamp}}</clr-dg-cell>
                </clr-dg-row>

                <clr-dg-footer *ngIf="downlinkItems.length > 5">
                  <clr-dg-pagination #pagination [clrDgPageSize]="5">
                    <clr-dg-page-size [clrPageSizeOptions]="[5, 10,20]">
                      Meters</clr-dg-page-size>
                    {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} meters
                  </clr-dg-pagination>
                </clr-dg-footer>

              </clr-datagrid>
            </div>
          </div>
        </div>
      </clr-tab-content>
    </ng-template>
  </clr-tab>
</clr-tabs>

