import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventHistory } from '@core/xprojector_backend/proto/xprojector.modulerossakerbms.events.pb';
import { RossakerXProjectorBmsEventsClient } from '@core/xprojector_backend/rossaker-xprojector-bms-events-client';
import { NGXLogger } from 'ngx-logger';
import { map } from 'rxjs/operators';
import { DateHelper } from 'xproj-lib';

@Component({
  selector: 'app-rossaker-bms-events-history',
  templateUrl: './rossaker-bms-events-history.component.html',
  styleUrls: ['./rossaker-bms-events-history.component.scss']
})
export class RossakerBmsEventsHistoryComponent implements OnInit {

  @Input() customerId: string = '';
  @Input() eventObjectId: string = '';

  events : EventHistory[] = [];

  loading : boolean = false;

  constructor(
    private route: ActivatedRoute,
    private eventsClient: RossakerXProjectorBmsEventsClient,
    private logger: NGXLogger,
    public dateHelper: DateHelper
  ) { }

  ngOnInit(): void {
    this.route.params.pipe(map(p => p.customerid)).subscribe(async (customerId) => {
      if (customerId) {
        this.logger.info('ngOnInit customerId', customerId);
        this.customerId = customerId;
      }
    });
    this.route.params.pipe(map(p => p.eventid)).subscribe(async (eventId) => {
      if (eventId) {
        this.eventObjectId = eventId;
      }
    });

    this.updateEventsHistory();
  }

  async updateEventsHistory() {
    try {
      this.loading = true;
      this.events = await this.eventsClient.getEventsHistory(this.customerId, this.eventObjectId, null, null, 10);
    }
    finally {
      this.loading = false;
    }
  }

}
