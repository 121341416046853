<clr-tabs class="events-area">
  <clr-tab>
    <button clrTabLink i18n='@@customerevents_notifications'>Notifications</button>
    <clr-tab-content>
      <div *ngIf="events">
        <p i18n='@@customerevents_information' class="information-area">Notifications information</p>
        <p i18n='@@customerevents_instructions' class="information-area">Click on the circles to confirm or close the message.</p>
        <clr-datagrid [clrDgLoading]="loadingEvents" class="event-table">
          <clr-dg-column class="col1">
            <ng-container></ng-container>
          </clr-dg-column>
          <clr-dg-column [clrDgField]="'event.category'" [style.min-width.px]="200">
            <ng-container i18n='@@customerevents_category'>Category</ng-container>
          </clr-dg-column>
          <clr-dg-column [clrDgField]="'event.message'">
            <ng-container i18n='@@customerevents_message'>Message</ng-container>
          </clr-dg-column>
          <clr-dg-column [clrDgField]="'event.location'" [style.min-width.px]="150">
            <ng-container i18n='@@customerevents_location'>Location</ng-container>
          </clr-dg-column>
          <clr-dg-column [clrDgField]="'event.timestamp'" [style.min-width.px]="170" [clrDgSortBy]="eventTimeSort"
            [clrDgSortOrder]="descSort">
            <ng-container i18n='@@customerevents_time'>Time</ng-container>
          </clr-dg-column>
          <clr-dg-column [clrDgField]="'event.pbClass'" [style.width.px]="30">
            <ng-container i18n='@@customerevents_severity'>Severity</ng-container>
          </clr-dg-column>
          <clr-dg-column [clrDgField]="'status'" [style.width.px]="100">
            <ng-container i18n='@@customerevents_status'>Status</ng-container>
          </clr-dg-column>
          <clr-dg-column [clrDgField]="'event.comment'" [style.min-width.px]="200">
            <ng-container i18n='@@customerevents_comment'>Comment</ng-container>
          </clr-dg-column>

          <clr-dg-row *clrDgItems="let item of events; let i = index" [clrDgItem]="item">
            <clr-dg-cell class="col1">
              <clr-dropdown>
                <button [class]="item.class" clrDropdownTrigger></button>
                <clr-dropdown-menu clrPosition="bottom-left" *clrIfOpen>
                  <div i18n='@@customerevents_ackn' aria-label="Dropdown header Acknowledge" clrDropdownItem
                    (click)="acknowledgeEvent(item)" [class.disabled]="!item.event.active || item.event.acknowledged">
                    Ackn.</div>
                  <div i18n='@@customerevents_close' aria-label="Dropdown header Close" clrDropdownItem
                    (click)="closeEvent(item)" [class.disabled]="!item.event.active">Close</div>
                  <div i18n='@@customerevents_mute' *ngIf="!item.event.muted" aria-label="Dropdown header Mute"
                    clrDropdownItem (click)="muteEvent(item)">Mute</div>
                  <div i18n='@@customerevents_unute' *ngIf="item.event.muted" aria-label="Dropdown header Mute"
                    clrDropdownItem (click)="unmuteEvent(item)">Unmute</div>
                </clr-dropdown-menu>
              </clr-dropdown>
            </clr-dg-cell>
            <clr-dg-cell class="left">{{item.event.category}}</clr-dg-cell>
            <clr-dg-cell class="left">{{item.event.message}}</clr-dg-cell>
            <clr-dg-cell class="left">{{item.event.location}}</clr-dg-cell>
            <clr-dg-cell class="left">{{dateHelper.utils.format(item.event.timestamp.toDate(), 'keyboardDateTime')}}
            </clr-dg-cell>
            <clr-dg-cell class="left">{{item.event.pbClass}}</clr-dg-cell>
            <clr-dg-cell class="left">{{item.status}}
            </clr-dg-cell>
            <clr-dg-cell class="left">{{item.event.comment}}</clr-dg-cell>

            <app-rossaker-bms-events-history *clrIfExpanded ngProjectAs="clr-dg-row-detail"
              [customerId]="selectedBmsCustomer?.customerId" [eventObjectId]="item.event.id"></app-rossaker-bms-events-history>
          </clr-dg-row>

          <clr-dg-footer>
            <clr-dg-pagination #pagination [clrDgPageSize]="10">
              <clr-dg-page-size i18n='@@customerevents_notifications' [clrPageSizeOptions]="[10,20,50,100]">
                Notifications</clr-dg-page-size>
              {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} notifications
            </clr-dg-pagination>
          </clr-dg-footer>

        </clr-datagrid>

        <table class="table table-noborder table-compact information-dot">
          <tbody>
            <tr>
              <td class="col1" [style.width.px]="40">
                <div class="dot-new"></div>
              </td>
              <td class="left" i18n='@@customerevents_info_newevent'>New event.</td>
            </tr>
            <tr>
              <td class="col1" [style.width.px]="40">
                <div class="dot-open"></div>
              </td>
              <td class="left" i18n='@@customerevents_info_openevent'>Event is acknowledged but still active.</td>
            </tr>
            <tr>
              <td class="col1" [style.width.px]="40">
                <div class="dot-closed"></div>
              </td>
              <td class="left" i18n='@@customerevents_info_closedevent'>Event is closed.</td>
            </tr>
            <tr>
              <td class="col1" [style.width.px]="40">
                <div class="dot-muted"></div>
              </td>
              <td class="left" i18n='@@customerevents_info_mutedevent'>Messages are muted.</td>
            </tr>
          </tbody>
        </table>

      </div>
    </clr-tab-content>
  </clr-tab>
  <clr-tab>
    <button clrTabLink i18n='@@customerevents_settings'>Settings</button>
    <clr-tab-content>
      <div *ngIf="escalationInfosByUser">
        <clr-datagrid [clrDgLoading]="loadingEvents" class="user-table">

          <clr-dg-action-bar>
            <div class="btn-group">
              <button i18n='@@customerevents_adduser' type="button" class="btn btn-primary" (click)="addNewUser()">
                <clr-icon shape="plus"></clr-icon> Add user
              </button>
            </div>
          </clr-dg-action-bar>

          <clr-dg-column [clrDgField]="'user.name'">
            <ng-container i18n='@@customerevents_nameheader'>Name</ng-container>
          </clr-dg-column>
          <clr-dg-column [clrDgField]="'user.email'">
            <ng-container i18n='@@customerevents_emailheader'>Email</ng-container>
          </clr-dg-column>
          <clr-dg-column [clrDgField]="'user.phone'">
            <ng-container i18n='@@customerevents_phoneheader'>Phone</ng-container>
          </clr-dg-column>

          <clr-dg-row *clrDgItems="let item of escalationInfosByUser; let i = index" [clrDgItem]="item">
            <clr-dg-cell class="left">{{item.user.name}}</clr-dg-cell>
            <clr-dg-cell class="left">{{item.user.email}}</clr-dg-cell>
            <clr-dg-cell class="left">{{item.user.phone}}</clr-dg-cell>

            <ng-template [(clrIfExpanded)]="item.expanded" ngProjectAs="clr-dg-row-detail">
              <clr-dg-row-detail [clrDgReplace]="true">
                <form clrForm>
                  <clr-input-container>
                    <label i18n='@@customerevents_name'>Name:</label>
                    <input clrInput required placeholder="name" name="name" type="text" size="50"
                      [(ngModel)]="item.user.name" />
                  </clr-input-container>
                  <clr-input-container>
                    <label i18n='@@customerevents_email'>Email:</label>
                    <input clrInput required placeholder="email" name="email" type="text" size="50"
                      [(ngModel)]="item.user.email" />
                  </clr-input-container>
                  <clr-input-container>
                    <label i18n='@@customerevents_phone'>Phone:</label>
                    <input clrInput placeholder="phone" name="phone" type="text" size="50"
                      [(ngModel)]="item.user.phone" />
                  </clr-input-container>

                  <clr-toggle-container clrInline>
                    <label i18n='@@customerevents_priority'>Priority:</label>
                    <clr-toggle-wrapper *ngFor="let escalationInfoEnabled of item.escalationInfos">
                      <input type="checkbox" clrToggle name="{{escalationInfoEnabled.info.id}}"
                        [(ngModel)]="escalationInfoEnabled.enabled" />
                      <label>{{escalationInfoEnabled.info.name}}</label>
                    </clr-toggle-wrapper>
                    <clr-control-helper>
                      <span i18n='@@customerevents_priorityhelpertext'>Select priorities</span>
                      <clr-signpost>
                        <clr-signpost-content *clrIfOpen>
                          <h3 i18n='@@customerevents_prioritysignpostheader'>Priority</h3>
                          <p i18n='@@customerevents_prioritysignposttext'>Select notifications priorities.</p>
                          <table class="table table-noborder table-compact information-priority">
                            <tbody>
                              <tr>
                                <td class="col1" [style.width.px]="40">
                                  A
                                </td>
                                <td class="left" i18n='@@customerevents_info_priority_a'>Highest priority</td>
                              </tr>
                              <tr>
                                <td class="col1" [style.width.px]="40">
                                  B
                                </td>
                                <td class="left" i18n='@@customerevents_info_priority_b'>Medium priority</td>
                              </tr>
                              <tr>
                                <td class="col1" [style.width.px]="40">
                                  C
                                </td>
                                <td class="left" i18n='@@customerevents_info_priority_c'>Lowest priority</td>
                              </tr>
                          </table>
                        </clr-signpost-content>
                      </clr-signpost>
                    </clr-control-helper>
                  </clr-toggle-container>

                  <button i18n='@@customerevents_save' type="button" class="btn btn-primary" style="margin-top: 1em;"
                    (click)="saveSettings()" [clrLoading]="saveInProgress">
                    <clr-icon shape="floppy"></clr-icon> Save
                  </button>
                  <button i18n='@@customerevents_delete' type="button" class="btn btn-danger-outline"
                    style="margin-top: 1em;" (click)="deleteUser(item.user)">
                    <clr-icon shape="trash"></clr-icon> Delete
                  </button>
                </form>
              </clr-dg-row-detail>
            </ng-template>
          </clr-dg-row>

        </clr-datagrid>
      </div>
    </clr-tab-content>
  </clr-tab>
</clr-tabs>
