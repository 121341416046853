import 'reflect-metadata';
import { jsonObject, jsonMember, TypedJSON, jsonArrayMember } from 'typedjson';
import { Guid } from '../utils/guid-service';
import { GridsterItem } from 'angular-gridster2';
import { DataFilter } from '../filters/data-filter/data-filter-service';
import { Aggregation, FilterLogicalGroupType, LuaQueryColumn, Transformation, XDataType } from '../XProjector/xprojector-client-service';

export enum LinkedWidgetChangeCommand {
  Zoom        = 0,
  Highlight   = 1,
  None        = 2
}

export enum GroupSelectionTypes {
  GROUP                   = 0,
  GROUP_INPUT             = 1,
  GROUP_INPUT_PARAMETERS  = 2
}

export enum LegendPosition {
  TOP        = 0,
  LEFT       = 1,
  BOTTOM     = 2,
  RIGHT      = 3
}

export enum LegendAlignment {
  START        = 0,
  CENTER       = 1,
  END          = 2
}

export enum InputArrayMode {
  OR_FILTER = 0,
  AND_FILTER = 1,
  MULTIPLE_QUERIES = 2
}

export interface BaseQueryConfigInterface {
  UseTransformInputParameter : boolean;
  TransformInputParameterId : string;
  UseProjectionInputParameter : boolean;
  ProjectionInputParameterId : string;
  GroupSelectionType : GroupSelectionTypes;
  GroupInputParameterId : string;
  GroupInputParameterIds : string[];
}

@jsonObject
export class LinkedWidgetActions {
  @jsonMember
  public OnZoom : LinkedWidgetChangeCommand = LinkedWidgetChangeCommand.Zoom;
  @jsonMember
  public OnHighlight : LinkedWidgetChangeCommand = LinkedWidgetChangeCommand.Highlight;
}

export enum OutputDataType {
  Float32 = 0,
  Float64 = 1,
  UInt8 = 2,
  Int32 = 3,
  Int64 = 4,
  Number = 5,
  Timestamp = 6,
  String = 7,
  UInt128=8,

  Aggregation = 100,
  Transformation = 101,
  Group = 102,
  Projection = 103,
  Time = 104,
  RelativeTimestamp = 105,
  RelativeOffsettype = 106
}

@jsonObject
export class WidgetOutputParameter {
  @jsonMember
  public id : string = Guid.newGuid();
  @jsonMember
  public name : string;
  @jsonMember
  public datatype : OutputDataType;
}

export enum WidgetInputParameterAction {
  Init = 0,
  Update = 1
}


@jsonObject
export class WidgetInputParameter {
  @jsonMember
  public id : string;
  @jsonMember
  public widgetId : string;
  @jsonMember
  public widgetOutputParameterId : string;
  @jsonMember
  public datatype : OutputDataType = OutputDataType.Number;
  @jsonMember
  public initValue : string;
  @jsonMember
  public invertValue : boolean = false;
  @jsonMember
  public mandatory : boolean = false;
  @jsonMember
  public action : WidgetInputParameterAction = WidgetInputParameterAction.Init;
}

export enum WidgetPrQueryEditMode {
  Number = 0,
  String = 1,
  Timestamp = 2,
  Boolean = 3,
  XDatatype = 4,
  Enum = 5
}

@jsonObject
export class WidgetPrQueryEnumMember {
  @jsonMember
  public value : number;

  @jsonMember
  public name : string;
}

@jsonObject
export class WidgetPrQueryColumnConfig {

  @jsonMember
  public Id : string = Guid.newGuid();

  @jsonMember
  public ColumnName : string = '';

  @jsonMember
  public Label : string = '';

  @jsonMember
  public Unit : string = '';

  @jsonMember
  public ColumnOutName : string = '';

  @jsonMember
  public Output : boolean = false;

  @jsonMember
  public Transform: Aggregation = Aggregation.NONE;

  @jsonMember
  public Datatype : XDataType = XDataType.Number;

  @jsonMember
  public UseAggregationInputParameter : boolean = false;

  @jsonMember
  public AggregationInputParameterId: string = '';

  @jsonMember
  public ColumnTransformation : Transformation = Transformation.NONE;

  @jsonMember
  public EditMode : WidgetPrQueryEditMode = WidgetPrQueryEditMode.String;

  @jsonArrayMember(WidgetPrQueryEnumMember)
  public EnumMembers : WidgetPrQueryEnumMember[] = [];

  @jsonArrayMember(String)
  public Tags : string[] = [];
}

@jsonObject
export class WidgetPreQueryConfig implements BaseQueryConfigInterface {
  @jsonMember
  public Id : string = Guid.newGuid();

  @jsonMember
  public Name : string;

  @jsonMember
  public Prefix : string;

  @jsonMember
  public Level : number = 0;

  @jsonArrayMember(WidgetOutputParameter)
  public OutputParameters : WidgetOutputParameter[] = [];

  @jsonArrayMember(WidgetInputParameter)
  public InputParameters : WidgetInputParameter[] = [];


  @jsonMember
  public ProjectionId : string = '';

  @jsonMember
  public MaxItems : number = 100;

  @jsonMember
  public UseGrouping : boolean = false;

  @jsonMember
  public GroupingTransform : Transformation = Transformation.NONE;

  @jsonMember
  public UseTransformInputParameter : boolean = false;

  @jsonMember
  public TransformInputParameterId: string = '';

  @jsonArrayMember(String)
  public Group : string[] = [];

  @jsonArrayMember(WidgetPrQueryColumnConfig)
  public ColumnConfigs : WidgetPrQueryColumnConfig[] = [];

  @jsonArrayMember(DataFilter)
  public DataFilters : DataFilter[] = [];

  @jsonMember
  public FilterLogicalGroupType : FilterLogicalGroupType = FilterLogicalGroupType.AND;

  @jsonMember
  public TimestampColumnName : string = '';

  @jsonMember
  public DefaultSortColumnName : string = '';

  @jsonMember
  public DefaultSortDescending : boolean = false;

  @jsonMember
  public UseProjectionInputParameter : boolean = false;

  @jsonMember
  public ProjectionInputParameterId: string = '';

  @jsonMember
  public GroupSelectionType : GroupSelectionTypes = GroupSelectionTypes.GROUP;

  @jsonMember
  public GroupInputParameterId: string = '';

  @jsonArrayMember(String)
  public GroupInputParameterIds: string[] = [];

  @jsonArrayMember(LuaQueryColumn)
  public ScriptedColumnsPostAggregation : LuaQueryColumn[] = [];

}

@jsonObject
export abstract class WidgetConfig implements GridsterItem{

  constructor() {
    this.Id = Guid.newGuid();
  }

  abstract Clone(): WidgetConfig;

  abstract GetSubscriprionData(): { projectionId: string, group : string[] }[];

  abstract CanExportToExcel : boolean;
  abstract CanExportToImage : boolean;

  @jsonMember
  public Id : string;

  @jsonMember
  public Name : string = '';

  @jsonMember
  public Title : string = '';

  @jsonMember
  public Tooltip : string = '';

  @jsonMember
  public ShowTitle : boolean = true;

  @jsonMember
  public Borderless : boolean = false;

  @jsonMember
  public LinkedInputWidget : string = '';

  @jsonMember
  public LinkedWidgetActions : LinkedWidgetActions = new LinkedWidgetActions();

  @jsonArrayMember(WidgetOutputParameter)
  public OutputParameters : WidgetOutputParameter[] = [];

  @jsonArrayMember(WidgetInputParameter)
  public InputParameters : WidgetInputParameter[] = [];

  @jsonMember
  public ControlledByMaster : boolean = false;

  @jsonMember
  public BackgroundColor : string = '';

  @jsonMember
  public BorderColor : string = '';

  @jsonMember
  public BorderWidth : number = 0;

  @jsonMember
  public BorderRadius : number = 0;

  @jsonMember
  public BlurPX : number = 0;

  @jsonMember
  public BoxShadowColor : string = '';
  @jsonMember
  public BoxShadowOffsetX : number  = 0;
  @jsonMember
  public BoxShadowOffsetY : number  = 0;
  @jsonMember
  public BoxShadowBlurRadius : number  = 0;
  @jsonMember
  public BoxShadowSpreadRadius : number  = 0;

  @jsonMember
  public ExportEnabled : boolean = true;

  @jsonMember
  public LegendShow : boolean = true;

  @jsonMember
  public LegendPosition : LegendPosition = LegendPosition.BOTTOM;

  @jsonMember
  public LegendAlignment : LegendAlignment = LegendAlignment.CENTER;

  @jsonMember
  public MinUpdateInterval : number = 0;

  @jsonMember
  public x : number;

  @jsonMember
  public y : number;

  @jsonMember
  public cols : number = 2;

  @jsonMember
  public rows : number = 1;

  @jsonMember
  public layerIndex : number = 1;

  @jsonArrayMember(WidgetPreQueryConfig)
  public WidgetPreQueryConfigs : WidgetPreQueryConfig[] = [];

  @jsonMember
  public maxConcurrentRequests : number = 5;

  @jsonMember
  public IsSensitive : boolean = false;

  @jsonMember
  public TitleIsSensitive : boolean = false;

  @jsonMember
  public FullscreenEnabled : boolean = false;

  //@jsonMember
  //public MinUpdateInterval : number = 0;
  // Todo @olof ; hur får jag denna att fungera tro? ;)

  public Height : number = 0;
  public Width : number = 0;
  public Href : string = '';

  private _displayTitle : string = '';
  get DisplayTitle() : string {
    if (this._displayTitle.length > 0) {
      return this._displayTitle;
    }
    if (this.Title?.length > 0) {
      return this.Title;
    }

    return this.Name;
  }
  set DisplayTitle(value : string) { this._displayTitle = value }

  update(config: WidgetConfig) {
    this.Name = config.Name;
    this.Title = config.Title;
    this.Tooltip = config?.Tooltip;
    this.ShowTitle = config.ShowTitle;
    this.TitleIsSensitive = config.TitleIsSensitive;
    this.FullscreenEnabled = config.FullscreenEnabled;
    this.LinkedInputWidget = config.LinkedInputWidget;
    this.OutputParameters = config.OutputParameters;
    this.InputParameters = config.InputParameters;
    this.ControlledByMaster = config.ControlledByMaster;
    this.BackgroundColor = config.BackgroundColor;
    this.BorderColor = config.BorderColor;
    this.BorderWidth = config.BorderWidth;
    this.BorderRadius = config.BorderRadius;
    this.ExportEnabled = config.ExportEnabled;
    this.LegendShow = config.LegendShow;
    this.LegendPosition = config.LegendPosition;
    this.LegendAlignment = config.LegendAlignment;
    this.MinUpdateInterval = config.MinUpdateInterval;
    this.BlurPX = config.BlurPX;
    this.BoxShadowColor = config.BoxShadowColor;
    this.BoxShadowOffsetX = config.BoxShadowOffsetX;
    this.BoxShadowOffsetY = config.BoxShadowOffsetY;
    this.BoxShadowBlurRadius = config.BoxShadowBlurRadius;
    this.BoxShadowSpreadRadius = config.BoxShadowSpreadRadius;
    this.x = config.x;
    this.y = config.y;
    this.cols = config.cols;
    this.rows = config.rows;
    this.layerIndex = config.layerIndex;
    this.WidgetPreQueryConfigs = config.WidgetPreQueryConfigs.sort((a, b) => a < b ? 1 : -1);
    this.IsSensitive = config.IsSensitive;
    this.Borderless = config.Borderless;
  }

}
