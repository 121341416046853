<div>
  <xproj-widget-config #widgetBaseConfig [dashboardConfig]="dashboardConfig" [config]="editConfig" [widgets]="widgets"
    [gridSettings]="gridSettings" [showPreQuery]="true" (save)="onWidgetConfigSaved($event)"></xproj-widget-config>
  <br />

  <clr-accordion>
    <clr-accordion-panel *ngFor="let configQuery of editConfig.ConfigQueries; index as i">
      <clr-accordion-title>Query {{i+1}}</clr-accordion-title>
      <clr-accordion-content>
        <xproj-text-widget-query-config [widgetQuery]="configQuery" [projections]="projections"
          [inputParameters]="editConfig.InputParameters" [widgetConfig]="editConfig" [widgets]="widgets"
          (onQueryRemoved)="onQueryRemoved($event)">
        </xproj-text-widget-query-config>
      </clr-accordion-content>
    </clr-accordion-panel>
  </clr-accordion>

  <button type="button" class="btn btn-link" (click)="addConfigQuery();">
    <clr-icon shape="plus-circle"></clr-icon>
    Add query
  </button>

  <form clrForm>
    <clr-checkbox-container>
      <clr-checkbox-wrapper>
        <input type="checkbox" clrCheckbox name="MultiSeriesQuery" [(ngModel)]="editConfig.MultiseriesConfig.Enabled" />
        <label>Multi series query</label>
      </clr-checkbox-wrapper>
    </clr-checkbox-container>

    <div *ngIf="editConfig.MultiseriesConfig.Enabled">
      <clr-input-container
        *ngFor="let value of editConfig.MultiseriesConfig.JoinableColumns; let i = index; trackBy: indexTracker">
        <label>Join Column {{i}}</label>
        <input clrInput type="text" [(ngModel)]="editConfig.MultiseriesConfig.JoinableColumns[i]"
          name="Joinablecolumns_{{i}}" />
      </clr-input-container>
    </div>

    <br />

    <clr-accordion *ngIf="editConfig.MultiseriesConfig.Enabled">
      <clr-accordion-panel
        *ngFor="let scriptedcolumn of editConfig.MultiseriesConfig.ScriptedColumnsPostJoin; index as i">
        <clr-accordion-title>ScriptedColumn {{i+1}}</clr-accordion-title>
        <clr-accordion-content>
          <xproj-edit-lua-query-column [luaQueryColumn]="scriptedcolumn.Column" [unit]="scriptedcolumn.Unit"
            (onUnitChange)="scriptedcolumn.Unit=$event" (onQueryColumnRemoved)="onLuaQueryColumnRemoved($event)">
          </xproj-edit-lua-query-column>
        </clr-accordion-content>
      </clr-accordion-panel>
    </clr-accordion>
    <button type="button" class="btn btn-link" (click)="addScriptedColumnsPostAggregation();"
      *ngIf="editConfig.MultiseriesConfig.Enabled">
      <clr-icon shape="plus-circle"></clr-icon>
      Add scripted column
    </button>
  </form>

  <md-editor name="Content" [preRender]="preRenderFunc" [postRender]="postRenderFunc" [(ngModel)]="editConfig.Content"
    [height]="'400px'" [mode]="mode" [options]="options" [upload]="doUpload" (onEditorLoaded)="onEditorLoaded($event)"
    (onPreviewDomChanged)="onPreviewDomChanged($event)" maxlength="1000">
  </md-editor>
  <br>
  <form clrForm>
    <clr-input-container>
      <label>Margin</label>
      <input clrInput type="text" [(ngModel)]="editConfig.Margin" name="margin" size="35" />
    </clr-input-container>
    <clr-input-container>
      <label>Background color</label>
      <input clrInput placeholder="" name="backgroundcolor" type="text" [(ngModel)]="editConfig.BackgroundColor" />
      <input clrInput type="color" placeholder="" name="backgroundcolor2" [(ngModel)]="editConfig.BackgroundColor" />
    </clr-input-container>
    <clr-input-container>
      <label>Text color</label>
      <input clrInput type="text" placeholder="" name="textcolor" [(ngModel)]="editConfig.TextColor" />
      <input clrInput type="color" placeholder="" name="textcolor2" [(ngModel)]="editConfig.TextColor" />
    </clr-input-container>
    <button type="button" class="btn btn-secondary"
      (click)="editConfig.TextColor='#000000';editConfig.BackgroundColor='#FFFFFF'">
      Reset colors
    </button>
  </form>
</div>
