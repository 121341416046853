import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginProvider } from '@xprojectorcore/models/login-provider';
import { UserRights } from '@xprojectorcore/models/user-rights';
import { StateService } from '@xprojectorcore/services/state-service';
import { GoogleLoginProvider, SocialAuthService, SocialUser, MicrosoftLoginProvider } from '@vipstorage/angularx-social-login';
import { NGXLogger } from 'ngx-logger';
import { environment } from 'src/environments/environment';

export enum AuthMethod {
  USERNAMEPASSWORD = 0,
  GOOGLE = 1,
  MICROSOFT = 2,
  MANODOSCADA = 3
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit {

  title : string = environment.title;

  customer: string;
  username: string;
  password: string;
  invalidlogin: boolean = false;
  socialuser: SocialUser

  loginProviders: LoginProvider[] = [];
  loginProvider: LoginProvider;

  adminLogin: boolean = false;
  returnUrl : string = '';

  AuthMethod = AuthMethod;

  constructor(
    private state: StateService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: SocialAuthService,
    private logger: NGXLogger
  ) { }

  async ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    this.adminLogin = (this.route.snapshot.queryParams['admin'] || 'false') == 'true';

    this.authService.authState.subscribe(socialuser => {
      if (!this.socialuser) {
        this.socialuser = socialuser;
        this.logger.info('user', socialuser);
        if (this.socialuser) {
          this.loginSocial();
        }
      }
    });

  }

  ngAfterViewInit(): void {

    this.state.getLoginProviders().then(providers => {
      this.loginProviders = providers;
      this.setDisplayNames();
      if (this.loginProviders.length > 0) {
        this.loginProvider = this.loginProviders[0];
      }
    });
  }


  async login() {
    switch (this.loginProvider.id) {
      case 'xprojector':
        await this.loginUsernamePassword();
        break;

      case 'manodoscada':
        await this.loginUsernamePassword();
        break;

      case 'google':
        await this.signInWithGoogle();
        break;

      case 'microsoft':
        await this.signInWithMicrosoft();
        break;

    }
  }

  async setDisplayNames() {
    this.loginProviders.forEach((provider) => {
      switch (provider.id) {
        case 'xprojector':
          provider.displayName = $localize `:@@login_usernamepassword:Username/Password`;
          break;

        case 'manodoscada':

          break;

        case 'google':
          provider.displayName = $localize `:@@login_google:Google`;
          break;

        case 'microsoft':
          provider.displayName = $localize `:@@login_microsoft:Microsoft`;
          break;

      }
    });
  }

  private async loginUsernamePassword() {
    this.invalidlogin = false;
    let user : UserRights;
    if (this.adminLogin) {
      user = await this.state.loginAdmin(this.username, this.password, this.loginProvider.id);
    }
    else {
      user = await this.state.login(this.customer, this.username, this.password, this.loginProvider.id);
    }

    if (user != null) {
      this.router.navigateByUrl(this.returnUrl);
    } else {
      this.invalidlogin = true;
    }
  }

  private async loginSocial() {
    //this.invalidlogin = false;
    let user = await this.state.loginSocial(this.customer, this.socialuser.idToken, this.socialuser.email, this.socialuser.provider);
    if (user != null) {
      this.router.navigateByUrl(this.returnUrl);
    } else {
      //this.invalidlogin = true;
    }
  }

  async signInWithGoogle() {
    this.socialuser = undefined;
    await this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  async signInWithMicrosoft() {
    this.socialuser = undefined;
    await this.authService.signIn(MicrosoftLoginProvider.PROVIDER_ID);
  }

}
