import 'reflect-metadata';
import { jsonObject, jsonMember, TypedJSON, jsonArrayMember } from 'typedjson';
import { DataFilter } from '../../../filters/data-filter/data-filter-service';
import { Guid } from '../../../utils/guid-service';
import { Aggregation, BaseQuery, FilterLogicalGroupType, Transformation, XDataType } from '../../../XProjector/xprojector-client-service';
import { GroupSelectionTypes, OutputDataType, WidgetConfig } from '../../widget-config-service';
import { MasterTimeSettings } from '../../xproj-widget-service';

@jsonObject
export abstract class BaseControllerConfig {
  @jsonMember
  public Id : string = Guid.newGuid();

  @jsonMember
  public Name : string = '';

  public abstract TypeName : string;
}

@jsonObject
export class XprojOutputRangeControllerConfig extends BaseControllerConfig {

  public TypeName : string = 'Range';

  @jsonMember
  public Label : string = '';

  @jsonMember
  public Min : number = 0;

  @jsonMember
  public Max : number = 100;

  @jsonMember
  public Step : number = 10;

  @jsonMember
  public Value : number = 0;

  @jsonMember
  public ShowPlay : boolean = true;

  @jsonMember
  public PlaySecondsPerStep : number = 2;

}

@jsonObject
export class ButtonConfig {

  @jsonMember
  public ScriptedAction : boolean = false;

  @jsonMember
  public ScriptedActionLuaScript : string = '';

  @jsonMember
  public EmitsOutputParameter : boolean = true;

  @jsonMember
  public Default : boolean = false;

  @jsonMember
  public Label : string = '';

  @jsonMember
  public ValueString : string = '';

  @jsonMember
  public ValueNumber : number = 0;

  @jsonMember
  public ValueNumber2 : number = 0;

  @jsonMember
  public ValueNumber3 : number = 0;

  @jsonMember
  public ValueDate : Date = new Date();

  @jsonMember
  public ButtonType : ButtonType = ButtonType.REGULAR;

}

export enum ButtonType {
  REGULAR  = 0,
  SUCCESS  = 1,
  INFO     = 2,
  WARNING  = 3,
  DANGER   = 4,
}

@jsonObject
export class XprojOutputButtonControllerConfig extends BaseControllerConfig {

  public TypeName : string = 'Buttons';

  @jsonMember
  public EmitsOutputParameter : boolean = true;

  @jsonArrayMember(ButtonConfig)
  public Buttons : ButtonConfig[] = [];

  @jsonMember
  public DataType : OutputDataType = OutputDataType.Int32;

  @jsonMember
  public DataType2 : OutputDataType = OutputDataType.Int32;

  @jsonMember
  public Name2 : string = '';

  @jsonMember
  public DataType3 : OutputDataType = OutputDataType.Int32;

  @jsonMember
  public Name3 : string = '';
}

@jsonObject
export class ComboboxMemberConfig {
  @jsonMember
  public Default : boolean = false;

  @jsonMember
  public Label : string = '';

  @jsonMember
  public ValueString : string = '';

  @jsonMember
  public ValueNumber : number = 0;

  @jsonMember
  public ValueDate : Date = new Date();

}

@jsonObject
export class ComboboxColumnConfig {
  @jsonMember
  public Id : string = Guid.newGuid();

  @jsonMember
  public ColumnName : string = '';

  @jsonMember
  public Label : string = '';

  @jsonMember
  public Datatype : XDataType = XDataType.Number;

  @jsonMember
  public Unit : string = '';

  @jsonMember
	public UseUnitInputParameter : boolean = false;

  @jsonMember
  public UnitInputParameterId: string = '';

  @jsonMember
  public ColumnOutName : string = '';

  @jsonMember
  public Transform: Aggregation = Aggregation.NONE;

  @jsonMember
	public UseAggregationInputParameter : boolean = false;

  @jsonMember
  public AggregationInputParameterId: string = '';

  @jsonMember
	public ColumnTransformation : Transformation = Transformation.NONE;

  @jsonMember
	public IncludeInOutput : boolean = false;

  public Clone(): ComboboxColumnConfig {
		return TypedJSON.parse(TypedJSON.stringify(this, ComboboxColumnConfig), ComboboxColumnConfig);
	}
}

@jsonObject
export class ComboboxControllerQuery {

  public Id : string = Guid.newGuid();

  @jsonMember
  public Query : BaseQuery = new BaseQuery();

  @jsonMember
  public ProjectionId : string = '';

  @jsonMember
  public MaxItems : number = 100;

  @jsonArrayMember(String)
  public Group : string[] = [];

  @jsonArrayMember(ComboboxColumnConfig)
  public DataConfigs : ComboboxColumnConfig[] = [];

  @jsonMember
  public ColumnNameLabel : string = '';

  @jsonArrayMember(DataFilter)
  public DataFilters : DataFilter[] = [];

  @jsonMember
  public FilterLogicalGroupType : FilterLogicalGroupType = FilterLogicalGroupType.AND;

  @jsonMember
  public timestampColumnName : string = '';

  @jsonMember
	public UseProjectionInputParameter : boolean = false;

  @jsonMember
  public ProjectionInputParameterId: string = '';

  @jsonMember
  public GroupSelectionType : GroupSelectionTypes = GroupSelectionTypes.GROUP;

  @jsonMember
  public GroupInputParameterId: string = '';

  @jsonArrayMember(String)
  public GroupInputParameterIds: string[] = [];

  @jsonMember
  public UseGrouping : boolean = false;

  @jsonMember
  public DefaultSortColumnName : string = '';

  @jsonMember
  public DefaultSortDescending : boolean = false;

  @jsonMember
	public UseTransformInputParameter : boolean = false;

  @jsonMember
  public TransformInputParameterId: string = '';

  @jsonMember
  public GroupingTransform : Transformation = Transformation.NONE;

}

@jsonObject
export class XprojOutputComboboxControllerConfig extends BaseControllerConfig {

  public TypeName : string = 'Combobox';

  @jsonArrayMember(ComboboxMemberConfig)
  public Members : ComboboxMemberConfig[] = [];

  @jsonMember
  public Label : string = '';

  @jsonMember
  public DataType : OutputDataType = OutputDataType.Int32;

  @jsonMember
  public ProjectionData : boolean = false;

  @jsonMember
  public ProjectionDataAddEmptyValue : boolean = true;

  @jsonMember
  public ProjectionDataEmptyValueLabel : string = '';

  @jsonMember
  public ProjectionDataSearchable : boolean = false;

  @jsonMember
  public ConfigQuery : ComboboxControllerQuery = new ComboboxControllerQuery();

}

@jsonObject
export class XprojOutputAggregationControllerConfig extends BaseControllerConfig {

  public TypeName : string = 'Aggregation';

  @jsonMember
  public DefaultAggregation : Aggregation = Aggregation.NONE;

  @jsonMember
  public Label : string = '';

  @jsonMember
  NONE : boolean = true;
  @jsonMember
  COUNT : boolean = true;
  @jsonMember
  SUM : boolean = true;
  @jsonMember
  MAX : boolean = true;
  @jsonMember
  MIN : boolean = true;
  @jsonMember
  FIRST : boolean = true;
  @jsonMember
  LAST : boolean = true;
  @jsonMember
  CHECKSUM : boolean = true;
  @jsonMember
  FORWARD_DIFF : boolean = true;

  @jsonMember
  MEAN_ARITHMETIC : boolean = true;
  @jsonMember
  MEAN_GEOMETRIC : boolean = true;
  @jsonMember
  MEAN_HARMONIC : boolean = true;

  //MODE : boolean = true;

  @jsonMember
  MEDIAN : boolean = true;
  //MEDIAN_APPROX : boolean = true;

  @jsonMember
  SD_SAMPLED_UNCORRECTED : boolean = true;
  @jsonMember
  SD_SAMPLED_CORRECTED : boolean = true;
  @jsonMember
  SD_SAMPLED_UNBIASED_APPROX_ND : boolean = true;

  @jsonMember
  MAD_ORIGO_MEAN_ARITHMETIC : boolean = true;
  @jsonMember
  MAD_ORIGO_MEAN_GEOMETRIC : boolean = true;
  @jsonMember
  MAD_ORIGO_MEAN_HARMONIC : boolean = true;

  @jsonMember
  MAD_ORIGO_MEDIAN : boolean = true;
  //MAD_ORIGO_MODE : boolean = true;

  @jsonMember
  VARIANCE_ND : boolean = true;
  @jsonMember
  S2_ND : boolean = true;

}

@jsonObject
export class SelectItemConfig {
  @jsonMember
  public Show : boolean;

  @jsonMember
  public Label : string;

  constructor(show?: boolean, label? : string) {
    this.Show = show;
    this.Label = label;
  }
}

@jsonObject
export class XprojOutputTransformControllerConfig extends BaseControllerConfig {

  public TypeName : string = 'Transform';

  @jsonMember
  public DefaultTransform : Transformation = Transformation.NONE;

  @jsonMember
  public Label : string = '';

  @jsonMember(SelectItemConfig)
  NONE : SelectItemConfig = new SelectItemConfig(true, 'None');
  @jsonMember(SelectItemConfig)
  TIMESTAMP_RESOLUTION_YEAR = new SelectItemConfig(true, 'Year');
  @jsonMember(SelectItemConfig)
  TIMESTAMP_RESOLUTION_MONTH = new SelectItemConfig(true, 'Month');
  @jsonMember(SelectItemConfig)
  TIMESTAMP_RESOLUTION_DAY = new SelectItemConfig(true, 'Day');
  @jsonMember(SelectItemConfig)
  TIMESTAMP_RESOLUTION_HOUR = new SelectItemConfig(true, 'Hour');
  @jsonMember(SelectItemConfig)
  TIMESTAMP_RESOLUTION_MINUTE = new SelectItemConfig(true, 'Minute');
  @jsonMember(SelectItemConfig)
  TIMESTAMP_RESOLUTION_SECOND = new SelectItemConfig(true, 'Second');
  @jsonMember(SelectItemConfig)
  TIMESTAMP_DAYOFYEAR = new SelectItemConfig(true, 'Day of year');
  @jsonMember(SelectItemConfig)
  TIMESTAMP_DAYOFMONTH = new SelectItemConfig(true, 'Day of month');
  @jsonMember(SelectItemConfig)
  TIMESTAMP_DAYOFWEEK = new SelectItemConfig(true, 'Day of week');
  @jsonMember(SelectItemConfig)
  TIMESTAMP_SECONDS_OF_DAY = new SelectItemConfig(true, 'Time of day - total seconds of day');
  @jsonMember(SelectItemConfig)
  TIMESTAMP_MINUTES_OF_DAY = new SelectItemConfig(true, 'Time of day - total minutes of day');
  @jsonMember(SelectItemConfig)
  TIMESTAMP_HOURS = new SelectItemConfig(true, 'Time of day - hours of day');
  @jsonMember(SelectItemConfig)
  TIMESTAMP_MINUTES = new SelectItemConfig(true, 'Time of day - minutes of hour');
  @jsonMember(SelectItemConfig)
  TIMESTAMP_SECONDS = new SelectItemConfig(true, 'Time of day - seconds of minute');
  @jsonMember(SelectItemConfig)
  FIXED_BINSIZE = new SelectItemConfig(true, 'Fixed bin size');
  @jsonMember(SelectItemConfig)
  FORWARD_DIFF = new SelectItemConfig(true, 'Forward diff');
}

@jsonObject
export class XprojOutputProjectionControllerConfig extends BaseControllerConfig {

  public TypeName : string = 'Projection';

  @jsonMember
  public Label : string = '';

  @jsonMember
  public DefaultProjectionId : string = '';

  @jsonArrayMember(String)
  public DefaultGroup : string[] = [];

  @jsonMember
  public ShowProjection : boolean = true;

  @jsonMember
  public ShowGroup : boolean = true;

}

@jsonObject
export class TimeConfig {
  @jsonMember
  public Text : string = '';

  @jsonMember
  public Year : number = -1;

  @jsonMember
  public YearOffset : number = 0;

  @jsonMember
  public Month : number = -1;

  @jsonMember
  public MonthOffset : number = 0;

  @jsonMember
  public Day : number = -1;

  @jsonMember
  public DayOffset : number = -1;

}

export enum OutputTimeType {
  Range = 0,
  Timestamp = 1,
  Period
}

export enum OutputPeriod {
  Hour = 0,
  Day = 1,
  WeekSunday = 2,
  WeekMonday = 3,
  Month = 4,
  Quarter = 5,
  Year = 6,
  SixMonths = 7
}

export enum MonthOfYear {
  January = 1,
  February = 2,
  March = 3,
  April = 4,
  May = 5,
  June = 6,
  July = 7,
  August = 8,
  September = 9,
  October = 10,
  November = 11,
  December = 12
}

@jsonObject
export class XprojOutputTimeControllerConfig extends BaseControllerConfig {

  public TypeName : string = 'Time';

  @jsonMember
  public RelativeTime : boolean = false;

  @jsonMember
  public FromConfig : TimeConfig = new TimeConfig();

  @jsonMember
  public ToConfig : TimeConfig = new TimeConfig();

  @jsonMember
  public Type : OutputTimeType = OutputTimeType.Range;

  @jsonMember
  public Period : OutputPeriod = OutputPeriod.Month;

  @jsonMember
  public PeriodCountBackward : number = 6;

  @jsonMember
  public PeriodCountForward : number = 0;

  @jsonMember
  public QuarterStartMonth : MonthOfYear = MonthOfYear.March;

  @jsonMember
  public DateFormat : string = '';

}

@jsonObject
export class ToggleMemberConfig {
  @jsonMember
  public Default : boolean = false;

  @jsonMember
  public Label : string = '';

  @jsonMember
  public ValueString : string = '';

  @jsonMember
  public ValueNumber : number = 0;

  @jsonMember
  public ValueDate : Date = new Date();

}

@jsonObject
export class XprojOutputToggleControllerConfig extends BaseControllerConfig {

  public TypeName : string = 'Toggle';

  @jsonMember
  public OnConfig : ToggleMemberConfig = new ToggleMemberConfig();

  @jsonMember
  public OffConfig : ToggleMemberConfig = new ToggleMemberConfig();

  @jsonMember
  public Label : string = '';

  @jsonMember
  public DefaultValue : boolean = false;

  @jsonMember
  public DataType : OutputDataType = OutputDataType.Int32;

  // TODO This type should not be ComboboxControllerQuery..
  @jsonMember
  public ProjectionData : boolean = false;

  @jsonMember
  public ConfigQuery : ComboboxControllerQuery = new ComboboxControllerQuery();
}

@jsonObject
export class RadioButtonConfig {
  @jsonMember
  public Default : boolean = false;

  @jsonMember
  public Label : string = '';

  @jsonMember
  public ValueString : string = '';

  @jsonMember
  public ValueNumber : number = 0;

  @jsonMember
  public ValueDate : Date = new Date();

}

@jsonObject
export class XprojOutputRadioButtonControllerConfig extends BaseControllerConfig {

  public TypeName : string = 'RadioButtons';

  @jsonArrayMember(RadioButtonConfig)
  public RadioButtons : RadioButtonConfig[] = [];

  @jsonMember
  public DataType : OutputDataType = OutputDataType.Int32;
}

@jsonObject({
  knownTypes: [
    XprojOutputRangeControllerConfig,
    XprojOutputButtonControllerConfig,
    XprojOutputComboboxControllerConfig,
    XprojOutputAggregationControllerConfig,
    XprojOutputTransformControllerConfig,
    XprojOutputProjectionControllerConfig,
    XprojOutputTimeControllerConfig,
    XprojOutputToggleControllerConfig,
    XprojOutputRadioButtonControllerConfig
  ]
})
export class OutputControllerWidgetConfig extends WidgetConfig {

  @jsonArrayMember(BaseControllerConfig)
  public Controllers : BaseControllerConfig[] = [];

  @jsonMember
  public UseApplyButton : boolean = false;

  @jsonMember
  public ApplyButtonText : string = 'Apply';

  public Clone(): OutputControllerWidgetConfig {
    return TypedJSON.parse(TypedJSON.stringify(this, OutputControllerWidgetConfig), OutputControllerWidgetConfig);
  }

  public GetSubscriprionData(): { projectionId: string, group : string[] }[] {
    return [];
  }

  public CanExportToExcel : boolean = false;

  public CanExportToImage : boolean = false;
}
