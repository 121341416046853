import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'rossakerbmseventactivepipe'
})
export class RossakerBmsEventActivePipe implements PipeTransform {

  constructor() {}

  transform(active: boolean, ...args: unknown[]): string {

    return  active ? 'Active' : 'Inactive';
  }

}
