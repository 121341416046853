import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ClrDatagrid, ClrDatagridStateInterface } from '@clr/angular';
import { GridsterItemComponentInterface } from 'angular-gridster2';
import { WidgetUtils } from '../../../utils/widget-utils-service';
import { BaseQuery, BaseQueryInputColumnDescription, ColumnFilteringNumerical, ColumnFilteringRelativeTimestamp, ColumnFilteringString, ColumnFilteringTimestamp, ColumnGroupingDescription, FilterComparator, FilterLogicalGroup, FilterLogicalGroupType, GroupsQuery, MsgPackCloneObject, ProjectionColumnDescription, ProjectionExecution, SetDataColumnDescription, SetDataQuery, SetSplittedDataQuery, XDataType, XProjectorClient } from '../../../XProjector/xprojector-client-service';
import { WidgetBase } from '../../widget-base';
import { GroupSelectionTypes } from '../../widget-config-service';
import { LinkedWidgetChangeParameters, MasterTimeSettings, WidgetOutputChangeParameters, XprojWidgetService } from '../../xproj-widget-service';
import { ColumnConfig, EditMode, EnumMember, ProjectionDataEditorWidgetConfig, ProjectionDataEditorWidgetQuery } from '../projection-dataeditor-widget-config/projection-dataeditor-widget-config-service';
import { XprojDatagridstringfilterComponent } from '../../../filters/datagrid-string-filter/xproj-datagrid-string-filter.component';
import { XprojModalService } from '../../../modals/xproj-modal-service.service';
import * as XLSX from 'xlsx';
import { XprojAlertService } from '../../../services/xproj-alert.service';
import { XprojGroupSelectionComponent } from '../../../filters/group-selection/xproj-group-selection.component';
import { ProjectionDataeditorWidgetUtils } from '../projection-dataeditor-utils-service';
import { DateHelper } from "../../../helpers/date-helper-service";
import { LOGGERSERVICE, XprojLoggerService } from '../../../logger/xproj-logger-service';
import { XprojDatagridNumericFilterComponent } from '../../../filters/datagrid-numeric-filter/xproj-datagrid-numeric-filter.component';

export class QueryDataColumn {
  id: string;
  columnname: string;
  columnnameSetData: string;
  label: string;
  datatype: XDataType;
  projectionid: string;
  hidden: boolean = false;
  writeable: boolean = false;
  primaryKey: boolean = false;
  editMode: EditMode = EditMode.String;
  enumMembers: EnumMember[] = [];
  useFixedValue: boolean = false;
  isOnDeleteColumn: boolean = false;
  fixedValueInputParameterId: string = '';
  fixedValue: string = '';
  data: any[] = [];
}

class TableData {
  columns: QueryDataColumn[] = [];
  data_col0: any = [];
  data_col_deleted: any = [];
  initilized: boolean = false;
  oldInitilized: boolean = false;
}


@Component({
  selector: 'xproj-projection-dataeditor-widget',
  templateUrl: './xproj-projection-dataeditor-widget.component.html',
  styleUrls: ['./xproj-projection-dataeditor-widget.component.scss']
})
export class XprojProjectionDataEditorWidgetComponent extends WidgetBase implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild("grid", { read: ClrDatagrid, static: false }) grid: ClrDatagrid;
  @ViewChild("groupSelect", { read: XprojGroupSelectionComponent, static: false }) groupSelect: XprojGroupSelectionComponent;
  @ViewChild('currentPageInput') currentPageInputRef: ElementRef;

  widgetConfig: ProjectionDataEditorWidgetConfig;

  loadingQuery: boolean = false;
  loading: boolean = true;

  private fromZoom: Date = null;
  private toZoom: Date = null;
  private useRelativeTimestamp: boolean = true;

  sizeOptions = [10, 20, 50, 100];
  pageSize: number = this.sizeOptions[1];

  private forcereload: boolean = false;
  queryNrRows: number = 0;
  queryNrTotalRows: number = 0;

  updatedColumns: QueryDataColumn[] = [];
  tableData: TableData = new TableData();
  columns: ProjectionColumnDescription[] = [];
  columnConfigs: ColumnConfig[] = [];
  fixedGroup: string[] = [];

  lastQueryJson: string = '';
  detailState: any = null;
  groupFilterEnabled: boolean = false;

  selected = [];
  selectedValues: any[] = [];
  selectedIsDeleted: boolean = false;
  selectedGroup: string[] = [];
  savingProperties: boolean = false;
  showFilter: boolean = false;
  queryTargetgroup: string[] = [];

  projectionColumns: ProjectionColumnDescription[] = [];
  projectionExecutions: ProjectionExecution[] = [];

  showDeleted = false;

  state: any;
  currentPage: number = 1;

  private lastQuery: BaseQuery;

  XDataType = XDataType;
  EditMode = EditMode;

  constructor(
    @Inject(LOGGERSERVICE) public logger: XprojLoggerService,
    public xprojClient: XProjectorClient,
    public widgetService: XprojWidgetService,
    public cdr: ChangeDetectorRef,
    private modalService: XprojModalService,
    private alertService: XprojAlertService,
    private dateHelper: DateHelper
  ) {
    super(logger, xprojClient, widgetService);
  }

  ngAfterViewInit(): void {
    if (!this.widgetConfig.ControlledByMaster || !this.responsive) {
      setTimeout(() => {
        this.load();
      }, 500);
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }


  async ngOnInit() {
    this.widgetConfig = this.config as ProjectionDataEditorWidgetConfig;

    this.columnConfigs = [];
    // this.widgetConfig.ConfigQuery.ColumnConfigs.forEach(c => {
    //   this.columnConfigs.push(c);
    // })

    this.fixedGroup = [];
    this.widgetConfig.ConfigQuery.FixedGroup.forEach(g => {
      this.fixedGroup.push(g);
    });

    await this.checkGroupEnabled();

    await super.ngOnInit();
  }

  async onInit() {
    this.updateFixedGroup();

    this.sizeOptions = this.widgetConfig.RowsPerPageOptions;
    this.pageSize = this.widgetConfig.DefaultRowsPerPage;
    this.widgetConfig.ConfigQuery.MaxItems = this.pageSize;
  }

  async onRefresh() {
    this.forcereload = true;
    this.state = undefined;


    if (this.state) {
      this.state.page.current = 1;
      this.currentPage = 1;
    }

    await this.load(this.state);
  }

  async onResized(component: GridsterItemComponentInterface) {
    this.setWidgetHeight(this.getHeight(component));
    this.load();
  }

  async onReset() {
    this.useRelativeTimestamp = true;
    this.fromZoom = null;
    this.toZoom = null;
    this.state = undefined;
    await this.load();
  }

  async onUpdateQuery() {
    this.state = undefined;
    await this.load();
  }

  async onLinkedWidgetChanged(e: LinkedWidgetChangeParameters) {
    let refresh: boolean = false;
    if (e.zoom) {
      if (this.fromZoom != e.zoom.from || this.toZoom != e.zoom.to) {
        this.fromZoom = e.zoom.from;
        this.toZoom = e.zoom.to;
        this.useRelativeTimestamp = false;

        refresh = true;
      }
    }

    if (e.master) {
      // if (e.master.projectionId?.length > 0 && e.master.group) {
      //   this.widgetConfig.ConfigQueries.forEach(query => {
      //     query.Query.targetprojectionid = e.master.projectionId;
      //     query.Query.targetgroup = e.master.group;
      //     refresh = true;
      //   })
      // }
      if (e.master.time) {
        if (e.master.time.relativeTimestamp) {
          this.relativeTimestamp = e.master.time.relativeTimestamp;
          this.useRelativeTimestamp = true;
          refresh = true;
        }
        else {
          this.fromZoom = e.master.time.from;
          this.toZoom = e.master.time.to;
          this.useRelativeTimestamp = false;
          refresh = true;
        }
      }
    }

    if (refresh) {
      await this.load();
    }
  }

  async onWidgetOutputTimeChanged(time: MasterTimeSettings) {
    if (time) {
      if (time.relativeTimestamp) {
        this.relativeTimestamp = time.relativeTimestamp;
        this.useRelativeTimestamp = true;
      }
      else {
        this.fromZoom = time.from;
        this.toZoom = time.to;
        this.useRelativeTimestamp = false;
      }
    }
  }

  async onWidgetOutputChanged(event: WidgetOutputChangeParameters[]) {
    this.updateFixedGroup();
    this.load();
  }

  private setWidgetHeight(height: number): void {
    if (height) {
      this.widgetheight = height;
    }
  }

  updateFixedGroup() {
    let updated = false;
    if (this.widgetConfig.ConfigQuery.FixedGroupSelectionType == GroupSelectionTypes.GROUP_INPUT) {
      this.fixedGroup = this.getParameterValue(this.widgetConfig.ConfigQuery.FixedGroupInputParameterId, this.fixedGroup).value;
      this.groupSelect?.setFixedGroup(this.fixedGroup);
      updated = true;
    }
    else if (this.widgetConfig.ConfigQuery.FixedGroupSelectionType == GroupSelectionTypes.GROUP_INPUT_PARAMETERS) {
      this.fixedGroup = [];
      this.widgetConfig.ConfigQuery.FixedGroupInputParameterIds.forEach(id => {
        this.fixedGroup.push(this.getParameterValue(id, '').value + '');
        this.groupSelect?.setFixedGroup(this.fixedGroup);
      });
      updated = true;
    }

    if (this.groupFilterEnabled && updated) {
      this.selectedGroup = this.fixedGroup.concat(this.widgetConfig.ConfigQuery.Query?.targetgroup);
    }

  }

  indexTracker(index: number, value: any) {
    return index;
  }

  getColumnValue(column: QueryDataColumn, index: number): any {
    switch (column.editMode) {
      case EditMode.XDatatype:
        return Object.values(XDataType).indexOf(XDataType[column.data[index]]);

      case EditMode.Boolean:
        return column.data[index] != 0;

      case EditMode.Timestamp:
        try
        {
          return this.dateHelper.utils.formatByString(column.data[index], "yyyy-MM-dd'T'HH:mm:ss");
        }
        catch {
          return column.data[index];
        }

      case EditMode.Enum:
        return column.data[index];

      case EditMode.Flags:
        if (!isNaN(column.data[index])) {
          let flagResult: boolean[] = [];
          column.enumMembers.forEach(e => flagResult.push((column.data[index] & e.value) > 0));
          return flagResult;
        }
        else {
          return column.data[index];
        }


      default:
        return column.data[index];

    }
  }

  getSetDataValue(column: QueryDataColumn, value: any): any {
    if (column.useFixedValue) {
      return this.getInitValue(column, value);
    }
    else {
      switch (column.editMode) {
        case EditMode.Boolean:
          return value == false ? 0 : 1;

        case EditMode.Timestamp:
          if(typeof value === 'string')
          {
            try {
              return this.dateHelper.utils.parse(value, "yyyy-MM-dd'T'HH:mm:ss");
            }
            catch {
              return this.dateHelper.utils.parse(value, "yyyy-MM-dd'T'HH:mm");
            }
          }
          else
          {
            let fromepoch = (value-25569)*24*60*60*1000;
            return new Date(fromepoch)
          }

        case EditMode.Enum:
          return value ?? '';

        case EditMode.Flags:
          let flagResult: number = 0;
          for (let i = 0; i < column.enumMembers.length; i++) {
            if (value[i]) {
              flagResult += column.enumMembers[i].value;
            }
          }
          return flagResult;

        default:
          return value ?? '';

      }
    }
  }

  async getColumns(projectionId: string, group: Array<string>): Promise<ProjectionColumnDescription[]> {
    let groupstr = "";
    if (group) {
      groupstr = group.join(",");
    }
    return this.xprojClient.RequestListQueryableProjectionColumns(projectionId, groupstr, 0, 500);
  }

  async checkColumns(config: ProjectionDataEditorWidgetConfig, query: BaseQuery) {
    if (this.columnConfigs?.length == 0) {
      if (this.columns.length == 0) {
        this.columns = await this.getColumns(query.targetprojectionid, this.selectedGroup);
      }

      this.widgetConfig.ConfigQuery.ColumnConfigs.forEach(c => {
        let col = this.columns.find(column => column.columnname == c.ColumnName);

        if (col) {
          c.PrimaryKey = col.primarykey;
          c.Tags = col.tags;
          c.EditMode = ProjectionDataeditorWidgetUtils.getEditMode(col.tags, col.datatype);
          c.EnumMembers = (c.EditMode == EditMode.Enum || c.EditMode == EditMode.Flags) ? ProjectionDataeditorWidgetUtils.getEnumMembers(c.Tags, c.EditMode != EditMode.Flags) : [];
          this.columnConfigs.push(c);
        }
      })
    }

    if (this.columnConfigs.length > 0) {
      let colindex = 0;
      for (let column of this.columnConfigs) {
        let col = new BaseQueryInputColumnDescription();
        col.columnname = column.ColumnName;
        if (column.Label?.length > 0) {
          col.columnoutname = column.ColumnOutName = column.Label;
        }
        else {
          col.columnoutname = column.ColumnOutName = column.ColumnName;
        }

        if (colindex == 0) {
          query.sorting.columnname = col.columnname;
          query.sorting.descending = false;
          query.grouping = new ColumnGroupingDescription();
          query.columns.push(col);
        }
        else {
          query.columns.push(col);
        }

        colindex++;
      }

    }
  }

  async checkGroupEnabled() {
    if (this.widgetConfig.GroupFilterEnabled) {
      let groupsQuery = new GroupsQuery();
      groupsQuery.targetprojectionid = this.widgetConfig.ConfigQuery.ProjectionId;
      groupsQuery.maxitems = 1;
      groupsQuery.seekoffset = 0;
      groupsQuery.groups.push('.*');

      let tgr = await this.xprojClient.RequestQueryGroups(groupsQuery);

      this.groupFilterEnabled = tgr.nrgroups > 0;
    }
    else {
      this.groupFilterEnabled = false;
    }

    if (this.groupFilterEnabled) {
      this.selectedGroup = this.widgetConfig.ConfigQuery.Query?.targetgroup;
    }
  }

  onDetailChange(dataIndex1: number) {
    this.selectedValues = [];
    this.selectedIsDeleted = false;
    if (dataIndex1 != null) {
      this.tableData.columns.forEach(col => {
        this.selectedValues.push(this.getColumnValue(col, dataIndex1 - 1));
        //this.selectedValues.push(col.data[dataIndex1 - 1]);
        if (this.widgetConfig.SetColumnValueOnDelete && col.columnname == this.widgetConfig.OnDeleteColumnName) {
          this.selectedIsDeleted = this.selectedValues[this.selectedValues.length - 1];
        }
      });
    }
  }

  async selectedProjectionGroupChange(group: any) {
    this.selectedGroup = group;
    this.onRefresh();
  }

  selectedChanged($event) {
    //this.logger.info($event);
  }

  getExportQueries(): (BaseQuery)[] {
    return [this.lastQuery];
  }

  getInitValue(queryDataColumn: QueryDataColumn, defaultValue: any): any {
    try {
      let result = defaultValue;
      if (queryDataColumn.useFixedValue) {
        if (queryDataColumn.fixedValueInputParameterId?.length > 0) {
          result = this.getParameterValue(queryDataColumn.fixedValueInputParameterId, defaultValue).value;
        }
        else {
          switch (queryDataColumn.editMode) {
            case EditMode.String:
              result = queryDataColumn.fixedValue;
              break;
            case EditMode.Number:
              result = parseFloat(queryDataColumn.fixedValue);
              break;
            case EditMode.Boolean:
              let val = queryDataColumn.fixedValue.toLocaleLowerCase();
              if (val == 'true' || val == 'false') {
                result = val == 'true';
              }
              else {
                result = parseFloat(queryDataColumn.fixedValue) != 0;
              }
              break;
            case EditMode.Timestamp:
              result = new Date(queryDataColumn.fixedValue);
              break;
            case EditMode.XDatatype:
              result = XDataType[queryDataColumn.fixedValue];
              break;
            case EditMode.Enum:
            case EditMode.Flags:
              result = +queryDataColumn.fixedValue;
              if (!result) {
                let enumMember = queryDataColumn.enumMembers.find(e => e.name == queryDataColumn.fixedValue);
                if (enumMember) {
                  result = enumMember.value;
                }
                else {
                  result = 0;
                }
              }
              break;

            default:
              result = queryDataColumn.fixedValue;
              break;
          }
        }
      }

      return result;
    }
    catch (err) {
      this.logger.error(err);
      return defaultValue;
    }
  }

  async onAddData() {
    setTimeout(() => {
      try {
        this.cdr.detach();

        if (this.tableData) {
          this.tableData.columns.forEach(c => {
            switch (c.editMode) {
              case EditMode.String:
                c.data.splice(0, 0, this.getInitValue(c, ''));
                break;
              case EditMode.Number:
                c.data.splice(0, 0, this.getInitValue(c, 0));
                break;
              case EditMode.Boolean:
                c.data.splice(0, 0, this.getInitValue(c, false));
                break;
              case EditMode.Timestamp:
                c.data.splice(0, 0, this.getInitValue(c, new Date()));
                break;
              case EditMode.XDatatype:
                c.data.splice(0, 0, this.getInitValue(c, XDataType.String));
                break;
              case EditMode.Enum:
              case EditMode.Flags:
                c.data.splice(0, 0, this.getInitValue(c, 0));
                break;

              default:
                c.data.splice(0, 0, this.getInitValue(c, ''));
                break;
            }
          });

          setTimeout(() => {
            this.detailState = 1;
          });

        }
      }
      finally {
        this.cdr.reattach();
      }
    });
  }

  async onCloseDetail() {
    this.detailState = null;
  }

  async onDeleteData(dataIndex1: number, closeDetail: boolean = false) {
    if (this.widgetConfig.SetColumnValueOnDelete) {
      let onDeleteColumn = this.tableData.columns.find(c => c.columnname == this.widgetConfig.OnDeleteColumnName);
      if (onDeleteColumn) {
        onDeleteColumn.data[dataIndex1 - 1] = 1;
      }

      let onDeletedAtColumn = this.tableData.columns.find(c => c.columnname == this.widgetConfig.OnDeletedAtColumnName);
      if (onDeletedAtColumn) {
        onDeletedAtColumn.data[dataIndex1 - 1] = new Date();
      }

      this.onDetailChange(dataIndex1);
      this.onSaveData(dataIndex1 - 1);
    }
    else {
      //TODO
    }

    if (closeDetail) {
      this.detailState = null;
      this.onRefresh();
    }
  }

  async onDeleteSelectedData() {
    if (this.selected?.length > 0) {
      this.selected.forEach(async (index) => {
        await this.onDeleteData(index);
      });

      this.onRefresh();
    }
  }

  async onRestoreData(dataIndex1: number) {
    if (this.widgetConfig.SetColumnValueOnDelete) {
      let onDeleteColumn = this.tableData.columns.find(c => c.columnname == this.widgetConfig.OnDeleteColumnName);
      if (onDeleteColumn) {
        onDeleteColumn.data[dataIndex1 - 1] = 0;

        let onDeletedAtColumn = this.tableData.columns.find(c => c.columnname == this.widgetConfig.OnDeletedAtColumnName);
        if (onDeletedAtColumn) {
          onDeletedAtColumn.data[dataIndex1 - 1] = new Date('0001-01-01T00:00:00Z');
        }

        this.onDetailChange(dataIndex1);
        this.onSaveData(dataIndex1 - 1);

        this.detailState = null;
        this.onRefresh();
      }
    }
  }

  async onDuplicateSelected() {
    if (this.selected?.length == 1) {
      this.onDetailChange(this.selected[0]);
      this.onDuplicateData(this.selected[0] - 1);
    }
  }

  async onDuplicateData(dataIndex: number) {
    let primaryKeyColumn = this.tableData.columns.find(c => c.primaryKey);

    if (primaryKeyColumn) {
      let primaryKey = primaryKeyColumn.data[dataIndex];

      this.modalService.ShowInputModal({ header: 'Duplicate data', description: 'New primary key?', value: primaryKey }, (result, value) => {
        if (result) {
          try {
            this.cdr.detach();

            if (this.tableData) {
              let i = 0;
              let updatedTableData: TableData = MsgPackCloneObject(this.tableData) as TableData;
              updatedTableData.columns.forEach(c => {
                if (c.primaryKey) {
                  c.data.splice(0, 0, value);
                } else {
                  c.data.splice(0, 0, this.selectedValues[i]);
                }
                i++;
              });

              this.updateTableData(updatedTableData);
              this.detailState = null;

              setTimeout(() => {
                this.detailState = 1;
              });

            }
          }
          finally {
            this.cdr.reattach();
          }
        }
      });

    }
  }

  onImportData($event) {
    const target: DataTransfer = <DataTransfer>($event.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');

    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {

      const filename: string = e.target.result;
      const data = <any[]>this.importFromFile(filename);

      this.importData(data);
    };
    reader.readAsBinaryString(target.files[0]);
  }

  importFromFile(filename: string): XLSX.AOA2SheetOpts {

    const wb: XLSX.WorkBook = XLSX.read(filename, { type: 'binary' });

    /* grab first sheet */
    const wsname: string = wb.SheetNames[0];
    const ws: XLSX.WorkSheet = wb.Sheets[wsname];

    return <XLSX.AOA2SheetOpts>(XLSX.utils.sheet_to_json(ws, { header: 1 }));
  }

  async importData(data: any[][]) {
    this.logger.info(data);

    if (data.length > 0) {
      if (this.tableData.columns.length >= data[0].length) {
        //Check if header is present
        let withHeader: boolean = true;
        this.tableData.columns.forEach(c => {
          if (data[0].findIndex(header => header.toUpperCase() == c.columnname.toUpperCase()) == -1) {
            withHeader = false;
          }
        });

        let columnmapper: Map<number, number> = new Map<number, number>();
        let i = 0;
        this.tableData.columns.forEach(c => {
          columnmapper[i] = withHeader ? data[0].findIndex(header => header.toUpperCase() == c.columnname.toUpperCase()) : i;
          i++;
        });

        if (withHeader) {
          data = data.slice(1);
        }

        this.savingProperties = true;

        let indexByDataType: Map<string, number> = new Map<string, number>();
        indexByDataType['number'] = 0;
        indexByDataType['string'] = 0;
        indexByDataType['time'] = 0;

        let query: any = null;

        if (this.widgetConfig.UseNativeGroupSetData) {
          query = new SetSplittedDataQuery();
          query.group = this.widgetConfig.ConfigQuery.Group;
        }
        else {
          query = new SetDataQuery();
        }

        query.datastrings = [];
        query.datanumbers = [];
        query.datatimestamps = [];

        query.projectionid = this.widgetConfig.ConfigQuery.ProjectionId;

        i = 0;
        let importCount = 0;
        this.tableData.columns.forEach(col => {
          let setDataCol: SetDataColumnDescription = new SetDataColumnDescription();
          setDataCol.columnname = col.columnnameSetData;
          setDataCol.datatype = col.datatype;

          let index = 0;
          if (col.datatype <= XDataType.Number) {
            index = indexByDataType['number'];
            indexByDataType['number'] = index + 1;

            let colData = [];
            data.forEach(row => {
              if (row.length > 0) {
                if (i == 0) {
                  importCount++;
                }
                colData.push(this.getSetDataValue(col, row[columnmapper[i]]));
              }
            });

            query.datanumbers.push(colData);
          }
          else if (col.datatype == XDataType.Timestamp) {
            index = indexByDataType['time'];
            indexByDataType['time'] = index + 1;

            let colData = [];
            data.forEach(row => {
              if (row.length > 0) {
                if (i == 0) {
                  importCount++;
                }
                colData.push(this.getSetDataValue(col, row[columnmapper[i]]));
              }
            });

            query.datatimestamps.push(colData);
          }
          else {
            index = indexByDataType['string'];
            indexByDataType['string'] = index + 1;

            let colData = [];
            data.forEach(row => {
              if (row.length > 0) {
                if (i == 0) {
                  importCount++;
                }
                colData.push(this.getSetDataValue(col, row[columnmapper[i]]));
              }
            });

            query.datastrings.push(colData);
          }

          setDataCol.indexintypedvector = index;

          query.columns.push(setDataCol);

          i++;
        });

        let result: any = null;
        if (this.widgetConfig.UseNativeGroupSetData) {
          result = await this.xprojClient.RequestSetSplittedData(query).catch(err => {
            this.alertService.error('Error import file!', err);
          });
        }
        else {
          result = await this.xprojClient.RequestSetData(query).catch(err => {
            this.alertService.error('Error import file!', err);
          });
        }


        if (result) {
          this.alertService.success(importCount + ' rows imported successfully.');
        }

      }
      else {
        this.alertService.error('Invalid column count');
      }

    }
  }

  onFlagChange(i: number, flagIndex: number) {
    if (i < this.selectedValues.length && flagIndex < this.selectedValues[i].length) {
      this.selectedValues[i][flagIndex] = !this.selectedValues[i][flagIndex];
    }
  }

  async onSaveData(dataIndex: number) {
    try {
      this.cdr.detach();
      this.savingProperties = true;

      let indexByDataType: Map<string, number> = new Map<string, number>();
      indexByDataType['number'] = 0;
      indexByDataType['string'] = 0;
      indexByDataType['time'] = 0;

      let query: any = null;

      if (this.widgetConfig.UseNativeGroupSetData) {
        query = new SetSplittedDataQuery();
        query.group = this.queryTargetgroup;
      }
      else {
        query = new SetDataQuery();
      }

      query.datastrings = [];
      query.datanumbers = [];
      query.datatimestamps = [];

      query.projectionid = this.widgetConfig.ConfigQuery.ProjectionId;

      let i = 0;
      this.tableData.columns.forEach(col => {
        //Uncomment when supported in xdb
        //if (col.primaryKey || !this.widgetConfig.UseNativeGroupSetData || col.writeable) {
        let setDataCol: SetDataColumnDescription = new SetDataColumnDescription();
        setDataCol.columnname = col.columnnameSetData;
        setDataCol.datatype = col.datatype;

        let index = 0;
        if (col.datatype <= XDataType.Number) {
          index = indexByDataType['number'];
          indexByDataType['number'] = index + 1;

          query.datanumbers.push([this.getSetDataValue(col, this.selectedValues[i++])]);
        }
        else if (col.datatype == XDataType.Timestamp) {
          index = indexByDataType['time'];
          indexByDataType['time'] = index + 1;

          if (col.columnname == this.widgetConfig.OnModifiedAtColumnName) {
            this.selectedValues[i] = new Date();
            query.datatimestamps.push([this.selectedValues[i]]);
            i++;
          }
          else {
            query.datatimestamps.push([this.getSetDataValue(col, this.selectedValues[i++])]);
          }
        }
        else {
          index = indexByDataType['string'];
          indexByDataType['string'] = index + 1;

          query.datastrings.push([this.getSetDataValue(col, this.selectedValues[i++])]);
        }

        setDataCol.indexintypedvector = index;

        query.columns.push(setDataCol);
        // }
        // else {
        //   i++;
        // }
      });

      if (!this.widgetConfig.UseNativeGroupSetData && this.queryTargetgroup?.length > 0) {
        if (this.projectionExecutions.length == 0) {
          this.projectionExecutions = await this.xprojClient.RequestListConfigProjectionExecutions(this.widgetConfig.ConfigQuery.ProjectionId, 0, 10);
        }

        let defaultExec = this.projectionExecutions.find(exec => exec.isdefaultexecution);
        if (defaultExec) {
          if (this.projectionColumns.length == 0) {
            this.projectionColumns = await this.xprojClient.RequestListConfigProjectionColumns(this.widgetConfig.ConfigQuery.ProjectionId, 0, 500);
          }

          if (defaultExec.grouping.groupcolumns.length == this.queryTargetgroup.length) {
            for (let i = 0; i < this.queryTargetgroup.length; i++) {
              let col = this.projectionColumns.find(c => c.projectioncolumndescriptionid == defaultExec.grouping.groupcolumns[i].projectioncolumndescriptionid);
              if (col) {
                let setDataCol: SetDataColumnDescription = new SetDataColumnDescription();
                setDataCol.columnname = col.columnname;
                setDataCol.datatype = col.datatype;

                let index = 0;
                if (col.datatype <= XDataType.Number) {
                  index = indexByDataType['number'];
                  indexByDataType['number'] = index + 1;

                  query.datanumbers.push([+this.queryTargetgroup[i]]);
                }
                else {
                  index = indexByDataType['string'];
                  indexByDataType['string'] = index + 1;

                  query.datastrings.push([this.queryTargetgroup[i]]);
                }

                setDataCol.indexintypedvector = index;
                query.columns.push(setDataCol);
              }
            }
          }
        }
      }

      //let result = await this.xprojClient.RequestSetData(query);
      let result: any = null;
      if (this.widgetConfig.UseNativeGroupSetData) {
        result = await this.xprojClient.RequestSetSplittedData(query).catch(err => {
          this.alertService.error('Error save data!', err);
        });
      }
      else {
        result = await this.xprojClient.RequestSetData(query).catch(err => {
          this.alertService.error('Error save data!', err);
        });
      }

       if (result) {
        let updatedTableData: TableData = MsgPackCloneObject(this.tableData) as TableData;

        i = 0;
        updatedTableData.columns.forEach(col => {
          if (col.primaryKey) {
            if (col.data[dataIndex] != this.selectedValues[i]) {
              //Primary key changed - remove old row
              //TODO
              this.logger.info('TODO: remove old primary key', col.data[dataIndex]);
            }
          }
          i++;
        });

        i = 0;
        updatedTableData.columns.forEach(col => {
          col.data.splice(dataIndex, 1, this.selectedValues[i++]);
        });

        this.updateTableData(updatedTableData);
      }

    }
    finally {
      this.cdr.reattach();
      this.savingProperties = false;
    }
  }

  checkIfNeedRefresh(query: BaseQuery): boolean {
    let result = true;
    let queryJson = JSON.stringify(query)
    result = this.lastQueryJson != queryJson;
    this.lastQueryJson = queryJson;

    return result;
  }

  async reQuery(query: BaseQuery, queryData: QueryDataColumn[]) {
    try {
      let queryResult = await this.xprojClient.RequestQueryBaseQuery(query, true, 'projectiondataeditwidget', this.config.Name);
      //let queryResult = await this.xprojClient.RequestQueryBaseQuery(query, this.forcereload);

      this.forcereload = false;

      let numericaldata = queryResult.datanumbers;
      let timestampdata = queryResult.datatimestamps;
      let stringdata = queryResult.datastrings;

      //this.logger.info(queryResult);
      for (let i = 0; i < queryResult.columns.length; i++) {
        let it = queryResult.columns[i];
        let typ = it.datatype;
        let data = [];

        let colname = it.columnoutname;
        let columnConfig = this.columnConfigs.find(c => c.ColumnOutName == colname);
        let colData = queryData.find(d => d.columnname == colname);


        if (typ == XDataType.Number) {
          if (columnConfig?.Datatype == XDataType.UInt8 || columnConfig?.Datatype == XDataType.Int32 || columnConfig?.Datatype == XDataType.Int64) {
            data = numericaldata[it.indexintypedvector];
          }
          else {
            if (this.globalWidgetSettings.Decimals >= 0) {
              data = WidgetUtils.FormatNumbers(numericaldata[it.indexintypedvector], this.globalWidgetSettings.Decimals);
            }
            else {
              data = numericaldata[it.indexintypedvector];
            }
          }
        }
        if (typ == XDataType.String) {
          data = stringdata[it.indexintypedvector];
        }
        if (typ == XDataType.Timestamp) {
          data = timestampdata[it.indexintypedvector];
        }

        if (colData) {
          colData.datatype = it.datatype;
          colData.data = data;
          colData.writeable = columnConfig?.Writeable;
          colData.hidden = columnConfig?.Hidden;
        }
        else {
          colData = new QueryDataColumn();
          colData.columnname = colname;
          colData.columnnameSetData = it.columnname;
          colData.label = colname;
          colData.data = data;
          colData.hidden = columnConfig?.Hidden;
          colData.writeable = columnConfig?.Writeable;
          colData.primaryKey = columnConfig?.PrimaryKey;
          colData.projectionid = this.widgetConfig.ConfigQuery.ProjectionId;
          colData.datatype = it.datatype;
          colData.id = columnConfig?.Id;
          colData.editMode = columnConfig.EditMode;
          colData.enumMembers = columnConfig.EnumMembers;
          colData.useFixedValue = columnConfig.UseFixedValue;
          colData.fixedValueInputParameterId = columnConfig.FixedValueInputParameterId;
          colData.fixedValue = columnConfig.FixedValue;
          colData.isOnDeleteColumn = this.widgetConfig.SetColumnValueOnDelete && columnConfig.ColumnName == this.widgetConfig.OnDeleteColumnName;

          queryData.push(colData);
        }
      }

      this.queryNrRows = queryResult.nrpoints;
      this.queryNrTotalRows = queryResult.nroriginalpoints;
    }
    catch (err) {
      queryData.length = 0;
    }

    if (queryData.length == 0) {
      this.columnConfigs.forEach(columnConfig => {
        let colData = new QueryDataColumn();
        colData.columnname = columnConfig.ColumnOutName;
        colData.columnnameSetData = columnConfig.ColumnName;
        colData.label = columnConfig.ColumnOutName;
        colData.data = [];
        colData.hidden = columnConfig?.Hidden;
        colData.writeable = columnConfig?.Writeable;
        colData.primaryKey = columnConfig?.PrimaryKey;
        colData.projectionid = this.widgetConfig.ConfigQuery.ProjectionId;
        colData.datatype = columnConfig.Datatype;
        colData.id = columnConfig?.Id;
        colData.editMode = columnConfig.EditMode;
        colData.enumMembers = columnConfig.EnumMembers;
        colData.useFixedValue = columnConfig.UseFixedValue;
        colData.fixedValueInputParameterId = columnConfig.FixedValueInputParameterId;
        colData.fixedValue = columnConfig.FixedValue;
        colData.isOnDeleteColumn = this.widgetConfig.SetColumnValueOnDelete && columnConfig.ColumnName == this.widgetConfig.OnDeleteColumnName;

        queryData.push(colData);
      });
    }
  }

  updateTableData(updatedTableData: TableData) {
    if (this.tableData.columns.length != updatedTableData.columns.length) {
      this.tableData = updatedTableData;

      this.loadingQuery = false;
      this.tableData.initilized = this.tableData.columns.length > 0;
      this.tableData.data_col0 = this.tableData.columns.length > 0 ? this.tableData.columns[0].data : [];
      this.tableData.data_col_deleted = undefined;
      this.tableData.columns.forEach(col => {
        if (this.widgetConfig.SetColumnValueOnDelete && col.isOnDeleteColumn) {
          this.tableData.data_col_deleted = col.data;
        }
      });
    }
    else {
      this.tableData.data_col0 = [];

      setTimeout(() => {
        let i = 0;
        this.tableData.data_col_deleted = undefined;
        this.tableData.columns.forEach(col => {
          col.data = updatedTableData.columns[i].data;
          if (this.widgetConfig.SetColumnValueOnDelete && col.isOnDeleteColumn) {
            this.tableData.data_col_deleted = col.data;
          }
          i++;
        });

        this.loadingQuery = false;
        this.tableData.initilized = this.tableData.columns.length > 0;
        this.tableData.data_col0 = this.tableData.columns.length > 0 ? this.tableData.columns[0].data : [];
      });

    }
  }

  stepFirst() {
    if (this.currentPage != 1) {
      this.currentPage = 1;
      this.load();
    }
  }

  stepPrevious() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.load();
    }
  }

  stepNext() {
    this.currentPage++;
    this.load();
  }

  updateCurrentPage($event) {
    const parsed = parseInt($event.target.value, 10);

    if (!isNaN(parsed)) {
      if (parsed < 1) {
        this.currentPage = 1;
      }
      else {
        this.currentPage = parsed;
      }
    }

    this.currentPageInputRef.nativeElement.value = this.currentPage;
    this.load();
  }


  loadTimer: any;
  async load(state?: ClrDatagridStateInterface, showLoading: boolean = true) {
    if (!this.loadTimer) {
      this.loadTimer = setTimeout(async () => {
        this.loadTimer = undefined;
        await this._load(state, showLoading);
      }, 200);
    }
  }

  async _load(state?: ClrDatagridStateInterface, showLoading: boolean = true) {
    if (!this.loadingQuery) {
      if (!this.widgetheight) {
        this.setWidgetHeight(this.widgetConfig.Height);
      }
      if (!this.inputParametersHasValue(true)) {
        this.tableData.initilized = false;
        this.tableData.oldInitilized = false;
      }
      else if (this.tableData.oldInitilized != this.tableData.initilized) {
        this.tableData.oldInitilized = this.tableData.initilized;
      }
      else {
        let updatedTableData: TableData = MsgPackCloneObject(this.tableData) as TableData;
        let newData: boolean = false;
        try {
          this.loadingQuery = true;
          this.loading = showLoading;

          if (state) {
            this.state = state;
          }

          let sortingDefined: boolean = false;

          let query = this.getQuery(this.widgetConfig.ConfigQuery);

          //Projection input parameters
          if (this.widgetConfig.ConfigQuery.UseProjectionInputParameter) {
            query.targetprojectionid = this.getParameterValue(this.widgetConfig.ConfigQuery.ProjectionInputParameterId, this.widgetConfig.ConfigQuery.Query.targetprojectionid).value;
          }

          //Group input parameters
          if (this.groupFilterEnabled) {
            query.targetgroup = this.selectedGroup;
          }
          else {
            if (this.widgetConfig.ConfigQuery.GroupSelectionType == GroupSelectionTypes.GROUP_INPUT) {
              let fixedgroup: [] = this.getParameterValue(this.widgetConfig.ConfigQuery.FixedGroupInputParameterId, []).value;
              query.targetgroup = fixedgroup.concat(this.getParameterValue(this.widgetConfig.ConfigQuery.GroupInputParameterId, this.widgetConfig.ConfigQuery.Query.targetgroup).value);
            }
            else if (this.widgetConfig.ConfigQuery.GroupSelectionType == GroupSelectionTypes.GROUP_INPUT_PARAMETERS) {
              query.targetgroup = [];

              this.widgetConfig.ConfigQuery.FixedGroupInputParameterIds.forEach(id => {
                query.targetgroup.push(this.getParameterValue(id, '').value + '');
              });

              this.widgetConfig.ConfigQuery.GroupInputParameterIds.forEach(id => {
                query.targetgroup.push(this.getParameterValue(id, '').value + '');
              });
            }
            this.logger.debug('query.targetgroup', query.targetgroup);
          }

          this.queryTargetgroup = query.targetgroup;

          await this.checkColumns(this.widgetConfig, query);

          WidgetUtils.SetQueryFilterValues(query, this.widgetConfig.ConfigQuery.DataFilters, (i, d) => this.getParameterValue(i, d));
          WidgetUtils.AddQueryTimeframe(query,
            this.fromZoom ?? this.from,
            this.toZoom ?? this.to,
            this.widgetConfig.ConfigQuery.timestampColumnName,
            this.useRelativeTimestamp ? this.relativeTimestamp : null);

          if (this.widgetConfig.SetColumnValueOnDelete && this.widgetConfig.OnDeleteColumnName.length > 0) {
            query.subfiltergroups.push(query.filter);

            let filter = new FilterLogicalGroup();
            filter.type = FilterLogicalGroupType.AND;
            filter.queryfilterid = 88888;
            filter.filters.push(query.filter.queryfilterid);

            let filterDelete = new ColumnFilteringNumerical();
            filterDelete.queryfilterid = 888880;
            filterDelete.columnname = this.widgetConfig.OnDeleteColumnName;
            filterDelete.comparator = this.showDeleted ? FilterComparator.GreatherThanOrEquals : FilterComparator.Equals;
            filterDelete.value = 0;

            filter.filters.push(filterDelete.queryfilterid);
            query.numericalfilters.push(filterDelete);

            query.filter = filter;
          }

          if (this.state) {
            let filterId = 20000;
            if (this.state.filters) {
              for (let filter of this.state.filters) {
                if (filter instanceof XprojDatagridstringfilterComponent) {
                  let dynfilter = filter as XprojDatagridstringfilterComponent;
                  let col = dynfilter.columnname;
                  let strings = dynfilter.SelectedStrings;
                  let gr = new FilterLogicalGroup();
                  gr.type = FilterLogicalGroupType.OR;
                  gr.queryfilterid = filterId++;
                  for (let str of strings) {
                    let newFilter = new ColumnFilteringString();
                    newFilter.queryfilterid = filterId++;
                    newFilter.columnname = col;
                    newFilter.comparator = FilterComparator.Equals;
                    newFilter.value = str;
                    gr.filters.push(newFilter.queryfilterid);
                    query.stringfilters.push(newFilter);
                  }
                  query.filter.filters.push(gr.queryfilterid);
                  query.subfiltergroups.push(gr);
                  continue;
                }

                if (filter instanceof XprojDatagridNumericFilterComponent) {
                  let dynfilter = filter as XprojDatagridNumericFilterComponent;
                  let col = dynfilter.columnname;
                  let exact = dynfilter.exact;
                  let low = dynfilter.low;
                  let high = dynfilter.high;
                  if (exact != null) {
                    let newFilter = new ColumnFilteringNumerical();
                    newFilter.columnname = col;
                    newFilter.comparator = FilterComparator.Equals;
                    newFilter.queryfilterid = ++filterId;
                    newFilter.value = exact;
                    query.filter.filters.push(newFilter.queryfilterid);
                    query.numericalfilters.push(newFilter);
                    continue;
                  }
                  else if (low != null || high != null) {
                    let gr = new FilterLogicalGroup();
                    gr.type = FilterLogicalGroupType.AND;
                    gr.queryfilterid = filterId++;
                    if (low != null) {
                      let newFilter = new ColumnFilteringNumerical();
                      newFilter.queryfilterid = filterId++;
                      newFilter.columnname = col;
                      newFilter.comparator = FilterComparator.GreatherThanOrEquals;
                      newFilter.value = low;
                      gr.filters.push(newFilter.queryfilterid);
                      query.numericalfilters.push(newFilter);
                    }
                    if (high != null) {
                      let newFilter = new ColumnFilteringNumerical();
                      newFilter.queryfilterid = filterId++;
                      newFilter.columnname = col;
                      newFilter.comparator = FilterComparator.LessThanOrEquals;
                      newFilter.value = high;
                      gr.filters.push(newFilter.queryfilterid);
                      query.numericalfilters.push(newFilter);
                    }

                    query.filter.filters.push(gr.queryfilterid);
                    query.subfiltergroups.push(gr);
                    continue;
                  }
                }

                let columname = filter.property;
                let value = filter.value;

                for (let col of query.columns) {
                  if (col.columnoutname != columname)
                    continue;

                  let scol = updatedTableData.columns.find(c => c.columnname == col.columnname);

                  if (scol) {
                    switch (scol.datatype) {
                      case XDataType.Number:
                      case XDataType.Float32:
                      case XDataType.Float64:
                      case XDataType.UInt8:
                      case XDataType.Int32:
                      case XDataType.Int64:
                        {
                          let newFilter = new ColumnFilteringNumerical();
                          newFilter.columnname = scol.columnname;
                          newFilter.comparator = FilterComparator.Equals;
                          newFilter.queryfilterid = ++filterId;
                          newFilter.value = parseFloat(value);
                          query.filter.filters.push(newFilter.queryfilterid);
                          query.numericalfilters.push(newFilter);
                          break;
                        }
                      case XDataType.Timestamp:
                        {
                          let newFilter = new ColumnFilteringTimestamp();
                          newFilter.columnname = scol.columnname;
                          newFilter.comparator = FilterComparator.Equals;
                          newFilter.queryfilterid = ++filterId;
                          newFilter.value = new Date(value);
                          query.filter.filters.push(newFilter.queryfilterid);
                          query.timestampfilters.push(newFilter);
                          break;
                        }
                      case XDataType.String:
                        {
                          let newFilter = new ColumnFilteringString();
                          newFilter.columnname = scol.columnname;
                          newFilter.comparator = FilterComparator.Equals;
                          newFilter.queryfilterid = ++filterId;
                          newFilter.value = value;
                          query.filter.filters.push(newFilter.queryfilterid);
                          query.stringfilters.push(newFilter);
                          break;
                        }
                    }
                  }
                }
              }
            }
            if (this.state.sort) {
              let scol = query.columns.find(c => c.columnoutname == this.state.sort.by);

              if (scol) {
                sortingDefined = true;
                query.sorting.columnname = scol.columnname;
                query.sorting.descending = this.state.sort.reverse;
              }
            }

            this.pageSize = this.state.page.size;
            query.seekoffset = this.state.page.size * (this.currentPage - 1);
            query.maxitems = this.state.page.size;
          }
          else {
            query.seekoffset = this.pageSize * (this.currentPage - 1);
            query.maxitems = this.pageSize;
          }

          if (!sortingDefined) {
            let scol = query.columns.find(c => c.columnname == this.widgetConfig.ConfigQuery.defaultSortColumnName);
            if (scol) {
              if (this.widgetConfig.ConfigQuery.UseGrouping) {
                query.sorting.columnname = scol.columnoutname;
              }
              else {
                query.sorting.columnname = scol.columnname;
              }
              query.sorting.descending = this.widgetConfig.ConfigQuery.defaultSortDescending;
            }
          }

          if (this.state || this.forcereload || this.checkIfNeedRefresh(query)) {
            newData = true;
            await this.reQuery(query, updatedTableData.columns);
          }

          this.lastQuery = query;
        }
        finally {
          this.cdr.detach();

          this.updateTableData(updatedTableData);

          // this.loadingQuery = false;
          // this.tableData.initilized = this.tableData.columns.length > 0;
          // this.tableData.data_col0 = this.tableData.columns.length > 0 ? this.tableData.columns[0].data : [];

          //To prevent changedetection errors on init.
          setTimeout(() => this.loading = false);
          this.cdr.reattach();
        }
      }
    }

  }

  getQuery(queryConfig: ProjectionDataEditorWidgetQuery): BaseQuery {
    let query: BaseQuery = new BaseQuery();

    query.targetprojectionid = queryConfig.ProjectionId;
    query.maxitems = 20;
    query.targetgroup = queryConfig.Group;

    let filterId = 0;
    queryConfig.DataFilters.forEach(datafilter => {
      let columnFiltering = WidgetUtils.GetColumnFiltering(datafilter);

      if (columnFiltering) {
        columnFiltering.queryfilterid = ++filterId;
        query.filter.filters.push(columnFiltering.queryfilterid);

        if (columnFiltering instanceof ColumnFilteringNumerical) {
          query.numericalfilters.push(columnFiltering);
        }
        else if (columnFiltering instanceof ColumnFilteringString) {
          query.stringfilters.push(columnFiltering);
        }
        else if (columnFiltering instanceof ColumnFilteringTimestamp) {
          query.timestampfilters.push(columnFiltering);
        }
        else if (columnFiltering instanceof ColumnFilteringRelativeTimestamp) {
          query.relativetimestampfilters.push(columnFiltering);
        }
      }
    });

    return query;

  }

}
