<div class="content-area" *ngIf="selectedBmsCustomer">
  <!-- <clr-combobox [(ngModel)]="selectedDatasource" name="datasourceselect" (ngModelChange)="selectedDatasourceChanged($event)">
    <clr-options>
      <clr-option *clrOptionItems="let datasource of datasources; field:'name'" [clrValue]="datasource">
        {{datasource.name}}
      </clr-option>
    </clr-options>
  </clr-combobox> -->

  <clr-select-container>
    <label>Datasource</label>
    <select clrSelect name="datasourceselect" [(ngModel)]="selectedDatasource"
      (ngModelChange)="selectedDatasourceChanged($event)">
      <option *ngFor="let datasource of datasources" [ngValue]="datasource">{{datasource.name}}</option>
    </select>
  </clr-select-container>

  <div class="datasource-area" *ngIf="selectedDatasource">
    <as-split direction="horizontal" [unit]="'pixel'" (dragEnd)="onSplitDragEnd($event)" class="main-area"
      [gutterSize]="7">
      <as-split-area [order]="1">
        <app-configuration-datasource #datasourceInstance [dataSourceInstance]="selectedDatasource" [lazy]="false"
          [treeMaxHops]="10" [sortByName]="true" [sortPaddingZeros]="10" [highlightSelected]="true"
          [customerId]="this.selectedBmsCustomer.customerId" (onTreeNodeSelect)="onTreeNodeSelect($event)"
          [moveEnabled]="true" [expandRoot]="true" [eventLogdEnabled]="true">
        </app-configuration-datasource>
      </as-split-area>
      <as-split-area [(size)]="rightPanelWidth" [order]="2">
        <!-- <app-bms-data-collector-view #bmsDataCollectorView>
        </app-bms-data-collector-view> -->
      </as-split-area>
    </as-split>
  </div>
</div>
