import { Component, OnInit } from '@angular/core';
import { StateService } from '@xprojectorcore/services/state-service';
import { User, UserDevice } from '@xprojectorcore/xprojector_backend/proto/xprojector.grpc.models.pb';
import { XProjectorSysAdminClient } from '@xprojectorcore/xprojector_backend/xprojector-sysadmin-client';
import { NGXLogger } from 'ngx-logger';
import { XprojAlertService, XprojModalService } from 'xproj-lib';

@Component({
  selector: 'app-sys-admin-user-profile',
  templateUrl: './sys-admin-user-profile.component.html',
  styleUrls: ['./sys-admin-user-profile.component.scss']
})
export class SysAdminUserProfileComponent implements OnInit {

  oldPassword : string = '';
  newPassword : string = '';
  user : User;
  userDevices : UserDevice[] = [];
  selectedUserDevice : UserDevice;
  showUserDevice : boolean = false;


  constructor(
    public state: StateService,
    private sysAdminClient: XProjectorSysAdminClient,
    private alertService : XprojAlertService,
    private modalService: XprojModalService,
    private logger: NGXLogger
  ) { }

  async ngOnInit() {
    this.user = await this.sysAdminClient.getActiveUser();
    this.userDevices = await this.sysAdminClient.getUserDevices();
  }

  async saveCustomerUser() {
    let result = await this.sysAdminClient.modifyUser(this.user);
    if (result.result) {
      this.alertService.success('User modified.');
    } else {
      this.alertService.error(result.message);
    }
  }

  async setPassword() {
    try {
      let result = await this.sysAdminClient.setPassword(this.oldPassword, this.newPassword);
      if (result.result) {
        this.alertService.success('Password updated.');
      } else {
        this.alertService.error(result.message);
      }
    } catch (err) {
      this.logger.error('err', err);
      this.alertService.error(err);
    }
  }


  async addCustomerUserDevice() {
    let addUserDeviceResult = await this.modalService.ShowInputModalAsync({
      header: 'Add device',
      description: 'Input device name:',
      value: '',
      ok: 'Add device',
      cancel: 'Cancel'
    });
    if (addUserDeviceResult.result) {
      let result = await this.sysAdminClient.addUserDevice(addUserDeviceResult.value);
      if (result) {
        this.userDevices.push(result);
      }
    }
  }

  async deleteCustomerUserDevice() {
    if (this.selectedUserDevice) {
      let doDelete = await this.modalService.ShowConfirmModalAsync({
        header: 'Delete device',
        description: 'Delete selected device, are you sure?',
        ok: 'Delete',
        cancel: 'Cancel'
      });

      if (doDelete) {
        let result = await this.sysAdminClient.deleteUserDevice(this.selectedUserDevice.id);
        if (result.result) {
          this.userDevices = this.userDevices.filter(userDevice => userDevice.id != this.selectedUserDevice.id);
          this.selectedUserDevice = null;
        }
      }
    }
  }

  async viewCustomerUserDevice() {
    if (this.selectedUserDevice) {
      this.showUserDevice = true;
    }
  }

}
