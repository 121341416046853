import { Component, inject, OnInit } from '@angular/core';
import { RossakerBmsDataCollectorViewData } from '@core/models/rossaker-bms-data-collector-view-data';
import { RossakerBmsDataCollectorComponent } from '@features/rossaker-bms/rossaker-bms-admin/rossaker-bms-data-collector-view.component';
import { RossakerBmsLorawanMultiMeter } from '@core/models/rossaker-bms-lorawan-multimeter';
import { RossakerBmsAdminService } from '@app/core/services/rossaker-bms-admin-service';
import { RossakerLorawanDeviceInfo } from '@app/core/models/rossaker-lorawan-device-info';
import { DateHelper, XprojAlertService, XprojModalService } from 'xproj-lib';
import { RossakerXProjectorBmsLoRaWANClient } from '@app/core/xprojector_backend/rossaker-xprojector-bms-lorawan-client';
import { ConfiguredDownlinkItem, ConfiguredDownlinkSequence, DownlinkItem } from '@app/core/xprojector_backend/proto/xprojector.modulerossakerbms.lorawan.pb';

@Component({
  selector: 'app-rossaker-bms-lorawan-multi-meter-data-collector-view',
  templateUrl: './rossaker-bms-lorawan-multi-meter-data-collector-view.component.html',
  styleUrls: ['./rossaker-bms-lorawan-multi-meter-data-collector-view.component.scss']
})
export class RossakerBmsLorawanMultiMeterDataCollectorViewComponent implements OnInit {

  public static NodeTypeId : string = '_x_lorawan_multimeter';

  private rossakerAdminService : RossakerBmsAdminService = inject(RossakerBmsAdminService);
  public dateHelper: DateHelper = inject(DateHelper);
  private modalService: XprojModalService = inject(XprojModalService);
  private loRaWANClient: RossakerXProjectorBmsLoRaWANClient = inject(RossakerXProjectorBmsLoRaWANClient);
  private alertService: XprojAlertService = inject(XprojAlertService);

  data: RossakerBmsDataCollectorViewData;
  visible: boolean;

  lorawanMultiMeter : RossakerBmsLorawanMultiMeter;
  lorawanDeviceInfo : RossakerLorawanDeviceInfo;

  deviceInfoActive : boolean = true;

  _downlinkActive: boolean = false;
  get downlinkActive(): boolean {
    return this._downlinkActive;
  }
  set downlinkActive(value: boolean) {
    if (value) {
      this.updateConfiguredDownlinkItems();
      this.updateConfiguredDownlinkSequences();
      this.updateDownLinkItems();
    }
    this._downlinkActive = value;
  }

  configuredDownlinkItems : ConfiguredDownlinkItem[] = [];
  selectedConfiguredDownlinkItem : ConfiguredDownlinkItem = null;
  configuredDownlinkItemsDeviceType : string = '';

  downlinkItems : DownlinkItem[];
  loadingDownlinkitems : boolean = true;

  configuredDownlinkSequences : ConfiguredDownlinkSequence[] = [];
  selectedConfiguredDownlinkSequence : ConfiguredDownlinkSequence = null;
  configuredDownlinkSequencesDeviceType : string = '';

  downlinkOverrideTimeout : number = -1;
  downlinkSendEmail : boolean = false;

  operatorId : string = '';


  constructor(

  ) { }

  ngOnInit(): void {
  }


  async initDataCollectorView() {
    this.lorawanMultiMeter = this.rossakerAdminService.getLorawanMultiMeter(this.data.node);
    let lorawanDeviceInfos = await this.rossakerAdminService.getLoraWANDeviceInfos([this.lorawanMultiMeter?.devEui]);
    if (lorawanDeviceInfos?.length > 0) {
      this.lorawanDeviceInfo = lorawanDeviceInfos[0];
    }
  }

  setWidth(width: number) {

  }

  async updateConfiguredDownlinkItems() {
    if (this.configuredDownlinkItems.length == 0 || this.configuredDownlinkItemsDeviceType != this.lorawanMultiMeter.deviceType) {
      this.configuredDownlinkItems = await this.loRaWANClient.getConfiguredDownlinkItems(this.lorawanMultiMeter.deviceType);
      this.configuredDownlinkItemsDeviceType = this.lorawanMultiMeter.deviceType;
    }
  }

  async updateConfiguredDownlinkSequences() {
    if (this.configuredDownlinkSequences.length == 0 || this.configuredDownlinkSequencesDeviceType != this.lorawanMultiMeter.deviceType) {
      this.configuredDownlinkSequences = await this.loRaWANClient.getConfiguredDownlinkSequences(this.lorawanMultiMeter.deviceType);
      this.configuredDownlinkSequencesDeviceType = this.lorawanMultiMeter.deviceType;
    }
  }

  async updateDownLinkItems(force : boolean = false) {
    if (!this.downlinkItems || force) {
      try {
        this.loadingDownlinkitems = true;

        this.downlinkItems = await this.loRaWANClient.getDownlinkItems(this.data.bmsCustomer.customerId, this.data.node.id,
          this.data.node.nodeTypeId, this.operatorId);
      }
      finally {
        this.loadingDownlinkitems = false;
      }
    }
  }

  async clearDownlinkQueue() {
    let doClear = await this.modalService.ShowConfirmModalAsync({
      header: 'Clear downlink queue',
      description: 'Clear downlink queue, are you sure?',
      ok: 'Clear',
      cancel: 'Cancel'
    });

    if (doClear) {
      let result = await this.loRaWANClient.clearDownlinkQueue(this.data.bmsCustomer.customerId, this.data.node.id,
        this.data.node.nodeTypeId, this.operatorId);

        if (result.ok) {
          this.alertService.success('Downlink queue cleared.');
        }
        else {
          this.alertService.error('Error clear downlink queue.');
        }

        await this.updateDownLinkItems(true);
    }
  }

  async enqueueDownlinkItem() {
    if (this.selectedConfiguredDownlinkItem) {
      let doEnqueue = await this.modalService.ShowConfirmModalAsync({
        header: 'Enqueue downlink item',
        description: 'Enqueue downlink item to device, are you sure?',
        ok: 'Enqueue',
        cancel: 'Cancel'
      });

      if (doEnqueue) {
        let result = await this.loRaWANClient.enqueueDownlinkItem(this.data.bmsCustomer.customerId, this.data.node.id,
              this.data.node.nodeTypeId, this.operatorId, this.selectedConfiguredDownlinkItem);

        if (result.ok && result.deviceCount > 0) {
          this.alertService.success('Downlink item enqueued.');
        }
        else {
          this.alertService.error('Enqueue downlink item failure');
        }

        await this.updateDownLinkItems(true);
      }
    }
  }

  downlinkSequenceChanged() {
    if (this.selectedConfiguredDownlinkSequence) {
      this.downlinkOverrideTimeout = this.selectedConfiguredDownlinkSequence.timeout;
      this.downlinkSendEmail = false;
    }
  }

  async enqueueDownlinkSequence() {
    if (this.selectedConfiguredDownlinkSequence) {
      let doEnqueue = await this.modalService.ShowConfirmModalAsync({
        header: 'Enqueue downlink sequence',
        description: 'Enqueue downlink sequence to all devices, are you sure?',
        ok: 'Enqueue',
        cancel: 'Cancel'
      });

      if (doEnqueue) {
        let result = await this.loRaWANClient.enqueueDownlinkSequence(this.data.bmsCustomer.customerId, this.data.node.id,
              this.data.node.nodeTypeId, 'chirpstack', this.selectedConfiguredDownlinkSequence.id, null, this.downlinkOverrideTimeout, this.downlinkSendEmail);

        if (result.ok) {
          this.modalService.ShowConfirmModalAsync({
            header: 'Enqueue downlink sequence success',
            description: 'Downlink sequence enqueued to ' + result.deviceCount + ' devices.',
            showCancel: false
          });
        }
        else {
          this.modalService.ShowConfirmModalAsync({
            header: 'Enqueue downlink sequence failure',
            description: result.message,
            showCancel: false
          });

        }

      }
    }
  }
}
