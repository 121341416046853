<div class="background">
    <div class="card centered" (scroll)="onScroll($event)">
        <div i18n='@@gdprconsent_header' class="card-header">
            Consent.
        </div>
        <div class="card-block" (scroll)="onScroll($event)">
            <div class="card-text" (scroll)="onScroll($event)">
                <pre>
Robotrisa Cloud Platform Terms of Service (Platform ToS)

Robotrisa Cloud Platform Terms of Service (hereinafter”Agreement”) generally governs your access to and use of Robotrisa Cloud Platform Services. Provision and use of the Services will also need to comply with AUP.

If you are accepting on behalf of a company or other legal entity, you represent and warrant that (a) you have full legal authority to bind that entity to this Agreement, in which case “Customer ”, “you” or “your” will refer to that entity, otherwise such terms refer to you as an individual; (b) you have read, understand and agree to this Agreement.

1. Provision of the Platform Services
Provision of Platform Services
1.1 Rossaker
will make the Platform Services available to Customer and its End Users in accordance with the Agreement, an applicable Order;
is responsible for (i) the operation of the Robotrisa Cloud Environment, (ii) implementation of reasonable physical, technical and administrative measures to ensure the security of the Platform Services and Customer Content;
may provide Documentation and Support Services for Customer’s use of the Platform Services.
Updates to the Services
1.2 Rossaker may make commercially reasonable updates to the Platform Services from time to time, and Customer acknowledges and agrees that Platform Services may in the future be subject to changes reflected in the Agreement, Documentation and pricing.

Updates to the Agreement
1.3 Material changes of the Agreement will become effective 30 days after they are posted at this page except to the extent that changes apply to new functionality, DPA, AUP or required by law, in which case they will be effective immediately. Customer’s continued use of the Services will constitute Customer’s consent to such changes.

2. Billing, Invoicing and Payment
Customer’s Payment Obligations
2.1 Customer is responsible for paying all Fees (including all Fees for excess Platform Services usage and related Support Services invoiced monthly in arrears) specified in an invoice within 15 days after its receipt of each invoice.

2.2 Rossaker’s measurement of Customer’s use of the Services is final. Unless otherwise required or permitted by law, Customer’s obligation to pay all Fees is not cancellable.

Taxes
2.2
Customer is solely responsible for payment of any applicable sales, value added or use taxes, or similar government fees or taxes, and will pay Rossaker for the Services without any reduction for such taxes.
If Rossaker is obligated to collect or pay any taxes, the taxes will be invoiced to Customer and Customer will pay such taxes to Rossaker, unless Customer provides Rossaker with a timely and valid tax exemption certificate in respect of those taxes.
Rossaker may require Customer to provide any applicable tax identification information for Rossaker’s compliance with applicable tax regulations and authorities. In the case of misdeclaration by Customer, Customer will be liable to pay any taxes, interest, penalties or fines arising out of the misdeclaration.

Payment Disputes
2.3 Any payment disputes must be submitted in good faith before the payment due date.

Delinquent Payment
2.4 All past due payments, except to the extent reasonably disputed, will accrue interest at 1.5%  per month or the highest rate permitted by law, if the later is less, from the payment due date until paid in full. Customer will be responsible for all reasonable expenses incurred by Rossaker in collecting such delinquent amounts.

3. Customer Obligations
Compliance
3.1 Customer acknowledges and agrees that
Customer and its End Users’ use of the Services complies with the Agreement and AUP;
Customer uses commercially reasonable efforts to prevent and terminate any unauthorized use of or access to the Services, and promptly notify Rossaker of any unauthorized use of or access to the Services, Account, or Customer’s passwords of which Customer becomes aware;
Customer is responsible for any permissions, consents and notices required for Customer to use the Services, and Rossaker’s accessing, processing and storing of data provided by Customer;
DPA is incorporated by reference and shall apply to the processing of Personal Data as described in the DPA;
Customer will comply with all applicable industrial specific laws, export controls and trade sanctions laws.

Security Measure and Risk
3.2 Customer
ensures that End Users will have their own credentials, protecting those credentials and not permitting any sharing of credentials;
ensures that each End User reviews the portion of Documentation relevant to Customer use of the Platform Services, any security information communicated by Rossaker, and uses commercially reasonable efforts to secure Customer Content;
is responsible for the risks associated with the use of the Platform Services by an End User under an End User’s account, weather such action was taken by an End User or another party, and weather or not such action was authorized by an End User, provided that such action was not taken by Rossaker or by a party acting under the direction of Rossaker, or an action by a third party that Rossaker should reasonably have prevented;

Back up
3.3 Customer is responsible for backing up Customer Content.

Restrictions
3.4 Customer will not, and will not allow its End Users to
violate AUP;
(b) copy, modify, or create a derivative work of the Services;
(c) disassemble, decompile, reverse engineer, or attempt to view or extract the source code of the Platform Services, in whole or in part, or permit or authorize a third party to do so, except to the extent such activities are expressly permitted by law;
sell, resell, license, sublicense, distribute, rent, lease any or all of the Platform Services to any third party except to the extent explicitly authorized in writing by Rossaker;
use or access the Services in a manner intended to avoid in insurring Fees or to circumvent Service-specific usage limits or quota;
include any data for which Customer does not have all rights, power and authority necessary for its collection, use and processing as contemplated by the Agreement;
during any free trial period, including during the use of any Beta Services, use the Services for any purpose other than to evaluate whether to purchase the Services;
permit access or use the Services in any countries where such access or use is subject to a trade prohibition;
access or use the Services in support of any controlled technology industry, industry, goods or services or any other restricted use without having a valid governmental license.

4. Term, Suspension and Termination
Agreement Term
4.1 The Agreement will become effective on the Effective Date, and will continue in full force and effect until terminated as stated in this Section 4 (Term, Suspension and Termination of Platform Services).

Suspension
4.2 Rossaker may immediately Suspend all or part of Customer’s use of the Services if
there is suspected unauthorized access to the Services;
Rossaker reasonably believes Suspension is needed to protect the Service, Rossaker’s Infrastructure supporting the Service, any other customer of the Services;
Rossaker reasonably believes that immediate Suspension is required to comply with any applicable law;
Customer is in breach of Section 3.4 (Restrictions);
Customer fails to pay undisputed Fees.

4.3 Rossaker will lift any such Suspension when the circumstances giving rise to the Suspension have been resolved.

Termination
4.4 The Agreement may be terminated
in accordance with the effective duration of the Services in an Order(No Operative Order Outstanding)
by Rossaker if Customer is delinquent in the payment of undisputed Fees (Delinquent in the Payment);
by either party if the other party is in material breach of the Agreement and fails to cure that breach prior to the end of the notice period (Material Breach);
by either party immediately on written notice if the other party ceases its business operations or threatens to do so, or becomes subject to insolvency proceedings (Ceasing Operation, Insolvency);
by Rossaker immediately if Rossaker reasonably believes that continued provision of Services used by Customer would violate applicable laws or Customer has violated or caused Rossaker to violate any laws or regulations (Violation of Law);

Effect of Termination
4.5 If the Agreement is terminated, unless otherwise specified in an Order,
Rossaker will terminate Customer’s access to the Platform Services, Documentation and Customer Content;
Rossaker may automatically delete all Customer Content within 90 days following the termination of Customer’s access to the Platform Services;
all Fees owed by Customer to Rossaker are immediately due upon Customer’s receipt of the final invoice;
all provisions of the Agreement that by their nature should survive termination will so survive.

5. Support Services
By Rossaker
5.1 Rossaker may provide Customer Support Services during the Term specified in an Order in accordance with Support Services Guidelines. If Support Services is not specified in an Order, Rossaker Support Services will be limited to Documentation.

By Customer
5.2 For clarity, Customer is responsible for technical support of its own applications, services, and products built on Customer Content.


6. Intellectual Property, Usage Data, Feedback
Intellectual Property
6.1 Except as expressly provided in this Agreement, this Agreement does not grant either party any rights, implied or otherwise to other’s content or any of the other’s Intellectual Property. As between the parties, Rossaker retains all Intellectual Property Rights in the Services, Documentation, and Customer retains all Intellectual Property Rights in Customer Content.

Usage Data
6.2 Rossaker may collect and use Usage Data to improve its Services and Products. Rossaker will not disclose any Usage Data to any third party unless it is anonymized and aggregated such that it does not identify Customer or Customer Confidential Information and provision of such is to perform the Rossaker Services.

Feedback
6.3 At Customer’s option, Customer may provide feedback or suggestions about the Services to Rossaker (“Feedback”). If Customer provides Feedback, Rossaker may use that Feedback without restriction and without obligation to Customer. Rossaker acknowledges that any Feedback is provided on an “as-is” basis with no warranties of any kind.

7. Confidentiality
Confidential Information
7.1 Confidential Information refers to any technical and business information disclosed by either party to the other that is designated as confidential at the time of disclosure or would normally under the circumstances be considered as confidential. Confidential Information does not include information that is developed by the recipient, is rightfully given to the recipient by a third party without confidentiality obligations, or becomes public through no fault of the recipient. Subject to the foregoing, all non-public information of Rossaker Services is Rossaker’s Confidential Information, Customers Content is Customer’s Confidential Information. Any information that either party conveys to the other party concerning the use of Rossaker Services, including, without limitation, security measures, incidents or findings constitute Confidential Information of both parties.

Recipient’s Obligations
7.2 The recipient will only use the disclosing party’s Confidential Information to exercise the recipient's rights and fulfill its obligations under the Agreement, and will use reasonable care to protect against the disclosure of the disclosing party’s Confidential Information.
The recipient may disclose Confidential Information only to its Affiliates, employees, subcontractors, agents or professional advisors who need to know it for the performance or enforcement of the Agreement and who have agreed in writing to keep it confidential (use reasonable care equivalent to recipient’s) and use it only for such purpose.

Required disclosures:
7.3 When disclosure of Confidential Information is required by applicable laws or legal processes, the recipient and its Affiliates shall use reasonable efforts to promptly notify (if legally permitted to do so) the other party before any such disclosure to enable it  to oppose the disclosure.

8. Publicity
Customer acknowledges and agrees that Rossaker may use Customer’s name and logo for public identification as a customer, along with general description of any non-confidential matters Rossaker has handled for Customer and a general statement that Customer has selected Robotrisa as its data and computation platform.

9. Disclaimer:
Except as expressly provided for in an Order or the Agreement,
Rossaker does not make any warranty of accuracy, completeness, timeliness or uninterruptibility of the Services;
Rossaker disclaims all implied warranties, conditions and other terms, including, without limitation, implied warranties of merchantability, satisfactory quality or fitness for a particular purpose;
Rossaker is not responsible for results obtained from the use of the Services;
Any Services provided under any free trial period are provided “as-is” and without warranty of any kind.

10. Limitation of Liability
Limitation on indirect liability
10.1 To the extent permitted by applicable law and subject to Section 10.3 (Unlimited Liability), neither party will have any liability for:
Loss or corruption of data;
Loss arising from inaccurate or unexpected results arising from the use of the Services;
Lost revenue, profits, savings or goodwill; or
Indirect, consequential, special, incidental, or punitive damages.

Limitation on Amount of Liability
10.2 Each party’s total aggregate Liability for damages arising out of or relating to the Agreement is limited to the Fees Customer paid during the 6 month period prior to the event giving rise to Liability.

10.3 Rossaker’s Liability for damages is exempted if the Services are provided to Customer free of charge.

Unlimited liability
10.4 Nothing in the Agreement excludes or limits either party’s liability for:
its fraud or fraudulent misrepresentation;
its obligations under Section 11 (Indemnification);
its infringement of the other party’s Intellectual Property Rights;
its payment obligations under the Agreement; or
matters for which liability cannot be excluded or limited under applicable law.

11. Indemnification
Rossaker Indemnification Obligations
11.1 Rossaker will defend Customer against any legal proceeding brought against Customer by a third party alleging that the Services provided to Customer or Customer's use of the Services in accordance with the Agreement infringes such third party’s Intellectual Property Rights and will indemnify Customer against Indemnified Liability in a such legal proceeding.

Other Remedies
11.2 If Rossaker receives information regarding an infringement claim relating to the Services or reasonably believes that the Services might infringe a third party’s Intellectual Property Right, Rosasaker may then, at its sole option and expense (a) replace or modify the Services to make them non-infringing without materially reducing their functionality; (b) procure the right for Customer to continue using the Services; or (c) terminate Customer’s use of the impacted Services if Rossaker does not believe the remedies in (a), (b) are commercially reasonable.

Customer Indemnification Obligations
11.3 Customer will defend Rossaker against any legal proceeding brought against Rossaker by a third party arising from or related to (a) Customer Content or its use with the Services; (b) Customer’s or an End User’s use of the Services in breach of AUP or Section 3.4 (Restrictions) and will indemnify Rossaker against any Indemnified Liability in a such legal proceeding.

Exclusion
11.4 Rossaker Indemnification Obligations and Customer Indemnification Obligations will not apply to the extent that the underlying allegation arises from
the indemnified party’s breach of the Agreement;
a combination of the Service with equipment, devices, software or data not provided by Rossaker unless the combination is required by the Agreement or an Order;
in the case of Rossaker as the indemnifying party, any Services provided to Customer free of charge.

Conditions of Indemnification
11.5 Rossaker Indemnification Obligations and Customer Indemnification Obligations are conditioned on the following:
any indemnified party will promptly notify the indemnifying party of the allegation for which the indemnified party is seeking indemnification, and cooperate reasonably with the indemnifying party to resolve the allegation and legal proceeding. If breach of Section 11.5 (a) prejudices the defense of the legal proceeding, the indemnifying party’s obligation under Section 11.1 (Rossaker Indemnification Obligations) or 11.3 (Customer Indemnification Obligations) will be reduced in proportion to the prejudice;
grant the indemnifying party sole control of the indemnified portion of defense and settlement of the claim, subject to (i) the indemnified party has the right to appoint its own non-controlling counsel, at its own expense; and (ii) any settlement imposing obligations on or restricting the rights of the indemnified party will require the indemnified party’s prior written consent, which may not be unreasonably withheld or delayed.

Sole Rights and Obligations
11.6 This Section 11 (Indemnification) states the patties’ sole and exclusive remedy under the Agreement for any third party allegation of Intellectual Property Rights infringement.

12. Representation and Warranties
Each party represents and warrants that (a) it has full power and authority to enter into the Agreement; and (b) it will comply with all laws applicable to its provision, receipt, or use of the Services.

13. Miscellaneous
Governing Law and Dispute Resolution
13.1 All claims relating to this Agreement or the Services will be governed by the laws of Sweden and settled firstly by negotiations, then resolved by a Swedish general court.

Notices
13.2 Under the Agreement, notices to Customer shall be sent to Customer’s notification email address designated by Customer and notices to Rossaker shall be sent to legal&#64;rossaker.com. Such notices are deemed given on the date of sending.

Assignment
13.3 No assignment or transfer of a party’s rights and obligations under the Agreement is permitted except with the prior written approval of the other party.

Change of Control
13.4 If a party experiences a change of control, that party will give written notice to the other party within 30 days after the change of control.

Force Majeure
13.5 Neither party will be liable to the failure or delay in performance to the extent caused by circumstances beyond its reasonable control, including without limitation the following force majeure events: acts of God, natural disaster, terrorism, pandemics, riots, war, act of government, acts or omissions of third parties, failures of computer, telecommunications, Internet, Internet service provider or hosting facility failure or delaying of recovery from such failures.

No Third-Party Beneficiaries
13.6 The Agreement does not confer any benefits on any third party unless it expressly states that it does.

Amendments
13.7 Except as set out in Section 1.2 (Platform Services Updates), any amendment must be in writing, signed by both parties and expressly state that it is amending the Agreement.

Survival
13.8 The following Sections will survive expiration or termination of the Agreement: Section 2 (Billing, Invoicing and Payment), Section 6 (Intellectual Property, Usage Data, Feedback), Section 7 (Confidentiality), Section 4.5 (Effect of Termination), Section 9 (Disclaimer), Section 10 (Limitation of Liability), Section 11 (Indemnification) and Section 13 (Miscellaneous).

Entire Agreement
13.9 The Agreement sets out all terms agreed between the parties relating to its subject matter. In entering into the Agreement, neither party has relied on, and neither party will have any right or remedy based on any statement, warranty, representation or promise except those expressly stated in the Agreement. Each party acknowledges and agrees that it has adequate sophistication, including legal representation to fully understand the Agreement, thereby, no presumption will be given in favor of the non-drafting party in interpreting the Agreement with respect to any drafting ambiguities.

Conflicting Terms
13.10 To the extent any provision in an Order clearly conflicts with a provision of the Agreement or a provision of an earlier Order, the provision in the new Order shall apply.

Headings
13.11 Headings used in the Agreement are for reference purposes only and will not affect the interpretation of the Agreement.

13.12 Definitions
“AUP” means Acceptable Use Policy.
“Affiliate” means any entity that directly or indirectly controls, is controlled by, or is under common control with a party.
“Beta Services” means any services that are clearly designed as “beta”, “experimental” or similar, that is provided prior to general commercial release, and that Rossaker at its sole discretion offers to Customer and Customer at its sole discretion chooses to use.
“Cloud Environment” means a cloud or other compute or storage infrastructure according to context under the Agreement.
“Customer Content”  means all data processed by Rossaker on Customer behalf in the course of providing the Services.
“Documentation” means the Rossaker documentation made available to Customer for use with the Services during the term of the Agreement.
“DPA” means Data Processing Addendum.
“End Users” means the individuals who are permitted by Customer to use the Services, End Users may include employees of Customer or its Affiliates and other authorized third parties.
“Effective Date” means the earliest of: the effective date of an Order that references the Agreement or the date of last signature of the Agreement.
“Fees” means all amounts payable to Rossaker by Customer.
“Intellectual Property Rights” means current and future worldwide intellectual property rights including without limitation rights with respect to patents, copyrights, trade secrets, know-how, trademarks, moral rights and databases.
“Order” means an order form or similar agreement for the provision of Rossaker services, products, entered into by the parties or any of their Affiliates, incorporated by reference into, and governed by, the Agreement.
“Suspend” or “Suspension” means disabling or limiting access to or use of the Services or components of the Services.
“Support Services” means Rossaker support services provided to Customer under the Support, Advisory, Training Services Guidelines.
“Usage Data” means data collected by Rossaker relating to Customer’s use of the Service.

Last modified: 2024-08-19
Acceptable Use Policy (AUP)
Capitalised terms have the meaning stated in the applicable agreement(s) between Customer and Rossaker.

Customer agrees not to, and not to allow third parties to use the Services:
to access any other Rossaker services or products in a manner that violates the terms of such other Rossaker services or products;
attempt to interfere with, harm or disrupt the Services;
permit or gain access to the Services or connected to related Systems except as expressly authorised under applicable Agreement(s);
use any means to bypass usage limitations;
abuse or violate the security or integrity of any system of any party, such as by storing, transmitting or installing malicious code;
engage in or promote any fraudulent, corrupt, deceptive or similar objectionable activities;
cause harm to or violate the rights of any person, such as privacy rights; or
process, store or transmit data or content in violation of any law or any third party rights.

Rossaker may update the AUP from time to time upon reasonable notice.

Last modified: 2024-08-19


                </pre>
            </div>
        </div>
        <div class="card-footer">
            <button i18n='@@gdprconsent_deny' class="btn btn-secondary" (click)="deny()">Deny</button>
            <button i18n='@@gdprconsent_agree' class="btn btn-primary" [disabled]="!agreeEnabled" (click)="agree()">Agree</button>
        </div>
    </div>
</div>
