<input
  class="datagrid-numeric-filter-input exact-input"
  #input_exact
  type="number"
  autocomplete="off"
  name="exact"
  [(ngModel)]="exact"
  (change)="submit()"
  [placeholder]="exactPlaceholderValue"
  [attr.aria-label]="exactPlaceholderValue"
/>
<br>
<input
  class="datagrid-numeric-filter-input"
  #input_low
  type="number"
  autocomplete="off"
  name="low"
  [(ngModel)]="low"
  (change)="submit()"
  [placeholder]="minPlaceholderValue"
  [attr.aria-label]="minPlaceholderValue"
/>
<span class="datagrid-filter-input-spacer"></span>
<input
  class="datagrid-numeric-filter-input"
  #input_high
  type="number"
  autocomplete="off"
  name="high"
  [(ngModel)]="high"
  (change)="submit()"
  [placeholder]="maxPlaceholderValue"
  [attr.aria-label]="maxPlaceholderValue"
/>
<br>
<button class="btn btn-sm btn-outline" type="button" (click)="clear()">
  Clear
</button>
