import { Component } from '@angular/core';
import { XAUTO_IntegrationServerDirection, XAUTO_IntegrationServerMqtt, XAUTO_IntegrationServerMqttVariableMapping, XAUTO_IntegrationServerType, XAUTO_XAutoIntegrationServer, XProjectorClient } from '../../XProjector/xprojector-client-service';
import { ClrDatagridSortOrder, ClrLoadingState } from '@clr/angular';
import * as uuid from 'uuid';

export class ViewServer{
  public Server : XAUTO_XAutoIntegrationServer;
  public Mqtt : XAUTO_IntegrationServerMqtt;
  public Variables: Array<XAUTO_IntegrationServerMqttVariableMapping> = new Array<XAUTO_IntegrationServerMqttVariableMapping>();
}

@Component({
  selector: 'xproj-editintegrationservers-mqtt',
  templateUrl: './editintegrationservers-mqtt.component.html',
  styleUrls: ['./editintegrationservers-mqtt.component.scss']
})

export class EditintegrationserversMqttComponent {
  sizeOptions = [10, 20, 50, 100];
  ascSort = ClrDatagridSortOrder.ASC;  
 
  
  public selectedServer : ViewServer;
  public savingServer : ClrLoadingState = ClrLoadingState.DEFAULT;
  public loadingServers : ClrLoadingState = ClrLoadingState.DEFAULT;
  public savingRemoveServer : ClrLoadingState = ClrLoadingState.DEFAULT;
  

  public servers =[];
  

  async addServer()
  {    
    let newServer = new XAUTO_XAutoIntegrationServer();
    newServer.servertype = XAUTO_IntegrationServerType.MQTT;
    newServer.xautointegrationserverid = uuid.v4();
    await this.xprojClient.XAUTO_SaveIntegrationServer(newServer);
    let newServerMQTT = new XAUTO_IntegrationServerMqtt();
    newServerMQTT.xautointegrationserverid = newServer.xautointegrationserverid;
    await this.xprojClient.XAUTO_SaveIntegrationServerMQTT(newServerMQTT);

    this.servers.push(newServer);
  }
  
  saveServer()
  {    
  }

  removeDriver()
  {

  }

  constructor(private xprojClient: XProjectorClient)
  {
    
  }
}
