import { Component, OnInit } from '@angular/core';
import { RossakerStateService } from '@app/core/services/rossaker-state-service';
import { Customer, CustomerUser } from '@xprojectorcore/xprojector_backend/proto/xprojector.grpc.models.pb';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-rossaker-bms-users',
  templateUrl: './rossaker-bms-users.component.html',
  styleUrls: ['./rossaker-bms-users.component.scss']
})
export class RossakerBmsUsersComponent implements OnInit {

  ngUnsubscribe = new Subject<void>();

  selectedCustomer: Customer;

  constructor(private rossakerState: RossakerStateService) { }

  ngOnInit() {
    this.rossakerState.customer$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(async (e) => {
      this.selectedCustomer = e.customer;
    });
  }

  async usersCustomerUserSelected(customerUser: CustomerUser) {

  }
}
