<form clrForm>
  <clr-input-container>
    <label>Label</label>
    <input clrInput placeholder="" name="label" type="string" [(ngModel)]="toggleConfig.Label" />
  </clr-input-container>

  <clr-select-container>
    <label>Data type</label>
    <select clrSelect name="coltype" [(ngModel)]="toggleConfig.DataType">
      <option [ngValue]="OutputDataType.Float32">Float32</option>
      <option [ngValue]="OutputDataType.Float64">Float64</option>
      <option [ngValue]="OutputDataType.UInt8">UInt8</option>
      <option [ngValue]="OutputDataType.Int32">Int32</option>
      <option [ngValue]="OutputDataType.Int64">Int64</option>
      <!-- <option value="5">Number</option> -->
      <option [ngValue]="OutputDataType.Timestamp">Timestamp</option>
      <option [ngValue]="OutputDataType.String">String</option>
    </select>
  </clr-select-container>

  <clr-input-container>
    <label>Off value</label>
    <input *ngIf="toggleConfig.DataType < 6" clrInput type="number" name="ValueOffNumber"
      [(ngModel)]="toggleConfig.OffConfig.ValueNumber" />
    <input *ngIf="toggleConfig.DataType == 6" clrInput type="date" name="ValueOffDate"
      [(clrDate)]="toggleConfig.OffConfig.ValueDate">
    <input *ngIf="toggleConfig.DataType == 7" clrInput type="string" name="ValueOffString"
      [(ngModel)]="toggleConfig.OffConfig.ValueString" />
  </clr-input-container>

  <clr-input-container>
    <label>On value</label>
    <input *ngIf="toggleConfig.DataType < 6" clrInput type="number" name="ValueOnNumber"
      [(ngModel)]="toggleConfig.OnConfig.ValueNumber" />
    <input *ngIf="toggleConfig.DataType == 6" clrInput type="date" name="ValueOnDate"
      [(clrDate)]="toggleConfig.OnConfig.ValueDate">
    <input *ngIf="toggleConfig.DataType == 7" clrInput type="string" name="ValueOnString"
      [(ngModel)]="toggleConfig.OnConfig.ValueString" />
  </clr-input-container>

  <clr-toggle-container>
    <clr-toggle-wrapper>
      <input type="checkbox" clrToggle [(ngModel)]="toggleConfig.DefaultValue" name="defaultvalue" />
      <label>Default value</label>
    </clr-toggle-wrapper>
  </clr-toggle-container>

  <clr-checkbox-container>
    <clr-checkbox-wrapper>
      <input type="checkbox" clrCheckbox name="useprojectiondata" [(ngModel)]="toggleConfig.ProjectionData" />
      <label>Use projection data</label>
    </clr-checkbox-wrapper>
  </clr-checkbox-container>

  <!-- todo: get value from projection, set value via scripted action -->
  <xproj-output-combobox-controller-query-config #queryConfig *ngIf="toggleConfig.ProjectionData"
  [widgetQuery]="toggleConfig.ConfigQuery" [widgets]="widgets" [inputParameters]="inputParameters">
  </xproj-output-combobox-controller-query-config>

</form>
