<clr-modal [(clrModalOpen)]="showEdit">
  <h3 class="modal-title">Edit</h3>
  <div class="modal-body">
    <form clrForm>
      <clr-input-container>
        <label>Name:</label>
        <input clrInput required placeholder="name" name="name" type="text" [(ngModel)]="newname" />
      </clr-input-container>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="showEdit = false">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="showEdit = false; doModifyDashboard()">Ok</button>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="showMove">
  <h3 class="modal-title">Move dashboard to... '{{moveToItem?.name}}'</h3>
  <div class="modal-body">
    <clr-tree *ngIf="treeview">
      <clr-tree-node *clrRecursiveFor="let item of treeview.children; getChildren: getChildren" [clrExpanded]="true">
        <a class="clr-treenode-link" routerLinkActive="active" (click)="moveToItem = item;">
          {{item.name}}
        </a>
      </clr-tree-node>
    </clr-tree>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="showMove = false">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="showMove = false; doMoveDashboard()">Ok</button>
  </div>
</clr-modal>

<clr-tree *ngIf="treeview">
  <clr-tree-node *clrRecursiveFor="let item of treeview.children; getChildren: getChildren"
    [(clrExpanded)]="item.expanded">
    <clr-icon shape="dashboard"></clr-icon>
    <a [routerLink]="['./' + item.id]" class="clr-treenode-link" routerLinkActive="active" (click)="selectItem(item)"
      (contextmenu)="onRightClick($event, item)">
      <clr-dropdown [clrCloseMenuOnItemClick]="true" style="margin-right: 1em;">
        <!-- <button clrDropdownTrigger aria-label="Dropdown user button" (click)="onOpenEditMenu(item)">
          <clr-icon shape="pencil"></clr-icon>
        </button> -->
        <clr-dropdown-menu *clrIfOpen="item.openDropDown" clrPosition="bottom-left">
          <div aria-label="Dropdown header Action EditDashboard" clrDropdownItem (click)="editDashboard(item)">
            <clr-icon shape="pencil"></clr-icon> Edit dashboard
          </div>
          <!-- <div aria-label="Dropdown header Action EditDashboard" clrDropdownItem (click)="editDashboard(item, true)">
            <clr-icon shape="pencil"></clr-icon> Edit mobile dashboard
          </div> -->
          <div aria-label="Dropdown header Action AddDashboard" clrDropdownItem (click)="addDashboard(item)">
            <clr-icon shape="plus"></clr-icon> Add dashboard
          </div>
          <div aria-label="Dropdown header Action DeleteDashboard" clrDropdownItem
            (click)="deleteDashboard(item, true)">
            <clr-icon shape="trash"></clr-icon> Delete dashboard
          </div>
          <div aria-label="Dropdown header Action CopyDashboard" clrDropdownItem (click)="copyItem(item)">
            <clr-icon shape="copy"></clr-icon> Copy dashboard
          </div>
          <!-- <div aria-label="Dropdown header Action PasteDashboard" clrDropdownItem
            [disabled]="adminDashboardsService.clipboardTreeviewItem == null" (click)="pasteItem(item)">
            <clr-icon shape="paste"></clr-icon> Paste dashboard
          </div> -->
          <div aria-label="Dropdown header Action PasteDashboard" clrDropdownItem
            (click)="pasteItem(item)">
            <clr-icon shape="paste"></clr-icon> Paste dashboard
          </div>
          <div aria-label="Dropdown header Action MoveDashboard" clrDropdownItem (click)="moveItem(item)">
            <clr-icon shape="cursor-move"></clr-icon> Move dashboard
          </div>
          <div aria-label="Dropdown header Action DeleteDashboard" clrDropdownItem (click)="modifyDashboard(item)">
            <clr-icon shape="pencil"></clr-icon> Rename dashboard
          </div>

          <div class="dropdown-divider" role="separator" aria-hidden="true"></div>
          <!-- <div aria-label="Dropdown header Action DeleteDashboard" clrDropdownItem
            (click)="showDashboardSettings(item)">
            <clr-icon shape="cog"></clr-icon> Dashboard settings
          </div> -->
          <!--
          <clr-dropdown>
            <button clrDropdownTrigger>
              <clr-icon shape="grid-chart"></clr-icon>Widgets
            </button>
            <clr-dropdown-menu>
              <div *ngFor="let widgetType of currentWidgetTypes" clrDropdownItem (click)="addWidget(item, widgetType)">
                Add {{widgetType.name}}
              </div>
            </clr-dropdown-menu>
          </clr-dropdown>
          <div class="dropdown-divider" role="separator" aria-hidden="true"></div>-->

          <div aria-label="Dropdown header Action DeleteDashboard" clrDropdownItem (click)="moveUp(item)">
            <clr-icon shape="arrow"></clr-icon> Move up
          </div>
          <div aria-label="Dropdown header Action DeleteDashboard" clrDropdownItem (click)="moveDown(item)">
            <clr-icon shape="arrow" style="transform: rotate(180);"></clr-icon> Move down
          </div>
          <div class="dropdown-divider" role="separator" aria-hidden="true"></div>
          <div aria-label="Dropdown header Action EditDashboard" clrDropdownItem (click)="viewFullscreen(item)">
            <clr-icon shape="resize"></clr-icon> View fullscreen
          </div>

        </clr-dropdown-menu>
      </clr-dropdown>
      {{item.name}}
    </a>
  </clr-tree-node>
</clr-tree>


<!-- <clr-tree>
  <div *ngIf="currentUserRights.isCustomerAdmin" clrDroppable (clrDrop)="onDrop($event.dragDataTransfer)"
    [ngClass]="currentDraggedItem !== null && currentDraggedItem.parent ? 'not-visible' : ''"></div>
  <clr-tree-node *clrRecursiveFor="let item of treeview.children; getChildren: getChildren" [clrDraggable]="item"
    tabIndex="1" [id]="item.id" [(clrExpanded)]="item.expanded" (clrDragStart)="dragStart(item)"
    (clrDragEnd)="dragEnd()">
    <clr-icon shape="dashboard" *ngIf="!currentUserRights.isCustomerAdmin"></clr-icon>

    <clr-dropdown [clrCloseMenuOnItemClick]="true" style="margin-right: 1em;" *ngIf="currentUserRights.isCustomerAdmin">
      <button clrDropdownTrigger aria-label="Dropdown user button">
        <clr-icon shape="pencil"></clr-icon>
      </button>
      <clr-dropdown-menu *clrIfOpen clrPosition="bottom-left">
        <div aria-label="Dropdown header Action AddDashboard" clrDropdownItem (click)="addDashboard(item)">
          <clr-icon shape="plus"></clr-icon> Add dashboard
        </div>
        <div aria-label="Dropdown header Action DeleteDashboard" clrDropdownItem (click)="deleteDashboard(item)">
          <clr-icon shape="trash"></clr-icon> Delete dashboard
        </div>
        <div aria-label="Dropdown header Action DeleteDashboard" clrDropdownItem (click)="copyItem(item)">
          <clr-icon shape="copy"></clr-icon> Copy dashboard
        </div>
        <div aria-label="Dropdown header Action DeleteDashboard" clrDropdownItem [disabled]="adminDashboardsService.clipboardTreeviewItem == null" (click)="pasteItem(item)">
          <clr-icon shape="paste"></clr-icon> Paste dashboard
        </div>
        <div aria-label="Dropdown header Action DeleteDashboard" clrDropdownItem (click)="modifyDashboard(item)">
          <clr-icon shape="pencil"></clr-icon> Edit
        </div>

        <div class="dropdown-divider" role="separator" aria-hidden="true"></div>

        <div aria-label="Dropdown header Action DeleteDashboard" clrDropdownItem (click)="moveUp(item)">
          <clr-icon shape="arrow"></clr-icon> Move up
        </div>
        <div aria-label="Dropdown header Action DeleteDashboard" clrDropdownItem (click)="moveDown(item)">
          <clr-icon shape="arrow" style="transform: rotate(180deg);"></clr-icon> Move down
        </div>

      </clr-dropdown-menu>
    </clr-dropdown>
    <a [routerLink]="['./' + item.id]" class="clr-treenode-link" routerLinkActive="active">{{item.name}}</a>
    <div *ngIf="currentUserRights.isCustomerAdmin"  [id]="item.id" tabIndex="-1" [ngClass]="
        currentDraggedItem === null ||
        currentDraggedItem.id === item.id ||
        (currentDraggedItem.parent !== item.parent && currentDraggedItem.parent !== item)
          ? 'not-visible'
          : ''
      " clrDroppable (clrDrop)="onDrop($event.dragDataTransfer, item)"></div>
  </clr-tree-node>
</clr-tree> -->
