<div class="content-area">

    <div style="width:80%;max-width:1000px; margin-left: auto; margin-right: auto;">
        <h1>Välkommen</h1>

        <h2>Händelser</h2>
         <p>2022-11-25 Uppdatering användargränssnitt</p>
         <p>2022-10-25 Uppdatering användargränssnitt</p>         
    </div>

</div>