import { Aggregation, BaseQuery, BaseQueryInputColumnDescription, BaseQueryResult, ColumnFilteringNumerical, ColumnFilteringString, ColumnFilteringTimestamp, FilterComparator, FilterLogicalGroup, FilterLogicalGroupType, SetDataQuery, XProjectorClient } from '../XProjector/xprojector-client-service';
import * as fengari from 'fengari-web';
import { XprojLoggerService } from '../logger/xproj-logger-service';
import { ScriptedParameters } from '../models/scripted-parameters';
import { SetDataColumnDescription } from '../XProjector/xprojector-client-service';
import { XDataType } from '../XProjector/xprojector-client-service';
import { DashboardOutputChangeParameters } from '../models/dashboard-output-change-parameters';

export class XprojLuaDashboardWrapper {

  onUpdateAndUpsertDynamicParameters : (events: DashboardOutputChangeParameters[], source: ScriptedParameters) => void;

  public constructor(
    public xprojClient: XProjectorClient,
    private logger: XprojLoggerService) {

      this.RegisterInLua();
  }

  public async Lua_SetDataScalar(targetProjection: string, data: Map<string, any>): Promise<boolean> {
    let sd = new SetDataQuery();
    sd.projectionid = targetProjection;
    sd.columns = [];
    sd.datanumbers = [];
    sd.datastrings = [];
    sd.datatimestamps = [];

    for (let it of data) {
      let col = it[0];
      let val = it[1];
      let coldesc: SetDataColumnDescription = new SetDataColumnDescription();
      coldesc.columnname = col;
      coldesc.indexintypedvector = -1;
      if (typeof (val) == "string") {
        coldesc.datatype = XDataType.String;
        coldesc.indexintypedvector = sd.datastrings.length;
        coldesc.columnname = col;
        sd.datastrings.push([val as string]);
      }
      else if (typeof (val) == "number") {
        coldesc.datatype = XDataType.Number;
        coldesc.indexintypedvector = sd.datanumbers.length;
        coldesc.columnname = col;
        sd.datanumbers.push([val as number]);
      }
      else if (val instanceof Date) {
        coldesc.datatype = XDataType.Timestamp;
        coldesc.indexintypedvector = sd.datatimestamps.length;
        coldesc.columnname = col;
        sd.datatimestamps.push([val as Date]);
      }
      if (coldesc.indexintypedvector == -1) {
        console.log("Invalid type for " + col);
        return false;
      }
      sd.columns.push(coldesc);
    }

    this.setdata_success = await this.xprojClient.RequestSetData(sd);
    return this.setdata_success;
  }

  public static Lua_BaseQuery(targetProjection: string, tableGroups: any): BaseQuery {
    let Rval = new BaseQuery();
    Rval.targetprojectionid = targetProjection;
    if (tableGroups) {
      let targetGroup = [];
      for (let j of tableGroups) {
        targetGroup.push(j[1]); // [0] == index in lua table
      }
      Rval.targetgroup = targetGroup;
    }

    return Rval;
  }

  public static Lua_AddStringFilter( ColumnName: string, Value: string) {
    let query: BaseQuery = (this as unknown) as BaseQuery;
    let colFilter = new ColumnFilteringString();
    colFilter.columnname = ColumnName;
    colFilter.comparator = FilterComparator.Equals;
    // 1000 here is unique for string, add one specifici offset per type
    colFilter.queryfilterid = 10000 + query.numericalfilters?.length +
      query.stringfilters?.length +
      query.timestampfilters?.length +
      query.relativetimestampfilters?.length +
      query.subfiltergroups?.length;
    colFilter.value = Value;

    if(!query.filter)
    {
      query.filter = new FilterLogicalGroup();
      query.filter.filters.push(colFilter.queryfilterid);
      query.filter.type = FilterLogicalGroupType.AND
      query.filter.queryfilterid = 0;      
    }
    query.filter.filters.push(colFilter.queryfilterid);
    query.stringfilters.push(colFilter);
  }
  public static Lua_AddNumberFilter( ColumnName: string, Value: number) {    
    let query: BaseQuery = (this as unknown) as BaseQuery;
    let colFilter = new ColumnFilteringNumerical();
    colFilter.columnname = ColumnName;
    colFilter.comparator = FilterComparator.Equals;
    // 1000 here is unique for string, add one specifici offset per type
    colFilter.queryfilterid = 20000 + query.numericalfilters?.length +
      query.stringfilters?.length +
      query.timestampfilters?.length +
      query.relativetimestampfilters?.length +
      query.subfiltergroups?.length;
    colFilter.value = Value;

    if(!query.filter)
    {
      query.filter = new FilterLogicalGroup();
      query.filter.filters.push(colFilter.queryfilterid);
      query.filter.type = FilterLogicalGroupType.AND
      query.filter.queryfilterid = 0;      
    }
    query.filter.filters.push(colFilter.queryfilterid);
    query.numericalfilters.push(colFilter);
  }
  public static Lua_AddTimestampFilter( ColumnName: string, Value: Date) {
    let query: BaseQuery = (this as unknown) as BaseQuery;
    let colFilter = new ColumnFilteringTimestamp();
    colFilter.columnname = ColumnName;
    colFilter.comparator = FilterComparator.Equals;
    // 1000 here is unique for string, add one specifici offset per type
    colFilter.queryfilterid = 30000 + query.numericalfilters?.length +
      query.stringfilters?.length +
      query.timestampfilters?.length +
      query.relativetimestampfilters?.length +
      query.subfiltergroups?.length;
    colFilter.value = Value;

    if(!query.filter)
    {
      query.filter = new FilterLogicalGroup();
      query.filter.filters.push(colFilter.queryfilterid);
      query.filter.type = FilterLogicalGroupType.AND
      query.filter.queryfilterid = 0;      
    }
    query.filter.filters.push(colFilter.queryfilterid);
    query.timestampfilters.push(colFilter);
  }  

  public scalar_value_string: string;
  public scalar_value_timestamp: Date;
  public scalar_value_number: number;
  public setdata_success: boolean;

  public async Lua_GetScalarValueString(query: BaseQuery, columname: string): Promise<string> {
    query.maxitems = 1;
    query.columns.length = 0;
    let col = new BaseQueryInputColumnDescription();
    col.columnname = columname;
    col.columnoutname = columname;
    col.columnaggregation = Aggregation.NONE;
    query.columns.push(col);
    let data = await this.xprojClient.RequestQueryBaseQuery(query, false, 'dashboard', 'dashboard');

    if (data.datastrings.length > 0) {
      if (data.datastrings[0].length == 0) {
        this.scalar_value_string = "";
        return this.scalar_value_string;
      }

      this.scalar_value_string = data.datastrings[0][0];
      this.logger.info(this.scalar_value_string);
      return this.scalar_value_string;
    }

    this.scalar_value_string = "";
    return this.scalar_value_string;
  }

  public async Lua_GetScalarValueTimestamp(query: BaseQuery, columname: string): Promise<Date> {
    query.maxitems = 1;
    query.columns.length = 0;
    let col = new BaseQueryInputColumnDescription();
    col.columnname = columname;
    col.columnoutname = columname;
    col.columnaggregation = Aggregation.NONE;
    query.columns.push(col);
    let data = await this.xprojClient.RequestQueryBaseQuery(query, false, 'dashboard', 'dashboard');

    if (data.datatimestamps.length > 0) {
      if (data.datatimestamps[0].length == 0) {
        this.scalar_value_timestamp = null;
        return this.scalar_value_timestamp;
      }

      this.scalar_value_timestamp = data.datatimestamps[0][0];
      this.logger.info(this.scalar_value_timestamp);
      return this.scalar_value_timestamp;
    }

    this.scalar_value_timestamp = null;
    return this.scalar_value_timestamp;
  } 
  
  public async Lua_GetScalarValueNumber(query: BaseQuery, columname: string): Promise<number> {
    query.maxitems = 1;
    query.columns.length = 0;
    let col = new BaseQueryInputColumnDescription();
    col.columnname = columname;
    col.columnoutname = columname;
    col.columnaggregation = Aggregation.NONE;
    query.columns.push(col);
    let data = await this.xprojClient.RequestQueryBaseQuery(query, false, 'dashboard', 'dashboard');

    if (data.datanumbers.length > 0) {
      if (data.datanumbers[0].length == 0) {
        this.scalar_value_number = null;
        return this.scalar_value_number;
      }

      this.scalar_value_number = data.datanumbers[0][0];
      this.logger.info(this.scalar_value_number);
      return this.scalar_value_number;
    }

    this.scalar_value_number = null;
    return this.scalar_value_number;
  }  

  public static Lua_AddColumnToQuery(columnname_in : string, columnname_out: string)
  {
    let query = (this as unknown) as BaseQuery;
    let col = new BaseQueryInputColumnDescription();
    col.columnname = columnname_in;
    col.columnoutname = columnname_out;
    query.columns.push(col);
  }

  public static Lua_AddAggColumnToQuery(columnname_in : string, columnname_out: string, aggregation:number)
  {
    let query = (this as unknown) as BaseQuery;
    let col = new BaseQueryInputColumnDescription();
    col.columnname = columnname_in;
    col.columnoutname = columnname_out;
    col.columnaggregation = aggregation;
    query.columns.push(col);
  }

  public QueryResult : BaseQueryResult = null;
  public async Lua_QueryResult(query: BaseQuery, maxItems): Promise<BaseQueryResult> {
    query.maxitems = maxItems || 100;
    let data = await this.xprojClient.RequestQueryBaseQuery(query, false, 'dashboard', 'dashboard');
    this.QueryResult = data;
    return data;
  }    

  public DebugPromise(p: Promise<string>): void {
    debugger;
  }

  public static Lua_GetDataNowUTC(): Date {
    let now = new Date();
    return now;
  }

  public static Lua_GetDataMinUTC(): Date {
    let mindate = new Date(1970,1,1);
    return mindate;
  }

  public static Lua_JSONPARSE_UINT8Array() : Object
  {
    let data = (this as unknown) as Uint8Array;
    let json = new TextDecoder().decode(data);
    let rval= JSON.parse(json);
    console.log("Lua_JSONPARSE_UINT8Array", rval);
    return rval;
  }
  public static Lua_JSONPARSE_string() : Object
  {
    let data = (this as unknown) as string;
    return JSON.parse(data);
  }

  public static Lua_BaseQueryResult_GetNrRows() : number
  {    
    let data : BaseQueryResult = (this as unknown) as BaseQueryResult;
    return data.nrpoints;
  }
  public static Lua_BaseQueryResult_GetNrColumns() : number
  {
    let data : BaseQueryResult = (this as unknown) as BaseQueryResult;
    return data.columns.length;
  }
  public static Lua_BaseQueryResult_GetColumnName(index  : number) : string
  {
    let data : BaseQueryResult = (this as unknown) as BaseQueryResult;
    return data.columns[0].columnname;
  }
  public static Lua_BaseQueryResult_GetString( rowindex: number, columnname: string) : string
  {
    let data : BaseQueryResult = (this as unknown) as BaseQueryResult;
    let index = -1;
    for(let i of data.columns)
    {
      if(i.columnoutname == columnname)
        index = i.indexintypedvector;
    }
    if(index == -1)
      throw Error("Column not found in getstring");

      return data.datastrings[index][rowindex];
  }
  public static Lua_BaseQueryResult_GetTimestamp( rowindex: number, columnname: string) : Date
  {
    let data : BaseQueryResult = (this as unknown) as BaseQueryResult;
    let index = -1;
    for(let i of data.columns)
    {
      if(i.columnoutname == columnname)
        index = i.indexintypedvector;
    }
    if(index == -1)
      throw Error("Column not found in gettimestamp");

      return data.datatimestamps[index][rowindex];
  }
  public static Lua_BaseQueryResult_GetNumber( rowindex: number, columnname: string) : number
  {
    let data : BaseQueryResult = (this as unknown) as BaseQueryResult;
    let index = -1;
    for(let i of data.columns)
    {
      if(i.columnoutname == columnname)
        index = i.indexintypedvector;
    }
    if(index == -1)
      throw Error("Column not found in getnumber");

      return data.datanumbers[index][rowindex];
  }

  async updateAndUpsertDynamicParameters(events: DashboardOutputChangeParameters[], source: ScriptedParameters) {
    if (this.onUpdateAndUpsertDynamicParameters) {
      await this.onUpdateAndUpsertDynamicParameters(events, source);
    }
  }

  Lua_PopStack(nrpops:number = 0)
  {
    for(let i = 0; i < nrpops; i++)
      fengari.interop.pop();
  }

  static triggerDebugger()
  {
    debugger;
  }

  RegisterInLua()
  {
    fengari.interop.push(fengari.L, this);
    fengari.lua.lua_setglobal(fengari.L, "db");
    
    {
      fengari.interop.push(
        fengari.L,
        this.Lua_PopStack
      );
      fengari.lua.lua_setglobal(fengari.L, "internal_lua_pop");
    }
    {
      fengari.interop.push(
        fengari.L,
        XprojLuaDashboardWrapper.Lua_GetDataNowUTC
      );
      fengari.lua.lua_setglobal(fengari.L, "now_utc");
    }
    {
      fengari.interop.push(
        fengari.L,
        XprojLuaDashboardWrapper.Lua_GetDataMinUTC
      );
      fengari.lua.lua_setglobal(fengari.L, "min_utc");
    }    
    {
      fengari.interop.push(
        fengari.L,
        XprojLuaDashboardWrapper.Lua_JSONPARSE_UINT8Array
      );
      fengari.lua.lua_setglobal(fengari.L, "json_parse_bytes");
    }
    {
      fengari.interop.push(
        fengari.L,
        XprojLuaDashboardWrapper.Lua_JSONPARSE_string
      );
      fengari.lua.lua_setglobal(fengari.L, "json_parse_string");
    }    
    {
      fengari.interop.push(
        fengari.L,
        this.Lua_SetDataScalar
      );
      fengari.lua.lua_setglobal(fengari.L, "lua_setdata_scalar");
    }
    {
      fengari.interop.push(
        fengari.L,
        XprojLuaDashboardWrapper.Lua_AddStringFilter
      );
      fengari.lua.lua_setglobal(fengari.L, "query_add_filter_string");//lua_query_add_filter_string");
    }
    {
      fengari.interop.push(
        fengari.L,
        XprojLuaDashboardWrapper.Lua_AddNumberFilter
      );
      fengari.lua.lua_setglobal(fengari.L, "query_add_filter_number");//lua_query_add_filter_string");
    }
    {
      fengari.interop.push(
        fengari.L,
        XprojLuaDashboardWrapper.Lua_AddTimestampFilter
      );
      fengari.lua.lua_setglobal(fengari.L, "query_add_filter_timestamp");//lua_query_add_filter_string");
    }
    {
      fengari.interop.push(
        fengari.L,
        XprojLuaDashboardWrapper.Lua_BaseQuery
      );
      fengari.lua.lua_setglobal(fengari.L, "lua_query_create");
    }
    {
      fengari.interop.push(
        fengari.L,
        XprojLuaDashboardWrapper.Lua_AddColumnToQuery
      );
      fengari.lua.lua_setglobal(fengari.L, "query_add_column");
    }
    {
      fengari.interop.push(
        fengari.L,
        XprojLuaDashboardWrapper.Lua_AddAggColumnToQuery
      );
      fengari.lua.lua_setglobal(fengari.L, "query_add_agg_column");
    }

    {
      fengari.interop.push(
        fengari.L,
        this.Lua_GetScalarValueString
      );
      fengari.lua.lua_setglobal(fengari.L, "lua_query_get_scalar_string");
    }
    {
      fengari.interop.push(
        fengari.L,
        this.Lua_GetScalarValueTimestamp
      );
      fengari.lua.lua_setglobal(fengari.L, "lua_query_get_scalar_timestamp");
    }
    {
      fengari.interop.push(
        fengari.L,
        this.Lua_GetScalarValueNumber
      );
      fengari.lua.lua_setglobal(fengari.L, "lua_query_get_scalar_number");
    }    
    {
      fengari.interop.push(
        fengari.L,
        this.Lua_QueryResult
      );
      fengari.lua.lua_setglobal(fengari.L, "lua_query_result");
    }
    {
      fengari.interop.push(
        fengari.L,
        this.DebugPromise
      );
      fengari.lua.lua_setglobal(fengari.L, "lua_debug_promise");
    }

    {
      fengari.interop.push(
        fengari.L,
        XprojLuaDashboardWrapper.triggerDebugger
      );
      fengari.lua.lua_setglobal(fengari.L, "lua_debug");
    }

    {
      fengari.interop.push(
        fengari.L,
        XprojLuaDashboardWrapper.Lua_BaseQueryResult_GetNrRows
      );
      fengari.lua.lua_setglobal(fengari.L, "queryresult_get_nrrows");
    }    

    {
      fengari.interop.push(
        fengari.L,
        XprojLuaDashboardWrapper.Lua_BaseQueryResult_GetNrColumns
      );
      fengari.lua.lua_setglobal(fengari.L, "queryresult_get_nrcolumns");
    }  
    {
      fengari.interop.push(
        fengari.L,
        XprojLuaDashboardWrapper.Lua_BaseQueryResult_GetColumnName
      );
      fengari.lua.lua_setglobal(fengari.L, "queryresult_get_columnname");
    }      
    {
      fengari.interop.push(
        fengari.L,
        XprojLuaDashboardWrapper.Lua_BaseQueryResult_GetString
      );
      fengari.lua.lua_setglobal(fengari.L, "queryresult_get_string");
    }      
    {
      fengari.interop.push(
        fengari.L,
        XprojLuaDashboardWrapper.Lua_BaseQueryResult_GetTimestamp
      );
      fengari.lua.lua_setglobal(fengari.L, "queryresult_get_timestamp");
    }      
    {
      fengari.interop.push(
        fengari.L,
        XprojLuaDashboardWrapper.Lua_BaseQueryResult_GetNumber
      );
      fengari.lua.lua_setglobal(fengari.L, "queryresult_get_number");
    }      

    this.RegisteredLuaCode =   `
    --function now_utc()
     --   return lua_now(db)
    --end

    function query_create(projectionid)
        return lua_query_create(db, projectionid)
    end

    function query_grouped_create(projectionid, groups)
        return lua_query_create(db, projectionid, groups)
    end

    --function query_add_filter_string(query, colname, colvalue)
    --    lua_query_add_filter_string(db, query, colname, colvalue)
    --end

    local AGG_NONE                = 0
    local AGG_COUNT               = 1
    local AGG_SUM                 = 2
    local AGG_MAX                 = 3
    local AGG_MIN                 = 4
    local AGG_FIRST               = 5
    local AGG_LAST                = 6
    local AGG_CHECKSUM            = 7
    local AGG_FORWARD_DIFF        = 8
    local AGG_DISTINCT_COUNT      = 10
    local AGG_MEAN_ARITHMETIC     = 20
    local AGG_MEAN_GEOMETRIC      = 21
    local AGG_MEAN_HARMONIC       = 22
    local AGG_MEDIAN              = 40


    function setdata_scalar(projectionid, data)
        local t = lua_setdata_scalar(db, projectionid, data)
        local co = coroutine.running()

        t["then"](t,
            function()
                print("t is resolved")

            coroutine.resume(co)
        end)
        coroutine.yield()
        return db.setdata_success

    end

    function query_get_scalar_string(query, colname)
        local t = lua_query_get_scalar_string(db, query, colname)
        local co = coroutine.running()

        t["then"](t,
            function()
                print("t is resolved")

            coroutine.resume(co)
        end)
        coroutine.yield()
        return db.scalar_value_string
    end
    function query_get_scalar_timestamp(query, colname)
        local t = lua_query_get_scalar_timestamp(db, query, colname)
        local co = coroutine.running()

        t["then"](t,
            function()
                print("t is resolved")

            coroutine.resume(co)
        end)
        coroutine.yield()
        return db.scalar_value_timestamp
    end
    function query_get_scalar_number(query, colname)
        local t = lua_query_get_scalar_number(db, query, colname)
        local co = coroutine.running()

        t["then"](t,
            function()
                print("t is resolved")

            coroutine.resume(co)
        end)
        coroutine.yield()
        return db.scalar_value_number
    end 
    
    function query_get_result(query, maxitems)
        local t = lua_query_result(db, query, maxitems)
        local co = coroutine.running()

        t["then"](t,
            function()
                print("t is resolved")

            coroutine.resume(co)
        end)
        coroutine.yield()
        return db.QueryResult
    end    
    `; 
  }

  RegisteredLuaCode:string ="";

  public isBusy = false;

  public RunScript(script: string, extrainnerlua : string = null, nrpops: number = 0): void {
    //fengari.fengari.push(this.jox);
    if(this.isBusy)
      throw Error("lua busy");
   
    //this.logger.info("should run lua: " + script);

    let innerLua = this.RegisteredLuaCode;

    if(extrainnerlua)
          innerLua += extrainnerlua + "\n";
    innerLua += "\n" + script + "\n";

    if(nrpops > 0)
      innerLua += "internal_lua_pop(" + nrpops.toString() + ")\n";
    let luaCode = "coroutine.wrap(function() " + innerLua + ";\n end)()";  
      
    //this.logger.info("LUA full lua: " + luaCode);
    fengari.load(luaCode)();

  }

  RegisterParamInLua(param:ScriptedParameters) : number
  {
    fengari.interop.push(fengari.L, param);
    fengari.lua.lua_setglobal(fengari.L, "param");

    {
      fengari.interop.push(
        fengari.L,
        param.GetString
      );
      fengari.lua.lua_setglobal(fengari.L, "lua_param_get_string");
    }
    {
      fengari.interop.push(
        fengari.L,
        param.GetNumber
      );
      fengari.lua.lua_setglobal(fengari.L, "lua_param_get_number");
    }
    {
      fengari.interop.push(
        fengari.L,
        param.SetString
      );
      fengari.lua.lua_setglobal(fengari.L, "lua_param_set_string");
    } 

    return 4; // pops requierd
  }

  RunScriptedParameter(param: ScriptedParameters): void {
    //fengari.fengari.push(this.jox);
    let nrPops = this.RegisterParamInLua(param);
   
   

    this.logger.info("should run lua: " + param.LuaScript);

    let extraInnerLua =
      `
function get_param_string(name)
    lua_param_get_string(param, name)
end
function get_param_number(name)
    lua_param_get_number(param, name)
end

function set_param_string(name, value)
    lua_param_set_string(param, name, value)
end
`;

    let luaCode = param.LuaScript + "\n" +
      `
        db.updateAndUpsertDynamicParameters(db, param.Parameter, param)
        `;

      this.RunScript(luaCode, extraInnerLua, nrPops);
    
  }
}
