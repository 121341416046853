

<h2>Create configuration backup</h2>

<div class="alert alert-warning" role="alert" *ngIf="showbackupwarning">
  <div class="alert-items">
    <div class="alert-item static">
      <div class="alert-icon-wrapper">
        <cds-icon class="alert-icon" shape="exclamation-triangle"></cds-icon>
      </div>
      <span class="alert-text">Backups of configuration <b>does not include</b> runtime-data or projection-data. It also
        discards projection configurations prefixed with (case insensitive) <i>xdb, xauto, xbot</i></span>
    </div>
  </div>
  <button type="button" class="close" aria-label="Close" (click)="showbackupwarning=false">
    <cds-icon aria-hidden="true" shape="close"></cds-icon>
  </button>
</div>

<textarea #logoutput *ngIf="create_backup_log!=''" clrTextarea [(ngModel)]="create_backup_log"
  style="width: 50rem; height: 20rem; font-family: monospace;"></textarea>
<button class="btn" (click)="generateBackup(false)">Create</button>

<h3 *ngIf="backup">Backup created</h3>
<button *ngIf="backup && !downloadUrl" class="btn" (click)="download()">Generate download</button>
<button *ngIf="backup && !savedtoblob" class="btn" (click)="savetoblob()">Save to xdb</button>
<a *ngIf="downloadUrl" [download]="backupFilename" [href]="downloadUrl">Download {{backupFilename}} ({{backupSize}}
  bytes)</a>

<h2>Restore configuration backup</h2>
<textarea #loginput *ngIf="restore_backup_log!=''" clrTextarea [(ngModel)]="restore_backup_log"
  style="width: 50rem; height: 20rem; font-family: monospace;"></textarea>
<p *ngIf="selectedRestorePoint">
  Selected restore point: {{selectedRestorePoint}} ({{this.restoredata.length}} bytes)
</p>

<div *ngIf="showrestorewarning && selectedRestorePoint" class="alert alert-danger" role="alert">
  <div class="alert-items">
    <div class="alert-item static">
      <div class="alert-icon-wrapper">
        <cds-icon class="alert-icon" shape="exclamation-triangle"></cds-icon>
      </div>
      <span class="alert-text">Restoring a backup (restore point) will revert any modifications.</span>
    </div>
  </div>
  <button type="button" class="close" aria-label="Close" (click)="showrestorewarning=false">
    <cds-icon aria-hidden="true" shape="close"></cds-icon>
  </button>
</div>

<button [clrLoading]="restoringStatus" class="btn" (click)="restoreBackup()"
  [disabled]="!restoredata || (restoringStatus!=ClrLoadingState.DEFAULT&&restoringStatus!=ClrLoadingState.SUCCESS&&restoringStatus!=ClrLoadingState.ERROR)">Restore
  selected</button>

<h3>From XDB</h3>
<div style="padding:1em;" *ngIf="blobbackups.length > 0">
  Load restore point from XDB;
  <ul>
    <li *ngFor="let f of blobbackups">
      <a style="cursor: pointer;" (click)="restoreFromBlob(f)">{{f}}</a>
    </li>
  </ul>
</div>
<div style="padding:1em;" *ngIf="blobbackups.length == 0">
  No backups stored in XDB.
</div>
<h3>From file</h3>


<div class="dropzone">
  <ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)">
    Drop backup file here.
  </ngx-file-drop>


  <clr-accordion [clrAccordionMultiPanel]="true" style="margin-top: 2em;">

  <clr-accordion-panel [clrAccordionPanelOpen]="false">
    <clr-accordion-title>Partial backup</clr-accordion-title>
    <clr-accordion-content>
  
  
    <h2>Create partial backup</h2>
    <p>Partial backups may cause dependency issues if not carefully selected. Partial backups are restored as full backups.</p>

    <h3>Select Projections</h3>
    <div class="limit-height" style="max-width:1200px;">
      <clr-datagrid [(clrDgSelected)]="selectedProjections" [clrDgLoading]="loadingProjections">

        <clr-dg-column [clrDgField]="'projectionid'">
          <ng-container *clrDgHideableColumn="{hidden: false}">ProjectionD</ng-container>
        </clr-dg-column>
        <clr-dg-column [clrDgField]="'name'">
          <ng-container *clrDgHideableColumn="{hidden: false}">Name</ng-container>
        </clr-dg-column>
        <clr-dg-column [clrDgField]="'description'">
          <ng-container *clrDgHideableColumn="{hidden: false}">Description</ng-container>
        </clr-dg-column>
        <clr-dg-column [clrDgField]="'tags'">
          <ng-container *clrDgHideableColumn="{hidden: false}">Tags</ng-container>
        </clr-dg-column>


        <clr-dg-row *clrDgItems="let proj of Projections; let i = index" [clrDgItem]="proj">
          <clr-dg-cell class="cellhideoverflow"> {{proj.projectionid}} </clr-dg-cell>
          <clr-dg-cell class="cellhideoverflow"> {{proj.name}} </clr-dg-cell>
          <clr-dg-cell class="cellhideoverflow"> {{proj.description}} </clr-dg-cell>
          <clr-dg-cell class="cellhideoverflow"> {{proj.tags}} </clr-dg-cell>

        </clr-dg-row>

        <clr-dg-footer> Selected {{selectedProjections.length}} / {{Projections.length}} Projections</clr-dg-footer>

      </clr-datagrid>
    </div>


  
    <h3>Select Drivers</h3>

    <div class="limit-height" style="max-width:1200px;">
      <clr-datagrid [(clrDgSelected)]="selectedDrivers" [clrDgLoading]="loadingDrivers">

        <clr-dg-column [clrDgField]="'xautodriverid'">
          <ng-container *clrDgHideableColumn="{hidden: false}">Driver ID</ng-container>
        </clr-dg-column>
        <clr-dg-column [clrDgField]="'name'">
          <ng-container *clrDgHideableColumn="{hidden: false}">Name</ng-container>
        </clr-dg-column>
        <clr-dg-column [clrDgField]="'description'">
          <ng-container *clrDgHideableColumn="{hidden: false}">Description</ng-container>
        </clr-dg-column>


        <clr-dg-row *clrDgItems="let driver of Drivers; let i = index" [clrDgItem]="driver">
          <clr-dg-cell class="cellhideoverflow"> {{driver.xautodriverid}} </clr-dg-cell>
          <clr-dg-cell class="cellhideoverflow"> {{driver.name}} </clr-dg-cell>
          <clr-dg-cell class="cellhideoverflow"> {{driver.description}} </clr-dg-cell>

        </clr-dg-row>

        <clr-dg-footer> Selected {{selectedDrivers.length}} / {{Drivers.length}} Drivers</clr-dg-footer>

      </clr-datagrid>
    </div>

    <h3>Select Programs</h3>
    <p>Selecting a program will automatically include schedulings. Drivers and thereby variables must be selected manually.</p>
    <div class="limit-height" style="max-width:1200px;">
      <clr-datagrid [(clrDgSelected)]="selectedPrograms" [clrDgLoading]="loadingDrivers">

        <clr-dg-column [clrDgField]="'xautoprogramid'">
          <ng-container *clrDgHideableColumn="{hidden: false}">Program ID</ng-container>
        </clr-dg-column>
        <clr-dg-column [clrDgField]="'name'">
          <ng-container *clrDgHideableColumn="{hidden: false}">Name</ng-container>
        </clr-dg-column>
        <clr-dg-column [clrDgField]="'description'">
          <ng-container *clrDgHideableColumn="{hidden: false}">Description</ng-container>
        </clr-dg-column>


        <clr-dg-row *clrDgItems="let program of Programs; let i = index" [clrDgItem]="program">
          <clr-dg-cell class="cellhideoverflow"> {{program.xautoprogramid}} </clr-dg-cell>
          <clr-dg-cell class="cellhideoverflow"> {{program.name}} </clr-dg-cell>
          <clr-dg-cell class="cellhideoverflow"> {{program.description}} </clr-dg-cell>

        </clr-dg-row>

        <clr-dg-footer> Selected {{selectedPrograms.length}} / {{Programs.length}} Programs</clr-dg-footer>

      </clr-datagrid>

    </div>    

    <button class="btn" (click)="generateBackup(true)">Create partial backup</button>


  </clr-accordion-content>
  </clr-accordion-panel>
  
  </clr-accordion>
  
</div>

