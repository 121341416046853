import { ChangeDetectorRef, Component, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RossakerBmsCustomer } from '@core/models/rossaker-bms-customer';
import { RossakerBmsCustomerConfig } from '@core/models/rossaker-bms-customer-config';
import { RossakerBmsCustomerData } from '@core/models/rossaker-bms-customer-data';
import { RossakerBmsAdminService } from '@core/services/rossaker-bms-admin-service';
import { RossakerStateService } from '@core/services/rossaker-state-service';
import { StateService } from '@xprojectorcore/services/state-service';
import { Customer } from '@xprojectorcore/xprojector_backend/proto/xprojector.grpc.models.pb';
import { GrpcNodeType } from '@xprojectorcore/xprojector_backend/proto/xprojector.xconf.pb';
import { XProjectorCustomerUsersClient } from '@xprojectorcore/xprojector_backend/xprojector-customerusers-client';
import { XProjectorXConfClient } from '@xprojectorcore/xprojector_backend/xprojector-xconf-client';
import { NGXLogger } from 'ngx-logger';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { OutputDataType, BaseQuery, DashboardOutputChangeParameters, DFTQuery, DownSampleQuery, MultiseriesQuery, WidgetExportParameters, XprojAlertService, XprojDashboardComponent } from 'xproj-lib';

@Component({
  selector: 'app-rossaker-bms-admin-dashboard',
  templateUrl: './rossaker-bms-admin-dashboard.component.html',
  styleUrls: ['./rossaker-bms-admin-dashboard.component.scss']
})
export class RossakerBmsAdminDashboardComponent implements OnInit, OnDestroy {

  @ViewChild("dashboard", { read: XprojDashboardComponent, static: false }) xprojDashboard: XprojDashboardComponent;

  responsiveWidth: number = 834;

  _dashboardId: string;
  @Input()
  get dashboardId() { return this._dashboardId };
  set dashboardId(value) {
    this._dashboardId = value;
    setTimeout(() => {
      if (value) {
        this.updateDashboardOutputParameters();
        this.xprojDashboard?.setDashboardId(value, -1, '', true);
      }
    });
  }


  ngUnsubscribe = new Subject<void>();

  selectedBmsCustomer: RossakerBmsCustomer;
  selectedCustomer: Customer;
  selectedCustomerData: RossakerBmsCustomerData;
  selectedCustomerConfig: RossakerBmsCustomerConfig;

  allNodeTypes: GrpcNodeType[] = [];

  dashboardOutputParameters: DashboardOutputChangeParameters[] = [];

  constructor(
    public state: StateService,
    private rossakerState: RossakerStateService,
    private logger: NGXLogger,
    private customerUserClient: XProjectorCustomerUsersClient,
    private alertService: XprojAlertService,
    private adminService: RossakerBmsAdminService,
    private xConfClient: XProjectorXConfClient,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute) {

  }

  async ngOnInit() {
    this.route.params.pipe(map(p => p.id)).subscribe(async (dashboardId) => {
      this.dashboardId = dashboardId;
      this.cdr.detectChanges();
      await this.xprojDashboard?.setDashboardId(this.dashboardId, -1, '', true);
    });

    this.rossakerState.customer$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(async (e) => {

      this.selectedCustomer = e.customer;
      this.selectedBmsCustomer = e.bmsCustomer;

      this.dashboardOutputParameters = [];
      this.updateDashboardOutputParameters();
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  updateDashboardOutputParameters() {
    this.dashboardOutputParameters = [];
      if (this.selectedBmsCustomer) {

        let out = new DashboardOutputChangeParameters();
        out.outputParameterName = 'customerid';
        out.value = this.selectedBmsCustomer.customerId;
        out.datatype = OutputDataType.String;
        this.dashboardOutputParameters.push(out);

        out = new DashboardOutputChangeParameters();
        out.outputParameterName = 'customerxdbid';
        out.value = this.selectedBmsCustomer.id;
        out.datatype = OutputDataType.Int64;
        this.dashboardOutputParameters.push(out);

        out = new DashboardOutputChangeParameters();
        out.outputParameterName = 'customername';
        out.value = this.selectedBmsCustomer.customerName;
        out.datatype = OutputDataType.String;
        this.dashboardOutputParameters.push(out);
      }
  }

  ngAfterViewInit(): void {
    this.applyNewWidth(window.innerWidth);
    if (this.dashboardId?.length > 0) {
      this.xprojDashboard?.setDashboardId(this.dashboardId, -1, '', true);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.applyNewWidth(event.target.innerWidth);
    this.logger.debug('applyNewWidth resize', event.target.innerWidth);
  }

  applyNewWidth(width) {
    this.logger.info("applying width: ", width);
    if (width > 850) {
      this.responsiveWidth = width - 300;
      return;
    }
    this.responsiveWidth = width;
  }


  async onWidgetExport(parameters: WidgetExportParameters) {
    let result = await this.customerUserClient.exportToExcel(parameters);

    if (!result) {
      this.alertService.error('Error export to excel!');
    }
  }
}
