import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { StateService } from '@xprojectorcore/services/state-service';

@Injectable(
  {
    providedIn: 'root'
  })
export class AuthGuard  {
  constructor(
    private router: Router,
    private state: StateService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const user = this.state.userRightsValue;
    if (user) {
      let expiresAtUTC = new Date(user.session.expiresAtUTC);
      if (expiresAtUTC.getTime() > Date.now()) {
        if (user.gdprConsent) {
          // authorised so return true
          return true;
        }
        else {
          //return true;
          this.router.navigate(['/gdprconsent'], { queryParams: { returnUrl: state.url } });
          return false;
        }
      }
      else if (user.isAdminUser) {
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url, admin: 'true' } });
        return false;
      }
    }
    else {
      // not logged in so redirect to login page with the return url
      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
      return false;
    }
  }
}
