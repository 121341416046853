import { Component, OnInit } from '@angular/core';
import { RossakerXProjectorBmsExportClient } from '@core/xprojector_backend/rossaker-xprojector-bms-export-client';
import { Customer, CustomerUser } from '@xprojectorcore/xprojector_backend/proto/xprojector.grpc.models.pb';
import { XProjectorSysAdminClient } from '@xprojectorcore/xprojector_backend/xprojector-sysadmin-client';
import { XProjectorXConfClient } from '@xprojectorcore/xprojector_backend/xprojector-xconf-client';
import { XprojAlertService } from 'xproj-lib';
import { RossakerBmsAdminService } from '@app/core/services/rossaker-bms-admin-service';

@Component({
  selector: 'app-rossaker-bms-customer-add',
  templateUrl: './rossaker-bms-customer-add.component.html',
  styleUrls: ['./rossaker-bms-customer-add.component.scss']
})
export class RossakerBmsCustomerAddComponent implements OnInit {

  newCustomer: Customer = new Customer();
  isTrustee: boolean = false;
  newCustomerAdmin: CustomerUser = new CustomerUser();
  county: string = '';
  district: string = '';
  isNonBmscustomer: boolean = false;

  constructor(
    private xconfClient: XProjectorXConfClient,
    private adminService: RossakerBmsAdminService,
    private sysAdminClient: XProjectorSysAdminClient,
    private bmsExportClient: RossakerXProjectorBmsExportClient,
    private alertService: XprojAlertService) { }

  ngOnInit(): void {
  }

  async addCustomer() {
    try {
      if (this.isNonBmscustomer) {
        this.newCustomer.username = this.newCustomer.name.replaceAll(' ', '').toLowerCase();
        this.newCustomer.isEnabled = true;
        let customer = await this.sysAdminClient.addNewCustomer(this.newCustomer);
        if (customer) {
          await this.bmsExportClient.assertCountyAndDistrict(customer.id, customer.name, 'Other', 'NonBmsCustomers');
          let customerConfig = await this.adminService.getCustomerConfig(customer.id);
          if (customerConfig) {
            customerConfig.customerIsTrustee = false;
            customerConfig.disabled = false;

            let result = await this.adminService.saveCustomerConfig(customerConfig);

            if (result.result) {
              this.alertService.success('New customer added.');

              let nonBmsCustomersParentNode = await this.xconfClient.getNode('nonbmscustomers', '_x_bms_customergrouping_group');
                if (!nonBmsCustomersParentNode) {
                  //TODO : add
                }

                if (nonBmsCustomersParentNode) {
                  await this.xconfClient.createReference(nonBmsCustomersParentNode.id, nonBmsCustomersParentNode.nodeTypeLabel, customer.id,
                              '_x_datasource', '_x_bms_customergrouping_hascustomer', true, null, '', customer.id);
                }

                        //Update customers cache
              await this.sysAdminClient.getCustomers(true);
              await this.adminService.getCustomers(true);
              //await this.adminService.getTrustees(true);  //TODO
            }
            else {
              this.alertService.error(result.message);
            }

            this.clear();
          }
        }
        else {
          this.alertService.error('Error add new customer!');
        }
      }
      else if (!this.isTrustee)
      {
        if (this.county.length > 0 && this.district.length > 0) {
          this.newCustomer.username = this.newCustomer.name.replaceAll(' ', '').toLowerCase();
          this.newCustomer.isEnabled = true;
          let customer = await this.sysAdminClient.addNewCustomer(this.newCustomer);
          if (customer) {
            await this.bmsExportClient.assertCountyAndDistrict(customer.id, customer.name, this.county, this.district);
            let customerConfig = await this.adminService.getCustomerConfig(customer.id);
            if (customerConfig) {
              customerConfig.customerIsTrustee = this.isTrustee;
              customerConfig.disabled = false;

              let result = await this.adminService.saveCustomerConfig(customerConfig);

              if (result.result) {
                this.alertService.success('New customer added.');

                let trusteesParentNode = await this.xconfClient.getNode('customergroupingtrustees', '_x_bms_customergrouping_group');
                if (!trusteesParentNode) {
                  //TODO : add
                }

                if (trusteesParentNode) {
                  await this.xconfClient.createReference(trusteesParentNode.id, trusteesParentNode.nodeTypeLabel, customer.id,
                              '_x_datasource', '_x_bms_customergrouping_hascustomer', true, null, '', customer.id);
                }

                          //Update customers cache
                await this.sysAdminClient.getCustomers(true);
                await this.adminService.getCustomers(true);
                //await this.adminService.getTrustees(true);  //TODO
              }
              else {
                this.alertService.error(result.message);
              }

              this.clear();
            }
          }
          else {
            this.alertService.error('Error add new customer!');
          }
        }
        else {
          this.alertService.error('Select county and district!');
        }
      }
      else {
        this.newCustomer.username = this.newCustomer.name.replaceAll(' ', '').toLowerCase();
        this.newCustomer.isEnabled = true;
        let customer = await this.sysAdminClient.addNewCustomer(this.newCustomer);
        if (customer) {
          let customerConfig = await this.adminService.getCustomerConfig(customer.id);
          if (customerConfig) {
            customerConfig.customerIsTrustee = this.isTrustee;
            customerConfig.disabled = false;

            let result = await this.adminService.saveCustomerConfig(customerConfig);

            if (result.result) {
              this.alertService.success('New trustee added.');

              let trusteesParentNode = await this.xconfClient.getNode('customergroupingtrustees', '_x_bms_customergrouping_group');
              if (!trusteesParentNode) {
                //TODO : add
              }

              if (trusteesParentNode) {
                await this.xconfClient.createReference(trusteesParentNode.id, trusteesParentNode.nodeTypeLabel, customer.id,
                        '_x_datasource', '_x_bms_customergrouping_hascustomer', true, null, '', customer.id);
              }

            }
            else {
              this.alertService.error(result.message);
            }

            this.clear();
          }
        }
      }
    }
    catch (err) {
      this.alertService.error('Error add new customer: ' + err);
    }
  }

  clear() {
    this.newCustomer = new Customer();
    this.newCustomerAdmin = new CustomerUser();
    this.isTrustee = false;
    this.county = '';
    this.district = '';
    this.isNonBmscustomer = false;
  }

}
