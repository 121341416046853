import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { RossakerXProjectorBmsEventsClient } from '@core/xprojector_backend/rossaker-xprojector-bms-events-client';
import { NGXLogger } from 'ngx-logger';
import { map, takeUntil } from 'rxjs/operators';
import { DateHelper, XprojModalService } from 'xproj-lib';
import { EscalationInfo, Event, EventUser } from '@core/xprojector_backend/proto/xprojector.modulerossakerbms.events.pb';
import { RossakerStateService } from '@core/services/rossaker-state-service';
import { Subject, Subscription, timer } from 'rxjs';
import { ClrDatagridComparatorInterface, ClrDatagridSortOrder } from '@clr/angular';
import { RossakerBmsCustomer } from '@app/core/models/rossaker-bms-customer';
import { Customer } from '@xprojectorcore/xprojector_backend/proto/xprojector.grpc.models.pb';
import { RossakerBmsCustomerData } from '@app/core/models/rossaker-bms-customer-data';
import { RossakerBmsCustomerConfig } from '@app/core/models/rossaker-bms-customer-config';
import { RossakerBmsAdminService } from '@app/core/services/rossaker-bms-admin-service';
import { XProjectorXConfClient } from '@xprojectorcore/xprojector_backend/xprojector-xconf-client';


export class RossakerBmsCustomerEventsEvent {
  event : Event;
  class : string;
  status : string;
}

class EventTimeComparator implements ClrDatagridComparatorInterface<RossakerBmsCustomerEventsEvent> {
  compare(a: RossakerBmsCustomerEventsEvent, b: RossakerBmsCustomerEventsEvent) {
    if (a && b && a.event.timestamp && b.event.timestamp) {
      return a.event.timestamp.toDate() > b.event.timestamp.toDate() ? 1 : -1;
    }
    else {
      return a ? 1 : -1
    }
  }
}

@Component({
  selector: 'app-rossaker-bms-events',
  templateUrl: './rossaker-bms-events.component.html',
  styleUrls: ['./rossaker-bms-events.component.scss']
})
export class RossakerBmsEventsComponent implements OnInit, OnDestroy {

  //@Input() customerId : string;
  ngUnsubscribe = new Subject<void>();

  selectedBmsCustomer: RossakerBmsCustomer;
  selectedCustomer: Customer;
  selectedCustomerData: RossakerBmsCustomerData;
  selectedCustomerConfig: RossakerBmsCustomerConfig;


  descSort = ClrDatagridSortOrder.DESC;
  eventTimeSort = new EventTimeComparator();

  events: RossakerBmsCustomerEventsEvent[] = [];
  escalationInfos: EscalationInfo[] = [];

  private updateTimerSource;
  private updateTimerSubscription: Subscription;

  escalationInfosByUser : { user : EventUser, expanded : boolean, escalationInfos : {info : EscalationInfo, enabled : boolean}[]}[] = [];

  loadingEvents : boolean = false;
  saveInProgress : boolean = false;

  constructor(
    private logger: NGXLogger,
    private rossakerState: RossakerStateService,
    private adminService: RossakerBmsAdminService,
    private xconfClient: XProjectorXConfClient,
    private modalService: XprojModalService,
    private eventsClient: RossakerXProjectorBmsEventsClient,
    public dateHelper: DateHelper,
  ) {
  }

  async ngOnInit() {
    this.rossakerState.customer$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(async (e) => {
      this.selectedCustomer = e.customer;
      this.selectedBmsCustomer = e.bmsCustomer;

      this.selectedCustomerData = new RossakerBmsCustomerData();
      if (this.selectedBmsCustomer) {
        this.selectedCustomerData.customerId = this.selectedBmsCustomer.customerId;
        this.updateSelectedCustomerConfig();

        this.updateEvents();
        this.updateEventSettings();

        this.updateTimerSource = timer(10000, 10000);
        this.updateTimerSubscription = this.updateTimerSource.subscribe($event => {
          this.updateEvents();
        });
      }
    });
  }

   ngOnDestroy(): void {
    this.updateTimerSubscription?.unsubscribe();
  }

  async initPage() {
    await this.updateEvents();
  }

  async updateEvents() {
    let events = await this.eventsClient.getEvents(this.selectedBmsCustomer.customerId);

    let activeCount : number = 0;
    events.forEach(e => {
      let bmsEvent = new RossakerBmsCustomerEventsEvent();
      bmsEvent.event = e;
      bmsEvent.class = e.muted ? 'dot-muted' : e.active ? (e.acknowledged ? 'dot-open' : 'dot-new') : 'dot-closed';
      bmsEvent.status = e.muted ? 'Muted' : e.active ? (e.acknowledged ? 'Open' : 'New') : 'Closed';
      if (e.active) {
        activeCount++;
      }

      let ev = this.events.find(x => x.event.id == e.id);
      if (ev) {
        ev.event = e;
        ev.class = bmsEvent.class;
        ev.status = bmsEvent.status;
      }
      else {
        this.events.push(bmsEvent);
      }
    });

    //this.events.sort((a, b) => !a.event.active ? 1 : -1);
  }

  async updateSelectedCustomerConfig() {
    if (this.selectedBmsCustomer && !this.selectedCustomerConfig) {
      this.selectedCustomerConfig = await this.adminService.getCustomerConfig(this.selectedBmsCustomer.customerId, await this.xconfClient.getNodeTypes());
    }
  }


  async updateEventSettings() {
    this.escalationInfos = await this.eventsClient.getEventSettings(this.selectedBmsCustomer.customerId);

    this.escalationInfosByUser = [];

    this.escalationInfos.forEach(info => {
      info.users.forEach(user => {
        if (this.escalationInfosByUser.findIndex(x => x.user.email == user.email) < 0) {
          this.escalationInfosByUser.push({user: user, expanded: false, escalationInfos : []});
        }
      });
    });

    this.escalationInfos.forEach(info => {
      this.escalationInfosByUser.forEach(byUser => {
        if (info.users.findIndex(u => u.email == byUser.user.email) >= 0) {
          byUser.escalationInfos.push({ info : info, enabled : true });
        }
        else {
          byUser.escalationInfos.push({ info : info, enabled : false });
        }
      });
    });
  }

  addNewUser() {
    this.escalationInfosByUser.forEach(x => {
      x.expanded = false;
    });

    let user : EventUser = new EventUser();
    user.name = '';
    user.typeId = '_x_bms_events_externaluser';

    let toAdd = {user : user, expanded: true, escalationInfos : []};

    this.escalationInfos.forEach(x => toAdd.escalationInfos.push({info : x, enabled : false}));
    this.escalationInfosByUser.push(toAdd);
  }

  deleteUser(user : EventUser) {
    this.modalService.ShowConfirmModal({ header: 'Remove user', description: 'Remove user, are you sure?' }, async (ok) => {
        if (ok) {
          this.escalationInfosByUser = this.escalationInfosByUser.filter(x => x.user.email != user.email);
          await this.saveSettings();
        }
      });
  }

  addEscalationInfoToUser(userWithInfos : { user : EventUser,  escalationInfos : {info :EscalationInfo, enabled : boolean}[]}, escalationInfo: EscalationInfo ) {
    let u = userWithInfos.escalationInfos.find(x => x.info.id == escalationInfo.id);
    if (u) {
      u.enabled = true;
    }
  }

  removeEscalationInfoFromUser(userWithInfos : { user : EventUser,  escalationInfos : {info :EscalationInfo, enabled : boolean}[]}, escalationInfo: EscalationInfo ) {
    userWithInfos.escalationInfos = userWithInfos.escalationInfos.filter(x => x.info.id != escalationInfo.id);
  }

  async saveSettings() {
    try {
      this.saveInProgress = true;

      this.escalationInfos.forEach(info => {
        info.users = [];
      });

      this.escalationInfosByUser.forEach(x => {
        if (x.user.name?.length > 0 && (x.user.email.length > 0 || x.user.phone.length > 0)) {
          x.escalationInfos.forEach(infoEnabled => {
            if (infoEnabled.enabled) {
              infoEnabled.info.users.push(x.user);
            }
          });
        }
      });

      await this.eventsClient.updateEventSettings(this.selectedBmsCustomer.customerId, this.escalationInfos);

      this.escalationInfosByUser.forEach(x => {
        x.expanded = false;
      });
    }
    finally {
      this.saveInProgress = false;
    }

  }

  async acknowledgeEvent(bmsEvent : RossakerBmsCustomerEventsEvent) {
    if (bmsEvent) {
      let result = await this.modalService.ShowInputModalAsync({
        header: 'Acknowledge',
        description: 'Input comment:',
        value: '',
        ok: 'Acknowledge',
        cancel: 'Cancel',
      });
      if (result.result) {
        //await this.eventsClient.acknowledgeEvent(bmsEvent.event.customerId, bmsEvent.event.id, result.value, this.rossakerState.userId, '_x_customerusers_user');
        this.updateEvents();
      }
    }
  }

  async closeEvent(bmsEvent : RossakerBmsCustomerEventsEvent) {
    if (bmsEvent) {
      let result = await this.modalService.ShowInputModalAsync({
        header: 'Close event',
        description: 'Input comment:',
        value: '',
        ok: 'Close event',
        cancel: 'Cancel',
      });
      if (result.result) {
        //await this.eventsClient.clearEvent(bmsEvent.event.customerId, bmsEvent.event.id, result.value, this.rossakerState.userId, '_x_customerusers_user');
        this.updateEvents();
      }
    }
  }

  async muteEvent(bmsEvent : RossakerBmsCustomerEventsEvent) {
    if (bmsEvent) {
      let result = await this.modalService.ShowInputModalAsync({
        header: 'Mute event',
        description: 'Input comment:',
        value: '',
        ok: 'Mute event',
        cancel: 'Cancel',
      });
      if (result.result) {
        //await this.eventsClient.muteEvent(bmsEvent.event.customerId, bmsEvent.event.id, result.value, this.rossakerState.userId, '_x_customerusers_user');
        this.updateEvents();
      }
    }
  }

  async unmuteEvent(bmsEvent : RossakerBmsCustomerEventsEvent) {
    if (bmsEvent) {
      let result = await this.modalService.ShowInputModalAsync({
        header: 'Unmute event',
        description: 'Input comment:',
        value: '',
        ok: 'Unmute event',
        cancel: 'Cancel',
      });
      if (result.result) {
        //await this.eventsClient.unmuteEvent(bmsEvent.event.customerId, bmsEvent.event.id, result.value, this.rossakerState.userId, '_x_customerusers_user');
        this.updateEvents();
      }
    }
  }

}
