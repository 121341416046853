<clr-accordion style="margin-top: 1em">
  <clr-accordion-panel [(clrAccordionPanelOpen)]="widgetConfigOpen">
    <clr-accordion-title>Widget config</clr-accordion-title>
    <clr-accordion-content>
      <form clrForm>
        <clr-input-container>
          <label>Name</label>
          <input clrInput style="width: 75%;" placeholder="" name="name" [(ngModel)]="config.Name" />
        </clr-input-container>

        <clr-input-container>
          <label>Title</label>
          <input clrInput style="width: 75%;" placeholder="" name="title" [(ngModel)]="config.Title" />
        </clr-input-container>

        <clr-checkbox-container>
          <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox name="ShowTitle" [(ngModel)]="config.ShowTitle" />
            <label>Show title</label>
          </clr-checkbox-wrapper>
        </clr-checkbox-container>

        <clr-checkbox-container>
          <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox name="Borderless" [(ngModel)]="config.Borderless" />
            <label>Borderless</label>
          </clr-checkbox-wrapper>
        </clr-checkbox-container>

        <clr-checkbox-container>
          <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox name="ExportEnabled" [(ngModel)]="config.ExportEnabled" />
            <label>Export enabled</label>
          </clr-checkbox-wrapper>
        </clr-checkbox-container>

        <clr-checkbox-container>
          <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox name="IsSensitive" [(ngModel)]="config.IsSensitive" />
            <label>Is Sensitive</label>
          </clr-checkbox-wrapper>
        </clr-checkbox-container>

        <clr-checkbox-container>
          <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox name="TitleIsSensitive" [(ngModel)]="config.TitleIsSensitive" />
            <label>Title Is Sensitive</label>
          </clr-checkbox-wrapper>
        </clr-checkbox-container>

        <clr-checkbox-container>
          <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox name="FullscreenEnabled" [(ngModel)]="config.FullscreenEnabled" />
            <label>Fullscreen enabled</label>
          </clr-checkbox-wrapper>
        </clr-checkbox-container>

        <clr-select-container *ngIf="widgets">
          <label>Linked input widget</label>
          <select clrSelect name="linkedinputwidget" [(ngModel)]="config.LinkedInputWidget">
            <option [ngValue]="''"> </option>
            <option *ngFor="let widget of widgets" [ngValue]="widget.Id">
              {{widget.Name?.length > 0 ? widget.Name : widget.Title}}</option>
          </select>
        </clr-select-container>

        <clr-select-container *ngIf="widgets && config.LinkedInputWidget?.length > 0">
          <label>Linked action on zoom</label>
          <select clrSelect name="linkedinputwidgetActionZoom" id="linkedinputwidgetActionZoom"
            [(ngModel)]="config.LinkedWidgetActions.OnZoom">
            <option [ngValue]="LinkedWidgetChangeCommand.None"> --- </option>
            <option [ngValue]="LinkedWidgetChangeCommand.Zoom"> Zoom </option>
            <option [ngValue]="LinkedWidgetChangeCommand.Highlight"> Highlight </option>
          </select>
        </clr-select-container>

        <clr-select-container *ngIf="widgets && config.LinkedInputWidget?.length > 0">
          <label>Linked action on highlight</label>
          <select clrSelect name="linkedinputwidgetActionHighlight" id="linkedinputwidgetActionHighlight"
            [(ngModel)]="config.LinkedWidgetActions.OnHighlight">
            <option [ngValue]="LinkedWidgetChangeCommand.None"> --- </option>
            <option [ngValue]="LinkedWidgetChangeCommand.Zoom"> Zoom </option>
            <option [ngValue]="LinkedWidgetChangeCommand.Highlight"> Highlight </option>
          </select>
        </clr-select-container>

        <clr-input-container>
          <label>Tooltip</label>
          <input clrInput style="width: 75%;" placeholder="" name="tooltip" [(ngModel)]="config.Tooltip" />
        </clr-input-container>

        <clr-input-container>
          <label>Min update interval (-1 = updates disabled)</label>
          <input clrInput placeholder="0" type="number" name="minupdateinterval" min="-1" max="100000000"
            [(ngModel)]="config.MinUpdateInterval" />
        </clr-input-container>

      </form>
    </clr-accordion-content>
  </clr-accordion-panel>
</clr-accordion>

<clr-accordion>
  <clr-accordion-panel>
    <clr-accordion-title>Grid parameters</clr-accordion-title>
    <clr-accordion-content>
      <clr-input-container>
        <label>X</label>
        <input clrInput placeholder="" type="number" name="x" min="0" max="1000" [(ngModel)]="config.x" />
      </clr-input-container>
      <clr-input-container>
        <label>Y</label>
        <input clrInput placeholder="" type="number" name="y" min="0" max="1000" [(ngModel)]="config.y" />
      </clr-input-container>
      <clr-input-container>
        <label>Rows</label>
        <input clrInput placeholder="" type="number" name="rows" min="0" max="1000" [(ngModel)]="config.rows" />
      </clr-input-container>
      <clr-input-container>
        <label>Cols</label>
        <input clrInput placeholder="" type="number" name="cols" min="0" max="1000" [(ngModel)]="config.cols" />
      </clr-input-container>
      <clr-input-container *ngIf="gridSettings.AllowMultiLayer">
        <label>Layer</label>
        <input clrInput placeholder="" type="number" name="layerIndex" min="0" [max]="gridSettings.MaxLayerIndex"
          [(ngModel)]="config.layerIndex" />
      </clr-input-container>
      <clr-input-container>
        <label>Background color</label>
        <input clrInput placeholder="" name="backgroundcolor" type="text" [(ngModel)]="config.BackgroundColor" />
        <input clrInput placeholder="" name="backgroundcolor2" type="color" [(ngModel)]="config.BackgroundColor"
          style="margin-left: 1em;" />
      </clr-input-container>
      <clr-input-container>
        <label>Border color</label>
        <input clrInput placeholder="" name="bordercolor" type="text" [(ngModel)]="config.BorderColor" />
        <input clrInput placeholder="" name="bordercolor2" type="color" [(ngModel)]="config.BorderColor"
          style="margin-left: 1em;" />
      </clr-input-container>
      <clr-input-container>
        <label>Border width (px)</label>
        <input clrInput placeholder="" type="number" name="borderwith" min="0" max="1000"
          [(ngModel)]="config.BorderWidth" />
      </clr-input-container>
      <clr-input-container>
        <label>Border radius (px)</label>
        <input clrInput placeholder="" type="number" name="borderradius" min="0" max="1000"
          [(ngModel)]="config.BorderRadius" />
      </clr-input-container>

    <clr-input-container>
      <label>Box-shadow color</label>
      <input clrInput placeholder="" name="boxshadowcolor" type="text" [(ngModel)]="config.BoxShadowColor" />
      <input clrInput placeholder="" name="boxshadowcolor2" type="color" [(ngModel)]="config.BoxShadowColor"
        style="margin-left: 1em;" />
    </clr-input-container>
    <clr-input-container>
      <label>Box-shadow offset X(px)</label>
      <input clrInput placeholder="" type="number" name="BoxShadowOffsetX" min="-1000" max="1000"
        [(ngModel)]="config.BoxShadowOffsetX" />
    </clr-input-container>
    <clr-input-container>
      <label>Box-shadow offset Y (px)</label>
      <input clrInput placeholder="" type="number" name="BoxShadowOffsetY" min="-1000" max="1000"
        [(ngModel)]="config.BoxShadowOffsetY" />
    </clr-input-container>
    <clr-input-container>
      <label>Box-shadow blur radius (px)</label>
      <input clrInput placeholder="" type="number" name="BoxShadowBlurRadius" min="-1000" max="1000"
        [(ngModel)]="config.BoxShadowBlurRadius" />
    </clr-input-container>
    <clr-input-container>
      <label>Box-shadow spread radius (px)</label>
      <input clrInput placeholder="" type="number" name="BoxShadowSpreadRadius" min="-1000" max="1000"
        [(ngModel)]="config.BoxShadowSpreadRadius" />
    </clr-input-container>

    <clr-input-container>
      <label>Blur</label>
      <input clrInput placeholder="" type="number" name="BlurPX" min="-1000" max="1000"
        [(ngModel)]="config.BlurPX" />
    </clr-input-container>
  </clr-accordion-content>
  </clr-accordion-panel>
</clr-accordion>

<!-- <br *ngIf="showInputParameters"> -->
<clr-accordion *ngIf="showInputParameters">
  <clr-accordion-panel>
    <clr-accordion-title>Input parameters</clr-accordion-title>
    <clr-accordion-content>
      <clr-datagrid [(clrDgSingleSelected)]="selectedInputParameter">
        <clr-dg-column>Id</clr-dg-column>
        <clr-dg-column>Widget</clr-dg-column>
        <clr-dg-column>Output parameter</clr-dg-column>
        <clr-dg-column>Mandatory</clr-dg-column>
        <clr-dg-column>Invert</clr-dg-column>
        <clr-dg-column>Init value</clr-dg-column>
        <clr-dg-column *ngIf="showInputParametersAction">Action</clr-dg-column>

        <clr-dg-row *ngFor="let input of inputParameters; let i = index" [clrDgItem]="input">
          <clr-dg-cell>
            <input class="clr-input" placeholder="" name="inputwidget_id_{{i}}" id=inputwidget_id{{i}}
              [(ngModel)]="input.inputParameter.id" />
          </clr-dg-cell>

          <clr-dg-cell>
            <div class="clr-select-wrapper">
              <select class="clr-select" name="inputwidget_widget{{i}}" id="inputwidget_widget{{i}}"
                [(ngModel)]="input.inputParameter.widgetId" (ngModelChange)="onWidgetSelect(input, $event)">
                <option [ngValue]="'__dashboard__'">Dashboard</option>
                <option *ngFor="let widget of widgets" [ngValue]="widget.Id">
                  {{widget.Name?.length > 0 ? widget.Name : widget.Title}}</option>
              </select>
            </div>
          </clr-dg-cell>

          <clr-dg-cell>
            <div class="clr-select-wrapper">
              <select class="clr-select" name="inputwidget_output_{{i}}" id="inputwidget_output{{i}}"
                [(ngModel)]="input.inputParameter.widgetOutputParameterId"
                (ngModelChange)="onOutputSelect(input, $event)">
                <option *ngFor="let output of input.outputparameters" [ngValue]="output.id">
                  {{output.name}}</option>
              </select>
            </div>
          </clr-dg-cell>

          <clr-dg-cell>
            <input class="clr-checkbox" type="checkbox" placeholder="" name="inputwidget_mandatory{{i}}"
              [(ngModel)]="input.inputParameter.mandatory" />
          </clr-dg-cell>

          <clr-dg-cell>
            <input class="clr-checkbox" type="checkbox" placeholder="" name="inputwidget_invert{{i}}"
              [(ngModel)]="input.inputParameter.invertValue" [disabled]="input.inputParameter.datatype >= 6" />
          </clr-dg-cell>

          <clr-dg-cell>
            <input class="clr-input" placeholder="" name="inputwidget_init_{{i}}" id=inputwidget_init{{i}}
              [(ngModel)]="input.inputParameter.initValue" />
          </clr-dg-cell>

          <clr-dg-cell *ngIf="showInputParametersAction">
            <div class="clr-select-wrapper">
              <select class="clr-select" name="inputwidget_action{{i}}" id="inputwidget_action{{i}}"
                [(ngModel)]="input.inputParameter.action" (ngModelChange)="onActionSelect(input, $event)">
                <option [ngValue]="WidgetInputParameterAction.Init">Init</option>
                <option [ngValue]="WidgetInputParameterAction.Update">Update</option>
              </select>
            </div>
          </clr-dg-cell>

        </clr-dg-row>
      </clr-datagrid>

      <button type="button" class="btn btn-link" (click)="addInputParameter()">
        <clr-icon shape="plus-circle"></clr-icon>
        Add input parameter
      </button>
      <button *ngIf="selectedInputParameter" type="button" class="btn btn-link" (click)="deleteSelectedParameter()">
        <clr-icon shape="minus-circle"></clr-icon>
        Remove selected parameter
      </button>
    </clr-accordion-content>
  </clr-accordion-panel>
</clr-accordion>

<clr-accordion *ngIf="showPreQuery">
  <clr-accordion-panel>
    <clr-accordion-title>Pre queries config</clr-accordion-title>
    <clr-accordion-content>
      <form clrForm>
        <clr-input-container>
          <label class="clr-col-4">Max concurrent requests</label>
          <input class="clr-col-4" clrInput placeholder="" type="number" name="maxconcurrentrequests" min="1" max="10"
            [(ngModel)]="config.maxConcurrentRequests" />
        </clr-input-container>
      </form>

      <clr-datagrid [(clrDgSingleSelected)]="selectedPreQueryConfig">
        <clr-dg-column>Query</clr-dg-column>
        <clr-dg-column>Level</clr-dg-column>

        <clr-dg-row *ngFor="let queryConfig of config.WidgetPreQueryConfigs; let i = index" [clrDgItem]="queryConfig">
          <clr-dg-cell>
            {{queryConfig.Name}}
          </clr-dg-cell>
          <clr-dg-cell>
            {{queryConfig.Level}}
          </clr-dg-cell>
        </clr-dg-row>
      </clr-datagrid>

      <button type="button" class="btn btn-link" (click)="addPreQuery()">
        <clr-icon shape="plus-circle"></clr-icon>
        Add query
      </button>
      <button *ngIf="selectedPreQueryConfig" type="button" class="btn btn-link" (click)="removeSelectedPreQuery()">
        <clr-icon shape="minus-circle"></clr-icon>
        Remove selected query
      </button>

      <xproj-widget-prequery-config *ngIf="selectedPreQueryConfig" #preQueryConfig
        [widgetQuery]="selectedPreQueryConfig" [projections]="projections" [widgets]="widgets"
        [getInputParameters]="getInputParameters" (save)="onPreQueryConfigSaved($event)">
      </xproj-widget-prequery-config>

    </clr-accordion-content>
  </clr-accordion-panel>
</clr-accordion>
