<clr-main-container>
    <app-alert></app-alert>

    <clr-header class="header-6" *ngIf="user">
        <div class="branding">
            <a *ngIf="state.isAdminUser" [routerLink]="['/']" class="nav-link">
                <img src="{{logo}}" class="logocls" />
                <span class="title" *ngIf="title != ''">{{title}}</span>
            </a>
            <a *ngIf="!state.isAdminUser" [routerLink]="['/customers']" class="nav-link">
                <img src="{{logo}}" class="logocls" />
                <!-- <span class="title">{{state.customerName}}</span> -->
            </a>
        </div>
        <!-- <div class="header-nav" [clr-nav-level]="1" *ngIf="!state.isAdminUser && state.isCustomerAdmin">
      <a [routerLink]="['/bmscustomer/events']" class="nav-link">
        <clr-icon shape="bell" size="lg"></clr-icon>
      </a>
    </div> -->
        <div class="header-nav" [clr-nav-level]="1" *ngIf="!state.isAdminUser && state.isCustomerAdmin && state.sandboxEnabled">
            <a *ngIf="state.isCustomerAdmin && state.sandboxEnabled" [routerLink]="['/dashboard']" routerLinkActive="active" class="nav-link nav-text">Sandbox</a>
        </div>
        <div class="header-nav" style="padding-left:36px;" [clr-nav-level]="1" *ngIf="state.isAdminUser">
            <!-- <a [routerLink]="['/sysadmin']" routerLinkActive="active" class="nav-link nav-text"
        [routerLinkActiveOptions]="{exact:true}">Admin</a> -->

            <a [routerLink]="['/rossakerbmsadmin']" routerLinkActive="active" class="nav-link nav-text boldtext"> <span class="small">Admin</span> ACCOUNTS</a>
            <a [routerLink]="['/rossakerbmstrusteeadmin']" routerLinkActive="active" class="nav-link nav-text boldtext"> <span class="small ">Admin</span> TRUSTEES</a>
            <a [routerLink]="[ '/dashboard'] " routerLinkActive="active " class="nav-link nav-text boldtext"> <span class="small">Insights</span> SANDBOX</a>
            <a [routerLink]="['/xconfdashboard']" routerLinkActive="active" class="nav-link nav-text boldtext"><span class="small ">Insights</span>BUILDER</a>
            <a [routerLink]="[ '/customers'] " routerLinkActive="active " class="nav-link nav-text boldtext"><span class="small">Insights</span>PORTAL</a>
            <a [routerLink]="['/xprojector']" routerLinkActive="active" class="nav-link nav-text boldtext"><span class="small ">Advanced</span>DATA</a>
            <a [routerLink]="['/xautomation']" routerLinkActive="active" class="nav-link nav-text boldtext"><span class="small ">Advanced</span>AUTOMATION</a>
            <a [routerLink]="[ '/configuration'] " routerLinkActive="active " class="nav-link nav-text boldtext"><span class="small">Advanced</span>CONFIGURATION</a>
        </div>
        <div class="header-actions">
            <clr-dropdown [clrCloseMenuOnItemClick]="true" style="margin-right: 1em;">
                <button *ngIf="!isMobile" clrDropdownTrigger aria-label="Dropdown user button">
           <span class="username">{{state.username}}</span> <clr-icon shape="user" size="20"></clr-icon>
        </button>
                <button *ngIf="isMobile" clrDropdownTrigger aria-label="Dropdown user button">
          <clr-icon shape="user" size="20"></clr-icon>
        </button>
                <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
                    <!-- <label class="dropdown-header" aria-hidden="true">{{state.customerName}}</label> -->
                    <div aria-label="Dropdown header Action EditUser" clrDropdownItem *ngIf="!state.isAdminUser">
                        <a [routerLink]="['/userprofile']" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" style="color: var(--clr-dropdown-item-color);">
                            <clr-icon shape="user" size="16" style="color: var(--clr-dropdown-item-color);"></clr-icon>
                            <ng-container i18n='@@mainnav_profile'> Profile</ng-container>
                        </a>
                    </div>
                    <div aria-label="Dropdown header Action EditUser" clrDropdownItem *ngIf="state.isAdminUser">
                        <a [routerLink]="['/adminuserprofile']" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" style="color: var(--clr-dropdown-item-color);">
                            <clr-icon shape="user" size="16" style="color: var(--clr-dropdown-item-color);"></clr-icon>
                            <ng-container i18n='@@mainnav_profile'> Profile</ng-container>
                        </a>
                    </div>
                    <!-- <div aria-label="Dropdown header Action Users" clrDropdownItem *ngIf="state.isCustomerAdmin">
            <a [routerLink]="['/userprofiles']" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"
              style="color: var(--clr-dropdown-item-color);">
              <clr-icon shape="users" size="16" style="color: var(--clr-dropdown-item-color);"></clr-icon>
              <ng-container i18n='@@mainnav_users'> Users</ng-container>
            </a>
          </div> -->
                    <!-- <div aria-label="Dropdown header Action Customer Settings" clrDropdownItem *ngIf="state.isCustomerAdmin">
                        <a [routerLink]="['/customersettings']" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" style="color: var(--clr-dropdown-item-color);">
                            <clr-icon shape="users" size="16" style="color: var(--clr-dropdown-item-color);"></clr-icon> Customer settings
                        </a>
                    </div> -->
                    <div class="dropdown-divider" role="separator" aria-hidden="true"></div>
                    <div aria-label="Dropdown header Version" clrDropdownItem>
                        <clr-icon shape="info-circle" size="16" style="color: var(--clr-dropdown-item-color);"></clr-icon>v {{version}}
                    </div>
                    <div class="dropdown-divider" role="separator" aria-hidden="true"></div>
                    <div aria-label="Dropdown header Action Logout" clrDropdownItem (click)="logout()">
                        <clr-icon shape="logout" size="16" style="color: var(--clr-dropdown-item-color);"></clr-icon>
                        <ng-container i18n='@@mainnav_logout'> Logout</ng-container>
                    </div>
                </clr-dropdown-menu>
            </clr-dropdown>
        </div>
    </clr-header>

    <router-outlet>
    </router-outlet>

    <!-- <div class="clr-footer">
        <button *ngIf="!xprojClient.IsConnected" class="btn btn-primary" (click)="xprojClient.Connect()">Connect</button>
        <button *ngIf="xprojClient.IsConnected" class="btn btn-link" (click)="xprojClient.Disconnect()">Disconnect</button>
    </div> -->

</clr-main-container>