<div>
  <form clrForm>
    <clr-input-container>
      <label>Name</label>
      <input clrInput style="width: 75%;" placeholder="" name="name_{{widgetQuery.Id}}"
        [(ngModel)]="widgetQuery.Name" />
    </clr-input-container>

    <clr-select-container>
      <label>Projection</label>
      <select clrSelect id="useprojectioninput_{{widgetQuery.Id}}_id" name="useprojectioninput_{{widgetQuery.Id}}"
        [(ngModel)]="queryData.useProjectionInputParameter">
        <option [ngValue]="true"> Input </option>
        <option [ngValue]="false"> Projection </option>
      </select>

      <select clrSelect style="margin-left: 1em;" *ngIf="!queryData.useProjectionInputParameter"
        id="projection_{{widgetQuery.Id}}" name="projection_{{widgetQuery.Id}}" [(ngModel)]="queryData.projection"
        (ngModelChange)="selectedProjectionChange($event)">
        <option *ngFor="let proj of projections" [ngValue]="proj">{{proj.name| xproj_pretty}}</option>
      </select>

      <select clrSelect style="margin-left: 1em;" *ngIf="queryData.useProjectionInputParameter" class="clr-select"
        id="projectioninputParameter_{{widgetQuery.Id}}" name="projectioninputParameter_{{widgetQuery.Id}}"
        [(ngModel)]="queryData.projectionInputParameterId">
        <option *ngFor="let input of inputParameters | xproj_filterinputparameters : [OutputDataType.Projection]"
          [ngValue]="input.id">
          {{input.id}}</option>
      </select>
    </clr-select-container>

    <clr-select-container>
      <label>Input / Group</label>
      <select clrSelect id="usegroupinput_{{widgetQuery.Id}}" name="usegroupinput_{{widgetQuery.Id}}"
        [(ngModel)]="queryData.groupSelectionType">
        <option [ngValue]="GroupSelectionTypes.GROUP"> Group </option>
        <option [ngValue]="GroupSelectionTypes.GROUP_INPUT"> Input group </option>
        <option [ngValue]="GroupSelectionTypes.GROUP_INPUT_PARAMETERS"> Input parameters </option>

      </select>

      <select clrSelect *ngIf="queryData.groupSelectionType == GroupSelectionTypes.GROUP_INPUT" class="clr-select"
        name="GroupInputParameter_{{widgetQuery.Id}}" [(ngModel)]="queryData.groupInputParameterId">
        <option *ngFor="let input of inputParameters | xproj_filterinputparameters : [OutputDataType.Group]"
          [ngValue]="input.id">
          {{input.id}}</option>
      </select>
    </clr-select-container>

    <xproj-group-selection #groupSelect *ngIf="queryData.groupSelectionType == GroupSelectionTypes.GROUP"
      [projection]="queryData.projection" [selected]="widgetQuery.Query?.targetgroup"
      (groupSelected)="selectedProjectionGroupChange($event)">
    </xproj-group-selection>

    <clr-combobox-container *ngIf="queryData.groupSelectionType == GroupSelectionTypes.GROUP_INPUT_PARAMETERS">
      <label>Group input selections</label>
      <clr-combobox [(ngModel)]="queryData.groupInputParameterIds" name="multiSelect_{{widgetQuery.Id}}" clrMulti="true"
        required>
        <ng-container *clrOptionSelected="let selected">
          {{selected}}
        </ng-container>
        <clr-options>
          <clr-option
            *clrOptionItems="let input of inputParameters | xproj_filterinputparameters : [OutputDataType.String, OutputDataType.Number];  field:'id'"
            [clrValue]="input.id">
            {{input.id}}
          </clr-option>
        </clr-options>
      </clr-combobox>
    </clr-combobox-container>

  </form>

  <h5>Data</h5>

  <clr-toggle-container>
    <clr-toggle-wrapper>
      <input type="checkbox" clrToggle value="true" name="sampledata_{{widgetQuery.Id}}"
        [(ngModel)]="widgetQuery.SampleData" />
      <label>Sample data (add retrieved data as new values)</label>
    </clr-toggle-wrapper>
  </clr-toggle-container>
  <clr-input-container *ngIf="widgetQuery.SampleData">
    <label>Samplepoints</label>
    <input clrInput type="number" style="width: 75%;" placeholder="" name="SampleLength_{{widgetQuery.Id}}"
      [(ngModel)]="widgetQuery.SampleLength" />
  </clr-input-container>

  <form clrForm>
    <clr-select-container>
      <label>X Axis</label>
      <select clrSelect id="xaxis_{{widgetQuery.Id}}" name="xaxis_{{widgetQuery.Id}}" [(ngModel)]="queryData.xaxis">
        <option *ngFor="let col of queryData.columns | xproj_filternumericalcolumns : true " [ngValue]="col.columnname">
          {{col.columnname}}</option>
      </select>

      <select style="margin-left: 1em;" clrSelect id="usegrouping_{{widgetQuery.Id}}"
        name="usegrouping_{{widgetQuery.Id}}" [(ngModel)]="queryData.usegrouping">
        <option [ngValue]="true"> Group </option>
        <option [ngValue]="false">No grouping</option>
      </select>

      <select *ngIf="queryData.usegrouping" style="margin-left: 1em;" clrSelect
        id="usetransforminput_{{widgetQuery.Id}}" name="usetransforminput_{{widgetQuery.Id}}"
        [(ngModel)]="queryData.useTransformInputParameter">
        <option [ngValue]="true"> Input </option>
        <option [ngValue]="false"> Aggregation </option>
      </select>

      <select style="margin-left: 1em;" *ngIf="queryData.usegrouping && !queryData.useTransformInputParameter" clrSelect
        name="xaxisgrouptransform_{{widgetQuery.Id}}" id="xaxisgrouptransform_{{widgetQuery.Id}}"
        [(ngModel)]="queryData.xaxistransform">
        <option [ngValue]="Transformation.NONE"> None </option>
        <option [ngValue]="Transformation.TIMESTAMP_RESOLUTION_YEAR"> Year </option>
        <option [ngValue]="Transformation.TIMESTAMP_RESOLUTION_MONTH"> Month </option>
        <option [ngValue]="Transformation.TIMESTAMP_RESOLUTION_DAY"> Day </option>
        <option [ngValue]="Transformation.TIMESTAMP_RESOLUTION_HOUR"> Hour </option>
        <option [ngValue]="Transformation.TIMESTAMP_RESOLUTION_MINUTE"> Minute </option>
        <option [ngValue]="Transformation.TIMESTAMP_RESOLUTION_SECOND"> Second </option>
        <option [ngValue]="Transformation.TIMESTAMP_DAYOFYEAR"> Day of year </option>
        <option [ngValue]="Transformation.TIMESTAMP_DAYOFMONTH"> Day of month </option>
        <option [ngValue]="Transformation.TIMESTAMP_DAYOFWEEK"> Day of week </option>
        <option [ngValue]="Transformation.TIMESTAMP_SECONDS_OF_DAY"> Time of day - total seconds of day
        </option>
        <option [ngValue]="Transformation.TIMESTAMP_MINUTES_OF_DAY"> Time of day - total minutes of day
        </option>
        <option [ngValue]="Transformation.TIMESTAMP_HOURS"> Time of day - hours of day </option>
        <option [ngValue]="Transformation.TIMESTAMP_MINUTES"> Time of day - minutes of hour </option>
        <option [ngValue]="Transformation.TIMESTAMP_SECONDS"> Time of day - seconds of minute </option>
        <option [ngValue]="Transformation.FORWARD_DIFF"> Forward diff </option>
      </select>

      <select clrSelect style="margin-left: 1em;" *ngIf="queryData.usegrouping && queryData.useTransformInputParameter"
        class="clr-select" name="UseTransformInputParameter_{{widgetQuery.Id}}"
        [(ngModel)]="queryData.transformInputParameterId">
        <option *ngFor="let input of inputParameters | xproj_filterinputparameters : [OutputDataType.Transformation]"
          [ngValue]="input.id">
          {{input.id}}</option>
      </select>
    </clr-select-container>

    <clr-select-container>
      <label>Split by</label>
      <select clrSelect id="splitby_{{widgetQuery.Id}}" name="splitby_{{widgetQuery.Id}}"
        [(ngModel)]="queryData.splitby">
        <option [ngValue]="null">None</option>
        <option *ngFor="let col of queryData.columns" [ngValue]="col.columnname">
          {{col.columnname}}</option>
      </select>
    </clr-select-container>

    <clr-checkbox-container clrInline>
      <label>Stack</label>

      <clr-checkbox-wrapper>
        <input type="checkbox" clrCheckbox value="true" name="stackleft_{{widgetQuery.Id}}"
          [(ngModel)]="queryData.stackLeft" />
        <label>Left Axis</label>
      </clr-checkbox-wrapper>
      <clr-checkbox-wrapper>
        <input type="checkbox" clrCheckbox value="true" name="stackright_{{widgetQuery.Id}}"
          [(ngModel)]="queryData.stackRight" />
        <label>Right Axis</label>
      </clr-checkbox-wrapper>
    </clr-checkbox-container>


    <clr-select-container>
      <label>Time filter column</label>
      <select clrSelect id="timefiltercolumn_{{widgetQuery.Id}}" name="timefiltercolumn_{{widgetQuery.Id}}"
        [(ngModel)]="queryData.timefiltercolumn">
        <option *ngFor="let col of queryData.columns | xproj_filtertimestampcolumns" [ngValue]="col.columnname">
          {{col.columnname}}</option>
      </select>
    </clr-select-container>

    <clr-select-container>
      <label>Input array mode</label>
      <select clrSelect id="inputarraymode_{{widgetQuery.Id}}" name="inputarraymode_{{widgetQuery.Id}}"
        [(ngModel)]="queryData.inputArrayMode">
        <option [ngValue]="InputArrayMode.OR_FILTER">OR_FILTER</option>
        <option [ngValue]="InputArrayMode.AND_FILTER">AND_FILTER</option>
        <option [ngValue]="InputArrayMode.MULTIPLE_QUERIES">MULTIPLE_QUERIES</option>
      </select>
    </clr-select-container>

    <clr-input-container>
      <label>Max Items</label>
      <input clrInput placeholder="" type="number" name="maxitems_{{widgetQuery.Id}}" min="1" max="100000"
        [(ngModel)]="queryData.maxItems" />
    </clr-input-container>


    <clr-datagrid [(clrDgSingleSelected)]="selectedYAxis">
      <clr-dg-column>Column</clr-dg-column>
      <clr-dg-column>Input / Aggregation</clr-dg-column>
      <clr-dg-column>Label</clr-dg-column>
      <clr-dg-column>Input / Unit</clr-dg-column>
      <clr-dg-column>Axis</clr-dg-column>
      <clr-dg-column>Type</clr-dg-column>
      <clr-dg-column>Move</clr-dg-column>

      <clr-dg-row *ngFor="let column of queryData.yAxesConfigs; let i = index" [clrDgItem]="column">
        <clr-dg-cell>
          <div class="clr-select-wrapper">
            <select *ngIf="!queryData.usegrouping" class="clr-select" name="queryData.xaxis_{{widgetQuery.Id}}{{i}}"
              id="yaxis_{{widgetQuery.Id}}{{i}}" [(ngModel)]="queryData.yaxises[i]"
              (ngModelChange)="onYAxisSelect($event, i)">
              <option *ngFor="let col of queryData.columns | xproj_filternumericalcolumns : false"
                [ngValue]="col.columnname">
                {{col.columnname}}</option>
              <option *ngFor="let col of queryData.preQueryColumnConfigs" [ngValue]="col.columnname">
                {{col.columnname}}</option>
              <option *ngFor="let col of queryData.scriptedcolumnspostaggregation"
                [ngValue]="'script:' + col.columnoutname">
                script:{{col.columnoutname}}</option>
            </select>
            <select *ngIf="queryData.usegrouping" class="clr-select" name="queryData.xaxis_{{widgetQuery.Id}}{{i}}"
              id="yaxis_{{widgetQuery.Id}}{{i}}" [(ngModel)]="queryData.yaxises[i]"
              (ngModelChange)="onYAxisSelect($event, i)">
              <option *ngFor="let col of queryData.columns" [ngValue]="col.columnname">
                {{col.columnname}}</option>
              <option *ngFor="let col of queryData.preQueryColumnConfigs" [ngValue]="col.columnname">
                {{col.columnname}}</option>
              <option *ngFor="let col of queryData.scriptedcolumnspostaggregation"
                [ngValue]="'script:' + col.columnoutname">
                script:{{col.columnoutname}}</option>
            </select>
          </div>
        </clr-dg-cell>
        <clr-dg-cell>
          <div class="clr-select-wrapper">
            <input class="clr-checkbox" type="checkbox" placeholder="" name="AggregationInput_{{widgetQuery.Id}}{{i}}"
              [(ngModel)]="queryData.yAxesConfigs[i].UseAggregationInputParameter"
              [disabled]="!queryData.usegrouping" />
            <select *ngIf="!queryData.yAxesConfigs[i].UseAggregationInputParameter" class="clr-select"
              style="width:13em;" name="queryData.yaxisagg_{{widgetQuery.Id}}{{i}}" [disabled]="!queryData.usegrouping"
              [(ngModel)]="queryData.yaxisesAggregation[i]">
              <option [ngValue]="Aggregation.NONE"> None </option>
              <option [ngValue]="Aggregation.COUNT"> Count </option>
              <option [ngValue]="Aggregation.DISTINCT_COUNT"> Distinct Count </option>
              <option [ngValue]="Aggregation.SUM"> Sum </option>

              <option [ngValue]="Aggregation.MAX"> Max </option>
              <option [ngValue]="Aggregation.MIN"> Min </option>

              <option [ngValue]="Aggregation.FIRST"> First </option>
              <option [ngValue]="Aggregation.LAST"> Last </option>

              <option [ngValue]="Aggregation.FORWARD_DIFF"> Forward diff </option>

              <option [ngValue]="Aggregation.MEAN_ARITHMETIC"> Mean - arithmetic </option>
              <option [ngValue]="Aggregation.MEAN_GEOMETRIC"> Mean - geometric </option>
              <option [ngValue]="Aggregation.MEAN_HARMONIC"> Mean - harmonic </option>

              <option [ngValue]="Aggregation.MEDIAN"> Median </option>

              <option [ngValue]="Aggregation.SD_SAMPLED_UNCORRECTED"> Standard deviation - uncorrected </option>
              <option [ngValue]="Aggregation.SD_SAMPLED_CORRECTED"> Standard deviation - corrected </option>
              <option [ngValue]="Aggregation.SD_SAMPLED_UNBIASED_APPROX_ND"> Standard deviation - unbiased approx
              </option>

              <option [ngValue]="Aggregation.MAD_ORIGO_MEAN_ARITHMETIC"> Mean absolute deviation - around
                arithmetic mean </option>
              <option [ngValue]="Aggregation.MAD_ORIGO_MEAN_GEOMETRIC"> Mean absolute deviation - around geometric
                mean </option>
              <option [ngValue]="Aggregation.MAD_ORIGO_MEAN_HARMONIC"> Mean absolute deviation - around harmonic
                mean </option>
              <option [ngValue]="Aggregation.MAD_ORIGO_MEDIAN"> Mean absolute deviation - around median </option>
            </select>
            <select *ngIf="queryData.yAxesConfigs[i].UseAggregationInputParameter" class="clr-select"
              style="width:13em;" name="Aggregationinputparam_{{widgetQuery.Id}}{{i}}"
              [(ngModel)]="queryData.yAxesConfigs[i].AggregationInputParameterId" [disabled]="!queryData.usegrouping">
              <option *ngFor="let input of inputParameters | xproj_filterinputparameters : [OutputDataType.Aggregation]"
                [ngValue]="input.id">
                {{input.id}}</option>
            </select>
          </div>
        </clr-dg-cell>

        <clr-dg-cell>
          <input class="clr-input" placeholder="" name="tlabel_{{widgetQuery.Id}}{{i}}"
            [(ngModel)]="queryData.yAxesConfigs[i].Label" />
        </clr-dg-cell>

        <clr-dg-cell>
          <div class="clr-select-wrapper">
            <input class="clr-checkbox" type="checkbox" placeholder="" name="UnitInput_{{widgetQuery.Id}}{{i}}"
              [(ngModel)]="queryData.yAxesConfigs[i].UseUnitInputParameter" />

            <input *ngIf="!queryData.yAxesConfigs[i].UseUnitInputParameter" class="clr-input" placeholder=""
              name="unit_{{widgetQuery.Id}}{{i}}" [(ngModel)]="queryData.yAxesConfigs[i].Unit" />

            <select *ngIf="queryData.yAxesConfigs[i].UseUnitInputParameter" class="clr-select" style="width:13em;"
              name="unitinputparam_{{widgetQuery.Id}}{{i}}"
              [(ngModel)]="queryData.yAxesConfigs[i].UnitInputParameterId">
              <option *ngFor="let input of inputParameters | xproj_filterinputparameters : [OutputDataType.String]"
                [ngValue]="input.id">
                {{input.id}}</option>
            </select>
          </div>
        </clr-dg-cell>

        <clr-dg-cell>
          <div class="clr-select-wrapper">
            <select class="clr-select" style="width:5em;" name="yaxisid_{{widgetQuery.Id}}{{i}}"
              [(ngModel)]="queryData.yAxesConfigs[i].AxisId">
              <option [ngValue]="YAxisId.LEFT"> Left </option>
              <option [ngValue]="YAxisId.RIGHT"> Right </option>
            </select>
          </div>
        </clr-dg-cell>

        <clr-dg-cell>
          <div class="clr-select-wrapper">
            <select class="clr-select" style="width:5em;" name="charttype_{{widgetQuery.Id}}{{i}}"
              [(ngModel)]="queryData.yAxesConfigs[i].Type">
              <option [ngValue]="'line'"> Line </option>
              <option [ngValue]="'bar'"> Bar </option>
              <option [ngValue]="'scatter'"> Scatter </option>
              <!-- <option [ngValue]="'horizontalBar'"> Horizontal Bar </option> -->
            </select>
          </div>
        </clr-dg-cell>

        <clr-dg-cell>
          <clr-icon (click)="moveColumnUp(i);" shape="circle-arrow"></clr-icon>
          <clr-icon (click)="moveColumnDown(i);" shape="circle-arrow"
            style="margin-left: 1em; transform: rotate(180deg);"></clr-icon>
        </clr-dg-cell>
      </clr-dg-row>

    </clr-datagrid>
    <button type="button" class="btn btn-link" (click)="addYaxis()">
      <clr-icon shape="plus-circle"></clr-icon>
      Add y-axis
    </button>
    <button *ngIf="selectedYAxis" type="button" class="btn btn-link" (click)="removeSelectedYAxis()">
      <clr-icon shape="minus-circle"></clr-icon>
      Remove selected y-axis
    </button>

    <div *ngIf="selectedYAxis" style="max-width: 600px;">
      <div class="card">
        <div class="card-header">
          Editing column
        </div>

        <div class="card-block">
          <div class="card-title">
            {{selectedYAxis.ColumnOutName}}
          </div>
          <form clrForm>
            <clr-select-container>
              <label>Transformation</label>
              <select clrSelect name="transformation" [(ngModel)]="selectedYAxis.Transformation">
                <option [ngValue]="Transformation.NONE"> None </option>
                <option [ngValue]="Transformation.TIMESTAMP_RESOLUTION_YEAR"> Year </option>
                <option [ngValue]="Transformation.TIMESTAMP_RESOLUTION_MONTH"> Month </option>
                <option [ngValue]="Transformation.TIMESTAMP_RESOLUTION_DAY"> Day </option>
                <option [ngValue]="Transformation.TIMESTAMP_RESOLUTION_HOUR"> Hour </option>
                <option [ngValue]="Transformation.TIMESTAMP_RESOLUTION_MINUTE"> Minute </option>
                <option [ngValue]="Transformation.TIMESTAMP_RESOLUTION_SECOND"> Second </option>
                <option [ngValue]="Transformation.TIMESTAMP_DAYOFYEAR"> Day of year </option>
                <option [ngValue]="Transformation.TIMESTAMP_DAYOFMONTH"> Day of month </option>
                <option [ngValue]="Transformation.TIMESTAMP_DAYOFWEEK"> Day of week </option>
                <option [ngValue]="Transformation.TIMESTAMP_SECONDS_OF_DAY"> Time of day - total seconds of day
                </option>
                <option [ngValue]="Transformation.TIMESTAMP_MINUTES_OF_DAY"> Time of day - total minutes of day
                </option>
                <option [ngValue]="Transformation.TIMESTAMP_HOURS"> Time of day - hours of day </option>
                <option [ngValue]="Transformation.TIMESTAMP_MINUTES"> Time of day - minutes of hour </option>
                <option [ngValue]="Transformation.TIMESTAMP_SECONDS"> Time of day - seconds of minute </option>
                <option [ngValue]="Transformation.FORWARD_DIFF"> Forward diff </option>
                <option [ngValue]="Transformation.ABSOLUTE_VALUE"> Absolute value </option>
              </select>
            </clr-select-container>
            <clr-checkbox-container>
              <clr-checkbox-wrapper>
                <input type="checkbox" clrCheckbox name="stepped" [(ngModel)]="selectedYAxis.Stepped" />
                <label>Stepped</label>
              </clr-checkbox-wrapper>
            </clr-checkbox-container>
            <clr-select-container *ngIf="selectedYAxis.Stepped">
              <label>Stepp mode</label>
              <select clrSelect name="transformation" [(ngModel)]="selectedYAxis.SteppedMode">
                <option [ngValue]="'before'"> Before </option>
                <option [ngValue]="'after'"> After </option>
                <option [ngValue]="'middle'"> Middle </option>
              </select>
            </clr-select-container>
            <clr-checkbox-container>
              <clr-checkbox-wrapper>
                <input type="checkbox" clrCheckbox name="hide" [(ngModel)]="selectedYAxis.Hide"/>
                <label>Hide</label>
              </clr-checkbox-wrapper>
            </clr-checkbox-container>
            <clr-checkbox-container>
              <clr-checkbox-wrapper>
                <input type="checkbox" clrCheckbox name="hideifempty" [(ngModel)]="selectedYAxis.HideIfEmpty" />
                <label>Hide if empty</label>
              </clr-checkbox-wrapper>
            </clr-checkbox-container>
            <clr-checkbox-container>
              <clr-checkbox-wrapper>
                <input type="checkbox" clrCheckbox name="fill" [(ngModel)]="selectedYAxis.Fill" />
                <label>Fill</label>
              </clr-checkbox-wrapper>
            </clr-checkbox-container>
            <clr-checkbox-container>
              <clr-checkbox-wrapper>
                <input type="checkbox" clrCheckbox name="usecolorscheme" [(ngModel)]="selectedYAxis.UseColorSchema" />
                <label>Use colorschema</label>
              </clr-checkbox-wrapper>
            </clr-checkbox-container>
            <clr-input-container>
              <label>Border Color</label>
              <input clrInput placeholder="" name="bordercolor" type="text" [(ngModel)]="selectedYAxis.BorderColor"
                [disabled]="selectedYAxis.UseColorSchema" />
              <input clrInput placeholder="" name="bordercolor2" type="color" [(ngModel)]="selectedYAxis.BorderColor"
                [disabled]="selectedYAxis.UseColorSchema" style="margin-left: 1em;" />
            </clr-input-container>
            <clr-input-container>
              <label>Background Color</label>
              <input clrInput placeholder="" name="backgroundcolor" type="text"
                [(ngModel)]="selectedYAxis.BackgroundColor" [disabled]="selectedYAxis.UseColorSchema" />
              <input clrInput placeholder="" name="backgroundcolor2" type="color"
                [(ngModel)]="selectedYAxis.BackgroundColor" [disabled]="selectedYAxis.UseColorSchema"
                style="margin-left: 1em;" />
            </clr-input-container>
            <clr-input-container>
              <label>Point Border Color</label>
              <input clrInput placeholder="" name="pointbordercolor" type="text"
                [(ngModel)]="selectedYAxis.PointBorderColor" [disabled]="selectedYAxis.UseColorSchema" />
              <input clrInput placeholder="" name="pointbordercolor2" type="color"
                [(ngModel)]="selectedYAxis.PointBorderColor" [disabled]="selectedYAxis.UseColorSchema"
                style="margin-left: 1em;" />
            </clr-input-container>
            <clr-input-container>
              <label>Point Background Color</label>
              <input clrInput placeholder="" name="pointbackgroundcolor" type="text"
                [(ngModel)]="selectedYAxis.PointBackgroundColor" [disabled]="selectedYAxis.UseColorSchema" />
              <input clrInput placeholder="" name="pointbackgroundcolor2" type="color"
                [(ngModel)]="selectedYAxis.PointBackgroundColor" [disabled]="selectedYAxis.UseColorSchema"
                style="margin-left: 1em;" />
            </clr-input-container>
            <clr-input-container>
              <label>Tension</label>
              <input clrInput placeholder="" type="number" name="tension" min="0" max="1000"
                [(ngModel)]="selectedYAxis.Tension" />
            </clr-input-container>
            <clr-input-container>
              <label>Border width</label>
              <input clrInput placeholder="" type="number" name="borderwidth" min="0" max="1000"
                [(ngModel)]="selectedYAxis.BorderWidth" />
            </clr-input-container>
            <clr-input-container>
              <label>Border radius</label>
              <input clrInput placeholder="" type="number" name="borderradius" min="0" max="1000"
                [(ngModel)]="selectedYAxis.BorderRadius" />
            </clr-input-container>
            <clr-input-container>
              <label>Point radius</label>
              <input clrInput placeholder="" type="number" name="pointradius" min="0" max="1000"
                [(ngModel)]="selectedYAxis.PointRadius" />
            </clr-input-container>
            <clr-input-container>
              <label>Border dash (line / gap)</label>
              <input clrInput placeholder="" type="number" name="borderdash1" min="0" max="1000"
                [(ngModel)]="selectedYAxis.BorderDash[0]" />
              <input clrInput placeholder="" type="number" name="borderdash2" min="0" max="1000"
                [(ngModel)]="selectedYAxis.BorderDash[1]" />
            </clr-input-container>
          </form>
        </div>
      </div>
    </div>

    <!--
		<div *ngFor="let yaxis of queryData.yaxises ; let i = index">
			<clr-select-container>
				<label>Y Axis {{i+1}}</label>
				<select clrSelect name="queryData.xaxis{{i}}" id="yaxis_{{i}}" [(ngModel)]="queryData.yaxises[i]"
					(ngModelChange)="onYAxisSelect($event, i)">
					<option *ngFor="let col of queryData.columns | xproj_filternumericalcolumns : false"
						[ngValue]="col.columnname">
						{{col.columnname}}</option>
				</select>

				<select style="margin-left: 1em;" *ngIf="queryData.usegrouping" clrSelect name="queryData.yaxisagg{{i}}"
					[(ngModel)]="queryData.yaxisestransforms[i]">
					<option [ngValue]="Aggregation.NONE"> None </option>
					<option [ngValue]="Aggregation.COUNT"> Count </option>
					<option [ngValue]="Aggregation.SUM"> Sum </option>

					<option [ngValue]="Aggregation.MAX"> Max </option>
					<option [ngValue]="Aggregation.MIN"> Min </option>

					<option [ngValue]="Aggregation.FIRST"> First </option>
					<option [ngValue]="Aggregation.LAST"> Last </option>


					<option [ngValue]="Aggregation.MEAN_ARITHMETIC"> Mean - arithmetic </option>
					<option [ngValue]="Aggregation.MEAN_GEOMETRIC"> Mean - geometric </option>
					<option [ngValue]="Aggregation.MEAN_HARMONIC"> Mean - harmonic </option>

					<option [ngValue]="Aggregation.MEDIAN"> Median </option>

					<option [ngValue]="Aggregation.SD_SAMPLED_UNCORRECTED"> Standard deviation - uncorrected </option>
					<option [ngValue]="Aggregation.SD_SAMPLED_CORRECTED"> Standard deviation - corrected </option>
					<option [ngValue]="Aggregation.SD_SAMPLED_UNBIASED_APPROX_ND"> Standard deviation - unbiased approx
					</option>

					<option [ngValue]="Aggregation.MAD_ORIGO_MEAN_ARITHMETIC"> Mean absolute deviation - around
						arithmetic mean </option>
					<option [ngValue]="Aggregation.MAD_ORIGO_MEAN_GEOMETRIC"> Mean absolute deviation - around geometric
						mean </option>
					<option [ngValue]="Aggregation.MAD_ORIGO_MEAN_HARMONIC"> Mean absolute deviation - around harmonic
						mean </option>
					<option [ngValue]="Aggregation.MAD_ORIGO_MEDIAN"> Mean absolute deviation - around median </option>
				</select>

			</clr-select-container>

			<clr-input-container>
				<label>Y Label {{i+1}}</label>
				<input clrInput placeholder="" name="label{{i}}" [(ngModel)]="queryData.yAxesConfigs[i].Label" />
			</clr-input-container>
			<clr-select-container>
				<label>Y Axis Id {{i+1}}</label>
				<select clrSelect name="yaxisid{{i}}" [(ngModel)]="queryData.yAxesConfigs[i].AxisId">
					<option [ngValue]="YAxisId.LEFT"> Left </option>
					<option [ngValue]="YAxisId.RIGHT"> Right </option>
				</select>
			</clr-select-container>
			<clr-select-container>
				<label>Chart type {{i+1}}</label>
				<select clrSelect name="charttype" [(ngModel)]="queryData.yAxesConfigs[i].Type">
					<option [ngValue]="'line'"> Line </option>
					<option [ngValue]="'bar'"> Bar </option>
				</select>
			</clr-select-container>
			<clr-input-container>
				<label>Unit {{i+1}}</label>
				<input clrInput placeholder="" name="unit{{i}}" [(ngModel)]="queryData.yAxesConfigs[i].Unit" />
			</clr-input-container>

			<button class="btn btn-danger-outline" (click)="removeYAxis(i)">Remove Y Axis {{i}}</button>
		</div> -->
  </form>
  <!--
	<button type="button" class="btn btn-link" (click)="addYaxis()">
		<clr-icon shape="plus-circle"></clr-icon>
		Add y-axis
	</button> -->

  <h5>Filters</h5>
  <xproj-projection-filter #projectionFilter id="projectionFilter_{{widgetQuery.Id}}"
    [projectionid]="queryData.projection?.projectionid" [columnDescriptions]="queryData.columns"
    [group]="queryData.selectedGroup" [datafilters]="widgetQuery.DataFilters"
    [filterLogicalGroupType]="widgetQuery.FilterLogicalGroupType" [inputParameters]="inputParameters"
    [widgets]="widgets">
  </xproj-projection-filter>

  <br />

  <clr-accordion>
    <clr-accordion-panel *ngFor="let scriptedcolumn of queryData.scriptedcolumnspostaggregation; index as i">
      <clr-accordion-title>ScriptedColumn {{i+1}}</clr-accordion-title>
      <clr-accordion-content>
        <xproj-edit-lua-query-column [luaQueryColumn]="scriptedcolumn"
          (onQueryColumnRemoved)="onLuaQueryColumnRemoved($event)"></xproj-edit-lua-query-column>
      </clr-accordion-content>
    </clr-accordion-panel>
  </clr-accordion>
  <button type="button" class="btn btn-link" (click)="addScriptedColumnsPostAggregation();">
    <clr-icon shape="plus-circle"></clr-icon>
    Add scripted column
  </button>

  <br />
  <button class="btn btn-outline" (click)="duplicateQuery()">Duplicate query</button>
  <button class="btn btn-danger-outline" (click)="removeQuery()">Remove query</button>
</div>
