import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { UploadResult } from 'ngx-markdown-editor';
import { Subscription } from 'rxjs';
import { TypedJSON } from 'typedjson';
import { XprojGroupSelectionComponent } from '../../../filters/group-selection/xproj-group-selection.component';
import { XprojProjectionFilterComponent } from '../../../filters/projection-filter/xproj-projection-filter.component';
import { LOGGERSERVICE, XprojLoggerService } from '../../../logger/xproj-logger-service';
import { ArrayUtils } from '../../../utils/array-utils-service';
import { Aggregation, BaseQueryInputColumnDescription, ColumnGroupingDescription, LuaQueryColumn, Projection, ProjectionColumnDescription, Transformation, XDataType, XProjectorClient } from '../../../XProjector/xprojector-client-service';
import { ProjectionDataeditorWidgetUtils } from '../../projection-dataeditor/projection-dataeditor-utils-service';
import { WidgetConfigBase } from '../../widget-config-base';
import { GroupSelectionTypes, OutputDataType, WidgetConfig, WidgetInputParameter, WidgetPrQueryColumnConfig } from '../../widget-config-service';
import { XprojWidgetConfigComponent } from '../../widget-config/xproj-widget-config.component';
import { XprojWidgetService } from '../../xproj-widget-service';
import { TextColumnConfig, TextWidgetConfig, TextWidgetQuery } from './text-widget-config-service';
import { XprojTextWidgetQueryConfigComponent } from '../text-widget-query-config/text-widget-query-config.component'
import { MultiseriesLuaQueryColumn } from '../../table/table-widget-config/table-widget-config-service';

@Component({
  selector: 'xproj-text-widget-config',
  templateUrl: './xproj-text-widget-config.component.html',
  styleUrls: ['./xproj-text-widget-config.component.scss']
})
export class XprojTextWidgetConfigComponent extends WidgetConfigBase implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild("projectionFilter", { read: XprojProjectionFilterComponent, static: false }) projectionFilter: XprojProjectionFilterComponent;
  @ViewChild("groupSelect", { read: XprojGroupSelectionComponent, static: false }) groupSelect: XprojGroupSelectionComponent;
  @ViewChild("widgetBaseConfig", { read: XprojWidgetConfigComponent, static: false }) widgetBaseConfig: XprojWidgetConfigComponent;
  @ViewChildren(XprojTextWidgetQueryConfigComponent, { read: XprojTextWidgetQueryConfigComponent }) widgetQueryConfigs: QueryList<XprojTextWidgetQueryConfigComponent>;

  widgetConfig: TextWidgetConfig;

  editConfig: TextWidgetConfig;

  projection: Projection;
  projections: Projection[] = [];
  columns: ProjectionColumnDescription[] = [];
  queryableSelectedGroup: string[] = [];
  selectedColumnConfig: any = null;
  preQueryColumnConfigs : { columnname : string, columnConfig : WidgetPrQueryColumnConfig }[] = [];
  inputParameters: WidgetInputParameter[] = [];

  options = {
    showPreviewPanel: false,
    showBorder: true,          // Show editor component's border. Default is true
    hideIcons: ['FullScreen'],     // ['Bold', 'Italic', 'Heading', 'Refrence', 'Link', 'Image', 'Ul', 'Ol', 'Code', 'TogglePreview', 'FullScreen']. Default is empty
    usingFontAwesome5: false,   // Using font awesome with version 5, Default is false
    scrollPastEnd: 0,        // The option for ace editor. Default is 0
    enablePreviewContentClick: false,  // Allow user fire the click event on the preview panel, like href etc. Default is false
    resizable: true,           // Allow resize the editor
    //markedjsOpt?: MarkedjsOption  // The markedjs option, see https://marked.js.org/#/USING_ADVANCED.md#options
    // customRender?: {              // Custom markedjs render
    //   image?: Function     // Image Render
    //   table?: Function     // Table Render
    //   code?: Function      // Code Render
    //   listitem?: Function  // Listitem Render
    // }
  }

  mode = 'editor';


  Transformation = Transformation;
  Aggregation = Aggregation;
  OutputDataType = OutputDataType;
  GroupSelectionTypes = GroupSelectionTypes;
  XDataType = XDataType;

  constructor(public xprojClient: XProjectorClient,
    public widgetService: XprojWidgetService,
    @Inject(LOGGERSERVICE) private logger: XprojLoggerService,
    private cdr: ChangeDetectorRef) {
    super(xprojClient, widgetService);
    this.postRenderFunc = this.postRenderFunc.bind(this);
    this.preRenderFunc = this.preRenderFunc.bind(this);
  }

  async ngOnInit() {
    this.widgetConfig = this.config as TextWidgetConfig;

    this.editConfig = this.widgetConfig.Clone();

    if (this.projections?.length == 0) {
      this.projections = await this.xprojClient.RequestListQueryableProjections(0, 10000);
    }

    while (this.editConfig.MultiseriesConfig.JoinableColumns.length < this.editConfig.ConfigQueries.length) {
      this.editConfig.MultiseriesConfig.JoinableColumns.push('');
    }

  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  async ngAfterViewInit() {
    this.updateInputParameters();
  }

  indexTracker(index: number, value: any) {
    return index;
  }

  onQueryRemoved(query: TextWidgetQuery) {
    let i = this.editConfig.ConfigQueries.findIndex(q => q == query);
    this.editConfig.ConfigQueries = this.editConfig.ConfigQueries.filter(q => q != query);
    ArrayUtils.RemoveItemAt(this.editConfig.MultiseriesConfig.JoinableColumns, i);
  }

  addConfigQuery(): void {
    this.editConfig.ConfigQueries.push(new TextWidgetQuery());
    this.editConfig.MultiseriesConfig.JoinableColumns.push('');
  }

  addScriptedColumnsPostAggregation() {
    this.editConfig.MultiseriesConfig.ScriptedColumnsPostJoin.push(new MultiseriesLuaQueryColumn());
  }

  onLuaQueryColumnRemoved(queryColumn: LuaQueryColumn) {
    this.editConfig.MultiseriesConfig.ScriptedColumnsPostJoin = this.editConfig.MultiseriesConfig.ScriptedColumnsPostJoin.filter(q => q.Column != queryColumn);
  }

  async onSaveConfig() {

    this.widgetQueryConfigs.toArray().forEach(queryConfig => {
      queryConfig.SaveQuery();
    });

    this.widgetConfig.update(this.editConfig);
  }

  doUpload(files: Array<File>): Promise<Array<UploadResult>> {
    //console.log(files);
    let that = this;
    return new Promise((resolve, reject) => {
        let result: Array<UploadResult> = [];
        for (let file of files) {
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = function () {
            //console.log(reader.result);
            result.push({
              name: file.name,
              url: reader.result as string,
              isImg: file.type.indexOf('image') !== -1
            })
            resolve(result);
          };
          reader.onerror = function (error) {
            that.logger.error('Error: ', error);
          };

        }
    });
  }

  preRenderFunc(content: string) {
    //console.log('preRenderFunc');
    return content;
  }

  postRenderFunc(content: string) {
    //console.log('postRenderFunc', content);
    this.editConfig.Html = content;
    return content;
  }

  onPreviewDomChanged($event) {
    //console.log('onPreviewDomChanged');
  }

  onEditorLoaded($event) {
    //console.log('onEditorLoaded');
  }

  onWidgetConfigSaved(widgetConfig : WidgetConfig) {
    this.updateInputParameters();
  }

  updateInputParameters() {
    if (this.inputParameters) {
      let inputParameters = this.widgetBaseConfig.getInputParameters(-1);
      this.inputParameters.length = 0;
      inputParameters.forEach(inputParam => this.inputParameters.push(inputParam));
    }
    else {
      this.inputParameters = this.widgetBaseConfig.getInputParameters(-1);
    }

  }
}
