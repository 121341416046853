import { Component, OnInit, QueryList, ViewChild, ViewChildren, inject } from '@angular/core';
import { RossakerBmsCustomer } from '@app/core/models/rossaker-bms-customer';
import { RossakerStateService } from '@app/core/services/rossaker-state-service';
import { Customer } from '@xprojectorcore/xprojector_backend/proto/xprojector.grpc.models.pb';
import { GrpcDataSourceInstance, GrpcNodeType } from '@xprojectorcore/xprojector_backend/proto/xprojector.xconf.pb';
import { XProjectorXConfClient } from '@xprojectorcore/xprojector_backend/xprojector-xconf-client';
import { ConfigurationDataSourceComponent } from '@xprojectorfeatures/xconf/components/configuration-datasource/configuration-datasource.component';
import { XconfTreeNode } from '@xprojectorfeatures/xconf/models/xconf-tree-node';
import { SplitAreaDirective } from 'angular-split';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-rossaker-bms-settings',
  templateUrl: './rossaker-bms-settings.component.html',
  styleUrls: ['./rossaker-bms-settings.component.scss']
})
export class RossakerBmsSettingsComponent implements OnInit {
  //@ViewChild("bmsDataCollectorView", { read: BmsDataCollectorViewComponent, static: false }) bmsDataCollectorView: BmsDataCollectorViewComponent;
  @ViewChild("datasourceInstance", { read: ConfigurationDataSourceComponent, static: false }) datasourceInstance: ConfigurationDataSourceComponent;
  @ViewChildren(SplitAreaDirective) areasEl: QueryList<SplitAreaDirective>

  private xConfClient: XProjectorXConfClient = inject(XProjectorXConfClient);
  private rossakerState: RossakerStateService = inject(RossakerStateService);
  ngUnsubscribe = new Subject<void>();

  selectedCustomer: Customer;
  selectedBmsCustomer: RossakerBmsCustomer;

  rightPanelWidth : number = 300;

  datasources : GrpcDataSourceInstance[] = [];
  selectedDatasource : GrpcDataSourceInstance;

  constructor() { }

  ngOnInit() {
    this.rossakerState.customer$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(async (e) => {
      this.selectedCustomer = e.customer;
      this.selectedBmsCustomer = e.bmsCustomer;
    });


    let lsrightPanelWidth = Number.parseInt(localStorage.getItem("xprojector-customer-configuration-rightPanelWidth") || this.rightPanelWidth.toString());
    if (lsrightPanelWidth != this.rightPanelWidth) {
      this.rightPanelWidth = lsrightPanelWidth;
    }

    this.updateDatasources();
  }

  async updateDatasources() {
    this.datasources = await this.xConfClient.getDataSourceInstanceChildren(this.selectedBmsCustomer.customerId);
  }

  async selectedDatasourceChanged($event) {
    setTimeout(() => {
      this.datasourceInstance?.initDataSourceInstance();
    });
  }

  getPaneArea(order: number): SplitAreaDirective {
    let result: SplitAreaDirective = undefined;
    this.areasEl.forEach(area => {
      if (area.order == order) {
        result = area;
      }
    });

    return result;
  }

  async onSplitDragEnd($event) {
    let treePaneArea = this.getPaneArea(2);

    if (treePaneArea) {
      this.rightPanelWidth = treePaneArea.elRef.nativeElement.clientWidth;
      localStorage.setItem("xprojector-customer-configuration-rightPanelWidth", this.rightPanelWidth.toString());
    }

    //this.datasourceInstance?.setWidth(this.rightPanelWidth);
  }

  async onTreeNodeSelect(item: { treeNode: XconfTreeNode, nodeType: GrpcNodeType }) {
    //this.bmsDataCollectorView?.setDataNode(item.treeNode.node, this.rightPanelWidth, this.selectedBmsCustomer, this.selectedCustomer);
  }

}
