import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { XprojGroupSelectionComponent } from '../../../filters/group-selection/xproj-group-selection.component';
import { XprojProjectionFilterComponent } from '../../../filters/projection-filter/xproj-projection-filter.component';
import { ArrayUtils } from '../../../utils/array-utils-service';
import { Aggregation, BaseQueryInputColumnDescription, ColumnGroupingDescription, LuaQueryColumn, Projection, ProjectionColumnDescription, Transformation, XDataType, XProjectorClient } from '../../../XProjector/xprojector-client-service';
import { TypedJSON } from 'typedjson';
import { ColumnConfig, TableColorThreshold, TableWidgetConfig, TableWidgetQuery } from '../table-widget-config/table-widget-config-service';
import { GroupSelectionTypes, InputArrayMode, OutputDataType, WidgetConfig, WidgetInputParameter, WidgetPrQueryColumnConfig } from '../../widget-config-service';
import { ProjectionDataeditorWidgetUtils } from '../../projection-dataeditor/projection-dataeditor-utils-service';
import { EditMode } from '../../projection-dataeditor/projection-dataeditor-widget-config/projection-dataeditor-widget-config-service';
import { XprojWidgetConfigComponent } from '../../widget-config/xproj-widget-config.component';
import { LOGGERSERVICE, XprojLoggerService } from '../../../logger/xproj-logger-service';
import { ThisReceiver } from '@angular/compiler';

export class QueryProjectionData {
  projection: Projection;
  queryableSelectedGroup: string[] = [];
  columns: ProjectionColumnDescription[] = [];
  selectedGroup: string[] = [];
  usegrouping: boolean = false;
  groupingtransform: Transformation = Transformation.NONE;
  columnConfigs: ColumnConfig[] = [];
  timefiltercolumn: string;
  defaultSortColumnName : string;
  defaultSortDescending : boolean;
  useTransformInputParameter : boolean = false;
  transformInputParameterId : string = '';
  useProjectionInputParameter : boolean;
  projectionInputParameterId : string = '';
  groupSelectionType : GroupSelectionTypes = GroupSelectionTypes.GROUP;
  groupInputParameterId: string = '';
  groupInputParameterIds: string[] = [];
  scriptedcolumnspostaggregation : LuaQueryColumn[] = [];
  preQueryColumnConfigs : { columnname : string, columnConfig : WidgetPrQueryColumnConfig }[] = [];
}

@Component({
  selector: 'xproj-table-widget-query-config',
  templateUrl: './xproj-table-widget-query-config.component.html',
  styleUrls: ['./xproj-table-widget-query-config.component.scss']
})
export class XprojTableWidgetQueryConfigComponent implements OnInit {

  @ViewChild("projectionFilter", { read: XprojProjectionFilterComponent, static: false }) projectionFilter: XprojProjectionFilterComponent;
  @ViewChild("groupSelect", { read: XprojGroupSelectionComponent, static: false }) groupSelect: XprojGroupSelectionComponent;

  @Input() widgetQuery: TableWidgetQuery;
  @Input() projections: Projection[] = [];
  @Input() inputParameters: WidgetInputParameter[];
  @Input() widgetConfig: TableWidgetConfig;
  @Input() widgets: WidgetConfig[];

  @Output() onQueryRemoved = new EventEmitter<TableWidgetQuery>();

  queryData: QueryProjectionData = new QueryProjectionData();

  selectedThreshold : TableColorThreshold;

  selectedColumnConfig: any = null;
  Transformation = Transformation;
  Aggregation = Aggregation;
  OutputDataType = OutputDataType;
  GroupSelectionTypes = GroupSelectionTypes;
  XDataType = XDataType;
  InputArrayMode = InputArrayMode;

  get multiselectKey(): boolean {
    return this.widgetConfig.MultiSelectKeyColumnId == this.selectedColumnConfig?.Id;
  }
  set multiselectKey(value: boolean) {
    if (value) {
      this.widgetConfig.MultiSelectKeyColumnId = this.selectedColumnConfig?.Id
    }
    else if (this.widgetConfig.MultiSelectKeyColumnId == this.selectedColumnConfig?.Id) {
      this.widgetConfig.MultiSelectKeyColumnId = '';
    }
  }

  get multiselectValue(): boolean {
    return this.widgetConfig.MultiSelectValueColumnId == this.selectedColumnConfig?.Id;
  }
  set multiselectValue(value: boolean) {
    if (value) {
      this.widgetConfig.MultiSelectValueColumnId = this.selectedColumnConfig?.Id
    }
    else if (this.widgetConfig.MultiSelectValueColumnId == this.selectedColumnConfig?.Id) {
      this.widgetConfig.MultiSelectValueColumnId = '';
    }
  }

  constructor(
    @Inject(LOGGERSERVICE) private logger: XprojLoggerService,
    private xprojClient: XProjectorClient,
    private cdr: ChangeDetectorRef) {

  }

  ngOnInit() {
    this.queryData.selectedGroup = this.widgetQuery.Query.targetgroup
    this.queryData.columnConfigs = this.widgetQuery.ColumnConfigs;

    this.queryData.usegrouping = this.widgetQuery.UseGrouping;
    this.queryData.groupingtransform = this.widgetQuery.GroupingTransform;
    this.queryData.timefiltercolumn = this.widgetQuery.timestampColumnName;
    this.queryData.defaultSortColumnName = this.widgetQuery.defaultSortColumnName;
    this.queryData.defaultSortDescending = this.widgetQuery.defaultSortDescending;
    this.queryData.useTransformInputParameter = this.widgetQuery.UseTransformInputParameter;
    this.queryData.transformInputParameterId = this.widgetQuery.TransformInputParameterId;
    this.queryData.useProjectionInputParameter = this.widgetQuery.UseProjectionInputParameter;
    this.queryData.projectionInputParameterId = this.widgetQuery.ProjectionInputParameterId;
    this.queryData.groupSelectionType = this.widgetQuery.GroupSelectionType;
    this.queryData.groupInputParameterId = this.widgetQuery.GroupInputParameterId;
    this.queryData.groupInputParameterIds = this.widgetQuery.GroupInputParameterIds;

    this.widgetQuery.Query.scriptedcolumnspostaggregation.forEach(scriptcol => {
      this.queryData.scriptedcolumnspostaggregation.push(TypedJSON.parse(JSON.stringify(scriptcol), LuaQueryColumn));
    })

  }

  addAllColumns()
  {
    //this.queryData.columnConfigs.push(new ColumnConfig());

    for(let col of this.queryData.columns)
    {
      let t = new ColumnConfig();
      t.ColumnName = col.columnname;
      t.ColumnOutName = col.columnname;
      t.Label = col.columnname;
      t.Datatype = col.datatype;
      t.Unit = col.unit;
      t.Tags = col.tags;
      t.EditMode = ProjectionDataeditorWidgetUtils.getEditMode(t.Tags, t.Datatype);
      t.EnumMembers = t.EditMode == EditMode.Enum ? ProjectionDataeditorWidgetUtils.getEnumMembers(t.Tags) : [];
      this.queryData.columnConfigs.push(t);
    }

  }

  removeAllColumns()
  {
    this.queryData.columnConfigs.length = 0;
  }

  addColumn() {
    this.queryData.columnConfigs.push(new ColumnConfig());
  }

  async ngAfterViewInit() {
    if (this.projections?.length == 0) {
      this.projections = await this.xprojClient.RequestListQueryableProjections(0, 10000);
    }

    this.queryData.projection = this.projections.find(p => p.projectionid == this.widgetQuery.ProjectionId);

    //await this.selectedProjectionChange(this.queryData.projection);

    this.selectedProjectionGroupChange(this.queryData.selectedGroup);
  }

  async selectedProjectionChange(projection: Projection) {
    if (projection) {
      this.queryData.selectedGroup = null;
      this.queryData.queryableSelectedGroup.length = 0;
      await this.queryColumns(this.queryData.projection.projectionid, null);
      this.checkTimeFilterColumn();
    }
  }

  async selectedProjectionGroupChange(group: any) {
    if (this.queryData.projection) {
      this.queryData.selectedGroup = group;
      this.queryData.queryableSelectedGroup.length = 0;
      for (let gr of group) {
        this.queryData.queryableSelectedGroup.push(gr);
      }
      //console.log('selectedProjectionGroupChange', group);
      await this.queryColumns(this.queryData.projection.projectionid, group);
      this.checkTimeFilterColumn();
    }
  }

  async queryColumns(projectionId: string, group: Array<string>) {
    //this.loadingProjectionColumns = true;
    let groupstr = "";
    if (group) {
      groupstr = group.join(",");
    }
    this.queryData.columns = await this.xprojClient.RequestListQueryableProjectionColumns(projectionId, groupstr, 0, 500);

    this.queryData.preQueryColumnConfigs = [];
    this.widgetConfig.WidgetPreQueryConfigs.forEach(preConfig => {
      preConfig.ColumnConfigs.forEach(c => this.queryData.preQueryColumnConfigs.push({ columnname: (preConfig.Prefix?.length > 0 ? preConfig.Prefix : preConfig.Name) + ':' + c.ColumnOutName, columnConfig: c }));
    });
    //this.loadingProjectionColumns = false;
  }

  checkTimeFilterColumn(force: boolean = false): void {
    if (!this.queryData.columns.find(col => col.columnname == this.queryData.timefiltercolumn)
      || (!this.widgetQuery.timestampColumnName || this.widgetQuery.timestampColumnName.length == 0)) {
      this.queryData.timefiltercolumn = '';
    }

    let found = false;
    //Look for timestamp or time column
    if (force || !this.queryData.timefiltercolumn || this.queryData.timefiltercolumn.length == 0) {
      this.queryData.columns.forEach(col => {
        if (col.datatype == XDataType.Timestamp &&
          (col.columnname.toLowerCase() == 'timestamp' || col.columnname.toLowerCase() == 'time')) {
          this.queryData.timefiltercolumn = col.columnname;
          found = true;
        }
      });
    }
    //Take first column with Timestamp type if not found before.
    if (!found && (force || !this.queryData.timefiltercolumn || this.queryData.timefiltercolumn.length == 0)) {
      this.queryData.columns.forEach(col => {
        if (col.datatype == XDataType.Timestamp) {
          this.queryData.timefiltercolumn = col.columnname;
        }
      });
    }
  }

  removeQuery() {
    this.onQueryRemoved?.emit(this.widgetQuery);
  }

  removeColumn(index: number) {
    ArrayUtils.RemoveItemAt(this.queryData.columnConfigs, index);
  }

  moveColumnUp(index: number) {
    ArrayUtils.MoveItemUp(this.queryData.columnConfigs, index);
    this.refreshColumnConfigs();
  }

  moveColumnDown(index: number) {
    ArrayUtils.MoveItemDown(this.queryData.columnConfigs, index);
    this.refreshColumnConfigs();
  }

  removeSelectedColumn() {
    let counter = 0;
    for (let i of this.queryData.columnConfigs) {
      if (i == this.selectedColumnConfig)
        break;
      counter++;
    }
    this.removeColumn(counter);
    this.selectedColumnConfig = null;
  }

  private refreshColumnConfigs() {
    let copy = [...this.queryData.columnConfigs];
    this.queryData.columnConfigs = [];
    this.cdr.detectChanges();
    this.queryData.columnConfigs = copy;
  }

  onColumnSelect($event, index) {
    let column = this.queryData.columns.find(col => col.columnname == $event);
    if (column && column.unit?.length > 0 && index < this.queryData.columnConfigs.length) {
      this.queryData.columnConfigs[index].Unit = column.unit;
    }
    if (column) {
      this.queryData.columnConfigs[index].Datatype = column.datatype;
      this.queryData.columnConfigs[index].Tags = column.tags;
      this.queryData.columnConfigs[index].EditMode = ProjectionDataeditorWidgetUtils.getEditMode(this.queryData.columnConfigs[index].Tags, this.queryData.columnConfigs[index].Datatype);
      this.queryData.columnConfigs[index].EnumMembers = this.queryData.columnConfigs[index].EditMode == EditMode.Enum ? ProjectionDataeditorWidgetUtils.getEnumMembers(this.queryData.columnConfigs[index].Tags) : [];
    }
    else {
      let column = this.queryData.preQueryColumnConfigs.find(col => col.columnname == $event);
      if (column) {
        this.queryData.columnConfigs[index].Datatype = column.columnConfig.Datatype;
        this.queryData.columnConfigs[index].EditMode = ProjectionDataeditorWidgetUtils.getEditMode(column.columnConfig.Tags, column.columnConfig.Datatype);
        //this.queryData.columnConfigs[index].EnumMembers = column.columnConfig.EditMode == EditMode.Enum ? ProjectionDataeditorWidgetUtils.getEnumMembers(column.columnConfig.Tags) : [];
      }
      else {
        let column = this.queryData.scriptedcolumnspostaggregation.find(col => 'script:' + col.columnoutname == $event);
        if (column) {
          this.queryData.columnConfigs[index].Datatype = column.datatypeout;
          //this.queryData.columnConfigs[index].EditMode = ProjectionDataeditorWidgetUtils.getEditMode(column.columnConfig.Tags, column.columnConfig.Datatype);
        }
      }
    }
  }

  addScriptedColumnsPostAggregation() {
    this.queryData.scriptedcolumnspostaggregation.push(new LuaQueryColumn());
  }

  onLuaQueryColumnRemoved(queryColumn: LuaQueryColumn) {
    this.queryData.scriptedcolumnspostaggregation = this.queryData.scriptedcolumnspostaggregation.filter(q => q != queryColumn);
  }

  addThreshold() {
    this.selectedColumnConfig.ColorThresholds.push(new TableColorThreshold());
  }

  removeThreshold(index: number) {
    ArrayUtils.RemoveItemAt(this.selectedColumnConfig.ColorThresholds, index);
  }

  moveThresholdUp(index: number)
  {
    ArrayUtils.MoveItemUp(this.selectedColumnConfig.ColorThresholds, index);
  }
  moveThresholdDown(index: number)
  {
    ArrayUtils.MoveItemDown(this.selectedColumnConfig.ColorThresholds, index);
  }

  removeSelectedThreshold()
  {
    let counter = 0;
    for(let threshold of this.selectedColumnConfig.ColorThresholds)
    {
      if(threshold == this.selectedThreshold)
        break;
      counter++;
    }
    this.removeThreshold(counter);
    this.selectedThreshold = null;
  }

  updateInputParameters(inputs: WidgetInputParameter[]) {
    this.inputParameters = inputs;
    if (this.projectionFilter) {
      this.projectionFilter.inputParameters = this.inputParameters;
    }
  }

  async SaveQuery() {
    //convert from literal object to class object
    this.widgetQuery.DataFilters.forEach(filter => {
      filter.ColumnDescriptor = TypedJSON.parse(JSON.stringify(filter.ColumnDescriptor), ProjectionColumnDescription);
    });

    this.widgetQuery.Query = this.projectionFilter.GetQuery();
    this.widgetQuery.DataFilters = this.projectionFilter.datafilters;
    this.widgetQuery.FilterLogicalGroupType = this.projectionFilter.filterLogicalGroupType;
    this.widgetQuery.Query.maxitems = 10;
    this.widgetQuery.Query.scriptedcolumnspostaggregation = this.queryData.scriptedcolumnspostaggregation;

    this.widgetQuery.ProjectionId = this.queryData.projection.projectionid;
    this.widgetQuery.Group = this.queryData.selectedGroup;
    this.widgetQuery.Query.targetprojectionid = this.widgetQuery.ProjectionId;

    if (this.queryData.columnConfigs.length == 0) {
      return;
    }

    let colindex = 0;
    for (let column of this.queryData.columnConfigs) {
      let col = new BaseQueryInputColumnDescription();
      col.columnname = column.ColumnName;
      if (column.Label?.length > 0) {
        col.columnoutname = column.ColumnOutName = column.Label;
      }
      else {
        col.columnoutname = column.ColumnOutName = this.queryData.usegrouping ? "col_" + colindex.toString() : column.ColumnName;
      }

      col.columntransformation = column.ColumnTransformation;

      if (colindex == 0) {
        if (!this.queryData.usegrouping) {
          this.widgetQuery.Query.sorting.columnname = col.columnname;
          this.widgetQuery.Query.sorting.descending = false;
          this.widgetQuery.Query.grouping = new ColumnGroupingDescription();
          this.widgetQuery.Query.columns.push(col);
        }
        else {
          this.widgetQuery.Query.grouping.columnname = col.columnname;
          this.widgetQuery.Query.grouping.columntransformation = this.queryData.groupingtransform;
          this.widgetQuery.Query.grouping.columnoutname = col.columnoutname;
        }
      }
      else {
        if (this.queryData.usegrouping) {
          col.columnaggregation = column.Transform;
        }
        this.widgetQuery.Query.columns.push(col);
      }

      colindex++;
    }
    this.widgetQuery.ColumnConfigs = this.queryData.columnConfigs;

    this.widgetQuery.UseGrouping = this.queryData.usegrouping;
    this.widgetQuery.GroupingTransform = this.queryData.groupingtransform;
    this.widgetQuery.timestampColumnName = this.queryData.timefiltercolumn;
    this.widgetQuery.defaultSortColumnName = this.queryData.defaultSortColumnName;
    this.widgetQuery.defaultSortDescending = this.queryData.defaultSortDescending;
    this.widgetQuery.UseTransformInputParameter = this.queryData.useTransformInputParameter;
    this.widgetQuery.TransformInputParameterId = this.queryData.transformInputParameterId;
    this.widgetQuery.UseProjectionInputParameter = this.queryData.useProjectionInputParameter;
    this.widgetQuery.ProjectionInputParameterId = this.queryData.projectionInputParameterId;
    this.widgetQuery.GroupSelectionType = this.queryData.groupSelectionType;
    this.widgetQuery.GroupInputParameterId = this.queryData.groupInputParameterId;
    this.widgetQuery.GroupInputParameterIds = this.queryData.groupInputParameterIds;

    if (this.groupSelect) {
      this.widgetQuery.Query.targetgroup = this.groupSelect.getSelectedGroup();
    }
  }

  multiselectkeyChange($event) {

  }
}
