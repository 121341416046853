<form clrForm clrLayout="vertical" autocomplete="off" *ngIf="node">
  <clr-input-container>
    <label class="clr-col-12">Id</label>
    <input clrInput style="width: 90%;" class="clr-col-12" placeholder="" name="id" [disabled]="nodeType.autoGenerateId"
      [(ngModel)]="node.id" />
  </clr-input-container>
  <clr-input-container *ngIf="nodeType.namePropertyId.length == 0 || nodeType.alwaysShowName">
    <label class="clr-col-12">{{nodeType.nameHeader.length > 0 ? nodeType.nameHeader : 'Name'}}</label>
    <input clrInput style="width: 90%;" class="clr-col-12" placeholder="" name="name" [(ngModel)]="node.name" />
  </clr-input-container>
</form>

<clr-tabs clrLayout="horizontal" class="category-tabs">
  <clr-tab *ngFor="let category of nodeType.categories">
    <button clrTabLink (click)="selectCategory(category)">{{category}}</button>
    <clr-tab-content *clrIfActive>
      <!-- <ng-template ngFor let-item [ngForOf]="parameters" let-i="index">

      </ng-template> -->
      <form clrForm clrLayout="vertical" autocomplete="off">
        <ng-template ngFor let-item [ngForOf]="parameters" let-i="index">
          <clr-input-container
            *ngIf="!item.parameter.hidden && item.parameter.typeName == 'string' && !item.parameter.projection && !item.parameter.multiline">
            <label class="clr-col-12">{{item.parameter.header}}</label>
            <input clrInput style="width: 90%;" class="clr-col-12" placeholder="{{item.parameter.defaultValue}}"
              [type]="item.parameter.password ? 'password' : 'text'" name="value_{{i}}" [(ngModel)]="item.value.value"
              [disabled]="item.parameter.readOnly" [minlength]="item.parameter.minLength"
              [maxlength]="item.parameter.maxLength" />
            <clr-control-helper *ngIf="!item.parameter.schedule">{{item.parameter.tooltip}}</clr-control-helper>
            <clr-control-helper *ngIf="item.parameter.schedule">{{cronDescription(item.value.value)}}</clr-control-helper>
          </clr-input-container>

          <clr-textarea-container
            *ngIf="!item.parameter.hidden && item.parameter.typeName == 'string' && !item.parameter.projection && item.parameter.multiline">
            <label class="clr-col-12">{{item.parameter.header}}</label>
            <textarea clrTextarea style="width: 90%;" class="clr-col-12"
              [rows]="item.parameter.multilineRows > 0 ? item.parameter.multilineRows : 8"
              [cols]="item.parameter.multilineCols > 0 ? item.parameter.multilineCols : 50"
              placeholder="{{item.parameter.defaultValue}}" name="value_{{i}}" [(ngModel)]="item.value.value"
              [disabled]="item.parameter.readOnly" [minlength]="item.parameter.minLength"
              [maxlength]="item.parameter.maxLength"></textarea>
            <clr-control-helper>{{item.parameter.tooltip}}</clr-control-helper>
          </clr-textarea-container>

          <clr-select-container
            *ngIf="!item.parameter.hidden && item.parameter.typeName == 'string' && item.parameter.projection">
            <label class="clr-col-12">{{item.parameter.header}}</label>
            <select clrSelect class="clr-col-12" name="value_{{i}}" [(ngModel)]="item.value.value"
              [disabled]="item.parameter.readOnly">
              <option *ngFor="let projection of projections" [ngValue]="projection.projectionid">
                {{projection.name}}
              </option>
            </select>
            <clr-control-helper>{{item.parameter.tooltip}}</clr-control-helper>
          </clr-select-container>


          <!-- <clr-input-container *ngIf="!item.parameter.hidden && item.parameter.typeName == 'stringarray' && !item.parameter.projectionGroup
              && !(item.parameter.optionsRootNodeId?.length > 0)">
            <label class="clr-col-12">{{item.parameter.header}}</label>
            <input clrInput style="width: 90%;" class="clr-col-12" placeholder="{{item.parameter.defaultValue}}"
              name="value_{{i}}" [(ngModel)]="item.value.value" [disabled]="item.parameter.readOnly" />
            <clr-control-helper>{{item.parameter.tooltip}}</clr-control-helper>
          </clr-input-container> -->

          <clr-textarea-container *ngIf="!item.parameter.hidden && item.parameter.typeName == 'stringarray' && !item.parameter.projectionGroup
              && !(item.parameter.optionsRootNodeId?.length > 0)">
            <label class="clr-col-12">{{item.parameter.header}}</label>
            <textarea clrTextarea style="width: 90%;" class="clr-col-12" [rows]="3" [cols]="50"
              placeholder="{{item.parameter.defaultValue}}" name="value_{{i}}" [(ngModel)]="item.value.value"
              [disabled]="item.parameter.readOnly"></textarea>
            <clr-control-helper>{{item.parameter.tooltip}}</clr-control-helper>
          </clr-textarea-container>


          <app-string-array-input [stringArrayProperty]="item.parameter" [value]="item.value.value" [customerId]="customerId"
            *ngIf="!item.parameter.hidden && item.parameter.typeName == 'stringarray' && (item.parameter.optionsRootNodeId?.length > 0)">
          </app-string-array-input>

          <div class="clr-form-control"
            *ngIf="!item.parameter.hidden && item.parameter.typeName == 'stringarray' && item.parameter.projectionGroup">
            <label class="clr-col-12">{{item.parameter.header}}</label>

            <xproj-group-selection #groupSelect [projection]="projection" [selected]="item.value.value"
              (groupSelected)="selectedProjectionGroupChange($event, item.value)" [onlyOutputSelectedOnData]="false"
              [showHeader]="false">
            </xproj-group-selection>
          </div>

          <clr-input-container *ngIf="!item.parameter.hidden && item.parameter.typeName == 'dashboard'">
            <label class="clr-col-12">{{item.parameter.header}}</label>
            <input clrInput style="width: 90%;" class="clr-col-12" placeholder="{{item.parameter.defaultValue}}"
              name="value_{{i}}" [(ngModel)]="item.value.value" />
            <clr-control-helper>{{item.parameter.tooltip}}</clr-control-helper>
          </clr-input-container>

          <clr-input-container *ngIf="!item.parameter.hidden && item.parameter.typeName == 'double'">
            <label class="clr-col-12">{{item.parameter.header}}</label>
            <input clrInput class="clr-col-12" type="number" min="{{item.parameter.min}}" max="{{item.parameter.max}}"
              step="{{item.parameter.step}}" placeholder="{{item.parameter.defaultValue}}" name="value_{{i}}"
              [(ngModel)]="item.value.value" [disabled]="item.parameter.readOnly" />
            <clr-control-helper>{{item.parameter.tooltip}}</clr-control-helper>
          </clr-input-container>

          <clr-checkbox-container *ngIf="!item.parameter.hidden && item.parameter.typeName == 'boolean' && !item.parameter.toggleSwitch">
            <clr-checkbox-wrapper>
              <input type="checkbox" clrCheckbox class="clr-col-12" [(ngModel)]="item.value.value" name="value_{{i}}"
                [disabled]="item.parameter.readOnly" />
              <label class="clr-col-12">{{item.parameter.header}}</label>
            </clr-checkbox-wrapper>
            <span class="clr-subtext">{{item.parameter.tooltip}}</span>
          </clr-checkbox-container>

          <clr-toggle-container *ngIf="!item.parameter.hidden && item.parameter.typeName == 'boolean' && item.parameter.toggleSwitch">
            <clr-toggle-wrapper>
              <input type="checkbox" clrToggle class="clr-col-12" [(ngModel)]="item.value.value" name="value_{{i}}"
              [disabled]="item.parameter.readOnly" />
              <label class="clr-col-12">{{item.parameter.header}}</label>
           </clr-toggle-wrapper>
           <span class="clr-subtext">{{item.parameter.tooltip}}</span>
           </clr-toggle-container>

          <clr-input-container *ngIf="!item.parameter.hidden && item.parameter.typeName == 'datetime'">
            <label class="clr-col-12">{{item.parameter.header}}</label>
            <input clrInput class="clr-col-12"
              type="{{item.parameter.dateOnly ? 'date' : item.parameter.timeOnly ? 'time' : 'datetime-local'}}" step="1"
              [(ngModel)]="item.value.value" name="value_{{i}}" [disabled]="item.parameter.readOnly">
            <clr-control-helper>{{item.parameter.tooltip}}</clr-control-helper>
          </clr-input-container>

          <app-string-option-input [stringOptionsProperty]="item.parameter" [(value)]="item.value.value" [customerId]="customerId"
            *ngIf="!item.parameter.hidden && item.parameter.typeName == 'stringoptions'">
          </app-string-option-input>

        </ng-template>
      </form>
    </clr-tab-content>
  </clr-tab>
</clr-tabs>
